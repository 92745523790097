@charset "UTF-8";
@font-face {
  font-family: "HelveticaNeueW01-45Ligh";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/5a13f7d1-b615-418e-bc3a-525001b9a671.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/530dee22-e3c1-4e9f-bf62-c31d510d9656.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW01-55Roma";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b0868b4c-234e-47d3-bc59-41ab9de3c0db.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/bcf54343-d033-41ee-bbd7-2b77df3fe7ba.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW01-65Medi";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/3600b37f-2bf1-45f3-be3a-03365f16d9cb.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/60be5c39-863e-40cb-9434-6ebafb62ab2b.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW01-75Bold";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/3b303641-706e-4221-94c4-4fb491f4f8ef.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/14ff6081-326d-4dae-b778-d7afa66166fc.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW01-UltLt";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/9baef9a5-e2af-4838-a3bc-da9d36c0bde8.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/4bff1fbb-b4bf-4d95-9c47-efcb14384e36.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW01-Thin";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/aff68211-86bb-476d-882e-f7a3face144c.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/50d35bbc-dfd4-48f1-af16-cf058f69421d.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW01-95Blac";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/fd05322c-bf43-4f8e-a129-24869b0942db.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/74649485-cd74-443e-9d54-331ccd448900.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-45Ligh";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/7732d6de-bd05-4c17-ae43-99e9a02edf31.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/9a2e4855-380f-477f-950e-d98e8db54eac.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-55Roma";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b4cd926c-1a31-4785-b261-1db1a68c521e.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/d5af76d8-a90b-4527-b3a3-182207cc3250.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-65Medi";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/9f93e286-eeaa-4077-b0b2-0e6901920f74.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/5b4a262e-3342-44e2-8ad7-719998a68134.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-75Bold";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/2a5cf81d-ffbe-4c76-8982-270592fb36b0.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/4a9c62ab-b359-4081-8383-a0d1cdebd111.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-UltLt";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b39b5378-b594-45fe-8067-fc4385d08520.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/84558c76-9f1b-44d2-ac62-d7937f43809b.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-Thin";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/96b33a5f-d3a7-4a34-b360-76867695bb83.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/775a65da-14aa-4634-be95-6724c05fd522.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-95Blac";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/a9e72f14-d263-440b-b2a4-fa729879fb51.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/6d5b06b3-f8db-4de6-aa46-2a6de9569b51.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-45Ligh";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/bb85cc70-1239-4a3c-b9c7-4da74afaf032.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/835e7b4f-b524-4374-b57b-9a8fc555fd4e.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-55Roma";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/cf45dd62-4ace-47a2-abee-6fe9e95a1daa.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/8ac9e38d-29c6-41ea-8e47-4ae4d2b1a4e1.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-65Medi";
  font-weight: bold;
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/da3af6d8-5634-4072-8989-1ddf770e3142.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/5b29e833-1b7a-40ab-82a5-cfd69c8650f4.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-75Bold";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/51f47b5d-143f-4d30-a6c3-23217fc0136b.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/d85949a1-c37a-43f7-9d09-fb056acf0c27.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-25UltL";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/277370bf-544b-4d3b-9f99-b9566a4b722b.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/723fd1ed-5aad-454d-af88-3711c5caf1c7.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-95Blac";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/e2c2affa-fc35-4c36-aabd-33c8665d0531.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b1d7b778-bdf9-4ff3-b4e8-8cdabf234863.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-35Thin";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/9ec06c5b-556a-4dc4-9db2-425acf9fd515.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/c881c21b-4148-4a11-a65d-f35e42999bc8.woff?1") format("woff"); }

html {
  font-family: sans-serif; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }
  input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

body {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility; }

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0; }

button {
  border-radius: 0; }

*:not(input):not(textarea) {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

[data-z-counter] {
  z-index: 0; }

[data-z-counter='0'] {
  z-index: auto; }

.santa-preview-container html,
.santa-preview-container body,
.santa-preview-container div,
.santa-preview-container span,
.santa-preview-container applet,
.santa-preview-container object,
.santa-preview-container iframe,
.santa-preview-container h1,
.santa-preview-container h2,
.santa-preview-container h3,
.santa-preview-container h4,
.santa-preview-container h5,
.santa-preview-container h6,
.santa-preview-container p,
.santa-preview-container blockquote,
.santa-preview-container pre,
.santa-preview-container a,
.santa-preview-container abbr,
.santa-preview-container acronym,
.santa-preview-container address,
.santa-preview-container big,
.santa-preview-container cite,
.santa-preview-container code,
.santa-preview-container del,
.santa-preview-container dfn,
.santa-preview-container em,
.santa-preview-container font,
.santa-preview-container img,
.santa-preview-container ins,
.santa-preview-container kbd,
.santa-preview-container q,
.santa-preview-container s,
.santa-preview-container samp,
.santa-preview-container small,
.santa-preview-container strike,
.santa-preview-container strong,
.santa-preview-container sub,
.santa-preview-container sup,
.santa-preview-container tt,
.santa-preview-container var,
.santa-preview-container b,
.santa-preview-container u,
.santa-preview-container i,
.santa-preview-container center,
.santa-preview-container dl,
.santa-preview-container dt,
.santa-preview-container dd,
.santa-preview-container ol,
.santa-preview-container ul,
.santa-preview-container li,
.santa-preview-container fieldset,
.santa-preview-container form,
.santa-preview-container label,
.santa-preview-container legend,
.santa-preview-container table,
.santa-preview-container caption,
.santa-preview-container tbody,
.santa-preview-container tfoot,
.santa-preview-container thead,
.santa-preview-container tr,
.santa-preview-container th,
.santa-preview-container td,
.santa-preview-container nav,
.santa-preview-container button,
.santa-preview-container section,
.santa-preview-container header,
.santa-preview-container footer,
.santa-preview-container title {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent; }

.control-button .symbol-with-label {
  margin-right: 8px; }

.button-wrapper-center {
  text-align: center;
  margin-bottom: 15px; }

.control-button2 {
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-size: 0;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center; }
  .btn-back.control-button2 {
    min-width: 67px;
    height: 21px;
    background-color: #eaf7ff; }
    .btn-back.control-button2:hover {
      background-color: #d3edff; }
  .default.control-button2, .btn-confirm-primary.control-button2 {
    background-color: #3899ec;
    color: #fff; }
    .default.control-button2:hover, .btn-confirm-primary.control-button2:hover {
      background-color: #4eb7f5; }
    .default.control-button2:active, .btn-confirm-primary.control-button2:active {
      background-color: #3899ec; }
  .btn-confirm-primary.control-button2 {
    color: #fff; }
  .default.control-button2 {
    height: 36px;
    border-radius: 18px; }
    .default.control-button2 .symbol:nth-child(2):nth-last-child(2) {
      margin-left: 8px; }
  .btn-confirm-secondary.control-button2 {
    background-color: #eaf7ff;
    color: #3899ec; }
    .btn-confirm-secondary.control-button2:hover {
      background-color: #d3edff; }
    .btn-confirm-secondary.control-button2:active {
      background-color: #eaf7ff; }
  .btn-md.control-button2, .btn-sm.control-button2 {
    padding: 0 18px;
    border-radius: 15px; }
  .btn-md.control-button2 {
    height: 30px; }
  .btn-sm.control-button2 {
    height: 24px; }
  .btn-text.control-button2 {
    background: 0;
    font-size: 18px;
    color: #3899ec; }
  .btn-danger-secondary.control-button2 {
    background-color: #fce6e5;
    color: #ee5951; }
    .btn-danger-secondary.control-button2:hover {
      background-color: #facdca; }
    .btn-danger-secondary.control-button2:active {
      background-color: #fce6e5; }
  .btn-danger-primary.control-button2 {
    background-color: #ee5951;
    color: #fff; }
    .btn-danger-primary.control-button2:hover {
      background-color: #f1726b; }
    .btn-danger-primary.control-button2:active {
      background-color: #ee5951; }
  .control-button2[disabled] {
    cursor: default; }
    .default.control-button2[disabled] {
      background-color: #bcbcbc; }
  .control-button2 > input {
    display: none; }
  .control-button2 > .label,
  .control-button2 > .symbol {
    display: inline-block; }
  .control-button2 > .label {
    font-size: initial; }
    .btn-back.control-button2 > .label {
      color: #3899ec;
      font-size: 13px; }
    .default.control-button2 > .label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px;
      padding: 0 24px;
      color: #fff; }
    .btn-text.control-button2 > .label {
      text-decoration: underline; }

.file-input-control {
  margin: 0;
  padding: 0;
  cursor: pointer; }
  .file-input-control.button {
    box-sizing: border-box;
    height: 30px;
    border-radius: 18px;
    background-color: #fb7d33;
    color: rgba(255, 255, 255, 0.96);
    margin-left: auto;
    padding-left: 22px; }
    .file-input-control.button:hover {
      background-color: #fc975c; }
    .file-input-control.button:active {
      background-color: #fb7d33; }
    .file-input-control.button[disabled] {
      cursor: default;
      background-color: #bcbcbc; }
    .file-input-control.button > .label,
    .file-input-control.button > .symbol {
      display: inline-block;
      vertical-align: middle;
      margin-top: 4px; }
    .file-input-control.button > .label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      padding: 0 24px 0 11px;
      color: #fff; }
  .file-input-control.text > .label {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    color: #3899ec; }
  .file-input-control > input {
    display: none; }

.input-date-picker {
  position: relative;
  background-color: #fff;
  border-radius: 8px; }
  .input-date-picker .dp-header {
    box-sizing: border-box;
    height: 48px;
    line-height: 32px;
    padding: 8px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    box-shadow: 0 1px 3.96px 0.04px rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid #f0f3f5; }
    .input-date-picker .dp-header .dp-previous {
      transform: rotate(-180deg); }
    .input-date-picker .dp-header .dp-navigate {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center; }
      .input-date-picker .dp-header .dp-navigate:hover {
        background-color: #eaf7ff; }
      .input-date-picker .dp-header .dp-navigate:active {
        background-color: #3899ec; }
        .input-date-picker .dp-header .dp-navigate:active * {
          fill: #fff; }
    .input-date-picker .dp-header .dp-title {
      flex: 1;
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      color: #3899ec; }
  .input-date-picker .dp-body table {
    text-align: center;
    width: calc(100% - 24px);
    margin: 8px 12px;
    font-size: 13px;
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #2b5672; }
    .input-date-picker .dp-body table td div {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      line-height: 32px; }
    .input-date-picker .dp-body table .dp-day-name {
      font-weight: bold; }
  .input-date-picker .dp-day {
    cursor: pointer; }
    .input-date-picker .dp-day:not(.dp-day-selected):hover {
      background-color: #eaf7ff; }
  .input-date-picker .dp-day-not-in-current-month {
    color: #d9e1e8; }
  .input-date-picker .dp-day-selected,
  .input-date-picker .dp-day-selected:hover {
    background-color: #3899ec;
    color: #fff; }
  .input-date-picker .dp-today:not(.dp-day-not-in-current-month) {
    border: 1px solid #3899ec; }
  .input-date-picker .dp-footer {
    border-top: 1px solid #f0f3f5;
    height: 32px;
    padding: 12px; }
    .input-date-picker .dp-footer .delete-btn {
      background-color: #fff;
      padding: 0 12px;
      margin-right: 12px;
      height: 32px;
      vertical-align: middle; }
      .input-date-picker .dp-footer .delete-btn path {
        fill: #2b5672; }
      .input-date-picker .dp-footer .delete-btn:hover path {
        fill: #ee5951; }
    .input-date-picker .dp-footer .goto-today,
    .input-date-picker .dp-footer .goto-selection {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      background-color: transparent;
      color: #3899ec;
      display: inline-block;
      border-radius: 32px;
      line-height: 32px;
      height: 32px;
      padding: 0 20px;
      cursor: pointer; }
      .input-date-picker .dp-footer .goto-today:hover,
      .input-date-picker .dp-footer .goto-selection:hover {
        background-color: #eaf7ff; }
    .input-date-picker .dp-footer .goto-selection {
      float: right; }
      .input-date-picker .dp-footer .goto-selection:disabled {
        cursor: inherit;
        color: #bcbcbc;
        background-color: transparent; }

.control-angle {
  position: relative;
  height: 108px; }
  .control-angle > .disabled {
    opacity: 0.4; }
  .control-angle > .circle {
    width: 72px;
    height: 72px;
    top: 32px;
    left: 48px;
    border-radius: 50%;
    border: solid 4px #d6e1e8;
    position: absolute;
    box-sizing: border-box; }
    .control-angle > .circle:hover > .knob {
      background-color: #86ccf7; }
    .control-angle > .circle:active > .circle-center,
    .control-angle > .circle:active > .knob {
      background-color: #0199ef; }
    .control-angle > .circle:active:after {
      border: solid 1px #0199ef; }
    .control-angle > .circle:hover > .knob, .control-angle > .circle:active > .knob {
      box-shadow: 0 0 4px 1px rgba(25, 94, 153, 0.3), 0 0 8px 1px #86ccf7; }
    .control-angle > .circle > .circle-center {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      position: absolute;
      background-color: #d6e1e8;
      top: calc(50% - 3px);
      left: calc(50% - 3px); }
    .control-angle > .circle:after {
      width: 0;
      height: 32px;
      content: '';
      position: absolute;
      border: solid 1px #d6e1e8;
      z-index: -1;
      left: 31px; }
    .control-angle > .circle .knob {
      width: 16px;
      height: 16px;
      position: absolute;
      cursor: pointer;
      background-color: #3899ec;
      border-radius: 50%;
      border: solid 4px #fff;
      box-shadow: 0 0 4px 1px rgba(25, 94, 153, 0.3);
      box-sizing: border-box;
      top: -9px;
      left: calc(50% - 8px); }
  .control-angle > .control-label {
    width: 54px;
    color: #2b5672;
    font-size: 14px;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  .animation-panel .control-angle {
    margin: 17px 10px 7px 24px;
    height: 96px; }
    .animation-panel .control-angle .input-stepper {
      left: auto;
      top: 28px;
      right: 0;
      width: 56px;
      padding: 0 6px; }
    .animation-panel .control-angle .circle {
      top: 18px;
      left: 80px; }

.control-thumbnails {
  position: relative;
  padding: 13px 24px 16px;
  white-space: initial; }
  .control-thumbnails,
  .control-thumbnails * {
    box-sizing: border-box;
    font-size: 0; }
  .control-thumbnails .radio-control {
    position: relative;
    display: inline-block;
    margin: 0 12px 12px 0;
    vertical-align: top; }
    .control-thumbnails .radio-control .svg-radio {
      position: relative;
      width: 100%;
      background-color: #eaf7ff;
      display: inline-block;
      border-radius: 8px;
      transition: background-color 0.2s; }
      .control-thumbnails .radio-control .svg-radio:hover {
        background-color: #d3edff; }
      .control-thumbnails .radio-control .svg-radio.disabled {
        background-color: #f0f0f0; }
    .control-thumbnails .radio-control .image-radio {
      position: relative;
      overflow: hidden;
      width: 100%;
      border: 1px solid #d9e1e8;
      display: inline-block;
      border-radius: 8px; }
      .control-thumbnails .radio-control .image-radio .image-radio-src {
        position: relative;
        display: block;
        top: -1px;
        left: -1px; }
    .control-thumbnails .radio-control:hover .image-radio {
      border: 2px solid #7fccf7; }
      .control-thumbnails .radio-control:hover .image-radio .image-radio-src {
        top: -2px;
        left: -2px; }
    .control-thumbnails .radio-control input:checked + .svg-radio {
      background-color: #3899ec; }
      .control-thumbnails .radio-control input:checked + .svg-radio.symbol-thumb-none, .control-thumbnails .radio-control input:checked + .svg-radio.symbol-transition-none {
        background-color: #eaf7ff; }
      .control-thumbnails .radio-control input:checked + .svg-radio .cls-5.cls-5 {
        fill: #eaf7ff; }
    .control-thumbnails .radio-control input:checked + .image-radio {
      border: 2px solid #3899ec; }
      .control-thumbnails .radio-control input:checked + .image-radio .image-radio-src {
        top: -2px;
        left: -2px; }
    .control-thumbnails .radio-control .label {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 12px;
      display: block;
      text-align: center;
      color: #3899ec;
      padding-top: 9px; }
      .control-thumbnails .radio-control .label.disabled {
        color: #bcbcbc; }
    .control-thumbnails .radio-control .notify-symbol {
      position: absolute; }
      .control-thumbnails .radio-control .notify-symbol.notify-symbol-small-right {
        right: -8px;
        top: -8px; }
  .control-thumbnails[data-max-thumbs-per-row="2"][data-items-to-skip="0"] .radio-control {
    width: 114px; }
    .control-thumbnails[data-max-thumbs-per-row="2"][data-items-to-skip="0"] .radio-control:nth-of-type(2n) {
      margin-right: 0px; }
  .square.control-thumbnails[data-max-thumbs-per-row="2"][data-items-to-skip="0"] input + .svg-radio, .square.control-thumbnails[data-max-thumbs-per-row="2"][data-items-to-skip="0"] input + .image-radio {
    height: 114px; }
  .control-thumbnails[data-max-thumbs-per-row="2"][data-items-to-skip="0"].with-title span:last-of-type {
    max-width: 114px; }
  .control-thumbnails[data-max-thumbs-per-row="2"][data-items-to-skip="1"] .radio-control {
    width: 114px; }
    .control-thumbnails[data-max-thumbs-per-row="2"][data-items-to-skip="1"] .radio-control:nth-of-type(1n) {
      margin-right: 126px; }
  .square.control-thumbnails[data-max-thumbs-per-row="2"][data-items-to-skip="1"] input + .svg-radio, .square.control-thumbnails[data-max-thumbs-per-row="2"][data-items-to-skip="1"] input + .image-radio {
    height: 114px; }
  .control-thumbnails[data-max-thumbs-per-row="2"][data-items-to-skip="1"].with-title span:last-of-type {
    max-width: 114px; }
  .control-thumbnails[data-max-thumbs-per-row="2"][data-items-to-skip="2"] .radio-control {
    width: 114px; }
    .control-thumbnails[data-max-thumbs-per-row="2"][data-items-to-skip="2"] .radio-control:nth-of-type(0n) {
      margin-right: 252px; }
  .square.control-thumbnails[data-max-thumbs-per-row="2"][data-items-to-skip="2"] input + .svg-radio, .square.control-thumbnails[data-max-thumbs-per-row="2"][data-items-to-skip="2"] input + .image-radio {
    height: 114px; }
  .control-thumbnails[data-max-thumbs-per-row="2"][data-items-to-skip="2"].with-title span:last-of-type {
    max-width: 114px; }
  .control-thumbnails[data-max-thumbs-per-row="3"][data-items-to-skip="0"] .radio-control {
    width: 72px; }
    .control-thumbnails[data-max-thumbs-per-row="3"][data-items-to-skip="0"] .radio-control:nth-of-type(3n) {
      margin-right: 0px; }
  .square.control-thumbnails[data-max-thumbs-per-row="3"][data-items-to-skip="0"] input + .svg-radio, .square.control-thumbnails[data-max-thumbs-per-row="3"][data-items-to-skip="0"] input + .image-radio {
    height: 72px; }
  .control-thumbnails[data-max-thumbs-per-row="3"][data-items-to-skip="0"].with-title span:last-of-type {
    max-width: 72px; }
  .control-thumbnails[data-max-thumbs-per-row="3"][data-items-to-skip="1"] .radio-control {
    width: 72px; }
    .control-thumbnails[data-max-thumbs-per-row="3"][data-items-to-skip="1"] .radio-control:nth-of-type(2n) {
      margin-right: 84px; }
  .square.control-thumbnails[data-max-thumbs-per-row="3"][data-items-to-skip="1"] input + .svg-radio, .square.control-thumbnails[data-max-thumbs-per-row="3"][data-items-to-skip="1"] input + .image-radio {
    height: 72px; }
  .control-thumbnails[data-max-thumbs-per-row="3"][data-items-to-skip="1"].with-title span:last-of-type {
    max-width: 72px; }
  .control-thumbnails[data-max-thumbs-per-row="3"][data-items-to-skip="2"] .radio-control {
    width: 72px; }
    .control-thumbnails[data-max-thumbs-per-row="3"][data-items-to-skip="2"] .radio-control:nth-of-type(1n) {
      margin-right: 168px; }
  .square.control-thumbnails[data-max-thumbs-per-row="3"][data-items-to-skip="2"] input + .svg-radio, .square.control-thumbnails[data-max-thumbs-per-row="3"][data-items-to-skip="2"] input + .image-radio {
    height: 72px; }
  .control-thumbnails[data-max-thumbs-per-row="3"][data-items-to-skip="2"].with-title span:last-of-type {
    max-width: 72px; }
  .control-thumbnails[data-max-thumbs-per-row="4"][data-items-to-skip="0"] .radio-control {
    width: 51px; }
    .control-thumbnails[data-max-thumbs-per-row="4"][data-items-to-skip="0"] .radio-control:nth-of-type(4n) {
      margin-right: 0px; }
  .square.control-thumbnails[data-max-thumbs-per-row="4"][data-items-to-skip="0"] input + .svg-radio, .square.control-thumbnails[data-max-thumbs-per-row="4"][data-items-to-skip="0"] input + .image-radio {
    height: 51px; }
  .control-thumbnails[data-max-thumbs-per-row="4"][data-items-to-skip="0"].with-title span:last-of-type {
    max-width: 51px; }
  .control-thumbnails[data-max-thumbs-per-row="4"][data-items-to-skip="1"] .radio-control {
    width: 51px; }
    .control-thumbnails[data-max-thumbs-per-row="4"][data-items-to-skip="1"] .radio-control:nth-of-type(3n) {
      margin-right: 63px; }
  .square.control-thumbnails[data-max-thumbs-per-row="4"][data-items-to-skip="1"] input + .svg-radio, .square.control-thumbnails[data-max-thumbs-per-row="4"][data-items-to-skip="1"] input + .image-radio {
    height: 51px; }
  .control-thumbnails[data-max-thumbs-per-row="4"][data-items-to-skip="1"].with-title span:last-of-type {
    max-width: 51px; }
  .control-thumbnails[data-max-thumbs-per-row="4"][data-items-to-skip="2"] .radio-control {
    width: 51px; }
    .control-thumbnails[data-max-thumbs-per-row="4"][data-items-to-skip="2"] .radio-control:nth-of-type(2n) {
      margin-right: 126px; }
  .square.control-thumbnails[data-max-thumbs-per-row="4"][data-items-to-skip="2"] input + .svg-radio, .square.control-thumbnails[data-max-thumbs-per-row="4"][data-items-to-skip="2"] input + .image-radio {
    height: 51px; }
  .control-thumbnails[data-max-thumbs-per-row="4"][data-items-to-skip="2"].with-title span:last-of-type {
    max-width: 51px; }
  .control-thumbnails[data-max-thumbs-per-row="5"][data-items-to-skip="0"] .radio-control {
    width: 38.4px; }
    .control-thumbnails[data-max-thumbs-per-row="5"][data-items-to-skip="0"] .radio-control:nth-of-type(5n) {
      margin-right: 0px; }
  .control-thumbnails[data-max-thumbs-per-row="5"][data-items-to-skip="0"] input + .svg-radio,
  .control-thumbnails[data-max-thumbs-per-row="5"][data-items-to-skip="0"] input + .image-radio {
    border-radius: 6px; }
    .square.control-thumbnails[data-max-thumbs-per-row="5"][data-items-to-skip="0"] input + .svg-radio, .square.control-thumbnails[data-max-thumbs-per-row="5"][data-items-to-skip="0"] input + .image-radio {
      height: 38.4px; }
  .control-thumbnails[data-max-thumbs-per-row="5"][data-items-to-skip="0"].with-title span:last-of-type {
    max-width: 38.4px; }
  .control-thumbnails[data-max-thumbs-per-row="5"][data-items-to-skip="1"] .radio-control {
    width: 38.4px; }
    .control-thumbnails[data-max-thumbs-per-row="5"][data-items-to-skip="1"] .radio-control:nth-of-type(4n) {
      margin-right: 50.4px; }
  .control-thumbnails[data-max-thumbs-per-row="5"][data-items-to-skip="1"] input + .svg-radio,
  .control-thumbnails[data-max-thumbs-per-row="5"][data-items-to-skip="1"] input + .image-radio {
    border-radius: 6px; }
    .square.control-thumbnails[data-max-thumbs-per-row="5"][data-items-to-skip="1"] input + .svg-radio, .square.control-thumbnails[data-max-thumbs-per-row="5"][data-items-to-skip="1"] input + .image-radio {
      height: 38.4px; }
  .control-thumbnails[data-max-thumbs-per-row="5"][data-items-to-skip="1"].with-title span:last-of-type {
    max-width: 38.4px; }
  .control-thumbnails[data-max-thumbs-per-row="5"][data-items-to-skip="2"] .radio-control {
    width: 38.4px; }
    .control-thumbnails[data-max-thumbs-per-row="5"][data-items-to-skip="2"] .radio-control:nth-of-type(3n) {
      margin-right: 100.8px; }
  .control-thumbnails[data-max-thumbs-per-row="5"][data-items-to-skip="2"] input + .svg-radio,
  .control-thumbnails[data-max-thumbs-per-row="5"][data-items-to-skip="2"] input + .image-radio {
    border-radius: 6px; }
    .square.control-thumbnails[data-max-thumbs-per-row="5"][data-items-to-skip="2"] input + .svg-radio, .square.control-thumbnails[data-max-thumbs-per-row="5"][data-items-to-skip="2"] input + .image-radio {
      height: 38.4px; }
  .control-thumbnails[data-max-thumbs-per-row="5"][data-items-to-skip="2"].with-title span:last-of-type {
    max-width: 38.4px; }
  .control-thumbnails.with-title > div {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    display: block;
    color: #2b5672;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 15px; }
  .control-thumbnails .info-icon {
    position: absolute;
    right: 12px;
    top: 12px;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.15s; }
  .control-thumbnails:hover .info-icon {
    opacity: 1; }

.thumbnails-with-icons .control-header {
  display: flex; }
  .thumbnails-with-icons .control-header .thumbnails-with-icons-label {
    flex: 1; }
  .thumbnails-with-icons .control-header .info-icon {
    opacity: 0;
    float: right;
    position: relative;
    transition-property: opacity;
    transition-duration: 0.15s; }

.thumbnails-with-icons:hover .control-header .info-icon {
  opacity: 1; }

.thumbnails-with-icons .thumbnails-with-icons-label,
.thumbnails-with-icons .option-title {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  color: #2b5672;
  text-overflow: ellipsis;
  overflow: hidden; }

.thumbnails-with-icons .thumbnails-with-icons-label {
  margin-bottom: 12px; }

.thumbnails-with-icons label {
  display: inline-block;
  cursor: pointer;
  text-align: center; }
  .thumbnails-with-icons label:first-of-type {
    margin-right: 12px; }
  .thumbnails-with-icons label svg {
    max-width: 62px;
    max-height: 62px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }

.thumbnails-with-icons input {
  display: none; }
  .thumbnails-with-icons input + span {
    padding-bottom: 18px;
    display: inline-block;
    border-radius: 8px;
    width: 114px;
    box-shadow: inset 0 0 0 1px #d9e1e8;
    font-size: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden; }
  .thumbnails-with-icons input:checked + span {
    box-shadow: inset 0 0 0 2px #3899ec; }
    .thumbnails-with-icons input:checked + span .option-title {
      color: #3899ec; }
  .thumbnails-with-icons input:not(:checked) + span:hover {
    box-shadow: inset 0 0 0 2px #d3edff; }

.thumbnails-with-icons .image-container {
  display: block;
  width: 78px;
  height: 83px;
  border-bottom: 1px solid #d9e1e8;
  margin: 0 auto 12px auto;
  position: relative;
  box-sizing: border-box; }

.thumbnails-vertical {
  width: 100%;
  text-align: center;
  margin: 24px 0; }
  .thumbnails-vertical label {
    display: block;
    margin: 12px 0;
    line-height: 0; }
  .thumbnails-vertical .thumbnail-container {
    cursor: pointer;
    box-shadow: 0 0 0 1px #d9e1e8 inset;
    border-radius: 6px;
    display: inline-block;
    width: calc(100% - 48px);
    line-height: 0;
    overflow: hidden; }
    .thumbnails-vertical .thumbnail-container img {
      margin: 20px 24px 13px 25px; }
      .thumbnails-vertical .thumbnail-container img.withoutLabel {
        margin-bottom: 20px; }
    .thumbnails-vertical .thumbnail-container span {
      margin: 0 0 12px 0;
      line-height: 14px; }
    .thumbnails-vertical .thumbnail-container.right {
      text-align: right; }
    .thumbnails-vertical .thumbnail-container.left {
      text-align: left; }
  .thumbnails-vertical .thumbnail-title {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    margin: 0;
    display: inline-block;
    color: #2b5672; }
  .thumbnails-vertical input {
    display: none; }
    .thumbnails-vertical input:checked + .thumbnail-container {
      box-shadow: 0 0 0 2px #3899ec inset; }
      .thumbnails-vertical input:checked + .thumbnail-container .thumbnail-title {
        color: #3899ec; }
    .thumbnails-vertical input:not(:checked) + .thumbnail-container:hover {
      box-shadow: 0 0 0 2px #d3edff inset; }

.control-mandatory {
  position: relative;
  cursor: pointer; }
  .control-mandatory:after {
    content: attr(data-text);
    display: inline-block;
    margin-left: 13px;
    position: absolute;
    white-space: nowrap;
    line-height: 18px; }
  .control-mandatory input {
    display: none; }
    .control-mandatory input + span {
      width: 16px;
      height: 16px;
      border: solid 1px #3899ec;
      display: inline-block;
      border-radius: 50%;
      transition-property: background-color, color;
      transition-duration: 0.2s;
      position: relative;
      background-color: #f0f3f5; }
      .control-mandatory input + span:before {
        content: '*';
        color: #b6c1cd;
        font-size: 30px;
        position: absolute;
        top: 4.5px;
        left: 2.7px;
        line-height: 18px; }
    .control-mandatory input:checked + span {
      background-color: #f0f3f5; }
      .control-mandatory input:checked + span:before {
        color: #3899ec; }
    .control-mandatory input:disabled + span {
      border-color: #bcbcbc; }
      .control-mandatory input:disabled + span:before {
        color: #bcbcbc; }
  .control-mandatory:hover input + span {
    background-color: #fff; }
    .control-mandatory:hover input + span:before {
      color: #b6c1cd; }
  .control-mandatory:hover input:checked + span {
    background-color: #eaf7ff; }
    .control-mandatory:hover input:checked + span:before {
      color: #3899ec; }
  .control-mandatory:hover input:disabled + span {
    border-color: #bcbcbc;
    background-color: #f0f3f5; }
    .control-mandatory:hover input:disabled + span:before {
      color: #bcbcbc; }

.control-buttons-group {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  white-space: nowrap;
  color: #2b5672;
  position: relative; }
  .control-buttons-group p {
    font-size: 14px;
    color: #2b5672;
    font-weight: 300;
    margin-bottom: 15px;
    margin-top: 0; }
  .control-buttons-group .group-buttons-container-left {
    text-align: left; }
  .control-buttons-group .group-buttons-container-right {
    text-align: right; }
  .control-buttons-group .group-buttons-container-center {
    text-align: center; }
  .control-buttons-group .info-icon {
    display: none;
    position: absolute;
    top: 0;
    right: 0; }
  .control-buttons-group:hover .info-icon {
    display: inline-block; }
  .control-buttons-group input {
    display: none; }
    .control-buttons-group input + span {
      color: #3899ec;
      cursor: pointer;
      height: 36px;
      background-color: #edf7ff;
      display: inline-block;
      position: relative;
      white-space: nowrap;
      line-height: 36px;
      vertical-align: middle;
      font-size: 14px;
      padding: 0 14px;
      max-width: 93px;
      min-width: 74px;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
      box-shadow: 0 2px 0 0 #d4e7fb; }
    .control-buttons-group input:checked + span {
      color: #fff;
      position: relative;
      top: 2px;
      background-color: #3899ec;
      box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.11); }
    .control-buttons-group input:disabled + span {
      cursor: default;
      background-color: #f6f6f6; }
    .control-buttons-group input:disabled:checked + span {
      background-color: #bcbcbc; }
    .control-buttons-group input:disabled:not(:checked) + span {
      color: #bcbcbc;
      box-shadow: 0 2px 0 0 #e5e5e5; }
  .control-buttons-group label {
    display: inline-block; }
    .control-buttons-group label input:not(:checked):not(:disabled) + span:hover {
      background-color: #d3edff; }
    .control-buttons-group label:first-of-type span {
      border-radius: 20px 0 0 20px; }
    .control-buttons-group label:last-of-type span {
      border-radius: 0 20px 20px 0; }
  .animation-panel .control-buttons-group {
    text-align: center; }

.control-tabs-horizontal {
  box-sizing: border-box;
  font-size: 0;
  width: 100%; }
  .tabbed.control-tabs-horizontal {
    height: 59px;
    background-color: #fff; }
  .arrowed.control-tabs-horizontal {
    box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.05); }
  .arrowed-box.control-tabs-horizontal {
    margin: 18px 0;
    width: 240px;
    counter-reset: boxes 0; }
  .control-tabs-horizontal > .container {
    text-align: center;
    cursor: pointer;
    display: inline-block;
    box-sizing: border-box; }
    .tabbed.control-tabs-horizontal > .container:first-child:nth-last-child(1), .tabbed.control-tabs-horizontal > .container:first-child:nth-last-child(1) ~ label, .horizontal.control-tabs-horizontal > .container:first-child:nth-last-child(1), .horizontal.control-tabs-horizontal > .container:first-child:nth-last-child(1) ~ label {
      width: 100%; }
    .tabbed.control-tabs-horizontal > .container:first-child:nth-last-child(2), .tabbed.control-tabs-horizontal > .container:first-child:nth-last-child(2) ~ label, .horizontal.control-tabs-horizontal > .container:first-child:nth-last-child(2), .horizontal.control-tabs-horizontal > .container:first-child:nth-last-child(2) ~ label {
      width: 50%; }
    .tabbed.control-tabs-horizontal > .container:first-child:nth-last-child(3), .tabbed.control-tabs-horizontal > .container:first-child:nth-last-child(3) ~ label, .horizontal.control-tabs-horizontal > .container:first-child:nth-last-child(3), .horizontal.control-tabs-horizontal > .container:first-child:nth-last-child(3) ~ label {
      width: 33.33333%; }
    .tabbed.control-tabs-horizontal > .container:first-child:nth-last-child(4), .tabbed.control-tabs-horizontal > .container:first-child:nth-last-child(4) ~ label, .horizontal.control-tabs-horizontal > .container:first-child:nth-last-child(4), .horizontal.control-tabs-horizontal > .container:first-child:nth-last-child(4) ~ label {
      width: 25%; }
    .tabbed.control-tabs-horizontal > .container:first-child:nth-last-child(5), .tabbed.control-tabs-horizontal > .container:first-child:nth-last-child(5) ~ label, .horizontal.control-tabs-horizontal > .container:first-child:nth-last-child(5), .horizontal.control-tabs-horizontal > .container:first-child:nth-last-child(5) ~ label {
      width: 20%; }
    .vertical.control-tabs-horizontal > .container {
      display: block; }
    .tabbed.control-tabs-horizontal > .container {
      vertical-align: middle; }
    .arrowed.control-tabs-horizontal > .container {
      height: 52px; }
      .arrowed.control-tabs-horizontal > .container:first-child:nth-last-child(1), .arrowed.control-tabs-horizontal > .container:first-child:nth-last-child(1) ~ label {
        width: 100%; }
      .arrowed.control-tabs-horizontal > .container:first-child:nth-last-child(2), .arrowed.control-tabs-horizontal > .container:first-child:nth-last-child(2) ~ label {
        width: 50%; }
      .arrowed.control-tabs-horizontal > .container:first-child:nth-last-child(3), .arrowed.control-tabs-horizontal > .container:first-child:nth-last-child(3) ~ label {
        width: 33.33333%; }
      .arrowed.control-tabs-horizontal > .container:first-child:nth-last-child(4), .arrowed.control-tabs-horizontal > .container:first-child:nth-last-child(4) ~ label {
        width: 25%; }
    .control-tabs-horizontal > .container > .input {
      display: none; }
      .control-tabs-horizontal > .container > .input + .tab-decorator {
        font-size: 14px;
        box-sizing: border-box;
        transition-duration: 0.2s;
        position: relative; }
        .tabbed.control-tabs-horizontal > .container > .input + .tab-decorator {
          height: 47px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding: 0 10px;
          background-color: #eaf7ff;
          border-right: solid 1px #d3edff;
          transition-property: background-color, border-color; }
          .tabbed.control-tabs-horizontal > .container > .input:checked + .tab-decorator {
            background-color: #fff;
            border-color: transparent; }
          .tabbed.control-tabs-horizontal > .container:last-child > .input + .tab-decorator {
            border-right: none; }
        .tabbed.control-tabs-horizontal > .container > .input + .tab-decorator, .arrowed-box.control-tabs-horizontal > .container > .input + .tab-decorator {
          color: #2b5672; }
        .arrowed.control-tabs-horizontal > .container > .input + .tab-decorator, .arrowed-box.control-tabs-horizontal > .container > .input + .tab-decorator {
          display: block; }
        .arrowed-box.control-tabs-horizontal > .container > .input + .tab-decorator {
          transition-property: border-color; }
          .horizontal.arrowed-box.control-tabs-horizontal > .container > .input + .tab-decorator {
            border-bottom: solid 3px #3899ec;
            padding-right: 3px;
            padding-left: 3px;
            height: 84px; }
          .vertical.arrowed-box.control-tabs-horizontal > .container > .input + .tab-decorator {
            border-right: solid 3px #3899ec;
            padding-right: 12px;
            height: calc(42px + 3px * 2);
            width: calc(132px + 12px + 3px); }
            .vertical.arrowed-box.control-tabs-horizontal > .container:last-child > .input + .tab-decorator {
              height: 42px; }
        .arrowed.control-tabs-horizontal > .container > .input + .tab-decorator {
          height: 52px;
          padding: 0 6px;
          border-bottom: solid 3px transparent;
          transition-property: border-color; }
          .arrowed.control-tabs-horizontal > .container:hover > .input + .tab-decorator {
            border-bottom-color: #7fccf7; }
          .arrowed.control-tabs-horizontal > .container > .input:checked + .tab-decorator {
            border-bottom-color: #3899ec;
            color: #2b5672; }
        .tabbed.control-tabs-horizontal > .container > .input + .tab-decorator:hover {
          background-color: #d3edff; }
        .arrowed.control-tabs-horizontal > .container > .input + .tab-decorator:after, .arrowed-box.control-tabs-horizontal > .container > .input + .tab-decorator:after {
          content: '';
          width: 0;
          height: 0;
          border: solid;
          border-color: transparent transparent #3899ec;
          border-width: 0 9px 7px;
          position: absolute;
          bottom: -3px;
          left: calc(50% - 9px);
          opacity: 0;
          transition-property: opacity;
          transition-duration: 0.2s; }
          .arrowed.control-tabs-horizontal > .container > .input:checked + .tab-decorator:after, .arrowed-box.control-tabs-horizontal > .container > .input:checked + .tab-decorator:after {
            opacity: 1; }
        .vertical.control-tabs-horizontal > .container > .input + .tab-decorator:after {
          border-color: transparent transparent transparent #3899ec;
          border-width: 9px 0 9px 7px;
          left: calc(132px + 12px);
          bottom: calc(50% - 3px * 2); }
          .vertical.control-tabs-horizontal:last-child > .container > .input + .tab-decorator:after {
            bottom: calc(50% - 9px); }
        .arrowed.control-tabs-horizontal > .container > .input + .tab-decorator > .text-label {
          display: inline-flex;
          align-items: center;
          height: 49px;
          white-space: normal;
          text-overflow: ellipsis;
          overflow: hidden;
          color: #3899ec;
          transition-property: color;
          transition-duration: 0.2s; }
          .arrowed.control-tabs-horizontal > .container:hover > .input:not(:checked) + .tab-decorator > .text-label {
            color: #2b5672; }
        .arrowed-box.control-tabs-horizontal > .container > .input + .tab-decorator > .text-label {
          background-color: #eaf7ff;
          display: inline-block;
          width: 100%;
          vertical-align: middle;
          text-align: center;
          transition-property: background-color;
          transition-duration: 0.2s; }
          .horizontal.arrowed-box.control-tabs-horizontal > .container > .input + .tab-decorator > .text-label {
            height: 48px; }
          .vertical.arrowed-box.control-tabs-horizontal > .container > .input + .tab-decorator > .text-label {
            height: 42px; }
          .arrowed-box.control-tabs-horizontal > .container:first-child > .input + .tab-decorator > .text-label {
            border-top-left-radius: 6px; }
            .horizontal.arrowed-box.control-tabs-horizontal > .container:first-child > .input + .tab-decorator > .text-label {
              border-bottom-left-radius: 6px; }
            .vertical.arrowed-box.control-tabs-horizontal > .container:first-child > .input + .tab-decorator > .text-label {
              border-top-right-radius: 6px; }
          .arrowed-box.control-tabs-horizontal > .container:last-child > .input + .tab-decorator > .text-label {
            border-bottom-right-radius: 6px; }
            .horizontal.arrowed-box.control-tabs-horizontal > .container:last-child > .input + .tab-decorator > .text-label {
              border-top-right-radius: 6px; }
            .vertical.arrowed-box.control-tabs-horizontal > .container:last-child > .input + .tab-decorator > .text-label {
              border-bottom-left-radius: 6px; }
          .arrowed-box.control-tabs-horizontal > .container > .input:checked + .tab-decorator > .text-label {
            background-color: #3899ec; }
          .arrowed-box.control-tabs-horizontal > .container:hover > .input:not(:checked) + .tab-decorator > .text-label {
            background-color: #d3edff; }
        .tabbed.control-tabs-horizontal > .container:last-child > .input + .tab-decorator > .text-label {
          border-right: none; }
        .arrowed-box.control-tabs-horizontal > .container > .input + .tab-decorator > .text-label:after {
          content: counter(boxes, decimal);
          counter-increment: boxes 1;
          position: absolute;
          bottom: 15px;
          font-size: 12px;
          color: #3899ec;
          text-align: center;
          left: calc(50% - 3px); }
        .vertical.control-tabs-horizontal > .container > .input + .tab-decorator > .text-label:after {
          left: calc(132px + 12px + 3px + 7px + 7px);
          bottom: calc(50% - 3px * 2); }

.control-tabs-vertical {
  display: block;
  font-size: 0;
  width: 48px;
  height: 100%;
  background-color: #eaf7ff;
  box-shadow: inset -1px 0 0 0 #e5f4ff, inset -2px 0 0 0 #d3edff; }
  .control-tabs-vertical .tab-text {
    display: none; }
  .control-tabs-vertical.closed {
    width: 288px;
    background-color: transparent;
    box-shadow: none; }
    .control-tabs-vertical.closed svg {
      left: 17px; }
    .control-tabs-vertical.closed .tab-text {
      display: inline-block; }
  .control-tabs-vertical label {
    cursor: pointer;
    display: block; }
  .control-tabs-vertical input {
    display: none; }
    .control-tabs-vertical input + span {
      display: inline-block;
      width: 100%;
      height: 48px;
      position: relative;
      line-height: 48px; }
      .control-tabs-vertical input + span svg {
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
        position: absolute;
        top: calc(50% - 7px);
        left: calc(50% - 7px);
        fill: #3899ec; }
    .control-tabs-vertical input:checked + span {
      background-color: #ffffff;
      border-top: 1px solid #d3edff;
      border-bottom: 1px solid #d3edff; }
      .control-tabs-vertical input:checked + span svg {
        fill: #162d3d; }
    .control-tabs-vertical input:not(:checked) + span {
      box-shadow: inset -1px 0 0 0 #e5f4ff, inset -2px 0 0 0 #d3edff; }
      .control-tabs-vertical input:not(:checked) + span:hover {
        background-color: #d3edff; }
  .control-tabs-vertical label:first-child input:checked + span {
    border-top: none;
    border-bottom: 1px solid #d3edff; }
  .control-tabs-vertical label:last-child input:checked + span {
    border-bottom: none; }

.control-link div {
  width: 29px;
  height: 26px;
  border-radius: 6px;
  background-color: transparent;
  border: solid 1px #d6e1e8;
  display: block;
  box-sizing: border-box;
  text-align: center;
  transition-duration: 0.2s;
  transition-property: background-color, border-color; }

.control-link:not(.disabled) div {
  cursor: pointer; }
  .control-link:not(.disabled) div:hover {
    background-color: #d3edff;
    border-color: #d3edff; }

.control-link path {
  fill: #7a92a5;
  transition-duration: 0.2s;
  transition-property: fill; }

.control-link input {
  display: none; }
  .control-link input:checked + div {
    background-color: #3899ec;
    border-color: transparent; }
    .control-link input:checked + div path {
      fill: #fff; }
    .control-link input:checked + div:hover {
      background-color: #4eb7f5; }

.info-icon {
  cursor: pointer;
  border-radius: 50%;
  display: inline-block;
  width: 18px;
  height: 18px; }
  .label-info-icon-container.info-icon {
    background-color: #2b5672;
    cursor: pointer;
    border-radius: 0;
    width: 30px;
    height: 16px;
    padding-top: 2px;
    box-sizing: border-box; }
  .accordion-header .info-icon {
    position: absolute;
    top: 12px;
    right: 12px;
    display: none; }
    .accordion-header:hover .info-icon {
      display: inline-block; }
  .dropdown .info-icon {
    vertical-align: middle;
    margin-bottom: 3px;
    line-height: 0;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.15s; }
    .dropdown .info-icon,
    .dropdown .info-icon > .symbol {
      margin: 0;
      padding: 0; }
  .dropdown:hover .info-icon {
    opacity: 1; }
  .control-checkbox .info-icon {
    display: none;
    position: absolute;
    top: 0;
    right: 0; }
    .control-checkbox:hover .info-icon {
      display: inline-block; }
  .control-text-input .info-icon {
    display: none;
    position: absolute;
    top: 0;
    right: 0; }
    .control-text-input:hover .info-icon {
      display: inline-block; }
  .preset-item .info-icon {
    position: absolute;
    bottom: 21px;
    right: 16px; }
    .preset-item:hover .info-icon {
      opacity: 1; }
      .preset-item:hover .info-icon:hover {
        color: #fff; }
    .preset-item .info-icon svg circle {
      fill: #fff; }
  .preset-item .info-wrapper.has-label .info-icon {
    bottom: 26px; }
  .section-header > .title-line .info-icon {
    position: absolute;
    bottom: 6px;
    right: 1px;
    display: none; }
  .site-members-settings .info-icon {
    display: none; }
    .site-members-settings:hover .info-icon {
      display: inline-block; }
  .control-section-divider.labeled .info-icon {
    position: absolute;
    right: 12px;
    top: 12px;
    line-height: 0;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.15s; }
  .control-section-divider.labeled:hover .info-icon {
    opacity: 1; }

.control-alignment {
  text-align: center; }
  .control-alignment .cls-4 {
    fill: #3899ec; }
  .control-alignment .cls-5 {
    fill: #fff; }
  .control-alignment div.label {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    color: #2b5672;
    font-weight: 300;
    text-align: left;
    padding-bottom: 15px; }
  .control-alignment div.items {
    display: inline-block;
    width: 90px;
    height: 90px; }
    .control-alignment div.items input[type="radio"] {
      display: none; }
    .control-alignment div.items label.item {
      cursor: pointer;
      width: 24px;
      height: 24px;
      margin: 3px;
      float: left; }
      .control-alignment div.items label.item span {
        display: inline-block;
        width: 100%;
        height: 100%;
        border-radius: 6px;
        background: #d3edff no-repeat center; }
        .control-alignment div.items label.item span:hover {
          background-color: #7fccf7; }
          .control-alignment div.items label.item span:hover svg {
            visibility: visible; }
        .control-alignment div.items label.item span svg {
          visibility: hidden;
          shape-rendering: crispEdges; }
      .control-alignment div.items label.item input[type="radio"]:checked + span {
        background-color: #3899ec; }
        .control-alignment div.items label.item input[type="radio"]:checked + span svg {
          visibility: visible; }

.playIcon {
  width: 0;
  height: 0;
  margin-top: 50%;
  margin-left: 50%;
  top: -4px;
  left: -2px;
  position: relative;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid #3899ec; }

.playButton {
  width: 22px;
  height: 22px;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  background-color: #ffffff;
  border: 1px solid #3899ec; }
  .playButton:hover {
    background-color: #edf7ff; }
  .playButton.isPlaying {
    background-color: #3899ec; }
    .playButton.isPlaying .playIcon {
      border-left-color: #ffffff; }

.thumbnail-preview-play {
  position: relative; }
  .thumbnail-preview-play svg {
    position: absolute;
    left: 18px;
    width: 49px;
    height: 24px;
    top: 50%;
    margin-top: -11px;
    text-align: center; }
  .thumbnail-preview-play .label {
    margin-left: 78px;
    margin-right: 48px;
    padding: 0;
    position: relative;
    color: #395671;
    font-size: 14px;
    font-weight: 300; }
  .thumbnail-preview-play .playButton {
    position: absolute;
    right: 18px;
    top: 50%;
    margin-top: -11px; }

.pick-text-alignment .svg-radio {
  border-radius: 6px;
  background-color: #eaf7fe;
  width: 38px;
  height: 38px;
  fill: #7fccf7;
  padding: 8px; }

.pick-text-alignment input:checked + .svg-radio {
  background-color: #4e99ec;
  fill: #d3edfe; }

.pick-text-alignment .control-thumbnails:not(.with-title) {
  padding-top: 25px; }

hr.divider-long, hr.divider-short {
  background-color: #d9e1e8;
  border: 0;
  height: 1px;
  margin: 0; }

hr.divider-short {
  margin: 0 24px; }

.rich-text-paragraph {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  .rich-text-paragraph .rich-text-paragraph-title {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 18px;
    color: #2b5672;
    font-weight: 300;
    text-align: left;
    margin: 10px 0 0; }
  .rich-text-paragraph .rich-text-paragraph-content {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    color: #2b5672;
    font-weight: 300;
    line-height: 21px;
    text-align: left;
    margin: 0; }
    .rich-text-paragraph .rich-text-paragraph-content a {
      color: #3899ec;
      text-decoration: none; }
      .rich-text-paragraph .rich-text-paragraph-content a:hover {
        cursor: pointer;
        text-decoration: underline; }
    .rich-text-paragraph .rich-text-paragraph-content b,
    .rich-text-paragraph .rich-text-paragraph-content strong {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      color: #2b5672;
      font-weight: normal; }

.listItemWithImage {
  height: 90px;
  cursor: pointer;
  padding: 13px 24px;
  background-color: #ffffff;
  display: flex; }
  .listItemWithImage:hover {
    background-color: #eaf7ff; }
    .disabled.listItemWithImage:hover {
      background-color: inherit;
      cursor: default; }
  .listItemWithImage .image {
    display: inline-block;
    width: 90px;
    height: 90px; }
  .listItemWithImage .content {
    vertical-align: top;
    display: inline-block;
    width: 148px;
    margin: 9px 10px 0 16px;
    flex: 1; }
    .listItemWithImage .content .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis; }
    .listItemWithImage .content .title {
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 15px;
      color: #162d3d;
      margin-bottom: 6px; }
      .disabled.listItemWithImage .content .title {
        color: #7a7a7a; }
    .listItemWithImage .content .desc {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      color: #2b5672; }
      .disabled.listItemWithImage .content .desc {
        color: #939393; }
  .listItemWithImage .indicator {
    display: flex;
    align-items: center; }

.listItem {
  height: 90px;
  cursor: pointer;
  padding: 13px 24px;
  background-color: #ffffff;
  display: flex; }
  .listItem:hover {
    background-color: #eaf7ff; }
  .listItem .image {
    display: inline-block;
    width: 90px;
    height: 90px; }
  .listItem .content {
    width: 148px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 9px 10px 0 14px; }
    .listItem .content.disabled {
      cursor: default; }
    .listItem .content .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis; }
    .listItem .content .title {
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 15px;
      color: #162d3d;
      margin: 0;
      height: 34px;
      line-height: 34px;
      flex-shrink: 0; }
    .listItem .content .desc {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      color: #2b5672;
      line-height: initial; }
  .listItem .expandSymbol {
    width: 28px;
    height: 28px; }

i.arrow-button {
  border: 2px solid #3899ec;
  border-radius: 50%;
  display: inline-block;
  height: 21px;
  width: 21px;
  opacity: 0.67;
  cursor: pointer; }
  i.arrow-button svg {
    fill: #3899ec;
    padding: 5px;
    vertical-align: middle; }
  i.arrow-button:hover {
    background-color: #3899ec; }
    i.arrow-button:hover svg {
      fill: #fff; }
  i.arrow-button.without-border {
    border: none;
    width: 9px;
    height: 9px;
    opacity: 1; }
    i.arrow-button.without-border svg {
      width: 9px;
      padding: 0; }
    i.arrow-button.without-border:hover {
      background-color: transparent; }
      i.arrow-button.without-border:hover svg {
        fill: #3899ec; }

:not(.composite-toggle-switch) > .control-boolean {
  overflow: hidden; }
  :not(.composite-toggle-switch) > .control-boolean input {
    display: none; }
    :not(.composite-toggle-switch) > .control-boolean input ~ svg,
    :not(.composite-toggle-switch) > .control-boolean input ~ .label {
      cursor: pointer; }
    :not(.composite-toggle-switch) > .control-boolean input:disabled ~ svg,
    :not(.composite-toggle-switch) > .control-boolean input:disabled ~ .label {
      cursor: default; }
  :not(.composite-toggle-switch) > .control-boolean span.label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: inherit;
    display: inline-block;
    vertical-align: middle; }
  :not(.composite-toggle-switch) > .control-boolean.medium-scale svg {
    transform: scale(0.8);
    margin-bottom: -3px;
    vertical-align: middle;
    padding: 1px; }
  :not(.composite-toggle-switch) > .control-boolean.no-scale svg {
    transform: scale(1);
    vertical-align: middle;
    padding: 1px; }

.control-boolean.toggle-button {
  width: 32px;
  height: 32px;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  .control-boolean.toggle-button:hover {
    background-color: #eaf7ff; }
  .control-boolean.toggle-button:active, .control-boolean.toggle-button.checked {
    background-color: #3899ec; }
    .control-boolean.toggle-button:active:hover, .control-boolean.toggle-button.checked:hover {
      background-color: #4eb7f5; }
  .control-boolean.toggle-button[disabled] {
    background-color: #fff; }
    .control-boolean.toggle-button[disabled]:hover {
      background-color: #fff; }

.radio-control {
  cursor: pointer; }
  .radio-control input {
    display: none; }
  .radio-control span.label {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.circle-preloader {
  width: 15px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
  overflow: hidden;
  transform-origin: 100% 50%;
  animation: semi-rotate 1s linear infinite; }
  .wix-code-file-tree .circle-preloader {
    width: 10px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px; }
  .circle-preloader::before {
    content: '';
    top: 0;
    left: 0;
    right: -100%;
    bottom: 0;
    border: 3px solid currentColor;
    border-color: currentColor transparent transparent currentColor;
    border-radius: 50%;
    position: absolute;
    transform: rotate(-45deg);
    animation: inner-rotate 0.5s linear infinite alternate;
    color: #7fccf7; }
  .circle-preloader::after {
    content: '';
    top: 0;
    left: 0;
    right: -100%;
    bottom: 0;
    border: 3px solid currentColor;
    border-color: currentColor transparent transparent currentColor;
    border-radius: 50%;
    position: absolute;
    transform: rotate(-45deg);
    animation: inner-rotate 0.5s linear infinite alternate;
    color: #3899ec;
    opacity: 0; }
  .circle-preloader.white::before {
    color: #f0f0f0; }
  .circle-preloader.white::after {
    color: #f0f0f0; }
  .install-preloader .circle-preloader {
    position: relative; }

@keyframes inner-rotate {
  to {
    transform: rotate(115deg);
    opacity: 1; } }

@keyframes semi-rotate {
  from {
    transform: rotate(180deg);
    animation-timing-function: ease-out; }
  45% {
    transform: rotate(198deg); }
  55% {
    transform: rotate(234deg); }
  to {
    transform: rotate(540deg); } }

.video-container {
  border-radius: 6px;
  height: 174px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  width: 310px;
  z-index: 0; }
  .video-container * {
    border: none;
    margin: 0;
    outline: none;
    padding: 0; }
  .video-container > video {
    object-fit: cover;
    width: 100%; }
  .video-container > .controls {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
    .video-container > .controls[data-state="stopped"] > footer > .actions > .play-pause, .video-container > .controls[data-state="paused"] > footer > .actions > .play-pause {
      border-left: 8px solid #ffffff;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      height: 0;
      width: 0; }
    .video-container > .controls[data-state="playing"] > .main-action,
    .video-container > .controls[data-state="playing"] > footer {
      box-shadow: 0 0 10px 5px rgba(22, 45, 61, 0.2);
      opacity: 0; }
    .video-container > .controls[data-state="playing"]:hover > footer {
      opacity: 1; }
    .video-container > .controls[data-state="playing"] > footer > .actions > .play-pause {
      height: 10px;
      border-left: 3px solid #ffffff;
      border-right: 3px solid #ffffff;
      width: 9px; }
    .video-container > .controls > .main-action,
    .video-container > .controls > footer {
      transition-duration: 0.3s;
      transition-property: opacity, box-shadow;
      will-change: opacity, box-shadow; }
    .video-container > .controls > .main-action {
      background: rgba(22, 45, 61, 0.2);
      flex-grow: 1;
      position: relative; }
      .video-container > .controls > .main-action > .play-big {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        transition-duration: 0.3s;
        transition-property: opacity;
        will-change: opacity;
        opacity: 0.7;
        border: 2px solid #ffffff;
        border-radius: 50%;
        background: rgba(22, 45, 61, 0.2);
        height: 80px;
        width: 80px; }
        .video-container > .controls > .main-action > .play-big:hover {
          opacity: 1; }
        .video-container > .controls > .main-action > .play-big:after {
          border-left: 30px solid #ffffff;
          border-top: 20px solid transparent;
          border-bottom: 20px solid transparent;
          height: 0;
          width: 0;
          content: '';
          display: inline-block;
          left: 4px;
          position: relative; }
    .video-container > .controls > footer {
      background: rgba(22, 45, 61, 0.2);
      box-sizing: border-box;
      height: 30px;
      padding: 0 18px;
      width: 100%; }
      .video-container > .controls > footer > .progress-bar {
        transition-duration: 0.3s;
        transition-property: opacity;
        will-change: opacity;
        opacity: 0.7;
        cursor: pointer;
        height: 10px;
        overflow: hidden;
        position: relative; }
        .video-container > .controls > footer > .progress-bar:hover {
          opacity: 1; }
        .video-container > .controls > footer > .progress-bar:before {
          top: 50%;
          transform: translateY(-50%);
          position: absolute;
          background: #ffffff;
          content: '';
          display: block;
          height: 2px;
          width: 100%; }
        .video-container > .controls > footer > .progress-bar:hover > .progress,
        .video-container > .controls > footer > .progress-bar:hover > .progress:after {
          box-shadow: 0 0 2px 0 #3899ec; }
        .video-container > .controls > footer > .progress-bar > .progress {
          top: 50%;
          transform: translateY(-50%);
          position: absolute;
          transition-duration: 0.3s;
          transition-property: box-shadow;
          will-change: box-shadow;
          background: #3899ec;
          height: 2px;
          width: 0;
          display: block; }
          .video-container > .controls > footer > .progress-bar > .progress:after {
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            transition-duration: 0.3s;
            transition-property: box-shadow;
            will-change: box-shadow;
            background: inherit;
            border-radius: 50%;
            content: '';
            display: block;
            height: 8px;
            right: 0;
            width: 8px; }
      .video-container > .controls > footer > .actions {
        align-items: center;
        display: flex;
        height: calc(100% - 14px); }
        .video-container > .controls > footer > .actions button {
          transition-duration: 0.3s;
          transition-property: opacity;
          will-change: opacity;
          opacity: 0.7;
          background: none; }
          .video-container > .controls > footer > .actions button:hover {
            opacity: 1; }
        .video-container > .controls > footer > .actions > .play-pause {
          margin-right: 10px; }
        .video-container > .controls > footer > .actions > .stop {
          background: #ffffff;
          height: 10px;
          width: 10px; }
        .video-container > .controls > footer > .actions > .fullscreen {
          border: 2px solid #ffffff;
          height: 10px;
          margin-left: auto;
          width: 16px; }
  .wix-store-switch-panel .video-container {
    margin-bottom: 60px; }

.background-video-patterns-control .class-v-overlay-pat-0-icon,
.background-video-patterns-control .control-v-overlay-pat-0-icon .class-v-overlay-pat-0-icon {
  width: 51px;
  height: 51px;
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-0-icon.png");
  background-color: #eaf7ff;
  border-radius: 8px;
  box-shadow: 0 0 0 2px #ffffff; }

.background-video-patterns-control .control-v-overlay-pat-0-icon:hover .class-v-overlay-pat-0-icon {
  border-radius: 8px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #7fccf7; }

.background-video-patterns-control .selected .class-v-overlay-pat-0-icon,
.background-video-patterns-control .control-v-overlay-pat-0-icon .input-v-overlay-pat-0-icon:checked ~ .class-v-overlay-pat-0-icon {
  border-radius: 8px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #3899ec; }

.background-video-patterns-control .class-v-overlay-pat-1-icon,
.background-video-patterns-control .control-v-overlay-pat-1-icon .class-v-overlay-pat-1-icon {
  width: 51px;
  height: 51px;
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-1-icon.png");
  background-color: #eaf7ff;
  border-radius: 8px;
  box-shadow: 0 0 0 2px #ffffff; }

.background-video-patterns-control .control-v-overlay-pat-1-icon:hover .class-v-overlay-pat-1-icon {
  border-radius: 8px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #7fccf7; }

.background-video-patterns-control .selected .class-v-overlay-pat-1-icon,
.background-video-patterns-control .control-v-overlay-pat-1-icon .input-v-overlay-pat-1-icon:checked ~ .class-v-overlay-pat-1-icon {
  border-radius: 8px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #3899ec; }

.background-video-patterns-control .class-v-overlay-pat-2-icon,
.background-video-patterns-control .control-v-overlay-pat-2-icon .class-v-overlay-pat-2-icon {
  width: 51px;
  height: 51px;
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-2-icon.png");
  background-color: #eaf7ff;
  border-radius: 8px;
  box-shadow: 0 0 0 2px #ffffff; }

.background-video-patterns-control .control-v-overlay-pat-2-icon:hover .class-v-overlay-pat-2-icon {
  border-radius: 8px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #7fccf7; }

.background-video-patterns-control .selected .class-v-overlay-pat-2-icon,
.background-video-patterns-control .control-v-overlay-pat-2-icon .input-v-overlay-pat-2-icon:checked ~ .class-v-overlay-pat-2-icon {
  border-radius: 8px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #3899ec; }

.background-video-patterns-control .class-v-overlay-pat-3-icon,
.background-video-patterns-control .control-v-overlay-pat-3-icon .class-v-overlay-pat-3-icon {
  width: 51px;
  height: 51px;
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-3-icon.png");
  background-color: #eaf7ff;
  border-radius: 8px;
  box-shadow: 0 0 0 2px #ffffff; }

.background-video-patterns-control .control-v-overlay-pat-3-icon:hover .class-v-overlay-pat-3-icon {
  border-radius: 8px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #7fccf7; }

.background-video-patterns-control .selected .class-v-overlay-pat-3-icon,
.background-video-patterns-control .control-v-overlay-pat-3-icon .input-v-overlay-pat-3-icon:checked ~ .class-v-overlay-pat-3-icon {
  border-radius: 8px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #3899ec; }

.background-video-patterns-control .class-v-overlay-pat-4-icon,
.background-video-patterns-control .control-v-overlay-pat-4-icon .class-v-overlay-pat-4-icon {
  width: 51px;
  height: 51px;
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-4-icon.png");
  background-color: #eaf7ff;
  border-radius: 8px;
  box-shadow: 0 0 0 2px #ffffff; }

.background-video-patterns-control .control-v-overlay-pat-4-icon:hover .class-v-overlay-pat-4-icon {
  border-radius: 8px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #7fccf7; }

.background-video-patterns-control .selected .class-v-overlay-pat-4-icon,
.background-video-patterns-control .control-v-overlay-pat-4-icon .input-v-overlay-pat-4-icon:checked ~ .class-v-overlay-pat-4-icon {
  border-radius: 8px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #3899ec; }

.background-video-patterns-control .class-v-overlay-pat-5-icon,
.background-video-patterns-control .control-v-overlay-pat-5-icon .class-v-overlay-pat-5-icon {
  width: 51px;
  height: 51px;
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-5-icon.png");
  background-color: #eaf7ff;
  border-radius: 8px;
  box-shadow: 0 0 0 2px #ffffff; }

.background-video-patterns-control .control-v-overlay-pat-5-icon:hover .class-v-overlay-pat-5-icon {
  border-radius: 8px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #7fccf7; }

.background-video-patterns-control .selected .class-v-overlay-pat-5-icon,
.background-video-patterns-control .control-v-overlay-pat-5-icon .input-v-overlay-pat-5-icon:checked ~ .class-v-overlay-pat-5-icon {
  border-radius: 8px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #3899ec; }

.background-video-patterns-control .class-v-overlay-pat-6-icon,
.background-video-patterns-control .control-v-overlay-pat-6-icon .class-v-overlay-pat-6-icon {
  width: 51px;
  height: 51px;
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-6-icon.png");
  background-color: #eaf7ff;
  border-radius: 8px;
  box-shadow: 0 0 0 2px #ffffff; }

.background-video-patterns-control .control-v-overlay-pat-6-icon:hover .class-v-overlay-pat-6-icon {
  border-radius: 8px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #7fccf7; }

.background-video-patterns-control .selected .class-v-overlay-pat-6-icon,
.background-video-patterns-control .control-v-overlay-pat-6-icon .input-v-overlay-pat-6-icon:checked ~ .class-v-overlay-pat-6-icon {
  border-radius: 8px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #3899ec; }

.background-video-patterns-control .class-v-overlay-pat-7-icon,
.background-video-patterns-control .control-v-overlay-pat-7-icon .class-v-overlay-pat-7-icon {
  width: 51px;
  height: 51px;
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/backgroundPanel/overlayPatternIcons/v-overlay-pat-7-icon.png");
  background-color: #eaf7ff;
  border-radius: 8px;
  box-shadow: 0 0 0 2px #ffffff; }

.background-video-patterns-control .control-v-overlay-pat-7-icon:hover .class-v-overlay-pat-7-icon {
  border-radius: 8px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #7fccf7; }

.background-video-patterns-control .selected .class-v-overlay-pat-7-icon,
.background-video-patterns-control .control-v-overlay-pat-7-icon .input-v-overlay-pat-7-icon:checked ~ .class-v-overlay-pat-7-icon {
  border-radius: 8px;
  box-shadow: 0 0 0 0 #ffffff, 0 0 0 2px #3899ec; }

.background-video-patterns-control .control-buttons-group {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 9px;
  padding-bottom: 24px;
  text-align: center; }
  .background-video-patterns-control .control-buttons-group input + span {
    max-width: 70px;
    max-height: 34px;
    line-height: 34px; }

.control-dock {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 0;
  position: relative; }
  .control-dock:hover .info-icon {
    opacity: 1; }
  .control-dock .info-icon {
    float: right;
    opacity: 0; }
  .control-dock .dock-label {
    font-size: 14px;
    left: 25px;
    margin-bottom: 20px;
    display: block;
    color: #2b5672;
    margin-top: 20px; }
  .control-dock .dock-corners-wrapper {
    position: relative;
    margin: 0 auto;
    width: 185px; }
  .control-dock label {
    font-size: 0;
    margin: 0;
    display: inline-block; }
    .control-dock label input {
      display: none; }
      .control-dock label input:checked + .top,
      .control-dock label input:checked + .right,
      .control-dock label input:checked + .bottom,
      .control-dock label input:checked + .left {
        border-color: #3899ec;
        background: #d3edff; }
      .control-dock label input:checked + .middle {
        background: #3899ec; }
    .control-dock label .square {
      cursor: pointer;
      box-sizing: border-box;
      background: #eaf7ff;
      margin: 0;
      padding: 0;
      width: 61px;
      height: 43px;
      display: block; }
      .control-dock label .square.top {
        margin-bottom: 1px;
        margin-right: 1px;
        border-top: 12px solid #b1ddf8; }
        .control-dock label .square.top:hover {
          border-top-color: #4eb7f5; }
        .control-dock label .square.top.left {
          border-top-left-radius: 5px; }
        .control-dock label .square.top.right {
          border-top-right-radius: 5px; }
      .control-dock label .square.left {
        border-left: 12px solid #b1ddf8;
        margin-left: 0; }
        .control-dock label .square.left:hover {
          border-left-color: #4eb7f5; }
      .control-dock label .square.middle {
        margin-left: 1px;
        margin-right: 1px; }
        .control-dock label .square.middle:hover {
          background: #4eb7f5; }
      .control-dock label .square.right {
        border-right: 12px solid #b1ddf8;
        margin-right: 0; }
        .control-dock label .square.right:hover {
          border-right-color: #4eb7f5; }
      .control-dock label .square.bottom {
        margin-top: 1px;
        margin-right: 1px;
        border-bottom: 12px solid #b1ddf8; }
        .control-dock label .square.bottom:hover {
          border-bottom-color: #4eb7f5; }
        .control-dock label .square.bottom.left {
          border-bottom-left-radius: 5px; }
        .control-dock label .square.bottom.right {
          border-bottom-right-radius: 5px;
          margin-right: 0; }
    .control-dock label.disabled .square {
      background: #f0f0f0;
      border-color: #e3e3e3; }
      .control-dock label.disabled .square:hover {
        background: #f0f0f0;
        border-color: #e3e3e3; }
      .control-dock label.disabled .square.middle {
        background: #ffffff; }
        .control-dock label.disabled .square.middle:hover {
          background: #ffffff; }
  .control-dock .dashed {
    position: absolute;
    box-sizing: border-box;
    pointer-events: none; }
    .control-dock .dashed.horizontal {
      border-top: 1px dotted #7fccf7;
      border-bottom: 1px dotted #7fccf7;
      height: 45px;
      top: 43px;
      left: 12px;
      right: 12px; }
    .control-dock .dashed.vertical {
      border-left: 1px dotted #7fccf7;
      border-right: 1px dotted #7fccf7;
      width: 63px;
      top: 12px;
      bottom: 12px;
      left: 61px; }

.drag-area-control {
  min-height: 100px; }
  .drag-area-control.with-border {
    border-radius: 14px;
    border: 2px dashed #d3edff;
    margin: 12px; }
    .fonts-upload-panel .drag-area-control.with-border {
      min-height: 299px; }
    .drag-area-control.with-border.dragging {
      border: 2px dashed #7fccf7; }
  .fonts-upload-panel .drag-area-control {
    min-height: 327px; }

.splitter {
  display: flex; }

.splitter-divider {
  z-index: 1; }
  .splitter-divider-horizontal {
    width: 100%;
    height: 10px;
    margin: -5px 0;
    cursor: row-resize; }
  .splitter-divider-vertical {
    width: 10px;
    height: 100%;
    margin: 0 -5px;
    cursor: col-resize; }

.ide-splitter .splitter-divider-horizontal {
  height: 18px;
  margin: -6px 0 -12px 0; }

.control-accordion {
  position: relative; }
  .control-accordion > .accordion-header {
    background-color: #fff; }
    .control-accordion > .accordion-header:hover {
      background-color: #eaf7ff; }
    .control-accordion > .accordion-header > .selected-indicator {
      margin-left: 12px;
      vertical-align: middle; }
    .control-accordion > .accordion-header > .control-label {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      color: #162d3d;
      font-weight: 500;
      text-align: left;
      padding: 19px 0 18px 0;
      display: inline-block;
      line-height: 13px; }
    .control-accordion > .accordion-header > .accordion-icon {
      margin: 19px 15px 18px 24px; }
      .control-accordion > .accordion-header > .accordion-icon.open {
        margin: 22px 12px 20px 24px; }

.control-text-input {
  position: relative;
  text-align: left;
  display: inline-block;
  width: 100%; }
  .control-text-input > .text-input-label {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    font-weight: 300;
    color: #2b5672;
    text-align: left;
    display: block;
    overflow: hidden;
    line-height: 18px;
    max-height: 34px;
    padding: 7px 30px 6px 12px; }
    .is-disabled.control-text-input > .text-input-label {
      color: #939393; }
    .developer-toolbar .control-text-input > .text-input-label {
      padding: 10px 5px 10px 1px; }
    .wix-code-find-replace .control-text-input > .text-input-label {
      padding-top: 6px; }
  .success.control-text-input > .input-container .input :not(:focus), .error.control-text-input > .input-container .input :not(:focus), .warning.control-text-input > .input-container .input :not(:focus) {
    width: calc(100% - 43px);
    padding-right: 0; }
  .instant-error.control-text-input > .input-container .input:focus {
    border: 1px solid #ee5951; }
  .color-format .control-text-input > .input-container .input {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    padding-left: 62px;
    height: 24px;
    line-height: 24px;
    width: 100%; }
  .developer-toolbar .control-text-input > .input-container .input {
    width: 100%;
    height: 24px;
    font-size: 14px;
    font-weight: 700; }
  .wix-code-find-replace .control-text-input > .input-container .input {
    height: 22px;
    font-size: 14px;
    color: #2b5672;
    border: 1px solid #4eb7f5;
    box-shadow: inset 0 0 3px 0 rgba(0, 90, 132, 0.25); }
    .wix-code-find-replace:hover .control-text-input > .input-container .input {
      background-color: transparent;
      cursor: text; }
    .wix-code-find-replace .control-text-input > .input-container .input:hover {
      background-color: transparent;
      cursor: text; }
  .control-text-input > .input-container .developer-toolbar {
    flex: 1; }
  .color-format .control-text-input > .input-container {
    display: block; }
  .wix-code-find-replace .control-text-input > .input-container {
    width: 190px; }
  .control-text-input .validation-icon {
    position: absolute;
    right: 12px;
    top: 9px;
    cursor: pointer;
    visibility: hidden;
    font-size: 0; }
    .error.control-text-input:not(.focused) .validation-icon.validation-icon-error {
      visibility: visible; }
    .success.control-text-input:not(.focused) .validation-icon.validation-icon-success {
      visibility: visible; }
    .warning.control-text-input:not(.focused) .validation-icon.validation-icon-warning {
      visibility: visible; }
    .color-format .control-text-input .validation-icon {
      top: 0; }
    .wix-code-find-replace .control-text-input .validation-icon {
      display: none; }
  .control-text-input .validation-icon-wrapper {
    position: absolute;
    top: -3px;
    right: 2px; }
    .has-label.control-text-input .validation-icon-wrapper {
      top: 37px;
      right: 25px; }
      .has-label.control-text-input .validation-icon-wrapper .validation-icon {
        top: 0;
        right: -11px; }
      .developer-toolbar .has-label.control-text-input .validation-icon-wrapper {
        top: 9px; }
  .color-format .control-text-input:before {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    content: "#";
    position: absolute;
    left: 55px;
    top: 4px; }
  .developer-toolbar .control-text-input {
    display: flex;
    align-items: center; }
  .wix-code-file-tree .control-text-input > .input-container > .input {
    height: 22px; }
  .wix-code-pages-tree .control-text-input > .input-container > .input {
    height: 22px; }

.selective-text-input {
  position: relative;
  display: flex;
  align-items: center; }
  .selective-text-input .textInput {
    margin-left: 16px;
    margin-right: 80px;
    padding: 0;
    width: auto; }
    .selective-text-input .textInput > input {
      font-size: 16px;
      line-height: 16px; }
  .selective-text-input .control-mandatory {
    display: flex;
    align-items: center;
    margin-left: 11px; }

.domain-input-wrapper, .domain-input-with-buttons .address-part {
  position: relative;
  height: 36px;
  color: #7a92a5;
  background-color: #d9e1e8;
  display: flex;
  cursor: default;
  border-radius: 4px; }
  .theme-blue.domain-input-wrapper, .domain-input-with-buttons .theme-blue.address-part {
    background-color: #d3edff; }
  .domain-input-wrapper .mini-toolbar, .domain-input-with-buttons .address-part .mini-toolbar {
    display: inline-block;
    width: 32px;
    line-height: 36px;
    margin-left: 10px; }
    .domain-input-wrapper .mini-toolbar svg, .domain-input-with-buttons .address-part .mini-toolbar svg {
      width: 32px;
      height: 12px; }
      .theme-blue.domain-input-wrapper .mini-toolbar svg > .c1, .domain-input-with-buttons .theme-blue.address-part .mini-toolbar svg > .c1 {
        fill: #7fccf7; }
  .domain-input-wrapper .full-domain-wrapper, .domain-input-with-buttons .address-part .full-domain-wrapper {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    margin-top: 5px;
    height: 26px;
    line-height: 26px;
    margin-left: 8px;
    margin-right: 6px; }
    .domain-input-wrapper .full-domain-wrapper > .domain-prefix, .domain-input-with-buttons .address-part .full-domain-wrapper > .domain-prefix {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px;
      position: relative;
      flex: 0 0 auto;
      line-height: 26px;
      height: 26px;
      padding: 0 0 0 10px;
      border-radius: 4px 0 0 4px;
      background-color: #fff;
      color: #7a92a5;
      cursor: default; }
      .theme-blue.domain-input-wrapper .full-domain-wrapper > .domain-prefix, .domain-input-with-buttons .theme-blue.address-part .full-domain-wrapper > .domain-prefix {
        color: #7fccf7; }
      .domain-input-wrapper .full-domain-wrapper > .domain-prefix::after, .domain-input-with-buttons .address-part .full-domain-wrapper > .domain-prefix::after {
        content: "";
        position: absolute;
        left: 100%;
        width: 9px;
        height: 100%;
        background-color: inherit; }
    .domain-input-wrapper .full-domain-wrapper.focus > .domain-prefix, .domain-input-with-buttons .address-part .full-domain-wrapper.focus > .domain-prefix {
      background-color: #f0f3f5; }
    .domain-input-wrapper .full-domain-wrapper .input-wrapper, .domain-input-with-buttons .address-part .full-domain-wrapper .input-wrapper {
      position: relative;
      flex: 1 1 auto; }
      .domain-input-wrapper .full-domain-wrapper .input-wrapper input, .domain-input-with-buttons .address-part .full-domain-wrapper .input-wrapper input {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 16px;
        position: relative;
        width: 100%;
        height: 26px;
        padding: 0;
        border: 1px solid #fff;
        border-radius: 4px;
        box-sizing: border-box;
        color: #2b5672;
        outline: none;
        transition: padding ease-out 150ms; }
        .theme-blue.domain-input-wrapper .full-domain-wrapper .input-wrapper input, .domain-input-with-buttons .theme-blue.address-part .full-domain-wrapper .input-wrapper input {
          color: #2b81cb; }
        .domain-input-wrapper .full-domain-wrapper .input-wrapper input:focus, .domain-input-with-buttons .address-part .full-domain-wrapper .input-wrapper input:focus {
          border: 1px solid #7fccf7;
          box-shadow: inset 0 0 3px 0 rgba(43, 86, 114, 0.55);
          padding-left: 5px;
          margin-left: 2px; }
        .domain-input-wrapper .full-domain-wrapper .input-wrapper input.invalid, .domain-input-with-buttons .address-part .full-domain-wrapper .input-wrapper input.invalid {
          border: 1px solid #ee5951;
          box-shadow: inset 0 0 3px 0 rgba(238, 89, 81, 0.55); }
        .domain-input-wrapper .full-domain-wrapper .input-wrapper input::selection, .domain-input-with-buttons .address-part .full-domain-wrapper .input-wrapper input::selection {
          background-color: #4eb7f5;
          color: #fff; }
        .domain-input-wrapper .full-domain-wrapper .input-wrapper input:disabled, .domain-input-with-buttons .address-part .full-domain-wrapper .input-wrapper input:disabled {
          background-color: #fff; }
      .domain-input-wrapper .full-domain-wrapper .input-wrapper:only-child, .domain-input-with-buttons .address-part .full-domain-wrapper .input-wrapper:only-child {
        left: 0; }
        .domain-input-wrapper .full-domain-wrapper .input-wrapper:only-child input, .domain-input-with-buttons .address-part .full-domain-wrapper .input-wrapper:only-child input {
          width: 100%; }
    .domain-input-wrapper .full-domain-wrapper .tooltip-anchor, .domain-input-with-buttons .address-part .full-domain-wrapper .tooltip-anchor {
      position: absolute;
      right: 32px;
      top: 0; }

.domain-input-with-buttons {
  display: flex; }
  .domain-input-with-buttons .address-part {
    flex: 1 1 auto; }
  .domain-input-with-buttons .button-container {
    position: relative;
    display: inline-block; }
    .domain-input-with-buttons .button-container .edit-button {
      position: relative;
      top: 3px;
      height: 30px;
      min-width: 72px;
      max-width: 160px;
      line-height: 30px;
      padding: 0 20px;
      margin-left: 13px;
      background-color: #eaf7ff;
      color: #3899ec;
      visibility: hidden; }
      .domain-input-with-buttons .button-container .edit-button.displayed {
        visibility: visible; }
        .domain-input-with-buttons .button-container .edit-button.displayed:hover {
          background-color: #d3edff; }
        .domain-input-with-buttons .button-container .edit-button.displayed:active {
          background-color: #eaf7ff; }
        .domain-input-with-buttons .button-container .edit-button.displayed:disabled {
          background-color: #bcbcbc; }
    .domain-input-with-buttons .button-container .dual-button-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      visibility: hidden; }
      .domain-input-with-buttons .button-container .dual-button-container.displayed {
        visibility: visible; }
        .domain-input-with-buttons .button-container .dual-button-container.displayed .cancel-button,
        .domain-input-with-buttons .button-container .dual-button-container.displayed .apply-button {
          position: relative;
          top: 3px;
          border-radius: 50%;
          border: 0;
          width: 33px;
          height: 30px;
          background-color: #eaf7ff;
          box-sizing: border-box;
          line-height: 21px;
          padding-left: 5px;
          outline: 0; }
          .domain-input-with-buttons .button-container .dual-button-container.displayed .cancel-button svg path:first-of-type,
          .domain-input-with-buttons .button-container .dual-button-container.displayed .apply-button svg path:first-of-type {
            fill: #3899ec; }
          .domain-input-with-buttons .button-container .dual-button-container.displayed .cancel-button:hover,
          .domain-input-with-buttons .button-container .dual-button-container.displayed .apply-button:hover {
            background-color: #d3edff; }
          .domain-input-with-buttons .button-container .dual-button-container.displayed .cancel-button:active,
          .domain-input-with-buttons .button-container .dual-button-container.displayed .apply-button:active {
            background-color: #eaf7ff; }
          .domain-input-with-buttons .button-container .dual-button-container.displayed .cancel-button[disabled],
          .domain-input-with-buttons .button-container .dual-button-container.displayed .apply-button[disabled] {
            background-color: #f0f0f0; }
            .domain-input-with-buttons .button-container .dual-button-container.displayed .cancel-button[disabled] svg path:first-of-type,
            .domain-input-with-buttons .button-container .dual-button-container.displayed .apply-button[disabled] svg path:first-of-type {
              fill: #bcbcbc; }
        .domain-input-with-buttons .button-container .dual-button-container.displayed .cancel-button {
          margin-left: 13px; }
        .domain-input-with-buttons .button-container .dual-button-container.displayed .apply-button {
          margin-left: 10px; }

.input-slider {
  position: relative; }
  .input-slider > label {
    display: none; }
  .input-slider.has-label > label {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    display: block;
    margin-bottom: 5px;
    color: #2b5672;
    text-align: left;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .input-slider .info-icon {
    display: none;
    position: absolute;
    top: 0;
    right: 0; }
  .input-slider .sliderArea {
    position: relative; }
  .input-slider .slider {
    position: relative;
    height: 36px;
    margin: 0 81px 0 0;
    padding-top: 3px; }
    .input-slider .slider .line {
      position: absolute;
      top: 17px;
      left: 0;
      height: 6px;
      width: 100%;
      background-color: #d3edff;
      border-radius: 3px;
      box-shadow: inset 0 1px 0 0 #c0e1f2;
      cursor: pointer; }
    .input-slider .slider .knobContainer {
      margin-right: 16px;
      position: relative; }
    .input-slider .slider .coloredLine {
      position: absolute;
      top: 14px;
      left: 0;
      height: 6px;
      background-color: #3899ec;
      border-radius: 3px;
      box-shadow: inset 0 1px 0 0 #3899ec;
      will-change: width;
      cursor: pointer; }
    .input-slider .slider .sliderKnob {
      position: absolute;
      content: '';
      top: 9px;
      display: inline-block;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 1px 3px 0 rgba(52, 94, 151, 0.6);
      width: 16px;
      height: 16px;
      cursor: pointer;
      will-change: left;
      transition: box-shadow 0.25s ease-in-out 0s; }
      .input-slider .slider .sliderKnob:after {
        position: absolute;
        content: '';
        display: inline-block;
        border-radius: 50%;
        background-color: #3899ec;
        width: 8px;
        height: 8px;
        left: 4px;
        top: 4px;
        cursor: pointer; }
      .input-slider .slider .sliderKnob:hover {
        box-shadow: 0 1px 2px 2px rgba(138, 209, 250, 0.58); }
  .input-slider .input-stepper {
    position: absolute;
    right: 0; }
    .input-slider .input-stepper .input {
      text-align: right; }
  .input-slider:hover .input-stepper,
  .input-slider:hover .input-stepper > :not(.units-container) {
    background-color: #eaf7ff; }
  .input-slider:hover .info-icon {
    display: inline-block; }
  .input-slider.disabled {
    opacity: 0.5; }
  .color-picker-input-with-opacity .input-slider {
    margin-right: 40px; }

.input-slider-opacity {
  position: relative; }
  .input-slider-opacity > label {
    display: none; }
  .input-slider-opacity.has-label > label {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    display: block;
    margin-bottom: 5px;
    color: #2b5672;
    text-align: left;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .input-slider-opacity .info-icon {
    display: none;
    position: absolute;
    top: 0;
    right: 0; }
  .input-slider-opacity .sliderArea {
    position: relative; }
  .input-slider-opacity .slider {
    position: relative;
    height: 36px;
    margin: 0 81px 0 0;
    padding-top: 3px; }
    .input-slider-opacity .slider .empty-line {
      position: absolute;
      top: 16px;
      left: 0;
      height: 8px;
      width: 100%;
      border-radius: 3px;
      background-image: linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc), linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc);
      background-position: 1px 1px, 5px 5px;
      background-size: 8px 8px;
      cursor: pointer; }
    .input-slider-opacity .slider .line {
      position: absolute;
      top: 16px;
      left: 0;
      height: 8px;
      width: 100%;
      border-radius: 3px;
      box-shadow: inset 0 1px 0 0 #c0e1f2;
      cursor: pointer; }
    .input-slider-opacity .slider .knobContainer {
      margin-right: 16px;
      position: relative; }
    .input-slider-opacity .slider .sliderKnob {
      position: absolute;
      content: '';
      top: 9px;
      display: inline-block;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 1px 3px 0 rgba(52, 94, 151, 0.6);
      width: 16px;
      height: 16px;
      cursor: pointer;
      will-change: left;
      transition: box-shadow 0.25s ease-in-out 0s; }
      .input-slider-opacity .slider .sliderKnob:after {
        position: absolute;
        content: '';
        display: inline-block;
        border-radius: 50%;
        background-color: #3899ec;
        width: 8px;
        height: 8px;
        left: 4px;
        top: 4px;
        cursor: pointer; }
      .input-slider-opacity .slider .sliderKnob:hover {
        box-shadow: 0 1px 2px 2px rgba(138, 209, 250, 0.58); }
  .input-slider-opacity .input-stepper {
    position: absolute;
    right: 0; }
    .input-slider-opacity .input-stepper .input {
      text-align: right; }
  .input-slider-opacity:hover .input-stepper,
  .input-slider-opacity:hover .input-stepper > :not(.units-container) {
    background-color: #eaf7ff; }
  .input-slider-opacity:hover .info-icon {
    display: inline-block; }
  .input-slider-opacity.disabled {
    opacity: 0.5; }
  .color-picker-input-with-opacity .input-slider-opacity {
    margin-right: 40px; }

.input-stepper {
  border-radius: 8px;
  border: 1px solid transparent;
  width: 56px;
  padding: 0 6px;
  position: relative;
  text-align: left;
  display: inline-block; }
  .input-stepper.no-padding {
    padding: 0; }
  .input-stepper:after {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    content: '';
    border: 1px solid transparent;
    border-radius: 8px;
    pointer-events: none; }
    .edited.input-stepper:after {
      border-color: #9cccf6;
      box-shadow: inset 0 0 3px 0 rgba(0, 90, 132, 0.25); }
    .bad-value.input-stepper:after {
      border-color: #f1726b; }
  .input-stepper.disabled {
    opacity: 0.5; }
  .units-dd-expanded.input-stepper:not(.disabled):not(.bad-value):not(.edited), .units-dd-expanded.input-stepper:not(.disabled):not(.bad-value):not(.edited) > .input, .units-dd-expanded.input-stepper:not(.disabled):not(.bad-value):not(.edited) > .units-container > .units, .units-dd-expanded.input-stepper:not(.disabled):not(.bad-value):not(.edited) > .units-container, .input-stepper:not(.disabled):not(.bad-value):not(.edited):hover, .input-stepper:not(.disabled):not(.bad-value):not(.edited):hover > .input, .input-stepper:not(.disabled):not(.bad-value):not(.edited):hover > .units-container > .units, .input-stepper:not(.disabled):not(.bad-value):not(.edited):hover > .units-container {
    background-color: #d3edff;
    color: #2b5672;
    cursor: pointer; }
  .units-dd-expanded.input-stepper:not(.disabled):not(.bad-value):not(.edited):after, .input-stepper:not(.disabled):not(.bad-value):not(.edited):hover:after {
    border-color: #d3edff; }
  .input-stepper:not(.disabled):not(.bad-value):not(.edited) > .input:focus {
    background-color: #d3edff;
    color: #2b5672;
    cursor: pointer; }
  .input-stepper.edited {
    background-color: transparent !important; }
    .stepper-with-svg .input-stepper.edited > .input {
      width: 100%; }
  .input-stepper.small {
    width: 45px;
    padding: 2px 2px 2px 0; }
  .input-stepper.has-units.with-units-dd {
    width: 64px;
    padding-right: 0; }
    .input-stepper.has-units.with-units-dd > .input {
      width: 28px; }
  .input-stepper > .input {
    border: none;
    outline: none;
    text-align: center;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 18px;
    height: 36px;
    line-height: 36px;
    padding: 0 7px;
    width: calc(100% - 14px);
    display: inline-block;
    color: #162d3d; }
    .disabled.input-stepper > .input {
      color: #7a7a7a;
      background: transparent; }
    .has-units.input-stepper > .input {
      width: 70%;
      padding: 0;
      text-align: right;
      display: inline-block; }
    .small.input-stepper > .input {
      font-size: 12px;
      height: 18px;
      line-height: 18px; }
    .edited.input-stepper > .input {
      background-color: transparent !important;
      cursor: text !important; }
    .input-stepper:not(.edited) > .input {
      cursor: default; }
    .input-stepper > .input::selection {
      background-color: #eaf7ff; }
      .input-stepper:not(.edited) > .input::selection {
        background-color: transparent; }
    .color-format .input-stepper > .input {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      height: 24px;
      line-height: 24px; }
  .input-stepper:edited > .units-container {
    background-color: transparent !important; }
  .edited.input-stepper:not(.with-units-dd) > .units-container {
    visibility: hidden; }
  .input-stepper > .units-container > .units {
    line-height: 36px;
    height: 36px;
    width: 1%;
    display: inline-block;
    white-space: nowrap;
    padding: 0 7px 0 1px; }
    .disabled.input-stepper > .units-container > .units {
      color: #7a7a7a; }
    .small.input-stepper > .units-container > .units {
      height: 18px;
      line-height: 18px;
      font-size: 12px; }
    .color-format .input-stepper > .units-container > .units {
      height: 24px;
      line-height: 24px; }
  .control-angle .input-stepper {
    position: absolute;
    top: 52px;
    left: 138px;
    width: 40px; }
    .control-angle .input-stepper > .units,
    .control-angle .input-stepper > .input {
      height: 28px;
      line-height: 28px; }
    .control-angle .input-stepper.has-units > .input {
      width: 78%; }
  .control-angle > .circle:active + .input-stepper,
  .control-angle > .circle:active + .input-stepper > .units,
  .control-angle > .circle:active + .input-stepper > .stepper,
  .control-angle:hover .input-stepper,
  .control-angle:hover .input-stepper > .units,
  .control-angle:hover .input-stepper > .stepper {
    background-color: #edf7ff; }
  .color-format .input-stepper {
    width: 100%;
    box-sizing: border-box; }
  .stepper-with-svg .input-stepper {
    text-align: left;
    padding-left: 0;
    border-radius: 4px;
    margin-top: 5px;
    overflow: hidden;
    margin-left: 12px;
    padding-right: 11px; }
    .stepper-with-svg .input-stepper:after {
      border-radius: 4px; }
    .stepper-with-svg .input-stepper > .units-container > .units,
    .stepper-with-svg .input-stepper > .input {
      height: 17px;
      line-height: 17px;
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
    .stepper-with-svg .input-stepper > .units-container > .units {
      height: 17px; }
    .stepper-with-svg .input-stepper > .input {
      height: 18px;
      color: #384f68;
      font-size: 12px;
      padding-right: 2px;
      padding-left: 1px;
      line-height: 14px; }

.stepper-with-svg {
  margin-right: 12px;
  font-size: 12px; }
  .stepper-with-svg .symbol {
    fill: #384f68;
    position: relative;
    bottom: 3px;
    margin-left: 2px; }
    .stepper-with-svg .symbol.safari {
      top: 3px;
      bottom: 0; }
    .stepper-with-svg .symbol:hover {
      fill: #2b81cb;
      cursor: pointer; }
  .stepper-with-svg .edited + .symbol {
    fill: #bcbcbc;
    pointer-events: none;
    cursor: default; }

.property-form .errorMessage {
  color: #f00; }

.property-form span.label {
  display: block;
  margin-bottom: 5px; }

.menu-panel {
  min-width: 250px;
  min-height: 300px; }
  .menu-panel header {
    font-size: 16px;
    color: #000000;
    position: relative;
    margin-bottom: 10px; }
    .menu-panel header a {
      position: absolute;
      top: -10px;
      right: 0;
      width: 25px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      font-size: 16px;
      color: #000;
      text-decoration: none; }
  .menu-panel ul {
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden; }
    .menu-panel ul li {
      color: #333;
      border-bottom: 1px solid #fff;
      padding: 5px 5px;
      cursor: pointer; }
      .menu-panel ul li:hover {
        background-color: #b2f3ff; }

.dropdown {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 16px;
  position: relative; }
  .dropdown.font-family-dd.toolbar-mode, .dropdown.text-style-dd.toolbar-mode {
    display: inline-block;
    vertical-align: middle;
    margin: 4px 5px;
    padding-right: 0;
    min-width: 140px; }
  .dropdown.context-menu {
    width: 24px;
    height: 24px;
    border: 1px solid #000;
    border-radius: 50%;
    line-height: 24px;
    text-align: center; }
    .dropdown.context-menu.wix-code-pages-tree-dd, .dropdown.context-menu.wix-code-file-tree-dd {
      border: none;
      width: 16px;
      height: 16px;
      line-height: 16px; }
  .dropdown.units-dd {
    display: inline-block;
    padding-left: 5px; }
  .dropdown:focus,
  .dropdown > .dd:focus {
    outline: none; }
  .dropdown > .dd,
  .dropdown > .dd > .expand.arrow {
    margin: 0;
    padding: 0; }
  .dropdown > .dropdown-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 3px 0;
    padding: 0;
    color: #2b5672;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    text-align: left;
    width: calc(100% - 20px); }
    .select.disabled.dropdown > .dropdown-label {
      color: #939393; }
  .dropdown > .dd {
    position: relative;
    cursor: pointer; }
    .disabled.dropdown > .dd {
      cursor: default; }
    .context-menu.dropdown > .dd {
      height: 100%; }
    .dropdown > .dd > .selected-container {
      position: relative;
      box-sizing: border-box;
      color: #162d3d;
      font-size: 18px;
      height: 36px;
      line-height: 36px;
      min-height: 36px;
      padding: 0 30px 0 0;
      margin: 0; }
      .input-slider.dropdown:hover > .dd > .selected-container {
        color: #3899ec; }
      .text-link-dd.dropdown > .dd > .selected-container {
        height: 32px;
        line-height: 32px;
        min-height: 32px;
        width: 32px;
        padding-right: 0; }
      .disabled.dropdown > .dd > .selected-container {
        color: #7a7a7a; }
      .context-menu.dropdown > .dd > .selected-container {
        height: 30px;
        line-height: 30px;
        min-height: 30px; }
      .units-dd.dropdown > .dd > .selected-container {
        padding-right: 12px;
        font-size: 14px;
        line-height: 14px;
        height: 14px;
        min-height: 14px; }
      .wix-code-pages-tree-dd.dropdown > .dd > .selected-container {
        height: 16px;
        line-height: 16px;
        min-height: 16px; }
      .wix-code-file-tree-dd.dropdown > .dd > .selected-container {
        height: 16px;
        line-height: 16px;
        min-height: 16px; }
      .dropdown > .dd > .selected-container > .symbol-more > .notification {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 12px;
        width: 20px;
        height: 20px;
        top: -10px;
        font-size: 10px;
        border-radius: 10px;
        line-height: 18px;
        border: 1px solid #fff;
        position: absolute;
        left: 10px;
        background-color: #ffc233;
        color: #fff;
        box-sizing: border-box; }
      .dropdown > .dd > .selected-container > .symbol-more.shrink > .notification {
        width: 14px;
        height: 14px;
        top: -7px;
        font-size: 7px;
        border-radius: 7px;
        line-height: 12px; }
    .dropdown > .dd > .expand.arrow {
      border: 2px solid #3899ec;
      border-radius: 50%;
      content: '';
      display: block;
      height: 22px;
      opacity: 0.6;
      right: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 22px; }
      .select.disabled.dropdown > .dd > .expand.arrow {
        border-color: #bcbcbc; }
        .select.disabled.dropdown > .dd > .expand.arrow:hover {
          background-color: transparent; }
      .font-family-dd.toolbar-mode.dropdown > .dd > .expand.arrow, .units-dd.dropdown > .dd > .expand.arrow {
        border: none;
        width: 9px;
        opacity: 1; }
        .font-family-dd.toolbar-mode.dropdown > .dd > .expand.arrow:hover, .units-dd.dropdown > .dd > .expand.arrow:hover {
          background-color: transparent; }
      .input-stepper.dropdown:hover > .dd > .expand.arrow {
        opacity: 1; }
      .input-slider:hover .dropdown > .dd > .expand.arrow {
        opacity: 1; }
      .units-dd.dropdown > .dd > .expand.arrow {
        opacity: 0; }
      .toolbar-mode.font-family-dd.dropdown > .dd > .expand.arrow {
        right: 8px; }

.combo-box > .combo-box-input {
  border: none;
  width: 100%;
  padding: 0; }
  .font-family-dd.toolbar-mode .combo-box > .combo-box-input {
    font-size: 16px; }
  .combo-box > .combo-box-input:focus {
    outline: none; }

.font-family-dd.toolbar-mode .combo-box.selected {
  width: 130px; }

.dropdown:not(.disabled):hover .combo-box {
  color: #3899ec; }

.dropdown.combobox .combo-box {
  overflow: inherit;
  text-overflow: inherit; }

.dropdown-options {
  left: 0;
  position: fixed;
  top: 0; }
  .dropdown-options.expanded {
    bottom: 0;
    right: 0;
    z-index: 5998 !important; }
  .dropdown-options > .options {
    display: none;
    position: fixed;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 22px 0 rgba(22, 45, 61, 0.26);
    flex-direction: column;
    max-height: 100%; }
    .expanded.dropdown-options > .options {
      display: flex !important; }
      .expanded.dropdown-options > .options.scroll.up > .arrow.top, .expanded.dropdown-options > .options.scroll.down > .arrow.bottom {
        visibility: visible; }
    .select.dropdown-options > .options, .combobox.dropdown-options > .options {
      overflow: hidden; }
    .context-menu.dropdown-options > .options.tag-triangle.tag-left.tag-side-top:before, .context-menu.dropdown-options > .options.tag-triangle.tag-right.tag-side-top:before {
      top: 17px; }
    .context-menu.dropdown-options > .options.tag-triangle.tag-left.tag-side-bottom:before, .context-menu.dropdown-options > .options.tag-triangle.tag-right.tag-side-bottom:before {
      bottom: 17px; }
    .dropdown-options > .options,
    .dropdown-options > .options > .top.arrow,
    .dropdown-options > .options > .list,
    .dropdown-options > .options > .options-footer,
    .dropdown-options > .options > .bottom.arrow,
    .dropdown-options > .options > .mouse-events-blocker {
      margin: 0;
      padding: 0; }
    .dropdown-options > .options:focus {
      outline: none; }
    .dropdown-options > .options > .list {
      display: block;
      max-height: 100%;
      overflow: hidden; }
      .context-menu.dropdown-options > .options > .list {
        box-sizing: border-box;
        padding: 10px 0; }
      .combobox.dropdown-options > .options > .list {
        overflow-y: auto; }
    .dropdown-options > .options > .arrow {
      background-color: rgba(255, 255, 255, 0.93);
      display: block;
      font-size: 8px;
      height: 36px;
      left: 0;
      opacity: 0.6;
      position: absolute;
      width: 100%;
      visibility: hidden;
      z-index: 1; }
      .dropdown-options > .options > .arrow.top {
        top: 0;
        transform: rotateX(180deg); }
      .dropdown-options > .options > .arrow.bottom {
        bottom: 0; }
    .dropdown-options > .options > .options-footer {
      flex-shrink: 0; }
      .combobox.dropdown-options > .options > .options-footer {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 14px;
        background-color: #fff;
        box-shadow: 0 -1px 23px 0 #fff;
        color: #3899ec;
        line-height: 35px;
        height: 35px;
        padding: 0 24px;
        z-index: 0; }
    .dropdown-options > .options > .mouse-events-blocker {
      display: none; }
      .dropdown-options > .options > .mouse-events-blocker.visible {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        left: 0;
        top: 0; }

.dropdown-option {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  font-size: 14px;
  height: 36px;
  line-height: 36px;
  padding: 0 20px;
  position: relative; }
  .dropdown-option.hovered:not(.separator), .dropdown-option.hovered:not(.separator).selected {
    background-color: #eaf7ff; }
  .dropdown-option.level1 {
    padding-left: 30px; }
  .dropdown-option.level2 {
    padding-left: 40px; }
  .dropdown-option.level3 {
    padding-left: 50px; }
  .dropdown-option.separator {
    height: 1px; }
  .dropdown-option.font-option {
    height: 24px;
    padding: 0 14px; }
  .dropdown-option.units-dropdown {
    text-align: center;
    padding: 0; }
  .dropdown-option.link-dd-option {
    padding: 4px 11px; }
  .optgroup-list .dropdown-option {
    padding: 0 40px; }
    .dropdown-options.combobox .optgroup-list .dropdown-option {
      padding: 0 24px; }
  .dropdown-options.select .dropdown-option.selected,
  .dropdown-options.combobox .dropdown-option.selected {
    background-color: #3899ec;
    color: #fff; }
  .units-dd .dropdown-option {
    padding: 0 10px; }

.dropdown-optgroup .optgroup-label {
  background-color: #f5f5f5;
  color: #2b5672;
  font-size: 14px;
  font-weight: 300;
  height: 35px;
  line-height: 35px;
  padding: 0 20px;
  margin: 0; }
  .combobox .dropdown-optgroup .optgroup-label {
    height: 36px;
    padding: 0 24px;
    background-color: #f0f3f5;
    border-top: 1px solid #d9e1e8;
    border-bottom: 1px solid #d9e1e8;
    cursor: default; }

.dropdown-optgroup > .optgroup-list {
  padding: 0; }

.dropdown.select .dropdown-selected {
  overflow: inherit;
  text-overflow: inherit; }

.icons-menu-button-wrapper {
  position: relative;
  height: 34px;
  width: 34px;
  display: inline-block;
  cursor: pointer; }
  .icons-menu-button-wrapper:hover, .icons-menu-button-wrapper.active {
    background: #eaf7ff;
    border-radius: 6px; }
  .icons-menu-button-wrapper .small-indicator {
    position: absolute;
    box-sizing: border-box;
    width: 4px;
    height: 4px;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-bottom: 2px solid #3899ec;
    border-right: 2px solid #3899ec;
    bottom: 3px;
    right: 3px; }

.dropdown.icons-menu > .dd > .selected-container {
  padding: 0; }

.dropdown-options.icons-menu .options {
  padding: 12px 0; }
  .dropdown-options.icons-menu .options.tag-triangle.tag-top::before, .dropdown-options.icons-menu .options.tag-triangle.tag-bottom::before {
    left: calc(50% - 5px); }
  .dropdown-options.icons-menu .options .dropdown-option {
    padding: 0 18px; }
    .dropdown-options.icons-menu .options .dropdown-option .symbol-wrapper {
      height: 100%;
      display: inline-block; }
      .dropdown-options.icons-menu .options .dropdown-option .symbol-wrapper .symbol {
        padding: 0 10px 0 0;
        margin-bottom: -2px; }

.control-checkable-text-input {
  margin: 0 12px 0 30px; }
  .control-checkable-text-input .checkable-text-input {
    align-items: center;
    display: flex;
    height: 59px; }
    .control-checkable-text-input .checkable-text-input .control-text-input,
    .settings-panel .control-checkable-text-input .checkable-text-input .control-text-input {
      margin: 0 0 0 12px; }
  .control-checkable-text-input + hr.divider-long {
    margin: 0 24px 0 30px; }

.control-numeric-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box; }
  .control-numeric-input .label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 18px 0 16px 24px;
    vertical-align: middle;
    color: #2b5672;
    font-size: 14px;
    line-height: 1.8;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    user-select: none; }
  .control-numeric-input .input-stepper {
    margin-right: 6px; }

.control-radio-list {
  padding: 10px 20px 5px 24px;
  box-sizing: border-box;
  position: relative;
  width: 100%; }
  .control-radio-list .title {
    color: #2b5672;
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 3px;
    text-align: left;
    font-weight: 300;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .control-radio-list label {
    display: block;
    padding: 15px 0 15px 4px;
    margin: 0;
    cursor: pointer;
    border-bottom: 1px solid #d9e1e8; }
    .control-radio-list label div:nth-of-type(1) {
      margin: 0 0 0 14px;
      vertical-align: middle;
      box-sizing: border-box;
      display: inline-block; }
      .control-radio-list label div:nth-of-type(1) div {
        display: table-cell;
        width: 54px;
        height: 42px;
        text-align: center;
        vertical-align: middle;
        border: 1px solid #e5e5e5;
        border-radius: 5px; }
        .control-radio-list label div:nth-of-type(1) div span {
          font-size: 30px; }
    .control-radio-list label:first-child {
      border-top: none; }
    .control-radio-list label:last-child {
      border-bottom-color: transparent; }
    .control-radio-list label.highlight-on-hover:hover span:nth-of-type(2) {
      color: #5c99e8; }
    .control-radio-list label.highlight-on-hover:hover .editor-mode-option-symbol svg g {
      fill: #5c99e8; }
  .control-radio-list span:nth-of-type(2) {
    display: inline-block;
    color: #2a3742;
    margin-left: 14px;
    vertical-align: middle; }
  .control-radio-list .info-icon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px; }
  .control-radio-list:hover .info-icon {
    display: inline-block; }
  .control-radio-list input {
    display: none; }
    .control-radio-list input + span {
      border-radius: 50%;
      display: inline-block;
      height: 12px;
      width: 12px;
      box-shadow: 0 0 0 1px #5c99e8;
      border: solid 5px #fff;
      transition-property: background-color, border-width;
      transition-duration: 0.15s;
      box-sizing: border-box;
      vertical-align: middle; }
    .control-radio-list input:checked + span {
      background-color: #5c99e8;
      border-width: 1px; }

.control-checkbox-with-button {
  white-space: nowrap; }
  .control-checkbox-with-button .controls-wrapper {
    height: 24px;
    display: flex;
    align-items: center; }
    .control-checkbox-with-button .controls-wrapper .checkbox-button {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      height: 24px;
      line-height: 24px;
      display: none;
      border-radius: 12px;
      margin-left: auto;
      overflow: visible; }
  .control-checkbox-with-button:hover {
    background-color: #eaf7ff; }
    .control-checkbox-with-button:hover .checkbox-button {
      display: inline-block;
      opacity: 1;
      transition: 0.2s opacity; }
  .control-checkbox-with-button:not(:hover) .checkbox-button {
    opacity: 0;
    width: 0;
    padding: 0; }

.color-scheme-picker .control-switch.invert-color-toggle {
  padding-top: 10px; }

.color-scheme-picker .control-switch .label {
  align-self: baseline; }

.action-container {
  position: relative;
  height: 72px;
  cursor: pointer; }
  .action-container > .action-symbol {
    position: absolute;
    top: 0;
    bottom: 0;
    background: #3899ec;
    border-radius: 50%;
    margin: auto 26px;
    width: 24px;
    height: 24px; }
    .action-container:hover > .action-symbol {
      background: #4eb7f5; }
    .symbol-right.action-container > .action-symbol {
      right: 0; }
    .action-container > .action-symbol > .symbol {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      padding-right: 1px; }
      .action-container > .action-symbol > .symbol > path {
        fill: #FFF; }
  .action-container > .action-label {
    position: absolute;
    top: 0;
    bottom: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: auto 24px;
    height: 20px;
    color: #3899ec;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px; }
    .action-container:hover > .action-label {
      text-decoration: underline;
      color: #4eb7f5; }
    .symbol-left.action-container > .action-label {
      left: 34px;
      right: 0; }
    .symbol-right.action-container > .action-label {
      right: 45px;
      left: 0; }

.media-button > .media-button-filename-text {
  text-align: center;
  margin-top: 16px; }
  .media-button > .media-button-filename-text > .wrapper > span {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #162d3d;
    vertical-align: middle;
    display: inline-block;
    max-width: calc(100% - 48px); }

.image-preview {
  background-color: #eaf7ff;
  box-sizing: border-box;
  height: 155px;
  position: relative;
  text-align: center;
  width: 288px; }
  .image-preview .image-wrapper {
    font-size: 0;
    height: 100%; }
    .image-preview .image-wrapper > .image {
      width: 100%;
      height: 100%; }
  .image-preview .button-wrapper {
    background-color: rgba(177, 221, 248, 0.4);
    bottom: 0;
    display: block;
    height: 54px;
    left: 0;
    position: absolute;
    white-space: nowrap;
    width: 100%; }
    .image-preview .button-wrapper button.action {
      background-color: #fff;
      border: 0;
      border-radius: 17px;
      color: #3899ec;
      cursor: pointer;
      font-size: 14px;
      height: 32px;
      line-height: 32px;
      margin-bottom: 0 !important;
      margin-top: 11px !important;
      padding: 0;
      outline: none;
      text-overflow: clip;
      vertical-align: top; }
      .image-preview .button-wrapper button.action.has-icon.has-label span {
        padding: 0 34px; }
      .image-preview .button-wrapper button.action.has-icon:not(.has-label) {
        padding: 0 9px;
        margin-left: 0;
        margin-right: 10px; }
      .image-preview .button-wrapper button.action:nth-of-type(1):nth-last-of-type(1) {
        min-width: 167px;
        max-width: 204px; }
      .image-preview .button-wrapper button.action:nth-of-type(1):nth-last-of-type(2) {
        margin-right: 24px; }
        .image-preview .button-wrapper button.action:nth-of-type(1):nth-last-of-type(2),
        .image-preview .button-wrapper button.action:nth-of-type(1):nth-last-of-type(2) + button {
          width: 108px; }
        .image-preview .button-wrapper button.action:nth-of-type(1):nth-last-of-type(2) + button {
          margin-left: 0; }
      .image-preview .button-wrapper button.action:nth-of-type(1):nth-last-of-type(3) {
        width: 152px;
        margin-left: 24px;
        margin-right: 12px;
        float: left; }
      .image-preview .button-wrapper button.action:nth-of-type(2):nth-last-of-type(2) {
        float: right;
        margin-right: 24px; }
      .image-preview .button-wrapper button.action:nth-of-type(3):nth-last-of-type(1) {
        float: right;
        margin-right: 12px; }
      .image-preview .button-wrapper button.action:hover {
        background-color: #eaf7ff; }
      .image-preview .button-wrapper button.action:active {
        background-color: #fff; }
      .image-preview .button-wrapper button.action > .button-content {
        position: relative; }
      .image-preview .button-wrapper button.action svg {
        width: 14px;
        height: 32px; }
        .image-preview .button-wrapper button.action svg.symbol-with-label {
          top: 0;
          left: 12px;
          position: absolute; }
        .image-preview .button-wrapper button.action svg * {
          fill: #3899ec; }
      .image-preview .button-wrapper button.action span {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 14px;
        color: #3899ec;
        line-height: 17px;
        text-align: left;
        left: 3px;
        position: relative; }
  .image-preview .placeholder-wrapper svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -75%); }
  .image-preview .placeholder-wrapper.symbol-in-the-middle svg {
    transform: translate(-50%, -50%); }
  .image-preview .placeholder-wrapper .corner-borders:before, .image-preview .placeholder-wrapper .corner-borders:after {
    border: solid #5c99e8;
    content: '';
    height: 10px;
    margin: 25px;
    position: absolute;
    width: 10px; }
  .image-preview .placeholder-wrapper .corner-borders.top:before {
    border-width: 1px 0 0 1px;
    left: 0;
    top: 0; }
  .image-preview .placeholder-wrapper .corner-borders.top:after {
    border-width: 1px 1px 0 0;
    right: 0;
    top: 0; }
  .image-preview .placeholder-wrapper .corner-borders.bottom:before {
    border-width: 0 0 1px 1px;
    bottom: 0;
    left: 0; }
  .image-preview .placeholder-wrapper .corner-borders.bottom:after {
    border-width: 0 1px 1px 0;
    bottom: 0;
    right: 0; }
  .image-preview .arrow-button {
    border-radius: 50%;
    box-shadow: 0 1px 3px 0 rgba(52, 94, 151, 0.43);
    cursor: pointer;
    height: 24px;
    position: absolute;
    top: 50%;
    width: 24px; }
    .image-preview .arrow-button:after {
      border-left: 2px solid #0199ef;
      border-top: 2px solid #0199ef;
      content: '';
      height: 6px;
      left: calc(50% - 2px);
      position: absolute;
      top: calc(50% - 4px);
      transform: rotate(-45deg);
      width: 6px; }
    .image-preview .arrow-button.prev {
      left: 0;
      margin: -12px 0 0 12px; }
    .image-preview .arrow-button.next {
      right: 0;
      margin: -12px 12px 0 0; }
      .image-preview .arrow-button.next:after {
        left: calc(50% - 5px);
        top: calc(50% - 4px);
        transform: rotate(135deg); }
    .image-preview .arrow-button:hover {
      background-color: rgba(56, 153, 236, 0.9); }
      .image-preview .arrow-button:hover:after {
        border-color: #fff; }
    .image-preview .arrow-button[disabled] {
      cursor: default; }
    .image-preview .arrow-button, .image-preview .arrow-button[disabled], .image-preview .arrow-button[disabled]:hover {
      background-color: rgba(255, 255, 255, 0.9); }
    .image-preview .arrow-button[disabled]:after, .image-preview .arrow-button[disabled]:hover:after {
      border-color: #bcbcbc; }

.video-input {
  width: 288px;
  height: 155px;
  box-sizing: border-box;
  background-color: #eaf7ff;
  position: relative;
  text-align: center; }
  .video-input .video-wrapper {
    height: 100%;
    font-size: 0; }
    .video-input .video-wrapper > iframe {
      border: none; }
    .video-input .video-wrapper,
    .video-input .video-wrapper > iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%; }
  .video-input .button-wrapper {
    height: 54px;
    display: block;
    bottom: 0;
    left: 0;
    position: absolute;
    white-space: nowrap;
    width: 100%;
    background-color: rgba(177, 221, 248, 0.4); }
    .video-input .button-wrapper button.action {
      height: 32px;
      line-height: 32px;
      margin-bottom: 0 !important;
      border: 0;
      border-radius: 17px;
      background-color: #fff;
      cursor: pointer;
      outline: none;
      color: #3899ec;
      font-size: 14px;
      vertical-align: top;
      text-overflow: clip; }
      .video-input .button-wrapper button.action.has-icon {
        padding-left: 0; }
      .video-input .button-wrapper button.action:nth-of-type(1):nth-last-of-type(1) {
        width: 167px; }
      .video-input .button-wrapper button.action:nth-of-type(1):nth-last-of-type(2) {
        margin-right: 23px; }
        .video-input .button-wrapper button.action:nth-of-type(1):nth-last-of-type(2),
        .video-input .button-wrapper button.action:nth-of-type(1):nth-last-of-type(2) + button {
          width: calc(50% - 2 * 23px); }
      .video-input .button-wrapper button.action:hover {
        background-color: #eaf7ff; }
      .video-input .button-wrapper button.action:active {
        background-color: #fff; }
      .video-input .button-wrapper button.action svg {
        float: left;
        margin: 0 20px 0 0 !important;
        width: 14px;
        height: 32px; }
      .video-input .button-wrapper button.action span {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 14px;
        color: #3899ec;
        line-height: 17px;
        text-align: left; }
  .video-input .placeholder-wrapper svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -75%); }
    .video-input .placeholder-wrapper svg path {
      fill: #3899ec; }
  .video-input .video-controls {
    width: 100%;
    height: 100%; }
    .video-input .video-controls .blocking-layer {
      background: #000;
      width: 100%;
      height: 100%; }
      .video-input .video-controls .blocking-layer.play {
        opacity: 0; }
      .video-input .video-controls .blocking-layer.pause {
        opacity: 0.4; }
    .video-input .video-controls .pause-button,
    .video-input .video-controls .play-button {
      width: 44px;
      height: 44px;
      border: 2px solid #fff;
      position: absolute;
      margin: 46px 0 65px 0;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 50%;
      top: 6px; }
    .video-input .video-controls .pause-button .pause {
      display: block;
      border-left: 5px solid #fff;
      border-right: 5px solid #fff;
      width: 10%;
      height: 40%;
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%); }
    .video-input .video-controls .pause-button:hover {
      border-color: #3899ec;
      cursor: pointer; }
      .video-input .video-controls .pause-button:hover .pause {
        border-left-color: #3899ec;
        border-right-color: #3899ec;
        cursor: pointer; }
    .video-input .video-controls .play-button .triangle {
      width: 0;
      height: 0;
      position: relative;
      display: block;
      border-color: inherit;
      border-left: 24px solid #fff;
      border-bottom: 14px transparent solid;
      border-top: 14px transparent solid;
      top: 50%;
      left: calc(50% + 4px);
      transform: translate(-50%, -50%); }
    .video-input .video-controls .play-button:hover {
      border-color: #3899ec;
      cursor: pointer; }
      .video-input .video-controls .play-button:hover .triangle {
        border-left-color: #3899ec;
        border-right-color: #3899ec;
        cursor: pointer; }

.control-text-input-with-button {
  position: relative; }
  .control-text-input-with-button:hover .info-icon {
    display: inline-block; }
  .control-text-input-with-button:hover .control-text-input:not(.focused) > input,
  .control-text-input-with-button:hover .control-text-input:not(.focused) > textarea {
    background-color: #eaf7ff;
    color: #2b5672; }
    .control-text-input-with-button:hover .control-text-input:not(.focused) > input::placeholder,
    .control-text-input-with-button:hover .control-text-input:not(.focused) > textarea::placeholder {
      color: #2b5672; }
  .control-text-input-with-button:hover .control-text-input:not(.focused) > input:hover,
  .control-text-input-with-button:hover .control-text-input:not(.focused) > textarea:hover {
    background-color: #d3edff;
    color: #2b5672; }
  .control-text-input-with-button .control-text-input {
    margin: 12px 0 !important;
    width: 100% !important; }
  .control-text-input-with-button .control-button {
    margin: 0 auto 18px !important;
    display: block;
    padding: 7px 24px;
    width: auto;
    min-width: 0;
    line-height: 14px !important;
    font-size: 14px;
    height: 30px; }

.control-text-input-with-fixed-button {
  display: flex;
  align-items: center; }
  .control-text-input-with-fixed-button > .control-text {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    flex-grow: 1;
    color: #2b5672;
    outline: none;
    text-align: left;
    box-sizing: border-box;
    text-overflow: ellipsis;
    border-radius: 4px;
    border: solid 1px #88ccf8;
    background-color: #fff;
    height: 23px;
    padding-left: 15px;
    width: 0; }
  .control-text-input-with-fixed-button > .control-button {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    flex-shrink: 0;
    margin-left: 6px;
    height: 20px;
    border-radius: 12px;
    border-width: 2px;
    line-height: 20px;
    padding-left: 10px;
    padding-right: 9px; }
    .control-text-input-with-fixed-button > .control-button.disabled {
      background-color: #bdbdbd; }
  .control-text-input-with-fixed-button.invalid > .control-text {
    border-color: #d82646; }

.control-thumbnailsSlider {
  white-space: nowrap;
  height: 68px;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  background-color: #fff; }
  .control-thumbnailsSlider .itemsContainer {
    visibility: hidden;
    padding-left: 25px;
    padding-right: 25px;
    position: absolute;
    transition-property: left;
    transition-duration: 0.3s;
    top: 50%; }
  .control-thumbnailsSlider .arrow {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    width: 24px;
    height: 100%;
    cursor: pointer; }
    .control-thumbnailsSlider .arrow span {
      width: 8px;
      height: 8px;
      position: relative;
      top: calc(50% - 10px);
      left: calc(50% - 4px);
      border-top: 1px solid #3899ec;
      border-right: 1px solid #3899ec;
      display: inline-block; }
    .control-thumbnailsSlider .arrow.left {
      left: 0; }
      .control-thumbnailsSlider .arrow.left span {
        transform: rotate(225deg);
        left: 10px; }
    .control-thumbnailsSlider .arrow.right {
      right: 0; }
      .control-thumbnailsSlider .arrow.right span {
        transform: rotate(45deg);
        left: 5px; }
  .control-thumbnailsSlider input {
    display: none; }
    .control-thumbnailsSlider input:hover:checked + img, .control-thumbnailsSlider input:checked + img {
      border-radius: 6px;
      border-color: #fc975c;
      background-color: transparent; }
  .control-thumbnailsSlider img {
    margin-right: 6px;
    border: solid 2px transparent; }
  .control-thumbnailsSlider label {
    height: 100%;
    display: inline-block;
    font-size: 0;
    cursor: pointer; }
    .control-thumbnailsSlider label:hover img {
      border-radius: 6px;
      border-color: #7fccf7;
      background-color: rgba(127, 204, 247, 0.2); }

.proportions-slider {
  border-radius: 8px;
  height: 25px;
  background-color: #3899ec;
  margin: 13px auto 28px;
  padding: 12px; }
  .proportions-slider > .column {
    background-color: #eaf7ff;
    height: 100%;
    position: relative;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    color: #3899ec;
    min-width: 2px; }
    .proportions-slider > .column > .label {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 12px;
      position: absolute;
      bottom: -32px;
      width: 100%; }
    .proportions-slider > .column > .divider {
      position: absolute;
      left: -6.5px;
      top: 0;
      width: 13px;
      height: 100%;
      background-color: #3899ec;
      cursor: col-resize; }
      .proportions-slider:hover > .column > .divider {
        background-color: #2b81cb; }
      .proportions-slider > .column > .divider:hover {
        background-color: #4eb7f5; }

.label-with-circle-button {
  position: relative;
  text-align: left;
  padding: 12px; }
  .label-with-circle-button label {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    line-height: 100%;
    color: #2b5672;
    text-align: left;
    display: block;
    padding: 5px 30px 3px 12px; }
  .label-with-circle-button .link-to-icon-info {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px; }
  .label-with-circle-button:hover:not(.disabled) .link-to-icon-info {
    display: inherit; }
  .label-with-circle-button .container-action {
    padding: 0 11px; }
    .label-with-circle-button .container-action .link {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 34px;
      height: 36px; }
      .label-with-circle-button .container-action .link::placeholder {
        color: #b6c1cd; }
      .label-with-circle-button .container-action .link:hover::placeholder {
        color: #2b5672; }
    .label-with-circle-button .container-action .validation-icon {
      font-size: 0; }
      .label-with-circle-button .container-action .validation-icon.validation-icon-success {
        border: none;
        background-color: transparent; }
        .label-with-circle-button .container-action .validation-icon.validation-icon-success svg {
          fill: #60bc57; }
  .label-with-circle-button.disabled label {
    color: #939393; }
  .label-with-circle-button.disabled svg {
    border-color: #bcbcbc;
    background-color: #bcbcbc; }

.shadow-control {
  margin-top: 15px;
  position: relative; }
  .shadow-control .blocking-layer {
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute; }

.colorSpace {
  position: relative;
  display: inline-block;
  margin-bottom: 10px; }
  .colorSpace > .top-section {
    height: 123px; }
    .colorSpace > .top-section > .select-area {
      display: inline-block;
      position: relative;
      height: 100%;
      width: calc(210px - 10px);
      cursor: crosshair; }
      .colorSpace > .top-section > .select-area > .selector-layer {
        position: absolute;
        width: 100%;
        height: 100%; }
      .colorSpace > .top-section > .select-area > .saturation-layer {
        background-image: linear-gradient(to right, white, rgba(255, 255, 255, 0)); }
      .colorSpace > .top-section > .select-area > .brightness-layer {
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), black); }
      .colorSpace > .top-section > .select-area > .color-indicator {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 10px; }
        .colorSpace > .top-section > .select-area > .color-indicator.bright-background {
          box-shadow: 0 0 0 1px #162d3d; }
        .colorSpace > .top-section > .select-area > .color-indicator.dark-background {
          box-shadow: 0 0 0 1px #fff; }
    .colorSpace > .top-section > .brightness-options {
      display: inline-block;
      width: 10px;
      height: 100%;
      cursor: pointer; }
      .colorSpace > .top-section > .brightness-options > .option {
        width: 10px;
        height: 20%; }
        .colorSpace > .top-section > .brightness-options > .option:hover {
          position: relative;
          margin-left: -2px;
          width: calc(2px + 10px); }
    .add-color-content .colorSpace > .top-section {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      overflow: hidden; }
  .colorSpace > .hue-scale {
    width: 100%;
    height: 10px;
    position: relative;
    background-image: linear-gradient(to right, red 0%, #ff4d00 5%, #ff9900 10%, #ffe600 15%, #ccff00 20%, #80ff00 25%, #33ff00 30%, #00ff1a 35%, #00ff66 40%, #00ffb3 45%, cyan 50%, #0066ff 60%, #001aff 65%, #3300ff 70%, #8000ff 75%, #cc00ff 80%, #ff00e6 85%, #ff0099 90%, #ff004d 95%, red 100%); }
    .colorSpace > .hue-scale > .hue-indicator {
      position: absolute;
      top: -3px;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      background-color: #fff;
      box-shadow: 0 2px 1px 0 rgba(43, 86, 114, 0.4);
      box-sizing: border-box; }
    .colorSpace > .hue-scale > .hue-indicator-drag {
      border: 2px solid #fff;
      background-color: transparent; }

.color-format > .color-values-wrapper {
  position: relative;
  margin: 18px 17px 12px 17px;
  max-height: 24px; }
  .color-format > .color-values-wrapper > .color-value-hex {
    width: 175px;
    margin: 0 auto 0 auto; }
  .color-format > .color-values-wrapper > .color-values > .color-value-item {
    position: relative;
    display: inline-block;
    width: 33%; }
    .color-format > .color-values-wrapper > .color-values > .color-value-item:nth-child(1) {
      text-align: left; }
    .color-format > .color-values-wrapper > .color-values > .color-value-item:nth-child(2) {
      text-align: center; }
    .color-format > .color-values-wrapper > .color-values > .color-value-item:nth-child(3) {
      text-align: right; }

.palette-displayer {
  width: 210px; }
  .palette-displayer > .palette-color-option {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 42px;
    height: 24px; }
    .palette-displayer > .palette-color-option.white-option {
      box-sizing: border-box;
      border: 1px solid #ccc; }
    .palette-displayer > .palette-color-option.selectable {
      cursor: pointer; }
      .palette-displayer > .palette-color-option.selectable.option-selected:after, .palette-displayer > .palette-color-option.selectable:not(.option-selected):hover:after, .palette-displayer > .palette-color-option.selectable.white-option.option-selected:after, .palette-displayer > .palette-color-option.selectable.white-option:not(.option-selected):hover:after {
        content: "";
        position: absolute;
        width: 46px;
        height: 28px;
        background-color: inherit; }
      .palette-displayer > .palette-color-option.selectable.option-selected:after, .palette-displayer > .palette-color-option.selectable:not(.option-selected):hover:after {
        top: -1px;
        left: -2px; }
      .palette-displayer > .palette-color-option.selectable.white-option.option-selected:after, .palette-displayer > .palette-color-option.selectable.white-option:not(.option-selected):hover:after {
        top: -2px;
        left: -3px; }
      .palette-displayer > .palette-color-option.selectable.option-selected:after, .palette-displayer > .palette-color-option.selectable.white-option:not(.option-selected):hover:after {
        box-sizing: border-box;
        box-shadow: 0 0 7px 0 rgba(22, 45, 61, 0.44);
        border: solid 1px #fff; }
      .palette-displayer > .palette-color-option.selectable.option-selected:after {
        z-index: 2; }
      .palette-displayer > .palette-color-option.selectable:not(.option-selected):hover:after {
        z-index: 1; }
      .palette-displayer > .palette-color-option.selectable.white-option.option-selected:after {
        border-color: #ccc; }

.palettes-list {
  position: relative;
  width: 138px;
  height: 100%;
  text-align: center; }
  .palettes-list > .palette-options {
    display: inline;
    vertical-align: top; }
    .palettes-list > .palette-options > .palette-option-wrapper {
      padding: 3px 0;
      display: inline-block;
      cursor: pointer; }
      .palettes-list > .palette-options > .palette-option-wrapper:hover > .palette-option {
        border-color: #d3edff;
        transition: border-color 0.4s; }
      .palettes-list > .palette-options > .palette-option-wrapper > .palette-option {
        position: relative;
        display: inline-block;
        height: 24px;
        line-height: 24px;
        border: 1px solid transparent;
        font-size: 0;
        padding: 3px; }
        .palettes-list > .palette-options > .palette-option-wrapper > .palette-option.selected {
          border-color: #3899ec; }
          .palettes-list > .palette-options > .palette-option-wrapper > .palette-option.selected:before {
            position: absolute;
            content: '';
            font-size: initial;
            left: -11px;
            cursor: initial;
            width: 0;
            height: 0;
            border-right: 6px solid #3899ec;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            top: 9px; }
        .palettes-list > .palette-options > .palette-option-wrapper > .palette-option > .palette-option-color {
          display: inline-block;
          width: 18px;
          height: 100%; }
          .palettes-list > .palette-options > .palette-option-wrapper > .palette-option > .palette-option-color.white-color {
            box-sizing: border-box;
            border: 1px solid #ccc; }

.color-picker-input {
  position: relative; }
  .color-picker-input > .color-picker-wrapper {
    width: 30px;
    height: 30px;
    transform: translateZ(0);
    border: 1px solid #cacaca;
    box-sizing: border-box;
    border-radius: 8px;
    background-size: 20px 20px;
    background-image: linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc), linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc);
    background-position: 5px 5px, 15px 15px;
    position: relative;
    cursor: pointer;
    overflow: hidden; }
    .color-picker-input > .color-picker-wrapper.disabled {
      opacity: 0.4; }
    .color-picker-input > .color-picker-wrapper > .color-picker-color {
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute; }
    .color-picker-input > .color-picker-wrapper:hover, .color-picker-input > .color-picker-wrapper.colorPickerOpen {
      border: 2px solid #7fccf7;
      background-position: 4px 4px, 14px 14px; }
  .color-picker-input > .color-picker-label {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    color: #2b5672;
    height: 30px;
    line-height: 30px;
    cursor: pointer; }
    .color-picker-input > .color-picker-label:hover + .color-picker-wrapper {
      border: 2px solid #7fccf7; }
    .color-picker-input > .color-picker-label ~ .color-picker-wrapper {
      position: absolute;
      right: 0;
      top: 0; }
  .color-picker-input > .divider-long,
  .color-picker-input > .rich-text-paragraph {
    margin-top: 14px; }
  .color-picker-input-with-opacity .color-picker-input {
    position: absolute;
    right: 0;
    top: 2px; }

.color-picker-input-with-opacity {
  position: relative; }
  .color-picker-input-with-opacity > .color-picker-input-with-opacity-label {
    display: block;
    font-size: 14px;
    color: #2b5672;
    overflow: hidden;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    text-overflow: ellipsis; }
    .color-picker-input-with-opacity > .color-picker-input-with-opacity-label.disabled {
      opacity: 0.5; }
  .color-picker-input-with-opacity > .color-picker-input-with-opacity-slider {
    position: relative; }
  .color-picker-input-with-opacity > .divider-long {
    margin-top: 14px; }
  .color-picker-input-with-opacity > .rich-text-paragraph {
    margin-top: 14px; }

section.tabs {
  overflow: hidden;
  position: relative; }
  section.tabs > ul.tab-header > li.tab-label {
    cursor: pointer;
    display: block; }
  section.tabs > section.tab-content {
    position: relative; }
  section.tabs.left {
    display: flex; }
    section.tabs.left > section.tab-content {
      align-self: stretch;
      flex-grow: 1; }
  section.tabs.top > ul.tab-header {
    display: block;
    overflow: hidden;
    width: 100%; }
    section.tabs.top > ul.tab-header > li.tab-label {
      float: left; }
  section.tabs.radio > ul.tab-header > li.tab-label {
    line-height: 16px;
    margin-bottom: 15px;
    padding-left: 28px;
    position: relative; }
    section.tabs.radio > ul.tab-header > li.tab-label:before, section.tabs.radio > ul.tab-header > li.tab-label:after {
      content: '';
      border-radius: 50%;
      display: block;
      position: absolute; }
    section.tabs.radio > ul.tab-header > li.tab-label:before {
      top: calc(50% - 8px);
      border: 1px solid #3899ec;
      box-sizing: border-box;
      height: 16px;
      left: 0;
      width: 16px; }
    section.tabs.radio > ul.tab-header > li.tab-label.selected:after {
      top: calc(50% - 6px);
      background-color: #3899ec;
      height: 12px;
      left: 2px;
      width: 12px; }
    section.tabs.radio > ul.tab-header > li.tab-label:hover:before {
      background-color: #d3edff; }

.control-section-divider.labeled {
  position: relative; }
  .control-section-divider.labeled > .section-divider-content {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 16px;
    cursor: default;
    color: #2b5672; }

.control-section-divider .tooltip-on-ellipsis-content.section-divider-content {
  position: static; }

.tooltip-presenter {
  box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.36);
  z-index: 5999;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fff;
  border-radius: 8px; }
  .tooltip-presenter .arrow {
    transform: rotate(45deg);
    background-color: #fff; }
    .tooltip-presenter .arrow:after {
      content: '';
      z-index: -1;
      position: absolute;
      top: calc(100% - 1px);
      left: 0;
      width: 100%;
      height: 1px;
      box-shadow: 0 2px 4px 0 rgba(22, 45, 61, 0.27); }
    .tooltip-presenter .arrow:before {
      content: '';
      z-index: -1;
      position: absolute;
      top: 0;
      left: calc(100% - 1px);
      width: 1px;
      height: 100%;
      box-shadow: 2px 0 4px 0 rgba(22, 45, 61, 0.27); }
  .tooltip-presenter.alignment-bottom .arrow:after {
    top: 0;
    left: 0;
    box-shadow: 0 -2px 4px 0 rgba(22, 45, 61, 0.27); }
  .tooltip-presenter.alignment-bottom .arrow:before {
    top: 0;
    left: 0;
    box-shadow: -2px 0 4px 0 rgba(22, 45, 61, 0.27); }
  .tooltip-presenter.alignment-right .arrow:after {
    top: calc(100% - 1px);
    left: 0;
    box-shadow: 0 2px 4px 0 rgba(22, 45, 61, 0.27); }
  .tooltip-presenter.alignment-right .arrow:before {
    top: 0;
    left: 0;
    box-shadow: -2px 0 4px 0 rgba(22, 45, 61, 0.27); }
  .tooltip-presenter.alignment-left .arrow:after {
    top: 0;
    left: 0;
    box-shadow: 0 -2px 4px 0 rgba(22, 45, 61, 0.27); }
  .tooltip-presenter.alignment-left .arrow:before {
    top: 0;
    left: calc(100% - 1px);
    box-shadow: 2px 0 4px 0 rgba(22, 45, 61, 0.27); }
  .tooltip-presenter .content-wrapper {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    position: relative;
    color: #2b5672; }
  .tooltip-presenter.normal-tooltip .content-wrapper {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    padding: 18px;
    color: #2b5672; }
  .tooltip-presenter.small-tooltip .content-wrapper {
    padding: 12px; }
  .tooltip-presenter.tooltip-transition {
    transition: 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) top, 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) left; }
  .tooltip-presenter.tooltip-behind-pop-ups {
    z-index: 1500; }
  .tooltip-presenter.tooltip-behind-pages-panel {
    z-index: 1253; }
  .tooltip-presenter.tooltip-shake {
    animation: shake-animation 0.3s linear; }
  .tooltip-presenter .content {
    word-wrap: break-word; }
  .rulers .tooltip-presenter {
    position: absolute;
    border-radius: 15px;
    height: 30px;
    white-space: nowrap;
    cursor: default; }
    .rulers .tooltip-presenter.hover-bubble {
      height: 24px; }
      .rulers .tooltip-presenter.hover-bubble .input-stepper {
        margin-top: 2px; }
    .rulers .tooltip-presenter.hover-bubble:hover {
      background-color: #d3edff; }
      .rulers .tooltip-presenter.hover-bubble:hover .input-stepper .input {
        background-color: #d3edff; }
    .rulers .tooltip-presenter .content-wrapper {
      padding: 0; }

@keyframes shake-animation {
  1% {
    transform: translate(-20px); }
  20% {
    transform: translate(10px); }
  40% {
    transform: translate(-5px); }
  60% {
    transform: translate(3px); } }

.quick-tip {
  white-space: normal;
  padding: 24px;
  width: 236px; }
  .quick-tip .close-quick-tip {
    position: absolute;
    right: 12px;
    top: 12px;
    height: 7px;
    width: 7px;
    cursor: pointer; }
    .quick-tip .close-quick-tip svg {
      position: absolute; }
  .quick-tip .text-container {
    display: inline-block;
    vertical-align: top;
    padding-left: 12px;
    width: 190px; }
    .quick-tip .text-container .text {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      color: #2b5672;
      display: inline; }
      .quick-tip .text-container .text.link-action {
        cursor: pointer;
        color: #3899ec;
        border-bottom: 1px dashed currentColor; }
        .quick-tip .text-container .text.link-action:hover {
          color: #4eb7f5; }
    .quick-tip .text-container.text-no-image {
      padding: 18px; }
  .quick-tip .quick-tip-image {
    display: inline-block;
    vertical-align: top; }
    .quick-tip .quick-tip-image svg {
      width: 34px;
      height: 34px; }

.imageAndTextTooltip {
  white-space: normal; }
  .imageAndTextTooltip .text-container {
    padding: 24px; }
    .imageAndTextTooltip .text-container .title {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 18px;
      color: #2b5672;
      margin-bottom: 12px; }
    .imageAndTextTooltip .text-container .text {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      color: #2b5672; }
    .imageAndTextTooltip .text-container .learn-more {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      cursor: pointer;
      color: #3899ec;
      margin-top: 10px; }
      .imageAndTextTooltip .text-container .learn-more :hover {
        text-decoration: underline; }
    .imageAndTextTooltip .text-container.text-no-image {
      padding: 18px; }
  .imageAndTextTooltip .image-container {
    background-color: #3899ec;
    text-align: center;
    left: 0;
    right: 0; }
    .imageAndTextTooltip .image-container.upper-image {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px; }
    .imageAndTextTooltip .image-container.lower-image {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px; }
    .imageAndTextTooltip .image-container svg {
      margin-top: 22px;
      margin-bottom: 22px; }
  .imageAndTextTooltip .close {
    float: right;
    cursor: pointer;
    margin: -6px -6px 0 0; }

.icon-and-text {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  padding: 18px;
  color: #2b5672;
  display: flex;
  align-items: center; }
  .icon-and-text svg {
    margin-right: 10px; }
  .icon-and-text span {
    flex: 1 1 auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }

.titleBodyAndLinkTooltip {
  white-space: normal;
  max-width: 300px;
  padding: 18px; }
  .titleBodyAndLinkTooltip .title {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 18px;
    color: #2b5672;
    margin-bottom: 6px; }
  .titleBodyAndLinkTooltip .text {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    color: #2b5672; }
  .titleBodyAndLinkTooltip .link {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    color: #3899ec;
    cursor: pointer;
    margin-top: 11px; }
    .titleBodyAndLinkTooltip .link :hover {
      text-decoration: underline; }
  .titleBodyAndLinkTooltip .close {
    float: right;
    cursor: pointer;
    margin: -6px -6px 0 0; }

.keyboardShortcutTooltip .label {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  color: #2b5672; }

.keyboardShortcutTooltip .shortcut {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 12px;
  color: #7a92a5;
  margin-left: 2px; }

.tooltips-layer {
  top: 0;
  left: 0; }

@font-face {
  font-family: "HelveticaNeueW01-45Ligh";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/5a13f7d1-b615-418e-bc3a-525001b9a671.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/530dee22-e3c1-4e9f-bf62-c31d510d9656.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW01-55Roma";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b0868b4c-234e-47d3-bc59-41ab9de3c0db.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/bcf54343-d033-41ee-bbd7-2b77df3fe7ba.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW01-65Medi";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/3600b37f-2bf1-45f3-be3a-03365f16d9cb.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/60be5c39-863e-40cb-9434-6ebafb62ab2b.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW01-75Bold";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/3b303641-706e-4221-94c4-4fb491f4f8ef.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/14ff6081-326d-4dae-b778-d7afa66166fc.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW01-UltLt";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/9baef9a5-e2af-4838-a3bc-da9d36c0bde8.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/4bff1fbb-b4bf-4d95-9c47-efcb14384e36.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW01-Thin";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/aff68211-86bb-476d-882e-f7a3face144c.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/50d35bbc-dfd4-48f1-af16-cf058f69421d.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW01-95Blac";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/fd05322c-bf43-4f8e-a129-24869b0942db.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/74649485-cd74-443e-9d54-331ccd448900.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-45Ligh";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/7732d6de-bd05-4c17-ae43-99e9a02edf31.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/9a2e4855-380f-477f-950e-d98e8db54eac.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-55Roma";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b4cd926c-1a31-4785-b261-1db1a68c521e.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/d5af76d8-a90b-4527-b3a3-182207cc3250.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-65Medi";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/9f93e286-eeaa-4077-b0b2-0e6901920f74.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/5b4a262e-3342-44e2-8ad7-719998a68134.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-75Bold";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/2a5cf81d-ffbe-4c76-8982-270592fb36b0.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/4a9c62ab-b359-4081-8383-a0d1cdebd111.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-UltLt";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b39b5378-b594-45fe-8067-fc4385d08520.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/84558c76-9f1b-44d2-ac62-d7937f43809b.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-Thin";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/96b33a5f-d3a7-4a34-b360-76867695bb83.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/775a65da-14aa-4634-be95-6724c05fd522.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW02-95Blac";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/a9e72f14-d263-440b-b2a4-fa729879fb51.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/6d5b06b3-f8db-4de6-aa46-2a6de9569b51.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-45Ligh";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/bb85cc70-1239-4a3c-b9c7-4da74afaf032.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/835e7b4f-b524-4374-b57b-9a8fc555fd4e.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-55Roma";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/cf45dd62-4ace-47a2-abee-6fe9e95a1daa.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/8ac9e38d-29c6-41ea-8e47-4ae4d2b1a4e1.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-65Medi";
  font-weight: bold;
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/da3af6d8-5634-4072-8989-1ddf770e3142.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/5b29e833-1b7a-40ab-82a5-cfd69c8650f4.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-75Bold";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/51f47b5d-143f-4d30-a6c3-23217fc0136b.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/d85949a1-c37a-43f7-9d09-fb056acf0c27.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-25UltL";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/277370bf-544b-4d3b-9f99-b9566a4b722b.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/723fd1ed-5aad-454d-af88-3711c5caf1c7.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-95Blac";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/e2c2affa-fc35-4c36-aabd-33c8665d0531.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b1d7b778-bdf9-4ff3-b4e8-8cdabf234863.woff?1") format("woff"); }

@font-face {
  font-family: "HelveticaNeueW10-35Thin";
  src: url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/9ec06c5b-556a-4dc4-9db2-425acf9fd515.woff2?1") format("woff2"), url("https://static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/c881c21b-4148-4a11-a65d-f35e42999bc8.woff?1") format("woff"); }

.add-thumbnail-button {
  display: flex;
  flex-direction: column;
  cursor: pointer; }
  .add-thumbnail-button > .add-thumbnail-symbol-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #7fccf7;
    box-sizing: border-box;
    border-radius: 8px;
    color: #3899ec; }
    .add-thumbnail-button:hover > .add-thumbnail-symbol-container {
      background-color: #eaf7ff; }
    .add-thumbnail-button > .add-thumbnail-symbol-container .line {
      fill: #3899ec; }
  .add-thumbnail-button > .add-thumbnail-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    margin-top: 12px;
    color: #3899ec; }

.composite-with-info-icon {
  position: relative; }

.control-label-base {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  line-height: 18px;
  display: inherit; }
  .control-label-base.disabled {
    color: inherit; }
  .bubble .bubble-content > .control-label-base {
    display: inline-block;
    color: #2b5672;
    user-select: none;
    cursor: default;
    max-width: 100%; }
    .bubble .bubble-content > .control-label-base.disabled {
      color: #939393; }
  .control-action-with-symbol > .content-container .content .control-label-base,
  .control-section-divider .section-divider-content .control-label-base,
  .input-container > .input-prefix .control-label-base,
  .control-google-preview .control-label-base {
    font-size: inherit;
    font-family: inherit;
    line-height: inherit; }

.bubble > .bubble-content {
  cursor: auto;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  min-width: 72px;
  box-shadow: 0 0 22px 0 rgba(22, 45, 61, 0.26);
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 8px;
  padding: 18px;
  word-wrap: break-word; }
  .tooltip.bubble > .bubble-content {
    position: fixed;
    z-index: 5999; }

.bubble > .bubble-arrow {
  cursor: auto;
  width: 0;
  height: 0; }
  .arrow-top.bubble > .bubble-arrow {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff; }
  .arrow-right.bubble > .bubble-arrow {
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #fff; }
  .arrow-bottom.bubble > .bubble-arrow {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #fff; }
  .arrow-left.bubble > .bubble-arrow {
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #fff; }
  .tooltip.bubble > .bubble-arrow {
    position: fixed;
    z-index: 5999;
    background-color: #fff;
    content: '';
    transform: translate(-50%, -50%) rotate(45deg);
    padding: 5px;
    border: none; }
    .arrow-bottom.tooltip.bubble > .bubble-arrow {
      box-shadow: 2px 2px 6px -2px rgba(0, 0, 0, 0.35); }
    .arrow-top.tooltip.bubble > .bubble-arrow {
      box-shadow: -2px -2px 6px -2px rgba(0, 0, 0, 0.35); }
    .arrow-left.tooltip.bubble > .bubble-arrow {
      box-shadow: -2px 2px 6px -2px rgba(0, 0, 0, 0.35); }
    .arrow-right.tooltip.bubble > .bubble-arrow {
      box-shadow: 2px -2px 6px -2px rgba(0, 0, 0, 0.35); }

.has-tooltip.info-icon-tooltip {
  display: inline-flex;
  align-items: center;
  justify-content: center; }

.control-label .has-tooltip {
  display: inline-flex;
  width: 100%; }
  .control-thumbnail .control-label .has-tooltip,
  .add-thumbnail-button .control-label .has-tooltip {
    justify-content: center; }

.composite-with-info-icon > .has-tooltip.info-icon-tooltip {
  position: absolute;
  top: 12px;
  right: 12px; }

.composite-banner > .has-tooltip.info-icon-tooltip {
  top: 6px; }

.control-action-with-symbol .has-tooltip,
.control-vertical-tabs .has-tooltip {
  display: inline-flex;
  width: 100%; }

.composite-checkboxes-labeled > .composite-checkbox-with-info-icon > .has-tooltip.info-icon-tooltip,
.composite-checkboxes > .composite-checkbox-with-info-icon > .has-tooltip.info-icon-tooltip {
  top: 0;
  right: calc(12px - 24px); }

.dropdown-detailed-option .has-tooltip {
  width: 100%;
  display: block; }

.dropdown-theme-option .has-tooltip {
  max-width: calc(100% - 84px);
  padding: 0 14px; }

.control-upload-button .bottom-message-container .has-tooltip {
  overflow: hidden; }

.tooltip-leave {
  opacity: 1; }
  .tooltip-leave.tooltip-leave-active {
    transition: opacity 0.2s ease-in-out;
    opacity: 0.01; }

.symbol-input-validation-error {
  fill: #ee5951; }
  .symbol-input-validation-error .c1,
  .symbol-input-validation-error .c2 {
    fill: #fff; }

.symbol-input-validation-success {
  fill: #60bc57; }
  .symbol-input-validation-success .c1 {
    fill: #fff; }

.input-container {
  display: flex;
  position: relative; }
  :not(.composite-text-input-labeled) > .input-container.control-numeric-input {
    min-width: 66px; }
  .input-container.control-numeric-input.numeric-corner {
    min-width: 48px;
    width: 48px; }
  .control-angle-input:not(.disabled) .input-container.control-numeric-input {
    user-select: none; }
  .control-angle-input .input-container.control-numeric-input,
  .composite-color-picker-input .control-slider-input .input-container.control-numeric-input {
    min-width: 54px; }
  .color-picker-color-format .input-container.control-numeric-input {
    min-width: 48px; }
  .control-angle-input .input-container.control-numeric-input {
    width: 54px; }
  .control-slider-input .input-container.control-numeric-input {
    margin-left: 12px; }
  .composite-text-input-multiline-with-button-labeled.composite-full-height .input-container.input-multiline {
    height: calc(100% - 48px); }
  .input-container > .input-prefix {
    line-height: 35px;
    font-size: 18px;
    padding: 0 9px 0 12px;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #b6c1cd;
    display: flex; }
    .control-numeric-input.input-container > .input-prefix {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      color: #2b5672;
      padding-right: 18px; }
    .input-container:not(.is-disabled) > .input-prefix.clickable {
      cursor: pointer; }
      .input-container:not(.is-disabled) > .input-prefix.clickable:hover + .input:not(:focus) {
        background-color: #eaf7ff; }
    .composite-numeric-input .input-container > .input-prefix {
      padding-left: 0;
      width: 100%; }
  .input-container > .input {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 18px;
    display: block;
    box-sizing: border-box;
    height: 36px;
    padding: 0 11px 0;
    border: 1px solid transparent;
    outline: none;
    border-radius: 8px;
    color: #162d3d;
    text-align: left;
    text-overflow: ellipsis;
    width: 100%; }
    .input-container > .input::placeholder {
      color: #b6c1cd; }
    .input-container > .input.has-text-suffix {
      padding: 0 5px; }
    .input-container > .input:focus ~ .input-text-suffix {
      display: none; }
    .success.input-container > .input:not(:focus), .invalid.input-container > .input:not(:focus) {
      width: calc(100% - 42px); }
    .input-multiline.success.input-container > .input:not(:focus), .input-multiline.invalid.input-container > .input:not(:focus) {
      width: calc(100% - 54px); }
    :not(.composite-text-input-labeled) > .control-numeric-input.input-container > .input {
      width: 66px;
      text-align: right;
      text-overflow: clip; }
      .color-picker-color-format :not(.composite-text-input-labeled) > .control-numeric-input.input-container > .input {
        width: 48px; }
    .control-corner .control-numeric-input.input-container > .input {
      width: 48px;
      height: 24px;
      font-size: 12px; }
    .composite-corner-radius-input:hover .control-numeric-input.input-container > .input:not(:hover):not(:focus), .composite-corner-radius-input-labeled:hover .control-numeric-input.input-container > .input:not(:hover):not(:focus), .control-corner-radius-input:hover .control-numeric-input.input-container > .input:not(:hover):not(:focus) {
      background-color: #eaf7ff;
      color: #2b5672; }
    .control-angle-input .control-numeric-input.input-container > .input, .composite-color-picker-input .control-slider-input .control-numeric-input.input-container > .input {
      width: 54px; }
    .percent-unit.control-numeric-input.input-container > .input {
      padding-right: 17px; }
    .px-unit.control-numeric-input.input-container > .input {
      padding-right: 18px; }
    .angle-unit.control-numeric-input.input-container > .input {
      padding-right: 10px; }
    .is-disabled.input-container > .input {
      color: #7a7a7a;
      background-color: transparent; }
    .invalid.input-container:not(.is-disabled) > .input:focus {
      border: 1px solid #ee5951; }
    .input-container:not(.is-disabled) > .input:hover {
      background-color: #d3edff;
      color: #2b5672;
      cursor: pointer; }
      .composite-angle-input-labeled:hover .input-container:not(.is-disabled) > .input:hover, .composite-slider:hover .input-container:not(.is-disabled) > .input:hover, .composite-slider-labeled:hover .input-container:not(.is-disabled) > .input:hover, .composite-text-input-multiline-with-button-labeled:hover .input-container:not(.is-disabled) > .input:hover, .composite-text-input-labeled:hover > .input-container:not(.is-disabled) > .input:hover {
        background-color: #d3edff;
        color: #2b5672; }
      .input-container:not(.is-disabled) > .input:hover::placeholder {
        color: #2b5672; }
    .input-container:not(.is-disabled) > .input:focus {
      background-color: transparent;
      border: 1px solid #7fccf7;
      box-shadow: inset 0 0 3px 0 rgba(0, 90, 132, 0.25);
      cursor: text; }
      .composite-angle-input-labeled:hover .input-container:not(.is-disabled) > .input:focus, .composite-slider:hover .input-container:not(.is-disabled) > .input:focus, .composite-slider-labeled:hover .input-container:not(.is-disabled) > .input:focus, .composite-text-input-multiline-with-button-labeled:hover .input-container:not(.is-disabled) > .input:focus, .composite-text-input-labeled:hover > .input-container:not(.is-disabled) > .input:focus {
        background-color: transparent; }
      .input-container:not(.is-disabled) > .input:focus::placeholder {
        color: #b6c1cd; }
      .input-container:not(.is-disabled) > .input:focus::selection {
        background-color: #eaf7ff; }
    .composite-angle-input-labeled:hover .input-container:not(.is-disabled) > .input, .composite-slider:hover .input-container:not(.is-disabled) > .input, .composite-slider-labeled:hover .input-container:not(.is-disabled) > .input, .composite-text-input-multiline-with-button-labeled:hover .input-container:not(.is-disabled) > .input, .composite-text-input-labeled:hover > .input-container:not(.is-disabled) > .input {
      background-color: #eaf7ff;
      color: #2b5672; }
      .composite-angle-input-labeled:hover .input-container:not(.is-disabled) > .input:focus, .composite-slider:hover .input-container:not(.is-disabled) > .input:focus, .composite-slider-labeled:hover .input-container:not(.is-disabled) > .input:focus, .composite-text-input-multiline-with-button-labeled:hover .input-container:not(.is-disabled) > .input:focus, .composite-text-input-labeled:hover > .input-container:not(.is-disabled) > .input:focus {
        background-color: transparent; }
    .input-multiline.input-container > .input {
      height: 108px;
      line-height: 24px;
      padding-top: 5px;
      padding-bottom: 7px;
      resize: none;
      overflow: hidden;
      padding: 5px 12px 7px;
      transition: height 200ms ease; }
      .input-multiline.input-container > .input:focus {
        overflow: auto;
        height: 156px; }
      .input-large.input-multiline.input-container > .input {
        height: 252px;
        line-height: 24px;
        padding-top: 5px;
        padding-bottom: 7px; }
        .input-large.input-multiline.input-container > .input:focus {
          overflow: auto;
          height: 300px; }
      .composite-text-input-multiline-with-button-labeled.composite-full-height .input-multiline.input-container > .input {
        height: 100%; }
  .input-container > .input-text-suffix {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 12px;
    display: flex;
    position: absolute;
    right: 5px;
    pointer-events: none; }
    .is-disabled.input-container > .input-text-suffix {
      color: #7a7a7a; }
    .input-container:hover:not(.is-disabled) > .input-text-suffix {
      color: #2b5672; }
    .color-picker-color-format-input-container .input-container > .input-text-suffix {
      line-height: 24px; }
    .percent-unit.input-container > .input-text-suffix {
      bottom: calc((36px - 18px) / 2); }
      .color-picker-color-format-input-container .percent-unit.input-container > .input-text-suffix {
        bottom: 0; }
    .px-unit.input-container > .input-text-suffix {
      bottom: calc((36px - 18px) / 2); }
    .angle-unit.input-container > .input-text-suffix {
      top: calc((36px - 18px) / 2); }
      .color-picker-color-format-input-container .angle-unit.input-container > .input-text-suffix {
        top: 0; }
    .control-corner .input-container > .input-text-suffix {
      font-size: 12px;
      color: #162d3d;
      bottom: calc((24px - 15px) / 2);
      user-select: none; }
    .control-corner-radius-input:hover .input-container > .input-text-suffix {
      color: #2b5672; }
  .input-container > .input-validation-icon {
    position: absolute;
    height: calc(100% + 6px);
    width: 25px;
    right: 12px;
    top: -3px;
    z-index: 1;
    pointer-events: none;
    display: flex;
    align-items: center; }
    .input-multiline.input-container > .input-validation-icon {
      height: 100%;
      top: 5px;
      align-items: flex-start; }
    .control-editable-url .input-container > .input-validation-icon {
      right: 6px; }
    .input-container > .input-validation-icon > .input-validation-icon-inner {
      display: flex;
      align-items: center; }
  .input-container > .input:focus ~ .input-validation-icon,
  .input-container > .input-editable-url:focus ~ .input-validation-icon {
    visibility: hidden; }
  .control-editable-url .input-container {
    height: 100%;
    flex: 1; }
    .control-editable-url .input-container.invalid > .input-editable-url:not(:focus), .control-editable-url .input-container.success > .input-editable-url:not(:focus) {
      width: calc(100% - 36px); }
    .control-editable-url .input-container > .input-editable-url {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px;
      width: 100%;
      height: 100%;
      color: #2b5672;
      background-color: #fff;
      border: 1px solid #fff;
      border-radius: 4px;
      outline: none;
      box-sizing: border-box;
      transition: padding ease-out 150ms; }
      .control-editable-url .input-container > .input-editable-url::selection {
        background-color: #4eb7f5;
        color: #fff; }
      .control-editable-url .input-container > .input-editable-url:read-only {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 16px;
        cursor: default;
        background-color: #fff; }
  .control-editable-url.focus .input-container > .input-editable-url {
    border-color: #7fccf7;
    box-shadow: inset 0 0 3px 0 rgba(43, 86, 114, 0.55);
    padding-left: 5px;
    margin-left: 2px; }
  .control-editable-url.focus .input-container.invalid > .input-editable-url {
    border-color: #ee5951; }
  .control-editable-url.with-edit-buttons:not(.focus) .input-container > .input-editable-url {
    pointer-events: none; }

.control-corner {
  display: flex; }
  .control-corner.top {
    flex-direction: column; }
  .control-corner.bottom {
    flex-direction: column-reverse; }
  .control-corner.right {
    align-items: flex-end; }
  .control-corner.left {
    align-items: flex-start; }
  .control-corner .corner-border {
    width: 40px;
    height: 22px;
    border: 0 solid #7a92a5; }
    .top.control-corner .corner-border {
      border-top-width: 2px;
      margin-top: 6px; }
    .bottom.control-corner .corner-border {
      border-bottom-width: 2px;
      margin-bottom: 6px; }
    .right.control-corner .corner-border {
      border-right-width: 2px;
      margin-right: 42px; }
    .left.control-corner .corner-border {
      border-left-width: 2px;
      margin-left: 42px; }
    .composite-corner-radius-input:hover .control-corner-radius-input.locked .control-corner .corner-border,
    .composite-corner-radius-input-labeled:hover .control-corner-radius-input.locked .control-corner .corner-border,
    .control-corner-radius-input.locked:hover .control-corner .corner-border {
      border-color: #7fccf7; }
  .control-corner .numeric-corner:hover ~ .corner-border {
    border-color: #7fccf7; }

.control-boolean {
  cursor: pointer;
  display: flex; }
  .control-boolean.control-switch {
    align-items: stretch;
    justify-content: space-between; }
  .control-boolean.disabled {
    cursor: default; }
  .control-boolean > .input {
    display: none; }
  .control-boolean.text-formatting-toggle-button {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .control-boolean.text-formatting-toggle-button:not(.disabled):active, .control-boolean.text-formatting-toggle-button:not(.disabled).selected {
      background-color: #3899ec; }
      .control-boolean.text-formatting-toggle-button:not(.disabled):active:hover, .control-boolean.text-formatting-toggle-button:not(.disabled).selected:hover {
        background-color: #4eb7f5; }
    .control-boolean.text-formatting-toggle-button:not(.disabled):not(.selected):not(:active):hover {
      background-color: #eaf7ff; }
  .composite-text-formatting .control-boolean {
    margin: 0 5px; }
  .control-corner-radius-input .control-boolean {
    border-radius: 6px;
    align-self: center;
    width: 24px;
    flex: 22px 0 0;
    border: 1px solid; }
    .control-corner-radius-input .control-boolean .symbol {
      width: 100%;
      display: flex;
      justify-content: center; }
      .control-corner-radius-input .control-boolean .symbol svg {
        display: block;
        align-self: center; }
  .control-corner-radius-input.locked .control-boolean {
    background-color: #3899ec;
    border-color: #3899ec; }
    .control-corner-radius-input.locked .control-boolean svg {
      fill: #fff; }
    .control-corner-radius-input.locked .control-boolean:hover {
      background-color: #4eb7f5;
      border-color: #4eb7f5; }
  .control-corner-radius-input.unlocked .control-boolean {
    background-color: #fff;
    border-color: #d9e1e8; }
    .control-corner-radius-input.unlocked .control-boolean svg {
      fill: #7a92a5; }
    .control-corner-radius-input.unlocked .control-boolean:hover {
      background-color: #d3edff;
      border-color: #d3edff; }

.control-corner-radius-input {
  width: 192px;
  height: 132px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .control-corner-radius-input .top,
  .control-corner-radius-input .bottom {
    display: flex;
    justify-content: space-between; }
  .composite-corner-radius-input .control-corner-radius-input {
    padding: 12px 24px 24px; }
  .composite-corner-radius-input-labeled .control-corner-radius-input {
    padding: 8px 24px 24px; }

.custom-scroll {
  min-height: 0;
  min-width: 0; }
  .custom-scroll .outer-container {
    overflow: hidden;
    position: relative; }
    .custom-scroll .outer-container:hover .custom-scrollbar {
      opacity: 1;
      transition-duration: 0.2s; }
  .custom-scroll .inner-container {
    overflow-x: hidden;
    overflow-y: scroll; }
    .custom-scroll .inner-container:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 0;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.05) 60%, transparent 100%);
      pointer-events: none;
      transition: height 0.1s ease-in;
      will-change: height; }
    .custom-scroll .inner-container.content-scrolled:after {
      height: 5px;
      transition: height 0.15s ease-out; }
  .custom-scroll.scroll-handle-dragged .inner-container {
    user-select: none; }
  .custom-scroll .custom-scrollbar {
    position: absolute;
    height: 100%;
    width: 6px;
    right: 3px;
    opacity: 0;
    z-index: 1;
    transition: opacity 0.4s ease-out;
    padding: 6px 0;
    box-sizing: border-box;
    will-change: opacity;
    pointer-events: none; }
    .custom-scroll .custom-scrollbar.custom-scrollbar-rtl {
      right: auto;
      left: 3px; }
  .custom-scroll.scroll-handle-dragged .custom-scrollbar {
    opacity: 1; }
  .custom-scroll .custom-scroll-handle {
    position: absolute;
    width: 100%;
    top: 0; }
  .custom-scroll .inner-handle {
    height: calc(100% - 12px);
    margin-top: 6px;
    background-color: rgba(78, 183, 245, 0.7);
    border-radius: 3px; }

.tooltip-on-ellipsis-content {
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }
  .tooltip-on-ellipsis-content::after {
    content: '';
    display: block; }
  .tooltip-on-ellipsis-content :first-child {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
    .tooltip-on-ellipsis-content :first-child::after {
      content: '';
      display: block; }

.control-section-divider {
  height: 42px;
  box-sizing: border-box;
  background-color: #f0f3f5;
  padding: 0 42px;
  width: 100%; }
  .control-section-divider.disabled {
    background-color: #f0f0f0; }
  .composite-banner > .control-section-divider {
    background-color: #2b5672;
    height: 30px; }
  .control-section-divider .section-divider-content {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 16px;
    color: #2b5672;
    line-height: 42px;
    text-align: center; }
    .disabled.control-section-divider .section-divider-content {
      color: #939393; }
    .dropdown-options .control-section-divider .section-divider-content {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      line-height: 36px; }
    .composite-banner .control-section-divider .section-divider-content {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      line-height: 30px;
      color: #fff; }
  .dropdown-options .control-section-divider {
    height: 36px; }

.control-checkbox > .label-checkbox.t01,
.control-switch > .label-switch.t01,
.control-rich-text.t01,
.control-label-base.t01,
.control-action-with-symbol > .content-container .content .control-label-base.t01,
.panel-header-centered-text > .title.t01 {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 16px;
  line-height: 24px; }

.control-checkbox > .label-checkbox.t02,
.control-switch > .label-switch.t02,
.control-rich-text.t02,
.control-label-base.t02,
.control-action-with-symbol > .content-container .content .control-label-base.t02,
.panel-header-centered-text > .title.t02 {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  line-height: 18px; }

.control-checkbox > .label-checkbox.t03,
.control-switch > .label-switch.t03,
.control-rich-text.t03,
.control-label-base.t03,
.control-action-with-symbol > .content-container .content .control-label-base.t03,
.panel-header-centered-text > .title.t03 {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 18px;
  line-height: 24px; }

.control-checkbox > .label-checkbox.t04,
.control-switch > .label-switch.t04,
.control-rich-text.t04,
.control-label-base.t04,
.control-action-with-symbol > .content-container .content .control-label-base.t04,
.panel-header-centered-text > .title.t04 {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 12px;
  line-height: 18px; }

.control-checkbox > .label-checkbox.t05,
.control-switch > .label-switch.t05,
.control-rich-text.t05,
.control-label-base.t05,
.control-action-with-symbol > .content-container .content .control-label-base.t05,
.panel-header-centered-text > .title.t05 {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 16px;
  line-height: 18px; }

.control-checkbox > .label-checkbox.t06,
.control-switch > .label-switch.t06,
.control-rich-text.t06,
.control-label-base.t06,
.control-action-with-symbol > .content-container .content .control-label-base.t06,
.panel-header-centered-text > .title.t06 {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 16px;
  line-height: 24px; }

.control-checkbox > .label-checkbox.t07,
.control-switch > .label-switch.t07,
.control-rich-text.t07,
.control-label-base.t07,
.control-action-with-symbol > .content-container .content .control-label-base.t07,
.panel-header-centered-text > .title.t07 {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  line-height: 18px; }

.control-checkbox > .label-checkbox.t08,
.control-switch > .label-switch.t08,
.control-rich-text.t08,
.control-label-base.t08,
.control-action-with-symbol > .content-container .content .control-label-base.t08,
.panel-header-centered-text > .title.t08 {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 20px;
  line-height: 24px; }

.control-checkbox > .label-checkbox.t09,
.control-switch > .label-switch.t09,
.control-rich-text.t09,
.control-label-base.t09,
.control-action-with-symbol > .content-container .content .control-label-base.t09,
.panel-header-centered-text > .title.t09 {
  font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 18px;
  line-height: 24px; }

.control-checkbox > .label-checkbox.t10,
.control-switch > .label-switch.t10,
.control-rich-text.t10,
.control-label-base.t10,
.control-action-with-symbol > .content-container .content .control-label-base.t10,
.panel-header-centered-text > .title.t10 {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 13px;
  line-height: 18px; }

.control-checkbox > .label-checkbox.t11,
.control-switch > .label-switch.t11,
.control-rich-text.t11,
.control-label-base.t11,
.control-action-with-symbol > .content-container .content .control-label-base.t11,
.panel-header-centered-text > .title.t11 {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 18px;
  line-height: 24px; }

.control-checkbox > .label-checkbox.t12,
.control-switch > .label-switch.t12,
.control-rich-text.t12,
.control-label-base.t12,
.control-action-with-symbol > .content-container .content .control-label-base.t12,
.panel-header-centered-text > .title.t12 {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 12px;
  line-height: 12px; }

.control-checkbox > .label-checkbox.t13,
.control-switch > .label-switch.t13,
.control-rich-text.t13,
.control-label-base.t13,
.control-action-with-symbol > .content-container .content .control-label-base.t13,
.panel-header-centered-text > .title.t13 {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 30px;
  line-height: 36px; }

.control-checkbox > .label-checkbox.t14,
.control-switch > .label-switch.t14,
.control-rich-text.t14,
.control-label-base.t14,
.control-action-with-symbol > .content-container .content .control-label-base.t14,
.panel-header-centered-text > .title.t14 {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 12px;
  line-height: 24px; }

.control-checkbox > .label-checkbox.t15,
.control-switch > .label-switch.t15,
.control-rich-text.t15,
.control-label-base.t15,
.control-action-with-symbol > .content-container .content .control-label-base.t15,
.panel-header-centered-text > .title.t15 {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 12px;
  line-height: 18px; }

.control-checkbox > .label-checkbox.t16,
.control-switch > .label-switch.t16,
.control-rich-text.t16,
.control-label-base.t16,
.control-action-with-symbol > .content-container .content .control-label-base.t16,
.panel-header-centered-text > .title.t16 {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 26px;
  line-height: 36px; }

.control-checkbox > .label-checkbox.t17,
.control-switch > .label-switch.t17,
.control-rich-text.t17,
.control-label-base.t17,
.control-action-with-symbol > .content-container .content .control-label-base.t17,
.panel-header-centered-text > .title.t17 {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 13px;
  line-height: 25px; }

.control-label {
  display: inline-block;
  color: #2b5672;
  user-select: none;
  cursor: default;
  max-width: 100%; }
  .control-label.disabled {
    color: #939393; }
  .control-upload-button .control-label.with-ellipsis {
    overflow: hidden; }
  .control-thumbnails-list .control-label.with-ellipsis {
    display: inline-flex; }
  .dropdown-detailed-option-label .control-label {
    cursor: pointer; }
  .composite-color-select-labeled .control-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(100% - 48px); }
  .composite-dynamic-field-with-description .control-label,
  .composite-editable-url-labeled .control-label {
    display: block; }
    .composite-dynamic-field-with-description .control-label:nth-child(1),
    .composite-editable-url-labeled .control-label:nth-child(1) {
      margin-bottom: 5px; }
    .composite-dynamic-field-with-description .control-label:nth-child(2),
    .composite-editable-url-labeled .control-label:nth-child(2) {
      margin-bottom: 15px; }
  .composite-checkboxes-labeled .control-label,
  .composite-button-large-labeled .control-label {
    margin-bottom: 14px; }
  .composite-date-picker-labeled .control-label,
  .composite-rich-text-labeled .control-label {
    margin-bottom: 6px; }
  .composite-dock-labeled .control-label {
    margin-bottom: 18px; }
  .composite-dock-labeled .control-label,
  .composite-horizontal-tabs-labeled .control-label {
    margin-bottom: 18px; }
  .composite-angle-input-labeled .control-label {
    margin-bottom: 13px; }
  .composite-vertical-tabs-labeled .control-label {
    margin-bottom: 18px; }
  .composite-text-input-labeled .control-label,
  .composite-text-input-multiline-with-button-labeled .control-label,
  .composite-dropdown-with-button-labeled .control-label,
  .composite-dropdown-with-color-input-labeled .control-label,
  .composite-dropdown-labeled .control-label,
  .composite-action-with-symbol-labeled > .control-label,
  .composite-dynamic-field-labeled .control-label {
    margin-bottom: 2px; }
  .composite-color-picker-input-labeled .control-label {
    margin-bottom: 3px; }
  .composite-link-button-with-color-input-labeled > .control-label {
    margin-bottom: 5px; }
  .composite-radio-buttons-labeled .control-label {
    width: calc(100% - (48px - 24px));
    max-height: 36px;
    overflow: hidden;
    display: block;
    margin-bottom: 12px;
    display: inline-flex; }
  .composite-slider-labeled .control-label,
  .composite-dropdown-with-button-labeled .control-label,
  .composite-dropdown-labeled .control-label,
  .composite-button-large-labeled .control-label,
  .composite-checkboxes-labeled .control-label,
  .composite-action-with-symbol-labeled > .control-label {
    width: calc(100% - (42px - 24px));
    max-height: 36px;
    overflow: hidden;
    display: block; }
  .composite-dropdown-with-button-labeled .dropdown-selected .control-label,
  .composite-dropdown-labeled .dropdown-selected .control-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .composite-text-input-multiline-with-button-labeled .control-label,
  .composite-text-input-labeled .control-label {
    margin-left: 12px;
    width: calc(100% - (42px - 12px));
    max-height: 36px;
    overflow: hidden;
    display: block; }
  .composite-buttons-group-labeled .control-label {
    margin-bottom: 18px;
    margin-top: 0; }
  .composite-preloader .control-label {
    margin-top: 32px; }
  .composite-corner-radius-input-labeled .control-label {
    margin-left: 24px;
    margin-top: 16px; }
  .control-upload-button .button-content .control-label {
    color: inherit; }
  .control-upload-button .upload-status-message .control-label {
    color: #162d3d; }
  .control-tab .control-label {
    display: inline-flex;
    color: inherit;
    cursor: inherit; }
  .control-dropdown-option .dropdown-detailed-option-title > .control-label {
    cursor: pointer;
    color: #162d3d; }
  .control-dropdown-option.active .dropdown-detailed-option-title > .control-label {
    color: #2b5672; }
  .control-dropdown-option.selected .dropdown-detailed-option-title > .control-label {
    color: #FFF; }
  .control-dropdown-option .dropdown-detailed-option-description .control-label {
    cursor: pointer;
    color: #7a92a5; }
  .control-dropdown-option.selected .dropdown-detailed-option-description .control-label {
    color: #FFF; }
  .control-action-with-symbol > .content-container .content .control-label {
    color: inherit;
    cursor: inherit; }
  .control-section-divider .section-divider-content .control-label,
  .input-container > .input-prefix .control-label,
  .control-google-preview .control-label {
    color: inherit; }

.control-button .button-content .control-button-content {
  display: flex;
  align-items: center; }

.control-button {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  height: 36px;
  line-height: 16px;
  padding: 0 24px;
  border-radius: 18px;
  text-align: center;
  box-sizing: border-box;
  border: 0;
  outline: none;
  background-color: #3899ec;
  color: #fff; }
  .composite-action-set-horizontal:not(.flex) .control-button:nth-of-type(1) {
    margin-left: 30px; }
  .composite-action-set-horizontal:not(.flex) .control-button:nth-of-type(2) {
    margin-left: 18px; }
  .composite-action-set-horizontal:not(.flex) .control-button.btn-text {
    display: flex; }
  .composite-action-set-horizontal.small .control-button.btn-text {
    height: 18px; }
  .composite-button-large-labeled .control-button {
    max-width: 100%;
    align-self: center; }
  .control-text-input-multiline-with-button .control-button {
    align-self: center;
    margin-top: 12px;
    margin-bottom: 6px; }
  .composite-button-medium .control-button,
  .composite-button-large-fixed-bottom .control-button,
  .composite-button-large .control-button,
  .composite-button-large-labeled .control-button {
    min-width: 84px; }
  .composite-action-set-vertical .control-button {
    min-width: 132px;
    max-width: 288px; }
    .composite-action-set-vertical .control-button svg {
      margin-left: 12px; }
  .control-upload-button .control-button {
    max-width: 100%; }
  .control-button svg {
    background: transparent; }
    .control-button svg * {
      fill: #fff; }
  .control-button:hover {
    background-color: #4eb7f5; }
  .control-button:active {
    background-color: #3899ec; }
  .control-button[disabled] {
    cursor: default;
    color: #fff;
    background-color: #bcbcbc; }
  .control-button.btn-big {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 18px; }
  .control-button.btn-md,
  .composite-popup > .composite-action-set-horizontal > .control-button,
  .composite-popup > .composite-action-set-horizontal-spaced > .control-button {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    height: 30px;
    line-height: 14px;
    padding: 0 18px;
    border-radius: 15px; }
    .control-button.btn-md.btn-round,
    .composite-popup > .composite-action-set-horizontal > .control-button.btn-round,
    .composite-popup > .composite-action-set-horizontal-spaced > .control-button.btn-round {
      width: 30px;
      padding: 0; }
  .control-button.btn-sm {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    height: 24px;
    line-height: 14px;
    padding: 0 18px;
    border-radius: 15px; }
  .control-button.btn-back {
    min-width: 67px;
    height: 21px;
    line-height: 13px;
    color: #3899ec;
    background-color: #eaf7ff;
    font-weight: 300;
    font-size: 13px;
    margin: 15px 0 15px 12px;
    text-align: center; }
    .composite-button-left .control-button.btn-back {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 13px;
      margin: 0;
      min-width: 54px;
      line-height: 18px;
      padding-left: 9px;
      padding-right: 12px; }
      .composite-button-left .control-button.btn-back > .button-content > svg {
        margin-right: 12px; }
      .composite-button-left .control-button.btn-back > .button-content > span {
        max-width: 144px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }
    .control-button.btn-back:hover {
      color: #3899ec;
      background-color: #d3edff; }
    .control-button.btn-back svg * {
      fill: #3899ec; }
  .control-button.btn-danger-primary,
  .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.primary {
    background-color: #ee5951;
    color: #fff; }
    .control-button.btn-danger-primary:hover,
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.primary:hover {
      color: #fff;
      background-color: #f1726b; }
    .control-button.btn-danger-primary:active,
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.primary:active {
      color: #fff;
      background-color: #ee5951; }
  .control-button.btn-danger-secondary,
  .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.secondary {
    background-color: #fce6e5;
    color: #ee5951; }
    .control-button.btn-danger-secondary:hover,
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.secondary:hover {
      color: #ee5951;
      background-color: #facdca; }
    .control-button.btn-danger-secondary:active,
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.secondary:active {
      color: #ee5951;
      background-color: #fce6e5; }
    .control-button.btn-danger-secondary svg *,
    .composite-popup-small-size.danger > .composite-action-set-horizontal > .control-button.secondary svg * {
      fill: #ee5951; }
  .control-button.btn-confirm-primary {
    background-color: #3899ec;
    color: #fff; }
    .control-button.btn-confirm-primary:hover {
      color: #fff;
      background-color: #4eb7f5; }
    .control-button.btn-confirm-primary:active {
      color: #fff;
      background-color: #3899ec; }
    .control-button.btn-confirm-primary[disabled] {
      color: #fff;
      background-color: #bcbcbc; }
  .control-button.btn-light, .control-button.btn-confirm-secondary,
  .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary,
  .composite-popup > .composite-action-set-horizontal > .control-button.secondary,
  .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary {
    background-color: #eaf7ff;
    color: #3899ec; }
    .control-button.btn-light:hover, .control-button.btn-confirm-secondary:hover,
    .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary:hover,
    .composite-popup > .composite-action-set-horizontal > .control-button.secondary:hover,
    .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary:hover {
      color: #3899ec;
      background-color: #d3edff; }
    .control-button.btn-light:active, .control-button.btn-confirm-secondary:active,
    .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary:active,
    .composite-popup > .composite-action-set-horizontal > .control-button.secondary:active,
    .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary:active {
      color: #3899ec;
      background-color: #eaf7ff; }
    .control-button.btn-light svg *, .control-button.btn-confirm-secondary svg *,
    .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary svg *,
    .composite-popup > .composite-action-set-horizontal > .control-button.secondary svg *,
    .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary svg * {
      fill: #3899ec; }
    .control-button.btn-light[disabled], .control-button.btn-confirm-secondary[disabled],
    .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary[disabled],
    .composite-popup > .composite-action-set-horizontal > .control-button.secondary[disabled],
    .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary[disabled] {
      cursor: default;
      color: #bcbcbc;
      background-color: #f0f0f0; }
      .control-button.btn-light[disabled] svg *, .control-button.btn-confirm-secondary[disabled] svg *,
      .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary[disabled] svg *,
      .composite-popup > .composite-action-set-horizontal > .control-button.secondary[disabled] svg *,
      .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary[disabled] svg * {
        fill: #bcbcbc; }
    .media-button .control-button.btn-light, .media-button .control-button.btn-confirm-secondary, .media-button
    .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary, .media-button
    .composite-popup > .composite-action-set-horizontal > .control-button.secondary, .media-button
    .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary {
      position: relative;
      display: block;
      margin: 0 auto; }
      .media-button .control-button.btn-light.has-file, .media-button .control-button.btn-confirm-secondary.has-file, .media-button
      .composite-dialog-panel > .composite-action-set-horizontal > .control-button.secondary.has-file, .media-button
      .composite-popup > .composite-action-set-horizontal > .control-button.secondary.has-file, .media-button
      .composite-popup > .composite-action-set-horizontal-spaced > .control-button.secondary.has-file {
        padding-left: 40px; }
  .control-button.btn-text {
    background: none;
    border-radius: 0;
    font-size: 18px;
    color: #3899ec;
    padding: 0; }
    .dropdown-options .control-button.btn-text {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      padding: 0 24px;
      box-sizing: border-box; }
    .control-button.btn-text:hover:not([disabled]) > .button-content {
      color: #3899ec;
      text-decoration: underline; }
    .control-button.btn-text[disabled] {
      cursor: default;
      color: #bcbcbc; }
    .control-button.btn-text > .button-content {
      display: inline-flex;
      align-items: baseline; }
      .control-dropdown-sticky-footer .control-button.btn-text > .button-content {
        align-items: center; }
    .composite-button-left .control-button.btn-text {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      line-height: 18px;
      margin-left: 12px;
      margin-top: 3px;
      height: auto; }
    .composite-action-set-vertical .control-button.btn-text {
      line-height: 20px;
      height: 20px;
      margin-top: 12px;
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px; }
    .composite-button-large-fixed-bottom .control-button.btn-text,
    .composite-button-large .control-button.btn-text {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      height: 16px;
      margin-top: 2px; }
      .composite-button-large-fixed-bottom .control-button.btn-text:nth-child(2),
      .composite-button-large .control-button.btn-text:nth-child(2) {
        margin-top: 8px; }
  .control-button.link {
    height: 14px;
    line-height: 14px;
    font-size: 14px;
    padding: 0;
    margin: 0;
    border-radius: 0; }
  .control-button.got-it {
    margin: 0;
    padding: 0 14px; }
    .control-button.got-it:hover {
      background-color: #b1ddf8; }
  .control-button.btn-test-drive {
    background-color: #fb7d33; }
    .control-button.btn-test-drive:hover {
      background-color: #fc975c; }
  .control-button.btn-header {
    background-color: #2b81cb;
    width: 25px;
    height: 25px;
    padding: 0;
    position: absolute; }
    .control-button.btn-header:hover {
      background-color: #0d487f; }
  .control-button.btn-edit {
    height: 30px;
    width: 66px;
    padding: 0; }
  .control-button.btn-thumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 8px;
    height: 100%; }
    .control-button.btn-thumbnail:not([disabled]) {
      background-color: #eaf7ff; }
      .control-button.btn-thumbnail:not([disabled]):active {
        background-color: #3899ec; }
    .control-button.btn-thumbnail[disabled] {
      background-color: #f0f0f0; }
      .control-button.btn-thumbnail[disabled] path {
        fill: #bcbcbc; }
    .control-button.btn-thumbnail:hover:not([disabled]):not(:active) {
      background-color: #d3edff; }
  :not(.control-upload-button) > .control-button > .button-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%; }
  .composite-action-set-horizontal-spaced .control-button > .button-content > svg:first-child,
  .composite-action-set-vertical .control-button > .button-content > svg:first-child,
  .composite-action-set-horizontal .control-button > .button-content > svg:first-child {
    margin-right: 12px; }
  .composite-action-set-horizontal-spaced .control-button > .button-content > svg:last-child,
  .composite-action-set-vertical .control-button > .button-content > svg:last-child,
  .composite-action-set-horizontal .control-button > .button-content > svg:last-child {
    margin-left: 12px; }
  .control-upload-button > .control-button > .button-content {
    line-height: initial; }
    .control-upload-button > .control-button > .button-content .symbol:first-child {
      padding-right: 12px; }

.control-upload-button .upload-status-message {
  max-width: 100%;
  display: flex;
  margin-top: 16px; }
  .control-upload-button .upload-status-message .status-icon svg {
    width: 20px;
    height: 20px;
    margin-left: 6px; }

.control-upload-button {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }
  .composite-upload-button-labeled > .control-upload-button {
    margin-top: 12px; }
  .composite-upload-button > .control-upload-button.no-bottom-message,
  .composite-upload-button-labeled > .control-upload-button.no-bottom-message {
    margin-bottom: 6px; }

.control-switch > .symbol-switch {
  align-self: center; }
  .control-switch > .symbol-switch > svg {
    vertical-align: middle; }
    .switch-size-medium.control-switch > .symbol-switch > svg {
      width: 40px; }
  .composite-toggle-switch .control-switch > .symbol-switch {
    margin-left: 20px; }

.control-switch > .input ~ .symbol-switch .switch-thumb-bg {
  fill: #d3edff; }
  .emphasise.control-switch > .input ~ .symbol-switch .switch-thumb-bg {
    fill: #fee5d6; }

.control-switch > .input ~ .symbol-switch .switch-thumb-border {
  fill: #b1ddf8; }
  .emphasise.control-switch > .input ~ .symbol-switch .switch-thumb-border {
    fill: #fed8c1; }

.control-switch > .input ~ .symbol-switch .switch-thumb-circle {
  fill: #fff;
  filter: url(#thumb-shadow); }

.control-switch > .input ~ .symbol-switch .switch-thumb-minus {
  fill: #b1ddf8; }
  .emphasise.control-switch > .input ~ .symbol-switch .switch-thumb-minus {
    fill: #fc975c; }

.control-switch > .input ~ .symbol-switch .switch-thumb-check {
  fill: none; }

.control-switch > .input ~ .symbol-switch .switch-thumb-regular {
  visibility: visible; }

.control-switch > .input ~ .symbol-switch .switch-thumb-selected {
  visibility: hidden; }

.control-switch.control-switch-animated > .input ~ .symbol-switch g,
.control-switch.control-switch-animated > .input ~ .symbol-switch path {
  transition: fill 0.1s ease-in-out; }

.control-switch.control-switch-animated > .input ~ .symbol-switch .switch-thumb-regular {
  transform: translate(0, 0);
  transition: visibility 0s linear 0.05s, transform 0.1s ease-in-out; }

.control-switch.control-switch-animated > .input ~ .symbol-switch .switch-thumb-selected {
  transform: translate(-22px, 0);
  transition: visibility 0s linear 0.05s, transform 0.1s ease-in-out; }

.control-switch > .input:checked ~ .symbol-switch .switch-thumb-bg,
.control-switch > .input:checked ~ .symbol-switch .switch-thumb-border {
  fill: #3899ec; }
  .emphasise.control-switch > .input:checked ~ .symbol-switch .switch-thumb-bg, .emphasise.control-switch > .input:checked ~ .symbol-switch .switch-thumb-border {
    fill: #fb7d33; }

.control-switch > .input:checked ~ .symbol-switch .switch-thumb-circle {
  fill: #fff; }

.control-switch > .input:checked ~ .symbol-switch .switch-thumb-minus {
  fill: none; }

.control-switch > .input:checked ~ .symbol-switch .switch-thumb-check {
  fill: #3899ec; }
  .emphasise.control-switch > .input:checked ~ .symbol-switch .switch-thumb-check {
    fill: #fb7d33; }

.control-switch > .input:checked ~ .symbol-switch .switch-thumb-regular {
  visibility: hidden; }

.control-switch > .input:checked ~ .symbol-switch .switch-thumb-selected {
  visibility: visible; }

.control-switch.control-switch-animated > .input:checked ~ .symbol-switch .switch-thumb-regular {
  transform: translate(22px, 0);
  transition: visibility 0s linear 0.05s, transform 0.1s ease-in-out; }

.control-switch.control-switch-animated > .input:checked ~ .symbol-switch .switch-thumb-selected {
  transform: translate(0, 0);
  transition: visibility 0s linear 0.05s, transform 0.1s ease-in-out; }

.control-switch > .input:disabled ~ .symbol-switch .switch-thumb-bg {
  fill: #ececec; }

.control-switch > .input:disabled ~ .symbol-switch .switch-thumb-border {
  fill: #e3e3e3; }

.control-switch > .input:disabled ~ .symbol-switch .switch-thumb-circle {
  filter: none; }

.control-switch > .input:disabled ~ .symbol-switch .switch-thumb-minus {
  fill: #c8c8c8; }

.control-switch > .input:disabled ~ .symbol-switch .switch-thumb-check {
  fill: none; }

.control-switch > .input:checked:disabled ~ .symbol-switch .switch-thumb-bg {
  fill: #ececec; }

.control-switch > .input:checked:disabled ~ .symbol-switch .switch-thumb-border {
  fill: #d5d5d5; }

.control-switch > .input:checked:disabled ~ .symbol-switch .switch-thumb-circle {
  filter: none; }

.control-switch > .input:checked:disabled ~ .symbol-switch .switch-thumb-minus {
  fill: none; }

.control-switch > .input:checked:disabled ~ .symbol-switch .switch-thumb-check {
  fill: #c8c8c8; }

.control-switch:hover > .input:not(:disabled) ~ .symbol-switch .switch-thumb-bg {
  fill: #b1ddf8; }
  .emphasise.control-switch:hover > .input:not(:disabled) ~ .symbol-switch .switch-thumb-bg {
    fill: #fed8c1; }

.control-switch:hover > .input:checked:not(:disabled) ~ .symbol-switch .switch-thumb-bg,
.control-switch:hover > .input:checked:not(:disabled) ~ .symbol-switch .switch-thumb-border {
  fill: #4eb7f5; }
  .emphasise.control-switch:hover > .input:checked:not(:disabled) ~ .symbol-switch .switch-thumb-bg, .emphasise.control-switch:hover > .input:checked:not(:disabled) ~ .symbol-switch .switch-thumb-border {
    fill: #fc975c; }

.control-switch:hover > .input:checked:not(:disabled) ~ .symbol-switch .switch-thumb-check {
  fill: #4eb7f5; }
  .emphasise.control-switch:hover > .input:checked:not(:disabled) ~ .symbol-switch .switch-thumb-check {
    fill: #fb7d33; }

.control-switch.width-by-text-length > .symbol-switch {
  width: 86px;
  display: inline-block;
  text-align: right; }

.control-switch.width-by-text-length {
  display: block; }

.control-switch > .label-switch {
  align-self: center;
  line-height: 18px;
  color: #2b5672;
  user-select: none;
  flex-grow: 1; }
  .secondary-color.control-switch > .label-switch {
    color: #162d3d; }
  .disabled.control-switch > .label-switch {
    color: #939393; }
  .has-on-check.control-switch:not(.disabled) > .label-switch {
    color: #3899ec; }
    .has-on-check.control-switch:not(.disabled) > .label-switch:hover {
      text-decoration: underline; }
  .composite-toggle-switch .control-switch > .label-switch {
    display: inline-block;
    max-height: 36px;
    overflow: hidden; }

.control-switch > .symbol-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #d9e1e8;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 28px;
  box-sizing: border-box; }
  .disabled.control-switch > .symbol-image {
    border-color: #ececec; }
  .composite-toggle-switch .control-switch > .symbol-image {
    margin-top: -6px;
    margin-bottom: -6px; }
  .control-switch > .symbol-image > svg > g,
  .control-switch > .symbol-image > svg > path {
    fill: #2d4150; }
    .disabled.control-switch > .symbol-image > svg > g, .disabled.control-switch > .symbol-image > svg > path {
      fill: #8a8a8a; }

.symbol-checkbox {
  display: inline-block; }
  .symbol-checkbox > svg {
    display: block;
    margin: 0 auto; }
  .emphasise.control-checkbox > .input ~ .symbol-checkbox .border {
    fill: #fb7d33; }
  .control-checkbox > .input ~ .symbol-checkbox .bg {
    fill: #fff; }
  .control-checkbox > .input ~ .symbol-checkbox .border {
    fill: #3899ec; }
  .control-checkbox > .input ~ .symbol-checkbox .check,
  .control-checkbox > .input ~ .symbol-checkbox .minus {
    fill: transparent; }
  .control-checkbox > .input:disabled ~ .symbol-checkbox .bg {
    fill: #fff; }
  .control-checkbox > .input:disabled ~ .symbol-checkbox .border {
    fill: #bcbcbc; }
  .emphasise.control-checkbox > .input:checked ~ .symbol-checkbox .bg, .emphasise.control-checkbox > .input:checked ~ .symbol-checkbox .border {
    fill: #fb7d33; }
  .control-checkbox > .input:checked ~ .symbol-checkbox .bg,
  .control-checkbox > .input:checked ~ .symbol-checkbox .border {
    fill: #3899ec; }
  .control-checkbox > .input:checked ~ .symbol-checkbox .check {
    fill: #fff; }
  .control-checkbox > .input:checked ~ .symbol-checkbox .minus {
    fill: transparent; }
  .control-checkbox > .input:checked:disabled ~ .symbol-checkbox .bg {
    fill: #bcbcbc; }
  .control-checkbox > .input:checked:disabled ~ .symbol-checkbox .border {
    fill: #bcbcbc; }
  .emphasise.control-checkbox:hover > .input:not(:disabled) ~ .symbol-checkbox .bg {
    fill: #fee5d6; }
  .control-checkbox:hover > .input:not(:disabled) ~ .symbol-checkbox .bg {
    fill: #d3edff; }
  .emphasise.control-checkbox:hover > .input:not(:disabled):checked ~ .symbol-checkbox .bg, .emphasise.control-checkbox:hover > .input:not(:disabled):checked ~ .symbol-checkbox .border {
    fill: #fc975c; }
  .control-checkbox:hover > .input:not(:disabled):checked ~ .symbol-checkbox .bg,
  .control-checkbox:hover > .input:not(:disabled):checked ~ .symbol-checkbox .border {
    fill: #4eb7f5; }
  .control-checkbox:active > .input:not(:disabled) ~ .symbol-checkbox .bg {
    fill: #fff; }
  .control-checkbox:active > .input:not(:disabled):checked ~ .symbol-checkbox .bg,
  .control-checkbox:active > .input:not(:disabled):checked ~ .symbol-checkbox .border {
    fill: #3899ec; }
  .control-checkbox > .input[data-indeterminate="true"] ~ .symbol-checkbox .bg {
    fill: #fff; }
  .control-checkbox > .input[data-indeterminate="true"] ~ .symbol-checkbox .border,
  .control-checkbox > .input[data-indeterminate="true"] ~ .symbol-checkbox .minus {
    fill: #3899ec; }
  .control-checkbox > .input[data-indeterminate="true"] ~ .symbol-checkbox .check {
    fill: transparent; }
  .control-checkbox:hover > .input[data-indeterminate="true"]:not(:disabled) ~ .symbol-checkbox .bg {
    fill: #d3edff; }
  .control-checkbox:active > .input[data-indeterminate="true"]:not(:disabled) ~ .symbol-checkbox .bg {
    fill: #fff; }
  .control-checkbox > .input:disabled[data-indeterminate="true"] ~ .symbol-checkbox .bg {
    fill: #e2e2e2; }
  .control-checkbox > .input:disabled[data-indeterminate="true"] ~ .symbol-checkbox .border,
  .control-checkbox > .input:disabled[data-indeterminate="true"] ~ .symbol-checkbox .minus {
    fill: #e3e3e3; }

.control-boolean.control-checkbox {
  justify-content: flex-start;
  align-items: center;
  line-height: 18px; }
  .composite-checkboxes-labeled .control-boolean.control-checkbox:not(:last-child),
  .composite-checkboxes .control-boolean.control-checkbox:not(:last-child),
  .composite-checkboxes-labeled .composite-checkbox-with-info-icon:not(:last-child) .control-boolean.control-checkbox,
  .composite-checkboxes .composite-checkbox-with-info-icon:not(:last-child) .control-boolean.control-checkbox {
    margin-bottom: 11px; }
  .composite-checkboxes-labeled .control-boolean.control-checkbox,
  .composite-checkboxes .control-boolean.control-checkbox,
  .composite-checkboxes-labeled .composite-checkbox-with-info-icon .control-boolean.control-checkbox,
  .composite-checkboxes .composite-checkbox-with-info-icon .control-boolean.control-checkbox {
    width: calc(100% - (42px - 24px)); }
  .composite-action-set-horizontal .control-boolean.control-checkbox {
    flex: 1; }

.label-checkbox {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }
  .control-checkbox > .input ~ .label-checkbox {
    color: #162d3d;
    margin: 0 13px; }
    .composite-checkboxes-labeled .control-checkbox > .input ~ .label-checkbox,
    .composite-checkboxes .control-checkbox > .input ~ .label-checkbox {
      margin-left: 13px;
      margin-right: 0; }
    .disabled.control-checkbox > .input ~ .label-checkbox {
      color: #7a7a7a; }

.control-checkbox > .input ~ .info-checkbox {
  position: absolute;
  right: 12px;
  top: 1px; }

.dropdown-options-container.context-menu-options .dropdown-options {
  overflow: visible; }
  .arrow-left-top.dropdown-options-container.context-menu-options .dropdown-options::before {
    box-shadow: -2px 2px 6px -2px rgba(0, 0, 0, 0.35);
    left: -5px;
    top: 16px; }
  .arrow-left-bottom.dropdown-options-container.context-menu-options .dropdown-options::before {
    box-shadow: -2px 2px 6px -2px rgba(0, 0, 0, 0.35);
    left: -5px;
    bottom: 18px; }
  .arrow-left-middle.dropdown-options-container.context-menu-options .dropdown-options::before {
    box-shadow: -2px 2px 6px -2px rgba(0, 0, 0, 0.35);
    left: -5px;
    top: calc(50% - 5px); }
  .arrow-right-top.dropdown-options-container.context-menu-options .dropdown-options::before {
    box-shadow: 2px -2px 6px -2px rgba(0, 0, 0, 0.35);
    right: -5px;
    top: 16px; }
  .arrow-right-bottom.dropdown-options-container.context-menu-options .dropdown-options::before {
    box-shadow: 2px -2px 6px -2px rgba(0, 0, 0, 0.35);
    right: -5px;
    bottom: 18px; }
  .arrow-right-middle.dropdown-options-container.context-menu-options .dropdown-options::before {
    box-shadow: 2px -2px 6px -2px rgba(0, 0, 0, 0.35);
    right: -5px;
    top: calc(50% - 5px); }
  .arrow-top-left.dropdown-options-container.context-menu-options .dropdown-options::before {
    box-shadow: -2px -2px 6px -2px rgba(0, 0, 0, 0.35);
    top: -5px;
    left: 18px; }
  .arrow-top-right.dropdown-options-container.context-menu-options .dropdown-options::before {
    box-shadow: -2px -2px 6px -2px rgba(0, 0, 0, 0.35);
    top: -5px;
    right: 18px; }
  .arrow-top-middle.dropdown-options-container.context-menu-options .dropdown-options::before {
    box-shadow: -2px -2px 6px -2px rgba(0, 0, 0, 0.35);
    top: -5px;
    left: calc(50% - 5px); }
  .arrow-bottom-left.dropdown-options-container.context-menu-options .dropdown-options::before {
    box-shadow: 2px 2px 6px -2px rgba(0, 0, 0, 0.35);
    bottom: -5px;
    left: 18px; }
  .arrow-bottom-right.dropdown-options-container.context-menu-options .dropdown-options::before {
    box-shadow: 2px 2px 6px -2px rgba(0, 0, 0, 0.35);
    bottom: -5px;
    right: 18px; }
  .arrow-bottom-middle.dropdown-options-container.context-menu-options .dropdown-options::before {
    box-shadow: 2px 2px 6px -2px rgba(0, 0, 0, 0.35);
    bottom: -5px;
    left: calc(50% - 5px); }
  .dropdown-options-container.context-menu-options .dropdown-options::before {
    width: 10px;
    height: 10px;
    content: '';
    background-color: inherit;
    display: block;
    position: absolute;
    transform: rotate(45deg);
    z-index: -1;
    overflow: visible; }
  .dropdown-options-container.context-menu-options .dropdown-options .dropdown-options-inner {
    padding: 6px 0;
    box-sizing: border-box;
    overflow: visible; }

.dropdown-options-container.themes-dropdown-options .control-dropdown-option {
  height: 72px;
  line-height: 72px; }
  .dropdown-options-container.themes-dropdown-options .control-dropdown-option:not(:last-child) {
    border-bottom: 1px solid #d9e1e8; }
  .dropdown-options-container.themes-dropdown-options .control-dropdown-option > .selected-content {
    display: flex;
    height: 100%; }

.dropdown-options-container.detailed-dropdown-options .control-dropdown-option {
  height: 72px;
  line-height: 72px; }
  .dropdown-options-container.detailed-dropdown-options .control-dropdown-option:not(:last-child) {
    border-bottom: 1px solid #d9e1e8; }
  .dropdown-options-container.detailed-dropdown-options .control-dropdown-option > .selected-content {
    display: flex;
    height: 100%; }

.dropdown-options-container .block-click-outside {
  z-index: 5997;
  cursor: default;
  position: fixed;
  top: -100vh;
  left: -100vw;
  height: 300vh;
  width: 300vw; }

.dropdown-options-container .dropdown-options {
  z-index: 5998;
  cursor: auto;
  overflow: hidden;
  position: fixed;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 22px 0 rgba(22, 45, 61, 0.26);
  max-height: calc(100vh - 24px); }
  .dropdown-options-container .dropdown-options > .dropdown-arrow-up,
  .dropdown-options-container .dropdown-options > .dropdown-arrow-down {
    background-color: rgba(255, 255, 255, 0.93);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    height: 36px;
    left: 0;
    opacity: 0.6;
    position: absolute;
    width: 100%;
    z-index: 1; }
    .dropdown-options-container .dropdown-options > .dropdown-arrow-up svg,
    .dropdown-options-container .dropdown-options > .dropdown-arrow-down svg {
      fill: #3899ec; }
  .dropdown-options-container .dropdown-options > .dropdown-arrow-up {
    top: 0;
    transform: rotate(180deg); }
  .dropdown-options-container .dropdown-options > .dropdown-arrow-down {
    bottom: 0; }
  .dropdown-options-container .dropdown-options > .dropdown-options-inner {
    overflow-y: scroll;
    height: 100%;
    width: 100%; }
    .hidden-scroll.dropdown-options-container .dropdown-options > .dropdown-options-inner {
      padding: 0 30px;
      margin: 0 -30px; }

.dropdown-options-container .control-dropdown-option {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  color: #162d3d;
  height: 36px;
  line-height: 36px;
  padding: 0 24px;
  position: relative;
  user-select: none;
  text-align: left; }
  .narrow-viewport.dropdown-options-container .control-dropdown-option {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px; }
  .dropdown-options-container .control-dropdown-option.sub-option {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    padding-left: 36px; }
  .dropdown-options-container .control-dropdown-option.active:not(.selected):not(.disabled) {
    background-color: #d3edff; }
  .dropdown-options-container .control-dropdown-option.selected {
    background-color: #3899ec;
    color: #fff; }
  .dropdown-options-container .control-dropdown-option.disabled {
    color: #bcbcbc;
    cursor: default; }

.control-dropdown-sticky-footer {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #FFF;
  border-top: 1px solid #e2e2e2;
  position: absolute;
  bottom: 0;
  width: calc(100% - 48px);
  padding: 0 24px;
  height: 35px;
  line-height: 35px; }

.control-dropdown-base {
  position: relative; }
  .composite-dropdown-with-color-input-labeled .control-dropdown-base,
  .composite-dropdown-with-button-labeled .control-dropdown-base {
    flex: 1;
    order: -1;
    max-width: 192px; }
  .composite-text-formatting .control-dropdown-base {
    margin: 0 5px; }
  .composite-text-input-labeled .control-dropdown-base.label-styled-drop-down {
    padding: 0 12px; }
  .control-dropdown-base > .dropdown-selected {
    cursor: pointer; }
    .disabled.control-dropdown-base > .dropdown-selected {
      cursor: default; }

.context-menu-button {
  box-shadow: 0 1px 1px 0 #7a92a5;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 50%; }
  .context-menu-button:hover {
    background-color: #7fccf7; }
  .context-menu-button:active,
  .is-open .context-menu-button {
    box-shadow: inset 0 1px 1px 0 #7a92a5;
    background-color: #7fccf7; }
  .context-menu-button svg {
    width: 12px;
    height: 4px; }
    .context-menu-button svg path {
      fill: #3899ec; }
  .flat .context-menu-button {
    box-shadow: none;
    background: transparent;
    box-sizing: border-box;
    border: 2px solid #b1ddf8; }
    .flat .context-menu-button:hover {
      border-color: #7fccf7; }
  .flat .context-menu-button:active,
  .flat .is-open .context-menu-button {
    box-shadow: none;
    background: #3899ec;
    border-color: #3899ec; }
    .flat .context-menu-button:active svg path,
    .flat .is-open .context-menu-button svg path {
      fill: #fff; }

.context-menu-action {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  color: #2b5672;
  min-width: 120px;
  max-width: 268px;
  height: 30px;
  padding: 0 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden; }
  .context-menu-action.active {
    background-color: #eaf7ff; }
  .context-menu-action.disabled {
    cursor: default;
    color: #939393; }
  .context-menu-action > .symbol {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px; }

.control-context-menu {
  display: inline-flex;
  cursor: pointer; }
  .control-context-menu.context-menu-block {
    display: block; }

.symbol-arrow-down {
  width: 22px;
  height: 22px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
  border: 2px solid #b1ddf8;
  border-radius: 50%; }
  .symbol-arrow-down > svg {
    fill: #3899ec; }
  .disabled.control-dropdown .symbol-arrow-down {
    border-color: #e3e3e3; }
    .disabled.control-dropdown .symbol-arrow-down > svg {
      fill: #bcbcbc; }
  .label-styled-drop-down .symbol-arrow-down {
    border: none; }
    .label-styled-drop-down .symbol-arrow-down > svg {
      width: 8px;
      height: 8px; }
      .label-styled-drop-down:not(:hover):not(.disabled) .symbol-arrow-down > svg {
        fill: #2b5672; }
  .control-dropdown:hover:not(.disabled) .symbol-arrow-down,
  .control-dropdown.label-styled-drop-down:hover:not(.disabled) .symbol-arrow-down,
  .composite-dropdown:hover .control-dropdown:not(.disabled) .symbol-arrow-down,
  .composite-dropdown-labeled:hover .control-dropdown:not(.disabled) .symbol-arrow-down {
    border-color: #3899ec; }

.control-dropdown > .dropdown-selected {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 18px;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  color: #162d3d;
  height: 36px;
  line-height: 36px;
  min-height: 36px;
  margin: 0; }
  .control-dropdown:hover:not(.disabled) > .dropdown-selected {
    color: #3899ec; }
  .disabled.control-dropdown > .dropdown-selected {
    color: #7a7a7a; }
  .label-styled-drop-down.control-dropdown > .dropdown-selected {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    height: 18px;
    line-height: 18px;
    min-height: 18px;
    margin-bottom: 2px; }
    .label-styled-drop-down.control-dropdown > .dropdown-selected > .selected-container {
      justify-content: flex-start; }
      .label-styled-drop-down.control-dropdown > .dropdown-selected > .selected-container > .selected-content, .label-styled-drop-down.control-dropdown > .dropdown-selected > .selected-container > .selected-content.has-arrow-icon {
        width: auto; }
      .label-styled-drop-down.control-dropdown:not(:hover):not(.disabled) > .dropdown-selected .label-styled-drop-down.control-dropdown > .dropdown-selected > .selected-container {
        color: #2b5672; }
  .control-dropdown > .dropdown-selected > .selected-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%; }
    .control-dropdown > .dropdown-selected > .selected-container > .selected-content {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      display: flex;
      align-items: center; }
      .control-dropdown > .dropdown-selected > .selected-container > .selected-content > .control-dropdown-option-content {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }
      .control-dropdown > .dropdown-selected > .selected-container > .selected-content.has-arrow-icon {
        width: calc(100% - 18px - 24px); }
      .control-dropdown > .dropdown-selected > .selected-container > .selected-content.placeholder {
        color: #b6c1cd; }
        .dynamic-field-container .control-dropdown > .dropdown-selected > .selected-container > .selected-content.placeholder {
          color: inherit; }
    .control-dropdown > .dropdown-selected > .selected-container > .selected-input {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 18px;
      width: 100%;
      border: none;
      padding: 0; }
      .control-dropdown > .dropdown-selected > .selected-container > .selected-input:focus {
        outline: none; }
      .control-dropdown > .dropdown-selected > .selected-container > .selected-input.has-arrow-icon {
        width: calc(100% - 18px - 24px); }

.control-google-preview {
  padding: 18px; }
  .composite-google-preview-with-rich-text .control-google-preview {
    padding-top: 6px; }
  .control-google-preview > .preview-content {
    padding: 12px;
    border: 1px solid #d9e1e8;
    background-color: #ffffff; }
    .control-google-preview > .preview-content > .title,
    .control-google-preview > .preview-content > .url,
    .control-google-preview > .preview-content > .description {
      margin: 0;
      line-height: 18px;
      word-wrap: break-word; }
    .control-google-preview > .preview-content > .title {
      font-family: Arial, sans-serif;
      font-size: 18px;
      font-weight: 700;
      color: #1a0dab;
      font-weight: 700; }
    .control-google-preview > .preview-content > .url {
      font-family: Arial, sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: #006621;
      user-select: text; }
    .control-google-preview > .preview-content > .description {
      font-family: Arial, sans-serif;
      font-size: 13px;
      font-weight: 400;
      color: #545454; }
      .control-google-preview > .preview-content > .description > .control-label {
        width: calc(100% - (42px - 24px));
        max-height: 36px;
        overflow: hidden;
        display: block; }

.control-action-with-symbol {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #162d3d; }
  .composite-link-button-with-color-input-labeled .control-action-with-symbol {
    flex: 1;
    order: -1;
    width: calc(100% - 24px - 30px - 9px - 8px); }
  .control-action-with-symbol:hover,
  .composite-date-picker-labeled:hover .control-action-with-symbol,
  .composite-action-with-symbol-labeled:hover .control-action-with-symbol,
  .composite-action-with-symbol:hover .control-action-with-symbol {
    color: #3899ec; }
  .control-action-with-symbol.link-style-action {
    color: #3899ec; }
    .control-action-with-symbol.link-style-action:hover {
      color: #4eb7f5;
      text-decoration: underline; }
    .control-action-with-symbol.link-style-action.disabled {
      color: #bcbcbc;
      text-decoration: none; }
  .control-action-with-symbol.not-initialized {
    color: #b6c1cd; }
  .control-action-with-symbol.disabled {
    cursor: default;
    color: #7a7a7a; }
  .control-action-with-symbol > .content-container {
    display: inline-flex;
    align-items: center;
    width: calc(100% - 24px - 12px); }
    .composite-link-button-with-color-input-labeled .control-action-with-symbol > .content-container {
      width: calc(100% - 24px - 5px); }
    .control-action-with-symbol > .content-container .content {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 18px;
      line-height: 24px;
      width: 100%;
      display: flex; }
      .link-style-action.control-action-with-symbol > .content-container .content {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 14px; }
  .control-action-with-symbol > .symbol-container {
    border-radius: 50%;
    display: inline-flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    background-color: #3899ec; }
    .composite-link-button-with-color-input-labeled .control-action-with-symbol > .symbol-container {
      width: 25px;
      height: 25px; }
    .control-action-with-symbol:hover > .symbol-container {
      background-color: #4eb7f5; }
    .inactive.control-action-with-symbol > .symbol-container {
      background-color: #eaf7ff; }
      .disabled.inactive.control-action-with-symbol > .symbol-container {
        background-color: #f0f0f0; }
      .inactive.control-action-with-symbol:hover:not(.disabled) > .symbol-container {
        background-color: #d3edff; }
    .disabled.control-action-with-symbol > .symbol-container {
      cursor: default;
      background-color: #bcbcbc; }
    .control-action-with-symbol > .symbol-container > svg > path {
      fill: #fff; }
      .inactive.control-action-with-symbol:not(.disabled) > .symbol-container > svg > path {
        fill: #3899ec; }
      .disabled.inactive.control-action-with-symbol > .symbol-container > svg > path {
        fill: #bcbcbc; }

.control-rich-text {
  color: #2b5672;
  text-align: left;
  width: 100%;
  margin: 0; }
  .composite-rich-text.composite-centered .control-rich-text,
  .composite-rich-text-labeled.composite-centered .control-rich-text,
  .composite-rich-text-with-illustration-vertical .control-rich-text {
    text-align: center; }
  .composite-google-preview-with-rich-text > .control-rich-text {
    padding-left: 24px; }
  .composite-action-set-horizontal .control-rich-text {
    flex: 1; }
  .control-rich-text p {
    margin: 0; }
    .control-rich-text p:not(:last-of-type) {
      margin-bottom: 1em; }
  .control-rich-text.light {
    color: #7a92a5; }
  .control-rich-text ul,
  .control-rich-text ol {
    margin: 0;
    padding-left: 16px; }
  .control-rich-text li:not(:last-child) {
    margin-bottom: 4px; }
  .control-rich-text b,
  .control-rich-text strong {
    color: #2b5672; }
  .control-rich-text a {
    color: #3899ec;
    text-decoration: none; }
    .control-rich-text a:hover {
      cursor: pointer;
      text-decoration: underline; }
    .control-rich-text a.disabled {
      color: #bcbcbc;
      cursor: default;
      text-decoration: none; }

.thumb-none-symbol {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border-radius: 5px; }
  .selected .thumb-none-symbol {
    width: calc(100% - 4px);
    height: calc(100% - 4px); }
  .thumb-none-symbol > .line {
    stroke: #ee5951;
    stroke-width: 2; }

.control-thumbnail {
  display: flex;
  flex-direction: column; }
  .control-thumbnail.disabled {
    cursor: default;
    box-shadow: inset 0 0 0 1px #d9e1e8; }
  .outer-border .control-thumbnail {
    box-shadow: inset 0 0 0 1px #d9e1e8;
    padding: 20px 0;
    border-radius: 8px;
    align-items: center;
    flex-grow: 1; }
  .outer-border.with-label .control-thumbnail {
    padding: 20px 6px; }
  .outer-border .thumbnail-wrapper:not(.selected):hover .control-thumbnail:not(.disabled) {
    box-shadow: inset 0 0 0 2px #d3edff; }
  .outer-border .selected .control-thumbnail {
    box-shadow: inset 0 0 0 2px #3899ec; }
  .fixed-ratio.control-thumbnail > .illustration-container {
    line-height: 0;
    position: relative;
    display: block; }
    .fixed-ratio.control-thumbnail > .illustration-container::before {
      display: inline-block;
      content: '';
      padding: 50%;
      vertical-align: -webkit-baseline-middle; }
  .composite-thumbnails > .control-thumbnails-list:not(.with-border) .control-thumbnail > .illustration-container {
    border-radius: 8px;
    background-color: #eaf7ff; }
  .composite-thumbnails > .control-thumbnails-list:not(.with-border) > .selected:not(.none-thumbnail) .control-thumbnail > .illustration-container {
    background-color: #3899ec; }
  .outer-border.with-label .control-thumbnail > .illustration-container {
    border-bottom: 1px solid #d9e1e8;
    margin: 0 auto; }
  .inner-border .control-thumbnail > .illustration-container {
    border-radius: 8px;
    position: relative; }
    .inner-border .control-thumbnail > .illustration-container:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: inset 0 0 0 1px #d9e1e8;
      border-radius: 8px;
      box-sizing: border-box; }
  .inner-border .selected .control-thumbnail > .illustration-container:after {
    border-radius: 6px;
    box-shadow: 0 0 0 2px #3899ec;
    border: 1px solid #fff;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    margin: 2px; }
  .inner-border .none-thumbnail.selected .control-thumbnail > .illustration-container:after,
  .control-thumbnails-list:not(.with-border) > .none-thumbnail.selected .control-thumbnail > .illustration-container {
    box-shadow: inset 0 0 0 2px #3899ec; }
  .control-thumbnail > .illustration-container > .illustration-inner-container {
    display: flex;
    justify-content: center;
    align-items: center; }
    .fixed-ratio.control-thumbnail > .illustration-container > .illustration-inner-container {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0; }
    .control-thumbnail > .illustration-container > .illustration-inner-container > .none-thumbnail-symbol-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      height: 100%; }
    .inner-border .control-thumbnail > .illustration-container > .illustration-inner-container {
      border-radius: 8px;
      overflow: hidden; }
  .composite-thumbnails > .control-thumbnails-list:not(.with-border) > .thumbnail-wrapper:not(.selected) .control-thumbnail:hover > .illustration-container {
    background-color: #d3edff; }
  .inner-border .thumbnail-wrapper:not(.selected) .control-thumbnail:hover > .illustration-container:after {
    box-shadow: inset 0 0 0 2px #d3edff; }
  .control-thumbnail .label {
    text-align: center;
    margin-top: 12px;
    cursor: pointer; }
    .control-thumbnail .label.disabled {
      cursor: default; }
    .control-thumbnails-list:not(.outer-border) .control-thumbnail .label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
    .composite-thumbnails .control-thumbnails-list:not(.outer-border) .control-thumbnail .label {
      color: #3899ec; }
    .outer-border .selected .control-thumbnail .label {
      color: #3899ec; }

.control-thumbnail-button-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .composite-thumbnail-button-group > .control-label + .control-thumbnail-button-group {
    margin-top: 18px; }
  .control-thumbnail-button-group[data-max-items-per-row="1"] .btn-thumbnail {
    width: calc((100% - (12px * (1 - 1))) / 1); }
    .control-thumbnail-button-group[data-max-items-per-row="1"] .btn-thumbnail:not(:nth-child(1n)) {
      margin-right: 12px; }
    .control-thumbnail-button-group[data-max-items-per-row="1"] .btn-thumbnail:nth-child(1n) ~ .btn-thumbnail {
      margin-top: 12px; }
  .with-label.control-thumbnail-button-group[data-max-items-per-row="1"] .btn-thumbnail:nth-child(1n) ~ .btn-thumbnail {
    margin-top: 16px; }
  .control-thumbnail-button-group[data-max-items-per-row="2"] .btn-thumbnail {
    width: calc((100% - (12px * (2 - 1))) / 2); }
    .control-thumbnail-button-group[data-max-items-per-row="2"] .btn-thumbnail:not(:nth-child(2n)) {
      margin-right: 12px; }
    .control-thumbnail-button-group[data-max-items-per-row="2"] .btn-thumbnail:nth-child(2n) ~ .btn-thumbnail {
      margin-top: 12px; }
  .with-label.control-thumbnail-button-group[data-max-items-per-row="2"] .btn-thumbnail:nth-child(2n) ~ .btn-thumbnail {
    margin-top: 16px; }
  .control-thumbnail-button-group[data-max-items-per-row="3"] .btn-thumbnail {
    width: calc((100% - (12px * (3 - 1))) / 3); }
    .control-thumbnail-button-group[data-max-items-per-row="3"] .btn-thumbnail:not(:nth-child(3n)) {
      margin-right: 12px; }
    .control-thumbnail-button-group[data-max-items-per-row="3"] .btn-thumbnail:nth-child(3n) ~ .btn-thumbnail {
      margin-top: 12px; }
  .with-label.control-thumbnail-button-group[data-max-items-per-row="3"] .btn-thumbnail:nth-child(3n) ~ .btn-thumbnail {
    margin-top: 16px; }
  .control-thumbnail-button-group[data-max-items-per-row="4"] .btn-thumbnail {
    width: calc((100% - (12px * (4 - 1))) / 4); }
    .control-thumbnail-button-group[data-max-items-per-row="4"] .btn-thumbnail:not(:nth-child(4n)) {
      margin-right: 12px; }
    .control-thumbnail-button-group[data-max-items-per-row="4"] .btn-thumbnail:nth-child(4n) ~ .btn-thumbnail {
      margin-top: 12px; }
  .with-label.control-thumbnail-button-group[data-max-items-per-row="4"] .btn-thumbnail:nth-child(4n) ~ .btn-thumbnail {
    margin-top: 16px; }
  .control-thumbnail-button-group[data-max-items-per-row="5"] .btn-thumbnail {
    width: calc((100% - (12px * (5 - 1))) / 5); }
    .control-thumbnail-button-group[data-max-items-per-row="5"] .btn-thumbnail:not(:nth-child(5n)) {
      margin-right: 12px; }
    .control-thumbnail-button-group[data-max-items-per-row="5"] .btn-thumbnail:nth-child(5n) ~ .btn-thumbnail {
      margin-top: 12px; }
  .with-label.control-thumbnail-button-group[data-max-items-per-row="5"] .btn-thumbnail:nth-child(5n) ~ .btn-thumbnail {
    margin-top: 16px; }

.control-thumbnail-color-input .btn-thumbnail {
  position: relative;
  display: block; }
  .control-thumbnail-color-input .btn-thumbnail::before {
    display: inline-block;
    content: '';
    padding: 50%;
    vertical-align: -webkit-baseline-middle; }
  .control-thumbnail-color-input .btn-thumbnail .button-content {
    display: block; }
    .control-thumbnail-color-input .btn-thumbnail .button-content .control-thumbnail-color-input-item {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0; }

.control-thumbnail-color-input .control-thumbnail-color-input-item {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px #ccc; }
  .control-thumbnail-color-input .control-thumbnail-color-input-item:hover {
    box-shadow: inset 0 0 0 2px #7fccf7; }
  .control-thumbnail-color-input .control-thumbnail-color-input-item.selected-item {
    border-radius: 6px;
    box-shadow: 0 0 0 2px #3899ec;
    border: 1px solid #fff;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    margin: 2px; }

.control-thumbnails-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .composite-thumbnails > .control-thumbnails-list:not(.with-label) {
    margin-bottom: 6px; }
  .composite-thumbnails > .control-label + .control-thumbnails-list {
    margin-top: 18px; }
  .control-thumbnails-list .thumbnail-wrapper {
    user-select: none;
    cursor: pointer;
    max-width: 100%; }
    .outer-border.control-thumbnails-list .thumbnail-wrapper {
      display: flex; }
    .control-thumbnails-list .thumbnail-wrapper.selected {
      cursor: default; }
    .control-thumbnails-list .thumbnail-wrapper > .label {
      cursor: inherit; }
      .outer-border.control-thumbnails-list .thumbnail-wrapper > .label {
        display: flex;
        flex-direction: column;
        width: 100%; }
      .control-thumbnails-list .thumbnail-wrapper > .label > .input {
        display: none; }
  .control-thumbnails-list[data-max-items-per-row="1"] .item-wrapper {
    width: calc((100% - (12px * (1 - 1))) / 1); }
    .control-thumbnails-list[data-max-items-per-row="1"] .item-wrapper:not(:nth-child(1n)) {
      margin-right: 12px; }
    .control-thumbnails-list[data-max-items-per-row="1"] .item-wrapper:nth-child(1n) ~ .item-wrapper {
      margin-top: 12px; }
  .with-label.control-thumbnails-list[data-max-items-per-row="1"] .item-wrapper:nth-child(1n) ~ .item-wrapper {
    margin-top: 16px; }
  .control-thumbnails-list[data-max-items-per-row="2"] .item-wrapper {
    width: calc((100% - (12px * (2 - 1))) / 2); }
    .control-thumbnails-list[data-max-items-per-row="2"] .item-wrapper:not(:nth-child(2n)) {
      margin-right: 12px; }
    .control-thumbnails-list[data-max-items-per-row="2"] .item-wrapper:nth-child(2n) ~ .item-wrapper {
      margin-top: 12px; }
  .with-label.control-thumbnails-list[data-max-items-per-row="2"] .item-wrapper:nth-child(2n) ~ .item-wrapper {
    margin-top: 16px; }
  .control-thumbnails-list[data-max-items-per-row="3"] .item-wrapper {
    width: calc((100% - (12px * (3 - 1))) / 3); }
    .control-thumbnails-list[data-max-items-per-row="3"] .item-wrapper:not(:nth-child(3n)) {
      margin-right: 12px; }
    .control-thumbnails-list[data-max-items-per-row="3"] .item-wrapper:nth-child(3n) ~ .item-wrapper {
      margin-top: 12px; }
  .with-label.control-thumbnails-list[data-max-items-per-row="3"] .item-wrapper:nth-child(3n) ~ .item-wrapper {
    margin-top: 16px; }
  .control-thumbnails-list[data-max-items-per-row="4"] .item-wrapper {
    width: calc((100% - (12px * (4 - 1))) / 4); }
    .control-thumbnails-list[data-max-items-per-row="4"] .item-wrapper:not(:nth-child(4n)) {
      margin-right: 12px; }
    .control-thumbnails-list[data-max-items-per-row="4"] .item-wrapper:nth-child(4n) ~ .item-wrapper {
      margin-top: 12px; }
  .with-label.control-thumbnails-list[data-max-items-per-row="4"] .item-wrapper:nth-child(4n) ~ .item-wrapper {
    margin-top: 16px; }
  .control-thumbnails-list[data-max-items-per-row="5"] .item-wrapper {
    width: calc((100% - (12px * (5 - 1))) / 5); }
    .control-thumbnails-list[data-max-items-per-row="5"] .item-wrapper:not(:nth-child(5n)) {
      margin-right: 12px; }
    .control-thumbnails-list[data-max-items-per-row="5"] .item-wrapper:nth-child(5n) ~ .item-wrapper {
      margin-top: 12px; }
  .with-label.control-thumbnails-list[data-max-items-per-row="5"] .item-wrapper:nth-child(5n) ~ .item-wrapper {
    margin-top: 16px; }

.radio-button-wrapper {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  color: #162d3d;
  line-height: 18px;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center; }
  .radio-button-wrapper.selected {
    cursor: default; }
  .control-vertical-tabs .radio-button-wrapper {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    color: #2b5672;
    border-radius: 20px;
    padding: 0 14px;
    display: inline-flex;
    height: 100%;
    max-width: 100%;
    box-sizing: border-box;
    transition-property: background-color, color;
    transition-duration: 0.2s; }
    .control-vertical-tabs .radio-button-wrapper.upgrade {
      color: #aa4dc8; }
      .control-vertical-tabs .radio-button-wrapper.upgrade:hover {
        background-color: #eedbf4; }
    .control-vertical-tabs .radio-button-wrapper:hover:not(.selected):not(.upgrade) {
      background-color: #d3edff;
      color: #3899ec; }
    .control-vertical-tabs .radio-button-wrapper.selected {
      background-color: #d3edff; }
  .control-vertical-tabs.arrowed-box .radio-button-wrapper {
    background-color: #eaf7ff;
    border-radius: 0;
    padding: 0;
    max-width: 138px;
    width: 138px;
    height: 42px; }
    .control-vertical-tabs.arrowed-box .radio-button-wrapper:hover:not(.selected) {
      background-color: #d3edff; }
    .control-vertical-tabs.arrowed-box .radio-button-wrapper.selected {
      background-color: #3899ec; }
    .control-vertical-tabs.arrowed-box .radio-button-wrapper:after {
      content: counter(boxes, decimal);
      counter-increment: boxes 1;
      position: absolute;
      bottom: calc(50% - 6px);
      font-size: 12px;
      color: #3899ec;
      left: 161px; }
  .control-horizontal-tabs .radio-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    transition-duration: 0.2s; }
  .control-horizontal-tabs.arrowed .radio-button-wrapper {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    position: relative;
    color: #3899ec;
    padding: 0 6px;
    border-bottom: solid 3px transparent;
    transition-property: border-bottom-color, color; }
    .control-horizontal-tabs.arrowed .radio-button-wrapper:hover {
      color: #2b5672; }
      .control-horizontal-tabs.arrowed .radio-button-wrapper:hover:not(.selected) {
        border-bottom-color: #7fccf7; }
    .control-horizontal-tabs.arrowed .radio-button-wrapper.selected {
      color: #2b5672;
      border-bottom-color: #3899ec; }
      .control-horizontal-tabs.arrowed .radio-button-wrapper.selected:after {
        opacity: 1; }
    .control-horizontal-tabs.arrowed .radio-button-wrapper:after {
      content: '';
      width: 0;
      height: 0;
      border: solid;
      border-color: transparent transparent #3899ec;
      border-width: 0 9px 7px;
      position: absolute;
      bottom: -3px;
      left: calc(50% - 9px);
      opacity: 0;
      transition-property: opacity;
      transition-duration: 0.2s; }
  .control-horizontal-tabs.arrowed-box .radio-button-wrapper {
    background-color: #eaf7ff;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    transition-property: background-color; }
    .control-horizontal-tabs.arrowed-box .radio-button-wrapper:hover:not(.selected) {
      background-color: #d3edff; }
    .control-horizontal-tabs.arrowed-box .radio-button-wrapper.selected {
      background-color: #3899ec; }
    .control-horizontal-tabs.arrowed-box .radio-button-wrapper:after {
      content: counter(boxes, decimal);
      counter-increment: boxes 1;
      position: absolute;
      bottom: 15px;
      font-size: 12px;
      color: #3899ec;
      text-align: center;
      left: calc(50% - 3px); }
  .control-horizontal-tabs.tabbed .radio-button-wrapper {
    color: #2b5672;
    background-color: #eaf7ff;
    padding: 0 10px;
    transition-duration: 0.2s;
    border-bottom: solid 1px #d3edff; }
    .control-horizontal-tabs.tabbed .radio-button-wrapper:hover:not(.selected) {
      background-color: #d3edff; }
    .control-horizontal-tabs.tabbed .radio-button-wrapper.selected {
      background-color: #FFFFFF;
      border-bottom-color: transparent; }
  .radio-button-wrapper .radio-button-symbol {
    width: 16px;
    height: 16px;
    position: relative;
    border: 1px solid #3899ec;
    box-sizing: border-box;
    border-radius: 50%;
    margin-right: 14px;
    flex-shrink: 0;
    transition-property: background-color;
    transition-duration: 0.2s; }
    .radio-button-wrapper .radio-button-symbol:hover {
      background-color: #d3edff; }
    .composite-radio-buttons-with-illustration .radio-button-wrapper .radio-button-symbol {
      align-self: center; }
    .radio-button-wrapper .radio-button-symbol .inner-fill {
      width: calc(12px);
      height: calc(12px);
      position: absolute;
      background-color: transparent;
      left: 1px;
      top: 1px;
      border-radius: 50%;
      transition-property: background-color;
      transition-duration: 0.2s; }
  .radio-button-wrapper > .input:checked:disabled ~ .radio-button-symbol {
    border: 1px solid #bcbcbc; }
    .radio-button-wrapper > .input:checked:disabled ~ .radio-button-symbol > .inner-fill {
      background-color: #bcbcbc; }
  .radio-button-wrapper > .input:checked:not(:disabled) ~ .radio-button-symbol > .inner-fill {
    background-color: #3899ec; }
  .radio-button-wrapper > .input:disabled:not(:checked) ~ .radio-button-symbol {
    border: 1px solid #bcbcbc;
    background-color: transparent; }
  .radio-button-wrapper.disabled {
    color: #7a7a7a;
    cursor: auto; }
  .radio-button-wrapper > .input {
    display: none; }
  .control-horizontal-tabs .radio-button-wrapper > .radio-button-label {
    max-height: 36px;
    overflow: hidden;
    text-align: center;
    word-break: break-all; }
  .control-horizontal-tabs.arrowed-box .radio-button-wrapper > .radio-button-label {
    display: none; }
  .control-vertical-tabs.arrowed-box .radio-button-wrapper > .radio-button-label {
    display: none; }
  .control-buttons-list-group .radio-button-wrapper > .radio-button-label {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    color: #3899ec;
    cursor: pointer;
    height: 36px;
    background-color: #edf7ff;
    display: inline-block;
    position: relative;
    white-space: nowrap;
    line-height: 36px;
    vertical-align: middle;
    font-size: 14px;
    padding: 0 14px;
    width: 73px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    box-shadow: 0 2px 0 0 #d4e7fb; }
  .control-buttons-list-group .selected.radio-button-wrapper > .radio-button-label {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    color: #fff;
    position: relative;
    top: 2px;
    background-color: #3899ec;
    box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.11); }
  .control-buttons-list-group .disabled.radio-button-wrapper > .radio-button-label {
    cursor: default;
    background-color: #f6f6f6; }
  .control-buttons-list-group .disabled.selected.radio-button-wrapper > .radio-button-label {
    background-color: #bcbcbc; }
  .control-buttons-list-group .disabled.radio-button-wrapper:not(.selected) > .radio-button-label {
    color: #bcbcbc;
    box-shadow: 0 2px 0 0 #e5e5e5; }
  .control-buttons-list-group .radio-button-wrapper:not(.disabled):not(.selected) > .radio-button-label:hover {
    background-color: #d3edff; }
  .control-buttons-list-group .radio-button-item:first-of-type .radio-button-wrapper > .radio-button-label {
    border-radius: 20px 0 0 20px; }
  .control-buttons-list-group .radio-button-item:last-of-type .radio-button-wrapper > .radio-button-label {
    border-radius: 0 20px 20px 0; }

.control-radio-buttons-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex; }
  .control-radio-buttons-list.horizontal {
    flex-direction: row; }
  .control-radio-buttons-list.vertical {
    flex-direction: column; }
  .control-dock .control-radio-buttons-list {
    flex-wrap: wrap; }
  .control-horizontal-tabs.tabbed .control-radio-buttons-list {
    height: 59px; }
  .control-vertical-tabs.arrowed-box .control-radio-buttons-list,
  .control-horizontal-tabs.arrowed-box .control-radio-buttons-list {
    counter-reset: boxes 0; }
  .control-horizontal-tabs.arrowed .control-radio-buttons-list {
    box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.05); }
  .control-vertical-tabs .control-radio-buttons-list > .radio-button-item {
    height: 32px; }
    .control-vertical-tabs .control-radio-buttons-list > .radio-button-item:not(:last-child) {
      margin-bottom: 4px; }
  .control-horizontal-tabs .control-radio-buttons-list > .radio-button-item {
    width: 100%; }
  .control-horizontal-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item {
    height: 84px;
    position: relative;
    box-sizing: border-box;
    border-bottom: solid 3px #3899ec;
    transition-duration: 0.2s;
    transition-property: border-bottom-color, color; }
    .control-horizontal-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item.selected:after {
      opacity: 1; }
    .control-horizontal-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item:not(:last-child) {
      padding-right: 6px; }
    .control-horizontal-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item:after {
      content: '';
      width: 0;
      border: solid;
      border-color: transparent transparent #3899ec;
      border-width: 0 9px 7px;
      position: absolute;
      bottom: -3px;
      left: calc(50% - 9px);
      opacity: 0;
      transition-property: opacity;
      transition-duration: 0.2s; }
    .control-horizontal-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item:first-child > .radio-button-wrapper {
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px; }
    .control-horizontal-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item:last-child > .radio-button-wrapper {
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px; }
  .control-horizontal-tabs.arrowed .control-radio-buttons-list > .radio-button-item {
    height: 54px; }
  .control-horizontal-tabs.tabbed .control-radio-buttons-list > .radio-button-item {
    height: 47px; }
    .control-horizontal-tabs.tabbed .control-radio-buttons-list > .radio-button-item:not(:last-child) {
      border-right: solid 1px #d3edff; }
  .control-vertical-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item {
    position: relative;
    box-sizing: border-box;
    border-right: solid 3px #3899ec;
    transition-duration: 0.2s;
    transition-property: border-bottom-color, color;
    padding-right: 12px;
    height: 48px;
    margin-bottom: 0; }
    .control-vertical-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item.selected:after {
      opacity: 1; }
    .control-vertical-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item:last-child {
      height: 42px; }
    .control-vertical-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item:after {
      content: '';
      width: 0;
      border: solid;
      border-color: transparent transparent transparent #3899ec;
      border-width: 9px 0 9px 7px;
      position: absolute;
      left: 150px;
      bottom: calc(50% - 6px);
      opacity: 0;
      transition-property: opacity;
      transition-duration: 0.2s; }
    .control-vertical-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item:first-child > .radio-button-wrapper {
      border-top-right-radius: 6px;
      border-top-left-radius: 6px; }
    .control-vertical-tabs.arrowed-box .control-radio-buttons-list > .radio-button-item:last-child > .radio-button-wrapper {
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px; }
  .composite-radio-buttons-labeled .control-radio-buttons-list > .radio-button-item:not(:last-child),
  .composite-radio-buttons .control-radio-buttons-list > .radio-button-item:not(:last-child) {
    margin-bottom: 12px; }
  .composite-radio-buttons-with-illustration .control-radio-buttons-list > .radio-button-item:not(:first-child) {
    padding-top: 24px; }

.image-and-text {
  display: inline-flex;
  align-items: flex-start; }
  .composite-radio-buttons-with-illustration .image-and-text > .image {
    margin-left: 10px;
    margin-right: 14px;
    flex-shrink: 0;
    align-self: center; }
  .image-and-text > .content {
    text-align: left;
    box-sizing: border-box; }
    .composite-radio-buttons-with-illustration .image-and-text > .content {
      margin-left: 10px; }
    .image-and-text > .content > .title {
      margin: 0; }
      .composite-radio-buttons-with-illustration .image-and-text > .content > .title {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 18px;
        line-height: 24px;
        color: #2b5672; }
    .image-and-text > .content > .text {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      line-height: 18px;
      color: #395671; }

.control-promotional-list {
  display: inline-block;
  text-align: left; }
  .control-promotional-list > .promotional-list-item {
    display: flex; }
    .control-promotional-list > .promotional-list-item:not(:last-child) {
      margin-bottom: 12px; }
    .control-promotional-list > .promotional-list-item > svg {
      flex-shrink: 0;
      margin-right: 20px;
      width: 16px;
      height: 18px;
      fill: #3899ec; }
    .control-promotional-list > .promotional-list-item > .control-label {
      max-width: 468px; }

@keyframes semi-rotate {
  0% {
    transform: rotate(180deg);
    animation-timing-function: ease-out; }
  45% {
    transform: rotate(198deg); }
  55% {
    transform: rotate(234deg); }
  100% {
    transform: rotate(540deg); } }

@keyframes inner-rotate {
  to {
    transform: rotate(115deg);
    opacity: 1; } }

.control-preloader {
  width: 15px;
  height: 30px;
  margin-left: -15px;
  overflow: hidden;
  transform-origin: 100% 50%;
  animation: semi-rotate 1s linear infinite; }
  .control-preloader.medium {
    width: 36px;
    height: 72px;
    margin-left: -36px; }
  .control-preloader.large {
    width: 60px;
    height: 120px;
    margin-left: -60px; }
  .control-preloader::before, .control-preloader::after {
    content: '';
    top: 0;
    left: 0;
    right: -100%;
    bottom: 0;
    border: 3px solid currentColor;
    border-color: currentColor transparent transparent currentColor;
    border-radius: 50%;
    position: absolute;
    transform: rotate(-45deg);
    animation: inner-rotate 0.5s linear infinite alternate; }
  .control-preloader::before {
    color: #7fccf7; }
  .control-preloader::after {
    color: #3899ec;
    opacity: 0; }
  .control-preloader.light::before, .control-preloader.light::after {
    color: #fff; }

.control-contextual-divider {
  display: flex;
  align-items: center;
  padding: 0 24px; }
  .control-contextual-divider > .horizontal-line {
    display: inline;
    flex: 1;
    border: 0;
    box-sizing: content-box;
    background-color: #d9e1e8;
    height: 1px; }
  .control-contextual-divider > .control-label {
    margin: 0 24px;
    color: #7a92a5; }
    .control-contextual-divider > .control-label::selection {
      background-color: rgba(255, 194, 51, 0.6); }

.control-editable-url .symbol-addressBarMiniToolbar {
  margin-right: 8px;
  width: 32px;
  height: 12px;
  fill: #adb7bf; }

.control-editable-url {
  display: flex;
  align-items: center; }
  .control-editable-url > .address-bar-container {
    padding: 5px 5px 5px 10px;
    border-radius: 4px;
    background-color: #d9e1e8;
    display: flex;
    align-items: center;
    flex: 1; }
    .control-editable-url > .address-bar-container > .url-container {
      border-radius: 4px;
      height: 26px;
      padding-left: 10px;
      flex: 1;
      display: flex;
      align-items: center;
      background-color: #fff; }
      .focus.control-editable-url > .address-bar-container > .url-container {
        background-color: #f0f3f5; }
      .control-editable-url > .address-bar-container > .url-container > .domain {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 16px;
        color: #7a92a5;
        cursor: default; }
  .control-editable-url > .buttons-container {
    display: flex;
    align-items: center;
    padding-left: 12px; }
    .control-editable-url > .buttons-container .control-button:nth-child(2) {
      margin-left: 6px; }

.panel-header {
  background-color: #3899ec;
  height: 54px;
  min-height: 54px;
  box-sizing: border-box;
  position: relative;
  width: 100%; }
  .panel-header.small {
    height: 48px;
    min-height: 48px; }
    .panel-header.small .panel-header-title {
      line-height: 48px;
      padding-left: 14px; }
    .panel-header.small > .btn-header {
      width: 21px;
      height: 21px; }
      .panel-header.small > .btn-header.btn-help, .panel-header.small > .btn-header.help {
        right: 49px; }
      .panel-header.small > .btn-header.btn-close, .panel-header.small > .btn-header.close {
        right: 14px; }
  .panel-header.dragger {
    cursor: move; }
  .panel-header .panel-header-title {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 16px;
    line-height: 54px;
    padding-left: 24px;
    overflow: hidden;
    position: absolute;
    color: #fff;
    max-width: 75%;
    white-space: nowrap; }
    .panel-header .panel-header-title > .panel-header-title-content {
      display: inline-flex;
      align-items: center; }
  .panel-header > .btn-header,
  .panel-header > button {
    padding: 0;
    border-radius: 50%;
    border: 0;
    position: absolute;
    width: 25px;
    height: 25px;
    cursor: pointer;
    top: 50%;
    margin-top: -10px;
    background-color: #d3edff;
    color: #fff;
    box-sizing: border-box;
    font: inherit; }
    .panel-header > .btn-header:hover,
    .panel-header > button:hover {
      background-color: #eaf7ff; }
    .panel-header > .btn-header:focus,
    .panel-header > button:focus {
      outline: 0; }
    .panel-header > .btn-header.btn-help, .panel-header > .btn-header.help,
    .panel-header > button.btn-help,
    .panel-header > button.help {
      right: 53px; }
    .panel-header > .btn-header.btn-close, .panel-header > .btn-header.close,
    .panel-header > button.btn-close,
    .panel-header > button.close {
      right: 18px; }
  .panel-header.dark > .btn-header,
  .panel-header.dark button {
    background-color: #2b81cb;
    color: #ffffff;
    line-height: 25px; }
    .panel-header.dark > .btn-header:hover,
    .panel-header.dark button:hover {
      background-color: #0d487f; }
    .panel-header.dark > .btn-header svg,
    .panel-header.dark button svg {
      fill: #ffffff; }
  .panel-header.light {
    border-radius: 0;
    background-color: #fff; }
    .panel-header.light span {
      color: #000; }
    .panel-header.light > .btn-header,
    .panel-header.light button {
      background-color: #d3edff;
      color: #3899ec; }
      .panel-header.light > .btn-header:hover,
      .panel-header.light button:hover {
        background-color: #eaf7ff; }
      .panel-header.light > .btn-header.control-button svg *,
      .panel-header.light > .btn-header svg,
      .panel-header.light button.control-button svg *,
      .panel-header.light button svg {
        fill: #3899ec; }
  .panel-header.red,
  .composite-popup-small-size.danger > .panel-header {
    border-radius: 0;
    background-color: #ee5951; }
    .panel-header.red span,
    .composite-popup-small-size.danger > .panel-header span {
      color: #fff; }
    .panel-header.red > .btn-header,
    .panel-header.red button,
    .composite-popup-small-size.danger > .panel-header > .btn-header,
    .composite-popup-small-size.danger > .panel-header button {
      background-color: #c3504d;
      color: #fff; }
      .panel-header.red > .btn-header:hover,
      .panel-header.red button:hover,
      .composite-popup-small-size.danger > .panel-header > .btn-header:hover,
      .composite-popup-small-size.danger > .panel-header button:hover {
        background-color: #942b28; }
      .panel-header.red > .btn-header.control-button svg *,
      .panel-header.red > .btn-header svg,
      .panel-header.red button.control-button svg *,
      .panel-header.red button svg,
      .composite-popup-small-size.danger > .panel-header > .btn-header.control-button svg *,
      .composite-popup-small-size.danger > .panel-header > .btn-header svg,
      .composite-popup-small-size.danger > .panel-header button.control-button svg *,
      .composite-popup-small-size.danger > .panel-header button svg {
        fill: #fff; }

.panel-header-centered-text {
  text-align: center;
  width: 100%;
  background-color: #3899ec;
  color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 138px;
  padding: 43px 18px 0; }
  .panel-header-centered-text > .title {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 30px;
    line-height: 36px; }
  .panel-header-centered-text > .subtitle {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 18px;
    line-height: 24px;
    margin-top: 11px; }
  .panel-header-centered-text > .btn-help {
    top: 18px;
    right: 51px; }
  .panel-header-centered-text > .btn-close {
    top: 18px;
    right: 18px; }

.panel-header-illustration {
  text-align: center;
  width: 100%;
  position: relative; }
  .panel-header-illustration > .blue-strip {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background-color: #3899ec; }
  .panel-header-illustration > .illustration-container {
    position: relative;
    z-index: 1; }

.composite-action-set-horizontal {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px; }
  .composite-action-set-horizontal.small {
    padding: 0 14px;
    height: 48px; }

.control-angle-input {
  display: flex;
  align-items: center;
  padding-left: 54px; }
  .control-angle-input > .circle {
    width: 72px;
    height: 72px;
    position: relative;
    border-radius: 50%;
    border: solid 4px #d9e1e8;
    box-sizing: border-box;
    margin-right: 12px; }
    .disabled.control-angle-input > .circle {
      border-color: #ececec; }
    .control-angle-input > .circle > .circle-center {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      position: absolute;
      background-color: #d9e1e8;
      top: calc(50% - 3px);
      left: calc(50% - 3px); }
      .control-angle-input > .circle:active > .circle-center {
        background-color: #3899ec; }
      .disabled.control-angle-input > .circle > .circle-center {
        background-color: #ececec; }
    .control-angle-input > .circle:active:after {
      border: solid 1px #3899ec; }
    .control-angle-input > .circle:after {
      content: '';
      position: absolute;
      left: 31px;
      z-index: -1;
      width: 0;
      height: 32px;
      border: solid 1px #d9e1e8; }
      .disabled.control-angle-input > .circle:after {
        border-color: #ececec; }
    .control-angle-input > .circle > .knob {
      width: 16px;
      height: 16px;
      position: absolute;
      cursor: pointer;
      background-color: #3899ec;
      border-radius: 50%;
      border: solid 4px #fff;
      box-shadow: 0 1px 3px 0 #2b5672;
      box-sizing: border-box;
      top: -9px;
      left: calc(50% - 8px); }
      .control-angle-input > .circle:hover > .knob {
        background-color: #7fccf7;
        box-shadow: 0 0 8px 1px #7fccf7; }
      .control-angle-input > .circle:active > .knob {
        background-color: #3899ec;
        box-shadow: 0 0 8px 1px #7fccf7; }
      .disabled.control-angle-input > .circle > .knob {
        background-color: #bcbcbc;
        box-shadow: 0 1px 3px 0 #939393;
        cursor: default; }

.composite-angle-input-labeled {
  padding: 17px 24px 24px; }

.composite-action-set-horizontal-spaced {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px; }

.composite-action-set-vertical {
  padding: 24px 36px;
  display: flex;
  flex-direction: column;
  align-items: center; }

.composite-action-with-symbol {
  padding: 32px 24px 27px; }

.control-info-icon {
  cursor: pointer;
  border-radius: 50%;
  display: inline-block;
  width: 18px;
  height: 18px; }
  .control-info-icon > svg {
    width: 18px;
    height: 18px; }
  .control-info-icon.small {
    width: 15px;
    height: 15px; }
    .control-info-icon.small > svg {
      width: 15px;
      height: 15px; }
  .control-info-icon:not(.error) > svg circle {
    fill: transparent; }
  .control-info-icon:not(.error) > svg path {
    fill: #7a92a5; }
    .composite-banner .control-info-icon:not(.error) > svg path {
      fill: #fff; }
  .control-info-icon:not(.error) > svg:hover path {
    fill: #3899ec; }
    .composite-banner .control-info-icon:not(.error) > svg:hover path {
      fill: #7fccf7; }
  .composite-with-info-icon > .info-icon-tooltip .control-info-icon:not(.error) {
    visibility: hidden; }
  .composite-with-info-icon:hover > .info-icon-tooltip .control-info-icon:not(.error) {
    visibility: visible; }

.info-icon-content > .info-icon-title {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 18px;
  color: #2b5672;
  margin-bottom: 12px; }

.info-icon-content > .info-icon-text {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  color: #2b5672;
  display: inline; }

.info-icon-content > .info-icon-text::after {
  display: inline;
  content: ' '; }

.info-icon-content > .info-icon-link {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  color: #3899ec;
  display: inline;
  text-decoration: underline;
  cursor: pointer; }

.composite-action-with-symbol-labeled {
  padding: 18px 24px; }

.composite-corner-radius-input {
  width: 240px; }

.composite-corner-radius-input-labeled {
  width: 240px; }

.control-date-picker {
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  user-select: none; }
  .control-date-picker .dp-header {
    box-sizing: border-box;
    height: 48px;
    line-height: 32px;
    padding: 8px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    box-shadow: 0 1px 3.96px 0.04px rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid #f0f3f5; }
    .control-date-picker .dp-header .dp-previous {
      transform: rotate(-180deg); }
    .control-date-picker .dp-header .dp-navigate {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center; }
      .control-date-picker .dp-header .dp-navigate:hover {
        background-color: #eaf7ff; }
      .control-date-picker .dp-header .dp-navigate:active {
        background-color: #3899ec; }
        .control-date-picker .dp-header .dp-navigate:active * {
          fill: #fff; }
    .control-date-picker .dp-header .dp-title {
      flex: 1;
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      color: #3899ec; }
  .control-date-picker .dp-body table {
    text-align: center;
    width: calc(100% - 24px);
    margin: 8px 12px;
    font-size: 13px;
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #2b5672;
    border-spacing: 0; }
    .control-date-picker .dp-body table td {
      padding: 0; }
      .control-date-picker .dp-body table td > div {
        border-radius: 50%;
        width: 32px;
        height: 32px;
        line-height: 32px; }
    .control-date-picker .dp-body table .dp-day-name {
      font-weight: bold; }
  .control-date-picker .dp-day {
    cursor: pointer; }
    .control-date-picker .dp-day:not(.dp-day-selected):hover {
      background-color: #eaf7ff; }
  .control-date-picker .dp-day-not-in-current-month {
    color: #d9e1e8; }
  .control-date-picker .dp-day-selected,
  .control-date-picker .dp-day-selected:hover {
    background-color: #3899ec;
    color: #fff; }
  .control-date-picker .dp-today:not(.dp-day-not-in-current-month) {
    border: 1px solid #3899ec; }
  .control-date-picker .dp-footer {
    display: flex;
    align-items: center;
    border-top: 1px solid #f0f3f5;
    height: 32px;
    padding: 12px; }
    .control-date-picker .dp-footer .delete-btn {
      background-color: #fff;
      padding: 0 12px;
      margin-right: 12px;
      height: 32px;
      vertical-align: middle; }
      .control-date-picker .dp-footer .delete-btn path {
        fill: #2b5672; }
      .control-date-picker .dp-footer .delete-btn:hover path {
        fill: #ee5951; }
    .control-date-picker .dp-footer .goto-today,
    .control-date-picker .dp-footer .goto-selection {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      background-color: transparent;
      color: #3899ec;
      display: inline-block;
      border-radius: 32px;
      line-height: 32px;
      height: 32px;
      padding: 0 20px;
      cursor: pointer; }
      .control-date-picker .dp-footer .goto-today:hover,
      .control-date-picker .dp-footer .goto-selection:hover {
        background-color: #eaf7ff; }
    .control-date-picker .dp-footer .goto-selection {
      float: right; }
      .control-date-picker .dp-footer .goto-selection:disabled {
        cursor: inherit;
        color: #bcbcbc;
        background-color: transparent; }

.composite-date-picker-labeled {
  padding: 18px 24px; }

.control-dock-direction {
  box-sizing: border-box;
  background: #eaf7ff;
  width: 60px;
  height: 42px; }
  .control-radio-buttons-list .radio-button-wrapper.selected .control-dock-direction:not(.disabled).top, .control-radio-buttons-list .radio-button-wrapper.selected .control-dock-direction:not(.disabled).right, .control-radio-buttons-list .radio-button-wrapper.selected .control-dock-direction:not(.disabled).bottom, .control-radio-buttons-list .radio-button-wrapper.selected .control-dock-direction:not(.disabled).left {
    border-color: #3899ec;
    background: #d3edff; }
  .control-radio-buttons-list .radio-button-wrapper.selected .control-dock-direction:not(.disabled).middle {
    background: #3899ec; }
  .control-dock-direction.top {
    margin-bottom: 1px;
    margin-right: 1px;
    border-top: 12px solid #b1ddf8; }
    .control-dock-direction.top:hover {
      border-top-color: #4eb7f5; }
    .control-dock-direction.top.left {
      border-top-left-radius: 5px; }
    .control-dock-direction.top.right {
      border-top-right-radius: 5px; }
  .control-dock-direction.left {
    border-left: 12px solid #b1ddf8;
    margin-left: 0; }
    .control-dock-direction.left:hover {
      border-left-color: #4eb7f5; }
  .control-dock-direction.middle {
    margin-left: 1px;
    margin-right: 1px; }
    .control-dock-direction.middle:hover {
      background: #4eb7f5; }
  .control-dock-direction.right {
    border-right: 12px solid #b1ddf8;
    margin-right: 0; }
    .control-dock-direction.right:hover {
      border-right-color: #4eb7f5; }
  .control-dock-direction.bottom {
    margin-top: 1px;
    margin-right: 1px;
    border-bottom: 12px solid #b1ddf8; }
    .control-dock-direction.bottom:hover {
      border-bottom-color: #4eb7f5; }
    .control-dock-direction.bottom.left {
      border-bottom-left-radius: 5px; }
    .control-dock-direction.bottom.right {
      border-bottom-right-radius: 5px;
      margin-right: 0; }
  .control-dock-direction.disabled {
    pointer-events: none;
    cursor: pointer;
    background: #f0f0f0;
    border-color: #d5d5d5; }
    .control-dock-direction.disabled:hover {
      background: #f0f0f0; }
    .control-dock-direction.disabled.middle {
      background: #ffffff; }
      .control-dock-direction.disabled.middle:hover {
        background: #ffffff; }

.control-dock {
  position: relative;
  align-self: center;
  width: 182px; }
  .control-dock > .grid {
    position: absolute;
    box-sizing: border-box;
    pointer-events: none; }
    .disabled.control-dock > .grid.horizontal, .disabled.control-dock > .grid.vertical {
      border-color: #d5d5d5; }
    .control-dock > .grid.horizontal {
      border-top: 1px dotted #7fccf7;
      border-bottom: 1px dotted #7fccf7;
      height: 44px;
      top: 42px;
      left: 12px;
      right: 12px; }
    .control-dock > .grid.vertical {
      border-left: 1px dotted #7fccf7;
      border-right: 1px dotted #7fccf7;
      width: 62px;
      left: 60px;
      top: 12px;
      bottom: 12px; }

.composite-dock-labeled {
  display: flex;
  flex-direction: column;
  padding: 12px 24px 24px; }

.composite-dropdown-labeled {
  display: flex;
  flex-direction: column;
  padding: 14px 24px; }

.composite-dropdown {
  padding: 14px 24px 10px; }

.composite-dropdown-with-button {
  display: flex;
  align-items: center; }
  .composite-dropdown-with-button::before {
    content: '';
    margin-left: 8px;
    margin-right: 9px;
    height: 24px;
    width: 1px;
    background-color: #d9e1e8; }

.composite-dropdown-with-button-labeled {
  padding: 14px 24px; }

.composite-horizontal-tabs-labeled {
  padding: 12px 24px 0; }

.color-picker-input-ctrl {
  width: 30px;
  height: 30px;
  border: 1px solid #b6c1cd;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  cursor: pointer; }
  .composite-color-picker-input > .color-picker-input-ctrl {
    margin-left: 6px; }
  .color-picker-input-ctrl.disabled {
    cursor: auto;
    border: none;
    background-color: #d5d5d5 !important; }
  .color-picker-input-ctrl:not(.disabled):hover {
    border: 2px solid #d3edff; }
  .color-picker-input-ctrl:not(.disabled).selected {
    border: 2px solid #3899ec; }

.composite-link-button-with-color-input {
  display: flex;
  align-items: center; }
  .composite-link-button-with-color-input::before {
    content: '';
    margin-left: 8px;
    margin-right: 9px;
    height: 24px;
    width: 1px;
    background-color: #d9e1e8; }

.composite-link-button-with-color-input-labeled {
  padding: 16px 24px 15px; }

.font-option-container {
  display: flex;
  align-items: center; }
  .font-option-container > .font-label {
    font-size: 16px;
    color: #162d3d;
    width: 250px; }
    .control-font-family-picker .font-option-container > .font-label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      height: 36px;
      line-height: 36px;
      min-height: 36px;
      display: inline; }
    .dropdown-selected .selected-container .font-option-container > .font-label {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
    .control-font-family-picker.disabled .selected-content .font-option-container > .font-label {
      color: #7a7a7a; }
    .control-font-family-picker:not(.toolbar-mode) .font-option-container > .font-label {
      font-size: 18px;
      padding-right: 30px;
      color: #162d3d; }
  .font-option-container > .font-example {
    font-size: 16px;
    margin-left: auto; }
    .selected-container .font-option-container > .font-example {
      display: none; }
  .font-option .font-option-container {
    height: 24px; }

.control-font-family-picker-options .control-dropdown-sticky-footer.font-family-footer {
  display: flex;
  border-top: 1px solid #e2e2e2; }
  .control-font-family-picker-options .control-dropdown-sticky-footer.font-family-footer .font-family-footer-label {
    cursor: pointer; }
  .control-font-family-picker-options .control-dropdown-sticky-footer.font-family-footer .footer-links-container {
    display: flex;
    color: #3899ec;
    width: 100%; }
    .control-font-family-picker-options .control-dropdown-sticky-footer.font-family-footer .footer-links-container svg {
      fill: #3899ec;
      margin-right: 8px; }
    .control-font-family-picker-options .control-dropdown-sticky-footer.font-family-footer .footer-links-container .font-family-footer-link {
      display: flex;
      align-items: center;
      cursor: pointer; }
      .control-font-family-picker-options .control-dropdown-sticky-footer.font-family-footer .footer-links-container .font-family-footer-link.upload-font-link {
        margin-left: auto; }

.composite-dropdown-with-color-input {
  display: flex;
  align-items: center; }
  .composite-dropdown-with-color-input::before {
    content: '';
    margin-left: 8px;
    margin-right: 9px;
    height: 24px;
    width: 1px;
    background-color: #d9e1e8; }

.composite-dropdown-with-color-input-labeled {
  padding: 14px 24px; }

.composite-google-preview-with-rich-text {
  padding-top: 22px;
  padding-bottom: 2px; }

.composite-text-input {
  padding: 14px 12px; }
  .composite-toggle-switch-with-selective-input .composite-text-input {
    padding-top: 0; }

.composite-text-input-labeled {
  display: flex;
  flex-direction: column;
  padding: 14px 12px; }

.control-text-input-multiline-with-button {
  display: flex;
  flex-direction: column; }
  .composite-text-input-multiline-with-button-labeled.composite-full-height .control-text-input-multiline-with-button {
    height: 100%; }

.composite-text-input-multiline-with-button {
  display: flex;
  flex-direction: column;
  padding: 0 12px 12px; }

.composite-text-input-multiline-with-button-labeled {
  display: flex;
  flex-direction: column;
  padding: 16px 12px 12px; }
  .composite-text-input-multiline-with-button-labeled.composite-full-height {
    height: 100%;
    box-sizing: border-box; }

.composite-toggle-switch {
  padding: 22px 24px; }

.composite-button-large {
  display: flex;
  justify-content: center;
  padding: 24px;
  flex-direction: column;
  align-items: center; }

.composite-button-large-fixed-bottom {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 24px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0, #fff 90%);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  flex-direction: column;
  align-items: center; }

.composite-button-large-labeled {
  display: flex;
  flex-direction: column;
  padding: 20px 24px; }

.composite-button-left {
  padding: 15px 0 18px 12px; }

.composite-button-medium {
  display: flex;
  justify-content: center;
  padding: 18px 24px; }

.composite-buttons-group .control-buttons-list-group,
.composite-buttons-group-labeled .control-buttons-list-group {
  align-self: center; }

.composite-buttons-group {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  white-space: nowrap;
  color: #2b5672;
  padding: 24px 42px;
  display: flex;
  flex-direction: column; }

.composite-buttons-group-labeled {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  white-space: nowrap;
  color: #2b5672;
  padding: 18px 24px 24px 24px;
  display: flex;
  flex-direction: column; }

.composite-checkboxes {
  padding: 22px 24px; }

.composite-checkboxes-labeled {
  display: flex;
  flex-direction: column;
  padding: 18px 24px; }

.composite-big-panel-header {
  width: 100%; }

.composite-big-panel-header-with-illustration {
  width: 100%; }

.composite-panel-content {
  padding: 36px 48px 0 48px; }
  .composite-popup > .composite-panel-content {
    flex: 1 1 auto; }
  .composite-popup-small-size > .composite-panel-content {
    padding: 24px 24px 0 24px; }
  .composite-popup-medium-size > .composite-big-panel-header + .composite-panel-content {
    padding: 24px 24px 0 24px; }
  .composite-popup-medium-size > .composite-big-panel-header-with-illustration + .composite-panel-content {
    padding: 0 24px; }
  .composite-popup-large-size > .composite-big-panel-header + .composite-panel-content {
    padding: 36px 48px 0 48px; }
  .composite-popup-large-size > .composite-big-panel-header-with-illustration + .composite-panel-content {
    padding: 0 48px; }

.composite-popup {
  box-shadow: 0 0 18px 0 rgba(22, 45, 61, 0.27);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column; }
  .composite-popup.composite-full-height {
    height: 100%; }

.composite-popup-small-size {
  width: 564px; }

.composite-popup-medium-size {
  width: 504px; }

.composite-popup-large-size {
  width: 744px; }

.composite-numeric-input {
  padding: 0 12px 0 24px; }

.composite-panel-content-asymmetric {
  padding-left: 12px;
  padding-right: 18px; }

.composite-preloader {
  min-height: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .composite-preloader.medium {
    min-height: 72px; }
  .composite-preloader.large {
    min-height: 120px; }

.composite-rich-text-with-illustration > .control-illustration {
  width: 144px; }

.composite-rich-text-labeled {
  padding: 18px 24px;
  box-sizing: border-box; }
  .composite-rich-text-labeled.composite-centered {
    text-align: center; }
  .composite-rich-text-with-illustration-vertical.content-width-md > .composite-rich-text-labeled,
  .composite-rich-text-with-illustration-vertical > .composite-rich-text-labeled {
    max-width: 288px; }
  .composite-rich-text-with-illustration-vertical.content-width-xl > .composite-rich-text-labeled {
    max-width: 420px; }

.composite-rich-text {
  padding: 18px 24px;
  box-sizing: border-box; }
  .composite-rich-text-with-illustration-vertical.content-width-md > .composite-rich-text,
  .composite-rich-text-with-illustration-vertical > .composite-rich-text {
    max-width: 288px; }
  .composite-rich-text-with-illustration-vertical.content-width-xl > .composite-rich-text {
    max-width: 420px; }

.composite-rich-text-with-illustration {
  display: flex; }

.composite-rich-text-with-illustration-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 24px; }

.composite-promotional-list {
  text-align: center;
  padding: 24px 48px; }
  .composite-panel-content .composite-promotional-list:last-child {
    padding-bottom: 0; }

.composite-radio-buttons-labeled {
  padding: 18px 24px; }

.composite-radio-buttons {
  padding: 18px 24px; }

.composite-radio-buttons-with-illustration {
  padding: 24px 24px 0; }

.composite-editable-url-labeled {
  padding: 16px 24px 28px 24px;
  text-align: left; }

.composite-dynamic-field-labeled .dynamic-field-container.vertical.un-prefixed {
  margin-left: -12px; }

.dynamic-field-container.horizontal {
  display: flex;
  align-items: center; }

.dynamic-field-container .field-container {
  align-items: center;
  width: 100%; }
  .vertical.dynamic-field-container .field-container {
    display: flex; }
  .horizontal.dynamic-field-container .field-container {
    display: inline-flex; }
  .dynamic-field-container .field-container > .input-prefix {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    color: #7a92a5; }
    .disabled.dynamic-field-container .field-container > .input-prefix {
      color: #bcbcbc; }
  .dynamic-field-container .field-container > .text-container {
    position: relative;
    width: 100%; }
    .dynamic-field-container .field-container > .text-container > .overlay-container > .overlay {
      width: 100%;
      height: 36px;
      bottom: 0;
      position: absolute;
      box-sizing: border-box;
      pointer-events: none;
      overflow: hidden;
      display: flex;
      align-items: center;
      border: 1px solid transparent;
      border-right-width: 12px;
      border-left-width: 12px;
      z-index: 1; }
      .dynamic-field-container .field-container > .text-container > .overlay-container > .overlay.hidden {
        visibility: hidden; }
      .dynamic-field-container .field-container > .text-container > .overlay-container > .overlay > .dynamic-text,
      .dynamic-field-container .field-container > .text-container > .overlay-container > .overlay > .static-text {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 14px; }
      .dynamic-field-container .field-container > .text-container > .overlay-container > .overlay > .static-text {
        color: #162d3d; }
        .disabled.dynamic-field-container .field-container > .text-container > .overlay-container > .overlay > .static-text {
          color: #7a7a7a; }
      .dynamic-field-container .field-container > .text-container > .overlay-container > .overlay > .dynamic-text {
        margin: 0 2px;
        background-color: #2b5672;
        color: #fff;
        border-radius: 6px;
        height: 24px;
        line-height: 24px; }
        .disabled.dynamic-field-container .field-container > .text-container > .overlay-container > .overlay > .dynamic-text {
          background-color: #939393; }
        .dynamic-field-container .field-container > .text-container > .overlay-container > .overlay > .dynamic-text::before, .dynamic-field-container .field-container > .text-container > .overlay-container > .overlay > .dynamic-text::after {
          color: transparent; }
        .dynamic-field-container .field-container > .text-container > .overlay-container > .overlay > .dynamic-text::before {
          content: attr(data-prefix); }
        .dynamic-field-container .field-container > .text-container > .overlay-container > .overlay > .dynamic-text::after {
          content: attr(data-suffix); }
    .dynamic-field-container .field-container > .text-container > .input-container.success + .overlay-container > .overlay, .dynamic-field-container .field-container > .text-container > .input-container.invalid + .overlay-container > .overlay {
      width: calc(100% - 42px); }
    .dynamic-field-container .field-container > .text-container > .input-container .input {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px; }
    .dynamic-field-container .field-container > .text-container > .input-container.hidden-text .input {
      color: transparent; }

.vertical.dynamic-field-container > .control-dropdown {
  display: flex; }

.horizontal.dynamic-field-container > .control-dropdown {
  display: inline-flex; }

.un-prefixed.vertical.dynamic-field-container > .control-dropdown {
  margin-left: 12px; }

.horizontal.dynamic-field-container > .control-dropdown {
  margin-left: 12px; }

.dynamic-field-container > .control-dropdown .dropdown-selected {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  color: #3899ec; }
  .vertical.dynamic-field-container > .control-dropdown .dropdown-selected:hover {
    color: #3899ec;
    text-decoration: underline; }
  .horizontal.dynamic-field-container > .control-dropdown .dropdown-selected {
    display: flex;
    align-items: center;
    height: 30px;
    min-height: 30px;
    padding: 0 20px;
    border-radius: 15px;
    box-sizing: border-box;
    background-color: #eaf7ff; }
    .horizontal.dynamic-field-container > .control-dropdown .dropdown-selected:hover {
      background-color: #d3edff; }

.dropdown-options-container.dynamic-field-options > .dropdown-options {
  width: 240px; }

.composite-dynamic-field-labeled {
  padding: 20px 12px 8px 24px; }

.composite-dynamic-field-with-description {
  padding: 20px 24px; }

.control-slider-input {
  display: flex; }
  .composite-color-picker-input > .control-slider-input {
    flex: 1; }
  .control-slider-input.hue-slider > .slider > .track {
    top: 0;
    border-radius: 0;
    box-shadow: none;
    background-image: linear-gradient(to right, red 0%, #ff4d00 5%, #ff9900 10%, #ffe600 15%, #ccff00 20%, #80ff00 25%, #33ff00 30%, #00ff1a 35%, #00ff66 40%, #00ffb3 45%, cyan 50%, #00b3ff 55%, #0066ff 60%, #001aff 65%, #3300ff 70%, #8000ff 75%, #cc00ff 80%, #ff00e6 85%, #ff0099 90%, #ff004d 95%, red 100%); }
    .control-slider-input.hue-slider > .slider > .track > .colored-part {
      display: none; }
  .control-slider-input.hue-slider > .slider > .knob-container {
    width: calc(100% - 12px); }
    .control-slider-input.hue-slider > .slider > .knob-container > .knob {
      top: -3px;
      width: 12px;
      height: 12px;
      box-shadow: 0 2px 1px 0 rgba(43, 86, 114, 0.4);
      box-sizing: border-box; }
      .control-slider-input.hue-slider > .slider > .knob-container > .knob::after {
        background-color: #fff; }
  .control-slider-input.opacity-slider > .slider.with-numeric-input {
    margin-top: 13px; }
  .control-slider-input.opacity-slider > .slider > .track {
    background-color: transparent;
    box-shadow: none;
    border: 1px solid #d5d5d5;
    height: 8px;
    box-sizing: border-box;
    background-image: linear-gradient(45deg, #ccc 26%, transparent 26%, transparent 74%, #ccc 74%, #ccc), linear-gradient(45deg, #ccc 26%, transparent 26%, transparent 74%, #ccc 74%, #ccc);
    background-size: 8px 8px;
    background-position: 0 0, 4px 4px, 4px 4px, 0 0;
    background-color: #fff; }
    .disabled.control-slider-input.opacity-slider > .slider > .track {
      background-color: transparent;
      box-shadow: none; }
    .control-slider-input.opacity-slider > .slider > .track > .colored-part {
      box-shadow: none;
      background-color: transparent;
      background: linear-gradient(90deg, transparent 0%, #FFF 100%); }
      .disabled.control-slider-input.opacity-slider > .slider > .track > .colored-part {
        background-color: transparent;
        box-shadow: none; }
  .control-slider-input > .slider {
    flex: 1;
    position: relative;
    height: 16px; }
    .control-slider-input > .slider.with-numeric-input {
      margin-top: 15px;
      margin-bottom: 2px; }
    .control-slider-input > .slider > .track {
      cursor: pointer;
      position: absolute;
      top: 4px;
      left: 0;
      height: 6px;
      width: 100%;
      background-color: #d3edff;
      border-radius: 3px;
      box-shadow: inset 0 1px 0 0 #c0e1f2; }
      .disabled.control-slider-input > .slider > .track {
        background-color: #e2e2e2;
        cursor: default;
        box-shadow: none; }
      .control-slider-input > .slider > .track > .colored-part {
        position: absolute;
        left: 0;
        height: 6px;
        background-color: #3899ec;
        border-radius: 3px;
        box-shadow: inset 0 1px 0 0 #3899ec;
        will-change: width; }
        .disabled.control-slider-input > .slider > .track > .colored-part {
          background-color: #bcbcbc;
          box-shadow: inset 0 1px 0 0 #bcbcbc; }
    .control-slider-input > .slider > .knob-container {
      width: calc(100% - 16px);
      position: relative; }
      .control-slider-input > .slider > .knob-container > .knob {
        cursor: pointer;
        position: absolute;
        top: -1px;
        display: inline-block;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 1px 3px 0 rgba(52, 94, 151, 0.6);
        width: 16px;
        height: 16px;
        will-change: left;
        transition: box-shadow 0.25s ease-in-out 0s; }
        .disabled.control-slider-input > .slider > .knob-container > .knob {
          cursor: default; }
        .control-slider-input > .slider > .knob-container > .knob:hover {
          box-shadow: 0 1px 2px 2px rgba(138, 209, 250, 0.58); }
        .control-slider-input > .slider > .knob-container > .knob:after {
          position: absolute;
          content: '';
          display: inline-block;
          border-radius: 50%;
          background-color: #3899ec;
          width: 8px;
          height: 8px;
          left: 4px;
          top: 4px; }
          .disabled.control-slider-input > .slider > .knob-container > .knob:after {
            background-color: #bcbcbc; }

.composite-slider-labeled {
  padding: 14px 12px 12px 24px; }

.composite-slider {
  padding: 12px 12px 12px 24px; }

.composite-thumbnails {
  padding: 24px 24px 18px 24px; }

.composite-thumbnail-button-group {
  padding: 20px 24px 24px; }

.composite-color-picker-input {
  padding: 9px 24px;
  display: flex;
  align-items: center; }
  .composite-color-picker-input-labeled .composite-color-picker-input {
    padding: 0; }

.composite-color-picker-input-labeled {
  padding: 15px 24px 12px; }

.composite-color-select-labeled {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px; }

.composite-text-formatting {
  padding: 19px 18px;
  display: flex; }

.composite-upload-button {
  padding: 24px 24px 18px 24px; }

.composite-upload-button-labeled {
  padding: 12px 24px 18px 24px; }

.control-tab {
  width: 100%; }
  .control-vertical-tabs.arrowed-box .control-tab {
    display: none; }

.control-vertical-tabs {
  padding: 15px 10px;
  box-sizing: border-box;
  background-color: #f6fbff;
  height: 100%;
  min-width: 114px;
  max-width: 150px;
  flex-shrink: 0; }
  .control-vertical-tabs.arrowed-box {
    padding: 0;
    max-width: 153px;
    width: 153px;
    background-color: transparent; }

.composite-vertical-tabs-labeled {
  padding: 12px 24px 18px; }

.sbdl-item-row-base {
  position: relative;
  width: 288px;
  min-height: 47px; }
  .sbdl-item-row.disabled > .sbdl-item-row-base .drag-handle-container .symbol-dragHandle {
    fill: #bcbcbc; }
  .sbdl-item-row-base .content {
    position: relative;
    display: inline-block;
    height: auto;
    width: 100%;
    vertical-align: top;
    overflow-x: hidden; }
  .sbdl-item-row-base.has-drag-handle .content {
    left: 48px;
    width: calc(100% - 48px); }
  .sbdl-item-row-base.has-drag-handle .drag-handle-container {
    position: absolute;
    display: inline-block;
    width: 48px;
    height: 100%; }
    .sbdl-item-row-base.has-drag-handle .drag-handle-container .symbol-dragHandle {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      fill: #3899ec; }
      .sbdl-item-row-base.has-drag-handle .drag-handle-container .symbol-dragHandle > svg,
      .sbdl-item-row-base.has-drag-handle .drag-handle-container .symbol-dragHandle .dragHandle-tooltip svg {
        width: 8px;
        height: 12px;
        margin-left: 24px;
        margin-right: 16px; }
  .sbdl-item-row-base.disabled-drag-handle .symbol-dragHandle {
    fill: #bcbcbc !important; }

.sbdl-text-edit {
  cursor: default; }
  .sbdl-text-edit .input-container {
    display: inline-block; }
    .sbdl-text-edit .input-container .input:hover,
    .sbdl-text-edit .input-container .input:focus {
      background-color: #fff; }
    .invalid.sbdl-text-edit .input-container .input {
      border: 1px solid #ee5951; }

.sbdl-item-row {
  width: 288px;
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  background-color: #fff;
  user-select: none;
  cursor: default; }
  .sbdl-item-row.edit-mode {
    background-color: #d3edff; }
  .sbdl-item-row.disabled {
    pointer-events: none;
    cursor: default; }
    .sbdl-item-row.disabled .view-mode-content .label,
    .sbdl-item-row.disabled .view-mode-content .value {
      color: #939393; }
  .sbdl-item-row:not(.withDragHandle) .view-mode-content {
    left: 24px;
    width: calc(100% - 24px); }
  .sbdl-item-row.draggable:not(.selectable) {
    cursor: move; }
  .sbdl-item-row.draggable .drag-handle-container {
    cursor: move; }
  .sbdl-item-row.selected {
    background-color: #d3edff; }
    .sbdl-item-row.selected .view-mode-content .context-menu-container .context-menu-button {
      visibility: visible; }
  .sbdl-item-row.dragging {
    background-color: #eaf7ff;
    box-shadow: 0 1px 3.92px 0.08px rgba(45, 86, 114, 0.22);
    cursor: move; }
    .sbdl-item-row.dragging .view-mode-content .context-menu-container .context-menu-button {
      visibility: visible; }
  .sbdl-item-row:not(.selected) .view-mode-content .context-menu-container .context-menu-button {
    visibility: hidden; }
  .sbdl-item-row:not(.selected):hover.selectable {
    background-color: #eaf7ff;
    cursor: pointer; }
  .sbdl-item-row:not(.selected):hover .view-mode-content .context-menu-container .context-menu-button {
    visibility: visible; }
  .sbdl-item-row .text-edit-container {
    height: 47px;
    display: flex;
    align-items: center; }
    .sbdl-item-row .text-edit-container .input-container {
      margin-left: 12px; }
      .sbdl-item-row .text-edit-container .input-container > input {
        width: 192px;
        height: 24px;
        border-radius: 4px;
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 14px; }
    .sbdl-item-row .text-edit-container .control-button {
      margin-left: 6px;
      width: 54px;
      height: 24px;
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      padding: 0; }
  .sbdl-item-row.custom-row:not(.has-value) .view-mode-content {
    height: 60px; }
  .sbdl-item-row.has-value .view-mode-content {
    height: 65px; }
    .multiline.sbdl-item-row.has-value .view-mode-content {
      height: auto;
      padding: 15px 0; }
    .multiline .sbdl-item-row.has-value .view-mode-content {
      height: auto;
      padding: 15px 0; }
    .sbdl-item-row.has-value .view-mode-content .label {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px; }
    .sbdl-item-row.has-value .view-mode-content .value {
      padding-top: 3px;
      color: #7a92a5;
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 12px;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
      .multiline.sbdl-item-row.has-value .view-mode-content .value {
        white-space: normal; }
      .multiline .sbdl-item-row.has-value .view-mode-content .value {
        white-space: normal; }
      .sbdl-item-row.has-value .view-mode-content .value.placeholder {
        color: #b6c1cd; }
  .sbdl-item-row.has-value .text-edit-container {
    height: 65px; }
    .sbdl-item-row.has-value .text-edit-container.label-text-edit .input-container > input {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px; }
    .sbdl-item-row.has-value .text-edit-container.value-text-edit .input-container > input {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 12px; }
  .sbdl-item-row.bold .view-mode-content .label {
    font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-weight: 700; }
  .sbdl-item-row .view-mode-content {
    position: relative;
    height: 47px;
    width: 100%;
    color: #2b5672; }
    .sbdl-item-row .view-mode-content .text-container {
      width: calc(100% - 48px);
      height: 100%;
      display: flex;
      align-items: center; }
      .sbdl-item-row .view-mode-content .text-container > .content-before,
      .sbdl-item-row .view-mode-content .text-container > .content-after {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center; }
      .sbdl-item-row .view-mode-content .text-container > .content-before {
        width: 40px; }
      .sbdl-item-row .view-mode-content .text-container > .content-after {
        width: 15px;
        min-width: 15px;
        margin-left: 8px;
        margin-right: 8px; }
      .sbdl-item-row .view-mode-content .text-container > .text-inner-content {
        width: 100%; }
      .sbdl-item-row .view-mode-content .text-container > .content-before + .text-inner-content {
        margin-left: 8px; }
    .sbdl-item-row .view-mode-content .label {
      width: 100%;
      font-size: 14px;
      font-weight: 300;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
      .sbdl-item-row .view-mode-content .label.placeholder {
        color: #b6c1cd; }
    .sbdl-item-row .view-mode-content .context-menu-container {
      position: absolute;
      top: 0;
      right: 0;
      width: 48px;
      height: 100%; }
      .sbdl-item-row .view-mode-content .context-menu-container .dropdown-selected.is-open .context-menu-button {
        visibility: visible; }
      .sbdl-item-row .view-mode-content .context-menu-container .control-context-menu {
        position: absolute;
        top: calc(50% - 12px); }

.sort-by-drag-list {
  width: 288px;
  overflow-x: hidden; }
  .sort-by-drag-list:not(.no-border-top) {
    border-top: 1px solid #d9e1e8; }
  .sort-by-drag-list .sbdl-drag-placeholder {
    background-color: #f8f8f8;
    border-top: 1px dashed #d9e1e8; }
  .sort-by-drag-list.disabled {
    pointer-events: none; }
  .sort-by-drag-list .sbdl-item-row {
    border-bottom: 1px solid #d9e1e8; }

.main-top-bar > .top-bar-right-section .top-bar-btn-save .symbol.top-bar-save-symbol {
  display: none; }

.main-top-bar > .top-bar-right-section .top-bar-button-preview .symbol {
  display: none; }

.main-top-bar > .top-bar-right-section .symbol-topBarMobile,
.main-top-bar > .top-bar-right-section .symbol-topBarDesktop {
  margin-right: 8px; }

.main-top-bar > .top-bar-right-section .symbol-topBarMobile .top-bar-mobile-symbol-small,
.main-top-bar > .top-bar-right-section .symbol-topBarDesktop .top-bar-desktop-symbol-small {
  display: none; }

.main-top-bar > .top-bar-right-section .top-bar-btn-back-to-editor .fake-preview-item-symbol {
  display: none; }

@media (max-width: 1146px) {
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .symbol-topBarMobile,
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .symbol-topBarDesktop {
    margin-right: 0; }
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .top-bar-btn-device .label {
    display: none; } }

@media (max-width: 1096px) {
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .top-bar-btn-save {
    min-width: 0;
    width: 60px;
    padding: 0 12px; }
    .main-top-bar.top-bar-lang-en > .top-bar-right-section .top-bar-btn-save .symbol.top-bar-save-symbol {
      display: inline; }
    .main-top-bar.top-bar-lang-en > .top-bar-right-section .top-bar-btn-save .label {
      display: none; }
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .symbol-topBarMobile .top-bar-mobile-symbol-large,
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .symbol-topBarDesktop .top-bar-desktop-symbol-large {
    display: none; }
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .symbol-topBarMobile .top-bar-mobile-symbol-small,
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .symbol-topBarDesktop .top-bar-desktop-symbol-small {
    display: inline; } }

@media (max-width: 1070px) {
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .top-bar-button-preview {
    min-width: 0;
    width: 60px;
    padding: 0 12px; }
    .main-top-bar.top-bar-lang-en > .top-bar-right-section .top-bar-button-preview .symbol {
      display: inline; }
    .main-top-bar.top-bar-lang-en > .top-bar-right-section .top-bar-button-preview .label {
      display: none; }
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .top-bar-btn-back-to-editor .fake-preview-item-symbol {
    display: inline-block; }
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .top-bar-btn-back-to-editor .fake-preview-item-text {
    display: none; } }

@media (max-width: 1040px) {
  .main-top-bar.top-bar-lang-en > .top-bar-left-section .top-bar-pages-navigation {
    width: 172px;
    height: 100%; }
    .main-top-bar.top-bar-lang-en > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-name-container {
      width: 100%; }
    .main-top-bar.top-bar-lang-en > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-name {
      padding: 12px; }
    .main-top-bar.top-bar-lang-en > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-caret,
    .main-top-bar.top-bar-lang-en > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-symbol,
    .main-top-bar.top-bar-lang-en > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-name-label {
      display: none; }
    .main-top-bar.top-bar-lang-en > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-search .pages-navigation-search-symbol {
      padding-left: 0;
      padding-right: 0; }
      .main-top-bar.top-bar-lang-en > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-search .pages-navigation-search-symbol .symbol-pagesSearch {
        display: none; }
    .main-top-bar.top-bar-lang-en > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-search .pages-navigation-search-clear {
      display: none; }
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .top-bar-btn-stores-migration {
    display: none; } }

@media (max-width: 986px) {
  .main-top-bar.top-bar-lang-en > .top-bar-left-section .top-bar-menu-bar-item.menu-bar-upgrade-item {
    display: none; } }

@media (max-width: 896px) {
  .main-top-bar.top-bar-lang-en > .top-bar-right-section .top-bar-button-redo {
    display: none; } }

@media (max-width: 1166px) {
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .symbol-topBarMobile,
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .symbol-topBarDesktop {
    margin-right: 0; }
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .top-bar-btn-device .label {
    display: none; } }

@media (max-width: 1120px) {
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .top-bar-btn-save {
    min-width: 0;
    width: 60px;
    padding: 0 12px; }
    .main-top-bar.top-bar-lang-small > .top-bar-right-section .top-bar-btn-save .symbol.top-bar-save-symbol {
      display: inline; }
    .main-top-bar.top-bar-lang-small > .top-bar-right-section .top-bar-btn-save .label {
      display: none; }
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .symbol-topBarMobile .top-bar-mobile-symbol-large,
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .symbol-topBarDesktop .top-bar-desktop-symbol-large {
    display: none; }
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .symbol-topBarMobile .top-bar-mobile-symbol-small,
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .symbol-topBarDesktop .top-bar-desktop-symbol-small {
    display: inline; } }

@media (max-width: 1094px) {
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .top-bar-button-preview {
    min-width: 0;
    width: 60px;
    padding: 0 12px; }
    .main-top-bar.top-bar-lang-small > .top-bar-right-section .top-bar-button-preview .symbol {
      display: inline; }
    .main-top-bar.top-bar-lang-small > .top-bar-right-section .top-bar-button-preview .label {
      display: none; }
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .top-bar-btn-back-to-editor .fake-preview-item-symbol {
    display: inline-block; }
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .top-bar-btn-back-to-editor .fake-preview-item-text {
    display: none; } }

@media (max-width: 1064px) {
  .main-top-bar.top-bar-lang-small > .top-bar-left-section .top-bar-pages-navigation {
    width: 172px;
    height: 100%; }
    .main-top-bar.top-bar-lang-small > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-name-container {
      width: 100%; }
    .main-top-bar.top-bar-lang-small > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-name {
      padding: 12px; }
    .main-top-bar.top-bar-lang-small > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-caret,
    .main-top-bar.top-bar-lang-small > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-symbol,
    .main-top-bar.top-bar-lang-small > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-name-label {
      display: none; }
    .main-top-bar.top-bar-lang-small > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-search .pages-navigation-search-symbol {
      padding-left: 0;
      padding-right: 0; }
      .main-top-bar.top-bar-lang-small > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-search .pages-navigation-search-symbol .symbol-pagesSearch {
        display: none; }
    .main-top-bar.top-bar-lang-small > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-search .pages-navigation-search-clear {
      display: none; }
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .top-bar-btn-stores-migration {
    display: none; } }

@media (max-width: 1010px) {
  .main-top-bar.top-bar-lang-small > .top-bar-left-section .top-bar-menu-bar-item.menu-bar-upgrade-item {
    display: none; } }

@media (max-width: 912px) {
  .main-top-bar.top-bar-lang-small > .top-bar-right-section .top-bar-button-redo {
    display: none; } }

@media (max-width: 1238px) {
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .symbol-topBarMobile,
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .symbol-topBarDesktop {
    margin-right: 0; }
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .top-bar-btn-device .label {
    display: none; } }

@media (max-width: 1208px) {
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .top-bar-btn-save {
    min-width: 0;
    width: 60px;
    padding: 0 12px; }
    .main-top-bar.top-bar-lang-medium > .top-bar-right-section .top-bar-btn-save .symbol.top-bar-save-symbol {
      display: inline; }
    .main-top-bar.top-bar-lang-medium > .top-bar-right-section .top-bar-btn-save .label {
      display: none; }
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .symbol-topBarMobile .top-bar-mobile-symbol-large,
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .symbol-topBarDesktop .top-bar-desktop-symbol-large {
    display: none; }
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .symbol-topBarMobile .top-bar-mobile-symbol-small,
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .symbol-topBarDesktop .top-bar-desktop-symbol-small {
    display: inline; } }

@media (max-width: 1164px) {
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .top-bar-button-preview {
    min-width: 0;
    width: 60px;
    padding: 0 12px; }
    .main-top-bar.top-bar-lang-medium > .top-bar-right-section .top-bar-button-preview .symbol {
      display: inline; }
    .main-top-bar.top-bar-lang-medium > .top-bar-right-section .top-bar-button-preview .label {
      display: none; }
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .top-bar-btn-back-to-editor .fake-preview-item-symbol {
    display: inline-block; }
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .top-bar-btn-back-to-editor .fake-preview-item-text {
    display: none; } }

@media (max-width: 1124px) {
  .main-top-bar.top-bar-lang-medium > .top-bar-left-section .top-bar-pages-navigation {
    width: 172px;
    height: 100%; }
    .main-top-bar.top-bar-lang-medium > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-name-container {
      width: 100%; }
    .main-top-bar.top-bar-lang-medium > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-name {
      padding: 12px; }
    .main-top-bar.top-bar-lang-medium > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-caret,
    .main-top-bar.top-bar-lang-medium > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-symbol,
    .main-top-bar.top-bar-lang-medium > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-name-label {
      display: none; }
    .main-top-bar.top-bar-lang-medium > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-search .pages-navigation-search-symbol {
      padding-left: 0;
      padding-right: 0; }
      .main-top-bar.top-bar-lang-medium > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-search .pages-navigation-search-symbol .symbol-pagesSearch {
        display: none; }
    .main-top-bar.top-bar-lang-medium > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-search .pages-navigation-search-clear {
      display: none; }
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .top-bar-btn-stores-migration {
    display: none; } }

@media (max-width: 1072px) {
  .main-top-bar.top-bar-lang-medium > .top-bar-left-section .top-bar-menu-bar-item.menu-bar-upgrade-item {
    display: none; } }

@media (max-width: 982px) {
  .main-top-bar.top-bar-lang-medium > .top-bar-right-section .top-bar-button-redo {
    display: none; } }

@media (max-width: 1524px) {
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .symbol-topBarMobile,
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .symbol-topBarDesktop {
    margin-right: 0; }
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .top-bar-btn-device .label {
    display: none; } }

@media (max-width: 1432px) {
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .top-bar-btn-save {
    min-width: 0;
    width: 60px;
    padding: 0 12px; }
    .main-top-bar.top-bar-lang-large > .top-bar-right-section .top-bar-btn-save .symbol.top-bar-save-symbol {
      display: inline; }
    .main-top-bar.top-bar-lang-large > .top-bar-right-section .top-bar-btn-save .label {
      display: none; }
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .symbol-topBarMobile .top-bar-mobile-symbol-large,
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .symbol-topBarDesktop .top-bar-desktop-symbol-large {
    display: none; }
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .symbol-topBarMobile .top-bar-mobile-symbol-small,
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .symbol-topBarDesktop .top-bar-desktop-symbol-small {
    display: inline; } }

@media (max-width: 1346px) {
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .top-bar-button-preview {
    min-width: 0;
    width: 60px;
    padding: 0 12px; }
    .main-top-bar.top-bar-lang-large > .top-bar-right-section .top-bar-button-preview .symbol {
      display: inline; }
    .main-top-bar.top-bar-lang-large > .top-bar-right-section .top-bar-button-preview .label {
      display: none; }
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .top-bar-btn-back-to-editor .fake-preview-item-symbol {
    display: inline-block; }
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .top-bar-btn-back-to-editor .fake-preview-item-text {
    display: none; } }

@media (max-width: 1261px) {
  .main-top-bar.top-bar-lang-large > .top-bar-left-section .top-bar-pages-navigation {
    width: 172px;
    height: 100%; }
    .main-top-bar.top-bar-lang-large > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-name-container {
      width: 100%; }
    .main-top-bar.top-bar-lang-large > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-name {
      padding: 12px; }
    .main-top-bar.top-bar-lang-large > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-caret,
    .main-top-bar.top-bar-lang-large > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-symbol,
    .main-top-bar.top-bar-lang-large > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-current-page .pages-navigation-name-label {
      display: none; }
    .main-top-bar.top-bar-lang-large > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-search .pages-navigation-search-symbol {
      padding-left: 0;
      padding-right: 0; }
      .main-top-bar.top-bar-lang-large > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-search .pages-navigation-search-symbol .symbol-pagesSearch {
        display: none; }
    .main-top-bar.top-bar-lang-large > .top-bar-left-section .top-bar-pages-navigation .pages-navigation-search .pages-navigation-search-clear {
      display: none; }
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .top-bar-btn-stores-migration {
    display: none; } }

@media (max-width: 1211px) {
  .main-top-bar.top-bar-lang-large > .top-bar-left-section .top-bar-menu-bar-item.menu-bar-upgrade-item {
    display: none; } }

@media (max-width: 1061px) {
  .main-top-bar.top-bar-lang-large > .top-bar-right-section .top-bar-button-redo {
    display: none; } }

.Qp3Bv {
  display: inline-block;
  position: relative;
  border-radius: 12px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  -webkit-font-smoothing: antialiased;
  margin: 0; }

@font-face {
  font-family: 'HelveticaNeueW01-UltLt';
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/41774233-b9da-44be-b252-6a7b612fb1c7.eot?#iefix");
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/41774233-b9da-44be-b252-6a7b612fb1c7.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/4bff1fbb-b4bf-4d95-9c47-efcb14384e36.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/7f1f2a7d-3837-4c93-b373-f03c5da3f9a1.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/d9f2752a-8d82-4cf1-b82f-109c1105be7f.svg#d9f2752a-8d82-4cf1-b82f-109c1105be7f") format("svg"); }

@font-face {
  font-family: 'HelveticaNeueW01-Thin';
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/56be84de-9d60-4089-8df0-0ea6ec786b84.eot?#iefix");
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/56be84de-9d60-4089-8df0-0ea6ec786b84.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/50d35bbc-dfd4-48f1-af16-cf058f69421d.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/278bef59-6be1-4800-b5ac-1f769ab47430.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/2e309b1b-08b8-477f-bc9e-7067cf0af0b3.svg#2e309b1b-08b8-477f-bc9e-7067cf0af0b3") format("svg"); }

@font-face {
  font-family: 'HelveticaNeueW02-UltLt';
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/787ee748-9cce-45a0-910b-6b5c6e87e327.eot?#iefix");
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/787ee748-9cce-45a0-910b-6b5c6e87e327.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/84558c76-9f1b-44d2-ac62-d7937f43809b.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/411a88fe-e483-4fb8-af42-8369ebb1138d.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/6dfe33a4-0ad5-4c85-8e01-f48ecfe3c167.svg#6dfe33a4-0ad5-4c85-8e01-f48ecfe3c167") format("svg"); }

@font-face {
  font-family: 'HelveticaNeueW02-Thin';
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/30b6ffc3-3b64-40dd-9ff8-a3a850daf535.eot?#iefix");
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/30b6ffc3-3b64-40dd-9ff8-a3a850daf535.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/775a65da-14aa-4634-be95-6724c05fd522.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/988eaaa7-5565-4f65-bb17-146b650ce9e9.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/3503a1a6-91c3-4c42-8e66-2ea7b2b57541.svg#3503a1a6-91c3-4c42-8e66-2ea7b2b57541") format("svg"); }

@font-face {
  font-family: 'HelveticaNeueW01-45Ligh';
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/ae1656aa-5f8f-4905-aed0-93e667bd6e4a.eot?#iefix");
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/ae1656aa-5f8f-4905-aed0-93e667bd6e4a.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/530dee22-e3c1-4e9f-bf62-c31d510d9656.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/688ab72b-4deb-4e15-a088-89166978d469.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/7816f72f-f47e-4715-8cd7-960e3723846a.svg#7816f72f-f47e-4715-8cd7-960e3723846a") format("svg"); }

@font-face {
  font-family: 'HelveticaNeueW01-55Roma';
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b7693a83-b861-4aa6-85e0-9ecf676bc4d6.eot?#iefix");
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b7693a83-b861-4aa6-85e0-9ecf676bc4d6.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/bcf54343-d033-41ee-bbd7-2b77df3fe7ba.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b0ffdcf0-26da-47fd-8485-20e4a40d4b7d.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/da09f1f1-062a-45af-86e1-2bbdb3dd94f9.svg#da09f1f1-062a-45af-86e1-2bbdb3dd94f9") format("svg"); }

@font-face {
  font-family: 'HelveticaNeueW01-65Medi';
  font-weight: bold;
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/07fe0fec-b63f-4963-8ee1-535528b67fdb.eot?#iefix");
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/07fe0fec-b63f-4963-8ee1-535528b67fdb.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/60be5c39-863e-40cb-9434-6ebafb62ab2b.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/4c6503c9-859b-4d3b-a1d5-2d42e1222415.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/36c182c6-ef98-4021-9b0d-d63122c2bbf5.svg#36c182c6-ef98-4021-9b0d-d63122c2bbf5") format("svg"); }

@font-face {
  font-family: 'HelveticaNeueW01-75Bold';
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/c07fef9e-a934-42d7-92ad-69205f2b8a00.eot?#iefix");
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/c07fef9e-a934-42d7-92ad-69205f2b8a00.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/14ff6081-326d-4dae-b778-d7afa66166fc.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/8fda1e47-19be-46c7-8d83-8d4fb35572f0.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/f751c8ae-1057-46d9-8d74-62592e002568.svg#f751c8ae-1057-46d9-8d74-62592e002568") format("svg"); }

@font-face {
  font-family: 'HelveticaNeueW01-95Blac';
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b26ccb58-f2e3-47aa-a83a-02861bf54862.eot?#iefix");
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b26ccb58-f2e3-47aa-a83a-02861bf54862.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/74649485-cd74-443e-9d54-331ccd448900.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/feb77f4f-9e6b-4f88-909a-66199fd402ed.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/2e490192-e531-4236-9c55-90daaad4a34e.svg#2e490192-e531-4236-9c55-90daaad4a34e") format("svg"); }

@font-face {
  font-family: 'HelveticaNeueW02-45Ligh';
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/88fcd49a-13c7-4d0c-86b1-ad1e258bd75d.eot?#iefix");
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/88fcd49a-13c7-4d0c-86b1-ad1e258bd75d.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/9a2e4855-380f-477f-950e-d98e8db54eac.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/fa82d0ee-4fbd-4cc9-bf9f-226ad1fcbae2.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/48d599a6-92b5-4d43-a4ac-8959f6971853.svg#48d599a6-92b5-4d43-a4ac-8959f6971853") format("svg"); }

@font-face {
  font-family: 'HelveticaNeueW02-55Roma';
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/0b3a3fca-0fad-402b-bd38-fdcbad1ef776.eot?#iefix");
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/0b3a3fca-0fad-402b-bd38-fdcbad1ef776.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/d5af76d8-a90b-4527-b3a3-182207cc3250.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/1d238354-d156-4dde-89ea-4770ef04b9f9.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b68875cb-14a9-472e-8177-0247605124d7.svg#b68875cb-14a9-472e-8177-0247605124d7") format("svg"); }

@font-face {
  font-family: 'HelveticaNeueW02-65Medi';
  font-weight: bold;
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/55f60419-09c3-42bd-b81f-1983ff093852.eot?#iefix");
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/55f60419-09c3-42bd-b81f-1983ff093852.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/5b4a262e-3342-44e2-8ad7-719998a68134.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/4a3ef5d8-cfd9-4b96-bd67-90215512f1e5.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/58ab5075-53ea-46e6-9783-cbb335665f88.svg#58ab5075-53ea-46e6-9783-cbb335665f88") format("svg"); }

@font-face {
  font-family: 'HelveticaNeueW02-75Bold';
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/db853e0e-929b-4272-b420-c946c954cf3a.eot?#iefix");
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/db853e0e-929b-4272-b420-c946c954cf3a.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/4a9c62ab-b359-4081-8383-a0d1cdebd111.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/db5f9ba6-05a4-433a-9461-0a6f257a0c3a.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/25e09910-ffc3-4fc4-b0d1-db9a95dface8.svg#25e09910-ffc3-4fc4-b0d1-db9a95dface8") format("svg"); }

@font-face {
  font-family: 'HelveticaNeueW02-95Blac';
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/c8ec8ade-129c-47df-86b4-f9f1cd69ac15.eot?#iefix");
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/c8ec8ade-129c-47df-86b4-f9f1cd69ac15.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/6d5b06b3-f8db-4de6-aa46-2a6de9569b51.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/86438896-04f9-4558-a21a-e7a6d6061591.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/c486d95f-f220-435d-8a83-5d9497f04c49.svg#c486d95f-f220-435d-8a83-5d9497f04c49") format("svg"); }

@font-face {
  font-family: 'HelveticaNeueW10-45Ligh';
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/5b85c7cc-6ad4-4226-83f5-9d19e2974123.eot?#iefix");
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/5b85c7cc-6ad4-4226-83f5-9d19e2974123.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/835e7b4f-b524-4374-b57b-9a8fc555fd4e.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/2c694ef6-9615-473e-8cf4-d8d00c6bd973.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/3fc84193-a13f-4fe8-87f7-238748a4ac54.svg#3fc84193-a13f-4fe8-87f7-238748a4ac54") format("svg"); }

@font-face {
  font-family: 'HelveticaNeueW10-65Medi';
  font-weight: bold;
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/7092fdcc-7036-48ce-ae23-cfbc9be2e3b0.eot?#iefix");
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/7092fdcc-7036-48ce-ae23-cfbc9be2e3b0.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/5b29e833-1b7a-40ab-82a5-cfd69c8650f4.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b0298148-2d59-44d1-9ec9-1ca6bb097603.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/ae1dea8c-a953-4845-b616-74a257ba72e6.svg#ae1dea8c-a953-4845-b616-74a257ba72e6") format("svg"); }

@font-face {
  font-family: 'HelveticaNeueW10-75Bold';
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/41fb73ed-90c8-456d-838e-254f4dfda106.eot?#iefix");
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/41fb73ed-90c8-456d-838e-254f4dfda106.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/d85949a1-c37a-43f7-9d09-fb056acf0c27.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/5289fb0d-053f-4fac-9c67-2d02365d6d05.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/1376b116-8954-4534-8045-eabe8e2fcaa4.svg#1376b116-8954-4534-8045-eabe8e2fcaa4") format("svg"); }

@font-face {
  font-family: 'HelveticaNeueW10-95Blac';
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/35f91c73-f2f1-4dd3-83ee-3649dc7ff4ed.eot?#iefix");
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/35f91c73-f2f1-4dd3-83ee-3649dc7ff4ed.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b1d7b778-bdf9-4ff3-b4e8-8cdabf234863.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/27bdcc15-be32-4f6c-a7df-12494376e947.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/3af50121-f334-4e73-b155-b105e0029509.svg#3af50121-f334-4e73-b155-b105e0029509") format("svg"); }

@font-face {
  font-family: 'HelveticaNeueW10-35Thin';
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/93b6bf6a-418e-4a8f-8f79-cb9c99ef3e32.eot?#iefix");
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/93b6bf6a-418e-4a8f-8f79-cb9c99ef3e32.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/c881c21b-4148-4a11-a65d-f35e42999bc8.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/03634cf1-a9c9-4e13-b049-c90d830423d4.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/1bc99c0a-298b-46f9-b325-18b5e5169795.svg#1bc99c0a-298b-46f9-b325-18b5e5169795") format("svg"); }

@font-face {
  font-family: 'HelveticaNeueW10-25UltL';
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/072d8516-5d40-44bc-b694-65b3c8bd8fa5.eot?#iefix");
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/072d8516-5d40-44bc-b694-65b3c8bd8fa5.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/723fd1ed-5aad-454d-af88-3711c5caf1c7.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/2a81d94e-d365-4f5f-9957-7e96414d6a72.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/6be38bc3-96b3-47e3-a9ef-e1a184b54d64.svg#6be38bc3-96b3-47e3-a9ef-e1a184b54d64") format("svg"); }

@font-face {
  font-family: 'HelveticaNeueW10-55Roma';
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/f1feaed7-6bce-400a-a07e-a893ae43a680.eot?#iefix");
  src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/f1feaed7-6bce-400a-a07e-a893ae43a680.eot?#iefix") format("eot"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/8ac9e38d-29c6-41ea-8e47-4ae4d2b1a4e1.woff") format("woff"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/4bd09087-655e-4abb-844c-dccdeb68003d.ttf") format("truetype"), url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/df234d87-eada-4058-aa80-5871e7fbe1c3.svg#df234d87-eada-4058-aa80-5871e7fbe1c3") format("svg"); }

.Y_oNO {
  font-family: 'HelveticaNeueW02-45Ligh', sans-serif, 'meiryo', 'hiragino kaku gothic', 'Ariel'; }

._3LzYo {
  font-family: 'HelveticaNeueW01-UltLt', 'HelveticaNeueW02-UltLt', 'HelveticaNeueW10-25UltL', meiryo, Nanum Gothic, sans-serif; }

._2dId_ {
  font-family: 'HelveticaNeueW01-Thin', 'HelveticaNeueW02-Thin', 'HelveticaNeueW10-35Thin', meiryo, Nanum Gothic, sans-serif; }

._1bLYs {
  font-family: 'HelveticaNeueW01-45Ligh', 'HelveticaNeueW02-45Ligh', 'HelveticaNeueW10-45Ligh', meiryo, Nanum Gothic, sans-serif; }

._2eonO {
  font-family: 'HelveticaNeueW01-55Roma', 'HelveticaNeueW02-55Roma', 'HelveticaNeueW10-55Roma', meiryo, Nanum Gothic, sans-serif; }

.ZM9cb {
  font-family: 'HelveticaNeueW01-65Medi', 'HelveticaNeueW02-65Medi', 'HelveticaNeueW10-65Medi', meiryo, Nanum Gothic, sans-serif; }

.k9IAQ {
  font-family: 'HelveticaNeueW01-75Bold', 'HelveticaNeueW02-75Bold', 'HelveticaNeueW10-75Bold', meiryo, Nanum Gothic, sans-serif; }

._25Q06 {
  font-family: 'HelveticaNeueW01-95Blac', 'HelveticaNeueW02-95Blac', 'HelveticaNeueW10-95Blac', meiryo, Nanum Gothic, sans-serif; }

._133Av {
  position: absolute;
  border: 1px solid transparent; }

._1Xx5d {
  border-radius: 2000px;
  -webkit-transition: background-color .2s, color .2s, border-color .2s;
  transition: background-color .2s, color .2s, border-color .2s;
  cursor: pointer; }

.jc5hL {
  cursor: pointer;
  text-decoration: none; }

/*# sourceMappingURL=dealer-react-viewer.css.map*/
@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.top-bar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1450; }
  .top-bar-wrapper::after {
    content: '';
    height: 48px;
    width: 100%;
    display: block;
    position: absolute;
    z-index: 0;
    top: 0;
    background-color: #FFF;
    transition: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) top; }
  .top-bar-wrapper.is-hidden::before {
    content: '';
    height: 53px;
    width: 100%;
    display: block;
    position: absolute;
    z-index: 1452; }
  .top-bar-wrapper.is-hidden::after {
    z-index: 1451;
    animation: fade-in linear 0.2s;
    top: -42px;
    transition-duration: 0.4s; }
  .top-bar-wrapper.is-hidden.is-hidden-completely::after {
    top: -50px; }
  .top-bar-wrapper > .main-top-bar {
    display: flex;
    justify-content: space-between;
    height: 48px;
    position: relative;
    width: 100%;
    min-width: 600px;
    box-shadow: 0 0 15px 0 rgba(22, 45, 61, 0.5);
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 1450;
    transition: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) top; }
    .is-hidden.top-bar-wrapper > .main-top-bar {
      top: -42px;
      transition-duration: 0.4s; }
      .is-hidden-completely.is-hidden.top-bar-wrapper > .main-top-bar {
        top: -50px; }
    .top-bar-wrapper > .main-top-bar > .top-bar-left-section {
      display: flex;
      height: 100%; }
      .top-bar-wrapper > .main-top-bar > .top-bar-left-section > .top-bar-menu-bar-wrapper {
        padding-left: 18px;
        display: flex; }
      .top-bar-wrapper > .main-top-bar > .top-bar-left-section .top-bar-language-button-icon {
        height: 30px; }
    .top-bar-wrapper > .main-top-bar > .top-bar-right-section {
      display: flex; }
    .top-bar-wrapper > .main-top-bar > .top-bar-center-section {
      display: flex;
      justify-content: center;
      align-items: center; }

.top-bar-tooltip-content-wrapper > :first-child {
  margin-bottom: 6px; }

@keyframes top-bar-pulse {
  0% {
    transform: scale(1);
    opacity: 0.8; }
  20% {
    opacity: 0.5; }
  100% {
    transform: scale(1.25);
    opacity: 0; } }

@keyframes top-bar-slide {
  100% {
    transform: translateX(15px); } }

.action-success-symbol {
  height: 48px; }
  .action-success-symbol > .symbol-mask {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    position: absolute;
    background-color: #ffffff;
    width: 10px;
    height: 8px;
    animation: top-bar-slide 0.85s ease-out forwards; }
    .top-bar-edit-mode-last-btn .action-success-symbol > .symbol-mask {
      background-color: #3899ec; }
    .top-bar-edit-mode-last-btn:hover .action-success-symbol > .symbol-mask {
      background-color: #4eb7f5; }
  .action-success-symbol > .circle {
    width: 30px;
    height: 30px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    box-sizing: border-box;
    margin: auto;
    background-color: transparent;
    border: 2px solid #3899ec;
    border-radius: 50%;
    animation: top-bar-pulse 0.75s linear forwards; }
    .top-bar-edit-mode-last-btn .action-success-symbol > .circle {
      border-color: #ffffff; }

.top-bar-left-wix-logo {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 12px;
  box-sizing: border-box;
  border-right: 1px #e3eaf6 solid; }
  .top-bar-left-wix-logo > svg {
    width: 50px;
    height: 48px; }
    .top-bar-left-wix-logo > svg > path {
      fill: #000 !important; }

.top-bar-pages-navigation {
  position: relative;
  width: 224px; }
  .top-bar-pages-navigation:hover {
    color: #3899ec; }
    .top-bar-pages-navigation:hover svg path {
      fill: #3899ec; }
  .top-bar-pages-navigation .exit-lightbox-mode {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    box-sizing: border-box;
    padding: 0 10px 0 22px;
    background-color: #60bc57; }
    .top-bar-pages-navigation .exit-lightbox-mode span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .top-bar-pages-navigation .exit-lightbox-mode .close {
      flex-shrink: 0;
      background-color: #dff2dd;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      border: none;
      position: relative;
      padding: 0; }
      .top-bar-pages-navigation .exit-lightbox-mode .close svg {
        position: absolute;
        top: 0;
        left: 0; }
        .top-bar-pages-navigation .exit-lightbox-mode .close svg path {
          fill: #60bc57; }
    .top-bar-pages-navigation .exit-lightbox-mode:hover {
      background-color: #80c979; }
      .top-bar-pages-navigation .exit-lightbox-mode:hover .close {
        background-color: #cfebcc; }
        .top-bar-pages-navigation .exit-lightbox-mode:hover .close svg path {
          fill: #80c979; }
  .top-bar-pages-navigation > .pages-navigation-current-page {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background-color: #eaf7ff;
    border-right: 1px #e3eaf6 solid;
    cursor: pointer;
    user-select: none; }
    .top-bar-pages-navigation > .pages-navigation-current-page > .pages-navigation-symbol {
      display: flex;
      padding-left: 9px;
      padding-right: 10px;
      box-sizing: border-box; }
    .top-bar-pages-navigation > .pages-navigation-current-page > .pages-navigation-name-container {
      display: flex;
      width: calc(100% - 100px);
      box-sizing: border-box; }
      .top-bar-pages-navigation > .pages-navigation-current-page > .pages-navigation-name-container > .pages-navigation-name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }
    .top-bar-pages-navigation > .pages-navigation-current-page > .pages-navigation-caret {
      display: flex;
      padding-left: 23px;
      padding-right: 19px;
      box-sizing: border-box; }
      .top-bar-pages-navigation > .pages-navigation-current-page > .pages-navigation-caret > svg {
        height: 48px;
        width: 6px; }
  .top-bar-pages-navigation > .pages-navigation-search {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    border-right: 1px #e3eaf6 solid;
    box-sizing: border-box; }
    .top-bar-pages-navigation > .pages-navigation-search > .pages-navigation-search-symbol {
      display: flex;
      padding-left: 15px;
      padding-right: 6px;
      box-sizing: border-box; }
      .top-bar-pages-navigation > .pages-navigation-search > .pages-navigation-search-symbol svg {
        width: 17px;
        height: 48px; }
        .top-bar-pages-navigation > .pages-navigation-search > .pages-navigation-search-symbol svg path {
          fill: #000; }
    .top-bar-pages-navigation > .pages-navigation-search > .pages-navigation-search-input {
      display: flex;
      align-items: center;
      height: 100%; }
      .top-bar-pages-navigation > .pages-navigation-search > .pages-navigation-search-input > input {
        height: 100%;
        width: 141px;
        margin: 0 10px;
        border: 0;
        box-sizing: border-box;
        outline: none;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        background-color: transparent; }
        .top-bar-pages-navigation > .pages-navigation-search > .pages-navigation-search-input > input::-ms-clear {
          display: none; }
      .top-bar-pages-navigation > .pages-navigation-search > .pages-navigation-search-input > .pages-navigation-search-clear {
        width: 19px;
        height: 19px;
        padding: 0;
        border-radius: 100%;
        background-color: #d3edff; }
        .top-bar-pages-navigation > .pages-navigation-search > .pages-navigation-search-input > .pages-navigation-search-clear path {
          fill: #3899ec; }
        .top-bar-pages-navigation > .pages-navigation-search > .pages-navigation-search-input > .pages-navigation-search-clear:hover {
          background-color: #eaf7ff; }

.info-drop-panel-illustration {
  min-height: 344px;
  width: 204px;
  box-sizing: border-box;
  padding: 0 24px 24px 24px;
  border-radius: 8px;
  background-color: #d3edff;
  word-wrap: break-word;
  cursor: pointer; }
  .info-drop-panel-illustration.disabled-action {
    cursor: default; }
  .info-drop-panel-illustration.illustration-upgrade {
    background-color: #eedbf4; }
  .drop-panel-mobile .info-drop-panel-illustration {
    min-height: 262px;
    width: 246px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: default; }
  .info-drop-panel-illustration > .symbol-container-drop-panel-illustration {
    display: flex;
    justify-content: center;
    align-self: center; }
    .personal-sale-campaign.info-drop-panel-illustration > .symbol-container-drop-panel-illustration {
      position: relative;
      height: 163px; }
    .drop-panel-mobile .info-drop-panel-illustration > .symbol-container-drop-panel-illustration {
      margin: 34px 0 18px 18px; }
    .personal-sale-campaign.info-drop-panel-illustration > .symbol-container-drop-panel-illustration > .img-drop-panel-illustration {
      position: absolute;
      top: 8px; }
    .drop-panel-mobile .info-drop-panel-illustration > .symbol-container-drop-panel-illustration > .img-drop-panel-illustration {
      width: 155px;
      height: 119px; }
  .info-drop-panel-illustration > .title-info-drop-panel-illustration {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 15px;
    color: #2b5672; }
    .drop-panel-mobile .info-drop-panel-illustration > .title-info-drop-panel-illustration {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px; }
  .info-drop-panel-illustration > .description-drop-panel-illustration {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 13px;
    color: #7a92a5;
    margin-top: 12px; }

.drop-panel-list {
  min-width: 180px;
  margin-right: 24px;
  padding-left: 12px; }
  .drop-panel-list.drop-panel-check-list {
    min-width: 203px; }
  .drop-panel-list > .drop-panel-list-separator > .drop-panel-menu-bar-separator {
    margin: 11px 0 10px 12px;
    box-sizing: content-box;
    border: 0;
    height: 1px;
    background-color: #d9e1e8; }
  .drop-panel-list > .drop-panel-list-item {
    display: flex;
    height: 30px;
    cursor: pointer; }
    .drop-panel-list > .drop-panel-list-item:first-child {
      margin-top: 4px; }
    .drop-panel-list > .drop-panel-list-item.disabled {
      cursor: default; }
    .drop-panel-list > .drop-panel-list-item > .title-item-container-drop-panel-list {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      max-width: 100%;
      color: #162d3d;
      padding: 6px 12px;
      border-radius: 20px; }
      .drop-panel-list > .drop-panel-list-item > .title-item-container-drop-panel-list.disabled {
        color: #7a7a7a; }
        .drop-panel-list > .drop-panel-list-item > .title-item-container-drop-panel-list.disabled > .list-item-label {
          cursor: default; }
      .drop-panel-list > .drop-panel-list-item > .title-item-container-drop-panel-list.selected {
        background-color: #eaf7ff; }
        .drop-panel-list > .drop-panel-list-item > .title-item-container-drop-panel-list.selected.list-item-upgrade {
          background-color: #eedbf4; }
      .drop-panel-check-list.drop-panel-list > .drop-panel-list-item > .title-item-container-drop-panel-list {
        pointer-events: none;
        cursor: pointer;
        padding-right: 0; }
        .drop-panel-check-list.drop-panel-list > .drop-panel-list-item > .title-item-container-drop-panel-list.nested {
          margin-left: 30px; }
      .drop-panel-list > .drop-panel-list-item > .title-item-container-drop-panel-list > .list-item-label {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        max-width: 100%;
        cursor: pointer; }
        .drop-panel-list > .drop-panel-list-item > .title-item-container-drop-panel-list > .list-item-label > .drop-panel-list-item-counter {
          font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-size: 12px;
          font-size: 11px;
          display: inline-block;
          position: absolute;
          background-color: #60bc57;
          border-radius: 15px;
          min-width: 12px;
          padding: 0 4px;
          text-align: center;
          border: 1px solid #fff;
          color: #fff;
          line-height: 20px;
          left: calc(100% - 12px);
          top: -6px; }

.drop-panel-mobile-controls {
  position: relative;
  width: 278px; }
  .drop-panel-mobile-controls .composite-radio-buttons-with-illustration {
    padding: 14px 24px; }
    .drop-panel-mobile-controls .composite-radio-buttons-with-illustration .radio-button-item {
      cursor: pointer; }
      .drop-panel-mobile-controls .composite-radio-buttons-with-illustration .radio-button-item:hover .image-and-text .image,
      .drop-panel-mobile-controls .composite-radio-buttons-with-illustration .radio-button-item:hover .image-and-text .content .text {
        color: #3899ec; }
        .drop-panel-mobile-controls .composite-radio-buttons-with-illustration .radio-button-item:hover .image-and-text .image svg > g,
        .drop-panel-mobile-controls .composite-radio-buttons-with-illustration .radio-button-item:hover .image-and-text .content .text svg > g {
          fill: #3899ec; }
      .drop-panel-mobile-controls .composite-radio-buttons-with-illustration .radio-button-item:not(:first-child) {
        padding-top: 0; }
      .drop-panel-mobile-controls .composite-radio-buttons-with-illustration .radio-button-item .radio-button-wrapper .radio-button-symbol {
        margin-top: 0;
        align-self: center;
        height: 14px;
        width: 14px; }
        .drop-panel-mobile-controls .composite-radio-buttons-with-illustration .radio-button-item .radio-button-wrapper .radio-button-symbol .inner-fill {
          height: 10px;
          width: 10px; }
      .drop-panel-mobile-controls .composite-radio-buttons-with-illustration .radio-button-item .radio-button-wrapper .image-and-text {
        align-items: center; }
        .drop-panel-mobile-controls .composite-radio-buttons-with-illustration .radio-button-item .radio-button-wrapper .image-and-text .image {
          margin: 0 10px 0 0; }
        .drop-panel-mobile-controls .composite-radio-buttons-with-illustration .radio-button-item .radio-button-wrapper .image-and-text .content {
          padding-bottom: 0; }
  .drop-panel-mobile-controls > .drop-panel-mobile-controls-bottom {
    position: absolute;
    width: 100%;
    bottom: 0; }
    .drop-panel-mobile-controls > .drop-panel-mobile-controls-bottom .composite-toggle-switch {
      padding: 18px 24px 6px 24px; }
      .drop-panel-mobile-controls > .drop-panel-mobile-controls-bottom .composite-toggle-switch .control-switch > .symbol-switch {
        margin-left: 0; }
        .drop-panel-mobile-controls > .drop-panel-mobile-controls-bottom .composite-toggle-switch .control-switch > .symbol-switch svg {
          width: 40px; }

.drop-panel-top-bar .drop-panel-content {
  display: flex;
  max-width: 100%; }

.upgrade-drop-panel-top-bar .drop-panel-content > .left-section-container {
  display: flex; }
  .upgrade-drop-panel-top-bar .drop-panel-content > .left-section-container > .text-container {
    max-width: 352px; }
    .upgrade-drop-panel-top-bar .drop-panel-content > .left-section-container > .text-container > .control-label {
      margin-left: 23px;
      margin-bottom: 14px; }
    .upgrade-drop-panel-top-bar .drop-panel-content > .left-section-container > .text-container > .control-promotional-list > .promotional-list-item {
      align-items: center; }
      .upgrade-drop-panel-top-bar .drop-panel-content > .left-section-container > .text-container > .control-promotional-list > .promotional-list-item > .control-label .control-label-base {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 14px; }
      .upgrade-drop-panel-top-bar .drop-panel-content > .left-section-container > .text-container > .control-promotional-list > .promotional-list-item > svg {
        width: 11px;
        height: 10px;
        align-self: flex-start;
        margin-right: 13px;
        margin-top: 4px;
        fill: #aa4dc8; }
  .upgrade-drop-panel-top-bar .drop-panel-content > .left-section-container > .symbol-container {
    padding: 16px 12px 0;
    align-self: flex-end; }

.upgrade-drop-panel-top-bar .drop-panel-content > .footer-container {
  padding: 0 24px; }
  .upgrade-drop-panel-top-bar .drop-panel-content > .footer-container .light-purple {
    background: #e5c9ee;
    color: #aa4dc8; }
    .upgrade-drop-panel-top-bar .drop-panel-content > .footer-container .light-purple:hover {
      background: #eedbf4;
      color: #aa4dc8; }
  .upgrade-drop-panel-top-bar .drop-panel-content > .footer-container .dark-purple {
    background: #aa4dc8;
    color: #fff; }
    .upgrade-drop-panel-top-bar .drop-panel-content > .footer-container .dark-purple:hover {
      background: #bb71d3;
      color: #fff; }
  .upgrade-drop-panel-top-bar .drop-panel-content > .footer-container > .regular-footer {
    margin-top: 24px; }
    .upgrade-drop-panel-top-bar .drop-panel-content > .footer-container > .regular-footer > .control-button:first-child {
      margin-right: 18px; }
  .upgrade-drop-panel-top-bar .drop-panel-content > .footer-container > .first-save-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 13px; }
    .upgrade-drop-panel-top-bar .drop-panel-content > .footer-container > .first-save-footer > .comment {
      color: #7a92a5;
      margin-bottom: 12px; }

.languages-panel-container {
  display: flex;
  align-items: stretch;
  cursor: default; }
  .languages-panel-container .drop-panel-left-side {
    display: flex;
    margin: 0;
    padding: 30px 30px 20px 24px; }
  .languages-panel-container .drop-panel-right-side {
    display: none;
    margin: 0; }
  .languages-panel-container .languages-option {
    height: 25px;
    display: flex;
    flex-direction: row;
    align-items: center; }
    .languages-panel-container .languages-option .languages-option-symbol {
      border: none;
      height: 25px;
      order: 3;
      margin-left: auto; }
      .languages-panel-container .languages-option .languages-option-symbol div {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center; }

.text-and-link-drop-panel-top-bar .drop-panel-content {
  flex-direction: column;
  width: 204px; }
  .text-and-link-drop-panel-top-bar .drop-panel-content > .drop-panel-title {
    margin-bottom: 6px; }
  .text-and-link-drop-panel-top-bar .drop-panel-content > .drop-panel-body > .drop-panel-link {
    display: block;
    margin-top: 11px; }

@keyframes progress-indicator {
  0% {
    width: 0; }
  50% {
    width: 100%; }
  100% {
    width: 0; } }

@keyframes top-bar-fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes top-bar-icon-shake {
  0% {
    transform: scale3d(1, 1, 1); }
  30% {
    transform: scale3d(0.75, 1.25, 1); }
  40% {
    transform: scale3d(1.25, 0.75, 1); }
  50% {
    transform: scale3d(0.85, 1.15, 1); }
  65% {
    transform: scale3d(1.05, 0.95, 1); }
  75% {
    transform: scale3d(0.95, 1.05, 1); }
  100% {
    transform: scale3d(1, 1, 1); } }

.top-bar-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  cursor: pointer;
  padding: 0 20px;
  box-sizing: border-box;
  outline: none;
  background-color: #fff;
  border: 0;
  border-left: 1px #eaf7ff solid;
  position: relative;
  white-space: nowrap;
  overflow: hidden; }
  .top-bar-btn.top-bar-symbol-btn {
    padding: 0 10px; }
  .top-bar-btn.top-bar-btn-in-progress-indicator::after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 2px;
    background: #3899ec;
    border-right: 300px solid #ffffff;
    animation: progress-indicator-anim 1.5s infinite ease-out; }
  .top-bar-btn.top-bar-btn-device {
    padding: 0 20px;
    min-width: 66px; }
    .top-bar-btn.top-bar-btn-device.icon-shiver > .btn-symbol {
      animation: top-bar-icon-shake 1000ms infinite linear; }
    .top-bar-btn.top-bar-btn-device.active {
      background-color: #3899ec; }
  .top-bar-btn.top-bar-btn-save {
    min-width: 85px; }
  .top-bar-btn.top-bar-edit-mode-last-btn:not(.top-bar-btn-in-progress-indicator) {
    background-color: #3899ec;
    color: #fff;
    border-left-color: #3899ec; }
    .top-bar-btn.top-bar-edit-mode-last-btn:not(.top-bar-btn-in-progress-indicator):hover {
      background-color: #4eb7f5;
      border-left-color: #4eb7f5; }
  .top-bar-btn.top-bar-edit-mode-last-btn.disabled.top-bar-btn-in-progress-indicator:hover, .top-bar-btn.top-bar-edit-mode-last-btn.disabled:not(.top-bar-btn-done-successfully) {
    background-color: #fff;
    border-left-color: #eaf7ff; }
  .top-bar-btn.top-bar-btn-back-to-editor {
    padding: 0;
    color: #fff;
    background-color: #60bc57;
    border-left-color: #60bc57; }
    .top-bar-btn.top-bar-btn-back-to-editor:hover:not(.disabled) {
      background-color: #80c979;
      border-left-color: #80c979; }
  .top-bar-btn.top-bar-btn-stores-migration {
    color: #fc975c; }
    .top-bar-btn.top-bar-btn-stores-migration:hover {
      color: #fb7d33; }
  .top-bar-btn.top-bar-fake-layout > .fake-layout-item {
    display: inline-block;
    height: 0;
    overflow: hidden; }
    .top-bar-btn.top-bar-fake-layout > .fake-layout-item.fake-layout-type-text {
      padding-left: 20px;
      padding-right: calc(20px + 1px); }
    .top-bar-btn.top-bar-fake-layout > .fake-layout-item:last-of-type {
      padding-right: 20px; }
  .top-bar-btn.top-bar-fake-layout > .label {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .top-bar-btn.hidden {
    display: none; }
  .top-bar-btn.disabled {
    cursor: default;
    background-color: #fff;
    color: #bcbcbc; }
  .top-bar-btn:hover:not(.disabled):not(.top-bar-btn-done-successfully):not(.active):not(.top-bar-edit-mode-last-btn) > .btn-symbol g, .top-bar-btn:hover:not(.disabled):not(.top-bar-btn-done-successfully):not(.active):not(.top-bar-edit-mode-last-btn) > .btn-symbol path {
    fill: #3899ec; }
  .top-bar-edit-mode-last-btn.top-bar-btn > .btn-symbol g, .top-bar-edit-mode-last-btn.top-bar-btn > .btn-symbol path {
    fill: #ffffff; }
  .active.top-bar-btn-device.top-bar-btn > .btn-symbol g, .active.top-bar-btn-device.top-bar-btn > .btn-symbol path {
    fill: #ffffff; }
  .disabled.top-bar-btn:not(.top-bar-btn-done-successfully) > .btn-symbol g, .disabled.top-bar-btn:not(.top-bar-btn-done-successfully) > .btn-symbol path {
    fill: #bcbcbc; }
  .top-bar-btn > .label {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    margin: 0 auto;
    display: block; }
    .top-bar-btn-done-successfully.top-bar-btn > .label {
      visibility: hidden;
      height: 0; }
    .active.top-bar-btn-device.top-bar-btn > .label {
      color: #ffffff; }
    .top-bar-btn-device.top-bar-btn > .label {
      display: inline-flex;
      align-items: center; }
      .active.top-bar-btn-device.top-bar-btn > .label, .top-bar-btn-device.top-bar-btn:hover > .label {
        color: #ffffff; }
    .top-bar-btn-idle-saved.top-bar-btn > .label {
      animation: top-bar-fade 0.3s ease-out; }
    .top-bar-btn-idle-saved.top-bar-btn:not(.top-bar-edit-mode-last-btn) > .label {
      color: #7a92a5; }
    .disabled.top-bar-btn > .label {
      color: #bcbcbc; }
    .top-bar-btn:hover:not(.top-bar-edit-mode-last-btn):not(.top-bar-btn-back-to-editor):not(.top-bar-btn-stores-migration):not(.disabled):not(.active) > .label {
      color: #3899ec; }

@keyframes border-blink {
  0% {
    border: 2px dashed #7e7eee;
    border-top: none; }
  50% {
    border: 2px dashed rgba(126, 126, 238, 0);
    border-top: none; }
  100% {
    border: 2px dashed #7e7eee;
    border-top: none; } }

@keyframes background-blink {
  0% {
    background: #0099EF; }
  50% {
    background: #006AA9; }
  100% {
    background: #0099EF; } }

.hide-tools-button {
  z-index: 1500;
  position: absolute;
  top: -1px;
  left: calc(50% - 57px);
  width: 115px;
  height: 12px;
  border: 1px solid #d9e1e8;
  background-color: #eaf7ff;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  text-align: center;
  cursor: pointer;
  transition: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) height; }
  .hide-tools-button.tools-hidden {
    transition-duration: 0.4s;
    height: 18px; }
    .hide-tools-button.tools-hidden .hide-tools-symbol {
      transition-duration: 0.4s;
      margin-top: 7px; }
  .hide-tools-button.blinking-border {
    animation: border-blink 1.2s linear infinite; }
  .hide-tools-button.stand-out {
    background: #3899ec;
    animation: background-blink 1.4s linear infinite; }
    .hide-tools-button.stand-out:hover {
      background: #4eb7f5; }
    .hide-tools-button.stand-out .hide-tools-symbol {
      fill: #fff; }
  .hide-tools-button:not(.tools-hidden) .hide-tools-symbol {
    transform: rotateX(180deg); }
  .hide-tools-button:not(.stand-out):hover .hide-tools-symbol {
    fill: #3899ec; }
  .hide-tools-button .hide-tools-symbol {
    transition: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) margin-top;
    color: #162d3c;
    width: 7px;
    height: 4px;
    vertical-align: top;
    margin-top: 3px;
    margin-left: -2px; }

.top-bar-center-notification {
  height: 48px;
  line-height: 48px;
  white-space: nowrap; }
  .top-bar-center-notification a {
    color: #3899ec; }
    .top-bar-center-notification a:hover {
      cursor: pointer;
      text-decoration: underline; }

.top-bar-menu-bar-item {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  position: relative;
  cursor: pointer;
  float: left;
  height: 48px;
  line-height: 48px;
  padding: 0 18px;
  text-align: center;
  transition: color 0.1s ease;
  white-space: nowrap; }
  .top-bar-menu-bar-item.active {
    color: #4eb7f5; }
    .top-bar-menu-bar-item.active.menu-bar-upgrade-item {
      color: #9a4cc6; }
  .top-bar-menu-bar-item .top-bar-menu-bar-item-counter {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 12px;
    font-size: 11px;
    display: inline-block;
    position: absolute;
    background-color: #60bc57;
    border-radius: 15px;
    min-width: 12px;
    padding: 0 4px;
    text-align: center;
    border: 1px solid #fff;
    color: #fff;
    line-height: 20px;
    left: calc(100% - 18px);
    top: 4px; }

#mediaGalleryFrame {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 6001; }

.panel-frame-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.screen-centered-frame {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.transparent-frame-overlay,
.dark-frame-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1; }

.dark-frame-overlay {
  background-color: rgba(0, 0, 0, 0.5); }

.center-top-position {
  top: 0;
  left: 50%;
  transform: translateX(-50%); }

.panel-drag-handle {
  cursor: move; }

.content-iframe {
  width: 100%;
  height: 100%;
  border: 0;
  box-sizing: border-box; }

.message-panel-frame-z-index {
  z-index: 1800; }

.message-panel-frame {
  box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.36);
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  min-width: 500px;
  min-height: 200px;
  max-width: 700px;
  background-color: #fff;
  transform: translateZ(0);
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  .message-panel-frame > .panel-header {
    background-color: #3899ec; }
    .important.message-panel-frame > .panel-header {
      background-color: #ee5951; }
    .message-panel-frame > .panel-header > .close {
      background-color: #2b81cb; }
      .important.message-panel-frame > .panel-header > .close {
        background-color: #C3504E; }
  .message-panel-frame > .panel-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 30px 30px; }
    .message-panel-frame > .panel-footer .footer-text {
      margin-right: 30px; }
    .message-panel-frame > .panel-footer .dont-show-again {
      position: absolute;
      bottom: 35px;
      left: 35px; }
    .message-panel-frame > .panel-footer > .btn-confirm-primary {
      flex: none;
      float: right;
      background-color: #3899ec; }
      .important.message-panel-frame > .panel-footer > .btn-confirm-primary {
        background-color: #ee5951; }
      .message-panel-frame > .panel-footer > .btn-confirm-primary:hover {
        background-color: #4eb7f5; }
        .important.message-panel-frame > .panel-footer > .btn-confirm-primary:hover {
          background-color: #f1726b; }
    .message-panel-frame > .panel-footer > .btn-confirm-secondary {
      margin-right: 15px;
      background-color: #d3edff;
      color: #3899ec; }
      .important.message-panel-frame > .panel-footer > .btn-confirm-secondary {
        background-color: #fce6e5;
        color: #ee5951; }
    .message-panel-frame > .panel-footer > .content {
      display: inline-block;
      vertical-align: top;
      overflow-y: auto;
      background-color: #fff; }

.confirmation-panel-frame-z-index {
  z-index: 1719; }

.confirmation-panel-frame {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 16px;
  box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.36);
  border-radius: 10px;
  background-color: #fff;
  min-width: 500px;
  min-height: 200px;
  max-width: 800px;
  max-height: 650px; }
  .confirmation-panel-frame > .panel-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; }
  .confirmation-panel-frame > .content {
    overflow-y: auto;
    padding: 25px; }
  .confirmation-panel-frame > footer {
    position: relative;
    bottom: 30px;
    text-align: right; }
    .confirmation-panel-frame > footer .control-button.btn-confirm-secondary, .confirmation-panel-frame > footer .control-button.btn-danger-secondary {
      margin-right: 15px; }
    .confirmation-panel-frame > footer .control-button.btn-danger-primary {
      margin-right: 30px; }
  .confirmation-panel-frame.important-confirmation {
    cursor: default;
    width: 564px; }
    .confirmation-panel-frame.important-confirmation > .panel-header {
      background-color: #ee5951;
      height: 60px; }
      .confirmation-panel-frame.important-confirmation > .panel-header > .panel-header-title {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 20px;
        font-weight: 300;
        height: 60px;
        line-height: 60px; }
      .confirmation-panel-frame.important-confirmation > .panel-header > .panel-header.close {
        background-color: rgba(22, 45, 61, 0.2);
        color: #facdca; }
      .confirmation-panel-frame.important-confirmation > .panel-header .close {
        background-color: #c3504d; }
        .confirmation-panel-frame.important-confirmation > .panel-header .close:hover {
          background-color: #942b28; }
    .confirmation-panel-frame.important-confirmation .content {
      margin-right: 15px;
      line-height: 22px; }
      .confirmation-panel-frame.important-confirmation .content > p {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 18px;
        font-weight: 300; }
      .confirmation-panel-frame.important-confirmation .content > p:first-child {
        margin-top: 0; }
      .confirmation-panel-frame.important-confirmation .content a {
        color: #3899ec;
        text-decoration: none; }
    .confirmation-panel-frame.important-confirmation .icon {
      height: 180px;
      width: 150px;
      float: left;
      margin-right: 10px; }
  .confirmation-panel-frame.concurrent-save-panel {
    width: 576px;
    background-color: #fff; }
    .confirmation-panel-frame.concurrent-save-panel .panel-header {
      height: 54px; }
      .confirmation-panel-frame.concurrent-save-panel .panel-header .panel-header-title {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 20px;
        font-weight: 300;
        line-height: 54px; }
      .confirmation-panel-frame.concurrent-save-panel .panel-header .close {
        top: 14px;
        margin-top: 0; }
    .confirmation-panel-frame.concurrent-save-panel .content {
      padding: 0; }
    .confirmation-panel-frame.concurrent-save-panel > footer {
      position: static;
      text-align: right;
      margin: 0 30px 30px 0; }
      .confirmation-panel-frame.concurrent-save-panel > footer .btn-confirm-primary,
      .confirmation-panel-frame.concurrent-save-panel > footer .btn-confirm-secondary {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 16px; }

.notification-panel-frame {
  box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.36);
  position: fixed;
  border-radius: 10px;
  background-color: #fff;
  font-size: 16px;
  min-width: 500px;
  min-height: 200px;
  max-width: 700px;
  max-height: 500px;
  top: 48px !important;
  z-index: 1710; }
  .notification-panel-frame > .content {
    overflow-y: auto; }

.left-panel-frame {
  box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.36);
  position: absolute;
  top: 32px;
  left: 70px;
  max-height: calc(100vh - 112px);
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  z-index: 1105;
  padding-left: 6px; }
  .left-panel-frame.panel-index-0,
  .left-panel-frame.panel-index-0 .left-panel-frame-content {
    min-height: 270px; }
  .left-panel-frame.panel-index-1,
  .left-panel-frame.panel-index-1 .left-panel-frame-content {
    min-height: 270px; }
  .left-panel-frame.panel-index-2,
  .left-panel-frame.panel-index-2 .left-panel-frame-content {
    min-height: 270px; }
  .left-panel-frame.panel-index-3,
  .left-panel-frame.panel-index-3 .left-panel-frame-content {
    min-height: 270px; }
  .left-panel-frame.panel-index-4,
  .left-panel-frame.panel-index-4 .left-panel-frame-content {
    min-height: 330px; }
  .left-panel-frame.panel-index-5,
  .left-panel-frame.panel-index-5 .left-panel-frame-content {
    min-height: 390px; }
  .left-panel-frame.panel-index-6,
  .left-panel-frame.panel-index-6 .left-panel-frame-content {
    min-height: 450px; }
  .left-panel-frame.panel-index-7,
  .left-panel-frame.panel-index-7 .left-panel-frame-content {
    min-height: 510px; }
  .left-panel-frame.panel-index-8,
  .left-panel-frame.panel-index-8 .left-panel-frame-content {
    min-height: 570px; }
  .left-panel-frame.panel-index-9,
  .left-panel-frame.panel-index-9 .left-panel-frame-content {
    min-height: 630px; }
  .left-panel-frame.panel-index-10,
  .left-panel-frame.panel-index-10 .left-panel-frame-content {
    min-height: 690px; }
  .left-panel-frame.panel-index-11,
  .left-panel-frame.panel-index-11 .left-panel-frame-content {
    min-height: 750px; }
  .left-panel-frame.panel-index-12,
  .left-panel-frame.panel-index-12 .left-panel-frame-content {
    min-height: 810px; }
  .left-panel-frame.panel-index-13,
  .left-panel-frame.panel-index-13 .left-panel-frame-content {
    min-height: 870px; }
  .left-panel-frame.panel-index-14,
  .left-panel-frame.panel-index-14 .left-panel-frame-content {
    min-height: 930px; }
  .left-panel-frame.panel-index-15,
  .left-panel-frame.panel-index-15 .left-panel-frame-content {
    min-height: 990px; }
  .left-panel-frame.panel-index-16,
  .left-panel-frame.panel-index-16 .left-panel-frame-content {
    min-height: 1050px; }
  .left-panel-frame.panel-index-17,
  .left-panel-frame.panel-index-17 .left-panel-frame-content {
    min-height: 1110px; }
  .left-panel-frame.panel-index-18,
  .left-panel-frame.panel-index-18 .left-panel-frame-content {
    min-height: 1170px; }
  .left-panel-frame.panel-index-19,
  .left-panel-frame.panel-index-19 .left-panel-frame-content {
    min-height: 1230px; }
  .left-panel-frame.panel-index-20,
  .left-panel-frame.panel-index-20 .left-panel-frame-content {
    min-height: 1290px; }
  .left-panel-frame .panel-header {
    border-bottom: 1px solid #e3e3e3; }
  .left-panel-frame.first-panel, .left-panel-frame.first-panel:after {
    border-top-left-radius: 0; }
  .left-panel-frame header.white-panel-header {
    background-color: #fff; }
  .left-panel-frame:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 6px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px; }
  .left-panel-frame.pages-panel-pp:after {
    background: #00aaaf; }
  .left-panel-frame.bg-panel:after {
    background: #f8a239; }
  .left-panel-frame.add-panel:after {
    background: #3797ea; }
  .left-panel-frame.app-market-panel:after {
    background: #d0427c; }
  .left-panel-frame.app-market-panel-20:after {
    background: #d0427c; }
  .left-panel-frame.my-uploads-panel:after {
    background: #fa7c33; }
  .left-panel-frame.blog-panel:after {
    background: #60bc57; }
  .left-panel-frame.new-blog-panel:after {
    background: #faad4d; }
  .left-panel-frame.shoutout-panel:after {
    background: #ffc233; }
  .left-panel-frame.wix-store-panel:after {
    background: #00adf5; }
  .left-panel-frame.wix-bookings-panel:after {
    background: #733ca6; }
  .left-panel-frame.hidden-items-panel:after {
    background: #d0427d; }
  .left-panel-frame.mobile-elements-panel:after {
    background: #3899eb; }
  .left-panel-frame.layout-optimizer-panel:after {
    background: #45c8c1; }
  .left-panel-frame.add-panel:after {
    background-image: linear-gradient(360deg, rgba(6, 49, 85, 0.2), rgba(56, 153, 236, 0)); }
  .left-panel-frame.add-panel > .left-panel-frame-content {
    color: #000;
    height: calc(100vh - 112px);
    white-space: nowrap; }

.help-panel-frame {
  box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.36);
  position: fixed;
  top: calc(50vh - (550px / 2));
  left: calc(50vw - (500px / 2));
  z-index: 1720;
  font-size: 0;
  border-radius: 8px;
  overflow: hidden; }
  .help-panel-frame .panel-header {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
    .help-panel-frame .panel-header .close {
      position: static;
      margin: auto 13px auto 0;
      top: auto; }
  .help-panel-frame iframe {
    width: 500px;
    height: 550px;
    background-color: #fff;
    border: 0; }
  .help-panel-frame .help-panel-frame-drag-handle {
    position: absolute;
    left: 48px;
    width: calc(100% - 50px - 2px);
    background-color: transparent; }
  .help-panel-frame svg.symbol-preloader {
    position: absolute;
    top: calc(50% - (30px / 2));
    left: calc(50% - (30px / 2)); }
  .help-panel-frame .hidden {
    display: none; }

.focus-panel-frame-z-index {
  z-index: 1710; }
  .focus-panel-frame-z-index.save-publish-panel-frame {
    z-index: 1730; }
  .focus-panel-frame-z-index.how-to-video-gallery-frame {
    min-height: calc(45vw + 2 * 60px); }

.focus-panel-frame {
  box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.36);
  margin-top: 48px;
  border-radius: 8px;
  overflow: hidden;
  transform: translateZ(0); }
  .focus-panel-frame.popup-medium {
    width: 504px; }
  .focus-panel-frame .focus-panel-frame-content {
    height: calc(100% - 54px);
    overflow: hidden;
    background-color: #fff; }
  .focus-panel-frame .focus-panel-frame-content-no-header {
    height: 100%;
    overflow: hidden;
    background-color: #fff; }
  .focus-panel-frame.aviary-panel {
    width: 850px;
    height: 600px; }
  .focus-panel-frame.blog-manager-panel {
    width: 100%;
    height: 100%; }
  .focus-panel-frame.shoutout-templates-panel {
    width: 95%;
    height: 95%;
    margin-top: 0; }
  .focus-panel-frame.example-focus-panel, .focus-panel-frame.itunes-link-maker {
    width: 66%;
    height: 66%; }
  .focus-panel-frame.organize-images-panel {
    width: 80%;
    height: 70%;
    min-width: 861px;
    min-height: 465px; }
  .focus-panel-frame.spotify-search-panel {
    width: 735px;
    height: 590px; }
  .focus-panel-frame.video-search-panel {
    width: 790px;
    height: 490px; }
  .focus-panel-frame.list-dbe-frame {
    height: 90%;
    position: relative; }
    @media screen and (max-width: 1200px) {
      .focus-panel-frame.list-dbe-frame {
        width: 900px; } }
    @media screen and (min-width: 1200px) {
      .focus-panel-frame.list-dbe-frame {
        width: 1170px; } }

.panel-frame-container.tool-panel-frame-container {
  height: calc(100% - 48px);
  top: 48px; }
  .panel-frame-container.tool-panel-frame-container.tool-panel-frame-z-index {
    z-index: 1710; }
  .panel-frame-container.tool-panel-frame-container .tool-panel-frame {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 16px;
    box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.36);
    position: fixed;
    border-radius: 10px;
    background-color: #fff; }
    .panel-frame-container.tool-panel-frame-container .tool-panel-frame > .panel-header {
      border-radius: 10px 10px 0 0; }
  .panel-frame-container.tool-panel-frame-container .transparent-frame-overlay {
    height: calc(100% - 48px);
    top: 48px; }
  .panel-frame-container.tool-panel-frame-container.full-screen {
    height: 100%;
    top: 0; }
    .panel-frame-container.tool-panel-frame-container.full-screen .transparent-frame-overlay {
      height: 100%;
      top: 0; }

.pages-tree-item {
  position: relative;
  background-color: #fff;
  border-radius: 7px;
  border: 1px solid #cde;
  box-sizing: border-box;
  color: #2b5672;
  height: 39px;
  line-height: 39px;
  padding-left: 12px; }
  .pages-tree-item:before {
    display: none;
    content: '';
    position: absolute;
    width: 11px;
    height: 100%;
    bottom: 50%;
    left: -12px;
    border: 1px solid #cde;
    border-top: 0;
    border-right: 0;
    padding: 4px 0 0; }
  .pages-tree-item .symbol-dragIcon {
    display: none;
    margin-right: 10px; }
  .pages-tree .pages-tree-item {
    cursor: move; }
    .pages-tree .pages-tree-item.disable-pages-drag {
      cursor: pointer; }
    .pages-tree .pages-tree-item .symbol-dragIcon {
      display: inline; }
  .pages-tree-item.hidden-page > .wrapper-view-mode > .page-title {
    color: #7a92a5; }
  .pages-tree-item.hidden-page.selected > .wrapper-view-mode > .page-title {
    color: #7a92a5; }
  .pages-tree-item.has-notification .pages-quick-actions .action-button {
    box-shadow: 0 1px 1px 0 #7a92a5;
    visibility: visible; }
  .pages-tree-item:hover {
    background-color: #eaf7ff; }
    .pages-tree-item:hover .pages-quick-actions .action-button {
      box-shadow: 0 1px 1px 0 #7a92a5;
      visibility: visible; }
    .pages-tree-item:hover .symbol-dragIcon path {
      fill: #7fccf7; }
  .pages-tree-item.selected {
    background-color: #d3edff; }
    .pages-tree-item.selected .pages-quick-actions .action-button {
      box-shadow: 0 1px 1px 0 #7a92a5;
      visibility: visible; }
      .pages-panel-settings-opened .pages-tree-item.selected .pages-quick-actions .action-button.action-settings {
        box-shadow: inset 0 1px 1px 0 #7a92a5;
        background-color: #7fccf7; }
        .pages-panel-settings-opened .pages-tree-item.selected .pages-quick-actions .action-button.action-settings .symbol-quickActionsSettings path {
          stroke: none;
          fill: #2b5672; }
    .pages-tree-item.selected .symbol-dragIcon path {
      fill: #2b5672; }
    .pages-tree-item.selected > .wrapper-view-mode > .page-title svg path {
      fill: #7a92a5; }
  .pages-tree-item > .handle {
    position: absolute;
    left: 12px;
    font-size: 8px;
    color: #3899ec; }
  .pages-tree-item.depth-0.icon-cnt-0 .page-title-name {
    max-width: 200px; }
  .pages-tree-item.depth-0.icon-cnt-0.selected .page-title-name, .pages-tree-item.depth-0.icon-cnt-0:hover .page-title-name {
    max-width: 200px; }
  .pages-tree-item.depth-0.icon-cnt-0.selected.qa-2 .page-title-name, .pages-tree-item.depth-0.icon-cnt-0:hover.qa-2 .page-title-name {
    max-width: 170px; }
  .pages-tree-item.depth-0.icon-cnt-1 .page-title-name {
    max-width: 206px; }
  .pages-tree-item.depth-0.icon-cnt-1.selected .page-title-name, .pages-tree-item.depth-0.icon-cnt-1:hover .page-title-name {
    max-width: 178px; }
  .pages-tree-item.depth-0.icon-cnt-1.selected.qa-2 .page-title-name, .pages-tree-item.depth-0.icon-cnt-1:hover.qa-2 .page-title-name {
    max-width: 150px; }
  .pages-tree-item.depth-0.icon-cnt-2 .page-title-name {
    max-width: 186px; }
  .pages-tree-item.depth-0.icon-cnt-2.selected .page-title-name, .pages-tree-item.depth-0.icon-cnt-2:hover .page-title-name {
    max-width: 160px; }
  .pages-tree-item.depth-0.icon-cnt-2.selected.qa-2 .page-title-name, .pages-tree-item.depth-0.icon-cnt-2:hover.qa-2 .page-title-name {
    max-width: 130px; }
  .pages-tree-item.depth-1.icon-cnt-0 .page-title-name {
    max-width: 173px; }
  .pages-tree-item.depth-1.icon-cnt-0.selected .page-title-name, .pages-tree-item.depth-1.icon-cnt-0:hover .page-title-name {
    max-width: 173px; }
  .pages-tree-item.depth-1.icon-cnt-0.selected.qa-2 .page-title-name, .pages-tree-item.depth-1.icon-cnt-0:hover.qa-2 .page-title-name {
    max-width: 144px; }
  .pages-tree-item.depth-1.icon-cnt-1 .page-title-name {
    max-width: 182px; }
  .pages-tree-item.depth-1.icon-cnt-1.selected .page-title-name, .pages-tree-item.depth-1.icon-cnt-1:hover .page-title-name {
    max-width: 154px; }
  .pages-tree-item.depth-1.icon-cnt-1.selected.qa-2 .page-title-name, .pages-tree-item.depth-1.icon-cnt-1:hover.qa-2 .page-title-name {
    max-width: 124px; }
  .pages-tree-item.depth-1.icon-cnt-2 .page-title-name {
    max-width: 160px; }
  .pages-tree-item.depth-1.icon-cnt-2.selected .page-title-name, .pages-tree-item.depth-1.icon-cnt-2:hover .page-title-name {
    max-width: 134px; }
  .pages-tree-item.depth-1.icon-cnt-2.selected.qa-2 .page-title-name, .pages-tree-item.depth-1.icon-cnt-2:hover.qa-2 .page-title-name {
    max-width: 102px; }
  .pages-tree-item > .wrapper-view-mode > .page-title {
    color: #2b5672;
    margin-right: 0.5em;
    position: absolute;
    top: 0;
    height: 100%; }
    .pages-tree-item > .wrapper-view-mode > .page-title > .page-title-name {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .pages-tree-item > .wrapper-view-mode > .page-title svg {
      vertical-align: top;
      margin-left: 6px;
      margin-top: 12px; }
      .pages-tree-item > .wrapper-view-mode > .page-title svg path {
        fill: #7a92a5; }
      .pages-tree-item > .wrapper-view-mode > .page-title svg.symbol-pageItemHomeIcon {
        margin-top: 13px; }
      .pages-tree-item > .wrapper-view-mode > .page-title svg.symbol-pageItemDropdownIcon {
        margin-top: 15px; }
  .pages-tree-item.edit-mode {
    padding-left: 6px;
    padding-right: 7px; }
  .pages-tree-item > .control-text-input-with-fixed-button {
    height: 100%; }
  .pages-tree-item .pages-quick-actions {
    position: absolute;
    right: 6px;
    top: 7px;
    text-align: center;
    width: 120px;
    margin-right: -5px; }
  .pages-tree-item .action-button {
    box-shadow: 0 1px 1px 0 #7a92a5;
    position: relative;
    background-color: #fff;
    visibility: hidden;
    float: right;
    text-align: center;
    text-decoration: none;
    border-radius: 100%;
    display: inline-block;
    height: 24px;
    line-height: 24px;
    width: 24px;
    margin-right: 5px; }
    .pages-tree-item .action-button svg {
      margin-top: 20%; }
      .pages-tree-item .action-button svg path {
        fill: #4eb7f5; }
    .pages-tree-item .action-button .symbol-quickActionsSettings {
      margin-top: 3px; }
      .pages-tree-item .action-button .symbol-quickActionsSettings path {
        stroke: #4eb7f5;
        fill: none; }
    .pages-tree-item .action-button .symbol-actionButtonInfo {
      margin-top: 0; }
    .pages-tree-item .action-button > .info-icon {
      height: 100%;
      width: 100%; }
    .pages-tree-item .action-button.dropdown.context-menu {
      border: 0; }
      .pages-tree-item .action-button.dropdown.context-menu .symbol-moreRegular {
        position: relative;
        left: 6px;
        top: -7px; }
        .pages-tree-item .action-button.dropdown.context-menu .symbol-moreRegular .cls-b1 {
          fill: #3899ec; }
      .pages-tree-item .action-button.dropdown.context-menu > div.dd > div.selected-container {
        height: 17px;
        min-height: 17px; }
      .pages-tree-item .action-button.dropdown.context-menu > div.dd > div.options > ol.list > li.option {
        text-align: left;
        font-size: 14px;
        font-weight: 300;
        line-height: 32px;
        height: 32px;
        padding-left: 43px; }
        .pages-tree-item .action-button.dropdown.context-menu > div.dd > div.options > ol.list > li.option.primary {
          font-weight: 500; }
        .pages-tree-item .action-button.dropdown.context-menu > div.dd > div.options > ol.list > li.option svg {
          position: absolute;
          top: 23%;
          left: 18px; }
          .pages-tree-item .action-button.dropdown.context-menu > div.dd > div.options > ol.list > li.option svg path {
            fill: #2d4150; }
    .pages-tree-item .action-button:hover {
      box-shadow: 0 1px 1px 0 #7a92a5;
      background-color: #7fccf7; }
      .pages-tree-item .action-button:hover .symbol-actionButtonSpecialPages path,
      .pages-tree-item .action-button:hover .symbol-actionButtonBlogPages path {
        fill: #fff; }
      .pages-tree-item .action-button:hover .symbol-quickActionsSettings path {
        stroke: #2b5672; }
    .pages-tree-item .action-button.expanded.action-button.dropdown.context-menu, .pages-tree-item .action-button.action-button:active {
      box-shadow: inset 0 1px 1px 0 #7a92a5;
      background-color: #7fccf7; }

.pages-tree-shrinked .pages-tree-item {
  height: 23px;
  line-height: 23px; }
  .pages-tree-shrinked .pages-tree-item:before {
    padding: 5px 0 0; }
  .pages-tree-shrinked .pages-tree-item .pages-quick-actions {
    top: 2px; }
    .pages-tree-shrinked .pages-tree-item .pages-quick-actions .action-button {
      height: 19px;
      line-height: 19px;
      width: 19px; }
      .pages-tree-shrinked .pages-tree-item .pages-quick-actions .action-button svg {
        margin-top: 0; }
      .pages-tree-shrinked .pages-tree-item .pages-quick-actions .action-button .symbol-actionButtonSpecialPages {
        margin-top: 10%; }
      .pages-tree-shrinked .pages-tree-item .pages-quick-actions .action-button.dropdown.context-menu .symbol-moreRegular {
        left: 4px;
        top: -9px; }
  .pages-tree-shrinked .pages-tree-item > .wrapper-view-mode > .page-title svg {
    margin-top: 5px; }
    .pages-tree-shrinked .pages-tree-item > .wrapper-view-mode > .page-title svg.symbol-pageItemDropdownIcon {
      margin-top: 9px; }
    .pages-tree-shrinked .pages-tree-item > .wrapper-view-mode > .page-title svg.symbol-pageItemHomeIcon {
      margin-top: 6px; }

.page-settings-panel {
  border-radius: 0 8px 8px 0;
  width: 480px;
  height: 100%;
  left: 293px;
  top: 0;
  position: absolute;
  background-color: #fff;
  z-index: 1254;
  transition-property: width;
  transition-duration: 0.4s;
  transition-timing-function: ease-in;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.35); }
  .page-settings-panel > .page-settings-panel-inner {
    display: flex;
    flex-direction: column;
    min-width: 480px;
    height: 100%;
    width: 100%;
    overflow: hidden; }
    .page-settings-panel > .page-settings-panel-inner .panel-header {
      padding-left: 49px;
      border-radius: 0 8px 0 0; }
      .page-settings-panel > .page-settings-panel-inner .panel-header > .btn-close .symbol-arrowLeft path {
        fill: #fff; }
    .page-settings-panel > .page-settings-panel-inner .page-settings-tabs {
      height: 100%; }
    .page-settings-panel > .page-settings-panel-inner > div {
      display: flex;
      flex-direction: column;
      height: calc(100% - 118px); }
  .page-settings-panel .page-settings-panel-content {
    height: calc(100% - 47px);
    color: #395671;
    padding: 5px 0;
    font-size: 14px;
    font-weight: 300;
    box-sizing: border-box; }
    .page-settings-panel .page-settings-panel-content,
    .page-settings-panel .page-settings-panel-content .tab,
    .page-settings-panel .page-settings-panel-content .custom-scroll,
    .page-settings-panel .page-settings-panel-content .outer-container,
    .page-settings-panel .page-settings-panel-content .inner-container {
      display: flex;
      flex-direction: column; }
    .page-settings-panel .page-settings-panel-content .tab,
    .page-settings-panel .page-settings-panel-content .custom-scroll {
      height: 100%; }
    .page-settings-panel .page-settings-panel-content .tab-inner {
      padding: 0 15px 10px 30px; }
      .page-settings-panel .page-settings-panel-content .tab-inner.empty {
        padding-top: 18px;
        padding-left: 38px; }
    .page-settings-panel .page-settings-panel-content :not(.bubble-content) > section {
      border-bottom: 1px solid #d9e1e8;
      padding: 20px 25px 15px 5px; }
      .page-settings-panel .page-settings-panel-content :not(.bubble-content) > section > *:not(.control-text-input):not(.info-icon) {
        box-sizing: border-box;
        padding-left: 12px; }
    .page-settings-panel .page-settings-panel-content .wixapps-subpage section {
      border-bottom: 0;
      padding: 0 25px 0 5px;
      margin-bottom: 34px; }
    .page-settings-panel .page-settings-panel-content .wixapps-subpage p:not(.title) {
      margin: 0 0 13px; }
    .page-settings-panel .page-settings-panel-content .wixapps-subpage .title {
      color: #2b5672;
      font-size: 18px;
      margin-top: 20px;
      margin-bottom: 8px; }
    .page-settings-panel .page-settings-panel-content .control-text-input {
      width: 100%; }
      .page-settings-panel .page-settings-panel-content .control-text-input > label {
        color: #2b5672; }
      .page-settings-panel .page-settings-panel-content .control-text-input > input {
        width: 100%;
        color: #1d2d3c; }
        .page-settings-panel .page-settings-panel-content .control-text-input > input::placeholder {
          color: #b6c1cd; }
    .page-settings-panel .page-settings-panel-content .control-toggle {
      width: 100%;
      display: inline-block; }
      .page-settings-panel .page-settings-panel-content .control-toggle > .text-label {
        line-height: 26px;
        float: left;
        width: auto; }
      .page-settings-panel .page-settings-panel-content .control-toggle > .icons-container {
        float: right; }
      .page-settings-panel .page-settings-panel-content .control-toggle .toggle-with-icon {
        width: 100%; }
    .page-settings-panel .page-settings-panel-content .control-button.btn-text {
      line-height: 16px;
      height: 16px;
      font-size: 14px;
      padding: 0;
      min-width: 0; }
  .page-settings-panel .button-control {
    border-radius: 50%;
    border: 0;
    width: 25px;
    height: 25px;
    background-color: rgba(13, 72, 127, 0.3);
    color: #fff;
    box-sizing: border-box;
    line-height: 25px;
    min-width: 0;
    padding: 0; }
    .page-settings-panel .button-control:hover {
      background-color: rgba(13, 72, 127, 0.6); }
    .page-settings-panel .button-control svg {
      margin: 0; }
  .page-settings-panel .symbol-settingsHome {
    margin-right: 10px; }
  .page-settings-panel .page-settings-tabs > .tab-header {
    margin-left: 8px;
    width: 472px;
    min-height: 47px; }
    .page-settings-panel .page-settings-tabs > .tab-header > .tab-label {
      color: rgba(43, 86, 114, 0.9);
      font-size: 14px;
      width: 119px;
      border: 1px solid #d9e1e8;
      border-top: 0;
      box-sizing: border-box;
      position: relative;
      margin-left: -1px;
      background-color: #eaf7ff;
      height: 47px;
      line-height: 47px;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      padding: 0 10px; }
      .page-settings-panel .page-settings-tabs > .tab-header > .tab-label:last-child {
        border-right: 0; }
      .page-settings-panel .page-settings-tabs > .tab-header > .tab-label:hover {
        background-color: #d3edff;
        border-bottom: 1px solid #d3edff; }
      .page-settings-panel .page-settings-tabs > .tab-header > .tab-label.selected {
        border-bottom: 1px solid #fff;
        background-color: #fff;
        color: #2b5672; }
  .page-settings-panel .page-settings-tabs > .tab-content .tabs {
    border-bottom: 0; }
    .page-settings-panel .page-settings-tabs > .tab-content .tabs .tab-header > .tab-label {
      border-bottom: 1px solid #d9e1e8;
      padding-bottom: 15px; }
      .page-settings-panel .page-settings-tabs > .tab-content .tabs .tab-header > .tab-label:last-child {
        border-bottom: 0; }
      .page-settings-panel .page-settings-tabs > .tab-content .tabs .tab-header > .tab-label:before {
        top: 0; }
      .page-settings-panel .page-settings-tabs > .tab-content .tabs .tab-header > .tab-label:after {
        top: 2px; }
  .page-settings-panel .page-settings-tabs > .tab-content .action-done {
    border-radius: 50%;
    border: 0;
    width: 24px;
    height: 24px;
    background-color: #60bc57;
    color: #fff;
    line-height: 24px;
    min-width: 0;
    padding: 0;
    display: inline-block;
    text-align: center; }
  .page-settings-panel .page-settings-tabs > .tab-content .copy-url {
    margin-top: 5px; }
    .page-settings-panel .page-settings-tabs > .tab-content .copy-url .btn-text {
      margin-right: 5px;
      height: 24px;
      line-height: 24px; }
  .page-settings-panel .page-settings-tabs > .tab-content .page-info .section-page-title {
    padding-top: 19px;
    padding-bottom: 14px; }
  .page-settings-panel .page-settings-tabs > .tab-content .page-info .section-hide-page {
    padding-top: 23px; }
  .page-settings-panel .page-settings-tabs > .tab-content .page-info > .page-settings-control-description {
    padding-top: 26px;
    padding-bottom: 25px; }
    .page-settings-panel .page-settings-tabs > .tab-content .page-info > .page-settings-control-description > .control-button {
      height: 24px;
      font-size: 14px;
      float: right;
      margin-top: 0; }
      .page-settings-panel .page-settings-tabs > .tab-content .page-info > .page-settings-control-description > .control-button:not(.btn-text) {
        min-width: 90px; }
      .page-settings-panel .page-settings-tabs > .tab-content .page-info > .page-settings-control-description > .control-button svg {
        position: relative;
        margin-left: -3px;
        margin-right: 5px;
        margin-bottom: -1px; }
        .page-settings-panel .page-settings-tabs > .tab-content .page-info > .page-settings-control-description > .control-button svg path {
          fill: #fff; }
  .page-settings-panel .page-settings-tabs > .tab-content .layout > section {
    padding: 7px 25px 0 5px; }
  .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label {
    overflow: hidden;
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 40px; }
    .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label > div {
      float: right;
      width: 227px; }
      .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label > div h3 {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 18px;
        margin-top: 0; }
      .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label > div p {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 13px;
        margin-bottom: 0; }
    .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label:before {
      top: 26px; }
    .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label:after {
      top: 28px; }
    .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label svg {
      margin-left: 20px;
      float: left; }
    .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label .symbol-layoutStandardSelected,
    .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label .symbol-layoutLandingSelected {
      display: none; }
    .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label .symbol-layoutStandardNotSelected,
    .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label .symbol-layoutLandingNotSelected {
      display: block; }
    .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label.selected .symbol-layoutStandardSelected,
    .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label.selected .symbol-layoutLandingSelected {
      display: block; }
    .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label.selected .symbol-layoutStandardNotSelected,
    .page-settings-panel .page-settings-tabs > .tab-content .layout .tab-header > .tab-label.selected .symbol-layoutLandingNotSelected {
      display: none; }
  .page-settings-panel .page-settings-tabs > .tab-content .privacy > section {
    padding-top: 5px; }
    .page-settings-panel .page-settings-tabs > .tab-content .privacy > section .tab-header {
      width: 100%; }
      .page-settings-panel .page-settings-tabs > .tab-content .privacy > section .tab-header .tab-label {
        padding-bottom: 13px;
        margin-bottom: 13px;
        color: #2b5672; }
        .page-settings-panel .page-settings-tabs > .tab-content .privacy > section .tab-header .tab-label > h4 {
          font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-size: 14px;
          margin-bottom: 8px; }
        .page-settings-panel .page-settings-tabs > .tab-content .privacy > section .tab-header .tab-label > span {
          font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-size: 13px; }
  .page-settings-panel .page-settings-tabs > .tab-content .privacy .password-settings-content {
    cursor: default; }
  .page-settings-panel .page-settings-tabs > .tab-content .privacy hr {
    border: none;
    border-top: 1px solid #f0f3f5;
    margin: 0; }
    .page-settings-panel .page-settings-tabs > .tab-content .privacy hr.separator-permissions-password {
      margin-top: 15px; }
    .page-settings-panel .page-settings-tabs > .tab-content .privacy hr.separator-permissions-password-language {
      margin-bottom: 13px; }
  .page-settings-panel .page-settings-tabs > .tab-content .privacy .composite-text-input-labeled {
    margin: 0 -12px 0 -24px; }
    .page-settings-panel .page-settings-tabs > .tab-content .privacy .composite-text-input-labeled .input-container {
      margin-top: 6px;
      margin-bottom: -6px; }
      .page-settings-panel .page-settings-tabs > .tab-content .privacy .composite-text-input-labeled .input-container input::placeholder {
        color: #b6c1cd; }
      .page-settings-panel .page-settings-tabs > .tab-content .privacy .composite-text-input-labeled .input-container:hover input:not(:focus)::placeholder {
        color: #162d3d; }
  .page-settings-panel .page-settings-tabs > .tab-content .privacy .control-permissions-password-language > label,
  .page-settings-panel .page-settings-tabs > .tab-content .privacy .control-permissions-member-language > label {
    margin-bottom: 0; }
  .page-settings-panel .page-settings-tabs > .tab-content .privacy .control-permissions-password-language .selected-container,
  .page-settings-panel .page-settings-tabs > .tab-content .privacy .control-permissions-member-language .selected-container {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #162d3d; }
  .page-settings-panel .page-settings-tabs > .tab-content .privacy .site-members-info {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 13px;
    color: #7a92a5;
    margin-top: 0; }
  .page-settings-panel .page-settings-tabs > .tab-content .privacy .separator-site-members-info {
    margin-top: 15px;
    margin-bottom: 17px; }
  .page-settings-panel .page-settings-tabs > .tab-content .privacy .separator-permissions-member-language {
    margin-top: 10px;
    margin-bottom: 20px; }
  .page-settings-panel .page-settings-tabs > .tab-content .seo.tab {
    padding-bottom: 10px; }
    .page-settings-panel .page-settings-tabs > .tab-content .seo.tab .seo-section-title,
    .page-settings-panel .page-settings-tabs > .tab-content .seo.tab .seo-section-description,
    .page-settings-panel .page-settings-tabs > .tab-content .seo.tab .seo-section-keywords {
      padding-bottom: 10px; }
    .page-settings-panel .page-settings-tabs > .tab-content .seo.tab .seo-section-description,
    .page-settings-panel .page-settings-tabs > .tab-content .seo.tab .seo-section-keywords {
      padding-top: 10px; }
    .page-settings-panel .page-settings-tabs > .tab-content .seo.tab .seo-section-seo-wiz-inner-div {
      margin-left: -12px;
      margin-bottom: -22px; }
    .page-settings-panel .page-settings-tabs > .tab-content .seo.tab .seo-section-seo-wiz {
      padding-top: 0; }
    .page-settings-panel .page-settings-tabs > .tab-content .seo.tab .seo-section-page-address-old {
      position: relative;
      padding-bottom: 20px; }
      .page-settings-panel .page-settings-tabs > .tab-content .seo.tab .seo-section-page-address-old .control-text-input {
        margin-bottom: 7px; }
        .page-settings-panel .page-settings-tabs > .tab-content .seo.tab .seo-section-page-address-old .control-text-input input {
          margin-left: 130px;
          width: calc(100% - 130px); }
      .page-settings-panel .page-settings-tabs > .tab-content .seo.tab .seo-section-page-address-old .page-address-prefix {
        position: absolute;
        top: 52px;
        left: 5px;
        line-height: 35px;
        font-size: 18px;
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        color: #b6c1cd; }
    .page-settings-panel .page-settings-tabs > .tab-content .seo.tab .tab-inner {
      margin-bottom: 50px; }
      .page-settings-panel .page-settings-tabs > .tab-content .seo.tab .tab-inner.google-preview-expanded {
        margin-bottom: 190px; }
  .page-settings-panel .page-settings-tabs > .tab-content .wixapps-subpage .seo-google > .google-preview {
    position: static;
    border-radius: 0;
    border: none;
    width: calc(100% - 10px);
    height: auto;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.14);
    box-sizing: border-box;
    padding: 17px 11px; }
    .page-settings-panel .page-settings-tabs > .tab-content .wixapps-subpage .seo-google > .google-preview > p {
      padding: 0; }
    .page-settings-panel .page-settings-tabs > .tab-content .wixapps-subpage .seo-google > .google-preview > .google-preview-title {
      margin: 0 0 5px; }
    .page-settings-panel .page-settings-tabs > .tab-content .wixapps-subpage .seo-google > .google-preview > .google-preview-url {
      margin-bottom: 2px; }
  .page-settings-panel .google-preview {
    border-radius: 15px 15px 0 0;
    transition: bottom 0.4s;
    position: absolute;
    bottom: -140px;
    height: 150px;
    width: calc(100% - 7px);
    left: 6px;
    border: 1px solid #d9e1e8;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff; }
    .page-settings-panel .google-preview .google-preview-corner-container {
      position: absolute;
      width: 15px;
      height: 15px;
      bottom: -5px;
      overflow: hidden; }
      .page-settings-panel .google-preview .google-preview-corner-container .google-preview-corner {
        width: 31px;
        height: 31px;
        position: relative;
        border: solid 8px #fff;
        top: -8px;
        left: -8px;
        box-sizing: border-box; }
      .page-settings-panel .google-preview .google-preview-corner-container.left {
        left: -15px; }
        .page-settings-panel .google-preview .google-preview-corner-container.left .google-preview-corner {
          border-radius: 0 0 15px; }
      .page-settings-panel .google-preview .google-preview-corner-container.right {
        right: -15px; }
        .page-settings-panel .google-preview .google-preview-corner-container.right .google-preview-corner {
          border-radius: 0 0 0 15px; }
    .page-settings-panel .google-preview.expanded {
      bottom: -1px; }
      .page-settings-panel .google-preview.expanded .google-preview-header > .symbol-equals {
        display: none; }
      .page-settings-panel .google-preview.expanded .google-preview-header > .symbol-arrowDown {
        display: block; }
    .page-settings-panel .google-preview > p {
      text-align: left;
      margin: 0 40px;
      word-wrap: break-word; }
    .page-settings-panel .google-preview > .google-preview-header {
      border-radius: 15px 15px 0 0;
      position: relative;
      cursor: pointer;
      height: 32px;
      padding-top: 5px;
      text-align: center;
      border: 1px solid #d9e1e8;
      box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
      width: 200px;
      background-color: #fff;
      border-bottom: 0;
      margin: 0 auto;
      top: -43px;
      color: #3899ec; }
      .page-settings-panel .google-preview > .google-preview-header > svg {
        fill: #3899ec; }
      .page-settings-panel .google-preview > .google-preview-header > .symbol-equals {
        margin: 6px auto 5px;
        display: block; }
      .page-settings-panel .google-preview > .google-preview-header > .symbol-arrowDown {
        margin: 2px auto 1px;
        display: none; }
      .page-settings-panel .google-preview > .google-preview-header > .google-preview-header-title {
        margin-top: 5px; }
      .page-settings-panel .google-preview > .google-preview-header > .google-preview-bottom-header-border {
        background-color: #fff;
        width: 100%;
        position: absolute;
        height: 8px;
        bottom: -8px; }
    .page-settings-panel .google-preview > .google-preview-title {
      font-family: Arial, sans-serif;
      font-size: 18px;
      font-weight: 400;
      color: #1a0dab;
      line-height: 21px;
      margin-top: -10px; }
      .page-settings-panel .google-preview > .google-preview-title > .google-preview-title-site-name {
        font-weight: 700; }
    .page-settings-panel .google-preview > .google-preview-url {
      font-family: Arial, sans-serif;
      font-size: 14px;
      color: #006621;
      margin-top: 4px;
      margin-bottom: 4px;
      user-select: text; }
    .page-settings-panel .google-preview > .google-preview-description {
      font-family: Arial, sans-serif;
      font-size: 13px;
      color: #545454; }
  .page-settings-panel footer {
    height: 64px;
    width: 100%;
    border-top: 1px solid #d9e1e8;
    flex-shrink: 0;
    z-index: 1; }
    .page-settings-panel footer .page-settings-controls {
      float: left;
      display: inline-block;
      line-height: 54px;
      margin-top: 10px;
      padding-left: 24px; }
      .page-settings-panel footer .page-settings-controls svg {
        margin-right: 5px; }
      .page-settings-panel footer .page-settings-controls > span {
        color: #395671;
        cursor: pointer;
        display: inline-block;
        line-height: 16px;
        margin-left: 24px; }
        .page-settings-panel footer .page-settings-controls > span > span {
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 110px; }
        .page-settings-panel footer .page-settings-controls > span:hover > span {
          color: #3899ec;
          text-decoration: underline; }
    .page-settings-panel footer .control-button {
      float: right;
      margin: 15px 20px;
      font-size: 16px;
      min-width: 92px;
      max-width: 115px; }
  .page-settings-panel .page-setting-mobile .page-settings-mobile-common-page .label {
    color: #2b5672;
    font-size: 14px;
    line-height: 24px;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  .page-settings-panel .page-setting-mobile .page-settings-mobile-common-page section:first-child {
    border-top: 1px solid #d9e1e8;
    margin-top: 19px;
    padding-top: 24px;
    padding-bottom: 24px; }
  .page-settings-panel .page-setting-mobile .page-settings-mobile-common-page .toggle-with-icon {
    position: relative; }
    .page-settings-panel .page-setting-mobile .page-settings-mobile-common-page .toggle-with-icon .mobileHidden {
      position: absolute;
      top: 3px; }
    .page-settings-panel .page-setting-mobile .page-settings-mobile-common-page .toggle-with-icon .mobileVisible {
      position: absolute;
      top: 5px;
      left: 3px; }
    .page-settings-panel .page-setting-mobile .page-settings-mobile-common-page .toggle-with-icon .label {
      margin-left: 25px;
      overflow: inherit; }
  .page-settings-panel .page-setting-mobile .page-settings-mobile-common-page .hidden-from-desktop {
    padding-top: 23px;
    padding-bottom: 23px; }
    .page-settings-panel .page-setting-mobile .page-settings-mobile-common-page .hidden-from-desktop > span {
      line-height: 24px; }
    .page-settings-panel .page-setting-mobile .page-settings-mobile-common-page .hidden-from-desktop > .control-button {
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      float: right;
      margin-top: 0; }
      .page-settings-panel .page-setting-mobile .page-settings-mobile-common-page .hidden-from-desktop > .control-button:not(.btn-text) {
        min-width: 90px; }
      .page-settings-panel .page-setting-mobile .page-settings-mobile-common-page .hidden-from-desktop > .control-button svg {
        position: relative;
        margin-left: -3px;
        margin-right: 5px;
        margin-bottom: -1px; }
        .page-settings-panel .page-setting-mobile .page-settings-mobile-common-page .hidden-from-desktop > .control-button svg path {
          fill: #fff; }
  .page-settings-panel .page-setting-mobile .symbol-desktopHidden {
    margin-right: 10px; }
  .page-settings-panel .page-setting-mobile .symbol-desktopVisible {
    margin-right: 10px; }

.seo-section-page-address {
  padding-left: 17px;
  top: 15px;
  position: relative; }
  .seo-section-page-address .seo-section-title-label {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    font-weight: 300;
    color: #2b5672;
    text-align: left;
    display: block;
    overflow: hidden;
    line-height: 18px;
    max-height: 34px; }
  .seo-section-page-address .seo-section-site-url {
    margin-bottom: 7px; }
    .seo-section-page-address .seo-section-site-url > .control-text-input-seo {
      margin-left: 7px;
      width: inherit; }
      .seo-section-page-address .seo-section-site-url > .control-text-input-seo input {
        width: calc(100% - 130px); }
  .seo-section-page-address .page-address-prefix {
    position: relative;
    top: 1px;
    left: 0;
    line-height: 35px;
    font-size: 18px;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #b6c1cd; }
  .seo-section-page-address.blog-seo-setting {
    padding-left: 11px; }

.page-settings-change-homepage-tooltip {
  float: right; }

.tab-seo .translated-by {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  color: #162d3d;
  float: right;
  font-size: 12px;
  transform: translateY(-0.5em);
  font-weight: 300; }

.tab-seo .symbol-google-logo {
  width: 42px;
  height: 13px;
  transform: translateY(0.3em); }

.tab-seo .seo-section-description textarea.input :not(.focused) {
  height: 100px; }

.page-settings-permissions-tab .site-members-settings {
  display: flex;
  align-items: flex-start;
  margin-top: 30px; }
  .page-settings-permissions-tab .site-members-settings .link {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    flex: 1;
    color: #3899ec;
    margin: 0 18px 0 12px; }

.pages-panel-frame {
  position: fixed;
  top: 80px;
  left: 70px;
  box-sizing: border-box;
  min-width: 250px; }
  .pages-panel-frame .panel-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px; }
    .pages-panel-frame .panel-header.tag-triangle.tag-top:before {
      left: 50%;
      margin-left: -10px; }
    .pages-panel-frame .panel-header .panel-header-title {
      padding-left: 0; }

.background-sub-panel-frame {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 16px;
  position: fixed;
  box-shadow: 0 0 18px 0 rgba(22, 45, 61, 0.27);
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
  z-index: 1200; }

.popup-template-panel {
  width: 498px; }
  .popup-template-panel .panel-header {
    height: auto;
    position: relative;
    background-color: #3899ec;
    padding: 42px 48px 0;
    color: #fff;
    text-align: center; }
    .popup-template-panel .panel-header button.close {
      position: absolute;
      top: 24px;
      right: 18px;
      z-index: 1; }
    .popup-template-panel .panel-header h1 {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 30px;
      margin-top: 0; }
    .popup-template-panel .panel-header h2 {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 18px;
      line-height: 26px;
      margin: 0; }
    .popup-template-panel .panel-header .image {
      position: relative;
      top: 35px; }
  .popup-template-panel .content {
    padding: 48px 48px;
    color: #1d2d3c;
    background-color: #fff; }
    .popup-template-panel .content .panel-text {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 27px; }
      .popup-template-panel .content .panel-text .learn-more-link {
        color: #3197ef;
        cursor: pointer; }
    .popup-template-panel .content .bottom-controls {
      padding-bottom: 18px; }
      .popup-template-panel .content .bottom-controls .dont-show-again {
        float: left;
        position: relative;
        top: 7px; }
      .popup-template-panel .content .bottom-controls .btn-ok {
        float: right; }
  .popup-template-panel.out-of-grid-panel.mobile .image {
    height: 125px; }
    .popup-template-panel.out-of-grid-panel.mobile .image svg {
      width: 78px; }
  .popup-template-panel.out-of-grid-panel.desktop h1 {
    font-size: 24px;
    line-height: 30px;
    margin: 0; }
  .popup-template-panel.out-of-grid-panel.desktop .image {
    height: 140px; }

.image.container-out-of-grid {
  height: 95px; }
  .image.container-out-of-grid::after {
    position: relative;
    height: 140px;
    width: 262px;
    margin: 0 auto;
    content: '';
    display: block;
    top: -36px;
    background: url("//static.parastorage.com/services/santa-resources/resources/editor/panels/focusPanels/columnsOutOfGrid.v2.gif") center -13px / 262px no-repeat; }
    @media (min-device-pixel-ratio: 2) {
      .image.container-out-of-grid::after {
        background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/panels/focusPanels/columnsOutOfGrid.retina.gif"); } }

.out-of-grid-desktop-panel .panel-header {
  padding-top: 39px; }

.out-of-grid-desktop-panel .image {
  top: 26px; }

.out-of-grid-desktop-panel h1 {
  font-size: 27px;
  margin-bottom: 11px; }

.out-of-grid-desktop-panel h2 {
  font-size: 17px; }

.aviary-dialog {
  position: relative;
  height: 100%;
  width: 100%;
  padding-bottom: 11px; }
  .aviary-dialog .tmp-image {
    display: none; }
  .aviary-dialog #aviary_dialog_editor_content_wrapper {
    position: relative;
    height: 100%;
    width: 100%; }
  .aviary-dialog #message {
    font-size: 16px;
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    width: 100%;
    top: 63%;
    color: #eee; }
  .aviary-dialog .message-bg {
    display: inline-block;
    padding: 6px 16px;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.5); }
  .aviary-dialog #cancel-button {
    cursor: pointer;
    font-size: 12px;
    color: #b99;
    text-decoration: underline; }
  .aviary-dialog #progress:after,
  .aviary-dialog #cancel-button:before {
    content: '\a';
    white-space: pre; }
  .aviary-dialog .hidden {
    display: none; }

.avpw_is_embed #avpw_controls {
  z-index: 0; }

body .avpw {
  background: #fdfdfd;
  border: none; }
  body .avpw .avpw_tool_icon_image {
    background-color: #f4f4f4;
    border-radius: 4px; }
  body .avpw .avpw_tool_icon:hover .avpw_tool_icon_image {
    background-color: #eeeeee;
    border: 1px solid #999999;
    box-sizing: border-box; }
  body .avpw .avpw_button {
    background-color: #f4f4f4;
    border-radius: 4px; }
    body .avpw .avpw_button:hover {
      background-color: #eeeeee; }
  body .avpw .avpw_primary_button, body .avpw .avpw_primary_button:link, body .avpw .avpw_primary_button:visited, body .avpw .avpw_primary_button:active {
    background-color: #2aa7ea;
    border: 1px solid #5b8fa7;
    border-radius: 4px;
    color: #ffffff; }
  body .avpw .avpw_primary_button:hover, body .avpw .avpw_primary_button:focus {
    background-color: #179ce1;
    border: 1px solid #237aa7; }
  body .avpw .avpw_current_tool_icon {
    background: #fdfdfd;
    box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 0.1), inset 0 -3px 5px 0 rgba(0, 0, 0, 0.1); }

.feedback-iframe {
  width: 720px;
  height: 487px;
  border: none; }

.how-to-video-gallery-panel {
  width: 80vw;
  height: 45vw;
  min-height: 452.25px;
  min-width: 804px;
  margin: 0; }
  .how-to-video-gallery-panel .panel-content {
    display: flex;
    flex-direction: column;
    height: 100%; }
    .how-to-video-gallery-panel .panel-content > .gallery-iframe {
      width: calc(100% - 3px);
      flex: 1;
      border: none; }

.member-area-added-panel .composite-panel-content {
  padding: 0 18px; }

.member-area-added-panel .illustration {
  height: 138px;
  width: 394px; }

.attach-to-sosp-panel .composite-panel-content {
  padding: 0 24px; }

.attach-to-sosp-panel .illustration {
  height: 138px;
  width: 394px; }

.detached-from-sosp-panel .composite-panel-content {
  padding: 0 24px; }

.detached-from-sosp-panel .illustration {
  height: 138px;
  width: 394px; }

.welcome-screen-panel.getTrafficPanel .content {
  margin-top: 70px; }

.welcome-screen-panel.getTrafficPanel .text-after-link {
  padding-right: 22px; }

.welcome-screen-panel.getTrafficPanel .get-found-link {
  text-decoration: none;
  color: #3899ec; }

.blog-manager-panel {
  transform: none !important; }
  .blog-manager-panel .dark-frame-overlay,
  .blog-manager-panel .focus-panel-frame-content-no-header {
    background: transparent; }
  .blog-manager-panel .focus-panel-frame {
    background: transparent;
    border-radius: 0;
    box-shadow: none;
    margin-top: 0; }

.organize-images-panel {
  position: relative; }
  .organize-images-panel #organizeImages {
    visibility: visible;
    opacity: 1; }
  .organize-images-panel svg.symbol-preloader {
    opacity: 1;
    visibility: visible; }
    .organize-images-panel svg.symbol-preloader.hidden {
      transition-delay: 0.5s; }
  .organize-images-panel .hidden {
    opacity: 0 !important;
    visibility: hidden !important; }
  .organize-images-panel .transition {
    transition-property: opacity, visibility;
    transition-duration: 0.5s; }

.promo-popup-panel {
  position: relative;
  width: 504px; }
  .promo-popup-panel .panel-header {
    background: #3899ec;
    height: 210px;
    text-align: center; }
    .promo-popup-panel .panel-header .close {
      position: absolute;
      margin-top: 0;
      top: 18px;
      right: 18px; }
    .promo-popup-panel .panel-header .title-line-1 {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 30px;
      color: #fff;
      padding-top: 57px; }
    .promo-popup-panel .panel-header .title-line-2 {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 18px;
      color: #fff;
      padding-top: 12px; }
    .promo-popup-panel .panel-header .image-wrapper {
      position: relative;
      display: inline-block;
      vertical-align: top;
      margin-top: 42px; }
      .promo-popup-panel .panel-header .image-wrapper .overlay-box-1,
      .promo-popup-panel .panel-header .image-wrapper .overlay-box-2,
      .promo-popup-panel .panel-header .image-wrapper .overlay-box-3 {
        display: none; }
    .promo-popup-panel .panel-header a[class~="image-wrapper"] {
      cursor: pointer; }
  .promo-popup-panel .content {
    background: #fff;
    text-align: center;
    padding: 0 48px; }
    .promo-popup-panel .content p:first-child {
      margin-top: 85px;
      line-height: 24px; }
    .promo-popup-panel .content p {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px;
      color: #162d3d;
      margin-bottom: 0;
      text-align: start; }
    .promo-popup-panel .content p:not(:first-child) {
      margin-top: 19px; }
    .promo-popup-panel .content button {
      margin-top: 33px;
      margin-bottom: 48px;
      height: 30px; }
    .promo-popup-panel .content a {
      color: #3899ec;
      cursor: pointer; }
      .promo-popup-panel .content a:hover {
        text-decoration: underline;
        cursor: pointer; }
  @media only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
    .promo-popup-panel.music-promo-popup .panel-header .image-wrapper img {
      width: 189px; } }
  .promo-popup-panel.music-promo-popup .panel-header .image-wrapper:hover .overlay-box-1,
  .promo-popup-panel.music-promo-popup .panel-header .image-wrapper:hover .overlay-box-2,
  .promo-popup-panel.music-promo-popup .panel-header .image-wrapper:hover .overlay-box-3 {
    content: "";
    position: absolute;
    display: block;
    border-radius: 3px; }
  .promo-popup-panel.music-promo-popup .panel-header .image-wrapper:hover .overlay-box-1 {
    background: rgba(56, 153, 236, 0.45);
    top: 0;
    left: 22px;
    width: 145px;
    height: 21px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .promo-popup-panel.music-promo-popup .panel-header .image-wrapper:hover .overlay-box-2 {
    background: rgba(56, 153, 236, 0.45);
    top: 0;
    left: 167px;
    width: 22px;
    height: 95px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .promo-popup-panel.music-promo-popup .panel-header .image-wrapper:hover .overlay-box-3 {
    background: rgba(56, 153, 236, 0.25);
    top: 21px;
    left: 0;
    width: 167px;
    height: 95px; }
  .promo-popup-panel.photo-promo-popup .panel-header {
    height: 252px; }
    .promo-popup-panel.photo-promo-popup .panel-header .title-line-1 {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 18px;
      padding-top: 42px; }
    .promo-popup-panel.photo-promo-popup .panel-header .title-line-2 {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 30px;
      padding-top: 9px; }
    .promo-popup-panel.photo-promo-popup .panel-header .image-wrapper {
      margin-top: 27px; }
      @media only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
        .promo-popup-panel.photo-promo-popup .panel-header .image-wrapper img {
          width: 270px; } }
  .promo-popup-panel.photo-promo-popup .content p:first-child {
    margin-top: 71px; }

.welcome-screen-panel {
  width: 742px;
  text-align: center; }
  .welcome-screen-panel .panel-header {
    height: 281px;
    text-align: center; }
    .welcome-screen-panel .panel-header > .close {
      top: 20px;
      right: 17px;
      margin-top: 0; }
    .welcome-screen-panel .panel-header > .videoInput-wrapper > iframe {
      width: 559px;
      height: 314px; }
    .welcome-screen-panel .panel-header > .videoInput-wrapper > .video-pixel-cover {
      display: inline-block;
      width: 559px;
      height: 1px;
      background-color: #ffffff;
      position: relative;
      top: -16px; }
  .welcome-screen-panel .welcome-panel-header-title {
    font-size: 30px;
    color: #ffffff;
    padding-top: 40px;
    padding-bottom: 11px; }
  .welcome-screen-panel .welcome-panel-header-subtitle {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.8);
    padding-bottom: 25px; }
  .welcome-screen-panel .content {
    display: inline-block;
    text-align: left;
    font-size: 16px;
    color: #162d3d;
    line-height: 20px;
    margin-top: 50px; }
    .welcome-screen-panel .content.only-new {
      padding-left: 90px;
      padding-right: 145px; }
    .welcome-screen-panel .content.switch-to-old {
      padding-left: 90px;
      padding-right: 110px; }
    .welcome-screen-panel .content.dev-mode-welcome {
      padding-left: 90px;
      padding-right: 110px;
      margin-top: 0; }
      .welcome-screen-panel .content.dev-mode-welcome a {
        margin-left: 6px;
        color: #3899ec; }
    .welcome-screen-panel .content p {
      margin: 0; }
      .welcome-screen-panel .content p:first-child {
        padding-top: 134px; }
        .welcome-screen-panel .content p:first-child a {
          color: #3899ec;
          cursor: pointer; }
  .welcome-screen-panel .buttons {
    padding: 30px 0; }
    .welcome-screen-panel .buttons .control-button {
      margin: 0 12px; }
  .welcome-screen-panel.wixstores-migration-video-popup .panel-header > .videoInput-wrapper {
    border: 3px solid #ffffff;
    border-radius: 8px;
    display: inline-block;
    height: 313px;
    overflow: hidden;
    position: relative;
    width: 558px;
    z-index: 0; }
  .welcome-screen-panel.wixstores-migration-video-popup .buttons {
    padding-top: 0; }

.how-to-video-player {
  z-index: 1740;
  min-height: calc(45vw + 2 * 60px); }
  .how-to-video-player > .content {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .how-to-video-player > .content > .dark-overlay {
      height: 100%;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5); }
    .how-to-video-player > .content > .close-overlay {
      height: 100%;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0; }
    .how-to-video-player > .content > .container-iframe {
      height: 45vw;
      width: 80vw;
      min-height: 452.25px;
      min-width: 804px;
      position: relative; }
      .how-to-video-player > .content > .container-iframe.with-shadow {
        box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.36); }
      .how-to-video-player > .content > .container-iframe > .close {
        cursor: pointer;
        position: absolute;
        right: -60px;
        top: 0; }
        .how-to-video-player > .content > .container-iframe > .close:hover > svg > path {
          fill: #4eb7f5; }
      .how-to-video-player > .content > .container-iframe > iframe {
        height: 100%;
        width: 100%;
        border: none; }

.editor-compatibility-panel {
  width: 744px;
  border-radius: 8px;
  box-shadow: #000 5px 5px 35px; }
  .editor-compatibility-panel .header {
    padding: 40px 170px 0 170px;
    height: auto; }
    .editor-compatibility-panel .header .subtitle {
      white-space: normal;
      width: 410px;
      position: relative; }
    .editor-compatibility-panel .header .header-content {
      padding-bottom: 82px; }
  .editor-compatibility-panel .coffee-img {
    position: absolute;
    top: 164px;
    left: 306px; }
  .editor-compatibility-panel .content {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 16px;
    padding: 85px 80px 0;
    text-align: left;
    color: #2b5672;
    word-wrap: break-word;
    line-height: 24px;
    height: 160px; }
    .editor-compatibility-panel .content a {
      color: #3899ec;
      margin-left: 6px;
      cursor: pointer;
      text-decoration: none; }
    .editor-compatibility-panel .content .line2 {
      position: relative;
      top: 20px; }
    .editor-compatibility-panel .content .button {
      margin: 0 auto;
      display: block;
      position: relative;
      top: 22px; }

.new-editor-list-welcome-screen-panel {
  width: 742px;
  text-align: center; }
  .new-editor-list-welcome-screen-panel .panel-header {
    height: 196px;
    text-align: center;
    margin-bottom: 95px; }
    .new-editor-list-welcome-screen-panel .panel-header .welcome-panel-header-title {
      font-size: 26px;
      color: #ffffff;
      padding-top: 40px;
      padding-bottom: 33px; }
  .new-editor-list-welcome-screen-panel .content {
    display: inline-block;
    text-align: left;
    font-size: 16px;
    color: #162d3d;
    line-height: 20px; }
    .new-editor-list-welcome-screen-panel .content.only-new {
      padding-left: 90px;
      padding-right: 145px; }
    .new-editor-list-welcome-screen-panel .content.switch-to-old {
      padding-left: 90px;
      padding-right: 110px; }
    .new-editor-list-welcome-screen-panel .content p a {
      color: #3899ec;
      cursor: pointer;
      text-decoration: none; }
  .new-editor-list-welcome-screen-panel .buttons {
    padding: 30px 0; }
    .new-editor-list-welcome-screen-panel .buttons .control-button {
      margin: 0 12px; }

.site-image-optimisation-panel {
  width: 414px; }
  .site-image-optimisation-panel .rich-text-paragraph {
    padding: 18px 0; }
  .site-image-optimisation-panel .image-optimisation-thumbnailsWithIcons {
    padding: 0 24px 24px 24px; }
    .site-image-optimisation-panel .image-optimisation-thumbnailsWithIcons .thumbnails-with-icons label {
      margin-right: 12px; }
    .site-image-optimisation-panel .image-optimisation-thumbnailsWithIcons .thumbnails-with-icons label:nth-of-type(3n) {
      margin-right: 0; }
    .site-image-optimisation-panel .image-optimisation-thumbnailsWithIcons .thumbnails-with-icons label:nth-of-type(-n+3) {
      margin-bottom: 12px; }
  .site-image-optimisation-panel .action-buttons {
    padding: 24px;
    height: 35px; }
    .site-image-optimisation-panel .action-buttons .control-button2.image-optimisation-cancel {
      float: left;
      background-color: #eaf7ff; }
    .site-image-optimisation-panel .action-buttons .control-button2.image-optimisation-cancel .label {
      color: #3899ec; }
    .site-image-optimisation-panel .action-buttons .control-button2.image-optimisation-cancel:hover {
      background-color: #d3edff; }
    .site-image-optimisation-panel .action-buttons .control-button2.image-optimisation-done {
      float: right; }

.member-settings-panel-content {
  display: flex;
  flex-direction: column;
  height: 100%; }

.site-members-settings-panel {
  width: 440px; }
  .site-members-settings-panel .social-login-settings .header {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 16px;
    background-color: #eff3f5;
    color: #075673;
    text-align: center;
    line-height: 40px; }
  .site-members-settings-panel .social-login-settings .options {
    padding: 0 24px; }
    .site-members-settings-panel .social-login-settings .options .social-option {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      display: flex;
      align-items: center;
      margin: 16px 0; }
      .site-members-settings-panel .social-login-settings .options .social-option > label {
        width: 100%;
        color: #162d3d; }
      .site-members-settings-panel .social-login-settings .options .social-option .icon {
        border-radius: 50%;
        box-shadow: 0 0 6px rgba(43, 86, 114, 0.18);
        background-color: #FFF;
        margin-right: 25px; }
        .site-members-settings-panel .social-login-settings .options .social-option .icon.google {
          padding: 13px 11.9px; }
        .site-members-settings-panel .social-login-settings .options .social-option .icon.facebook {
          padding: 3px 4.5px; }
  .site-members-settings-panel .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 18px 18px 18px; }
    .site-members-settings-panel .actions .link-container {
      display: flex;
      align-items: center;
      max-width: 296px; }
      .site-members-settings-panel .actions .link-container .link {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 14px;
        flex: 1;
        color: #3899ec;
        margin: 0 18px 0 12px;
        cursor: pointer; }

.site-members-settings-new-panel {
  width: 440px;
  max-height: 670px;
  height: calc(100vh - 166px); }
  .site-members-settings-new-panel hr {
    flex-shrink: 0; }
  .site-members-settings-new-panel div:not(.tabbed):not(.control-action-with-symbol):not(.content-container):not(.has-tooltip):not(.tooltip-on-ellipsis-content):not(.content) {
    display: flex;
    flex-direction: column; }
  .site-members-settings-new-panel .policies-segment-header {
    flex-shrink: 0; }
  .site-members-settings-new-panel .tab-content {
    max-height: calc(100% - 47px - 1px - 65px);
    overflow: scroll;
    flex-shrink: 0;
    flex-grow: 1;
    flex-basis: 1;
    margin-top: -12px; }
  .site-members-settings-new-panel .policy-link-area {
    display: flex;
    flex-shrink: 0;
    margin-top: -21px; }
    .site-members-settings-new-panel .policy-link-area .policy-toggle-divider {
      margin: 25px 0 7px; }
  .site-members-settings-new-panel .settings {
    display: flex;
    flex-direction: column; }
    .site-members-settings-new-panel .settings .header {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px;
      background-color: #eff3f5;
      color: #075673;
      text-align: center;
      line-height: 40px; }
    .site-members-settings-new-panel .settings .options {
      padding: 0 24px;
      flex-shrink: 0; }
      .site-members-settings-new-panel .settings .options .social-option {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 14px;
        display: flex;
        align-items: center;
        margin: 16px 0; }
        .site-members-settings-new-panel .settings .options .social-option > label {
          width: 100%;
          color: #162d3d; }
        .site-members-settings-new-panel .settings .options .social-option .icon {
          border-radius: 50%;
          box-shadow: 0 0 6px rgba(43, 86, 114, 0.18);
          background-color: #FFF;
          margin-right: 25px; }
          .site-members-settings-new-panel .settings .options .social-option .icon.google {
            padding: 13px 11.9px; }
          .site-members-settings-new-panel .settings .options .social-option .icon.facebook {
            padding: 3px 4.5px; }
          .site-members-settings-new-panel .settings .options .social-option .icon.community {
            padding: 13px 11.5px; }
      .site-members-settings-new-panel .settings .options .option {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 14px;
        display: flex;
        align-items: center;
        margin: 21px 0; }
        .site-members-settings-new-panel .settings .options .option > label {
          width: 100%;
          color: #162d3d; }
  .site-members-settings-new-panel .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 18px 18px 18px; }
    .site-members-settings-new-panel .actions .done-button {
      margin-left: auto; }

.mobile-editor-first-time h1 {
  margin-bottom: -29px; }

.mobile-editor-first-time .image.check-your-mobile-site-image {
  background: url("//static.parastorage.com/services/santa-resources/resources/editor/panels/focusPanels/checkYourMobileSite.v2.gif") -1px 0 no-repeat;
  width: 406px;
  height: 146px;
  transform: translateY(12px); }

.mobile-editor-first-time .image {
  transform: translateY(34px); }

.mobile-editor-first-time .content {
  padding-top: 83px; }

.check-your-mobile-site h1 {
  margin-bottom: -29px; }

.check-your-mobile-site .image.check-your-mobile-site-image {
  background: url("//static.parastorage.com/services/santa-resources/resources/editor/panels/focusPanels/checkYourMobileSite.v2.gif") -1px 0 no-repeat;
  width: 406px;
  height: 146px;
  transform: translateY(12px); }

.check-your-mobile-site .content .panel-text {
  padding-top: 12px; }

.check-your-mobile-site .content .bottom-controls {
  text-align: right;
  padding-bottom: 0;
  padding-top: 18px; }
  .check-your-mobile-site .content .bottom-controls button {
    margin-left: 18px; }

.fonts-upload-panel .fonts-upload-header {
  height: 53px;
  border-bottom: 1px solid #d3edff;
  display: flex;
  align-items: center;
  background: #eaf7ff;
  padding: 0 18px 0 24px; }
  .fonts-upload-panel .fonts-upload-header .general-explanation-text {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 12px;
    color: #7a92a5; }
  .fonts-upload-panel .fonts-upload-header .font-upload-button {
    margin-left: auto; }

.fonts-upload-panel .empty-message-container {
  text-align: center;
  padding-top: 60px;
  padding-bottom: 72px;
  width: 324px;
  margin: 0 auto; }
  .fonts-upload-panel .empty-message-container .empty-symbol-container {
    margin-bottom: 21px; }
  .fonts-upload-panel .empty-message-container .empty-text-title {
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 16px;
    font-size: 18px;
    color: #2b5672;
    display: inline-block;
    margin-bottom: 14px; }
  .fonts-upload-panel .empty-message-container .empty-text {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    color: #7a92a5;
    margin-right: 5px; }
  .fonts-upload-panel .empty-message-container .fonts-upload-link {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    color: #3899ec;
    margin-left: 6px; }
    .fonts-upload-panel .empty-message-container .fonts-upload-link:hover {
      text-decoration: underline;
      cursor: hand; }

.fonts-upload-panel .font-list-container {
  max-height: 352px; }

.fonts-upload-panel .fonts-upload-footer {
  border-top: 1px solid #d9e1e8;
  display: flex;
  align-items: center;
  padding: 0 18px 0 24px;
  height: 54px; }
  .fonts-upload-panel .fonts-upload-footer .footer-msg,
  .fonts-upload-panel .fonts-upload-footer .footer-error-msg {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    padding-right: 18px; }
  .fonts-upload-panel .fonts-upload-footer .footer-msg {
    color: #7a92a5; }
  .fonts-upload-panel .fonts-upload-footer .footer-error-msg {
    color: #ee5951; }
  .fonts-upload-panel .fonts-upload-footer .btn-md {
    min-width: 85px; }
  .fonts-upload-panel .fonts-upload-footer .close-panel {
    margin-left: auto; }

@keyframes hide-fileName {
  0%,
  30% {
    transform: translate(0, 0);
    opacity: 1; }
  100% {
    transform: translate(0, 30px);
    opacity: 0; } }

@keyframes show-displayName {
  0%,
  70% {
    transform: translate(0, -20px);
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes hide-fontMsg {
  0%,
  30% {
    transform: translate(0, 0);
    opacity: 1; }
  60% {
    opacity: 0; }
  100% {
    transform: translate(0, 30px);
    opacity: 0; } }

.font-item {
  position: relative;
  min-height: 72px;
  border-bottom: 1px solid #f0f3f5;
  padding: 0 24px; }
  .font-item.queue .font-title.display-name {
    opacity: 0; }
  .font-item.queue .font-title.file-name {
    opacity: 1; }
  .font-item.animated .font-title.file-name {
    animation: hide-fileName 1.8s 1 cubic-bezier(0.65, -1, 0.14, 1); }
  .font-item.animated .font-title.display-name {
    animation: show-displayName 1.8s 1 cubic-bezier(0.65, -1, 0.14, 1); }
  .font-item.animated .font-status-msg {
    animation: hide-fontMsg 1.8s 1 cubic-bezier(0.65, -1, 0.14, 1); }
  .font-item.list .font-title.display-name {
    opacity: 1; }
  .font-item.list .font-title.file-name {
    opacity: 0; }
  .font-item .font-description .font-title {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #2b5672;
    max-width: 498px;
    position: absolute;
    display: block; }
    .font-item .font-description .font-title.display-name {
      min-height: 25px;
      top: 25px;
      font-size: 24px; }
    .font-item .font-description .font-title.file-name {
      height: 20px;
      top: 22px; }
  .font-item .font-description.uploaded .font-status-msg {
    opacity: 0; }
  .font-item .font-description .font-status-msg {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 12px;
    color: #7a92a5;
    display: block;
    position: absolute;
    top: 41px; }
    .queue .font-item .font-description .font-status-msg {
      min-height: 14px; }
  .font-item.error .font-status-msg {
    color: #ee5951; }
  .font-item .indicator {
    position: absolute;
    right: 24px;
    top: 23px; }
  .font-item .cancel-icon {
    position: absolute;
    right: 26px;
    top: 23px;
    display: none; }
    .font-item .cancel-icon .cancel-btn {
      width: 25px;
      height: 25px;
      padding: 0;
      border-radius: 50%;
      border: 1px solid rgba(255, 255, 255, 0.96);
      box-sizing: border-box;
      background-color: rgba(255, 255, 255, 0.96); }
  .font-item .actions {
    min-height: 29px;
    display: none;
    position: absolute;
    right: 18px;
    top: 29px; }
    .font-item .actions .action-btn-container {
      display: inline-block; }
      .font-item .actions .action-btn-container .action-btn {
        cursor: pointer; }
      .font-item .actions .action-btn-container .download {
        margin-right: 12px; }
  .font-item:hover {
    background: #eaf7ff;
    cursor: default; }
  .font-item.cancelable:hover .cancel-icon {
    display: block;
    cursor: pointer; }
  .font-item.cancelable:hover .indicator {
    display: none; }
  .font-item.with-actions:hover .actions {
    display: inline-block; }
  .font-item.with-actions:hover .indicator {
    display: none; }

.delete-font-message {
  width: 498px; }
  .delete-font-message .message {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 16px;
    color: #162d3d;
    width: 417px;
    margin: 31px auto 31px 24px; }

.cancel-font-message {
  width: 498px; }
  .cancel-font-message .message {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 16px;
    color: #162d3d;
    width: 417px;
    margin: 31px auto 31px 24px; }

.link-panel {
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 560px; }
  .link-panel .dropdown.combobox .combo-box {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .link-panel > .content {
    color: #162d3d;
    font-size: 14px; }
    .link-panel > .content p,
    .link-panel > .content button.control-button {
      font-size: 14px; }
    .link-panel > .content .tabs {
      border-bottom: 1px solid #d9e1e8;
      min-height: 235px; }
      .link-panel > .content .tabs > .tab-content {
        flex-grow: 1;
        overflow: hidden;
        position: relative; }
        .link-panel > .content .tabs > .tab-content.NoLink, .link-panel > .content .tabs > .tab-content.PageLink, .link-panel > .content .tabs > .tab-content.AnchorLink, .link-panel > .content .tabs > .tab-content.EdgeAnchorLinks, .link-panel > .content .tabs > .tab-content.PopupLink {
          padding: 20px 24px; }
        .link-panel > .content .tabs > .tab-content.PopupLink {
          flex-grow: 1;
          display: flex; }
        .link-panel > .content .tabs > .tab-content > .tab > .control-text-input {
          border-bottom: 1px solid #d9e1e8;
          padding-bottom: 8px;
          width: 100%; }
          .link-panel > .content .tabs > .tab-content > .tab > .control-text-input > label {
            padding-left: 0; }
        .link-panel > .content .tabs > .tab-content > .tab.email > .control-text-input {
          padding-bottom: 11px; }
          .link-panel > .content .tabs > .tab-content > .tab.email > .control-text-input:last-child {
            border-bottom: none;
            margin-top: 18px; }
        .link-panel > .content .tabs > .tab-content > .tab.phone {
          height: 100%; }
          .link-panel > .content .tabs > .tab-content > .tab.phone > .extra-info {
            font-size: 14px;
            color: #7a92a5;
            line-height: 19px;
            margin: 0;
            position: absolute;
            bottom: 0;
            padding: 20px 24px; }
            .link-panel > .content .tabs > .tab-content > .tab.phone > .extra-info > .inline-link-button {
              font-size: 14px;
              height: 16px; }
        .link-panel > .content .tabs > .tab-content > .tab.anchor > .no-anchors {
          display: flex;
          flex-direction: column;
          flex: 1; }
          .link-panel > .content .tabs > .tab-content > .tab.anchor > .no-anchors .symbol-container {
            height: 106px;
            line-height: 106px;
            margin-bottom: 12px;
            text-align: center; }
            .link-panel > .content .tabs > .tab-content > .tab.anchor > .no-anchors .symbol-container > svg {
              vertical-align: bottom; }
          .link-panel > .content .tabs > .tab-content > .tab.anchor > .no-anchors h3,
          .link-panel > .content .tabs > .tab-content > .tab.anchor > .no-anchors p {
            padding: 0 24px;
            text-align: center; }
          .link-panel > .content .tabs > .tab-content > .tab.anchor > .no-anchors h3 {
            color: #2b5672;
            font-size: 18px;
            font-weight: bold;
            line-height: 18px;
            margin: 0 0 10px 0; }
          .link-panel > .content .tabs > .tab-content > .tab.anchor > .no-anchors p {
            color: #7a92a5;
            font-weight: 300;
            line-height: 18px;
            margin: 0; }
          .link-panel > .content .tabs > .tab-content > .tab.anchor > .no-anchors button.control-button.btn-text {
            height: 18px; }
        .link-panel > .content .tabs > .tab-content > .tab.none, .link-panel > .content .tabs > .tab-content > .tab.popup {
          text-align: center;
          display: flex;
          flex-direction: column;
          flex: 1; }
          .link-panel > .content .tabs > .tab-content > .tab.none .symbol-container, .link-panel > .content .tabs > .tab-content > .tab.popup .symbol-container {
            height: 106px;
            line-height: 106px;
            margin-bottom: 12px; }
            .link-panel > .content .tabs > .tab-content > .tab.none .symbol-container > svg, .link-panel > .content .tabs > .tab-content > .tab.popup .symbol-container > svg {
              vertical-align: bottom; }
          .link-panel > .content .tabs > .tab-content > .tab.none > .popup-list, .link-panel > .content .tabs > .tab-content > .tab.popup > .popup-list {
            text-align: left;
            display: flex;
            flex-direction: column;
            flex: 1; }
            .link-panel > .content .tabs > .tab-content > .tab.none > .popup-list > .popup-divider, .link-panel > .content .tabs > .tab-content > .tab.popup > .popup-list > .popup-divider {
              margin-top: 12px; }
            .link-panel > .content .tabs > .tab-content > .tab.none > .popup-list > .popup-footer, .link-panel > .content .tabs > .tab-content > .tab.popup > .popup-list > .popup-footer {
              flex: 1;
              display: flex;
              justify-content: flex-end;
              flex-direction: column; }
              .link-panel > .content .tabs > .tab-content > .tab.none > .popup-list > .popup-footer > .popup-footer-content > .popup-want-to-add-link, .link-panel > .content .tabs > .tab-content > .tab.popup > .popup-list > .popup-footer > .popup-footer-content > .popup-want-to-add-link {
                margin-right: 4px; }
          .link-panel > .content .tabs > .tab-content > .tab.none h3,
          .link-panel > .content .tabs > .tab-content > .tab.none p, .link-panel > .content .tabs > .tab-content > .tab.popup h3,
          .link-panel > .content .tabs > .tab-content > .tab.popup p {
            padding: 0 24px; }
          .link-panel > .content .tabs > .tab-content > .tab.none h3, .link-panel > .content .tabs > .tab-content > .tab.popup h3 {
            color: #2b5672;
            font-size: 18px;
            font-weight: bold;
            line-height: 18px;
            margin: 0 0 10px 0; }
          .link-panel > .content .tabs > .tab-content > .tab.none p, .link-panel > .content .tabs > .tab-content > .tab.popup p {
            color: #7a92a5;
            font-weight: 300;
            line-height: 18px;
            margin: 0; }
          .link-panel > .content .tabs > .tab-content > .tab.none button.control-button.btn-text, .link-panel > .content .tabs > .tab-content > .tab.popup button.control-button.btn-text {
            height: 18px; }
        .link-panel > .content .tabs > .tab-content > .tab.page .dropdown-title, .link-panel > .content .tabs > .tab-content > .tab.anchor .dropdown-title {
          color: #2b5672; }
        .link-panel > .content .tabs > .tab-content > .tab.page .combobox,
        .link-panel > .content .tabs > .tab-content > .tab.page .control-dropdown, .link-panel > .content .tabs > .tab-content > .tab.anchor .combobox,
        .link-panel > .content .tabs > .tab-content > .tab.anchor .control-dropdown {
          padding-top: 4px;
          padding-bottom: 12px; }
        .link-panel > .content .tabs > .tab-content > .tab.page .dropdown-link-separator, .link-panel > .content .tabs > .tab-content > .tab.anchor .dropdown-link-separator {
          margin-bottom: 20px; }
        .link-panel > .content .tabs > .tab-content > .tab.page > .dropdown.select:first-child, .link-panel > .content .tabs > .tab-content > .tab.anchor > .dropdown.select:first-child {
          border-bottom: 1px solid #d9e1e8;
          padding-bottom: 12px; }
        .link-panel > .content .tabs > .tab-content > .tab.page > .dropdown.select:nth-child(2), .link-panel > .content .tabs > .tab-content > .tab.anchor > .dropdown.select:nth-child(2) {
          padding-top: 20px;
          padding-bottom: 8px; }
        .link-panel > .content .tabs > .tab-content > .tab.page > button.control-button.btn-text, .link-panel > .content .tabs > .tab-content > .tab.anchor > button.control-button.btn-text {
          margin-top: 8px;
          text-align: left; }
        .link-panel > .content .tabs > .tab-content > .tab.web > .control-text-input {
          padding: 20px 24px 8px;
          box-sizing: border-box;
          border-bottom: none; }
          .link-panel > .content .tabs > .tab-content > .tab.web > .control-text-input > .validation-icon-wrapper {
            top: 57px;
            right: 49px; }
        .link-panel > .content .tabs > .tab-content > .tab.document > .media-button {
          margin-bottom: 22px; }
        .link-panel > .content .tabs > .tab-content.LoginToWixLink > .tab > .dropdown.select,
        .link-panel > .content .tabs > .tab-content.LoginToWixLink > .tab > .boolean-control.control-switch {
          border-bottom: 1px solid #d9e1e8;
          width: 100%; }
          .link-panel > .content .tabs > .tab-content.LoginToWixLink > .tab > .dropdown.select > svg,
          .link-panel > .content .tabs > .tab-content.LoginToWixLink > .tab > .boolean-control.control-switch > svg {
            float: none;
            margin-left: 20px;
            margin-top: 7px; }
        .link-panel > .content .tabs > .tab-content.LoginToWixLink > .tab > .control-text-input {
          padding-bottom: 0; }
      .link-panel > .content .tabs > .tab-header {
        padding: 20px 24px;
        border-right: 1px solid #d9e1e8;
        box-sizing: border-box;
        min-width: 210px;
        width: 210px; }
        .link-panel > .content .tabs > .tab-header > .tab-label {
          margin-bottom: 14px; }
          .link-panel > .content .tabs > .tab-header > .tab-label:last-child {
            margin-bottom: 0; }
    .link-panel > .content footer {
      padding: 18px; }
      .link-panel > .content footer button.control-button {
        height: 30px; }
        .link-panel > .content footer button.control-button.btn-confirm-primary {
          float: right; }

.link-panel-options-container .dropdown-options {
  max-width: 300px;
  max-height: 300px; }

.link-panel-options-container .selected-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.language-support-panel {
  position: fixed;
  min-height: 580px;
  width: 360px;
  top: calc(50vh - (580px / 2));
  left: calc(50vw - (360px / 2)); }

.language-support-panel-content-wrapper > .label {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  color: #2b5672;
  padding: 20px 24px 10px; }

.language-support-panel-content-wrapper .language-option {
  border-bottom: 1px solid #d9e1e8;
  height: 60px;
  margin: 0 24px;
  display: flex;
  align-items: center; }
  .language-support-panel-content-wrapper .language-option .example-img {
    margin-left: auto; }

.language-support-panel-content-wrapper footer {
  text-align: center;
  padding-bottom: 20px; }
  .language-support-panel-content-wrapper footer .copy-right-text {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    margin: 20px 24px;
    color: #7a92a5;
    line-height: 21px;
    font-weight: 300; }
    .language-support-panel-content-wrapper footer .copy-right-text a {
      color: #3899ec;
      text-decoration: none;
      margin-left: 5px; }
      .language-support-panel-content-wrapper footer .copy-right-text a:hover {
        cursor: pointer;
        text-decoration: underline; }

.add-color-content {
  width: 210px;
  position: relative;
  background-color: #fff;
  border-radius: 10px; }
  .add-color-content .colorSpace {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; }
  .add-color-content .color-indicator {
    text-align: center;
    margin-top: 16px;
    font-size: 0; }
    .add-color-content .color-indicator .color-sample {
      display: inline-block;
      width: 67px;
      height: 12px;
      border: 1px solid #d9e1e8;
      box-sizing: border-box; }
    .add-color-content .color-indicator .initial-color {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      cursor: pointer;
      border-right: none; }
    .add-color-content .color-indicator .current-color {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-left: none; }
  .add-color-content .custom-colors-separator {
    border-top: 1px solid #f0f3f5;
    margin-top: 16px; }
  .add-color-content .custom-colors-section-title {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    padding: 20px 14px 3px 14px;
    color: #2b5672; }
  .add-color-content .custom-colors-section {
    height: 100px;
    padding: 0 9px; }
  .add-color-content .footer {
    text-align: center;
    padding: 12px 0;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around; }
    .add-color-content .footer button {
      height: 24px;
      line-height: 24px;
      width: 72px;
      padding: 0; }

.site-custom-colors {
  padding: 10px 0 0 0;
  font-size: 0; }
  .site-custom-colors .add-color-button {
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border: 1px solid #3899ec; }
    .site-custom-colors .add-color-button:hover {
      box-shadow: 0 0 0 3px rgba(56, 153, 236, 0.3); }
  .site-custom-colors .color-option {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px; }
    .site-custom-colors .color-option .color-displayer {
      border-radius: 50%;
      width: 22px;
      height: 22px; }
      .site-custom-colors .color-option .color-displayer.white-option {
        box-sizing: border-box;
        border: 1px solid #ccc; }
    .site-custom-colors .color-option input {
      display: none; }
      .site-custom-colors .color-option input:checked + .color-displayer {
        border-radius: 50%;
        width: 22px;
        height: 22px;
        box-shadow: 0 0 1px 2px #fff, 0 0 0 3px #7a92a5; }
    .site-custom-colors .color-option:hover {
      cursor: pointer; }
      .site-custom-colors .color-option:hover input:not(:checked) + .color-displayer {
        border-radius: 50%;
        width: 26px;
        height: 26px; }
  .site-custom-colors .empty-color-option {
    position: relative;
    display: inline-block;
    vertical-align: top;
    top: -1px;
    width: 21px;
    height: 21px;
    margin-right: 7px;
    margin-bottom: 8px;
    border-radius: 50%;
    border: solid 1px #d9e1e8; }

.color-picker-panel.tool-panel-frame {
  overflow: visible;
  background: none !important;
  border: none !important;
  box-shadow: none !important; }

.color-picker-panel-content-wrapper {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  white-space: nowrap; }
  .color-picker-panel-content-wrapper .panel-section {
    padding: 0 14px; }
  .color-picker-panel-content-wrapper .color-picker-header {
    position: relative;
    border-bottom: solid 1px #d9e1e8;
    cursor: move; }
    .color-picker-panel-content-wrapper .color-picker-header .color-picker-header-content {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px;
      height: 47px;
      line-height: 47px;
      margin-right: 21px; }
    .color-picker-panel-content-wrapper .color-picker-header .btn-back {
      text-align: left;
      padding-left: 9px;
      width: auto;
      margin-left: 0; }
      .color-picker-panel-content-wrapper .color-picker-header .btn-back svg {
        margin-right: 12px; }
        .color-picker-panel-content-wrapper .color-picker-header .btn-back svg > path {
          color: #5c99e9; }
    .color-picker-panel-content-wrapper .color-picker-header .close-panel-button {
      position: absolute;
      top: calc(50% - (21px / 2));
      right: 14px;
      width: 21px;
      height: 21px;
      border-radius: 50%;
      background-color: #d3edff;
      cursor: pointer; }
      .color-picker-panel-content-wrapper .color-picker-header .close-panel-button svg {
        width: 21px;
        height: 21px;
        fill: #3899ec; }
      .color-picker-panel-content-wrapper .color-picker-header .close-panel-button:hover {
        background-color: #eaf7ff; }
  .color-picker-panel-content-wrapper .main-content {
    box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.36);
    position: relative;
    z-index: 1;
    display: inline-block;
    vertical-align: top;
    width: 210px;
    border-radius: 10px;
    background-color: #fff;
    white-space: normal; }
  .color-picker-panel-content-wrapper .choose-color-content {
    height: 391px; }
    .color-picker-panel-content-wrapper .choose-color-content .site-colors-section {
      display: flex;
      padding-top: 16px;
      padding-bottom: 12px; }
      .color-picker-panel-content-wrapper .choose-color-content .site-colors-section .section-left {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 14px;
        flex: 2;
        text-align: left;
        color: #2b5672; }
      .color-picker-panel-content-wrapper .choose-color-content .site-colors-section .section-gap {
        width: 18px; }
      .color-picker-panel-content-wrapper .choose-color-content .site-colors-section .section-right {
        flex: 1;
        text-align: right;
        user-select: all; }
        .color-picker-panel-content-wrapper .choose-color-content .site-colors-section .section-right a {
          font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-size: 14px;
          color: #3899ec;
          cursor: pointer; }
        .color-picker-panel-content-wrapper .choose-color-content .site-colors-section .section-right :hover {
          text-decoration: underline; }
    .color-picker-panel-content-wrapper .choose-color-content .custom-colors-title-section {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      padding-top: 20px;
      padding-bottom: 3px;
      color: #2b5672; }
    .color-picker-panel-content-wrapper .choose-color-content .custom-colors-section {
      height: 132px;
      margin: 0 9px; }
    .color-picker-panel-content-wrapper .choose-color-content .footer {
      height: 48px;
      line-height: 48px;
      display: flex; }
      .color-picker-panel-content-wrapper .choose-color-content .footer .section-left {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 14px;
        flex: 1;
        text-align: left;
        color: #3899ec; }
        .color-picker-panel-content-wrapper .choose-color-content .footer .section-left a {
          cursor: pointer; }
          .color-picker-panel-content-wrapper .choose-color-content .footer .section-left a:hover {
            text-decoration: underline; }
      .color-picker-panel-content-wrapper .choose-color-content .footer .section-right {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 14px;
        flex: 1;
        text-align: right;
        color: #2b5672;
        user-select: all; }
        .color-picker-panel-content-wrapper .choose-color-content .footer .section-right span {
          -moz-user-select: text;
          -webkit-user-select: text;
          -ms-user-select: text;
          user-select: text; }
  .color-picker-panel-content-wrapper .content-change-palette {
    height: 391px; }
    .color-picker-panel-content-wrapper .content-change-palette .change-palette-section {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      height: 45px;
      line-height: 45px;
      color: #2b5672; }
    .color-picker-panel-content-wrapper .content-change-palette .root-colors-wrapper {
      margin-top: 7px;
      padding-bottom: 11px;
      display: flex;
      flex-direction: row;
      border-bottom: solid 1px #d9e1e8; }
      .color-picker-panel-content-wrapper .content-change-palette .root-colors-wrapper .root-color-box {
        flex: 1 1 auto;
        text-align: center; }
        .color-picker-panel-content-wrapper .content-change-palette .root-colors-wrapper .root-color-box.white-color .root-color-circle {
          box-sizing: border-box;
          border: 1px solid #ccc; }
        .color-picker-panel-content-wrapper .content-change-palette .root-colors-wrapper .root-color-box.white-color .root-color-arrow {
          border-bottom-color: #ccc !important; }
        .color-picker-panel-content-wrapper .content-change-palette .root-colors-wrapper .root-color-box .root-color-arrow {
          margin-left: 50%;
          transform: translateX(-50%);
          margin-bottom: 5px;
          width: 0;
          height: 0;
          border-bottom: 6px solid;
          border-right: 3.5px solid transparent;
          border-left: 3.5px solid transparent; }
        .color-picker-panel-content-wrapper .content-change-palette .root-colors-wrapper .root-color-box .root-color-circle {
          display: inline-block;
          width: 24px;
          height: 24px;
          line-height: 24px;
          border-radius: 50%;
          cursor: pointer; }
          .color-picker-panel-content-wrapper .content-change-palette .root-colors-wrapper .root-color-box .root-color-circle svg {
            margin-top: 6px; }
    .color-picker-panel-content-wrapper .content-change-palette .help-text {
      padding: 20px 17px 0; }
      .color-picker-panel-content-wrapper .content-change-palette .help-text a {
        display: block;
        color: #3899ec;
        cursor: pointer; }
        .color-picker-panel-content-wrapper .content-change-palette .help-text a:hover {
          text-decoration: underline; }
    .color-picker-panel-content-wrapper .content-change-palette .footer {
      position: absolute;
      bottom: 0;
      width: 100%; }
      .color-picker-panel-content-wrapper .content-change-palette .footer .cancel-button,
      .color-picker-panel-content-wrapper .content-change-palette .footer .confirm-button {
        margin-bottom: 12px;
        width: 72px;
        height: 24px;
        line-height: 24px;
        padding: 0;
        cursor: pointer; }
      .color-picker-panel-content-wrapper .content-change-palette .footer .cancel-button {
        margin-left: 18px; }
      .color-picker-panel-content-wrapper .content-change-palette .footer .confirm-button {
        float: right;
        margin-right: 18px; }
  .color-picker-panel-content-wrapper .secondary-content {
    box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.36);
    display: inline-block;
    vertical-align: top;
    position: relative;
    left: -18px;
    background-color: #fff;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    white-space: normal; }
    .color-picker-panel-content-wrapper .secondary-content.palette-list-section {
      height: 438px;
      text-align: center; }
      .color-picker-panel-content-wrapper .secondary-content.palette-list-section .palette-list-title {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 14px;
        height: 53px;
        line-height: 60px;
        padding-left: 19px;
        color: #2b5672; }
      .color-picker-panel-content-wrapper .secondary-content.palette-list-section .palette-list-comp-wrapper {
        height: calc(100% - 53px); }
        .color-picker-panel-content-wrapper .secondary-content.palette-list-section .palette-list-comp-wrapper .change-palette-palette-list {
          margin-left: 19px; }

.icon-picker-header {
  padding: 0 14px; }

.icon-picker-header-content {
  display: flex;
  width: 100%;
  align-items: center; }

.icon-picker-title {
  box-sizing: border-box;
  padding-top: 12px;
  padding-left: 9px;
  flex-grow: 1;
  height: 46px; }

.icon-picker-panel-close-button {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #D9EFFD;
  fill: #3A9DEE; }
  .icon-picker-panel-close-button svg {
    width: 20px;
    height: 20px; }

.icon-picker-body {
  width: 188px;
  max-height: 195px;
  box-sizing: border-box;
  padding: 0 14px; }

.icon-picker-selection {
  fill: #2D576F;
  width: 40px;
  height: 40px; }
  .icon-picker-selection.icon-current {
    fill: #3898EE; }

.actionbar-sub-panel {
  height: 545px;
  width: 340px; }
  .actionbar-sub-panel .actionbar-sub-panel-content-wrapper {
    padding: 10px;
    height: 465px; }

.social-link-item {
  margin: 10px 10px 10px 7px;
  height: 36px; }
  .social-link-item .control-text-input {
    width: 87%;
    margin-left: 0; }
  .social-link-item .symbol {
    display: inline-block;
    vertical-align: top;
    margin-top: 2px; }
  .social-link-item:first-child {
    margin-top: 0; }
  .social-link-item:last-child {
    margin-bottom: 0; }

.pages-selector-item {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #b1ddf8;
  border-radius: 8px;
  color: #7a7a7a;
  height: 48px;
  line-height: 48px;
  margin-bottom: 5px;
  padding: 0 10px;
  position: relative; }
  .pages-selector-item.item-is-page {
    color: #162d3d; }
    .pages-selector-item.item-is-page .page-name {
      margin-left: 10px; }
  .pages-selector-item.current-page {
    background-color: #d3edff; }
  .pages-selector-item.item-hidden {
    color: #7a92a5; }
  .pages-selector-item:not(.item-disabled).item-is-page:hover {
    background-color: #eaf7ff; }
    .pages-selector-item:not(.item-disabled).item-is-page:hover,
    .pages-selector-item:not(.item-disabled).item-is-page:hover > .item-label {
      cursor: pointer; }
  .pages-selector-item.item-second-level {
    margin-left: 25px; }
    .pages-selector-item.item-second-level:before {
      border-top: 1px solid #b1ddf8;
      content: '';
      height: 0;
      left: -11px;
      position: absolute;
      top: 50%;
      width: 11px; }
    .pages-selector-item.item-second-level:after {
      border-left: 1px solid #b1ddf8;
      content: '';
      height: 55px;
      left: -11px;
      position: absolute;
      top: -6px;
      width: 11px; }
    .pages-selector-item.item-second-level.item-second-level-last:after {
      height: 30px; }
  .pages-selector-item > .item-label {
    display: flex;
    align-items: center;
    width: 100%; }
  .pages-selector-item .page-name {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1; }
  .pages-selector-item .symbol-homePage {
    height: 10px;
    width: 13px; }
  .pages-selector-item .symbol-hiddenPage {
    height: 15px;
    width: 17px; }

.pages-selector-panel {
  overflow: hidden; }
  .pages-selector-panel > .content {
    background-color: #f0f3f5;
    padding: 20px 0 0; }
    .pages-selector-panel > .content .description-block {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      color: #2b5672;
      padding: 0 27px 20px; }
    .pages-selector-panel > .content .select-all-block {
      padding: 0 28px 10px; }
    .pages-selector-panel > .content .pages-selector-scrollbox {
      max-height: calc(100vh - 293px);
      min-height: 166px;
      padding: 0 18px 40px; }

.site-revisions-panel {
  width: 564px;
  background-color: #fff; }
  .site-revisions-panel .content-wrapper {
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 31px 23px 28px 43px; }
    .site-revisions-panel .content-wrapper .image-container {
      display: inline-block; }
    .site-revisions-panel .content-wrapper .description-container {
      width: 280px;
      padding-left: 22px;
      position: relative;
      display: inline-block;
      vertical-align: top;
      color: #162d3d; }
      .site-revisions-panel .content-wrapper .description-container p {
        margin: 0; }
  .site-revisions-panel .panel-footer {
    padding: 0 15px 20px; }

.switch-to-old-editor .panel-header .panel-header-title {
  font-size: 20px;
  font-weight: 300; }

.switch-to-old-editor .content-wrapper .logo {
  display: inline-block;
  padding: 36px 55px; }

.switch-to-old-editor .content-wrapper .text {
  display: flex;
  width: 400px;
  float: right;
  align-items: stretch;
  flex-direction: column;
  padding-top: 36px; }
  .switch-to-old-editor .content-wrapper .text a {
    display: inline;
    color: #3899ec;
    cursor: pointer; }

.switch-to-old-editor .panel-footer .btn-confirm-secondary {
  margin-right: 20px; }

.concurrent-user-panel.important-confirmation,
.concurrent-user-panel {
  width: 564px; }
  .concurrent-user-panel.important-confirmation .panel-header > .panel-header-title,
  .concurrent-user-panel .panel-header > .panel-header-title {
    font-size: 18px; }
  .concurrent-user-panel.important-confirmation .content,
  .concurrent-user-panel .content {
    margin: 0; }
  .concurrent-user-panel.important-confirmation .panel-inner,
  .concurrent-user-panel .panel-inner {
    padding: 5px; }
    .concurrent-user-panel.important-confirmation .panel-inner .concurrent-users-panel-img,
    .concurrent-user-panel .panel-inner .concurrent-users-panel-img {
      display: inline-block;
      position: relative;
      left: 0;
      top: 0;
      padding-right: 24px;
      vertical-align: top; }
    .concurrent-user-panel.important-confirmation .panel-inner .textual-content,
    .concurrent-user-panel .panel-inner .textual-content {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px;
      padding-bottom: 18px;
      max-width: 318px;
      display: inline-block;
      line-height: 21px; }
      .concurrent-user-panel.important-confirmation .panel-inner .textual-content .sub-title,
      .concurrent-user-panel .panel-inner .textual-content .sub-title {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 16px;
        padding-bottom: 24px;
        max-width: 300px;
        word-wrap: break-word; }
        .concurrent-user-panel.important-confirmation .panel-inner .textual-content .sub-title .concurrent-user,
        .concurrent-user-panel .panel-inner .textual-content .sub-title .concurrent-user {
          color: #3899ec; }
      .concurrent-user-panel.important-confirmation .panel-inner .textual-content .recommendation-list .number,
      .concurrent-user-panel .panel-inner .textual-content .recommendation-list .number {
        display: inline-block;
        position: relative;
        left: 0;
        top: 0;
        width: 15px;
        margin-right: 4px; }
      .concurrent-user-panel.important-confirmation .panel-inner .textual-content .recommendation-list .description,
      .concurrent-user-panel .panel-inner .textual-content .recommendation-list .description {
        display: inline-block;
        max-width: calc(100% - 15px - 4px);
        vertical-align: top; }
  .concurrent-user-panel.important-confirmation footer .btn-danger-primary,
  .concurrent-user-panel.important-confirmation footer .btn-confirm-primary,
  .concurrent-user-panel footer .btn-danger-primary,
  .concurrent-user-panel footer .btn-confirm-primary {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 16px;
    height: 30px;
    min-width: 72px;
    margin-right: 30px; }

.change-style.message-panel-frame {
  vertical-align: middle;
  width: 564px; }
  .change-style.message-panel-frame .content {
    overflow-y: auto;
    padding: 36px 30px 5px 54px; }
  .change-style.message-panel-frame .panel-footer {
    padding: 0 30px 20px; }
  .change-style.message-panel-frame .icon {
    height: auto;
    width: 80px;
    margin-right: 54px;
    float: left; }
  .change-style.message-panel-frame p {
    margin-top: 0; }
  .change-style.message-panel-frame .control-button.btn-text {
    font-size: 16px; }
  .change-style.message-panel-frame .btn-confirm-primary {
    right: 30px; }

.delete-page-confirmation.message-panel-frame {
  vertical-align: middle;
  width: 564px; }
  .delete-page-confirmation.message-panel-frame .content {
    overflow-y: auto;
    padding: 36px 30px 5px 54px; }
  .delete-page-confirmation.message-panel-frame .panel-footer {
    padding: 0 30px 20px; }
  .delete-page-confirmation.message-panel-frame .icon {
    height: auto;
    width: 80px;
    margin-right: 54px;
    float: left; }
  .delete-page-confirmation.message-panel-frame p {
    margin-top: 0; }
  .delete-page-confirmation.message-panel-frame .control-button.btn-text {
    font-size: 16px; }
  .delete-page-confirmation.message-panel-frame .btn-confirm-primary {
    right: 30px; }

.delete-page-message {
  vertical-align: middle;
  width: 564px; }
  .delete-page-message .panel-header {
    height: 60px; }
  .delete-page-message .panel-header-title {
    font-size: 20px;
    font-weight: 300;
    height: 60px;
    line-height: 60px; }
  .delete-page-message .panel-footer {
    padding-top: 27px; }
  .delete-page-message .control-button:not(.btn-header) {
    font-size: 16px;
    line-height: 16px;
    height: 30px;
    padding: 0 18px; }
  .delete-page-message .content {
    padding-top: 31px; }
  .delete-page-message .icon {
    padding-left: 30px;
    padding-right: 21px;
    display: inline-block; }
  .delete-page-message .message {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #162d3d;
    font-size: 18px;
    display: inline-block;
    line-height: 22px;
    vertical-align: top;
    max-width: 325px;
    margin: 5px 0 0 3px; }
  .delete-page-message footer {
    text-align: right; }
    .delete-page-message footer button {
      font-size: 16px;
      line-height: 16px; }

.link-to-button {
  border-radius: 50%;
  background-color: #fff;
  border-color: #d6e1e8;
  display: flex;
  align-items: center;
  justify-content: center; }
  .link-to-button svg path {
    fill: #2b5672; }
  .link-to-button:hover {
    background-color: #d3edff; }

.btn-with-spinner {
  position: relative; }
  .btn-with-spinner.success-status {
    animation: greanify 0.4s;
    background-color: #60bc57; }
  .btn-with-spinner.error-status {
    animation: redify 0.4s;
    background-color: #ee5951; }
  .btn-with-spinner .btn-content.hidden-content {
    visibility: hidden; }
  .btn-with-spinner .intermidiate-states {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .btn-with-spinner .spinner {
    font-size: 2px;
    width: 10em;
    height: 10em;
    border: 1em solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    position: relative;
    border-top-color: #ffffff;
    animation: spin 0.5s infinite linear; }
  .btn-with-spinner .appearance-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: appearance 0.4s; }
    .btn-with-spinner .appearance-container .success-icon {
      width: 15px;
      height: auto; }
    .btn-with-spinner .appearance-container .error-container {
      animation: appearance 0.4s;
      font-size: 20px; }

@keyframes redify {
  0% {
    background-color: #3899ec; }
  85% {
    background-color: #ee5951; } }

@keyframes greanify {
  0% {
    background-color: #3899ec; }
  85% {
    background-color: #60bc57; } }

@keyframes appearance {
  0% {
    transform: translateY(100%); }
  85% {
    transform: translateY(0%); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.grid-add-column-panel {
  border-radius: 8px;
  background-color: #fff;
  width: 222px;
  z-index: 1055;
  padding-bottom: 18px; }
  .grid-add-column-panel .header-separator {
    margin: 0 0 6px 0;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #f0f3f5; }
  .grid-add-column-panel .header {
    height: 42px;
    min-height: 42px;
    border-radius: 50%; }
  .grid-add-column-panel .panel-header-title {
    line-height: 42px; }
  .grid-add-column-panel .grid-add-column-toolbar-content {
    padding: 12px; }
  .grid-add-column-panel .dropdown {
    padding: 7px 30px 14px 12px; }
  .grid-add-column-panel .input-container {
    padding-bottom: 6px; }
  .grid-add-column-panel .text-input-label,
  .grid-add-column-panel .dropdown-label {
    color: #888888; }
  .grid-add-column-panel .button-row {
    text-align: center; }
  .grid-add-column-panel .button {
    margin-top: 23px; }

.error-page-panel-frame-z-index {
  z-index: 1719; }

.error-page-panel {
  box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.36);
  border-radius: 10px;
  background-color: #fff;
  min-width: 500px;
  min-height: 200px;
  max-width: 800px;
  max-height: 650px; }
  .error-page-panel > .panel-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; }
  .error-page-panel > .content {
    overflow-y: auto;
    padding: 25px; }
  .error-page-panel > footer {
    position: relative;
    bottom: 30px;
    text-align: right;
    padding: 40px 30px 0 30px; }
    .error-page-panel > footer .control-button.btn-confirm-secondary, .error-page-panel > footer .control-button.btn-danger-secondary {
      margin-right: 15px; }
    .error-page-panel > footer .control-button.btn-danger-primary {
      margin-right: 30px; }
  .error-page-panel.important-confirmation {
    cursor: default;
    width: 564px; }
    .error-page-panel.important-confirmation > .panel-header {
      background-color: #ee5951;
      height: 60px; }
      .error-page-panel.important-confirmation > .panel-header > .panel-header-title {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 20px;
        font-weight: 300;
        height: 60px;
        line-height: 60px; }
      .error-page-panel.important-confirmation > .panel-header > .panel-header.close {
        background-color: rgba(22, 45, 61, 0.2);
        color: #facdca; }
      .error-page-panel.important-confirmation > .panel-header .close {
        background-color: #c3504d; }
        .error-page-panel.important-confirmation > .panel-header .close:hover {
          background-color: #942b28; }
    .error-page-panel.important-confirmation .content {
      margin-right: 15px;
      line-height: 22px; }
      .error-page-panel.important-confirmation .content > p {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 18px;
        font-weight: 300; }
      .error-page-panel.important-confirmation .content > p:first-child {
        margin-top: 0; }
      .error-page-panel.important-confirmation .content a {
        color: #3899ec;
        text-decoration: none; }
    .error-page-panel.important-confirmation .icon {
      height: 180px;
      width: 150px;
      float: left;
      margin-right: 10px; }
  .error-page-panel.concurrent-save-panel {
    width: 576px;
    background-color: #fff; }
    .error-page-panel.concurrent-save-panel .panel-header {
      height: 54px; }
      .error-page-panel.concurrent-save-panel .panel-header .panel-header-title {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 20px;
        font-weight: 300;
        line-height: 54px; }
      .error-page-panel.concurrent-save-panel .panel-header .close {
        top: 14px;
        margin-top: 0; }
    .error-page-panel.concurrent-save-panel .content {
      padding: 0; }
    .error-page-panel.concurrent-save-panel > footer {
      position: static;
      text-align: right;
      margin: 0 30px 30px 0; }
      .error-page-panel.concurrent-save-panel > footer .btn-confirm-primary,
      .error-page-panel.concurrent-save-panel > footer .btn-confirm-secondary {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 16px; }
  .error-page-panel .content-wrapper {
    display: inline-block;
    font-size: 0;
    position: relative;
    margin: 36px 0 30px 0;
    width: 100%; }
  .error-page-panel .error-logo {
    float: left;
    text-align: center;
    width: 186px; }
  .error-page-panel .description-wrapper {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 16px;
    display: inline-block;
    max-width: 294px;
    line-height: 22px;
    margin: -1px 50px 0 0;
    color: #162d3d; }
    .error-page-panel .description-wrapper .description-line {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px;
      padding-left: 0.2em; }
      .error-page-panel .description-wrapper .description-line:first-child, .error-page-panel .description-wrapper .description-line.initialPadding {
        padding-left: initial; }

.accessibility-panel {
  width: 744px; }
  .accessibility-panel .accessibility-panel-content {
    margin: 50px 67px 0 48px; }
    .accessibility-panel .accessibility-panel-content .accessibility-settings .accessibility-settings-block {
      display: flex; }
      .accessibility-panel .accessibility-panel-content .accessibility-settings .accessibility-settings-block:first-child {
        margin-bottom: 24px; }
      .accessibility-panel .accessibility-panel-content .accessibility-settings .accessibility-settings-block:last-child {
        margin-top: 14px; }
      .accessibility-panel .accessibility-panel-content .accessibility-settings .accessibility-settings-block .accessibility-settings-block-icon {
        flex: 0 0 145px;
        text-align: center; }
      .accessibility-panel .accessibility-panel-content .accessibility-settings .accessibility-settings-block .accessibility-settings-block-control {
        align-self: center;
        display: flex;
        justify-content: flex-end;
        flex: 0 0 120px; }
      .accessibility-panel .accessibility-panel-content .accessibility-settings .accessibility-settings-block .accessibility-settings-block-info {
        font-size: 10px;
        margin: 0 25px; }
      .accessibility-panel .accessibility-panel-content .accessibility-settings .accessibility-settings-block .accessibility-settings-block-title {
        font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 18px;
        color: #2b5672;
        margin-bottom: 6px; }
      .accessibility-panel .accessibility-panel-content .accessibility-settings .accessibility-settings-block .accessibility-settings-block-description {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 14px;
        color: #2b5672; }
      .accessibility-panel .accessibility-panel-content .accessibility-settings .accessibility-settings-block .accessibility-settings-reordering-btn {
        height: 30px; }
  .accessibility-panel .accessibility-panel-footer {
    height: 62px;
    margin-top: 42px; }
    .accessibility-panel .accessibility-panel-footer .accessibility-panel-footer-content {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .accessibility-panel .accessibility-panel-footer .accessibility-panel-footer-content .accessibility-learn-more {
        margin-left: 36px; }
      .accessibility-panel .accessibility-panel-footer .accessibility-panel-footer-content .accessibility-learn-more-link {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 14px;
        height: 16px; }
        .accessibility-panel .accessibility-panel-footer .accessibility-panel-footer-content .accessibility-learn-more-link:hover {
          text-decoration: underline; }
      .accessibility-panel .accessibility-panel-footer .accessibility-panel-footer-content .accessibility-learn-more-text {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 14px;
        color: #2b5672;
        margin-right: 0.3em; }
      .accessibility-panel .accessibility-panel-footer .accessibility-panel-footer-content .accessibility-done-btn {
        margin-right: 29px; }

.comp-panel-frame {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1310;
  width: 288px;
  box-shadow: 0 0 18px 0 rgba(22, 45, 61, 0.27);
  border-radius: 10px;
  overflow: hidden;
  font-size: 16px;
  text-align: left;
  background-color: #fff; }
  .comp-panel-frame.first-time-panel > .panel-header {
    height: 160px; }
    .comp-panel-frame.first-time-panel > .panel-header > button {
      top: 24px;
      right: 12px;
      background-color: rgba(255, 255, 255, 0.2); }
      .comp-panel-frame.first-time-panel > .panel-header > button:hover {
        background-color: rgba(255, 255, 255, 0.4); }
  .comp-panel-frame .comp-panel-content {
    max-height: 525px;
    background-color: #fff; }
  .comp-panel-frame .btn-back-wrapper {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0, white 90%);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    height: 50px;
    width: calc(100% - 9px);
    top: 0;
    bottom: auto;
    z-index: 1;
    text-align: left; }
  .comp-panel-frame .btn-back {
    text-align: left;
    padding-left: 9px; }
    .comp-panel-frame .btn-back svg {
      margin-right: 12px; }
      .comp-panel-frame .btn-back svg > path {
        color: #5c99e9; }
  .comp-panel-frame .panel-header-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 173px;
    display: inline-block; }

.background-sub-panel,
.animation-panel,
.settings-panel {
  padding: 0;
  margin: 0; }
  .background-sub-panel .dropdown,
  .animation-panel .dropdown,
  .settings-panel .dropdown {
    padding: 19px 12px 17px 12px;
    margin: 0 11px; }
  .background-sub-panel > .control-switch,
  .background-sub-panel :not(.composite-toggle-switch) > .control-switch,
  .animation-panel > .control-switch,
  .animation-panel :not(.composite-toggle-switch) > .control-switch,
  .settings-panel > .control-switch,
  .settings-panel :not(.composite-toggle-switch) > .control-switch {
    margin: 24px; }
    .background-sub-panel > .control-switch .label-switch,
    .background-sub-panel :not(.composite-toggle-switch) > .control-switch .label-switch,
    .animation-panel > .control-switch .label-switch,
    .animation-panel :not(.composite-toggle-switch) > .control-switch .label-switch,
    .settings-panel > .control-switch .label-switch,
    .settings-panel :not(.composite-toggle-switch) > .control-switch .label-switch {
      width: calc(100% - 57px); }
  .background-sub-panel .control-buttons-group,
  .animation-panel .control-buttons-group,
  .settings-panel .control-buttons-group {
    padding: 19px 24px 26px 24px; }
  .background-sub-panel .control-button-in-panel-content,
  .animation-panel .control-button-in-panel-content,
  .settings-panel .control-button-in-panel-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 48px); }
  .background-sub-panel .selective-text-input .control-text-input,
  .animation-panel .selective-text-input .control-text-input,
  .settings-panel .selective-text-input .control-text-input {
    width: calc(100% - 100px); }
  .background-sub-panel .control-alignment,
  .animation-panel .control-alignment,
  .settings-panel .control-alignment {
    padding: 19px 24px 24px 24px; }
    .background-sub-panel .control-alignment .label,
    .animation-panel .control-alignment .label,
    .settings-panel .control-alignment .label {
      padding: 0 0 12px 0; }
  .background-sub-panel .first-time-info-box,
  .animation-panel .first-time-info-box,
  .settings-panel .first-time-info-box {
    margin-bottom: 12px; }
  .background-sub-panel .control-section-divider,
  .animation-panel .control-section-divider,
  .settings-panel .control-section-divider {
    padding: 0 42px; }
  .background-sub-panel .rich-text-paragraph,
  .animation-panel .rich-text-paragraph,
  .settings-panel .rich-text-paragraph {
    padding: 18px 24px; }
  .background-sub-panel .rich-text-paragraph-content,
  .animation-panel .rich-text-paragraph-content,
  .settings-panel .rich-text-paragraph-content {
    overflow: hidden;
    text-overflow: ellipsis; }
  .background-sub-panel .control-button:not(.btn-back):not(.no-margin):not(.create-news-letter):not(.btn-help):not(.btn-close),
  .animation-panel .control-button:not(.btn-back):not(.no-margin):not(.create-news-letter):not(.btn-help):not(.btn-close),
  .settings-panel .control-button:not(.btn-back):not(.no-margin):not(.create-news-letter):not(.btn-help):not(.btn-close) {
    margin: 12px 24px; }
  .background-sub-panel .thumbnails-with-icons,
  .animation-panel .thumbnails-with-icons,
  .settings-panel .thumbnails-with-icons {
    margin: 24px; }
  .background-sub-panel .media-button,
  .animation-panel .media-button,
  .settings-panel .media-button {
    margin: 24px; }
    .background-sub-panel .media-button .control-button,
    .animation-panel .media-button .control-button,
    .settings-panel .media-button .control-button {
      margin: 0 auto;
      max-width: calc(100% - 24px); }
  .background-sub-panel .control-button2-wrapper,
  .animation-panel .control-button2-wrapper,
  .settings-panel .control-button2-wrapper {
    padding: 24px 0;
    text-align: center; }
    .background-sub-panel .control-button2-wrapper.control-button2-wrapper-no-top-spacing,
    .animation-panel .control-button2-wrapper.control-button2-wrapper-no-top-spacing,
    .settings-panel .control-button2-wrapper.control-button2-wrapper-no-top-spacing {
      padding-top: 0; }
    .background-sub-panel .control-button2-wrapper.control-button2-wrapper-no-bottom-spacing,
    .animation-panel .control-button2-wrapper.control-button2-wrapper-no-bottom-spacing,
    .settings-panel .control-button2-wrapper.control-button2-wrapper-no-bottom-spacing {
      padding-bottom: 0; }
  .background-sub-panel > .control-button,
  .animation-panel > .control-button,
  .settings-panel > .control-button {
    margin: 24px auto;
    max-width: calc(100% - 48px);
    display: block; }
  .background-sub-panel .inner-control-button,
  .animation-panel .inner-control-button,
  .settings-panel .inner-control-button {
    margin: 24px auto;
    max-width: calc(100% - 48px);
    display: block; }
    .background-sub-panel .inner-control-button.no-margin-top,
    .animation-panel .inner-control-button.no-margin-top,
    .settings-panel .inner-control-button.no-margin-top {
      margin-top: 0; }
  .background-sub-panel .control-text-input,
  .animation-panel .control-text-input,
  .settings-panel .control-text-input {
    margin: 12px;
    width: calc(100% - 24px); }
  .background-sub-panel .control-text-input-with-button,
  .animation-panel .control-text-input-with-button,
  .settings-panel .control-text-input-with-button {
    margin: 0 12px;
    width: calc(100% - 24px); }
  .background-sub-panel .input-link-to,
  .animation-panel .input-link-to,
  .settings-panel .input-link-to {
    padding: 12px;
    width: calc(100% - 24px); }
  .background-sub-panel .c22,
  .animation-panel .c22,
  .settings-panel .c22 {
    margin: 0 23px; }
  .background-sub-panel .button-row,
  .animation-panel .button-row,
  .settings-panel .button-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 19px 12px; }
    .background-sub-panel .button-row .dropdown,
    .animation-panel .button-row .dropdown,
    .settings-panel .button-row .dropdown {
      padding: 0;
      margin: 0; }
  .background-sub-panel .input-slider,
  .animation-panel .input-slider,
  .settings-panel .input-slider {
    margin: 17px 10px 7px 24px; }
  .background-sub-panel .control-line-height > .slider-wrapper > .input-slider,
  .animation-panel .control-line-height > .slider-wrapper > .input-slider,
  .settings-panel .control-line-height > .slider-wrapper > .input-slider {
    margin-top: 0; }
  .background-sub-panel .writable-drop-down,
  .animation-panel .writable-drop-down,
  .settings-panel .writable-drop-down {
    padding: 17px 12px;
    margin-right: 11px; }
  .background-sub-panel .image-control-action-link,
  .animation-panel .image-control-action-link,
  .settings-panel .image-control-action-link {
    min-height: 150px; }
  .background-sub-panel .color-picker-input-wrapper,
  .animation-panel .color-picker-input-wrapper,
  .settings-panel .color-picker-input-wrapper {
    padding: 15px 24px;
    box-sizing: border-box; }
  .background-sub-panel .color-picker-input-with-opacity,
  .animation-panel .color-picker-input-with-opacity,
  .settings-panel .color-picker-input-with-opacity {
    margin: 15px 24px 0 24px; }
    .background-sub-panel .color-picker-input-with-opacity .input-slider,
    .animation-panel .color-picker-input-with-opacity .input-slider,
    .settings-panel .color-picker-input-with-opacity .input-slider {
      margin: 0 35px 10px 0; }
  .background-sub-panel .text-block,
  .animation-panel .text-block,
  .settings-panel .text-block {
    margin: 24px;
    display: block;
    color: #7a92a5;
    font-size: 14px;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }

.first-time-panel {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px; }
  .first-time-panel .content-wrapper {
    padding: 22px; }
    .first-time-panel .content-wrapper .content-title {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 18px;
      padding-bottom: 18px; }
    .first-time-panel .content-wrapper .content-text > .space-at-end::after {
      content: '\0000a0'; }
    .first-time-panel .content-wrapper .content-text > .link-action {
      display: inline-block;
      color: #3899ec;
      cursor: pointer;
      margin: 0 0 18px 0; }
      .first-time-panel .content-wrapper .content-text > .link-action:hover {
        color: #7fccf7;
        text-decoration: underline; }

.items-list {
  height: 100%; }
  .items-list .list-header {
    height: 67px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    border-bottom: 1px solid;
    border-color: #d9e1e8;
    padding: 0 34px; }
    .items-list .list-header .list-header-left-pane {
      flex: 1; }
    .items-list .list-header .list-title {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 20px;
      font-weight: 300;
      font-size: 24px;
      color: #162d3d;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }
    .items-list .list-header .item-list-filter {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 20px;
      font-weight: 300;
      margin-left: 14px;
      font-size: 14px;
      color: #162d3d; }
    .items-list .list-header .add-item-btn {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 20px;
      font-weight: 300;
      height: 36px;
      line-height: 36px;
      min-width: 120px;
      font-size: 16px; }
  .items-list .select-all-container {
    height: 53px;
    display: flex;
    box-sizing: border-box;
    border-bottom: 1px solid #d9e1e8;
    padding-left: 38px;
    align-items: center;
    color: #162d3d; }
    .items-list .select-all-container .multi-select-action-menu {
      display: flex;
      font-size: 14px; }
      .items-list .select-all-container .multi-select-action-menu .multi-duplicate {
        color: #162d3d;
        background-color: #fff;
        font-size: 14px;
        line-height: 36px; }
        .items-list .select-all-container .multi-select-action-menu .multi-duplicate span {
          vertical-align: middle; }
        .items-list .select-all-container .multi-select-action-menu .multi-duplicate svg {
          vertical-align: text-bottom; }
        .items-list .select-all-container .multi-select-action-menu .multi-duplicate path {
          fill: #2b5672; }
        .items-list .select-all-container .multi-select-action-menu .multi-duplicate:hover {
          color: #3899ec; }
          .items-list .select-all-container .multi-select-action-menu .multi-duplicate:hover path {
            fill: #3899ec; }
      .items-list .select-all-container .multi-select-action-menu .multi-delete {
        color: #162d3d;
        background-color: #fff;
        font-size: 14px;
        line-height: 36px; }
        .items-list .select-all-container .multi-select-action-menu .multi-delete span {
          vertical-align: middle; }
        .items-list .select-all-container .multi-select-action-menu .multi-delete svg {
          vertical-align: text-bottom; }
        .items-list .select-all-container .multi-select-action-menu .multi-delete path {
          fill: #2b5672; }
        .items-list .select-all-container .multi-select-action-menu .multi-delete:hover {
          color: #ee5951; }
          .items-list .select-all-container .multi-select-action-menu .multi-delete:hover path {
            fill: #ee5951; }
      .items-list .select-all-container .multi-select-action-menu .multi-show-hide {
        color: #162d3d;
        background-color: #fff;
        font-size: 14px;
        line-height: 36px; }
        .items-list .select-all-container .multi-select-action-menu .multi-show-hide span {
          vertical-align: middle; }
        .items-list .select-all-container .multi-select-action-menu .multi-show-hide svg {
          vertical-align: text-bottom; }
        .items-list .select-all-container .multi-select-action-menu .multi-show-hide path {
          fill: #2b5672; }
        .items-list .select-all-container .multi-select-action-menu .multi-show-hide:hover {
          color: #3899ec; }
          .items-list .select-all-container .multi-select-action-menu .multi-show-hide:hover path {
            fill: #3899ec; }
  .items-list .add-item-container .select-all-checkbox {
    display: none; }
  .items-list .items-container {
    height: 100%; }
    .items-list .items-container .item-list-row {
      height: 60px;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      padding: 0 36px 0 12px;
      border-bottom: 1px solid #d9e1e8; }
      .items-list .items-container .item-list-row.hidden-item .item-details {
        opacity: 0.6; }
      .items-list .items-container .item-list-row.hidden-item:hover .item-details {
        opacity: initial; }
      .items-list .items-container .item-list-row.hidden-item .drag-handle-container {
        visibility: hidden; }
      .items-list .items-container .item-list-row .symbol-dragHandle {
        width: 13px;
        height: 21px;
        padding-right: 13px;
        cursor: move; }
        .items-list .items-container .item-list-row .symbol-dragHandle path {
          fill: #7fccf7; }
        .items-list .items-container .item-list-row .symbol-dragHandle:hover path {
          fill: #3899ec; }
      .items-list .items-container .item-list-row .item-details {
        display: flex;
        flex: 1;
        align-items: center;
        height: 100%;
        margin-left: 15px; }
      .items-list .items-container .item-list-row .item-action-menu {
        display: none; }
      .items-list .items-container .item-list-row:hover {
        background-color: #eaf7ff; }
        .items-list .items-container .item-list-row:hover .action-buttons-container {
          visibility: visible; }
        .items-list .items-container .item-list-row:hover .item-action-menu {
          height: 100%;
          right: 30px;
          display: flex; }
    .items-list .items-container .no-hover:hover {
      background-color: #fff; }
      .items-list .items-container .no-hover:hover .item-action-menu {
        display: none; }
    .items-list .items-container .selected {
      background-color: #d3edff; }
      .items-list .items-container .selected:hover {
        background-color: #d3edff; }
        .items-list .items-container .selected:hover .item-action-menu {
          display: none; }
    .items-list .items-container .drag-placeholder {
      background-color: #f8f8f8;
      border-top: 1px dashed #d9e1e8; }
    .items-list .items-container .drag-container {
      background-color: #eaf7ff;
      box-shadow: 0 1px 3.92px 0.08px rgba(45, 86, 114, 0.22); }
  .items-list .drag-container {
    z-index: 1; }
  .items-list.small .add-item-btn {
    display: none; }
  .items-list.small .list-title {
    max-width: 153px; }
  .items-list.small .custom-scroll {
    background-color: #eaf7ff; }
  .items-list.small .items-container-edit {
    overflow-x: hidden;
    min-width: 215px; }
    .items-list.small .items-container-edit .item-list-row {
      height: 60px;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      padding: 0 36px 0 12px;
      border-bottom: 1px solid #d9e1e8;
      background-color: #eaf7ff; }
      .items-list.small .items-container-edit .item-list-row.hidden-item .item-details {
        opacity: 0.6; }
      .items-list.small .items-container-edit .item-list-row.hidden-item:hover .item-details {
        opacity: initial; }
      .items-list.small .items-container-edit .item-list-row.hidden-item .drag-handle-container {
        visibility: hidden; }
      .items-list.small .items-container-edit .item-list-row:hover {
        background-color: #d3edff;
        cursor: pointer; }
      .items-list.small .items-container-edit .item-list-row .item-details {
        display: flex;
        flex: 1;
        align-items: center;
        height: 100%;
        margin-left: 23px; }
        .items-list.small .items-container-edit .item-list-row .item-details .item-title {
          font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          max-width: 250px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
      .items-list.small .items-container-edit .item-list-row .item-action-menu {
        display: none; }
    .items-list.small .items-container-edit .is-open {
      background-color: #d3edff;
      font-weight: 500;
      box-shadow: 6px 0 0 0 #3899ec inset; }
      .items-list.small .items-container-edit .is-open .item-details {
        margin-left: 23px; }
        .items-list.small .items-container-edit .is-open .item-details .item-title {
          font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  @media screen and (max-width: 1160px) {
    .items-list.small {
      width: 215px; } }
  @media screen and (min-width: 1160px) {
    .items-list.small {
      width: 292px; } }

.add-item-container {
  padding: 10px 0;
  box-sizing: border-box;
  visibility: visible;
  border-bottom: 1px solid #d9e1e8; }
  .add-item-container .select-all {
    display: none; }
  .add-item-container .multi-select-action-menu {
    display: none; }
  .add-item-container .small-add-item {
    height: 32px;
    line-height: 32px;
    background-color: #fff;
    margin-left: 10px;
    color: #3899ec; }

.no-items {
  display: flex;
  align-items: center;
  flex-direction: column; }
  .no-items .no-items-image {
    margin-top: 69px; }
  .no-items .no-items-title {
    margin-top: 25px;
    margin-bottom: 16px;
    font-size: 24px; }
  .no-items .no-items-click-container > * {
    font-size: 16px; }

.item-thumb-img {
  width: 43px;
  height: 43px;
  overflow: hidden;
  border-radius: 4px; }
  .item-thumb-img.noImage {
    border: 1px solid #f0f0f0;
    background-color: #eaf7ff; }
  .item-thumb-img img {
    width: 100%;
    height: 100%; }
  .item-thumb-img svg {
    width: 25px;
    margin: auto;
    display: block; }

.item-title {
  max-width: 500px;
  font-size: 16px;
  color: #162d3d;
  padding-right: 8px;
  padding-left: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  .item-title:hover {
    color: #3899ec;
    cursor: pointer; }

.items-container-edit .item-title {
  padding-left: 0; }
  .items-container-edit .item-title:hover {
    color: #2b5672; }

.action-buttons-container {
  height: 60px;
  display: flex;
  align-items: center;
  margin-right: 13px; }
  .action-buttons-container .duplicate-item-button {
    color: #162d3d;
    flex-direction: row;
    align-items: center;
    background-color: #eaf7ff;
    font-size: 14px; }
    .action-buttons-container .duplicate-item-button path {
      fill: #162d3d; }
    .action-buttons-container .duplicate-item-button:hover {
      color: #3899ec; }
      .action-buttons-container .duplicate-item-button:hover path {
        fill: #3899ec; }
    .action-buttons-container .duplicate-item-button:active {
      color: #3899ec;
      background-color: #d3edff; }
      .action-buttons-container .duplicate-item-button:active path {
        fill: #3899ec; }
    @media screen and (max-width: 1160px) {
      .action-buttons-container .duplicate-item-button {
        border-radius: 50%;
        padding: 0;
        width: 36px;
        margin-right: 8px; }
        .action-buttons-container .duplicate-item-button .symbol-with-label {
          margin-right: 0; }
        .action-buttons-container .duplicate-item-button span {
          display: none; } }
    .action-buttons-container .duplicate-item-button svg {
      width: 12px;
      height: 14px; }
  .action-buttons-container .edit-item-button {
    color: #162d3d;
    flex-direction: row;
    align-items: center;
    background-color: #eaf7ff;
    font-size: 14px; }
    .action-buttons-container .edit-item-button path {
      fill: #162d3d; }
    .action-buttons-container .edit-item-button:hover {
      color: #3899ec; }
      .action-buttons-container .edit-item-button:hover path {
        fill: #3899ec; }
    .action-buttons-container .edit-item-button:active {
      color: #3899ec;
      background-color: #d3edff; }
      .action-buttons-container .edit-item-button:active path {
        fill: #3899ec; }
    @media screen and (max-width: 1160px) {
      .action-buttons-container .edit-item-button {
        border-radius: 50%;
        padding: 0;
        width: 36px;
        margin-right: 8px; }
        .action-buttons-container .edit-item-button .symbol-with-label {
          margin-right: 0; }
        .action-buttons-container .edit-item-button span {
          display: none; } }
    .action-buttons-container .edit-item-button svg {
      width: 14px;
      height: 14px; }
  .action-buttons-container .delete-item-button {
    color: #162d3d;
    flex-direction: row;
    align-items: center;
    background-color: #eaf7ff;
    font-size: 14px; }
    .action-buttons-container .delete-item-button path {
      fill: #162d3d; }
    .action-buttons-container .delete-item-button:hover {
      color: #ee5951; }
      .action-buttons-container .delete-item-button:hover path {
        fill: #ee5951; }
    .action-buttons-container .delete-item-button:active {
      color: #ee5951;
      background-color: #d3edff; }
      .action-buttons-container .delete-item-button:active path {
        fill: #ee5951; }
    @media screen and (max-width: 1160px) {
      .action-buttons-container .delete-item-button {
        border-radius: 50%;
        padding: 0;
        width: 36px;
        margin-right: 8px; }
        .action-buttons-container .delete-item-button .symbol-with-label {
          margin-right: 0; }
        .action-buttons-container .delete-item-button span {
          display: none; } }
    .action-buttons-container .delete-item-button svg {
      width: 13px;
      height: 14px; }
  .action-buttons-container .show-item-button {
    color: #162d3d;
    flex-direction: row;
    align-items: center;
    background-color: #eaf7ff;
    font-size: 14px; }
    .action-buttons-container .show-item-button path {
      fill: #162d3d; }
    .action-buttons-container .show-item-button:hover {
      color: #3899ec; }
      .action-buttons-container .show-item-button:hover path {
        fill: #3899ec; }
    .action-buttons-container .show-item-button:active {
      color: #3899ec;
      background-color: #d3edff; }
      .action-buttons-container .show-item-button:active path {
        fill: #3899ec; }
    @media screen and (max-width: 1160px) {
      .action-buttons-container .show-item-button {
        border-radius: 50%;
        padding: 0;
        width: 36px;
        margin-right: 8px; }
        .action-buttons-container .show-item-button .symbol-with-label {
          margin-right: 0; }
        .action-buttons-container .show-item-button span {
          display: none; } }
    .action-buttons-container .show-item-button svg {
      width: 15px;
      height: 11px;
      fill: #3899ec; }
  .action-buttons-container .hide-item-button {
    color: #162d3d;
    flex-direction: row;
    align-items: center;
    background-color: #eaf7ff;
    font-size: 14px; }
    .action-buttons-container .hide-item-button path {
      fill: #162d3d; }
    .action-buttons-container .hide-item-button:hover {
      color: #3899ec; }
      .action-buttons-container .hide-item-button:hover path {
        fill: #3899ec; }
    .action-buttons-container .hide-item-button:active {
      color: #3899ec;
      background-color: #d3edff; }
      .action-buttons-container .hide-item-button:active path {
        fill: #3899ec; }
    @media screen and (max-width: 1160px) {
      .action-buttons-container .hide-item-button {
        border-radius: 50%;
        padding: 0;
        width: 36px;
        margin-right: 8px; }
        .action-buttons-container .hide-item-button .symbol-with-label {
          margin-right: 0; }
        .action-buttons-container .hide-item-button span {
          display: none; } }
    .action-buttons-container .hide-item-button svg {
      width: 15px;
      height: 13px; }

.item-list-filter .item-list-filter-value-label {
  color: #3899ec;
  cursor: pointer; }

.item-list-filter .symbol-arrowDown {
  width: 9px;
  height: 7px;
  fill: #3899ec; }

.item-list-filter.is-open .symbol-arrowDown {
  transform: rotate(180deg); }

.item-list-filter .item-list-filter-bubble {
  width: 190px;
  cursor: pointer; }

.item-list-filter .item-list-filter-bubble-option {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  height: 40px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  color: #2b5672; }
  .item-list-filter .item-list-filter-bubble-option .symbol-check {
    visibility: hidden;
    margin-right: 10px;
    fill: #3899ec; }
  .item-list-filter .item-list-filter-bubble-option.selected {
    font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
    .item-list-filter .item-list-filter-bubble-option.selected .symbol-check {
      visibility: visible; }
  .item-list-filter .item-list-filter-bubble-option:hover {
    background-color: #eaf7ff; }

.item-edit {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: calc(100% - 292px);
  height: 100%;
  top: 0;
  right: 0;
  overflow: hidden;
  background-color: #fff;
  box-shadow: -4px 0 3px 0 rgba(0, 0, 0, 0.1); }
  @media screen and (max-width: 1160px) {
    .item-edit {
      min-width: 685px; } }
  .item-edit .no-scroll-container .item-header {
    border-bottom: 1px solid #d9e1e8;
    box-sizing: border-box;
    padding: 15px 60px;
    text-align: left;
    height: 67px;
    display: flex; }
    .item-edit .no-scroll-container .item-header .button-container {
      flex-grow: 1; }
    .item-edit .no-scroll-container .item-header .close-item-btn {
      background-color: #eaf7ff;
      color: #3899ec;
      height: 36px;
      border-radius: 18px;
      font-size: 14px;
      padding: 0 21px 0 26px; }
      .item-edit .no-scroll-container .item-header .close-item-btn svg {
        transform: scaleX(-1);
        width: 7px;
        height: 11px;
        margin-top: 0.5px; }
        .item-edit .no-scroll-container .item-header .close-item-btn svg .c1 {
          stroke: #3899ec; }
      .item-edit .no-scroll-container .item-header .close-item-btn:hover {
        background-color: #d3edff; }
    .item-edit .no-scroll-container .item-header .save-item-label {
      display: inline-block;
      height: 36px;
      min-width: 100px;
      line-height: 36px;
      text-align: left;
      font-size: 14px;
      color: #7a92a5;
      margin-right: 26px; }
    .item-edit .no-scroll-container .item-header .action-container {
      text-align: right;
      flex-direction: row;
      align-items: center; }
      .item-edit .no-scroll-container .item-header .action-container .delete-item-btn {
        background-color: #fff;
        padding: 0;
        margin-right: 26px; }
        .item-edit .no-scroll-container .item-header .action-container .delete-item-btn .button-content {
          display: inline-flex; }
        .item-edit .no-scroll-container .item-header .action-container .delete-item-btn path {
          fill: #2b5672; }
        .item-edit .no-scroll-container .item-header .action-container .delete-item-btn:hover {
          background-color: #fff; }
          .item-edit .no-scroll-container .item-header .action-container .delete-item-btn:hover path {
            fill: #ee5951; }
      .item-edit .no-scroll-container .item-header .action-container .duplicate-item-btn {
        background-color: #fff;
        padding: 0; }
        .item-edit .no-scroll-container .item-header .action-container .duplicate-item-btn .button-content {
          display: inline-flex; }
        .item-edit .no-scroll-container .item-header .action-container .duplicate-item-btn path {
          fill: #2b5672; }
        .item-edit .no-scroll-container .item-header .action-container .duplicate-item-btn:hover {
          background-color: #fff; }
          .item-edit .no-scroll-container .item-header .action-container .duplicate-item-btn:hover path {
            fill: #3899ec; }
  .item-edit .item-fields-container {
    padding: 32px 60px calc(2 * 32px) 60px;
    flex: 1;
    overflow-x: hidden;
    min-height: 100%;
    position: relative;
    box-sizing: border-box; }
    @media screen and (max-width: 1160px) {
      .item-edit .item-fields-container {
        padding-right: 20px; } }
    .item-edit .item-fields-container .item-field-title,
    .item-edit .item-fields-container .control-text-input label,
    .item-edit .item-fields-container .input-link-to label {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      display: block;
      margin-bottom: 12px;
      margin-left: 12px;
      color: #2b5672; }
    .item-edit .item-fields-container .item-field {
      margin-bottom: 26px; }
      .item-edit .item-fields-container .item-field > input {
        border-radius: 4px;
        font-size: 14px;
        border: 1px solid #7fccf7;
        color: #162d3d; }
        .item-edit .item-fields-container .item-field > input::placeholder {
          color: #b6c1cd; }
        .item-edit .item-fields-container .item-field > input:disabled {
          border-color: #f0f0f0;
          background-color: #fff; }
          .item-edit .item-fields-container .item-field > input:disabled::placeholder {
            color: #939393; }
        .item-edit .item-fields-container .item-field > input.invalid {
          border-color: #ee5951; }
        .item-edit .item-fields-container .item-field > input:hover {
          background-color: #eaf7ff; }
        .item-edit .item-fields-container .item-field > input:focus {
          border-color: #3899ec;
          background-color: #fff; }
    .item-edit .item-fields-container .control-text-input {
      display: block;
      width: 100%; }
      .item-edit .item-fields-container .control-text-input input {
        border-radius: 4px;
        font-size: 14px;
        border: 1px solid #7fccf7;
        color: #162d3d;
        width: 100%; }
        .item-edit .item-fields-container .control-text-input input::placeholder {
          color: #b6c1cd; }
        .item-edit .item-fields-container .control-text-input input:disabled {
          border-color: #f0f0f0;
          background-color: #fff; }
          .item-edit .item-fields-container .control-text-input input:disabled::placeholder {
            color: #939393; }
        .item-edit .item-fields-container .control-text-input input.invalid {
          border-color: #ee5951; }
        .item-edit .item-fields-container .control-text-input input:hover {
          background-color: #eaf7ff; }
        .item-edit .item-fields-container .control-text-input input:focus {
          border-color: #3899ec;
          background-color: #fff; }
    .item-edit .item-fields-container .link {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px; }
    .item-edit .item-fields-container .unused-fields-text {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      position: absolute;
      bottom: 32px;
      left: 60px;
      right: 60px;
      color: #2b5672; }

.input-link-to-field .link {
  margin: 0; }

.date-item-field .text-input {
  display: flex;
  align-items: center;
  flex-direction: row; }
  .date-item-field .text-input .control-text-input {
    border-radius: 4px;
    font-size: 14px;
    border: 1px solid #7fccf7;
    color: #162d3d;
    height: 36px;
    padding: 0 12px 0;
    line-height: 36px;
    flex: 1; }
    .date-item-field .text-input .control-text-input::placeholder {
      color: #b6c1cd; }
    .date-item-field .text-input .control-text-input:disabled {
      border-color: #f0f0f0;
      background-color: #fff; }
      .date-item-field .text-input .control-text-input:disabled::placeholder {
        color: #939393; }
    .date-item-field .text-input .control-text-input.invalid {
      border-color: #ee5951; }
    .date-item-field .text-input .control-text-input:hover {
      background-color: #eaf7ff; }
    .date-item-field .text-input .control-text-input:focus {
      border-color: #3899ec;
      background-color: #fff; }
    .date-item-field .text-input .control-text-input svg {
      margin-right: 10px;
      vertical-align: text-top; }
      .date-item-field .text-input .control-text-input svg * {
        fill: #2b5672; }
    .date-item-field .text-input .control-text-input.date-picker-open svg * {
      fill: #3899ec; }
  .date-item-field .text-input .disabled {
    color: #b6c1cd; }
  .date-item-field .text-input .link-to-button {
    padding: 0;
    width: 36px;
    height: 36px;
    color: #162d3d;
    border: 1px solid #7fccf7;
    margin-left: 12px;
    position: relative; }
    .date-item-field .text-input .link-to-button svg {
      width: 16px;
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0; }

.date-item-field .input-link-to {
  margin-top: 12px; }
  .date-item-field .input-link-to > label {
    padding: 0; }
  .date-item-field .input-link-to .link {
    border-radius: 4px;
    font-size: 14px;
    border: 1px solid #7fccf7;
    color: #162d3d;
    flex-direction: row;
    margin-right: 0; }
    .date-item-field .input-link-to .link::placeholder {
      color: #b6c1cd; }
    .date-item-field .input-link-to .link:disabled {
      border-color: #f0f0f0;
      background-color: #fff; }
      .date-item-field .input-link-to .link:disabled::placeholder {
        color: #939393; }
    .date-item-field .input-link-to .link.invalid {
      border-color: #ee5951; }
    .date-item-field .input-link-to .link:hover {
      background-color: #eaf7ff; }
    .date-item-field .input-link-to .link:focus {
      border-color: #3899ec;
      background-color: #fff; }
    .date-item-field .input-link-to .link svg {
      width: 14px;
      position: relative;
      top: 2px; }

.datepicker-popup .wrapper .content {
  padding: 0; }

.image-item-field {
  display: flex; }
  .image-item-field .image-panel {
    position: relative;
    width: 252px;
    height: 146px;
    box-sizing: border-box;
    margin-right: 12px; }
    .image-item-field .image-panel .image-control-action-link {
      background-color: rgba(194, 215, 230, 0.4); }
      .image-item-field .image-panel .image-control-action-link > span {
        background-color: rgba(194, 215, 230, 0.6); }
        .image-item-field .image-panel .image-control-action-link > span > button {
          color: #3899ec; }
    .image-item-field .image-panel > div :after {
      margin: 15px 11px; }
    .image-item-field .image-panel > div :before {
      margin: 15px 11px; }
    .image-item-field .image-panel > div > svg > path {
      fill: #3899ec; }
    .image-item-field .image-panel .button-wrapper button.action {
      margin: 12px 0;
      padding: 0; }
      .image-item-field .image-panel .button-wrapper button.action:nth-of-type(1):nth-last-of-type(2) {
        width: calc(50% - 2 * 8px);
        margin-right: 10px;
        padding: initial; }
        .image-item-field .image-panel .button-wrapper button.action:nth-of-type(1):nth-last-of-type(2) svg {
          width: 13px; }
        .image-item-field .image-panel .button-wrapper button.action:nth-of-type(1):nth-last-of-type(2) + button {
          margin-right: 0;
          padding: initial; }
  .image-item-field .text-panel {
    width: calc(100% - 252px);
    box-sizing: border-box;
    margin-right: 0; }
    .image-item-field .text-panel .control-text-input > label {
      padding: 0;
      color: #2b5672;
      line-height: 16px; }
    .image-item-field .text-panel .control-text-input > input {
      border-radius: 4px;
      font-size: 14px;
      border: 1px solid #7fccf7;
      color: #162d3d; }
      .image-item-field .text-panel .control-text-input > input::placeholder {
        color: #b6c1cd; }
      .image-item-field .text-panel .control-text-input > input:disabled {
        border-color: #f0f0f0;
        background-color: #fff; }
        .image-item-field .text-panel .control-text-input > input:disabled::placeholder {
          color: #939393; }
      .image-item-field .text-panel .control-text-input > input.invalid {
        border-color: #ee5951; }
      .image-item-field .text-panel .control-text-input > input:hover {
        background-color: #eaf7ff; }
      .image-item-field .text-panel .control-text-input > input:focus {
        border-color: #3899ec;
        background-color: #fff; }
    .image-item-field .text-panel .control-text-input.is-disabled > label {
      color: #939393; }
    .image-item-field .text-panel .control-text-input.is-disabled > input {
      color: #939393;
      background-color: #fff; }
    .image-item-field .text-panel .input-link-to {
      margin-top: 12px; }
      .image-item-field .text-panel .input-link-to > .container-action {
        border-radius: 4px;
        font-size: 14px;
        border: 1px solid #7fccf7;
        color: #162d3d;
        padding: 0; }
        .image-item-field .text-panel .input-link-to > .container-action::placeholder {
          color: #b6c1cd; }
        .image-item-field .text-panel .input-link-to > .container-action:disabled {
          border-color: #f0f0f0;
          background-color: #fff; }
          .image-item-field .text-panel .input-link-to > .container-action:disabled::placeholder {
            color: #939393; }
        .image-item-field .text-panel .input-link-to > .container-action.invalid {
          border-color: #ee5951; }
        .image-item-field .text-panel .input-link-to > .container-action:hover {
          background-color: #eaf7ff; }
        .image-item-field .text-panel .input-link-to > .container-action:focus {
          border-color: #3899ec;
          background-color: #fff; }
        .image-item-field .text-panel .input-link-to > .container-action > .control-action-with-symbol {
          padding: 0 11px; }
          .image-item-field .text-panel .input-link-to > .container-action > .control-action-with-symbol:hover {
            color: #162d3d; }
      .image-item-field .text-panel .input-link-to > label {
        padding: 0;
        color: #2b5672; }
    .image-item-field .text-panel:last-child {
      margin-bottom: 0; }
    .image-item-field .text-panel .disabled > .container-action {
      border-color: #f0f0f0; }
      .image-item-field .text-panel .disabled > .container-action > .control-action-with-symbol {
        cursor: default; }
        .image-item-field .text-panel .disabled > .container-action > .control-action-with-symbol .link {
          color: #939393; }
    .image-item-field .text-panel .disabled > label {
      color: #939393; }
  .image-item-field > * {
    display: inline-block; }

.text-item-field .text-input {
  display: flex;
  align-items: center;
  flex-direction: row; }
  .text-item-field .text-input .control-text-input {
    flex: 1; }
  .text-item-field .text-input .link-to-button {
    padding: 0;
    width: 36px;
    height: 36px;
    color: #162d3d;
    border: 1px solid #7fccf7;
    margin-left: 12px;
    position: relative; }
    .text-item-field .text-input .link-to-button svg {
      width: 16px;
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0; }

.text-item-field .input-link-to {
  margin-top: 12px; }
  .text-item-field .input-link-to > .container-action {
    border-radius: 4px;
    font-size: 14px;
    border: 1px solid #7fccf7;
    color: #162d3d;
    padding: 0; }
    .text-item-field .input-link-to > .container-action::placeholder {
      color: #b6c1cd; }
    .text-item-field .input-link-to > .container-action:disabled {
      border-color: #f0f0f0;
      background-color: #fff; }
      .text-item-field .input-link-to > .container-action:disabled::placeholder {
        color: #939393; }
    .text-item-field .input-link-to > .container-action.invalid {
      border-color: #ee5951; }
    .text-item-field .input-link-to > .container-action:hover {
      background-color: #eaf7ff; }
    .text-item-field .input-link-to > .container-action:focus {
      border-color: #3899ec;
      background-color: #fff; }
    .text-item-field .input-link-to > .container-action .control-action-with-symbol {
      padding: 0 11px; }
      .text-item-field .input-link-to > .container-action .control-action-with-symbol:hover {
        color: #162d3d; }
  .text-item-field .input-link-to > label {
    padding: 0; }

.video-item-field .inputs-container {
  display: flex;
  flex-direction: row; }
  .video-item-field .inputs-container > *:first-child {
    margin-right: 12px;
    width: 252px;
    height: 155px; }
  .video-item-field .inputs-container > *:last-child {
    flex: 1; }
  .video-item-field .inputs-container .control-text-input label {
    margin-left: 0; }
  .video-item-field .inputs-container .error-msg-and-button {
    display: flex;
    align-items: center;
    margin-top: 16px; }
    .video-item-field .inputs-container .error-msg-and-button > span {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 12px;
      color: #ee5951;
      flex: 1;
      margin-left: 11px;
      margin-right: 10px; }
    .video-item-field .inputs-container .error-msg-and-button .update-button {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      width: 88px;
      padding: 0;
      height: 24px;
      line-height: 24px;
      color: #fff;
      background: #3899ec;
      font-weight: 400; }
  .video-item-field .inputs-container .button-wrapper button.action {
    margin: 12px 0; }
  .video-item-field .inputs-container.invalid .video-url-input input {
    border: 1px solid #ec4d4d; }
  .video-item-field .inputs-container .symbol-inputValidationError {
    fill: #ee5951; }
  .video-item-field .inputs-container .symbol-inputValidationSuccess {
    fill: #60bc57; }

.rich-text-item-field .rich-text-input-control {
  height: 75px;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid #7fccf7;
  color: #162d3d;
  overflow: hidden; }
  .rich-text-item-field .rich-text-input-control:hover {
    background-color: #eaf7ff; }
  .rich-text-item-field .rich-text-input-control.with-toolbar {
    display: flex;
    flex-direction: column; }
    .rich-text-item-field .rich-text-input-control.with-toolbar .rich-text-content {
      flex: 1;
      overflow: hidden; }
    .rich-text-item-field .rich-text-input-control.with-toolbar .rich-text-toolbar {
      margin-bottom: 0; }
  .rich-text-item-field .rich-text-input-control .rich-text-content {
    margin: 12px; }

.rich-text-item-field.editing .rich-text-input-control {
  height: 225px;
  border-color: #3899ec;
  background-color: #fff;
  overflow: inherit; }

.delete-list-items-confirmation {
  width: 564px; }
  .delete-list-items-confirmation .image,
  .delete-list-items-confirmation .text {
    display: inline-block;
    vertical-align: top; }
  .delete-list-items-confirmation .image {
    width: 132px;
    height: 85px; }
  .delete-list-items-confirmation .text {
    width: 250px;
    margin-left: 30px; }
    .delete-list-items-confirmation .text span:not(:last-child) {
      display: inline-block;
      margin-bottom: 22px; }
  .delete-list-items-confirmation > .content {
    padding: 30px 30px 0 30px; }
  .delete-list-items-confirmation > footer {
    text-align: right;
    position: static;
    padding: 30px; }

.list-load-failed-panel {
  background-color: #fff;
  width: 564px; }
  .list-load-failed-panel .panel-header {
    height: 60px; }
  .list-load-failed-panel .panel-header-title {
    font-size: 20px;
    line-height: 60px; }
  .list-load-failed-panel .content {
    padding: 30px 55px; }
    .list-load-failed-panel .content > * {
      display: inline-block; }
    .list-load-failed-panel .content .error-img {
      width: 76px;
      height: 126px;
      margin-bottom: 30px; }
    .list-load-failed-panel .content .msg-text {
      position: absolute;
      margin-left: 55px;
      line-height: 22px;
      width: 350px; }
    .list-load-failed-panel .content .support-link {
      color: #3899ec;
      text-decoration: none;
      padding: 0 9px; }
      .list-load-failed-panel .content .support-link:hover {
        text-decoration: underline; }
    .list-load-failed-panel .content .control-button.btn-confirm-primary {
      margin-right: 30px; }
  .list-load-failed-panel > footer {
    padding: 0 30px; }

.list-save-failed-panel {
  background-color: #fff;
  width: 564px; }
  .list-save-failed-panel .panel-header {
    height: 60px; }
  .list-save-failed-panel .panel-header-title {
    font-size: 20px;
    line-height: 60px; }
  .list-save-failed-panel .content-wrapper {
    display: flex;
    vertical-align: top;
    padding: 36px 55px 8px; }
    .list-save-failed-panel .content-wrapper > * {
      display: inline-block;
      vertical-align: top; }
    .list-save-failed-panel .content-wrapper .error-img {
      flex: none; }
    .list-save-failed-panel .content-wrapper .msg-text {
      padding-left: 55px;
      line-height: 22px; }
      .list-save-failed-panel .content-wrapper .msg-text p {
        margin: 0; }
    .list-save-failed-panel .content-wrapper .support-link {
      color: #3899ec;
      text-decoration: none; }
      .list-save-failed-panel .content-wrapper .support-link:hover {
        text-decoration: underline; }
  .list-save-failed-panel .btn-confirm-primary {
    bottom: 30px; }
    .list-save-failed-panel .btn-confirm-primary.align-right {
      right: 30px; }

.db-editor-container {
  height: 100%;
  min-width: 900px;
  font-size: 14px;
  position: relative;
  overflow: hidden; }

.list-dbe-frame {
  z-index: 1710; }
  .list-dbe-frame .focus-panel-frame {
    height: 90%;
    position: relative; }
    @media screen and (max-width: 1160px) {
      .list-dbe-frame .focus-panel-frame > header {
        width: 900px; } }
    @media screen and (min-width: 1160px) {
      .list-dbe-frame .focus-panel-frame > header {
        width: 1170px; } }
    .list-dbe-frame .focus-panel-frame > header .panel-header-title {
      font-size: 18px; }
  .list-dbe-frame .content-iframe {
    position: absolute;
    top: 0; }
    .list-dbe-frame .content-iframe:not(.loaded) {
      z-index: -1; }

.text-settings-panel {
  position: relative;
  max-height: 1000px !important; }

.more-styling-options {
  max-height: 400px;
  position: relative; }
  .more-styling-options .fonts-added-indicator {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    text-align: right;
    font-weight: 300;
    position: absolute;
    top: 17px;
    right: -20px;
    opacity: 0;
    visibility: hidden; }
    .more-styling-options .fonts-added-indicator.visible {
      visibility: visible;
      right: 24px;
      opacity: 1;
      transition: right 400ms ease-out 300ms, opacity 220ms ease-out 330ms; }
    .more-styling-options .fonts-added-indicator .fonts-added-text {
      line-height: 21px;
      color: #60bc57; }
  .more-styling-options .reset-style-container {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    text-align: right;
    font-weight: 300; }
    .more-styling-options .reset-style-container > .reset-style-text {
      margin: 17px 25px;
      color: #3899ec;
      line-height: 21px;
      display: inline-block; }
      .more-styling-options .reset-style-container > .reset-style-text:hover {
        cursor: pointer;
        text-decoration: underline; }

.text-theme-settings-section .save-theme-text-indicator {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  text-align: right;
  font-weight: 300;
  position: absolute;
  top: 17px;
  right: -20px;
  opacity: 0;
  visibility: hidden; }
  .text-theme-settings-section .save-theme-text-indicator.visible {
    visibility: visible;
    right: 24px;
    opacity: 1;
    transition: right 400ms ease-out, opacity 220ms ease-out 30ms; }
  .text-theme-settings-section .save-theme-text-indicator > .save-theme-button {
    color: #3899ec;
    line-height: 21px; }
    .text-theme-settings-section .save-theme-text-indicator > .save-theme-button:hover {
      cursor: pointer;
      text-decoration: underline; }
  .text-theme-settings-section .save-theme-text-indicator .save-success-indicator {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    line-height: 21px;
    color: #60bc57; }
  .text-theme-settings-section .save-theme-text-indicator.saving-theme .save-success-indicator {
    visibility: visible;
    opacity: 1;
    transition: opacity 600ms ease-out 100ms; }
  .text-theme-settings-section .save-theme-text-indicator.saving-theme .save-theme-button {
    visibility: hidden; }

.video-layout-panel .thumbnails-with-icons {
  padding: 24px; }

.skype-call-button-layout-panel .smallSize > span:before {
  border-radius: 3px;
  width: 10px;
  height: 10px;
  top: calc(50% - 10px / 2); }

.skype-call-button-layout-panel .mediumSize > span:before {
  border-radius: 3px;
  width: 14px;
  height: 14px;
  top: calc(50% - 14px / 2); }

.skype-call-button-layout-panel .largeSize > span:before {
  border-radius: 3px;
  width: 18px;
  height: 18px;
  top: calc(50% - 18px / 2); }

.skype-call-button-layout-panel .thumbnails-with-icons .image-container {
  border: 1px solid #dbe1e8;
  border-radius: 8px;
  height: 66px;
  margin-top: 18px;
  margin-bottom: 11px; }

.skype-call-button-layout-panel .call-blue .image-container {
  background-image: linear-gradient(45deg, rgba(192, 192, 192, 0.4) 25%, transparent 25%, transparent 75%, rgba(192, 192, 192, 0.4) 75%, rgba(192, 192, 192, 0.4)), linear-gradient(45deg, rgba(192, 192, 192, 0.4) 25%, transparent 25%, transparent 75%, rgba(192, 192, 192, 0.4) 75%, rgba(192, 192, 192, 0.4)), linear-gradient(0deg, rgba(236, 247, 254, 0.9) 0%, rgba(236, 247, 254, 0.9) 100%);
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px; }
  .skype-call-button-layout-panel .call-blue .image-container svg {
    fill: #3899ec; }

.skype-call-button-layout-panel .call-white .image-container {
  background-image: linear-gradient(45deg, rgba(76, 115, 133, 0.5) 25%, transparent 25%, transparent 75%, rgba(76, 115, 133, 0.5) 75%, rgba(76, 115, 133, 0.5)), linear-gradient(45deg, rgba(76, 115, 133, 0.5) 25%, transparent 25%, transparent 75%, rgba(76, 115, 133, 0.5) 75%, rgba(76, 115, 133, 0.5)), linear-gradient(0deg, rgba(92, 134, 152, 0.7) 0%, rgba(92, 134, 152, 0.7) 100%);
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px; }
  .skype-call-button-layout-panel .call-white .image-container svg {
    fill: #fff; }

.skype-call-button-settings-panel .control-text-input .info-icon {
  padding-top: 4px; }

.skype-call-button-settings-panel .control-buttons-group .info-icon {
  right: 5px;
  top: 5px; }

.bg-image-strip-settings-panel .image-control-with-buttons {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; }
  .bg-image-strip-settings-panel .image-control-with-buttons .button-bar {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit; }

.bg-image-strip-settings-panel .image-control-action-link {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; }
  .bg-image-strip-settings-panel .image-control-action-link span {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit; }

.background-media-panel {
  background-color: #fff; }
  .background-media-panel .bg-panel-top {
    position: relative; }
  .background-media-panel .bg-panel-preview {
    height: 174px;
    width: 288px; }
  .background-media-panel .bg-panel-preview-media {
    position: absolute;
    width: 100%;
    height: 174px; }
  .background-media-panel .bg-panel-preview-overlay {
    position: absolute;
    width: 100%;
    height: 174px; }
  .background-media-panel .bg-panel-preview-color {
    width: 44px;
    height: 44px;
    border-bottom-right-radius: 6px;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0; }
    .background-media-panel .bg-panel-preview-color .symbol-fold-clipart {
      width: 50px;
      height: 50px;
      position: relative;
      top: 0;
      left: 0; }
  .background-media-panel .bg-panel-preview-buttons {
    width: 100%;
    height: 120px;
    text-align: center;
    position: absolute; }
    .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button {
      display: inline-block;
      text-align: center;
      vertical-align: top;
      margin-top: 52px;
      padding: 0 4px;
      position: relative; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .symbol-play-video,
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video .symbol-play-video {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: #fff 2px solid;
        box-sizing: border-box;
        background-color: rgba(22, 45, 61, 0.5); }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:hover .symbol-play-video {
        border-color: #4eb7f5; }
        .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:hover .symbol-play-video .st0 {
          fill: #4eb7f5; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:hover .input-play-video:not(:disabled):checked ~ .symbol-play-video {
        border-color: #4eb7f5; }
        .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:hover .input-play-video:not(:disabled):checked ~ .symbol-play-video .st0 {
          fill: transparent; }
        .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:hover .input-play-video:not(:disabled):checked ~ .symbol-play-video .st1 {
          fill: #4eb7f5; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:active .input-play-video:checked ~ .symbol-play-video {
        border-color: #fff !important; }
        .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:active .input-play-video:checked ~ .symbol-play-video .st0 {
          fill: transparent !important; }
        .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:active .input-play-video:checked ~ .symbol-play-video .st1 {
          fill: #fff !important; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:active .symbol-play-video {
        border: #fff 2px solid; }
        .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:active .symbol-play-video .st0 {
          fill: #fff; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video .input-play-video:disabled ~ .symbol-play-video {
        pointer-events: none;
        border-color: #bcbcbc; }
        .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video .input-play-video:disabled ~ .symbol-play-video .st0 {
          fill: #bcbcbc; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video .input-play-video:checked:disabled ~ .symbol-play-video {
        pointer-events: none;
        border-color: #bcbcbc; }
        .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video .input-play-video:checked:disabled ~ .symbol-play-video .st1 {
          fill: #bcbcbc; }
        .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video .input-play-video:checked:disabled ~ .symbol-play-video .st0 {
          fill: transparent; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .symbol-settings-icon,
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-settings-icon .symbol-settings-icon {
        position: absolute;
        left: 5px;
        top: 1px; }
        .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .symbol-settings-icon .st0,
        .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-settings-icon .symbol-settings-icon .st0 {
          fill: #fff; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-settings-icon:hover .symbol-settings-icon .st0 {
        fill: #4eb7f5; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .label-settings-icon,
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-settings-icon .label-settings-icon {
        border-radius: 18px;
        border: #fff 2px solid;
        box-sizing: border-box;
        background-color: rgba(22, 45, 61, 0.5);
        font-size: 14px;
        height: 36px;
        line-height: 30px;
        padding: 0 12px 0 34px;
        color: #fff; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-settings-icon:hover .label-settings-icon {
        border-color: #4eb7f5;
        color: #4eb7f5; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-settings-icon:active .symbol-settings-icon .st0 {
        fill: #fff; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-settings-icon:active .input-settings-icon:checked ~ .symbol-settings-icon .st0 {
        fill: #fff; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-settings-icon:active .label-settings-icon {
        border: #fff 2px solid;
        color: #fff; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-settings-icon:active .input-settings-icon:checked ~ .label-settings-icon {
        border: #fff 2px solid;
        color: #fff; }
      .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .circle-preloader {
        width: 18px;
        height: 36px;
        margin: 0;
        top: 0;
        left: 4px;
        animation-duration: 2s; }
        .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .circle-preloader::before, .background-media-panel .bg-panel-preview-buttons .bg-panel-preview-button .circle-preloader::after {
          color: #fff;
          border-width: 2px;
          animation-duration: 1s; }
  .background-media-panel .bg-panel-top-actions {
    position: absolute;
    bottom: 0;
    height: 54px;
    width: 100%;
    background: rgba(177, 221, 248, 0.4);
    text-align: center; }
  .background-media-panel .bg-panel-action {
    padding: 11px 3px 0 3px;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    box-sizing: border-box; }
    .background-media-panel .bg-panel-action .control-button2.bg-panel-action-btn {
      background-color: #fff;
      height: 32px;
      line-height: 32px;
      width: 82px;
      text-align: center; }
    .background-media-panel .bg-panel-action .control-button2.bg-panel-action-btn .symbol {
      margin-left: -1px; }
      .background-media-panel .bg-panel-action .control-button2.bg-panel-action-btn .symbol .st0 {
        fill: #3899ec; }
    .background-media-panel .bg-panel-action .control-button2.bg-panel-action-btn .label {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 12px;
      color: #3899ec;
      padding: 0 8px 0 0;
      margin-left: -2px; }
    .background-media-panel .bg-panel-action .control-button2.bg-panel-action-btn:hover {
      background-color: #eaf7ff; }
    .background-media-panel .bg-panel-action .control-button2.bg-panel-action-btn input:disabled ~ .label {
      color: #bcbcbc; }
    .background-media-panel .bg-panel-action .control-button2.bg-panel-action-btn[disabled] {
      background-color: #fff; }
    .background-media-panel .bg-panel-action .control-button2.bg-panel-action-btn input:disabled ~ .symbol .st0 {
      fill: #bcbcbc; }
    .background-media-panel .bg-panel-action .control-button2-wrapper {
      padding: 0; }
    .background-media-panel .bg-panel-action:first-child {
      padding-left: 8px; }
    .background-media-panel .bg-panel-action:last-child {
      padding-right: 8px; }
    .background-media-panel .bg-panel-action:first-child:nth-last-child(2),
    .background-media-panel .bg-panel-action:first-child:nth-last-child(2) ~ .bg-panel-action {
      width: 44%; }
    .background-media-panel .bg-panel-action:first-child:nth-last-child(3),
    .background-media-panel .bg-panel-action:first-child:nth-last-child(3) ~ .bg-panel-action {
      width: 33%; }
    .background-media-panel .bg-panel-action:first-child:nth-last-child(4),
    .background-media-panel .bg-panel-action:first-child:nth-last-child(4) ~ .bg-panel-action {
      width: 25%; }
  .background-media-panel .image-control-with-buttons {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; }
    .background-media-panel .image-control-with-buttons .button-bar {
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit; }
  .background-media-panel .image-control-action-link {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; }
    .background-media-panel .image-control-action-link span {
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit; }
  .background-media-panel .scroll-type-thumbs {
    padding-top: 24px; }
  .background-media-panel .background-media-preset-list {
    min-height: 60px;
    max-height: 331px;
    width: 288px;
    box-sizing: border-box; }
    .background-media-panel .background-media-preset-list .background-media-preset-list-sections {
      padding: 20px 24px 0; }
      .background-media-panel .background-media-preset-list .background-media-preset-list-sections.extra-padding {
        padding-bottom: 84px; }
        .background-media-panel .background-media-preset-list .background-media-preset-list-sections.extra-padding .preset-list-item:last-child {
          margin-bottom: 0; }
    .background-media-panel .background-media-preset-list .preset-list-item {
      margin-bottom: 18px; }
  .background-media-panel .background-media-controls {
    border-bottom: 1px solid transparent; }
  .background-media-panel .button-wrapper {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0, white 90%);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    bottom: 0;
    height: 84px;
    width: 100%;
    position: absolute;
    text-align: center; }
    .background-media-panel .button-wrapper .button {
      bottom: -12px;
      position: relative; }

.drop-down-menu-layout-panel .thumbnails-with-icons {
  margin-bottom: 17px;
  margin-top: 21px; }
  .drop-down-menu-layout-panel .thumbnails-with-icons label {
    font-size: 0; }
    .drop-down-menu-layout-panel .thumbnails-with-icons label svg {
      width: 78px;
      max-width: 78px;
      height: 36px;
      max-height: 36px; }

.combobox-manage-items-panel.comp-panel-content {
  height: 525px; }

.combobox-manage-items-panel .control-text-input-with-button {
  margin: 0 12px; }

.combobox-manage-items-panel .list-view-mode .sort-by-drag-list {
  padding-bottom: 126px; }

.combobox-manage-items-panel .list-view-mode .actions {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0, white 90%);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  bottom: 0;
  height: 126px;
  width: 100%;
  position: absolute;
  text-align: center;
  box-sizing: border-box;
  padding-top: 12px;
  padding-bottom: 21px; }
  .combobox-manage-items-panel .list-view-mode .actions .main-button {
    margin: 12px 24px; }
  .combobox-manage-items-panel .list-view-mode .actions .action-item {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    display: block;
    color: #3899ec;
    line-height: 18px;
    cursor: pointer; }

.combo-box-input-settings-panel .control-text-input .info-icon {
  top: 7px;
  right: 11px; }

.combo-box-input-settings-panel .selected-container {
  overflow: hidden;
  height: 36px; }

.combo-box-input-settings-panel .manage-items-link-container {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 13px;
  margin: 14px 24px 14px 24px;
  color: #2b5672;
  margin-bottom: 14px; }
  .combo-box-input-settings-panel .manage-items-link-container a {
    color: #4eb7f5; }
    .combo-box-input-settings-panel .manage-items-link-container a label {
      cursor: pointer; }
    .combo-box-input-settings-panel .manage-items-link-container a :hover {
      text-decoration: underline; }

.header-scroll-panel .scroll-behaviors-thumbnails .illustration-container .header-behavior-illustration {
  position: relative;
  height: 114px;
  width: 114px; }
  .header-scroll-panel .scroll-behaviors-thumbnails .illustration-container .header-behavior-illustration img {
    border-radius: 8px;
    position: absolute;
    left: 0;
    top: 0; }

.radio-group-layout-panel .title {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #2b5672;
  margin: 17px 25px 0 24px;
  display: block; }

.radio-group-layout-panel .input-slider {
  position: relative;
  height: 68px;
  padding-left: 24px;
  padding-top: 17px; }

.radio-group-layout-panel .input-stepper {
  right: 10px; }

.radio-group-layout-panel .layout-container {
  display: flex;
  height: 150px;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 13px 24px; }

.radio-group-manage-items-panel.comp-panel-content {
  height: 525px; }

.radio-group-manage-items-panel .sort-by-drag-list {
  padding-bottom: 114px; }

.radio-group-manage-items-panel .item-list-row {
  height: 65px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid #d9e1e8;
  cursor: move;
  position: relative; }
  .radio-group-manage-items-panel .item-list-row.defaultRadio .radio-text {
    font-family: HelveticaNeueW01-75Bold, HelveticaNeueW02-75Bold, HelveticaNeueW10-75Bold, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  .radio-group-manage-items-panel .item-list-row .radio-text {
    display: inline-block;
    width: 182px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    color: #2b5672; }
  .radio-group-manage-items-panel .item-list-row .radio-value {
    width: 182px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #7a92a5; }
  .radio-group-manage-items-panel .item-list-row .symbol-dragHandle {
    width: 13px;
    height: 13px;
    padding-right: 16px; }
    .radio-group-manage-items-panel .item-list-row .symbol-dragHandle path {
      fill: #7fccf7; }
    .radio-group-manage-items-panel .item-list-row .symbol-dragHandle:hover path {
      fill: #3899ec; }
  .radio-group-manage-items-panel .item-list-row .item-details {
    display: flex;
    flex: 1;
    align-items: center;
    height: 100%; }
    .radio-group-manage-items-panel .item-list-row .item-details.cant-be-drag {
      cursor: auto; }
    .radio-group-manage-items-panel .item-list-row .item-details .control-text-input-with-fixed-button > .control-text {
      user-select: text; }
  .radio-group-manage-items-panel .item-list-row:hover {
    background-color: #eaf7ff; }
    .radio-group-manage-items-panel .item-list-row:hover .quick-actions {
      visibility: visible;
      border: 2px solid #B3E3F9; }
  .radio-group-manage-items-panel .item-list-row .quick-actions {
    position: absolute;
    right: 24px;
    top: 20px;
    border: 0;
    visibility: hidden; }
    .radio-group-manage-items-panel .item-list-row .quick-actions svg {
      position: relative;
      left: 6px;
      top: -7px; }
      .radio-group-manage-items-panel .item-list-row .quick-actions svg .cls-b1 {
        fill: #3899ec; }
    .radio-group-manage-items-panel .item-list-row .quick-actions:hover {
      border: 2px solid #54CCF9; }
  .radio-group-manage-items-panel .item-list-row.expanded {
    background-color: #d3edff; }
    .radio-group-manage-items-panel .item-list-row.expanded .quick-actions.expanded {
      background-color: #3899ec;
      visibility: visible;
      border: 2px solid #3899ec; }
      .radio-group-manage-items-panel .item-list-row.expanded .quick-actions.expanded path {
        fill: #fff; }

.radio-group-manage-items-panel .control-text-input-with-fixed-button {
  flex: 1; }

.radio-group-manage-items-panel .drag-placeholder {
  background-color: #f8f8f8;
  border-top: 1px dashed #d9e1e8; }

.radio-group-manage-items-panel .drag-container {
  background-color: #eaf7ff;
  box-shadow: 0 1px 3.92px 0.08px rgba(45, 86, 114, 0.22); }

.radio-group-manage-items-panel .actions {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0, white 90%);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  bottom: 0;
  height: 126px;
  width: 100%;
  position: absolute;
  text-align: center;
  box-sizing: border-box;
  padding-top: 12px;
  padding-bottom: 21px; }
  .radio-group-manage-items-panel .actions .main-button {
    margin: 12px 24px; }
    .radio-group-manage-items-panel .actions .main-button .button-label {
      margin-left: 5px; }
  .radio-group-manage-items-panel .actions .action-item {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    display: block;
    color: #3899ec;
    line-height: 18px;
    cursor: pointer; }

.text-input-settings-panel .control-text-input .info-icon {
  top: 7px;
  right: 11px; }

.text-input-settings-panel .control-text-input input[type='number'] {
  -moz-appearance: textfield; }

.text-input-settings-panel .control-text-input input[type=number]::-webkit-inner-spin-button,
.text-input-settings-panel .control-text-input input[type=number]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0; }

.text-input-settings-panel .validation-icon-wrapper {
  top: 9px;
  right: 25px; }

.text-input-settings-panel .validation-icon {
  top: 0;
  right: -11px; }

.blog-post-filter span.label-checkbox {
  margin-right: 0;
  font-size: 14px;
  max-width: 199px; }

.twitter-tweet-settings .control-text-input-with-button .control-button {
  display: none !important; }

.anchor-menu-manage-panel .rich-text-paragraph {
  padding-bottom: 4px; }

.anchor-menu-manage-panel .anchors-wrapper {
  margin-bottom: 8px; }

.anchor-menu-manage-panel .anchor-wrapper {
  padding: 0 24px 12px 24px;
  display: flex;
  align-items: center; }
  .anchor-menu-manage-panel .anchor-wrapper .anchor-text-input {
    display: inline-block;
    margin: 0;
    height: auto;
    padding-left: 10px; }
    .anchor-menu-manage-panel .anchor-wrapper .anchor-text-input .anchor-text-inner-input {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px; }

.anchor-menu-manage-panel .control-button2-wrapper {
  padding: 0 30px;
  text-align: left; }

.anchor-menu-manage-panel .add-anchor-row {
  padding: 12px 0; }
  .anchor-menu-manage-panel .add-anchor-row .symbol {
    width: 14px;
    fill: #3899ec;
    margin-right: 15px; }

.multi-select-layout-panel .radio-control svg.disabled {
  cursor: default;
  background-color: #f0f0f0; }
  .multi-select-layout-panel .radio-control svg.disabled:hover {
    background-color: #f0f0f0; }
  .multi-select-layout-panel .radio-control svg.disabled .cls-b3 {
    fill: #d5d5d5; }
  .multi-select-layout-panel .radio-control svg.disabled .st2 {
    fill: #d5d5d5; }
  .multi-select-layout-panel .radio-control svg.disabled .cls-b1 {
    fill: #bcbcbc; }
  .multi-select-layout-panel .radio-control svg.disabled .st1 {
    fill: #bcbcbc; }

.show-popup-on-load {
  display: flex; }
  .show-popup-on-load .control-buttons-group {
    width: 100%; }
    .show-popup-on-load .control-buttons-group .group-buttons-container {
      text-align: center; }

#quickActionBarManagementPanel .comp-panel-content {
  height: 494px;
  min-height: 260px; }

#quickActionBarManagementPanel .button-wrapper {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 126px;
  width: 288px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  position: absolute;
  text-align: center;
  box-sizing: border-box;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0, white 90%); }
  #quickActionBarManagementPanel .button-wrapper .button {
    line-height: 26px; }

#quickActionBarManagementPanel .action-container {
  padding-bottom: 126px;
  min-height: 244px; }

#quickActionBarManagementPanel .dragItemContentBefore {
  width: 40px;
  height: 40px;
  border: 1px solid #d9e1e8;
  border-radius: 50%;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center; }
  #quickActionBarManagementPanel .dragItemContentBefore svg {
    fill: #2b5672; }
  #quickActionBarManagementPanel .dragItemContentBefore.disabled {
    border-color: #ececec; }
    #quickActionBarManagementPanel .dragItemContentBefore.disabled svg {
      fill: #939393; }

#quickActionBarManagementPanel .dragItemAfter {
  margin-top: 5px; }

#quickActionBarManagementPanel .dynamicAction {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  display: flex;
  padding: 12px 0 12px 8px;
  border-bottom: 1px solid #d9e1e8; }
  #quickActionBarManagementPanel .dynamicAction .handle-wrapper {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center; }
    #quickActionBarManagementPanel .dynamicAction .handle-wrapper svg {
      fill: #bcbcbc; }
  #quickActionBarManagementPanel .dynamicAction .symbolWrapper {
    width: 40px;
    height: 40px;
    border: 1px solid #d9e1e8;
    border-radius: 50%;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center; }
    #quickActionBarManagementPanel .dynamicAction .symbolWrapper svg {
      fill: #2b5672; }
  #quickActionBarManagementPanel .dynamicAction .contentWrapper {
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    width: 112px; }
    #quickActionBarManagementPanel .dynamicAction .contentWrapper .label,
    #quickActionBarManagementPanel .dynamicAction .contentWrapper .value {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
    #quickActionBarManagementPanel .dynamicAction .contentWrapper .label {
      font-size: 16px;
      font-weight: 300;
      color: #2b5672; }
    #quickActionBarManagementPanel .dynamicAction .contentWrapper .value {
      font-size: 12px;
      padding-top: 3px;
      color: #7a92a5; }
  #quickActionBarManagementPanel .dynamicAction .content-after {
    flex-grow: 1;
    padding-left: 8px;
    display: flex;
    align-items: center; }

#quickActionBarManagementPanel .sort-by-drag-list {
  -ms-overflow-style: none; }

#quickActionBarManagementPanel .control-button .button-content {
  flex-direction: column; }

#quickActionBarManagementPanel .disabled-after-3 > div > div > div:nth-of-type(4) .sbdl-item-row .view-mode-content .label,
#quickActionBarManagementPanel .disabled-after-3 > div > div > div:nth-of-type(5) .sbdl-item-row .view-mode-content .label {
  color: #939393; }

#quickActionBarManagementPanel .disabled-after-3 > div > div > div:nth-of-type(4) .sbdl-item-row .view-mode-content .value,
#quickActionBarManagementPanel .disabled-after-3 > div > div > div:nth-of-type(5) .sbdl-item-row .view-mode-content .value {
  color: #bcbcbc; }

#quickActionBarManagementPanel .disabled-after-4 > div > div > div:nth-of-type(5) .sbdl-item-row .view-mode-content .label {
  color: #939393; }

#quickActionBarManagementPanel .disabled-after-4 > div > div > div:nth-of-type(5) .sbdl-item-row .view-mode-content .value {
  color: #bcbcbc; }

.qab-more-actions-context-menu-content {
  width: 200px;
  max-height: 400px;
  -ms-overflow-style: none; }

.qab-more-actions-context-menu-symbol {
  fill: #2b5672; }

.divider-short.qab-more-actions-context-menu-divider {
  margin: 6px 18px; }

.manage-columns-panel .controller-section-wrapper {
  margin: 0 24px; }
  .manage-columns-panel .controller-section-wrapper > .actions-container {
    margin-bottom: 24px; }
    .manage-columns-panel .controller-section-wrapper > .actions-container .control-button2-wrapper {
      padding: 0;
      display: inline-block; }

.manage-columns-panel .title {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  margin: 18px 0;
  display: block;
  color: #2b5672;
  text-align: left;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.manage-columns-panel .btn-with-symbol {
  padding-left: 16px; }
  .manage-columns-panel .btn-with-symbol.arrow-rotated-up .symbol {
    transform: rotate(90deg); }
  .manage-columns-panel .btn-with-symbol.arrow-rotated-down .symbol {
    transform: rotate(-90deg); }
  .manage-columns-panel .btn-with-symbol.arrow-rotated-right .symbol {
    transform: rotate(180deg); }
  .manage-columns-panel .btn-with-symbol .label {
    color: #3899ec;
    padding-left: 8px; }
  .manage-columns-panel .btn-with-symbol:hover {
    color: #3899ec;
    background-color: #d3edff; }
  .manage-columns-panel .btn-with-symbol:active {
    color: #3899ec;
    background-color: #eaf7ff; }
  .manage-columns-panel .btn-with-symbol .symbol path {
    fill: #3899ec; }
  .manage-columns-panel .btn-with-symbol[disabled] {
    color: #bcbcbc;
    background-color: #f0f0f0; }
    .manage-columns-panel .btn-with-symbol[disabled] .label {
      color: #bcbcbc; }
    .manage-columns-panel .btn-with-symbol[disabled] .symbol path {
      fill: #bcbcbc; }
  .manage-columns-panel .btn-with-symbol.squared {
    margin-right: 9px;
    border-radius: 6px;
    height: 38px;
    width: 38px;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    vertical-align: middle; }

.members-login-button-settings-panel .site-members-link-container {
  display: flex;
  padding: 18px;
  align-items: flex-start; }
  .members-login-button-settings-panel .site-members-link-container .link {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    flex: 1;
    color: #3899ec;
    margin: -2px 18px 0 12px;
    cursor: pointer; }

.login-social-bar-settings-panel .login-social-bar-link-container {
  display: flex;
  padding: 18px;
  align-items: flex-start; }
  .login-social-bar-settings-panel .login-social-bar-link-container .link {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    flex: 1;
    color: #3899ec;
    margin: -2px 18px 0 12px;
    cursor: pointer; }

.grid-column-manager-panel {
  min-height: 525px; }
  .grid-column-manager-panel.comp-panel-content {
    height: 525px; }
  .grid-column-manager-panel .sort-by-drag-list {
    padding-bottom: 82px; }
  .grid-column-manager-panel .button-row {
    padding-top: 23px;
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0, white 90%); }
  .grid-column-manager-panel .button {
    margin-bottom: 23px; }

.grid-layout-panel .control-thumbnails input + svg .icon-state-selected {
  display: none; }

.grid-layout-panel .control-thumbnails input:checked + svg .icon-state-up {
  display: none; }

.grid-layout-panel .control-thumbnails input:checked + svg .icon-state-selected {
  display: inline-block; }

.mediaPlayer-layout-panel .control-alignment {
  padding: 13px 24px 16px; }

.mediaPlayer-layout-panel .controls-padding {
  padding: 24px; }

.mediaPlayer-Settings-panel .bg-panel-top {
  position: relative; }

.mediaPlayer-Settings-panel .bg-panel-preview {
  height: 155px;
  width: 288px; }

.mediaPlayer-Settings-panel .bg-panel-preview-media {
  position: absolute;
  width: 100%;
  height: 155px; }

.mediaPlayer-Settings-panel .bg-panel-preview-overlay {
  position: absolute;
  width: 100%;
  height: 155px; }

.mediaPlayer-Settings-panel .bg-panel-preview-buttons {
  width: 100%;
  height: 101px;
  text-align: center;
  position: absolute; }
  .mediaPlayer-Settings-panel .bg-panel-preview-buttons .bg-panel-preview-button {
    display: inline-block;
    text-align: center;
    vertical-align: top;
    margin-top: 52px;
    padding: 0 4px;
    position: relative; }
    .mediaPlayer-Settings-panel .bg-panel-preview-buttons .bg-panel-preview-button .symbol-play-video,
    .mediaPlayer-Settings-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video .symbol-play-video {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      border: #fff 2px solid;
      box-sizing: border-box;
      background-color: rgba(22, 45, 61, 0.5); }
    .mediaPlayer-Settings-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:hover .symbol-play-video {
      border-color: #4eb7f5; }
      .mediaPlayer-Settings-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:hover .symbol-play-video .st0 {
        fill: #4eb7f5; }
    .mediaPlayer-Settings-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:hover .input-play-video:not(:disabled):checked ~ .symbol-play-video {
      border-color: #4eb7f5; }
      .mediaPlayer-Settings-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:hover .input-play-video:not(:disabled):checked ~ .symbol-play-video .st0 {
        fill: transparent; }
      .mediaPlayer-Settings-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:hover .input-play-video:not(:disabled):checked ~ .symbol-play-video .st1 {
        fill: #4eb7f5; }
    .mediaPlayer-Settings-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:active .input-play-video:checked ~ .symbol-play-video {
      border-color: #fff !important; }
      .mediaPlayer-Settings-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:active .input-play-video:checked ~ .symbol-play-video .st0 {
        fill: transparent !important; }
      .mediaPlayer-Settings-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:active .input-play-video:checked ~ .symbol-play-video .st1 {
        fill: #fff !important; }
    .mediaPlayer-Settings-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:active .symbol-play-video {
      border: #fff 2px solid; }
      .mediaPlayer-Settings-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:active .symbol-play-video .st0 {
        fill: #fff; }
    .mediaPlayer-Settings-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video .input-play-video:disabled ~ .symbol-play-video {
      pointer-events: none;
      border-color: #bcbcbc; }
      .mediaPlayer-Settings-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video .input-play-video:disabled ~ .symbol-play-video .st0 {
        fill: #bcbcbc; }
    .mediaPlayer-Settings-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video .input-play-video:checked:disabled ~ .symbol-play-video {
      pointer-events: none;
      border-color: #bcbcbc; }
      .mediaPlayer-Settings-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video .input-play-video:checked:disabled ~ .symbol-play-video .st1 {
        fill: #bcbcbc; }
      .mediaPlayer-Settings-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video .input-play-video:checked:disabled ~ .symbol-play-video .st0 {
        fill: transparent; }
    .mediaPlayer-Settings-panel .bg-panel-preview-buttons .bg-panel-preview-button .circle-preloader {
      width: 18px;
      height: 36px;
      margin: 0;
      top: 0;
      left: 4px;
      animation-duration: 2s; }
      .mediaPlayer-Settings-panel .bg-panel-preview-buttons .bg-panel-preview-button .circle-preloader::before, .mediaPlayer-Settings-panel .bg-panel-preview-buttons .bg-panel-preview-button .circle-preloader::after {
        color: #fff;
        border-width: 2px;
        animation-duration: 1s; }

.mediaPlayer-Settings-panel .bg-panel-top-actions {
  position: absolute;
  bottom: 0;
  height: 54px;
  width: 100%;
  background: rgba(177, 221, 248, 0.4);
  text-align: center; }

.mediaPlayer-Settings-panel .bg-panel-action {
  padding: 11px 3px 0 3px;
  display: inline-block;
  text-align: center;
  vertical-align: top;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  box-sizing: border-box; }
  .mediaPlayer-Settings-panel .bg-panel-action .control-button2.bg-panel-action-btn {
    background-color: #fff;
    height: 32px;
    line-height: 32px;
    min-width: 147px;
    max-width: 167px;
    justify-content: center; }
  .mediaPlayer-Settings-panel .bg-panel-action .control-button2.bg-panel-action-btn .symbol {
    margin-left: 14px; }
    .mediaPlayer-Settings-panel .bg-panel-action .control-button2.bg-panel-action-btn .symbol .st0 {
      fill: #3899ec; }
  .mediaPlayer-Settings-panel .bg-panel-action .control-button2.bg-panel-action-btn .label {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    padding: 0 24px 0 12px;
    color: #3899ec; }
  .mediaPlayer-Settings-panel .bg-panel-action .control-button2.bg-panel-action-btn:hover {
    background-color: #eaf7ff; }
  .mediaPlayer-Settings-panel .bg-panel-action .control-button2.bg-panel-action-btn input:disabled ~ .label {
    color: #bcbcbc; }

.mediaPlayer-Settings-panel .control-thumbnails.with-title > div {
  width: 100%; }

.mediaPlayer-Settings-panel .controls-padding {
  padding: 18px 24px 18px 24px; }

.contactform-manage-fields-panel.comp-panel-content {
  height: 525px; }

.contactform-manage-fields-panel .sticky-message {
  border-top: none; }

.contactform-manage-fields-panel .shadow-button-wrapper {
  height: 84px;
  width: 288px; }

.contactform-manage-fields-panel .button-wrapper {
  height: 84px;
  width: 288px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0, white 90%);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  bottom: 0;
  position: absolute;
  text-align: center;
  box-sizing: border-box; }
  .contactform-manage-fields-panel .button-wrapper .menu-source {
    margin: 0 15px; }

.repeater-manage-items-panel {
  height: 525px; }
  .repeater-manage-items-panel .mouse-leave-indicator {
    width: 100%;
    height: 100%; }
    .repeater-manage-items-panel .mouse-leave-indicator .repeater-manage-items-drag-list {
      padding-bottom: 114px; }
  .repeater-manage-items-panel .repeater-manage-items-bottom-button {
    height: 114px; }

.repeater-layout-panel .align-thumbnail {
  width: 51px;
  height: 51px; }
  .repeater-layout-panel .align-thumbnail.left {
    background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-left-static.gif") no-repeat 50% 50%; }
    .repeater-layout-panel .align-thumbnail.left:hover {
      background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-left.gif") no-repeat 50% 50%; }
    .repeater-layout-panel .align-thumbnail.left.selected {
      background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-left-selected-static.gif") no-repeat 50% 50%; }
      .repeater-layout-panel .align-thumbnail.left.selected:hover {
        background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-left-selected.gif") no-repeat 50% 50%; }
  .repeater-layout-panel .align-thumbnail.right {
    background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-right-static.gif") no-repeat 50% 50%; }
    .repeater-layout-panel .align-thumbnail.right:hover {
      background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-right.gif") no-repeat 50% 50%; }
    .repeater-layout-panel .align-thumbnail.right.selected {
      background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-right-selected-static.gif") no-repeat 50% 50%; }
      .repeater-layout-panel .align-thumbnail.right.selected:hover {
        background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-right-selected.gif") no-repeat 50% 50%; }
  .repeater-layout-panel .align-thumbnail.center {
    background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-center-static.gif") no-repeat 50% 50%; }
    .repeater-layout-panel .align-thumbnail.center:hover {
      background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-center.gif") no-repeat 50% 50%; }
    .repeater-layout-panel .align-thumbnail.center.selected {
      background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-center-selected-static.gif") no-repeat 50% 50%; }
      .repeater-layout-panel .align-thumbnail.center.selected:hover {
        background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-center-selected.gif") no-repeat 50% 50%; }
  .repeater-layout-panel .align-thumbnail.justify {
    background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-justify-static.gif") no-repeat 50% 50%; }
    .repeater-layout-panel .align-thumbnail.justify:hover {
      background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-justify.gif") no-repeat 50% 50%; }
    .repeater-layout-panel .align-thumbnail.justify.selected {
      background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-justify-selected-static.gif") no-repeat 50% 50%; }
      .repeater-layout-panel .align-thumbnail.justify.selected:hover {
        background: url("//static.parastorage.com/services/santa-resources/resources/editor/compPanels/repeater/layout-align-icon-justify-selected.gif") no-repeat 50% 50%; }

.language-selector-language-panel .content-after path {
  fill: #939393; }

.language-selector-language-panel .sbdl-item-row.disabled {
  cursor: move;
  pointer-events: auto; }

.language-selector-language-panel .buttons-container {
  padding-top: 15px;
  text-align: center; }
  .language-selector-language-panel .buttons-container button {
    display: block;
    margin: 5px auto; }
  .language-selector-language-panel .buttons-container .btn-text .button-content {
    align-items: center;
    font-size: 14px; }

.toggle-switch-layout-panel .title {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #2b5672;
  margin: 17px 25px 0 24px;
  display: block; }

.toggle-switch-layout-panel .input-slider {
  position: relative;
  height: 68px;
  padding-left: 24px;
  padding-top: 17px; }

.toggle-switch-layout-panel .input-stepper {
  right: 10px; }

.toggle-switch-layout-panel .layout-container {
  display: flex;
  height: 150px;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 13px 24px; }

.animation-panel .comp-panel-content {
  max-height: 545px; }

.animation-panel .control-button.btn-back {
  margin-top: 12px;
  margin-bottom: 12px; }

.animation-panel .control-switch.toggle-play-once {
  margin-top: 20px;
  margin-bottom: 20px; }

.animation-panel .panel-bottom {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 72px;
  text-align: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.7) 0, white 70%); }
  .animation-panel .panel-bottom button {
    margin-top: 20px; }

.animation-panel .customize-mode {
  border-bottom: 1px solid transparent; }

.animation-panel .preview-animation-panel {
  overflow: hidden; }
  .animation-panel .preview-animation-panel .current-animation-details {
    float: left; }
    .animation-panel .preview-animation-panel .current-animation-details .current-animation-symbol {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px; }
    .animation-panel .preview-animation-panel .current-animation-details .current-animation-label {
      display: inline-block;
      color: #2b5672;
      font-size: 14px;
      font-weight: 300;
      text-align: left; }
  .animation-panel .preview-animation-panel .btn-preview-button {
    cursor: pointer;
    border: none;
    background: none;
    outline: 0;
    float: right;
    margin: 25px 23px 0 0;
    padding: 0; }

.behavior-thumbnails {
  width: 252px;
  margin: 0 auto;
  padding: 15px 0 64px; }
  .behavior-thumbnails li {
    display: inline-block;
    margin: 9px 6px;
    width: 72px;
    vertical-align: top; }
    .behavior-thumbnails li .behavior-symbol {
      width: 100%;
      background-color: #eaf7ff;
      border-radius: 8px;
      transition: background-color 0.2s ease-in;
      position: relative;
      font-size: 12px;
      color: #4eb7f5;
      height: 72px;
      cursor: pointer;
      overflow: hidden; }
      .behavior-thumbnails li .behavior-symbol .behavior-icon-preview {
        display: none; }
        .behavior-thumbnails li .behavior-symbol .behavior-icon-preview svg {
          cursor: pointer;
          padding: 0; }
      .behavior-thumbnails li .behavior-symbol .behavior-icon svg {
        cursor: pointer;
        padding: 0; }
      .behavior-thumbnails li .behavior-symbol.selected {
        background: #4eb7f5; }
        .behavior-thumbnails li .behavior-symbol.selected.none {
          background: #eaf7ff; }
        .behavior-thumbnails li .behavior-symbol.selected.playing .behavior-icon {
          display: none; }
        .behavior-thumbnails li .behavior-symbol.selected.playing .behavior-icon-preview {
          display: inherit; }
      .behavior-thumbnails li .behavior-symbol:hover {
        background: #d3edff;
        cursor: pointer; }
        .behavior-thumbnails li .behavior-symbol:hover.selected {
          background: #4eb7f5; }
          .behavior-thumbnails li .behavior-symbol:hover.selected.allow-preview .behavior-icon {
            display: none; }
          .behavior-thumbnails li .behavior-symbol:hover.selected .behavior-icon-preview {
            display: inherit; }
          .behavior-thumbnails li .behavior-symbol:hover.selected.none {
            background: #d3edff; }
            .behavior-thumbnails li .behavior-symbol:hover.selected.none .behavior-icon {
              display: inherit; }
            .behavior-thumbnails li .behavior-symbol:hover.selected.none .behavior-icon-preview {
              display: none; }
    .behavior-thumbnails li .behavior-name {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;
      color: #4eb7f5;
      padding-top: 9px;
      font-size: 12px;
      line-height: 1; }

.panel-dock {
  width: 288px;
  height: 500px; }
  .panel-dock > .content {
    color: #162d3d;
    font-size: 14px; }
  .panel-dock hr {
    background-color: #d9e1e8; }
  .panel-dock .control-dock {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 0;
    margin-bottom: 20px;
    width: 100%; }
  .panel-dock .dock-offset-slider {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 0;
    margin-bottom: 10px;
    margin-top: 15px; }
  .panel-dock .unPinLabel {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 20px;
    margin-bottom: 10px;
    color: #3899ec;
    position: relative;
    cursor: pointer;
    top: 0;
    display: block;
    margin-bottom: 20px; }
    .panel-dock .unPinLabel:hover {
      text-decoration: underline; }
  .panel-dock .disabled {
    cursor: default;
    pointer-events: none; }
  .panel-dock .transparent {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }
    .panel-dock .transparent:hover {
      text-decoration: none; }

.apply-panel .rich-text-paragraph {
  padding: 19px 12px 17px 12px;
  margin: 0 11px; }

.apply-panel .control-button2-wrapper {
  text-align: center; }
  .apply-panel .control-button2-wrapper .control-button2 {
    margin: 24px 0; }
    .apply-panel .control-button2-wrapper .control-button2 .label {
      margin-bottom: 2px;
      padding-left: 12px; }
    .apply-panel .control-button2-wrapper .control-button2 .symbol {
      padding-left: 12px; }

.popup-full-layout-panel .comp-panel-content {
  display: flex; }
  .popup-full-layout-panel .comp-panel-content .control-buttons-group {
    margin: 20px auto 35px; }

.panel-dock {
  width: 288px;
  height: auto; }
  .panel-dock > .content {
    color: #162d3d;
    font-size: 14px; }
  .panel-dock hr {
    background-color: #d9e1e8; }
  .panel-dock .control-dock {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 0;
    margin-bottom: 20px; }
  .panel-dock .dock-offset-slider {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 0;
    margin-bottom: 10px;
    margin-top: 15px; }
  .panel-dock .unPinLabel {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 20px;
    margin-bottom: 10px;
    color: #3899ec;
    position: relative;
    cursor: pointer;
    top: 0;
    display: block;
    margin-bottom: 20px; }
    .panel-dock .unPinLabel:hover {
      text-decoration: underline; }
  .panel-dock .disabled {
    cursor: default;
    pointer-events: none; }
  .panel-dock .transparent {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }
    .panel-dock .transparent:hover {
      text-decoration: none; }

.tractor-plus-section-content .tractor_plus_button {
  line-height: 30px;
  height: 30px;
  padding-left: 40px;
  font-size: 14px;
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  color: #2b5672;
  user-select: none;
  cursor: default; }
  .tractor-plus-section-content .tractor_plus_button:not(.disabled) {
    cursor: pointer; }
    .tractor-plus-section-content .tractor_plus_button:not(.disabled):active {
      font-weight: bold; }
  .tractor-plus-section-content .tractor_plus_button.disabled {
    color: #d3d3d3; }

.dock-input-line {
  position: relative;
  width: 100%;
  height: 40px; }
  .dock-input-line .dock-line-label {
    display: inline-block;
    font-size: 14px;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #2b5672;
    cursor: default;
    height: 40px;
    width: 60px;
    line-height: 40px;
    position: absolute;
    padding-left: 40px; }
  .dock-input-line .input-stepper {
    visibility: hidden;
    position: absolute;
    margin-top: 7px; }
    .dock-input-line .input-stepper.left {
      right: 130px; }
    .dock-input-line .input-stepper.right {
      right: 60px; }
  .dock-input-line .dock-line-input-fields {
    position: absolute;
    left: 100px;
    height: 40px;
    width: calc(100% - 100px); }
  .dock-input-line .cancel-button {
    visibility: hidden;
    position: absolute;
    top: 50%;
    right: 10px;
    width: 16px;
    height: 16px;
    padding: 0;
    border-radius: 50%;
    border: 0;
    margin-top: -6px;
    background-color: #d3edff;
    color: #fff;
    box-sizing: border-box;
    cursor: pointer; }
    .dock-input-line .cancel-button:hover {
      background-color: #eaf7ff; }
    .dock-input-line .cancel-button:focus {
      outline: 0; }
    .dock-input-line .cancel-button svg {
      fill: #3899ec;
      width: 100%;
      height: 100%; }
  .dock-input-line.active .input-stepper {
    visibility: visible; }
  .dock-input-line.active .dock-line-label {
    font-weight: bold; }
  .dock-input-line.active:hover .cancel-button {
    visibility: visible; }
  .dock-input-line.disabled .dock-line-label {
    color: #d3d3d3; }
  .dock-input-line:not(.active):not(.disabled) .dock-line-label {
    cursor: pointer; }
    .dock-input-line:not(.active):not(.disabled) .dock-line-label:hover {
      font-weight: bold; }

.layout-tractor2 {
  position: fixed;
  top: 100px;
  left: 200px;
  width: 320px;
  height: 470px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.94);
  z-index: 1276;
  box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.36); }
  .layout-tractor2 .header-seperator {
    margin: 0 0 9px 0;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #f0f3f5; }
  .layout-tractor2 .seperator {
    margin-top: 9px;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #f0f3f5; }
  .layout-tractor2 .header {
    height: 30px;
    width: 100%;
    background: transparent; }
    .layout-tractor2 .header .title {
      display: block;
      cursor: move;
      left: 48px;
      width: calc(100% - 72px);
      line-height: 30px;
      padding-left: 12px;
      color: #2b5672; }
    .layout-tractor2 .header .drag-zone {
      height: 30px;
      cursor: move;
      background: transparent;
      width: 100%; }
    .layout-tractor2 .header .drag-handle {
      cursor: move;
      left: calc(50% - 6px);
      position: absolute;
      top: 13px;
      color: #2d4150; }
      .layout-tractor2 .header .drag-handle > svg {
        display: block; }
    .layout-tractor2 .header .close {
      float: right;
      margin: 6px 7px 0 0;
      border: 0;
      background: transparent;
      padding: 7px;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0; }
      .layout-tractor2 .header .close > svg {
        fill: #2d4150;
        display: block; }
      .layout-tractor2 .header .close:hover path {
        fill: #3899ec; }
  .layout-tractor2 .section-title {
    font-size: medium;
    display: block;
    left: 48px;
    width: calc(100% - 72px);
    line-height: 48px;
    padding-left: 24px;
    font-weight: bold;
    color: #2b5672; }
  .layout-tractor2 .relative-to-parent-section,
  .layout-tractor2 .relative-to-screen-section,
  .layout-tractor2 .tractor-plus-section,
  .layout-tractor2 .tractor-plus-children-section {
    height: 360px; }
  .layout-tractor2 .layout-tractor-placeholder {
    left: 48px;
    height: 412px;
    line-height: 48px;
    padding-left: 24px;
    font-weight: bold;
    color: #2b5672; }

.filters-panel .filters-list {
  transform: translateZ(0); }

.stretching-slider {
  border-top: 1px solid transparent;
  position: relative;
  padding: 20px 25px 18px 25px; }
  .stretching-slider .title {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    color: #2b5672;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .stretching-slider .info-icon {
    position: absolute;
    top: 12px;
    right: 12px;
    opacity: 0; }
  .stretching-slider:hover .info-icon {
    opacity: 1; }
  .stretching-slider.disabled {
    opacity: 0.5; }
  .stretching-slider:not(.disabled) .slider-container .handle {
    cursor: col-resize; }
    .stretching-slider:not(.disabled) .slider-container .handle:hover, .stretching-slider:not(.disabled) .slider-container .handle.active {
      background: #3899ec; }
      .stretching-slider:not(.disabled) .slider-container .handle:hover:before, .stretching-slider:not(.disabled) .slider-container .handle.active:before {
        background: #3899ec; }
      .stretching-slider:not(.disabled) .slider-container .handle:hover .symbol-graphicSliderHandleDots rect, .stretching-slider:not(.disabled) .slider-container .handle.active .symbol-graphicSliderHandleDots rect {
        fill: #fff; }
  .stretching-slider .slider-container {
    position: relative;
    margin-top: 15px;
    width: 238px;
    height: 96px;
    border-radius: 6px;
    overflow-x: hidden;
    background: #eaf7ff url("//static.parastorage.com/services/santa-resources/resources/editor/baseUI/panelInputs/graphic-slider-fill-pattern.png") repeat; }
    .stretching-slider .slider-container .center-container {
      position: absolute;
      height: 100%;
      background: #4eb7f5; }
      .stretching-slider .slider-container .center-container .handle {
        position: absolute;
        top: calc(50% - 36px / 2);
        width: 12px;
        height: 36px;
        background: #fff;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        transition: background 0.2s;
        display: flex;
        justify-content: center;
        align-items: center; }
        .stretching-slider .slider-container .center-container .handle:before {
          position: absolute;
          content: '';
          height: 96px;
          top: calc(-96px / 2 + 36px / 2);
          left: -1px;
          width: 1px;
          background: #fff;
          transition: background 0.2s; }
        .stretching-slider .slider-container .center-container .handle.left {
          left: 0; }
        .stretching-slider .slider-container .center-container .handle.right {
          right: 0;
          transform: rotateY(180deg); }

.steppers-container {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .steppers-container .text {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    color: #2b5672;
    font-weight: 300;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.stretching-panel .input-slider {
  margin: 24px; }

.tpa-preloader {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #373737;
  width: 100%;
  height: 100%;
  background: #fff;
  text-align: center; }
  .tpa-preloader .circle-preloader {
    margin-top: -40px; }
  .tpa-preloader .text {
    margin-top: 65px; }

.tpa-unresponsive {
  position: absolute;
  top: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 54px 43px;
  background-color: #fff; }
  .tpa-unresponsive header {
    color: #2b5672;
    font-size: 18px;
    font-weight: 300;
    line-height: 18px; }
    .tpa-unresponsive header svg.symbol-alert {
      margin-bottom: -3px; }
  .tpa-unresponsive #textContainer {
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 16px;
    color: #7a92a5;
    margin-top: 10px; }
  .tpa-unresponsive a {
    color: #3899ec;
    cursor: pointer;
    margin: 4px; }
    .tpa-unresponsive a:hover {
      text-decoration: underline; }
  .tpa-unresponsive #dismissButton {
    display: inline-block; }
  .tpa-unresponsive #text {
    line-height: 24px; }
  .tpa-unresponsive img {
    margin-left: 10px; }

.tpa-settings-panel-frame {
  position: fixed;
  z-index: 1320;
  box-shadow: 0 0 18px 0 rgba(22, 45, 61, 0.27);
  border-radius: 10px;
  background-color: #fdfdfd;
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 16px;
  overflow-x: hidden; }
  .tpa-settings-panel-frame.version2 {
    background-color: #fdfdfd;
    overflow: hidden; }
  .tpa-settings-panel-frame .content {
    max-height: calc(100vh - 166px); }
    .tpa-settings-panel-frame .content .tpa-settings-iframe-wrapper {
      padding: 0;
      overflow: hidden; }
      .tpa-settings-panel-frame .content .tpa-settings-iframe-wrapper .tpa-iframe {
        overflow: hidden; }
    .tpa-settings-panel-frame .content .tpa-settings-iframe-wrapper-padding {
      padding: 1px 12px 5px 12px; }
    .tpa-settings-panel-frame .content .tpa-settings-panel-frame-padding {
      padding-bottom: 10px; }

.more {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 13px;
  padding-top: 12px;
  border-top: 1px #d9e1e8 solid;
  margin: 0 20px;
  color: #7a92a5;
  font-weight: 300;
  line-height: 21px;
  text-align: left; }
  .more .symbol-market {
    position: relative;
    left: 3px;
    top: 3px; }
  .more span {
    margin-left: 11px; }
  .more a {
    display: block;
    color: #3899ec;
    cursor: pointer;
    margin-left: 28px; }
    .more a:hover {
      text-decoration: underline; }

.box-app-wrapper {
  cursor: pointer;
  height: 118px;
  width: 328px;
  position: relative;
  display: inline-flex;
  padding-left: 24px; }
  .box-app-wrapper:hover {
    background-color: #eaf7ff; }
  .box-app-wrapper .box-app {
    border-radius: 8px;
    box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.1);
    border: 5px solid #fff;
    background-size: cover;
    background-position: center;
    height: 60px;
    width: 60px;
    float: left;
    position: absolute;
    top: 16px; }
  .box-app-wrapper .content {
    cursor: pointer;
    left: 60px;
    position: relative;
    width: 160px;
    margin: 26px 31px;
    height: 52px; }
    .box-app-wrapper .content .title {
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 15px;
      color: #162d3d;
      margin-bottom: 6px; }
    .box-app-wrapper .content label {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      cursor: pointer;
      color: #2b5672;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 100%; }
  .box-app-wrapper .plus-tooltip {
    position: absolute;
    margin: 31px 0;
    right: 24px;
    top: 17px;
    width: 28px;
    height: 28px; }
  .box-app-wrapper .icon-app {
    right: 23.6px;
    bottom: 23.6px;
    position: absolute; }

.tpaSection .section-header {
  margin: 0 auto; }

.app-market-frame {
  width: 848px;
  height: 100%;
  max-height: 681px; }

.app-market-frame-20 {
  width: 1164px;
  height: 100%; }

.app-market-frame-responsive {
  width: 100%;
  height: 100%; }

.app-market-responsive {
  width: 85%;
  max-width: 1254px;
  min-width: 594px; }

.app-market-panel {
  height: 735px; }

.app-market-panel-20 {
  height: calc(100vh - 96px);
  z-index: 1105; }

.app-market-panel .panel-header, .app-market-panel-20 .panel-header {
  border-bottom: 1px solid #d9e1e8; }

.app-market-panel.left-panel-frame-content, .app-market-panel-20.left-panel-frame-content {
  height: calc(100% - 54px); }
  .app-market-panel.left-panel-frame-content.no-header, .app-market-panel-20.left-panel-frame-content.no-header {
    height: 100%; }

.app-market-app-modal-container {
  width: 906px;
  height: 576px; }

.app-market-permissions-modal-container {
  width: 534px;
  height: 216px; }

.app-market-modal-container {
  width: 100%;
  height: 100%; }

.app-market-frame, .app-market-frame-20, .app-market-app-modal-container, .app-market-permissions-modal-container, .app-market-modal-container {
  background-color: #fff; }

.tpa-settings-modal.focus-panel-frame {
  margin-top: 0; }

.tpa-settings-modal .focus-panel-frame-content-no-header {
  height: calc(100% - 54px); }

.tpa-settings-modal.no-margin {
  border-radius: 0; }
  .tpa-settings-modal.no-margin .focus-panel-frame-content-no-header {
    height: 100%; }

.tpa-settings-modal.bare .focus-panel-frame-content-no-header {
  height: 100%; }

.settings-panel.ui-lib-text-design-panel {
  width: 288px; }
  .settings-panel.ui-lib-text-design-panel .color-picker-header {
    position: relative;
    cursor: move;
    border-bottom: none;
    padding: 0 24px; }
    .settings-panel.ui-lib-text-design-panel .color-picker-header .color-picker-header-content {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 47px;
      line-height: 47px;
      margin-right: 21px; }
    .settings-panel.ui-lib-text-design-panel .color-picker-header .btn-back {
      text-align: left;
      padding-left: 9px;
      width: auto;
      margin-left: 0; }
      .settings-panel.ui-lib-text-design-panel .color-picker-header .btn-back > svg {
        transform: translateY(-50%);
        margin-right: 12px; }
        .settings-panel.ui-lib-text-design-panel .color-picker-header .btn-back > svg > path {
          color: #5c99e9; }
    .settings-panel.ui-lib-text-design-panel .color-picker-header .close-panel-button {
      position: absolute;
      top: calc(50% - (21px / 2));
      right: 14px;
      width: 21px;
      height: 21px;
      border-radius: 50%;
      background-color: #d3edff;
      cursor: pointer; }
      .settings-panel.ui-lib-text-design-panel .color-picker-header .close-panel-button svg {
        width: 21px;
        height: 21px;
        fill: #3899ec; }
      .settings-panel.ui-lib-text-design-panel .color-picker-header .close-panel-button:hover {
        background-color: #eaf7ff; }
  .settings-panel.ui-lib-text-design-panel .style-options {
    width: 130px;
    padding: 20px 24px; }
    .settings-panel.ui-lib-text-design-panel .style-options .icon-button-container {
      margin-right: 10px; }
  .settings-panel.ui-lib-text-design-panel .text-style-dd .style-option-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }

.tpa-base-message-panel.section-already-installed .message-panel-frame .content-wrapper {
  width: 510px;
  padding-bottom: 3px; }
  .tpa-base-message-panel.section-already-installed .message-panel-frame .content-wrapper .icon {
    width: 84px;
    justify-content: initial; }
    .tpa-base-message-panel.section-already-installed .message-panel-frame .content-wrapper .icon img {
      width: 60px;
      height: 60px;
      border-radius: 8px; }
  .tpa-base-message-panel.section-already-installed .message-panel-frame .content-wrapper .textual {
    width: calc(100% - 108px);
    line-height: initial; }

.tpa-base-message-panel .message-panel-frame .content-wrapper {
  display: inline-block;
  vertical-align: top;
  width: 564px;
  box-sizing: border-box;
  padding: 24px 24px 4px; }
  .tpa-base-message-panel .message-panel-frame .content-wrapper .icon {
    width: 150px;
    height: initial;
    float: left;
    margin-top: 4px;
    display: flex;
    justify-content: center; }
    .tpa-base-message-panel .message-panel-frame .content-wrapper .icon .symbol-plaster {
      margin-left: -20px; }
  .tpa-base-message-panel .message-panel-frame .content-wrapper .textual {
    width: calc(100% - 180px);
    float: left;
    margin-right: 0;
    line-height: 24px; }
    .tpa-base-message-panel .message-panel-frame .content-wrapper .textual p {
      margin-bottom: 12px;
      margin-top: 0; }
      .tpa-base-message-panel .message-panel-frame .content-wrapper .textual p a:hover {
        text-decoration: underline; }

.tpa-base-message-panel .message-panel-frame button.btn-confirm-primary {
  height: 30px; }

.tpa-base-message-panel.tpa-cannot-delete-section .message-panel-frame .content-wrapper {
  width: 564px; }
  .tpa-base-message-panel.tpa-cannot-delete-section .message-panel-frame .content-wrapper .icon {
    width: 150px; }
  .tpa-base-message-panel.tpa-cannot-delete-section .message-panel-frame .content-wrapper .textual {
    width: calc(100% - 174px); }

.tpa-new-page-added {
  width: 498px;
  background: #fff;
  border-radius: 10px; }
  .tpa-new-page-added .content {
    padding: 76px 48px 0;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    font-size: 16px;
    font-weight: 300;
    color: #162d3d;
    line-height: 22px;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
    .tpa-new-page-added .content .ok-btn {
      margin: 13px 0 48px;
      float: right; }
      .tpa-new-page-added .content .ok-btn button {
        height: 30px;
        line-height: 30px;
        padding: 0 22px; }
  .tpa-new-page-added .panel-header {
    padding: 0;
    height: 167px; }
    .tpa-new-page-added .panel-header.close {
      top: 19px;
      height: 25px; }
    .tpa-new-page-added .panel-header button.close {
      position: absolute;
      margin-top: 0;
      top: 18px;
      right: 18px;
      z-index: 1; }
    .tpa-new-page-added .panel-header .panel-header-title {
      max-width: 100%;
      white-space: normal;
      text-align: center;
      font-size: 20px;
      padding-top: 36px;
      margin-left: -24px; }
      .tpa-new-page-added .panel-header .panel-header-title .title-text {
        color: #fff;
        font-weight: 300;
        font-size: 20px;
        line-height: 31px;
        display: inline-block;
        margin-top: 7px;
        width: 84%; }
      .tpa-new-page-added .panel-header .panel-header-title .bold {
        font-weight: bold; }
    .tpa-new-page-added .panel-header .symbol-newPageAdded {
      position: absolute;
      height: 129px;
      width: 176px;
      left: calc(50% - 88px);
      top: 115px; }

.tpa-delete-confirmation-wrapper .confirmation-panel-frame.important-confirmation {
  position: relative;
  overflow: hidden;
  background-color: transparent;
  width: 574px; }
  .tpa-delete-confirmation-wrapper .confirmation-panel-frame.important-confirmation > .content {
    margin: 0;
    padding: 24px 24px 0 24px;
    background-color: #fff; }
    .tpa-delete-confirmation-wrapper .confirmation-panel-frame.important-confirmation > .content .content-wrapper {
      width: 100%; }
      .tpa-delete-confirmation-wrapper .confirmation-panel-frame.important-confirmation > .content .content-wrapper .icon {
        width: 150px;
        height: initial;
        float: left;
        text-align: center; }
        .tpa-delete-confirmation-wrapper .confirmation-panel-frame.important-confirmation > .content .content-wrapper .icon .symbol-deleteConfirmationIcon {
          margin-left: -20px; }
      .tpa-delete-confirmation-wrapper .confirmation-panel-frame.important-confirmation > .content .content-wrapper > .content {
        width: calc(100% - 173px);
        float: left;
        margin-right: 0;
        color: #162d3d; }
        .tpa-delete-confirmation-wrapper .confirmation-panel-frame.important-confirmation > .content .content-wrapper > .content p {
          margin-bottom: 12px;
          margin-top: 0; }
          .tpa-delete-confirmation-wrapper .confirmation-panel-frame.important-confirmation > .content .content-wrapper > .content p a:hover {
            text-decoration: underline; }
        .tpa-delete-confirmation-wrapper .confirmation-panel-frame.important-confirmation > .content .content-wrapper > .content :last-child {
          margin-bottom: 0; }
  .tpa-delete-confirmation-wrapper .confirmation-panel-frame.important-confirmation > footer {
    background-color: #fff;
    position: static;
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    text-align: right; }
    .tpa-delete-confirmation-wrapper .confirmation-panel-frame.important-confirmation > footer > button {
      margin-right: 18px; }
      .tpa-delete-confirmation-wrapper .confirmation-panel-frame.important-confirmation > footer > button:last-child {
        margin-right: 0; }

.add-ecom {
  width: 498px;
  background: #fff;
  border-radius: 10px; }
  .add-ecom .content {
    padding: 57px 26px 0;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    color: #162d3d;
    line-height: 22px;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
    .add-ecom .content .ok-btn {
      margin: 13px 0 20px;
      float: right; }
      .add-ecom .content .ok-btn button {
        height: 30px;
        line-height: 30px;
        padding: 0 22px; }
  .add-ecom .panel-header {
    padding: 0;
    height: 57px; }
    .add-ecom .panel-header.close {
      top: 19px;
      height: 25px; }
    .add-ecom .panel-header button.close {
      position: absolute;
      margin-top: 0;
      top: 18px;
      right: 18px;
      z-index: 1; }
    .add-ecom .panel-header .panel-header-title {
      max-width: 100%;
      white-space: normal;
      text-align: center;
      font-size: 20px;
      padding-top: 36px;
      margin-left: -24px; }
      .add-ecom .panel-header .panel-header-title .title-text {
        color: #fff;
        font-weight: 300;
        font-size: 20px;
        line-height: 31px;
        display: inline-block;
        margin-top: 7px;
        width: 84%; }
      .add-ecom .panel-header .panel-header-title .bold {
        font-weight: bold; }
    .add-ecom .panel-header .symbol-newPageAdded {
      position: absolute;
      height: 71px;
      width: 176px;
      left: calc(50% - 88px);
      top: 13px; }

.benefits-modal {
  width: 498px; }
  .benefits-modal .custom-header {
    height: 83px;
    background-color: #3899ec; }
    .benefits-modal .custom-header .panel-header-title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
      color: #fff;
      font-size: 24px;
      position: relative;
      top: -28px;
      max-width: calc(100% - 85px);
      margin: 0 auto; }
    .benefits-modal .custom-header .symbol-messageConfetti {
      position: absolute;
      left: calc(50% - 93px);
      top: 92px; }
    .benefits-modal .custom-header .symbol-messageShadow {
      position: absolute;
      left: calc(50% - 44px);
      top: 146px; }
    .benefits-modal .custom-header .app-icon {
      height: 60px;
      width: 60px;
      background-size: contain;
      border-radius: 9px;
      position: absolute;
      left: calc(50% - 30px);
      top: 98px;
      box-shadow: 0 0 10px #fff;
      background-color: #fff; }
  .benefits-modal .content {
    text-align: center;
    background-color: #fff;
    padding-bottom: 40px; }
    .benefits-modal .content .benefit-list {
      color: #2b5672;
      margin: 65px 0 0;
      font-size: 16px;
      display: inline-block;
      max-width: 80%; }
      .benefits-modal .content .benefit-list li {
        position: relative;
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        line-height: 20px;
        text-align: left;
        margin-bottom: 11px; }
      .benefits-modal .content .benefit-list svg {
        position: absolute;
        top: 7px; }
      .benefits-modal .content .benefit-list span {
        display: inline-block;
        margin-left: 28px; }
    .benefits-modal .content .price {
      font-size: 18px;
      text-align: center;
      margin: 34px 0; }
    .benefits-modal .content .actions {
      text-align: center; }
      .benefits-modal .content .actions .btn-later {
        background-color: #eedbf4;
        color: #aa4dc8;
        margin-right: 18px; }
        .benefits-modal .content .actions .btn-later:hover {
          background-color: #e5c9ee; }
        .benefits-modal .content .actions .btn-later:active {
          background-color: #eedbf4; }
      .benefits-modal .content .actions .btn-upgrade {
        background-color: #aa4dc8; }
        .benefits-modal .content .actions .btn-upgrade:hover {
          background-color: #bb71d3; }
        .benefits-modal .content .actions .btn-upgrade:active {
          background-color: #aa4dc8; }

.base-tpa-panel {
  position: relative; }
  .base-tpa-panel .x-button {
    user-select: none;
    position: fixed;
    right: 0;
    top: 0;
    width: 9px;
    height: 10px;
    line-height: 4px;
    padding: 20px;
    cursor: pointer;
    z-index: 1; }

.picker-header {
  height: 74px;
  width: 100%;
  border-bottom: 1px solid #dad9d9;
  background-color: #fff;
  border-top: 12px solid #fff;
  box-sizing: border-box; }
  .picker-header .app-logo {
    height: 32px;
    width: 32px;
    background-size: contain;
    margin: 7px 0 0 24px;
    display: inline-block; }
  .picker-header .app-textual-info {
    position: absolute;
    top: 21px;
    left: 70px;
    border-left: 1px solid #dfdfdf;
    padding-left: 14px;
    height: 32px;
    color: #555;
    font-size: 13px;
    width: 170px; }
    .picker-header .app-textual-info .app-name {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      position: absolute;
      top: 0; }
    .picker-header .app-textual-info .app-owner {
      position: absolute;
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      bottom: 0; }
  .picker-header .upgrade-text {
    position: absolute;
    top: 9px;
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #363636;
    line-height: 62px; }

.panel-header.cycle-picker {
  height: 84px; }
  .panel-header.cycle-picker .close {
    top: 29px;
    right: 19px; }
  .panel-header.cycle-picker .app-logo {
    height: 46px;
    width: 46px;
    background-size: contain;
    margin: 19px 19px 19px 24px;
    display: inline-block;
    border-radius: 7px;
    position: relative;
    box-shadow: 0 0 26px 2px rgba(255, 255, 255, 0.32); }
  .panel-header.cycle-picker .panel-header-title {
    display: inline-block;
    max-width: 400px;
    height: 84px;
    line-height: 84px;
    position: absolute;
    padding-left: 0; }
  .panel-header.cycle-picker span {
    position: relative; }

.cycles-modal-tooltip-content-wrapper {
  max-width: 280px;
  display: inline-block;
  white-space: initial; }

.cycles-modal {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  height: 663px;
  width: 757px; }
  .cycles-modal .wrapper {
    background-color: #fff;
    height: 582px; }
    .cycles-modal .wrapper .tableWrapper {
      width: 713px;
      padding: 44px 44px 44px 0;
      background-color: #ffffff;
      display: inline-block; }
      .cycles-modal .wrapper .tableWrapper div,
      .cycles-modal .wrapper .tableWrapper span {
        box-sizing: border-box; }
      .cycles-modal .wrapper .tableWrapper .header {
        color: #162d3c;
        vertical-align: bottom;
        text-align: right; }
        .cycles-modal .wrapper .tableWrapper .header .free {
          width: 172px;
          height: 46px;
          text-align: center;
          display: inline-block;
          border: 1px solid #d9e1e8;
          border-right: none;
          border-radius: 5px 0 0 0;
          font-size: 16px;
          line-height: 46px; }
        .cycles-modal .wrapper .tableWrapper .header .premium {
          width: 288px;
          height: 66px;
          text-align: center;
          display: inline-block;
          border: 1px solid #aa4dc8;
          border-bottom: none;
          border-radius: 5px 5px 0 0;
          font-size: 20px;
          line-height: 66px;
          position: relative; }
          .cycles-modal .wrapper .tableWrapper .header .premium:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            bottom: 0;
            border-bottom: 1px solid #d9e1e8; }
      .cycles-modal .wrapper .tableWrapper .feature-table-wrapper {
        display: block;
        width: calc(100% + 20px);
        padding-right: 20px; }
        .cycles-modal .wrapper .tableWrapper .feature-table-wrapper .feature {
          text-align: right;
          vertical-align: middle;
          height: 36px;
          line-height: 36px;
          overflow: hidden; }
          .cycles-modal .wrapper .tableWrapper .feature-table-wrapper .feature > * {
            height: 100%;
            float: right;
            font-size: 14px;
            font-weight: 500; }
          .cycles-modal .wrapper .tableWrapper .feature-table-wrapper .feature .symbol {
            padding: 12px; }
          .cycles-modal .wrapper .tableWrapper .feature-table-wrapper .feature .title {
            width: 212px;
            font-size: 13px;
            font-weight: normal;
            color: #2b5672;
            text-align: right;
            padding-right: 23px;
            cursor: default; }
          .cycles-modal .wrapper .tableWrapper .feature-table-wrapper .feature .basic {
            width: 172px;
            height: 36px;
            border: solid #d9e1e8;
            border-width: 0 0 1px 1px;
            text-align: center; }
          .cycles-modal .wrapper .tableWrapper .feature-table-wrapper .feature .premium {
            width: 288px;
            border-style: solid;
            border-color: #aa4dc8;
            border-width: 0 1px 0 1px;
            text-align: center;
            position: relative; }
            .cycles-modal .wrapper .tableWrapper .feature-table-wrapper .feature .premium:after {
              content: '';
              display: block;
              position: absolute;
              width: 100%;
              bottom: 0;
              border-bottom: 1px solid #d9e1e8; }
        .cycles-modal .wrapper .tableWrapper .feature-table-wrapper .feature-display:nth-child(n+8) {
          display: none; }
      .cycles-modal .wrapper .tableWrapper .cycle-modal-footer {
        margin-top: -1px;
        position: relative;
        height: 176px; }
        .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade {
          float: right;
          width: 288px;
          height: 174px;
          text-align: center;
          border: 1px solid #aa4dc8;
          border-top: none;
          border-radius: 0 0 5px 5px;
          position: relative; }
          .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            top: 0;
            border-bottom: 1px solid #d9e1e8; }
          .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options {
            width: 100%;
            display: flex;
            justify-content: center; }
            .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options label {
              text-align: center;
              cursor: pointer;
              display: inline-block;
              max-width: 50%;
              margin: 20px 0 12px 0; }
              .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options label:nth-child(2):last-child {
                margin-left: 20px; }
            .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options input {
              display: none;
              font-size: initial; }
              .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options input + div {
                box-sizing: border-box;
                border-radius: 5px;
                border: 1px solid #d3edff;
                background-color: #fff;
                min-height: 90px;
                width: 112px;
                position: relative; }
                .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options input + div .text-node {
                  font-size: 14px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden; }
                .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options input + div .circle {
                  border-radius: 50%;
                  border: 1px solid #d3edff;
                  background-color: #fff;
                  width: 20px;
                  height: 20px;
                  position: absolute;
                  top: -10px;
                  left: -10px; }
                  .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options input + div .circle svg {
                    display: none;
                    margin: 5px; }
                .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options input + div .title {
                  color: #162d3d;
                  font-size: 14px;
                  font-weight: 300;
                  text-align: center;
                  margin-top: 8px; }
                .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options input + div .save {
                  color: #aa4dc8;
                  font-size: 12px;
                  font-weight: 500;
                  text-align: center;
                  margin-top: 4px; }
              .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options input:not(:checked) + div:hover {
                background-color: #eaf7ff; }
                .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options input:not(:checked) + div:hover .circle {
                  background-color: #eaf7ff; }
                  .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options input:not(:checked) + div:hover .circle svg {
                    display: none; }
              .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options input:checked + div {
                background-color: #eaf7ff; }
                .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options input:checked + div .circle {
                  background-color: #eaf7ff; }
                  .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options input:checked + div .circle svg {
                    display: block; }
            .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options .price {
              font-size: 16px;
              color: #162d3d;
              text-align: center;
              font-weight: 500;
              display: inline-block;
              left: 9px;
              position: absolute;
              width: 100%; }
              .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options .price .currency {
                font-size: 10px;
                top: -11px;
                display: inline-block;
                position: relative;
                margin-right: 4px; }
              .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options .price .price-whole-number {
                font-size: 24px;
                line-height: 24px; }
              .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options .price .price-fragment-number {
                width: 13px;
                top: -13px;
                display: inline-block;
                position: relative;
                font-size: 10px; }
              .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options .price .month {
                position: relative;
                left: -17px;
                font-size: 12px;
                margin-left: 8px; }
            .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options .price-monthly,
            .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade .cycle-options .price-yearly {
              top: 60px; }
        .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .basic-bottom-border {
          float: right;
          height: 1px;
          width: 172px;
          box-sizing: border-box;
          border-top: 1px solid #d9e1e8; }
        .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .features {
          color: #3899ec;
          font-size: 14px;
          font-weight: 300;
          line-height: 40px;
          text-align: right;
          box-sizing: border-box;
          position: absolute;
          cursor: pointer;
          width: 253px;
          padding-right: 22px; }
          .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .features svg {
            margin-left: 10px;
            margin-bottom: 2px; }
        .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade-yearly-btn {
          background-color: #aa4dc8;
          border-radius: 4px;
          height: 38px;
          width: 137px;
          box-shadow: 1px 2px 0 0 #9936ba;
          cursor: pointer;
          padding: 0 18px;
          max-width: 193px; }
          .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade-yearly-btn:hover {
            background-color: #b85ad6; }
          .cycles-modal .wrapper .tableWrapper .cycle-modal-footer .upgrade-yearly-btn:active {
            background-color: #aa4dc8; }
      .cycles-modal .wrapper .tableWrapper .cancel-btn {
        bottom: 42px;
        left: 45px;
        color: #3899ec;
        font-size: 16px;
        box-sizing: border-box;
        width: 50px;
        height: 22px;
        position: absolute;
        cursor: pointer; }
        .cycles-modal .wrapper .tableWrapper .cancel-btn:hover {
          text-decoration: underline; }

.package-picker-modal-tooltip-content-wrapper {
  max-width: 295px;
  display: block;
  padding: 24px;
  width: initial; }

.package-picker-container {
  font-smoothing: initial;
  width: 870px;
  background-repeat: no-repeat;
  border-radius: 5px;
  letter-spacing: 0.5px; }
  .package-picker-container .packages {
    height: 502px;
    width: 100%;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    background-color: #fff;
    text-align: center;
    padding: 44px 0 0;
    z-index: 0; }
    .package-picker-container .packages button {
      color: #fff;
      background-color: #aa4dc8;
      margin: 10px auto 0;
      width: 137px;
      height: 38px;
      font-size: 15px;
      text-align: center;
      border-radius: 4px;
      font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      border: none;
      letter-spacing: 0.5px;
      line-height: 39px;
      box-shadow: 0 2px 0 #9936ba; }
      .package-picker-container .packages button span {
        margin-top: -2px;
        display: block; }
      .package-picker-container .packages button:focus {
        outline: none; }
      .package-picker-container .packages button:hover {
        background-color: #bf60d6;
        cursor: pointer; }
    .package-picker-container .packages .radio-button {
      border: 1px solid #5C5A5A;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      display: inline-block; }
      .package-picker-container .packages .radio-button:hover {
        cursor: pointer; }
    .package-picker-container .packages .selected .radio-button:after {
      content: '';
      height: 8px;
      width: 8px;
      margin: 1px;
      background-color: #aa4dc8;
      border-radius: 50px;
      display: block; }
    .package-picker-container .packages .packages-wrapper {
      direction: rtl;
      background-color: #ffffff;
      display: inline-block; }
    .package-picker-container .packages .package {
      position: relative;
      width: 193px;
      display: inline-block;
      vertical-align: top;
      margin-bottom: 44px;
      direction: ltr; }
      .package-picker-container .packages .package .plane {
        font-size: 13px;
        border: 1px solid #dad9d9;
        border-width: 0 1px 1px 1px; }
        .package-picker-container .packages .package .plane.yearly {
          background-color: #fff;
          height: 210px; }
        .package-picker-container .packages .package .plane.monthly {
          background-color: #fafafa;
          height: 0;
          border-bottom-width: 0;
          transition: height 0s;
          overflow: hidden; }
          .package-picker-container .packages .package .plane.monthly .price {
            margin-bottom: 21px; }
          .package-picker-container .packages .package .plane.monthly.monthly-open {
            overflow: inherit;
            height: 164px;
            border-bottom-width: 1px; }
          .package-picker-container .packages .package .plane.monthly.no-cycles {
            height: 210px;
            background-color: #fff; }
        .package-picker-container .packages .package .plane .original-price {
          color: #868686;
          text-decoration: line-through;
          padding: 4px; }
          .package-picker-container .packages .package .plane .original-price .currency {
            font-size: 12px; }
        .package-picker-container .packages .package .plane .you-save {
          color: #449fed;
          margin-top: 2px;
          font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
          .package-picker-container .packages .package .plane .you-save .discount-percent {
            margin-left: 3px; }
        .package-picker-container .packages .package .plane .best-selling-feature {
          font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          letter-spacing: 0.5px;
          padding: 5px;
          border: solid #ececec;
          border-width: 1px 0;
          width: 80%;
          margin: 11px auto 14px;
          color: #363636; }
        .package-picker-container .packages .package .plane .select-package button {
          display: none; }
        .package-picker-container .packages .package .plane .select-package.selected button {
          display: block; }
        .package-picker-container .packages .package .plane.disabled .select-package button,
        .package-picker-container .packages .package .plane.disabled .select-package .radio-button {
          display: none; }
      .package-picker-container .packages .package header {
        margin-top: 9px;
        height: 67px;
        line-height: 85px;
        color: #fff;
        background-color: #449fed; }
        .package-picker-container .packages .package header .package-name {
          font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-size: 19px;
          letter-spacing: 0.3px;
          line-height: 67px; }
      .package-picker-container .packages .package:last-child {
        right: -1px; }
        .package-picker-container .packages .package:last-child header {
          border-radius: 5px 0 0 0; }
        .package-picker-container .packages .package:last-child .plane {
          position: relative; }
        .package-picker-container .packages .package:last-child .features {
          border-left: 1px solid #dfdfdf; }
      .package-picker-container .packages .package.popular {
        z-index: 1;
        box-shadow: rgba(140, 138, 138, 0.4) 0 0 6px;
        width: 226px;
        border-radius: 5px 5px 0 0; }
        .package-picker-container .packages .package.popular .plane .you-save {
          color: #aa4dc8; }
        .package-picker-container .packages .package.popular header {
          margin-top: 0;
          padding-top: 9px;
          background-color: #aa4dc8;
          border-radius: 5px 5px 0 0;
          position: relative; }
          .package-picker-container .packages .package.popular header .badge {
            display: block;
            width: 61px;
            height: 61px;
            background-position: 0 -72px;
            position: absolute;
            top: -4px;
            right: -4px; }
            .package-picker-container .packages .package.popular header .badge .badge-caption {
              font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
              font-weight: bold;
              display: block;
              transform: rotate(45deg);
              position: absolute;
              line-height: 17px;
              height: 17px;
              width: 85px;
              right: -20px;
              top: 16px;
              color: #000;
              font-size: 9.5px;
              letter-spacing: 0.5px; }
        .package-picker-container .packages .package.popular .features {
          width: 224px; }
      .package-picker-container .packages .package:first-child {
        left: -1px; }
        .package-picker-container .packages .package:first-child header {
          border-radius: 0 5px 0 0; }
      .package-picker-container .packages .package .features {
        border-right: 1px solid #dfdfdf;
        border-left: 1px solid #dfdfdf; }
        .package-picker-container .packages .package .features .feature {
          height: 34px;
          line-height: 34px;
          padding: 0 10px;
          background-color: #fff;
          color: #363636;
          font-size: 12px;
          position: relative; }
          .package-picker-container .packages .package .features .feature:nth-child(even) {
            background-color: #f1f6fb; }
          .package-picker-container .packages .package .features .feature.highlighted {
            font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
            font-weight: bold; }
          .package-picker-container .packages .package .features .feature:last-child {
            border-bottom: 1px solid #dfdfdf; }
      .package-picker-container .packages .package .price {
        font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        color: #393939;
        font-size: 13px;
        padding: 19px 0 0 17px; }
        .package-picker-container .packages .package .price .currency {
          vertical-align: top;
          line-height: 32px;
          margin-right: 3px; }
        .package-picker-container .packages .package .price .yearly-per-month-whole {
          font-size: 38px;
          letter-spacing: -1.2px; }
        .package-picker-container .packages .package .price .yearly-per-month-fragment {
          width: 13px;
          top: -17px;
          display: inline-block;
          position: relative;
          margin-left: 4px; }
        .package-picker-container .packages .package .price .per-month {
          position: relative;
          left: -11px; }
      .package-picker-container .packages .package.plane-cycles {
        position: relative;
        display: none;
        width: 162px;
        margin-top: 75px;
        vertical-align: top;
        margin-right: -1px;
        z-index: 1; }
        .package-picker-container .packages .package.plane-cycles .best-value-badge {
          font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-size: 10px;
          color: #0d0d0d;
          position: absolute;
          height: 39px;
          width: 57px;
          top: -8px;
          left: 15px;
          z-index: 1; }
          .package-picker-container .packages .package.plane-cycles .best-value-badge > svg {
            position: absolute;
            left: 0; }
          .package-picker-container .packages .package.plane-cycles .best-value-badge > div {
            position: absolute;
            text-align: center;
            overflow: hidden;
            height: 100%;
            margin-left: 13%;
            margin-top: 9%;
            font-weight: bold; }
        .package-picker-container .packages .package.plane-cycles .cycle-title {
          font-size: 36px;
          margin-top: 42px;
          color: #000;
          letter-spacing: 0;
          padding-left: 15px; }
        .package-picker-container .packages .package.plane-cycles .cycle-subtitle {
          font-size: 15px;
          padding-left: 15px;
          text-align: left;
          color: #000; }
        .package-picker-container .packages .package.plane-cycles .yearly {
          height: 210px;
          border: 1px solid #dfdfdf;
          border-right: none;
          border-radius: 5px 0 0 0;
          background-color: #fff;
          position: relative; }
          .package-picker-container .packages .package.plane-cycles .yearly .cycle-subtitle {
            text-align: left; }
          .package-picker-container .packages .package.plane-cycles .yearly .cycle-title {
            text-align: left; }
          .package-picker-container .packages .package.plane-cycles .yearly .open-monthly {
            width: 100%;
            display: block;
            transition: height 0s;
            position: absolute;
            bottom: 15px;
            text-align: left;
            padding-left: 15px; }
            .package-picker-container .packages .package.plane-cycles .yearly .open-monthly .arrow {
              display: inline-block;
              width: 16px;
              height: 14px;
              position: relative;
              top: 2px;
              margin-right: 5px; }
            .package-picker-container .packages .package.plane-cycles .yearly .open-monthly .caption {
              line-height: 10px;
              font-size: 15px;
              color: #000; }
            .package-picker-container .packages .package.plane-cycles .yearly .open-monthly.monthly-open {
              display: none; }
            .package-picker-container .packages .package.plane-cycles .yearly .open-monthly:hover {
              cursor: pointer; }
        .package-picker-container .packages .package.plane-cycles .monthly {
          background-color: #fafafa;
          height: 0;
          border-bottom-width: 0;
          transition: height 0s;
          border-radius: 0 0 0 5px;
          position: relative;
          border: 1px solid #dfdfdf;
          border-right: none;
          display: none; }
          .package-picker-container .packages .package.plane-cycles .monthly .cycle-subtitle {
            text-align: left;
            overflow: hidden; }
          .package-picker-container .packages .package.plane-cycles .monthly .cycle-title {
            margin-top: 0;
            padding-top: 20px;
            text-align: left;
            overflow: hidden; }
          .package-picker-container .packages .package.plane-cycles .monthly.monthly-open {
            display: block;
            height: 164px;
            border-top: none; }
        .package-picker-container .packages .package.plane-cycles.has-cycles {
          display: inline-block; }
        .package-picker-container .packages .package.plane-cycles .yearly:before,
        .package-picker-container .packages .package.plane-cycles .monthly:before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          margin-top: -13px;
          border: solid transparent;
          border-width: 16px 8px;
          border-left-color: #dfdfdf;
          right: -17px; }
        .package-picker-container .packages .package.plane-cycles .yearly:after,
        .package-picker-container .packages .package.plane-cycles .monthly:after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          margin-top: -13px;
          border: solid transparent;
          border-width: 16px 8px;
          border-left-color: #fff;
          right: -16px; }
        .package-picker-container .packages .package.plane-cycles .monthly:after {
          border-left-color: #fafafa; }

.super-app-first-time-tour-panel {
  border-radius: 0 8px 8px 0;
  height: calc(100vh - 112px);
  max-height: 626px;
  overflow: hidden;
  position: relative;
  width: 522px;
  z-index: 0; }

.get-started-slide {
  text-align: center;
  color: #395671;
  width: 522px;
  height: 100%; }
  .get-started-slide .button {
    border-radius: 50%;
    background: #d0e9fa;
    cursor: pointer;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 15px;
    right: 14px;
    display: inline-block; }
    .get-started-slide .button path {
      fill: #3899ec; }
    .get-started-slide .button .collapsed {
      display: none; }
    .get-started-slide .button.close {
      margin-right: 14px; }
    .get-started-slide .button:hover {
      background-color: #eaf7ff; }
  .get-started-slide .main-image {
    margin-top: 36px;
    margin-bottom: 14px; }
  .get-started-slide img {
    display: inline-block;
    margin: 20px auto 20px auto; }
  .get-started-slide .title {
    font-size: 30px;
    line-height: 36px;
    margin-top: 0;
    font-weight: 300; }
  .get-started-slide .sub-title {
    font-size: 16px;
    line-height: 17px;
    margin: 10px 20px; }
  .get-started-slide .get-started-add-btn {
    margin: 0 auto 24px auto;
    display: block;
    height: 36px; }
  .get-started-slide .teaser {
    margin: 40px auto 20px auto; }
  .get-started-slide .bullets-wrapper {
    margin-bottom: 10px; }
  .get-started-slide .tip-bullets {
    text-align: left;
    margin: 0 72px;
    display: inline-block; }
    .get-started-slide .tip-bullets .bullet {
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      text-align: left;
      margin: 18px 0; }
      .get-started-slide .tip-bullets .bullet .bullet-symbol {
        float: left; }
      .get-started-slide .tip-bullets .bullet .bullet-text {
        padding-left: 20px;
        margin: 0; }
  .get-started-slide footer {
    background: #ffffff;
    min-height: 54px;
    max-height: 90px;
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    border-top: 1px solid #cfcfcf;
    display: flex;
    justify-content: center;
    align-items: center; }
    .get-started-slide footer > * {
      display: inline-block;
      margin-right: 3px;
      padding: 0 54px; }
      .get-started-slide footer > * > a {
        text-decoration: none;
        color: #3899ec; }

.first-time-install-app {
  text-align: center;
  color: #395671; }
  .first-time-install-app .close {
    position: absolute;
    top: 20px;
    right: 20px;
    fill: #3899ec;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    line-height: 25px;
    text-align: center;
    width: 25px;
    height: 25px;
    box-sizing: border-box;
    z-index: 3; }
    .first-time-install-app .close:hover {
      background-color: #eaf7ff; }
    .first-time-install-app .close:active {
      background-color: #d3edff; }
    .first-time-install-app .close.white {
      background-color: #fff; }
      .first-time-install-app .close.white:hover {
        background-color: #eaf7ff; }
      .first-time-install-app .close.white:active {
        background-color: #fff; }
  .first-time-install-app .pre-install-section .inner-image-gallery {
    width: 522px; }
  .first-time-install-app .pre-install-section .title {
    font-size: 30px;
    line-height: 38px;
    margin-top: 0;
    margin-bottom: 3px;
    font-weight: normal; }
  .first-time-install-app .pre-install-section .sub-title,
  .first-time-install-app .pre-install-section .helper-text {
    font-size: 16px;
    line-height: 22px;
    margin-top: 0;
    margin-bottom: 20px; }
  .first-time-install-app .pre-install-section .helper-text {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 16px;
    margin-top: 18px;
    margin-bottom: 0; }
  .first-time-install-app .pre-install-section .tip-bullets {
    text-align: left;
    margin: 0 20px;
    display: inline-block; }
    .first-time-install-app .pre-install-section .tip-bullets .bullet {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px;
      font-size: 16px;
      font-weight: 300;
      line-height: 22px;
      text-align: left;
      margin-bottom: 14px; }
      .first-time-install-app .pre-install-section .tip-bullets .bullet .bullet-symbol {
        float: left;
        margin-right: 14px; }
      .first-time-install-app .pre-install-section .tip-bullets .bullet .bullet-text {
        padding-left: 32px;
        margin: 0; }
  .first-time-install-app .pre-install-section svg.symbol-plus rect {
    fill: #ffffff; }
  .first-time-install-app .pre-install-section .promotional-add-btn {
    margin: 20px auto 0 auto;
    display: block;
    width: 192px; }
  .first-time-install-app .pre-install-section .promotional-link {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    width: 100%;
    margin-top: 20px; }
  .first-time-install-app .pre-install-section a {
    margin: 0 auto 25px auto;
    color: #3899ec;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    display: inline-block; }
    .first-time-install-app .pre-install-section a:hover {
      text-decoration: underline; }
  .first-time-install-app .pre-install-section footer {
    height: 45px;
    font-size: 14px;
    line-height: 22px;
    border-top: 1px solid #cfcfcf; }
    .first-time-install-app .pre-install-section footer > * {
      display: inline-block;
      margin-right: 3px; }
    .first-time-install-app .pre-install-section footer > a {
      text-decoration: none;
      color: #3899ec;
      font-size: 14px; }
  .first-time-install-app .install-section {
    margin: 0 auto;
    width: 522px;
    padding-bottom: 225px; }
    .first-time-install-app .install-section .installed-image {
      margin: 40px auto 30px auto; }
    .first-time-install-app .install-section p {
      text-align: center;
      font-size: 30px; }
    .first-time-install-app .install-section .install-preloader {
      margin-top: 80px; }
  .first-time-install-app .banner {
    width: 100%;
    margin-bottom: 14px; }

.welcome-slide {
  text-align: center;
  color: #395671;
  margin: 0 auto;
  width: 522px; }
  .welcome-slide .close {
    position: absolute;
    top: 15px;
    right: 20px;
    border-radius: 50%;
    background-color: #d3edff;
    width: 25px;
    height: 25px;
    cursor: pointer;
    border: 1px solid transparent; }
    .welcome-slide .close path {
      fill: #3899ec; }
    .welcome-slide .close:hover {
      background-color: #eaf7ff; }
    .welcome-slide .close:active {
      background-color: #d3edff; }
    .welcome-slide .close.white {
      background-color: #fff; }
      .welcome-slide .close.white:hover {
        background-color: #eaf7ff;
        border: 1px solid #7fccf7; }
      .welcome-slide .close.white:active {
        background-color: #fff;
        border: 1px solid #3899ec; }
  .welcome-slide .installed-image {
    margin: 36px auto 14px auto; }
  .welcome-slide .title {
    font-size: 30px;
    line-height: 36px;
    margin-top: 0;
    margin-bottom: 13px;
    font-weight: 300; }
  .welcome-slide .sub-title {
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px;
    padding: 0 73px;
    text-align: center; }
  .welcome-slide .welcome-add-btn {
    margin: 40px auto 20px auto;
    display: block;
    width: 197px; }

.inner-image-gallery .gallery-images {
  overflow: hidden;
  white-space: nowrap;
  position: relative; }
  .inner-image-gallery .gallery-images .slide {
    opacity: 0;
    display: inline-block;
    width: 100%; }
    .inner-image-gallery .gallery-images .slide.left, .inner-image-gallery .gallery-images .slide.center, .inner-image-gallery .gallery-images .slide.right {
      transition: all 0.45s ease-out;
      position: absolute;
      top: 0;
      left: 0; }
    .inner-image-gallery .gallery-images .slide.left {
      transform: translate3d(-100%, 0, 0); }
    .inner-image-gallery .gallery-images .slide.center {
      opacity: 1;
      transform: translate3d(0, 0, 0); }
      .inner-image-gallery .gallery-images .slide.center.relative {
        position: relative; }
    .inner-image-gallery .gallery-images .slide.right {
      transform: translate3d(100%, 0, 0); }
    .inner-image-gallery .gallery-images .slide img {
      width: 100%; }

.inner-image-gallery .gallery-navigation-wrapper {
  margin: 19px auto 20px auto; }
  .inner-image-gallery .gallery-navigation-wrapper .navigation-bullets .bullet {
    border-radius: 50%;
    background-color: #d3edff;
    width: 12px;
    height: 12px;
    margin-right: 8px;
    display: inline-block;
    cursor: pointer; }
    .inner-image-gallery .gallery-navigation-wrapper .navigation-bullets .bullet:hover {
      background-color: #7fccf7; }
    .inner-image-gallery .gallery-navigation-wrapper .navigation-bullets .bullet.active {
      background-color: #3899ec; }

.super-app-panel {
  background: #fff; }

.pages-panel-pp .super-app-menu-panel > .tabs {
  background: #00aaaf; }

.bg-panel .super-app-menu-panel > .tabs {
  background: #f8a239; }

.add-panel .super-app-menu-panel > .tabs {
  background: #3797ea; }

.app-market-panel .super-app-menu-panel > .tabs {
  background: #d0427c; }

.app-market-panel-20 .super-app-menu-panel > .tabs {
  background: #d0427c; }

.my-uploads-panel .super-app-menu-panel > .tabs {
  background: #fa7c33; }

.blog-panel .super-app-menu-panel > .tabs {
  background: #60bc57; }

.new-blog-panel .super-app-menu-panel > .tabs {
  background: #faad4d; }

.shoutout-panel .super-app-menu-panel > .tabs {
  background: #ffc233; }

.wix-store-panel .super-app-menu-panel > .tabs {
  background: #00adf5; }

.wix-bookings-panel .super-app-menu-panel > .tabs {
  background: #733ca6; }

.hidden-items-panel .super-app-menu-panel > .tabs {
  background: #d0427d; }

.mobile-elements-panel .super-app-menu-panel > .tabs {
  background: #3899eb; }

.layout-optimizer-panel .super-app-menu-panel > .tabs {
  background: #45c8c1; }

.super-app-menu-panel {
  height: calc(100vh - 112px);
  white-space: nowrap; }
  .super-app-menu-panel:not(.dynamic-height) {
    max-height: 626px; }
  .super-app-menu-panel > .tabs {
    display: inline-block;
    vertical-align: top;
    height: calc(100% - 30px);
    padding: 15px 7px 15px 0;
    color: #ffffff; }
    .super-app-menu-panel > .tabs .tab {
      font-size: 14px;
      cursor: pointer;
      max-height: 30px;
      min-height: 14px;
      padding: 0 4px 0 5px;
      position: relative;
      margin-bottom: 4px; }
      .super-app-menu-panel > .tabs .tab .tabNameWrapper {
        position: relative;
        height: 30px;
        display: inline-block;
        border-radius: 20px;
        overflow: hidden;
        padding: 0 14px; }
        .super-app-menu-panel > .tabs .tab .tabNameWrapper.isSelected {
          background-color: rgba(0, 0, 0, 0.2);
          box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.11), inset 1px 1px 2px rgba(0, 0, 0, 0.15); }
          .new-blog-panel .super-app-menu-panel > .tabs .tab .tabNameWrapper.isSelected {
            background-color: #e78e1f; }
        .super-app-menu-panel > .tabs .tab .tabNameWrapper:not(.isSelected):hover {
          background-color: rgba(0, 0, 0, 0.07);
          box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.11), inset 1px 1px 2px rgba(0, 0, 0, 0.15); }
          .new-blog-panel .super-app-menu-panel > .tabs .tab .tabNameWrapper:not(.isSelected):hover {
            background-color: rgba(231, 142, 31, 0.5); }
        .super-app-menu-panel > .tabs .tab .tabNameWrapper .tabName {
          display: inline-block;
          position: relative;
          height: 14px;
          top: 7px; }
    .super-app-menu-panel > .tabs .separator {
      width: calc(100% - 35px);
      background: #d9e1e8;
      margin: 14px auto 21px auto;
      height: 1px; }
    .super-app-menu-panel > .tabs .premium {
      margin: 0 17px 25px 18px;
      cursor: pointer;
      white-space: pre-wrap; }
      .super-app-menu-panel > .tabs .premium:hover, .super-app-menu-panel > .tabs .premium.selected {
        text-decoration: underline; }
  .super-app-menu-panel > .content {
    background: #fff;
    display: inline-block;
    height: 100%;
    overflow: hidden;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    box-sizing: border-box;
    width: 354px; }
    .super-app-menu-panel > .content.inner-navigation-open {
      border-top-right-radius: 0; }
    .super-app-menu-panel > .content .custom-scroll > .outer-container {
      position: static; }
    .super-app-menu-panel > .content > .header {
      border-bottom: 1px solid #d9e1e8;
      height: 54px; }
      .super-app-menu-panel > .content > .header .title,
      .super-app-menu-panel > .content > .header .button {
        color: #3899ec;
        top: 17px;
        bottom: 16px;
        position: relative; }
      .super-app-menu-panel > .content > .header > .title {
        left: 24px;
        float: left;
        font-size: 18px;
        color: #395671; }
      .super-app-menu-panel > .content > .header .button {
        border-radius: 50%;
        background: #d0e9fa;
        cursor: pointer;
        width: 25px;
        height: 25px;
        top: 15px;
        margin-right: 9px;
        display: inline-block; }
        .super-app-menu-panel > .content > .header .button path {
          fill: #3899ec; }
        .super-app-menu-panel > .content > .header .button .collapsed {
          display: none; }
        .super-app-menu-panel > .content > .header .button.close {
          margin-right: 14px; }
        .super-app-menu-panel > .content > .header .button:hover {
          background-color: #eaf7ff; }
      .super-app-menu-panel > .content > .header .actions {
        float: right; }
      .super-app-menu-panel > .content > .header .help {
        line-height: 25px;
        text-align: center;
        top: 7px; }
    .super-app-menu-panel > .content > .selected-content-wrapper {
      white-space: normal;
      height: 100%; }
      .super-app-menu-panel > .content > .selected-content-wrapper:not(.dynamic-height) > *, .super-app-menu-panel > .content > .selected-content-wrapper.dynamic-height {
        height: calc(100% - 55px); }
        .super-app-menu-panel > .content > .selected-content-wrapper:not(.dynamic-height) > * > *, .super-app-menu-panel > .content > .selected-content-wrapper.dynamic-height > * {
          height: 100%; }

.superAppTooltipUpgrade {
  width: 384px;
  height: 141px;
  margin: 6px;
  white-space: normal; }
  .superAppTooltipUpgrade .wix-store-upgrade-left {
    float: left;
    width: 234px; }
  .superAppTooltipUpgrade .upgrade-title {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 16px;
    color: #162d3d;
    font-weight: 600;
    margin-bottom: 14px;
    margin-top: 5px; }
  .superAppTooltipUpgrade .upgrade-content {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    color: #2b5672;
    line-height: 18px;
    margin-top: 0;
    margin-bottom: 20px; }
  .superAppTooltipUpgrade .upgrade-button {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    background-color: #aa4dc8;
    max-width: 234px;
    height: 30px;
    color: #ffffff;
    text-align: center;
    line-height: 18px;
    position: relative;
    bottom: 0;
    margin-bottom: 6px; }
  .superAppTooltipUpgrade .migration-button {
    background-color: #fb7d33;
    margin-bottom: 25px; }
    .superAppTooltipUpgrade .migration-button:hover {
      background-color: #fc975c; }
  .superAppTooltipUpgrade svg {
    margin-top: 2px;
    position: relative;
    left: 24px; }

.test-drive svg {
  width: 140px;
  height: 150px; }

.site-app-learn-more {
  overflow: auto; }
  .site-app-learn-more .image-container {
    height: 163px;
    width: 313px;
    margin: 13px 18px;
    border: 2px solid transparent;
    border-radius: 11px;
    background-color: #fff;
    box-shadow: 0 0 9px 0.5px rgba(43, 86, 114, 0.13);
    cursor: pointer;
    display: block; }
    .site-app-learn-more .image-container:first-child {
      margin-top: 0; }
    .site-app-learn-more .image-container .active-layer {
      visibility: hidden;
      position: absolute;
      height: 153px;
      width: 303px;
      margin: 3px 3px;
      border-radius: 6px;
      border: 2px solid transparent; }
    .site-app-learn-more .image-container img {
      margin: 3px 3px;
      height: 157px;
      width: 307px; }
    .site-app-learn-more .image-container:hover {
      border: 2px solid #4eb7f5; }
      .site-app-learn-more .image-container:hover .link-container {
        background: #d3edff; }
    .site-app-learn-more .image-container:active {
      border: 2px solid #4eb7f5; }
      .site-app-learn-more .image-container:active .active-layer {
        opacity: 0.4;
        background: #d3edff;
        visibility: visible; }
    .site-app-learn-more .image-container .link-container {
      height: 48px;
      width: 308px;
      float: left;
      margin-top: -54px;
      margin-left: 2px;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      background: #eaf7ff;
      opacity: 0.85;
      display: inline-block; }
      .site-app-learn-more .image-container .link-container p {
        font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 16px;
        color: #2b5672;
        margin-left: 17px;
        position: relative;
        display: inline-block;
        text-decoration: none;
        width: calc(100% - 12px - 17px - 12px - 25px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }
      .site-app-learn-more .image-container .link-container svg {
        display: inline-block;
        text-align: center;
        float: right;
        margin-right: 12px;
        margin-top: 13px;
        width: 25px;
        height: 25px; }
      .site-app-learn-more .image-container .link-container .symbol-arrowWithStates .st0 {
        fill: transparent;
        stroke: #3899ec;
        stroke-width: 2px; }
      .site-app-learn-more .image-container .link-container .symbol-arrowWithStates .st1 {
        fill: transparent;
        stroke: #3899ec;
        stroke-miterlimit: 10;
        stroke-width: 2px; }
  .site-app-learn-more .first-view {
    padding-top: 17px; }
  .site-app-learn-more .second-view {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #2b5672; }
    .site-app-learn-more .second-view .section-top {
      border-bottom: 1px solid #d9e1e8;
      margin: 0 25px 0 25px;
      overflow: hidden;
      padding: 20px 0 5px 0; }
      .site-app-learn-more .second-view .section-top .title {
        font-size: 15px;
        font-weight: 400;
        float: left;
        width: 70%;
        margin: 0; }
      .site-app-learn-more .second-view .section-top .see-all-link {
        color: #3899ec;
        font-size: 13px;
        line-height: 18px;
        text-decoration: none;
        float: right;
        width: 30%;
        text-align: right; }
        .site-app-learn-more .second-view .section-top .see-all-link:hover {
          text-decoration: underline; }
    .site-app-learn-more .second-view .content-wrapper {
      height: 90px;
      cursor: pointer; }
      .site-app-learn-more .second-view .content-wrapper:first-child {
        margin-top: 10px; }
      .site-app-learn-more .second-view .content-wrapper:hover {
        background-color: #eaf7ff; }
        .site-app-learn-more .second-view .content-wrapper:hover svg path {
          fill: #3899ec;
          color: #fff; }
      .site-app-learn-more .second-view .content-wrapper img {
        display: inline-block;
        padding-left: 17px; }
      .site-app-learn-more .second-view .content-wrapper .description {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: #7a92a5;
        margin: 0 15px;
        width: 140px;
        display: inline-block;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        vertical-align: top; }
      .site-app-learn-more .second-view .content-wrapper .arrow-wrapper {
        border-radius: 50%;
        background-color: #eaf7ff;
        width: 25px;
        height: 25px;
        display: inline-block;
        position: relative;
        top: calc((25px - 90px) / 2); }
        .site-app-learn-more .second-view .content-wrapper .arrow-wrapper svg path {
          fill: #eaf7ff; }
        .site-app-learn-more .second-view .content-wrapper .arrow-wrapper:hover {
          background-color: #3899ec; }

.site-app-pages-panel {
  text-align: left;
  height: 100%; }
  .site-app-pages-panel .content-wrapper .site-app-pages-first-time {
    top: 24px; }
  .site-app-pages-panel .pages-panel-shadow {
    box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.36);
    border-radius: 8px;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%; }
  .site-app-pages-panel .pageSettings {
    align-self: flex-end; }
    .site-app-pages-panel .pageSettings .page-settings-panel-pp {
      position: absolute;
      left: auto;
      margin-left: -3px;
      z-index: -1;
      top: 0; }
    .site-app-pages-panel .pageSettings .page-settings-panel {
      left: auto;
      margin-left: -3px;
      z-index: -1; }
  .site-app-pages-panel .page-list-container {
    flex: 1 1 auto;
    order: 0;
    padding-top: 24px; }
    .site-app-pages-panel .page-list-container .page-list {
      width: 304px;
      padding: 0 24px 20px 24px; }
      .site-app-pages-panel .page-list-container .page-list > .hidden-page .page-title {
        color: #b6c1cd; }
      .site-app-pages-panel .page-list-container .page-list .pages-tree-item {
        cursor: pointer;
        padding: 0 0 0 10px;
        margin-bottom: 8px;
        line-height: 37px; }
      .site-app-pages-panel .page-list-container .page-list .pages-quick-actions .action-button {
        cursor: pointer; }
      .site-app-pages-panel .page-list-container .page-list > .selected .pages-tree-item {
        background-color: #d3edff; }
        .site-app-pages-panel .page-list-container .page-list > .selected .pages-tree-item .action-button {
          box-shadow: 0 1px 1px 0 #7a92a5;
          visibility: visible; }
        .site-app-pages-panel .page-list-container .page-list > .selected .pages-tree-item > .page-title svg path {
          fill: #7a92a5; }
      .site-app-pages-panel .page-list-container .page-list .handle {
        display: none; }

.super-app-add-elements-panel {
  border-top-right-radius: 0 !important;
  width: 100%; }
  .super-app-add-elements-panel.category-view-wrapper {
    display: inline; }
  .super-app-add-elements-panel .category-view {
    height: 100% !important; }
  .super-app-add-elements-panel .super-app-mobile-view {
    text-align: center; }
    .super-app-add-elements-panel .super-app-mobile-view .image-container {
      margin: 19px auto 35px auto; }
    .super-app-add-elements-panel .super-app-mobile-view .symbol-mobileToEditor {
      margin-top: 30px;
      margin-bottom: 3px; }
    .super-app-add-elements-panel .super-app-mobile-view p {
      margin: 14px 50px;
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      color: #2b5672; }
      .super-app-add-elements-panel .super-app-mobile-view p.bigger-text {
        font-size: 18px; }
    .super-app-add-elements-panel .super-app-mobile-view a {
      color: #3899ec;
      cursor: pointer; }
      .super-app-add-elements-panel .super-app-mobile-view a:hover {
        text-decoration: underline; }

.super-app-manage-panel,
.super-app-extra-panel {
  text-align: center; }
  .super-app-manage-panel .image-container,
  .super-app-extra-panel .image-container {
    margin: 19px auto 35px auto; }
  .super-app-manage-panel p,
  .super-app-extra-panel p {
    width: 224px;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #2b5672;
    line-height: 18px;
    margin: 0 auto 20px; }
  .super-app-manage-panel .buttons-container,
  .super-app-extra-panel .buttons-container {
    display: inline-block; }
    .super-app-manage-panel .buttons-container .manage-btn,
    .super-app-extra-panel .buttons-container .manage-btn {
      width: 100%;
      margin-bottom: 12px;
      height: 36px; }

.pages-panel-pp {
  height: calc(100vh - 112px);
  z-index: 1105; }
  .pages-panel-pp:not(.dynamic-height) {
    max-height: 626px; }
  .pages-panel-pp .left-panel-frame-pages-panel-pp {
    height: 100%;
    display: flex; }

.pages-panel-pp .tabs-pp {
  background: #00aaaf; }

.bg-panel .tabs-pp {
  background: #f8a239; }

.add-panel .tabs-pp {
  background: #3797ea; }

.app-market-panel .tabs-pp {
  background: #d0427c; }

.app-market-panel-20 .tabs-pp {
  background: #d0427c; }

.my-uploads-panel .tabs-pp {
  background: #fa7c33; }

.blog-panel .tabs-pp {
  background: #60bc57; }

.new-blog-panel .tabs-pp {
  background: #faad4d; }

.shoutout-panel .tabs-pp {
  background: #ffc233; }

.wix-store-panel .tabs-pp {
  background: #00adf5; }

.wix-bookings-panel .tabs-pp {
  background: #733ca6; }

.hidden-items-panel .tabs-pp {
  background: #d0427d; }

.mobile-elements-panel .tabs-pp {
  background: #3899eb; }

.layout-optimizer-panel .tabs-pp {
  background: #45c8c1; }

.tabs-pp {
  max-width: 180px;
  height: 100%;
  padding: 17px 12px 17px 6px;
  box-sizing: border-box;
  color: #fff; }
  .tabs-pp .tab .container-for-background {
    cursor: pointer;
    border-radius: 20px;
    height: 30px;
    padding: 0 12px;
    margin-bottom: 6px;
    display: inline-flex;
    align-items: center; }
    .tabs-pp .tab .container-for-background.selected {
      background-color: rgba(0, 0, 0, 0.2);
      box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.11), inset 1px 1px 2px rgba(0, 0, 0, 0.15); }
      .tabs-pp .tab .container-for-background.selected .actions-tooltip .control-context-menu {
        opacity: 0.7; }
    .tabs-pp .tab .container-for-background:not(.selected):hover {
      background-color: rgba(0, 0, 0, 0.07);
      box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.11), inset 1px 1px 2px rgba(0, 0, 0, 0.15); }
      .tabs-pp .tab .container-for-background:not(.selected):hover .actions-tooltip .control-context-menu {
        opacity: 0.7; }
    .tabs-pp .tab .container-for-background .title {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      max-width: 120px; }
    .tabs-pp .tab .container-for-background .actions-tooltip {
      height: 24px; }
      .tabs-pp .tab .container-for-background .actions-tooltip .control-context-menu {
        opacity: 0;
        margin-left: 6px; }
        .tabs-pp .tab .container-for-background .actions-tooltip .control-context-menu:hover {
          opacity: 1; }
        .tabs-pp .tab .container-for-background .actions-tooltip .control-context-menu .symbol {
          height: 24px; }
          .tabs-pp .tab .container-for-background .actions-tooltip .control-context-menu .symbol path {
            fill: #fff; }
  .tabs-pp .tab .divider-short {
    margin: 0 12px 5px 12px; }

.quick-navigation-pp {
  float: left;
  width: 222px;
  position: relative;
  border-right: 1px #e3eaf6 solid; }
  .quick-navigation-pp:hover {
    background-color: #eaf7ff; }
  .quick-navigation-pp .control-dropdown {
    padding: 0 18px 0 24px;
    display: flex;
    align-items: center;
    height: 100%; }
    .quick-navigation-pp .control-dropdown::before {
      content: attr(data-label);
      margin-right: 3px; }
    .quick-navigation-pp .control-dropdown .dropdown-selected {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      flex: 1; }
      .quick-navigation-pp .control-dropdown .dropdown-selected .control-dropdown-option-content {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 14px;
        color: #3899ec; }
      .quick-navigation-pp .control-dropdown .dropdown-selected > .selected-container > .selected-input {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 14px; }
      .quick-navigation-pp .control-dropdown .dropdown-selected .symbol-arrow-down {
        border: none; }
    .quick-navigation-pp .control-dropdown:hover + .bubble-quick-navigation-pp {
      display: inherit; }
  .quick-navigation-pp .bubble-quick-navigation-pp {
    display: none; }
    .quick-navigation-pp .bubble-quick-navigation-pp .bubble-content {
      position: relative;
      left: 0;
      top: 13px;
      flex-direction: column;
      align-items: left; }
      .quick-navigation-pp .bubble-quick-navigation-pp .bubble-content .bubble-title-quick-navigation-pp {
        margin-bottom: 6px; }

.dropdown-quick-navigation-pp .dropdown-options {
  width: 222px;
  border-radius: 0 0 8px 8px;
  border-top: 1px #e3eaf6 solid;
  box-shadow: 0 9px 13px 0 rgba(22, 45, 61, 0.2); }
  .dropdown-quick-navigation-pp .dropdown-options .dropdown-options-inner .control-section-divider {
    padding: 0 24px;
    height: 36px; }
    .dropdown-quick-navigation-pp .dropdown-options .dropdown-options-inner .control-section-divider.upper:not(:first-child) {
      margin-top: 12px; }
    .dropdown-quick-navigation-pp .dropdown-options .dropdown-options-inner .control-section-divider .section-divider-content {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 12px;
      line-height: 36px;
      text-align: inherit; }
  .dropdown-quick-navigation-pp .dropdown-options .dropdown-options-inner .control-dropdown-option {
    height: 30px; }
    .dropdown-quick-navigation-pp .dropdown-options .dropdown-options-inner .control-dropdown-option.nested-page {
      padding-left: 42px; }
    .dropdown-quick-navigation-pp .dropdown-options .dropdown-options-inner .control-dropdown-option .control-dropdown-option-content {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      line-height: 30px; }
  .dropdown-quick-navigation-pp .dropdown-options .dropdown-options-inner .control-dropdown-sticky-footer {
    align-items: center; }
    .dropdown-quick-navigation-pp .dropdown-options .dropdown-options-inner .control-dropdown-sticky-footer .control-button {
      padding: 0; }
      .dropdown-quick-navigation-pp .dropdown-options .dropdown-options-inner .control-dropdown-sticky-footer .control-button .manage-pages-text {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 13px;
        line-height: 40px; }

.menu-item-pp {
  padding: 0 6px 0 8px;
  background-color: #fff;
  border: 1px solid #fff;
  position: relative;
  border-radius: 7px;
  box-sizing: border-box;
  color: #2b5672;
  height: 39px;
  line-height: 39px;
  cursor: move; }
  .menu-item-pp:before {
    display: none;
    content: '';
    position: absolute;
    width: 11px;
    height: 100%;
    bottom: 50%;
    left: -12px;
    border: 1px solid #cde;
    border-top: 0;
    border-right: 0;
    padding: 4px 0 0; }
  .menu-item-pp.selected {
    background-color: #d3edff;
    border-color: #b1ddf8; }
  .menu-item-pp:hover:not(.selected) {
    background-color: #eaf7ff;
    border-color: #d3edff; }
  .menu-item-pp .title-action-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .menu-item-pp .title-action-container .left-container {
      display: flex;
      align-items: center; }
      .menu-item-pp .title-action-container .left-container > .has-tooltip {
        display: flex;
        align-items: center;
        margin-left: 10px; }
        .menu-item-pp .title-action-container .left-container > .has-tooltip .symbol {
          margin-right: 6px; }
          .menu-item-pp .title-action-container .left-container > .has-tooltip .symbol path {
            fill: #2b5672; }
            .hidden-page.menu-item-pp .title-action-container .left-container > .has-tooltip .symbol path {
              fill: #7a92a5; }
      .menu-item-pp .title-action-container .left-container .symbol-dragPage {
        display: none; }
        .selected.menu-item-pp .title-action-container .left-container .symbol-dragPage {
          display: inline; }
          .selected.menu-item-pp .title-action-container .left-container .symbol-dragPage + .has-tooltip {
            margin-left: 5px; }
        .menu-item-pp:hover .title-action-container .left-container .symbol-dragPage {
          display: inline; }
          .menu-item-pp:hover .title-action-container .left-container .symbol-dragPage + .has-tooltip {
            margin-left: 5px; }
      .menu-item-pp .title-action-container .left-container .menu-item-title {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 14px;
        color: #2b5672;
        max-width: 200px; }
        .hidden-page.menu-item-pp .title-action-container .left-container .menu-item-title {
          color: #7a92a5; }
    .menu-item-pp .title-action-container .right-container {
      display: flex;
      align-items: center; }
      .menu-item-pp .title-action-container .right-container > .symbol path {
        fill: #7a92a5; }
      .menu-item-pp .title-action-container .right-container > .symbol + .symbol {
        margin-left: 3px; }
      .menu-item-pp .title-action-container .right-container .control-context-menu {
        display: none;
        margin-left: 6px; }
        .selected.menu-item-pp .title-action-container .right-container .control-context-menu {
          display: inline-flex; }
        .menu-item-pp:hover .title-action-container .right-container .control-context-menu {
          display: inline-flex; }
  .menu-item-pp .input-button-container {
    height: 100%;
    display: flex;
    align-items: center; }
    .menu-item-pp .input-button-container .input-container {
      display: inline-flex;
      flex: 1;
      height: 23px; }
      .menu-item-pp .input-button-container .input-container .input {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 14px;
        height: 23px;
        color: #2b5672;
        border: 1px solid #7fccf7;
        border-radius: 4px;
        box-shadow: none;
        background-color: #fff; }
        .menu-item-pp .input-button-container .input-container .input:hover {
          background-color: #fff; }
    .menu-item-pp .input-button-container .done-button {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      height: 20px;
      margin-left: 6px;
      border-radius: 12px;
      padding: 0 10px; }

.pages-tree-shrinked .menu-item-pp {
  height: 23px;
  line-height: 23px; }
  .pages-tree-shrinked .menu-item-pp:before {
    padding: 5px 0 0; }
  .pages-tree-shrinked .menu-item-pp > .wrapper-view-mode > .menu-item-title svg {
    margin-top: 5px; }
    .pages-tree-shrinked .menu-item-pp > .wrapper-view-mode > .menu-item-title svg.symbol-pageItemDropdownIcon {
      margin-top: 9px; }
    .pages-tree-shrinked .menu-item-pp > .wrapper-view-mode > .menu-item-title svg.symbol-pageItemHomeIcon {
      margin-top: 6px; }

.menu-item-settings-action-pp + .divider-short {
  margin: 5px 18px 6px 18px; }

.menu-view-pp {
  background-color: #f0f3f5;
  height: 100%;
  width: 336px; }
  .menu-view-pp .title-menu-view-pp {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 230px; }
  .menu-view-pp > .pages-tree-container {
    height: calc(100% - 54px - 73px - 12px);
    width: 100%;
    position: relative;
    margin-top: 12px; }
    .menu-view-pp > .pages-tree-container > .pages-tree-docker {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0; }
      .menu-view-pp > .pages-tree-container > .pages-tree-docker .menu-item-pp {
        line-height: inherit; }
    .menu-view-pp > .pages-tree-container.disable-drag .menu-item-pp {
      cursor: default; }
  .menu-view-pp .pages-tree-container > .custom-scroll,
  .menu-view-pp .pages-tree-docker > .custom-scroll {
    position: relative;
    height: 100%; }
    .menu-view-pp .pages-tree-container > .custom-scroll > .outer-container,
    .menu-view-pp .pages-tree-docker > .custom-scroll > .outer-container {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0; }
      .menu-view-pp .pages-tree-container > .custom-scroll > .outer-container > .inner-container,
      .menu-view-pp .pages-tree-docker > .custom-scroll > .outer-container > .inner-container {
        height: 100%; }
  .menu-view-pp .pages-tree-container .tree-root,
  .menu-view-pp .pages-tree-docker .tree-root {
    box-sizing: border-box;
    padding: 0 17px 20px;
    width: 100%; }
  .menu-view-pp > footer {
    padding: 18px 24px;
    border-top: 1px solid #d9e1e8;
    background-color: #fff;
    box-sizing: border-box; }
    .menu-view-pp > footer > .add-options-container {
      display: flex;
      align-items: center;
      justify-content: center; }
      .menu-view-pp > footer > .add-options-container > .add-menu-page-button {
        display: inline-block; }
        .menu-view-pp > footer > .add-options-container > .add-menu-page-button > .button-content > .symbol {
          margin-right: 8px; }
        .menu-view-pp > footer > .add-options-container > .add-menu-page-button > .button-content > .add-title {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 157px;
          line-height: 20px; }
      .menu-view-pp > footer > .add-options-container .add-menu-link-button-container,
      .menu-view-pp > footer > .add-options-container .add-menu-folder-button-container {
        margin-left: 12px; }
        .menu-view-pp > footer > .add-options-container .add-menu-link-button-container .control-button,
        .menu-view-pp > footer > .add-options-container .add-menu-folder-button-container .control-button {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          padding: 0;
          background-color: #d3edff; }
          .menu-view-pp > footer > .add-options-container .add-menu-link-button-container .control-button > .button-content > .symbol > path,
          .menu-view-pp > footer > .add-options-container .add-menu-folder-button-container .control-button > .button-content > .symbol > path {
            fill: #3899ec; }

.add-tooltip-pp .composite-rich-text-labeled {
  padding: 0; }

.menu-view-pp .pages-tree-node {
  margin: 4px 0 0;
  border: 0;
  position: relative; }
  .menu-view-pp .pages-tree-node:first-child > .menu-item-pp:before {
    height: 50%;
    padding-top: 3px; }
  .menu-view-pp .pages-tree-node .pages-tree-node {
    margin-left: 25px; }
    .menu-view-pp .pages-tree-node .pages-tree-node > .menu-item-pp:before {
      display: block; }
  .menu-view-pp .pages-tree-node .expander {
    display: none;
    background-color: #7fccf7;
    border-radius: 100%;
    color: #1a92df;
    cursor: pointer;
    height: 14px;
    left: -7px;
    font-size: 14px;
    line-height: 10px;
    position: absolute;
    text-align: center;
    top: 12.5px;
    width: 14px;
    z-index: 1445; }

.menu-view-pp .pages-tree-shrinked .pages-tree {
  margin: 5px 0 0; }
  .menu-view-pp .pages-tree-shrinked .pages-tree:first-child > .menu-item-pp:before {
    padding-top: 4px; }
  .menu-view-pp .pages-tree-shrinked .pages-tree .expander {
    top: 4.5px; }

.menu-view-pp .has-children.pages-tree-node > .expander {
  display: block; }

.menu-view-pp .tree-collapsed.pages-tree-node + .pages-tree-node {
  margin-top: 12px; }

.menu-view-pp .tree-collapsed .pages-tree-node {
  display: none; }
  .menu-view-pp .tree-collapsed .pages-tree-node.shadow {
    display: block;
    margin-top: 12px; }
    .menu-view-pp .tree-collapsed .pages-tree-node.shadow > .menu-item-pp:before {
      height: 50%;
      padding-top: 4px; }

.menu-view-pp .tree-collapsed > .menu-item-pp,
.menu-view-pp .rotated.has-children > .menu-item-pp {
  margin-bottom: 6px; }

.menu-view-pp .tree-collapsed > .menu-item-pp {
  box-shadow: 0 0 0 1px #cde, 0 3px 0 #fff, 0 4px 0 #cde, 0 6px 0 #fff, 0 7px 0 #cde; }

.menu-view-pp .tree-collapsed > .menu-item-pp:hover,
.menu-view-pp .tree-collapsed > .menu-item-pp.selected,
.menu-view-pp .rotated.has-children > .menu-item-pp {
  box-shadow: 0 0 0 1px #cde, 0 3px 0 #eaf7ff, 0 4px 0 #cde, 0 6px 0 #eaf7ff, 0 7px 0 #cde; }

.menu-view-pp .rotated > .menu-item-pp {
  background-color: #eaf7ff;
  transform-origin: 50% 50%;
  transform: rotate(-6deg); }
  .menu-view-pp .rotated > .menu-item-pp:before {
    display: none !important; }
  .menu-view-pp .rotated > .menu-item-pp .control-context-menu {
    display: none !important; }

.menu-view-pp .shadow .pages-tree {
  display: none; }

.menu-view-pp .shadow > .expander {
  display: none !important; }

.menu-view-pp .shadow > .menu-item-pp {
  background-color: #d9e1e8;
  border: 1px solid #cde; }
  .menu-view-pp .shadow > .menu-item-pp > * {
    display: none; }

.pages-view-pp {
  height: 100%;
  width: 336px; }
  .pages-view-pp .title-pages-view-pp {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 230px; }
  .pages-view-pp .pages-container-outer {
    height: calc(100% - 54px - 73px); }
    .pages-view-pp .pages-container-outer .custom-scroll {
      height: 100%; }
      .pages-view-pp .pages-container-outer .custom-scroll .outer-container {
        height: 100%; }
        .pages-view-pp .pages-container-outer .custom-scroll .outer-container .inner-container {
          height: 100%; }
          .pages-view-pp .pages-container-outer .custom-scroll .outer-container .inner-container .content-wrapper {
            height: 100%; }
            .pages-view-pp .pages-container-outer .custom-scroll .outer-container .inner-container .content-wrapper .pages-container-inner {
              height: 100%;
              padding: 18px;
              box-sizing: border-box; }
  .pages-view-pp .add-container {
    padding: 18px 0;
    border-top: 1px solid #d9e1e8;
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    justify-content: center; }
    .pages-view-pp .add-container .add-button .symbol-addPagePagesPanel {
      margin-right: 8px; }

.page-pp {
  height: 36px;
  background-color: #fff;
  margin-bottom: 6px;
  border-radius: 8px;
  border: 1px solid #fff;
  box-sizing: border-box; }
  .page-pp.selected {
    background-color: #d3edff;
    border-color: #b1ddf8; }
  .page-pp:hover:not(.selected) {
    background-color: #eaf7ff;
    border-color: #d3edff; }
  .page-pp .title-container {
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 6px 0 16px; }
    .page-pp .title-container .left-container {
      display: flex;
      align-items: center; }
      .page-pp .title-container .left-container .symbol {
        margin-right: 6px; }
      .page-pp .title-container .left-container .title {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 14px;
        color: #2b5672;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
    .page-pp .title-container .right-container {
      display: flex;
      align-items: center; }
      .page-pp .title-container .right-container > .symbol path {
        fill: #7a92a5; }
      .page-pp .title-container .right-container .page-actions {
        display: none;
        margin-left: 6px; }
        .selected.page-pp .title-container .right-container .page-actions {
          display: inline-flex; }
        .page-pp:hover .title-container .right-container .page-actions {
          display: inline-flex; }
        .page-pp .title-container .right-container .page-actions.single-action {
          border-radius: 50%;
          border: 2px solid #b1ddf8;
          height: 24px;
          width: 24px;
          padding: 0;
          background-color: transparent; }
          .page-pp .title-container .right-container .page-actions.single-action:hover {
            border-color: #7fccf7; }
          .page-pp .title-container .right-container .page-actions.single-action svg {
            width: 14px;
            height: 14px; }
            .page-pp .title-container .right-container .page-actions.single-action svg path {
              fill: #4eb7f5; }
  .page-pp .input-button-container {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 12px; }
    .page-pp .input-button-container .input-container {
      display: inline-flex;
      flex: 1;
      height: 24px; }
      .page-pp .input-button-container .input-container .input {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 14px;
        height: 24px;
        color: #2b5672;
        border: 1px solid #7fccf7;
        box-shadow: none;
        background-color: #fff; }
        .page-pp .input-button-container .input-container .input:hover {
          background-color: #fff; }
    .page-pp .input-button-container .done-button {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      height: 20px;
      margin-left: 6px;
      border-radius: 12px;
      padding: 0 10px; }

.page-settings-action-pp + .divider-short {
  margin: 5px 18px 6px 18px; }

.transitions-view-pp {
  height: 100%;
  width: 336px; }
  .transitions-view-pp .composite-thumbnails {
    padding: 24px 48px 18px 48px; }
    .transitions-view-pp .composite-thumbnails .t12.label {
      line-height: 14px; }

.dynamic-pages-intro-view-pp {
  height: 100%;
  width: 336px; }
  .dynamic-pages-intro-view-pp > .banner {
    width: 100%;
    height: 162px; }

.page-settings-panel-pp {
  border-radius: 0 8px 8px 0;
  width: 480px;
  height: 100%;
  background-color: #fff;
  z-index: 1254;
  transition-property: width;
  transition-duration: 0.4s;
  transition-timing-function: ease-in;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.35);
  white-space: normal; }
  .page-settings-panel-pp > .page-settings-panel-pp-inner {
    display: flex;
    flex-direction: column;
    min-width: 480px;
    height: 100%;
    width: 100%;
    overflow: hidden; }
    .page-settings-panel-pp > .page-settings-panel-pp-inner .panel-header {
      border-radius: 0 8px 0 0; }
    .page-settings-panel-pp > .page-settings-panel-pp-inner .page-settings-tabs {
      height: 100%; }
    .page-settings-panel-pp > .page-settings-panel-pp-inner > div {
      display: flex;
      flex-direction: column;
      height: calc(100% - 118px); }
  .page-settings-panel-pp .page-settings-panel-pp-content {
    height: calc(100% - 47px);
    color: #395671;
    padding: 5px 0;
    font-size: 14px;
    font-weight: 300;
    box-sizing: border-box; }
    .page-settings-panel-pp .page-settings-panel-pp-content,
    .page-settings-panel-pp .page-settings-panel-pp-content .tab,
    .page-settings-panel-pp .page-settings-panel-pp-content .custom-scroll,
    .page-settings-panel-pp .page-settings-panel-pp-content .outer-container,
    .page-settings-panel-pp .page-settings-panel-pp-content .inner-container {
      display: flex;
      flex-direction: column; }
    .page-settings-panel-pp .page-settings-panel-pp-content .tab,
    .page-settings-panel-pp .page-settings-panel-pp-content .container-preloader-iframe,
    .page-settings-panel-pp .page-settings-panel-pp-content .custom-scroll {
      height: 100%; }
    .page-settings-panel-pp .page-settings-panel-pp-content .tab-inner {
      padding: 0 15px 10px 30px; }
      .page-settings-panel-pp .page-settings-panel-pp-content .tab-inner.empty {
        padding-top: 18px;
        padding-left: 38px; }
    .page-settings-panel-pp .page-settings-panel-pp-content :not(.bubble-content) > section {
      border-bottom: 1px solid #d9e1e8;
      padding: 20px 25px 15px 5px; }
      .page-settings-panel-pp .page-settings-panel-pp-content :not(.bubble-content) > section > *:not(.control-text-input):not(.info-icon) {
        box-sizing: border-box;
        padding-left: 12px; }
    .page-settings-panel-pp .page-settings-panel-pp-content .wixapps-subpage section {
      border-bottom: 0;
      padding: 0 25px 0 5px;
      margin-bottom: 34px; }
    .page-settings-panel-pp .page-settings-panel-pp-content .wixapps-subpage p:not(.title) {
      margin: 0 0 13px; }
    .page-settings-panel-pp .page-settings-panel-pp-content .wixapps-subpage .title {
      color: #2b5672;
      font-size: 18px;
      margin-top: 20px;
      margin-bottom: 8px; }
    .page-settings-panel-pp .page-settings-panel-pp-content .control-text-input {
      width: 100%; }
      .page-settings-panel-pp .page-settings-panel-pp-content .control-text-input > label {
        color: #2b5672; }
      .page-settings-panel-pp .page-settings-panel-pp-content .control-text-input > input {
        width: 100%;
        color: #1d2d3c; }
        .page-settings-panel-pp .page-settings-panel-pp-content .control-text-input > input::placeholder {
          color: #b6c1cd; }
    .page-settings-panel-pp .page-settings-panel-pp-content .control-toggle {
      width: 100%;
      display: inline-block; }
      .page-settings-panel-pp .page-settings-panel-pp-content .control-toggle > .text-label {
        line-height: 26px;
        float: left;
        width: auto; }
      .page-settings-panel-pp .page-settings-panel-pp-content .control-toggle > .icons-container {
        float: right; }
      .page-settings-panel-pp .page-settings-panel-pp-content .control-toggle .toggle-with-icon {
        width: 100%; }
    .page-settings-panel-pp .page-settings-panel-pp-content .control-button.btn-text {
      line-height: 16px;
      height: 16px;
      font-size: 14px;
      padding: 0;
      min-width: 0; }
  .page-settings-panel-pp .button-control {
    border-radius: 50%;
    border: 0;
    width: 25px;
    height: 25px;
    background-color: rgba(13, 72, 127, 0.3);
    color: #fff;
    box-sizing: border-box;
    line-height: 25px;
    min-width: 0;
    padding: 0; }
    .page-settings-panel-pp .button-control:hover {
      background-color: rgba(13, 72, 127, 0.6); }
    .page-settings-panel-pp .button-control svg {
      margin: 0; }
  .page-settings-panel-pp .symbol-settingsHome {
    margin-right: 10px; }
  .page-settings-panel-pp .page-settings-tabs > .tab-header {
    width: 480px;
    min-height: 47px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-header > .tab-label {
      color: rgba(43, 86, 114, 0.9);
      font-size: 14px;
      width: 120px;
      border: 1px solid #d9e1e8;
      border-top: 0;
      border-right: 0;
      box-sizing: border-box;
      position: relative;
      background-color: #eaf7ff;
      height: 47px;
      line-height: 47px;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      padding: 0 10px; }
      .page-settings-panel-pp .page-settings-tabs > .tab-header > .tab-label:last-child {
        border-right: 0; }
      .page-settings-panel-pp .page-settings-tabs > .tab-header > .tab-label:hover {
        background-color: #d3edff;
        border-bottom: 1px solid #d3edff; }
      .page-settings-panel-pp .page-settings-tabs > .tab-header > .tab-label.selected {
        border-bottom: 1px solid #fff;
        background-color: #fff;
        color: #2b5672; }
      .page-settings-panel-pp .page-settings-tabs > .tab-header > .tab-label:first-child {
        border-left: 0; }
  .page-settings-panel-pp .page-settings-tabs > .tab-content .tabs {
    border-bottom: 0; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .tabs .tab-header > .tab-label {
      border-bottom: 1px solid #d9e1e8;
      padding-bottom: 15px; }
      .page-settings-panel-pp .page-settings-tabs > .tab-content .tabs .tab-header > .tab-label:last-child {
        border-bottom: 0; }
      .page-settings-panel-pp .page-settings-tabs > .tab-content .tabs .tab-header > .tab-label:before {
        top: 0; }
      .page-settings-panel-pp .page-settings-tabs > .tab-content .tabs .tab-header > .tab-label:after {
        top: 2px; }
  .page-settings-panel-pp .page-settings-tabs > .tab-content .action-done {
    border-radius: 50%;
    border: 0;
    width: 24px;
    height: 24px;
    background-color: #60bc57;
    color: #fff;
    line-height: 24px;
    min-width: 0;
    padding: 0;
    display: inline-block;
    text-align: center; }
  .page-settings-panel-pp .page-settings-tabs > .tab-content .copy-url {
    margin-top: 5px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .copy-url .btn-text {
      margin-right: 5px;
      height: 24px;
      line-height: 24px; }
  .page-settings-panel-pp .page-settings-tabs > .tab-content .page-info .section-page-title {
    padding-top: 19px;
    padding-bottom: 14px; }
  .page-settings-panel-pp .page-settings-tabs > .tab-content .page-info .section-hide-page {
    padding-top: 23px; }
  .page-settings-panel-pp .page-settings-tabs > .tab-content .page-info > .page-settings-control-description {
    padding-top: 26px;
    padding-bottom: 25px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .page-info > .page-settings-control-description > .control-button {
      height: 24px;
      font-size: 14px;
      float: right;
      margin-top: 0; }
      .page-settings-panel-pp .page-settings-tabs > .tab-content .page-info > .page-settings-control-description > .control-button:not(.btn-text) {
        min-width: 90px; }
      .page-settings-panel-pp .page-settings-tabs > .tab-content .page-info > .page-settings-control-description > .control-button svg {
        position: relative;
        margin-left: -3px;
        margin-right: 5px;
        margin-bottom: -1px; }
        .page-settings-panel-pp .page-settings-tabs > .tab-content .page-info > .page-settings-control-description > .control-button svg path {
          fill: #fff; }
  .page-settings-panel-pp .page-settings-tabs > .tab-content .layout > section {
    padding: 7px 25px 0 5px; }
  .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label {
    overflow: hidden;
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 40px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label > div {
      float: right;
      width: 227px; }
      .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label > div h3 {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 18px;
        margin-top: 0; }
      .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label > div p {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 13px;
        margin-bottom: 0; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label:before {
      top: 26px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label:after {
      top: 28px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label svg {
      margin-left: 20px;
      float: left; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label .symbol-layoutStandardSelected,
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label .symbol-layoutLandingSelected {
      display: none; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label .symbol-layoutStandardNotSelected,
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label .symbol-layoutLandingNotSelected {
      display: block; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label.selected .symbol-layoutStandardSelected,
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label.selected .symbol-layoutLandingSelected {
      display: block; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label.selected .symbol-layoutStandardNotSelected,
    .page-settings-panel-pp .page-settings-tabs > .tab-content .layout .tab-header > .tab-label.selected .symbol-layoutLandingNotSelected {
      display: none; }
  .page-settings-panel-pp .page-settings-tabs > .tab-content .permissions-tab .composite-thumbnails + .composite-rich-text {
    padding-top: 0; }
  .page-settings-panel-pp .page-settings-tabs > .tab-content .permissions-tab .members-links-container .control-rich-text {
    display: flex;
    align-items: center;
    margin-bottom: 5px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .permissions-tab .members-links-container .control-rich-text .symbol {
      margin-right: 5px; }
      .page-settings-panel-pp .page-settings-tabs > .tab-content .permissions-tab .members-links-container .control-rich-text .symbol.symbol-goToURLAction {
        width: 17px;
        height: 17px; }
        .page-settings-panel-pp .page-settings-tabs > .tab-content .permissions-tab .members-links-container .control-rich-text .symbol.symbol-goToURLAction path {
          fill: #3899ec; }
  .page-settings-panel-pp .page-settings-tabs > .tab-content .seo.tab {
    padding-bottom: 10px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .seo.tab .seo-section-title,
    .page-settings-panel-pp .page-settings-tabs > .tab-content .seo.tab .seo-section-description,
    .page-settings-panel-pp .page-settings-tabs > .tab-content .seo.tab .seo-section-keywords {
      padding-bottom: 10px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .seo.tab .seo-section-description,
    .page-settings-panel-pp .page-settings-tabs > .tab-content .seo.tab .seo-section-keywords {
      padding-top: 10px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .seo.tab .seo-section-seo-wiz-inner-div {
      margin-left: -12px;
      margin-bottom: -22px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .seo.tab .seo-section-seo-wiz {
      padding-top: 0; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .seo.tab .seo-section-page-address-old {
      position: relative;
      padding-bottom: 20px; }
      .page-settings-panel-pp .page-settings-tabs > .tab-content .seo.tab .seo-section-page-address-old .control-text-input {
        margin-bottom: 7px; }
        .page-settings-panel-pp .page-settings-tabs > .tab-content .seo.tab .seo-section-page-address-old .control-text-input input {
          margin-left: 130px;
          width: calc(100% - 130px); }
      .page-settings-panel-pp .page-settings-tabs > .tab-content .seo.tab .seo-section-page-address-old .page-address-prefix {
        position: absolute;
        top: 52px;
        left: 5px;
        line-height: 35px;
        font-size: 18px;
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        color: #b6c1cd; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .seo.tab .tab-inner {
      margin-bottom: 50px; }
      .page-settings-panel-pp .page-settings-tabs > .tab-content .seo.tab .tab-inner.google-preview-expanded {
        margin-bottom: 190px; }
  .page-settings-panel-pp .page-settings-tabs > .tab-content .wixapps-subpage .seo-google > .google-preview {
    position: static;
    border-radius: 0;
    border: none;
    width: calc(100% - 10px);
    height: auto;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.14);
    box-sizing: border-box;
    padding: 17px 11px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .wixapps-subpage .seo-google > .google-preview > p {
      padding: 0; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .wixapps-subpage .seo-google > .google-preview > .google-preview-title {
      margin: 0 0 5px; }
    .page-settings-panel-pp .page-settings-tabs > .tab-content .wixapps-subpage .seo-google > .google-preview > .google-preview-url {
      margin-bottom: 2px; }
  .page-settings-panel-pp .page-settings-tabs + .preloader-page-settings-pp {
    left: 75%; }
  .page-settings-panel-pp .google-preview {
    border-radius: 15px 15px 0 0;
    transition: bottom 0.4s;
    position: absolute;
    bottom: -140px;
    height: 150px;
    width: calc(100% - 7px);
    left: 6px;
    border: 1px solid #d9e1e8;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff; }
    .page-settings-panel-pp .google-preview .google-preview-corner-container {
      position: absolute;
      width: 15px;
      height: 15px;
      bottom: -5px;
      overflow: hidden; }
      .page-settings-panel-pp .google-preview .google-preview-corner-container .google-preview-corner {
        width: 31px;
        height: 31px;
        position: relative;
        border: solid 8px #fff;
        top: -8px;
        left: -8px;
        box-sizing: border-box; }
      .page-settings-panel-pp .google-preview .google-preview-corner-container.left {
        left: -15px; }
        .page-settings-panel-pp .google-preview .google-preview-corner-container.left .google-preview-corner {
          border-radius: 0 0 15px; }
      .page-settings-panel-pp .google-preview .google-preview-corner-container.right {
        right: -15px; }
        .page-settings-panel-pp .google-preview .google-preview-corner-container.right .google-preview-corner {
          border-radius: 0 0 0 15px; }
    .page-settings-panel-pp .google-preview.expanded {
      bottom: -1px; }
      .page-settings-panel-pp .google-preview.expanded .google-preview-header > .symbol-equals {
        display: none; }
      .page-settings-panel-pp .google-preview.expanded .google-preview-header > .symbol-arrowDown {
        display: block; }
    .page-settings-panel-pp .google-preview > p {
      text-align: left;
      margin: 0 40px;
      word-wrap: break-word; }
    .page-settings-panel-pp .google-preview > .google-preview-header {
      border-radius: 15px 15px 0 0;
      position: relative;
      cursor: pointer;
      height: 32px;
      padding-top: 5px;
      text-align: center;
      border: 1px solid #d9e1e8;
      box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
      width: 200px;
      background-color: #fff;
      border-bottom: 0;
      margin: 0 auto;
      top: -43px;
      color: #3899ec; }
      .page-settings-panel-pp .google-preview > .google-preview-header > svg {
        fill: #3899ec; }
      .page-settings-panel-pp .google-preview > .google-preview-header > .symbol-equals {
        margin: 6px auto 5px;
        display: block; }
      .page-settings-panel-pp .google-preview > .google-preview-header > .symbol-arrowDown {
        margin: 2px auto 1px;
        display: none; }
      .page-settings-panel-pp .google-preview > .google-preview-header > .google-preview-header-title {
        margin-top: 5px; }
      .page-settings-panel-pp .google-preview > .google-preview-header > .google-preview-bottom-header-border {
        background-color: #fff;
        width: 100%;
        position: absolute;
        height: 8px;
        bottom: -8px; }
    .page-settings-panel-pp .google-preview > .google-preview-title {
      font-family: Arial, sans-serif;
      font-size: 18px;
      font-weight: 400;
      color: #1a0dab;
      line-height: 21px;
      margin-top: -10px; }
      .page-settings-panel-pp .google-preview > .google-preview-title > .google-preview-title-site-name {
        font-weight: 700; }
    .page-settings-panel-pp .google-preview > .google-preview-url {
      font-family: Arial, sans-serif;
      font-size: 14px;
      color: #006621;
      margin-top: 4px;
      margin-bottom: 4px;
      user-select: text; }
    .page-settings-panel-pp .google-preview > .google-preview-description {
      font-family: Arial, sans-serif;
      font-size: 13px;
      color: #545454; }
  .page-settings-panel-pp .page-setting-mobile .page-settings-mobile-common-page .label {
    color: #2b5672;
    font-size: 14px;
    line-height: 24px;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  .page-settings-panel-pp .page-setting-mobile .page-settings-mobile-common-page section:first-child {
    border-top: 1px solid #d9e1e8;
    margin-top: 19px;
    padding-top: 24px;
    padding-bottom: 24px; }
  .page-settings-panel-pp .page-setting-mobile .page-settings-mobile-common-page .toggle-with-icon {
    position: relative; }
    .page-settings-panel-pp .page-setting-mobile .page-settings-mobile-common-page .toggle-with-icon .mobileHidden {
      position: absolute;
      top: 3px; }
    .page-settings-panel-pp .page-setting-mobile .page-settings-mobile-common-page .toggle-with-icon .mobileVisible {
      position: absolute;
      top: 5px;
      left: 3px; }
    .page-settings-panel-pp .page-setting-mobile .page-settings-mobile-common-page .toggle-with-icon .label {
      margin-left: 25px;
      overflow: inherit; }
  .page-settings-panel-pp .page-setting-mobile .page-settings-mobile-common-page .hidden-from-desktop {
    padding-top: 23px;
    padding-bottom: 23px; }
    .page-settings-panel-pp .page-setting-mobile .page-settings-mobile-common-page .hidden-from-desktop > span {
      line-height: 24px; }
    .page-settings-panel-pp .page-setting-mobile .page-settings-mobile-common-page .hidden-from-desktop > .control-button {
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      float: right;
      margin-top: 0; }
      .page-settings-panel-pp .page-setting-mobile .page-settings-mobile-common-page .hidden-from-desktop > .control-button:not(.btn-text) {
        min-width: 90px; }
      .page-settings-panel-pp .page-setting-mobile .page-settings-mobile-common-page .hidden-from-desktop > .control-button svg {
        position: relative;
        margin-left: -3px;
        margin-right: 5px;
        margin-bottom: -1px; }
        .page-settings-panel-pp .page-setting-mobile .page-settings-mobile-common-page .hidden-from-desktop > .control-button svg path {
          fill: #fff; }
  .page-settings-panel-pp .page-setting-mobile .symbol-desktopHidden {
    margin-right: 10px; }
  .page-settings-panel-pp .page-setting-mobile .symbol-desktopVisible {
    margin-right: 10px; }

.seo-section-page-address {
  padding-left: 17px;
  top: 15px;
  position: relative; }
  .seo-section-page-address .seo-section-title-label {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    font-weight: 300;
    color: #2b5672;
    text-align: left;
    display: block;
    overflow: hidden;
    line-height: 18px;
    max-height: 34px; }
  .seo-section-page-address .seo-section-site-url {
    margin-bottom: 7px; }
    .seo-section-page-address .seo-section-site-url > .control-text-input-seo {
      margin-left: 7px;
      width: inherit; }
      .seo-section-page-address .seo-section-site-url > .control-text-input-seo input {
        width: calc(100% - 130px); }
  .seo-section-page-address .page-address-prefix {
    position: relative;
    top: 1px;
    left: 0;
    line-height: 35px;
    font-size: 18px;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #b6c1cd; }
  .seo-section-page-address.blog-seo-setting {
    padding-left: 11px; }

.page-settings-change-homepage-tooltip {
  float: right; }

.tab-seo .translated-by {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  color: #162d3d;
  float: right;
  font-size: 12px;
  transform: translateY(-0.5em);
  font-weight: 300; }

.tab-seo .symbol-google-logo {
  width: 42px;
  height: 13px;
  transform: translateY(0.3em); }

.tab-seo .seo-section-description textarea.input :not(.focused) {
  height: 100px; }

.blog-manage-panel {
  height: 100%; }
  .blog-manage-panel p {
    font-size: 18px; }
  .blog-manage-panel svg {
    margin-top: 30px;
    margin-bottom: 14px; }

.left-panel-frame.shoutout-panel {
  width: 698px; }
  .left-panel-frame.shoutout-panel .super-app-first-time-tour-panel {
    width: inherit; }
  .left-panel-frame.shoutout-panel .welcome-slide {
    width: inherit;
    height: 99.9%; }

.shoutout-add-panel .shoutout-panel-loading {
  padding-top: 20px; }

.shoutout-add-panel .shoutout-panel-first-time .shoutout-first-time-title {
  margin-top: 48px;
  width: 430px;
  margin-left: auto;
  margin-right: auto; }

.shoutout-add-panel .shoutout-panel-first-time .shoutout-first-time-sub-title {
  margin-top: 12px; }
  .shoutout-add-panel .shoutout-panel-first-time .shoutout-first-time-sub-title .shoutout-first-time-sub-title-line {
    margin-top: 0; }

.shoutout-add-panel .shoutout-panel-first-time .shoutout-returning-create-new {
  min-width: 197px;
  max-width: 250px;
  margin-top: 30px; }

.shoutout-add-panel .shoutout-panel-returning {
  height: 100%; }
  .shoutout-add-panel .shoutout-panel-returning .shoutout-returning-header {
    border-top-right-radius: 7px;
    text-align: left;
    top: 0;
    left: 0;
    width: 100%;
    position: absolute; }
  .shoutout-add-panel .shoutout-panel-returning .shoutout-returning-user-iframe {
    margin-top: 54px;
    height: calc(98% - 54px);
    border: 0;
    border-bottom-right-radius: 7px;
    border-top-right-radius: 7px; }
  .shoutout-add-panel .shoutout-panel-returning .shoutout-returning-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    border-bottom-right-radius: 7px; }
    .shoutout-add-panel .shoutout-panel-returning .shoutout-returning-footer .shoutout-returning-create-new {
      min-width: 197px;
      max-width: 250px;
      margin: 18px; }

.shoutout-add-panel .shoutout-first-time-image {
  width: 100%;
  background-color: #6DCCE8;
  border-top-right-radius: 7px;
  padding-top: 50px; }
  .shoutout-add-panel .shoutout-first-time-image img {
    vertical-align: bottom; }

.left-panel-frame.shoutout-panel-new {
  width: auto; }
  .left-panel-frame.shoutout-panel-new .shoutout-panel-url {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #009aef;
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    text-decoration: none; }
    .left-panel-frame.shoutout-panel-new .shoutout-panel-url:active, .left-panel-frame.shoutout-panel-new .shoutout-panel-url:hover, .left-panel-frame.shoutout-panel-new .shoutout-panel-url:visited {
      text-decoration: none;
      color: #009aef; }
  .left-panel-frame.shoutout-panel-new .shoutout-panel-url-my-shoutouts {
    line-height: 1;
    margin-top: -4px;
    display: block;
    text-align: center; }
  .left-panel-frame.shoutout-panel-new .shoutout-panel-button-as-url {
    background: none;
    border: none;
    text-decoration: none;
    outline: 0;
    color: #3899ec;
    display: block;
    margin-left: auto;
    margin-right: auto; }
    .left-panel-frame.shoutout-panel-new .shoutout-panel-button-as-url:active, .left-panel-frame.shoutout-panel-new .shoutout-panel-button-as-url:hover, .left-panel-frame.shoutout-panel-new .shoutout-panel-button-as-url:visited {
      text-decoration: none; }
  .left-panel-frame.shoutout-panel-new .shoutout-panel-url-as-button {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #ffffff;
    display: inline-block;
    padding-top: 10px;
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    text-decoration: none; }
    .left-panel-frame.shoutout-panel-new .shoutout-panel-url-as-button:active, .left-panel-frame.shoutout-panel-new .shoutout-panel-url-as-button:hover, .left-panel-frame.shoutout-panel-new .shoutout-panel-url-as-button:visited {
      text-decoration: none;
      color: #ffffff; }
  .left-panel-frame.shoutout-panel-new .shoutout-first-time-tour {
    width: 646px; }
    .left-panel-frame.shoutout-panel-new .shoutout-first-time-tour .button {
      background: #3c9ce0; }
      .left-panel-frame.shoutout-panel-new .shoutout-first-time-tour .button .symbol-headerCloseButton path {
        fill: #fff; }
    .left-panel-frame.shoutout-panel-new .shoutout-first-time-tour h1 {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      margin-right: 12px;
      margin-left: 12px; }
    .left-panel-frame.shoutout-panel-new .shoutout-first-time-tour .title {
      margin-bottom: 12px; }
    .left-panel-frame.shoutout-panel-new .shoutout-first-time-tour .bullets-wrapper {
      margin-bottom: 24px; }
    .left-panel-frame.shoutout-panel-new .shoutout-first-time-tour .get-started-add-bt {
      margin-bottom: 12px; }
    .left-panel-frame.shoutout-panel-new .shoutout-first-time-tour .tip-bullets {
      margin: 0 12px; }
      .left-panel-frame.shoutout-panel-new .shoutout-first-time-tour .tip-bullets .bullet {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        margin: 12px 0; }
        .left-panel-frame.shoutout-panel-new .shoutout-first-time-tour .tip-bullets .bullet:last-child {
          margin-bottom: 0; }
    .left-panel-frame.shoutout-panel-new .shoutout-first-time-tour-image-container {
      background-color: #4db7f0;
      font-size: 0;
      margin-bottom: 58px; }
      .left-panel-frame.shoutout-panel-new .shoutout-first-time-tour-image-container-large {
        margin-bottom: 45px; }
      .left-panel-frame.shoutout-panel-new .shoutout-first-time-tour-image-container img {
        margin: 20px auto 0 auto; }
  .left-panel-frame.shoutout-panel-new .left-panel-frame-content .content {
    width: auto; }
  .left-panel-frame.shoutout-panel-new .shoutout-email-panel,
  .left-panel-frame.shoutout-panel-new .super-app-add-elements-panel .content-wrapper {
    width: 474px;
    display: inline-block; }
    .left-panel-frame.shoutout-panel-new .shoutout-email-panel .section-header,
    .left-panel-frame.shoutout-panel-new .super-app-add-elements-panel .content-wrapper .section-header {
      width: 423px !important; }
  .left-panel-frame.shoutout-panel-new .shoutout-email-panel .shoutout-panel-loading {
    padding-top: 20px; }
  .left-panel-frame.shoutout-panel-new .shoutout-email-panel .shoutout-panel-new-user .shoutout-new-user-image {
    width: 100%;
    background-color: #ffffff;
    padding-top: 50px; }
    .left-panel-frame.shoutout-panel-new .shoutout-email-panel .shoutout-panel-new-user .shoutout-new-user-image img {
      vertical-align: bottom; }
  .left-panel-frame.shoutout-panel-new .shoutout-email-panel .shoutout-panel-new-user .shoutout-new-user-overlay {
    position: relative;
    top: -94px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    padding-top: 36px; }
  .left-panel-frame.shoutout-panel-new .shoutout-email-panel .shoutout-panel-new-user .shoutout-new-user-title {
    width: 300px;
    font-size: 20px;
    line-height: 30px; }
  .left-panel-frame.shoutout-panel-new .shoutout-email-panel .shoutout-panel-new-user .shoutout-new-user-title-gs {
    width: 200px;
    font-size: 16px;
    line-height: 24px; }
  .left-panel-frame.shoutout-panel-new .shoutout-email-panel .shoutout-panel-new-user .shoutout-open-templates {
    margin-top: 20px; }
  .left-panel-frame.shoutout-panel-new .shoutout-email-panel .shoutout-email-panel-returning {
    position: relative; }
    .left-panel-frame.shoutout-panel-new .shoutout-email-panel .shoutout-email-panel-returning .shoutout-email-returning-banner-image {
      margin-top: 26px;
      display: inline-block;
      height: 216px; }
    .left-panel-frame.shoutout-panel-new .shoutout-email-panel .shoutout-email-panel-returning .shoutout-email-returning-banner-bottom {
      position: absolute;
      top: 137px;
      left: 0;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.96); }
    .left-panel-frame.shoutout-panel-new .shoutout-email-panel .shoutout-email-panel-returning .shoutout-email-returning-banner-title {
      width: 100%;
      font-size: 16px;
      margin-top: 24px;
      margin-bottom: 0; }
    .left-panel-frame.shoutout-panel-new .shoutout-email-panel .shoutout-email-panel-returning .shoutout-email-returning-banner-action {
      margin-top: 18px;
      height: 30px;
      line-height: 30px;
      font-size: 14px; }
    .left-panel-frame.shoutout-panel-new .shoutout-email-panel .shoutout-email-panel-returning .shoutout-email-returning-shoutouts-iframe {
      margin-top: 4px;
      height: 296px;
      overflow: hidden;
      border: none; }
    .left-panel-frame.shoutout-panel-new .shoutout-email-panel .shoutout-email-panel-returning .shoutout-email-panel-returning-gs {
      min-height: 212px; }
      .left-panel-frame.shoutout-panel-new .shoutout-email-panel .shoutout-email-panel-returning .shoutout-email-panel-returning-gs .shoutout-new-user-gs-banner {
        padding: 0 0 24px 30px; }
        .left-panel-frame.shoutout-panel-new .shoutout-email-panel .shoutout-email-panel-returning .shoutout-email-panel-returning-gs .shoutout-new-user-gs-banner-image {
          text-align: center;
          border-radius: 50%;
          background-color: #ffc233;
          width: 70px;
          height: 70px;
          position: relative;
          display: inline-block;
          vertical-align: top;
          margin-right: 20px; }
          .left-panel-frame.shoutout-panel-new .shoutout-email-panel .shoutout-email-panel-returning .shoutout-email-panel-returning-gs .shoutout-new-user-gs-banner-image img {
            vertical-align: top;
            margin-top: 21px; }
        .left-panel-frame.shoutout-panel-new .shoutout-email-panel .shoutout-email-panel-returning .shoutout-email-panel-returning-gs .shoutout-new-user-gs-banner-notification {
          color: #fff;
          font-size: 10px;
          line-height: 17px;
          text-align: center;
          border-radius: 50%;
          border-width: 4px;
          border-color: #ffffff;
          border-style: solid;
          background-color: #ee5951;
          width: 18px;
          height: 18px;
          position: absolute;
          top: -5px;
          right: -5px;
          display: inline-block;
          vertical-align: top; }
        .left-panel-frame.shoutout-panel-new .shoutout-email-panel .shoutout-email-panel-returning .shoutout-email-panel-returning-gs .shoutout-new-user-gs-banner-content {
          display: inline-block;
          vertical-align: top;
          width: 270px;
          font-size: 14px;
          color: #2b5672;
          text-align: left;
          margin-right: 30px; }
        .left-panel-frame.shoutout-panel-new .shoutout-email-panel .shoutout-email-panel-returning .shoutout-email-panel-returning-gs .shoutout-new-user-gs-banner-text {
          margin-bottom: 17px;
          min-height: 29px;
          text-overflow: ellipsis;
          overflow: hidden; }
        .left-panel-frame.shoutout-panel-new .shoutout-email-panel .shoutout-email-panel-returning .shoutout-email-panel-returning-gs .shoutout-new-user-gs-banner-actions {
          display: flex;
          align-items: center;
          justify-content: space-between; }
        .left-panel-frame.shoutout-panel-new .shoutout-email-panel .shoutout-email-panel-returning .shoutout-email-panel-returning-gs .shoutout-new-user-gs-banner-action {
          height: 24px;
          line-height: 24px;
          max-width: 150px;
          font-size: 14px; }
      .left-panel-frame.shoutout-panel-new .shoutout-email-panel .shoutout-email-panel-returning .shoutout-email-panel-returning-gs .shoutout-new-user-image {
        background: transparent; }
      .left-panel-frame.shoutout-panel-new .shoutout-email-panel .shoutout-email-panel-returning .shoutout-email-panel-returning-gs .shoutout-new-user-overlay {
        top: -60px; }
  .left-panel-frame.shoutout-panel-new .tabs li:nth-child(2),
  .left-panel-frame.shoutout-panel-new .tabs li:nth-child(4) {
    display: none; }

.blog-pages-panel {
  height: 100%; }

.wix-store-pages-panel,
.blog-pages-panel {
  background-color: #f5f7f9;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%; }
  .wix-store-pages-panel ul,
  .blog-pages-panel ul {
    margin: 0 auto; }
  .wix-store-pages-panel .first-time-info-box,
  .blog-pages-panel .first-time-info-box {
    margin-top: 0;
    order: 0;
    flex: 0 0 auto;
    margin-bottom: 24px; }
  .wix-store-pages-panel .pages-panel-add-gallery-section,
  .blog-pages-panel .pages-panel-add-gallery-section {
    min-height: 90px;
    padding: 14px 40px 25px 40px;
    word-wrap: break-word;
    border-top: solid 1px #cde;
    background: #ffffff;
    z-index: 1;
    box-sizing: border-box;
    order: 0;
    flex: 0 0 auto; }
    .wix-store-pages-panel .pages-panel-add-gallery-section .pages-panel-add-label,
    .blog-pages-panel .pages-panel-add-gallery-section .pages-panel-add-label {
      color: #2b5672;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      text-align: center;
      margin: 0; }
    .wix-store-pages-panel .pages-panel-add-gallery-section svg.addPage,
    .blog-pages-panel .pages-panel-add-gallery-section svg.addPage {
      position: relative;
      top: -25px;
      left: 65px; }
      .wix-store-pages-panel .pages-panel-add-gallery-section svg.addPage path,
      .blog-pages-panel .pages-panel-add-gallery-section svg.addPage path {
        fill: #ffffff; }
    .wix-store-pages-panel .pages-panel-add-gallery-section .add-page-btn,
    .blog-pages-panel .pages-panel-add-gallery-section .add-page-btn {
      margin: 13px auto 2px auto;
      display: block;
      min-width: 185px;
      max-width: 270px; }
      .wix-store-pages-panel .pages-panel-add-gallery-section .add-page-btn span,
      .blog-pages-panel .pages-panel-add-gallery-section .add-page-btn span {
        margin-right: 6px; }
  .wix-store-pages-panel .panel-header > .panel-header-title,
  .blog-pages-panel .panel-header > .panel-header-title {
    font-size: 18px; }

.wix-store-manage-panel {
  height: 100%; }
  .wix-store-manage-panel .manage-panel-description {
    font-size: 14px; }

.wix-store-switch-panel .content-wrapper {
  padding-top: 20px;
  box-sizing: border-box; }
  .wix-store-switch-panel .content-wrapper > .video-link {
    cursor: pointer;
    display: block;
    margin: 0 0 60px 0;
    position: relative; }
    .wix-store-switch-panel .content-wrapper > .video-link > .play-big {
      background: rgba(22, 45, 61, 0.2);
      border: 2px solid #ffffff;
      border-radius: 50%;
      height: 80px;
      left: 50%;
      outline: none;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 80px; }
      .wix-store-switch-panel .content-wrapper > .video-link > .play-big:after {
        border-left: 30px solid #ffffff;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        height: 0;
        width: 0;
        content: '';
        display: inline-block;
        left: 4px;
        position: relative; }
    .wix-store-switch-panel .content-wrapper > .video-link > img {
      border-radius: 4px;
      vertical-align: middle;
      width: 310px; }

.wix-store-add-panel-view {
  border-bottom: 1px solid #cfcfcf; }
  .wix-store-add-panel-view .pre-install-section .inner-image-gallery {
    width: 100%; }
    .wix-store-add-panel-view .pre-install-section .inner-image-gallery .gallery-navigation-wrapper {
      margin: 13px auto 15px auto; }
  .wix-store-add-panel-view .pre-install-section .tip-bullets .bullet {
    margin-bottom: 10px; }
  .wix-store-add-panel-view .pre-install-section .promotional-add-btn {
    margin: 30px auto 0 auto; }
  .wix-store-add-panel-view .pre-install-section .promotional-link {
    font-size: 16px;
    margin: 17px auto; }

.wixstores-migration {
  text-align: center; }
  .wixstores-migration h2,
  .wixstores-migration p {
    margin: 0;
    padding: 0;
    font-weight: 300; }
  .wixstores-migration p {
    font-size: 16px;
    line-height: 24px; }
  .wixstores-migration .control-button {
    padding: 0 20px; }
  .wixstores-migration .panel-header,
  .wixstores-migration .content {
    padding: 0 50px; }
  .wixstores-migration .panel-header h2,
  .wixstores-migration .panel-header p {
    color: #fff; }
  .wixstores-migration .panel-header h2 {
    font-size: 30px; }
  .wixstores-migration .panel-header .close {
    top: 18px;
    margin-top: 0; }
  .wixstores-migration .panel-header.half-height {
    height: 50%;
    padding-top: 45px; }
  .wixstores-migration .content {
    position: relative;
    padding-top: 84px; }
    .wixstores-migration .content p {
      color: #2b5672;
      text-align: left; }
    .wixstores-migration .content .text-group {
      margin-bottom: 24px; }
    .wixstores-migration .content .symbol.main-image {
      position: absolute;
      top: 0;
      transform: translate(-50%, -50%); }
    .wixstores-migration .content .action-buttons {
      margin-top: 38px; }
    .wixstores-migration .content .control-button {
      height: 32px; }

.wixstores-migration-success-popup {
  width: 744px;
  height: 574px; }
  .wixstores-migration-success-popup .panel-header {
    height: 124px;
    line-height: 124px; }
    .wixstores-migration-success-popup .panel-header .title {
      display: inline-block;
      line-height: normal;
      vertical-align: middle; }
  .wixstores-migration-success-popup .content {
    padding: 50px 88px 0; }
    .wixstores-migration-success-popup .content .domain-preview {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .wixstores-migration-success-popup .content .domain-preview .domain-input-wrapper, .wixstores-migration-success-popup .content .domain-preview .domain-input-with-buttons .address-part, .domain-input-with-buttons .wixstores-migration-success-popup .content .domain-preview .address-part {
        width: 450px; }
      .wixstores-migration-success-popup .content .domain-preview .btn-confirm-secondary {
        margin-left: 12px; }
    .wixstores-migration-success-popup .content .panel-shout-out {
      margin-top: 64px; }
    .wixstores-migration-success-popup .content .action-buttons {
      position: absolute;
      right: 24px; }

.wixstores-switch-progress-popup {
  width: 674px;
  height: 438px; }
  .wixstores-switch-progress-popup .panel-header {
    height: 102px; }
    .wixstores-switch-progress-popup .panel-header h2 {
      line-height: 102px; }
  .wixstores-switch-progress-popup .content {
    padding-top: 180px; }
    .wixstores-switch-progress-popup .content .store-switch-progress {
      height: 80px;
      margin-left: -40px;
      margin-top: -40px;
      top: 110px;
      width: 40px; }
    .wixstores-switch-progress-popup .content .description {
      text-align: center; }

.wixstores-migration-fail-popup {
  width: 674px;
  height: 534px; }
  .wixstores-migration-fail-popup .panel-header {
    height: 182px; }
    .wixstores-migration-fail-popup .panel-header h2 {
      line-height: 112px; }
  .wixstores-migration-fail-popup .content .text-group {
    margin-bottom: 24px; }

.wixstores-switch-popup {
  width: 674px;
  height: 538px; }
  .wixstores-switch-popup .panel-header {
    padding-top: 45px;
    height: 254px; }
  .wixstores-switch-popup .content .symbol.main-image {
    transform: translate(-50%, -70%);
    height: 142px; }
  .wixstores-switch-popup .content .description {
    text-align: left; }
  .wixstores-switch-popup .content .btn-confirm-secondary {
    margin-right: 24px; }

.wixstores-migration-preview-welcome-popup {
  width: 720px; }
  .wixstores-migration-preview-welcome-popup .panel-header {
    height: 224px; }
    .wixstores-migration-preview-welcome-popup .panel-header h2 {
      line-height: 152px; }
  .wixstores-migration-preview-welcome-popup .content {
    padding-bottom: 38px; }
    .wixstores-migration-preview-welcome-popup .content .symbol.main-image {
      transform: translate(-50%, -60%); }
    .wixstores-migration-preview-welcome-popup .content .text-group {
      margin-bottom: 24px; }
    .wixstores-migration-preview-welcome-popup .content .action-buttons {
      align-items: center;
      display: flex;
      justify-content: space-between; }

.manage-bookings {
  padding-top: 26px;
  padding-bottom: 29px;
  width: 100%;
  height: 139px;
  background: url("//static.parastorage.com/services/santa-resources/resources/editor/wixBookings/appMenuPanel/main/manageBookings/manageBookings_01.png") center no-repeat; }

.super-app-menu-panel.dynamic-height {
  max-height: 626px; }

.video-search-panel {
  min-width: 430px; }
  .video-search-panel > .focus-panel-frame-content {
    position: relative; }
    .video-search-panel > .focus-panel-frame-content .control-button {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      height: 32px;
      min-width: 107px;
      max-width: 195px; }
  .video-search-panel .content-wrapper {
    display: flex;
    flex-direction: column; }
  .video-search-panel .search-info {
    height: 57px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin: 0;
    padding: 0 29px;
    font-size: 16px; }
  .video-search-panel .video-search-content {
    height: calc(100% - 180px);
    margin: 0 15px 0 20px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .video-search-panel .video-search-content .custom-scroll {
      display: flex;
      height: 100%;
      width: 100%; }
    .video-search-panel .video-search-content .outer-container {
      display: flex; }
    .video-search-panel .video-search-content .video-search-results {
      margin-left: 5px; }
    .video-search-panel .video-search-content .video-search-empty-state {
      margin-top: -30px;
      text-align: center;
      font-size: 18px; }
  .video-search-panel footer {
    height: 69px;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 18px;
    box-sizing: border-box; }

.video-preview-modal .focus-panel-frame-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .video-preview-modal .focus-panel-frame-content header {
    width: 100%;
    box-sizing: border-box;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    padding: 0 10px; }
    .video-preview-modal .focus-panel-frame-content header .title {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      font-weight: normal;
      color: #162d3d;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }
    .video-preview-modal .focus-panel-frame-content header .close {
      position: relative;
      width: 21px;
      height: 21px;
      border-radius: 50%;
      border: none;
      padding: 0;
      background-color: #d3edff;
      outline: none;
      flex-shrink: 0;
      margin-left: 5px; }
      .video-preview-modal .focus-panel-frame-content header .close svg {
        fill: #3899ec;
        transform: scale(0.8);
        position: absolute;
        top: -2px;
        left: -2px; }
      .video-preview-modal .focus-panel-frame-content header .close:hover {
        background-color: #eaf7ff; }
  .video-preview-modal .focus-panel-frame-content main {
    bottom: 10px;
    left: 10px;
    width: 640px;
    height: 345px;
    flex-shrink: 0; }
  .video-preview-modal .focus-panel-frame-content footer {
    border-radius: 0 0 8px 8px;
    height: 54px;
    width: 100%;
    background: #fff;
    bottom: 0; }

.video-search-results {
  display: flex;
  flex-wrap: wrap; }
  .video-search-results .load-more {
    width: 100%;
    text-align: center;
    height: 50px;
    position: relative; }

.video-search-item {
  display: block;
  width: 174px;
  height: 123px;
  position: relative;
  margin: 5px;
  cursor: pointer; }
  .video-search-item img {
    width: 100%;
    height: 100%; }
  .video-search-item .title {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    height: 32px;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgba(22, 45, 61, 0.65);
    color: #fff;
    line-height: 32px;
    text-align: left;
    padding: 0 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box; }
  .video-search-item .open-preview-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 19px;
    width: 25px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    text-align: center;
    position: absolute;
    bottom: 40px;
    left: 6px; }
    .video-search-item .open-preview-button span {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4.5px 0 4.5px 7px;
      margin-left: 2px;
      margin-top: -0.5px;
      border-color: transparent transparent transparent #ffffff; }
    .video-search-item .open-preview-button:hover {
      background-color: #4eb7f5; }
  .video-search-item .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    border: 3px #3899ec solid;
    border-radius: 5px;
    left: -3px;
    top: -3px;
    opacity: 0; }
  .video-search-item:hover:not(.selected) .overlay {
    opacity: 0.5;
    background-color: #3899ec; }
  .video-search-item.selected .overlay {
    opacity: 1; }
  .video-search-item.selected .title {
    background-color: #3899ec; }

.video-search-form {
  position: relative;
  background-color: #b1ddf8;
  padding: 11px 18px;
  display: flex; }
  .video-search-form .control-button[disabled] {
    color: #3899ec;
    background-color: #eaf7ff; }
  .video-search-form input {
    flex-grow: 1;
    margin-right: 9px;
    line-height: 16px;
    padding: 0 24px 0 37px;
    border-radius: 18px;
    border: none;
    outline: none;
    font-size: 14px; }
    .video-search-form input:-webkit-autofill {
      box-shadow: 0 0 0 1000px #fff inset; }
    .video-search-form input::-ms-clear {
      display: none; }
  .video-search-form svg {
    position: absolute;
    left: 30px;
    top: 20px; }

.add-panel-draggable-wrapper {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1350; }
  .add-panel-draggable-wrapper > .add-panel-draggable-item {
    background: rgba(45, 245, 253, 0.2) no-repeat center center;
    border: 1px dashed #2df5fd;
    height: 100px;
    pointer-events: none;
    position: fixed;
    transition: width, height, background-size;
    transition-duration: 200ms;
    width: 200px;
    z-index: 1350; }

.add-panel-category-list {
  background: #3899ec linear-gradient(360deg, rgba(6, 49, 85, 0.2), rgba(56, 153, 236, 0));
  display: inline-block;
  height: calc(100% - 28px);
  padding: 14px 0;
  vertical-align: top; }
  .add-panel-category-list > .category {
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 2px;
    max-height: 30px;
    min-height: 14px;
    padding: 0 10px 0 3px;
    position: relative; }
    .add-panel-category-list > .category > .category-name-wrapper {
      border-radius: 20px;
      display: inline-block;
      height: 100%;
      overflow: hidden;
      padding: 0 14px;
      position: relative; }
      .add-panel-category-list > .category > .category-name-wrapper.is-selected, .add-panel-category-list > .category > .category-name-wrapper:not(.is-selected):hover {
        box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.11), inset 1px 1px 2px rgba(0, 0, 0, 0.15); }
      .add-panel-category-list > .category > .category-name-wrapper.is-selected {
        background-color: rgba(0, 0, 0, 0.2); }
      .add-panel-category-list > .category > .category-name-wrapper:not(.is-selected):hover {
        background-color: rgba(0, 0, 0, 0.07); }
      .add-panel-category-list > .category > .category-name-wrapper > .category-name {
        display: inline-block;
        position: relative;
        height: 14px;
        top: calc(50% - 14px / 2 - 2px); }

.inner-navigation {
  height: 36px;
  left: 100%;
  position: absolute;
  top: 10px; }
  .larger-than-window.inner-navigation {
    visibility: hidden; }
  .closed.inner-navigation {
    width: 17px; }
  .hovered.inner-navigation {
    width: 36px; }
  .open.inner-navigation, .expand.inner-navigation {
    height: auto;
    top: 0; }
  .inner-navigation > .inner-nav-section-wrapper {
    background-color: #fff;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: inset 4px 0 3px -2px rgba(0, 0, 0, 0.2), 2px 1px 6px -1px rgba(0, 0, 0, 0.2); }
    .closed.inner-navigation > .inner-nav-section-wrapper {
      background-color: rgba(255, 255, 255, 0.7); }
    .inner-navigation > .inner-nav-section-wrapper > .hover-button {
      padding: 0 12px;
      height: 36px; }
      .closed.inner-navigation > .inner-nav-section-wrapper > .hover-button, .hovered.inner-navigation > .inner-nav-section-wrapper > .hover-button {
        padding: 0;
        position: relative; }
      .hovered.inner-navigation > .inner-nav-section-wrapper > .hover-button {
        cursor: pointer;
        left: 12px; }
      .open.inner-navigation > .inner-nav-section-wrapper > .hover-button, .expand.inner-navigation > .inner-nav-section-wrapper > .hover-button {
        left: 2px;
        position: relative;
        top: 26px;
        border-bottom-right-radius: 0; }
      .inner-navigation > .inner-nav-section-wrapper > .hover-button > .stripes {
        background: #3899ec;
        cursor: pointer;
        height: 1px; }
        .closed.inner-navigation > .inner-nav-section-wrapper > .hover-button > .stripes, .hovered.inner-navigation > .inner-nav-section-wrapper > .hover-button > .stripes {
          position: relative;
          top: 14px; }
        .closed.inner-navigation > .inner-nav-section-wrapper > .hover-button > .stripes {
          width: 5px; }
        .hovered.inner-navigation > .inner-nav-section-wrapper > .hover-button > .stripes {
          width: 12px; }
        .inner-navigation > .inner-nav-section-wrapper > .hover-button > .stripes:before, .inner-navigation > .inner-nav-section-wrapper > .hover-button > .stripes:after {
          background: #3899ec;
          content: "";
          height: 1px;
          position: absolute; }
          .closed.inner-navigation > .inner-nav-section-wrapper > .hover-button > .stripes:before, .closed.inner-navigation > .inner-nav-section-wrapper > .hover-button > .stripes:after {
            width: 5px; }
          .hovered.inner-navigation > .inner-nav-section-wrapper > .hover-button > .stripes:before, .hovered.inner-navigation > .inner-nav-section-wrapper > .hover-button > .stripes:after {
            width: 12px; }
        .closed.inner-navigation > .inner-nav-section-wrapper > .hover-button > .stripes:before, .hovered.inner-navigation > .inner-nav-section-wrapper > .hover-button > .stripes:before {
          top: 4px; }
        .closed.inner-navigation > .inner-nav-section-wrapper > .hover-button > .stripes:after, .hovered.inner-navigation > .inner-nav-section-wrapper > .hover-button > .stripes:after {
          top: 8px; }
      .inner-navigation > .inner-nav-section-wrapper > .hover-button > .arrow {
        width: 7px;
        height: 7px;
        border: solid #3899ec;
        cursor: pointer; }
      .inner-navigation > .inner-nav-section-wrapper > .hover-button > .left-arrow {
        border-width: 0 0 1px 1px;
        transform: rotate(45deg); }
    .inner-navigation > .inner-nav-section-wrapper > .inner-nav-sections {
      list-style: none;
      margin: 0;
      padding: 16px 0;
      position: relative; }
      .inner-navigation > .inner-nav-section-wrapper > .inner-nav-sections > .inner-nav-section-item {
        cursor: pointer;
        margin-bottom: 18px;
        padding: 0 12px;
        position: relative;
        white-space: nowrap; }
        .inner-navigation > .inner-nav-section-wrapper > .inner-nav-sections > .inner-nav-section-item:last-child {
          margin-bottom: 0; }
        .inner-navigation > .inner-nav-section-wrapper > .inner-nav-sections > .inner-nav-section-item > .nav-label {
          color: #395671;
          display: none;
          font-size: 12px;
          padding-left: 14px;
          padding-right: 6px; }
          .expand.inner-navigation > .inner-nav-section-wrapper > .inner-nav-sections > .inner-nav-section-item > .nav-label {
            display: inline-block; }
        .inner-navigation > .inner-nav-section-wrapper > .inner-nav-sections > .inner-nav-section-item:hover > .nav-label {
          color: #3899ec; }
        .expand.inner-navigation > .inner-nav-section-wrapper > .inner-nav-sections > .inner-nav-section-item.selected > .nav-label {
          color: #3899ec; }

.nav-pin {
  width: 12px;
  height: 12px;
  display: inline-block;
  line-height: 12px;
  position: relative;
  text-align: center;
  vertical-align: middle; }
  .nav-pin:after {
    width: 6px;
    height: 6px;
    background-color: #fff;
    border: solid 2px #cceaff;
    border-radius: 50%;
    content: "";
    display: inline-block;
    position: relative; }
    .inner-nav-section-item:hover .nav-pin:after {
      background-color: #cceaff; }
    .expand .selected .nav-pin:after,
    .open .selected .nav-pin:after {
      width: 10px;
      height: 10px;
      background-color: #3899ec;
      left: -1px; }
  .nav-pin:before {
    width: 4px;
    height: 31px;
    background-color: #cceaff;
    content: "";
    left: 50%;
    margin-left: -2px;
    margin-top: -4px;
    position: absolute;
    top: 100%; }
    .inner-nav-section-item:last-child .nav-pin:before {
      display: none; }

.anchors-navigation {
  height: auto;
  left: 100%;
  position: absolute;
  top: 0; }
  .larger-than-window.anchors-navigation {
    visibility: hidden; }
  .anchors-navigation > .anchors-nav-section-wrapper {
    background-color: #fff;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: inset 4px 0 3px -2px rgba(0, 0, 0, 0.2), 2px 1px 6px -1px rgba(0, 0, 0, 0.2); }
    .anchors-navigation > .anchors-nav-section-wrapper > .anchors-nav-sections {
      list-style: none;
      margin: 0;
      padding: 16px 0;
      position: relative; }
      .anchors-navigation > .anchors-nav-section-wrapper > .anchors-nav-sections > .anchors-nav-section-item {
        cursor: pointer;
        margin-bottom: 18px;
        padding: 0 12px;
        position: relative; }
        .anchors-navigation > .anchors-nav-section-wrapper > .anchors-nav-sections > .anchors-nav-section-item:last-child {
          margin-bottom: 0; }

.anchors-nav-pin {
  width: 12px;
  height: 12px;
  display: inline-block;
  line-height: 12px;
  position: relative;
  text-align: center;
  vertical-align: middle; }
  .anchors-nav-pin:after {
    width: 6px;
    height: 6px;
    background-color: #fff;
    border: solid 2px #cceaff;
    border-radius: 50%;
    content: "";
    display: inline-block;
    position: relative; }
    .anchors-nav-section-item:hover:not(.selected) .anchors-nav-pin:after {
      background-color: #cceaff; }
    .selected .anchors-nav-pin:after {
      width: 10px;
      height: 10px;
      background-color: #3899ec;
      left: -1px; }
  .anchors-nav-pin:before {
    width: 4px;
    height: 31px;
    background-color: #cceaff;
    content: "";
    left: 50%;
    margin-left: -2px;
    margin-top: -4px;
    position: absolute;
    top: 100%; }
    .anchors-nav-section-item:last-child .anchors-nav-pin:before {
      display: none; }

.how-to-video-tooltip-content {
  max-width: 204px;
  word-wrap: break-word; }
  .how-to-video-tooltip-content > .container-close {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer; }
    .how-to-video-tooltip-content > .container-close > .symbol-close > path {
      fill: #162d3d; }
      .how-to-video-tooltip-content > .container-close:hover > .symbol-close > path {
        fill: #3899ec; }
  .how-to-video-tooltip-content > .control-label {
    margin-bottom: 6px; }
  .how-to-video-tooltip-content > .container-thumbnail {
    position: relative;
    left: -4px;
    margin: 1px 0 6px 0;
    cursor: pointer; }
    .how-to-video-tooltip-content > .container-thumbnail > .thumbnail {
      border: 4px solid #fff;
      border-radius: 6px;
      box-shadow: 0 0 0 1px #d9e1e8;
      width: 204px; }
      .how-to-video-tooltip-content > .container-thumbnail:hover > .thumbnail {
        box-shadow: 0 0 0 2px #d3edff; }
    .how-to-video-tooltip-content > .container-thumbnail > .symbol-play-video {
      position: absolute;
      top: calc(50% - 42px / 2);
      left: calc(50% - 42px / 2);
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background-color: rgba(22, 45, 61, 0.7);
      border: 2px solid #fff; }
      .how-to-video-tooltip-content > .container-thumbnail:hover > .symbol-play-video {
        border-color: #7fccf7; }
      .how-to-video-tooltip-content > .container-thumbnail > .symbol-play-video .st0 {
        fill: #fff; }
        .how-to-video-tooltip-content > .container-thumbnail:hover > .symbol-play-video .st0 {
          fill: #7fccf7; }
      .how-to-video-tooltip-content > .container-thumbnail > .symbol-play-video .st1 {
        fill: transparent; }

.category-view-wrapper {
  background: #fff;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  box-sizing: border-box;
  display: inline-block;
  height: 100%;
  overflow: hidden;
  white-space: normal; }
  .category-view-wrapper.inner-navigation-open {
    border-top-right-radius: 0; }
  .category-view-wrapper > .header {
    border-bottom: 1px solid #d9e1e8;
    height: 54px; }
    .category-view-wrapper > .header > .title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      top: 17px;
      color: #395671;
      font-size: 18px;
      left: 24px;
      width: 231px;
      display: inline-block; }
    .category-view-wrapper > .header > .actions {
      float: right; }
      .category-view-wrapper > .header > .actions > .button {
        width: 25px;
        height: 25px;
        display: inline-block;
        position: relative;
        color: #3899ec;
        background-color: #d3edff;
        border-radius: 50%;
        cursor: pointer;
        line-height: 25px;
        text-align: center;
        top: 16px;
        box-sizing: border-box; }
        .category-view-wrapper > .header > .actions > .button.close {
          margin-left: 9px;
          margin-right: 14px; }
          .category-view-wrapper > .header > .actions > .button.close:active {
            background-color: #d3edff; }
        .category-view-wrapper > .header > .actions > .button.help-button {
          top: 8px; }
          .category-view-wrapper > .header > .actions > .button.help-button:active {
            background-color: #ffffff;
            border: solid 1px #0099ef; }
        .category-view-wrapper > .header > .actions > .button:hover {
          background-color: #eaf7ff; }

.category-view {
  height: calc(100% - 55px); }
  .category-view .sections {
    position: relative; }

.live-comps {
  padding-bottom: 9px;
  margin-bottom: 21px; }
  .live-comps .info-icon {
    bottom: 6px;
    right: 6px;
    position: absolute; }
  .live-comps .section-live-site-wrapper {
    margin: 24px 24px 0 24px; }
    .live-comps .section-live-site-wrapper input,
    .live-comps .section-live-site-wrapper textarea {
      pointer-events: none; }
    .live-comps .section-live-site-wrapper,
    .live-comps .section-live-site-wrapper * {
      cursor: pointer !important; }
    .live-comps .section-live-site-wrapper .label-describing-comp {
      color: #2b5672; }

.section-live-site-wrapper h1,
.section-live-site-wrapper h2,
.section-live-site-wrapper h3,
.section-live-site-wrapper h4,
.section-live-site-wrapper h5,
.section-live-site-wrapper h6 {
  margin: 0; }

.section-live-site-wrapper p {
  -webkit-margin-before: 0;
  -moz-margin-before: 0;
  -o-margin-before: 0;
  margin-before: 0;
  -webkit-margin-after: 0;
  -moz-margin-after: 0;
  -o-margin-after: 0;
  margin-after: 0;
  -webkit-margin-start: 0;
  -moz-margin-start: 0;
  -o-margin-start: 0;
  margin-start: 0;
  -webkit-margin-end: 0;
  -moz-margin-end: 0;
  -o-margin-end: 0;
  margin-end: 0;
  margin: 0; }

.section-live-site-wrapper .section-live-site-block-layer {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }
  .section-live-site-wrapper .section-live-site-block-layer .symbol-selectedItemDesignPanel {
    position: absolute; }
  .section-live-site-wrapper .section-live-site-block-layer .section-live-site-item {
    box-sizing: content-box;
    position: absolute; }
    .section-live-site-wrapper .section-live-site-block-layer .section-live-site-item.design-panel-no-frame-skin-item > div {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      color: #fff;
      text-align: center; }
      .section-live-site-wrapper .section-live-site-block-layer .section-live-site-item.design-panel-no-frame-skin-item > div > span {
        background-color: rgba(22, 45, 61, 0.5); }
    .section-live-site-wrapper .section-live-site-block-layer .section-live-site-item.design-panel-transparent-skin-item {
      background-color: #f2f2f2;
      background-image: linear-gradient(45deg, #dbdbdb 25%, transparent 25%, transparent 75%, #dbdbdb 75%, #dbdbdb), linear-gradient(45deg, #dbdbdb 25%, transparent 25%, transparent 75%, #dbdbdb 75%, #dbdbdb);
      background-position: 0 0, 8px 8px;
      background-size: 16px 16px; }
      .section-live-site-wrapper .section-live-site-block-layer .section-live-site-item.design-panel-transparent-skin-item.section-scaled-in-half {
        background-position: 0 0, 16px 16px;
        background-size: 32px 32px; }
      .section-live-site-wrapper .section-live-site-block-layer .section-live-site-item.design-panel-transparent-skin-item > div {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 14px;
        color: #162d3d;
        text-align: center; }

.design-data-preset-items-container {
  position: relative;
  padding: 7px 24px 0; }
  .design-data-preset-items-container .design-data-preset-item-wrapper {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 12px; }
    .design-data-preset-items-container .design-data-preset-item-wrapper:nth-child(2n) {
      margin-left: 12px; }
    .design-data-preset-items-container .design-data-preset-item-wrapper .design-data-preset-item {
      width: 114px;
      height: 72px;
      box-sizing: border-box;
      cursor: pointer; }
      .design-data-preset-items-container .design-data-preset-item-wrapper .design-data-preset-item.empty-preset {
        border: 1px solid #ccc;
        text-align: center;
        line-height: 72px;
        color: #333; }

.list-section {
  padding-bottom: 19px; }

.preset-section.with-labels {
  padding-bottom: 5px; }

.preset-section.without-labels {
  padding-bottom: 9px; }

.preset-section.no-header-sibling {
  padding-bottom: 0; }

.preset-section > .items-wrapper {
  margin-left: auto;
  margin-right: auto; }
  .category-view-wrapper .preset-section > .items-wrapper {
    margin-left: 14px; }
  .preset-section > .items-wrapper > .items {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    overflow: hidden; }
    .preset-section > .items-wrapper > .items.has-label {
      margin-bottom: 28px; }
    .preset-section > .items-wrapper > .items.disabled-preset-section {
      opacity: 0.2;
      pointer-events: none; }

.preset-section .image-items-wrapper {
  width: 100%;
  height: 100%;
  text-align: center; }

.add-panel-welcome-section > .banner {
  width: 352px;
  height: 162px;
  margin-bottom: 24px; }

.add-panel-welcome-section > .title-container {
  text-align: center;
  padding: 0 24px;
  margin-bottom: 6px; }
  .add-panel-welcome-section > .title-container > .control-label {
    display: block; }
  .add-panel-welcome-section > .title-container > .title {
    margin-bottom: 6px; }

.add-panel-welcome-section > .composite-promotional-list {
  padding: 18px 24px 12px; }
  .add-panel-welcome-section > .composite-promotional-list > .control-promotional-list > .promotional-list-item {
    align-items: center; }
    .add-panel-welcome-section > .composite-promotional-list > .control-promotional-list > .promotional-list-item > svg {
      width: 11px;
      height: 10px;
      align-self: flex-start;
      margin-right: 13px;
      margin-top: 4px;
      fill: #60bc57; }

.preset-item {
  position: absolute;
  cursor: pointer; }
  .preset-item.small-gap {
    overflow: visible; }
  .preset-item > .video {
    position: absolute;
    top: 0; }
    .preset-item > .video.videoPaused {
      visibility: hidden; }
  .preset-item.live-text {
    position: relative;
    margin-bottom: 14px;
    margin-left: 14px; }
    .preset-item.live-text:first-child {
      margin-top: 24px; }
    .preset-item.live-text:last-child {
      margin-bottom: 0; }
    .preset-item.live-text:nth-child(1), .preset-item.live-text:nth-child(2) {
      margin-bottom: 10px; }
    .preset-item.live-text > .preset-item-label {
      position: initial;
      visibility: hidden;
      display: block;
      font-size: 13px; }
  .preset-item.live-text:hover {
    color: #2b5672; }
    .preset-item.live-text:hover > .preset-item-label {
      visibility: visible; }
  .preset-item > .preset-item-label {
    position: absolute;
    top: calc(100% - 14px);
    right: 0;
    left: 0;
    text-align: center;
    display: none;
    font-size: 12px;
    color: #2b5672; }
    .small-gap.preset-item > .preset-item-label {
      bottom: -5px; }
    .preset-item:hover > .preset-item-label.hover {
      display: block; }
    .preset-item > .preset-item-label.align-left {
      text-align: left;
      left: 11px; }
    .preset-item > .preset-item-label.always {
      display: block; }
    .preset-item > .preset-item-label.never {
      display: none; }
  .preset-item > .info-wrapper {
    opacity: 0; }
    .preset-item:hover > .info-wrapper {
      opacity: 1; }

.preset-area-item {
  outline: none; }

.section-header {
  margin: 0 auto 5px; }
  .section-header > .title-line {
    font-size: 14px;
    position: relative;
    border-bottom: 1px solid #8092a4;
    padding-bottom: 5px;
    color: #2b5672;
    display: flex; }
    .section-header > .title-line > .title {
      display: inline-block;
      width: calc(100% - 42px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left; }
  .list-section .section-header {
    margin-bottom: 0; }
  .preset-section.without-labels .section-header {
    margin-bottom: 7px; }
  .section-wrapper.invisible-header .section-header {
    display: none; }

.section-footer {
  text-align: center;
  padding-top: 6px; }
  .section-footer > .link {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 13px;
    padding: 4px 9px 5px 13px;
    border-radius: 18px;
    cursor: pointer;
    color: #3899ec;
    background-color: #eaf7ff; }
    .section-footer > .link:hover {
      background-color: #d3edff; }
  .preset-section.without-labels.with-footer .section-footer {
    margin-top: 3px; }

.category-view-wrapper .section-wrapper:not(.no-space-before-section) {
  margin-top: 19px;
  padding-top: 3px;
  overflow: hidden; }

.section-wrapper h1,
.section-wrapper h2,
.section-wrapper h3,
.section-wrapper h4,
.section-wrapper h5,
.section-wrapper h6 {
  margin: 0; }

.section-wrapper p {
  -webkit-margin-before: 0;
  -moz-margin-before: 0;
  -o-margin-before: 0;
  margin-before: 0;
  -webkit-margin-after: 0;
  -moz-margin-after: 0;
  -o-margin-after: 0;
  margin-after: 0;
  -webkit-margin-start: 0;
  -moz-margin-start: 0;
  -o-margin-start: 0;
  margin-start: 0;
  -webkit-margin-end: 0;
  -moz-margin-end: 0;
  -o-margin-end: 0;
  margin-end: 0;
  margin: 0; }

.section-wrapper:hover .section-header .info-icon {
  display: block; }

.cannot-add-list {
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  width: 352px;
  margin-top: 38px; }
  .cannot-add-list > .error-title {
    font-size: 18px;
    margin-top: 26px; }
  .cannot-add-list > .support-link {
    color: #3899ec;
    text-decoration: none; }
    .cannot-add-list > .support-link:hover {
      text-decoration: underline; }

.list-customize-fields .first-time-info-box {
  margin-bottom: 12px; }

.list-customize-fields .field-checkbox-component {
  padding: 24px; }

.design-customization-checkbox .controls-wrapper {
  padding: 22px 24px; }

#designPanel .comp-panel-content {
  max-height: 504px; }
  #designPanel .comp-panel-content.hideOverFlow {
    overflow: hidden; }

#designPanel .sections {
  overflow: hidden; }
  #designPanel .sections.panel-has-children {
    padding-bottom: 84px; }
    #designPanel .sections.panel-has-children .section-wrapper:last-child {
      padding-bottom: 0; }
  #designPanel .sections .section-header {
    padding-top: 18px; }
    #designPanel .sections .section-header .title {
      width: 100%; }

#designPanel .button-wrapper {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0, white 90%);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  bottom: 0;
  height: 84px;
  width: 100%;
  position: absolute;
  text-align: center; }
  #designPanel .button-wrapper .button {
    bottom: -23px;
    position: relative;
    line-height: 26px; }
    #designPanel .button-wrapper .button.animate-button {
      animation-duration: 0.6s;
      animation-timing-function: ease;
      animation-name: tada; }

@keyframes tada {
  60% {
    background-color: #d3edff;
    transform: scale(1.05); } }

#designPanel .design-panel-selected-item svg {
  position: absolute; }

#designPanel .design-panel-selected-item.preset-item svg {
  top: 0;
  right: -2px;
  height: 31px;
  width: 31px; }

#designPanel .design-panel-transparent-skin-item > div {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  color: #162d3d;
  text-align: center; }

#designPanel .design-panel-no-frame-skin-item > div {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  color: #fff;
  text-align: center; }
  #designPanel .design-panel-no-frame-skin-item > div > span {
    background-color: rgba(22, 45, 61, 0.5); }

#designPanel .design-panel-no-frame-skin-item-discover-more > div {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  color: #fff;
  text-align: center; }
  #designPanel .design-panel-no-frame-skin-item-discover-more > div > span {
    background-color: rgba(22, 45, 61, 0.5);
    font-size: 14px; }

#designPanel .preset-item.design-panel-transparent-skin-item > div, #designPanel .preset-item.design-panel-no-frame-skin-item > div {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 18px; }

#designPanel .section-live-site-wrapper .section-live-site-block-layer {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }
  #designPanel .section-live-site-wrapper .section-live-site-block-layer .section-live-site-item {
    box-sizing: content-box;
    position: absolute; }
    #designPanel .section-live-site-wrapper .section-live-site-block-layer .section-live-site-item.design-panel-transparent-skin-item {
      background-color: #f2f2f2;
      background-image: linear-gradient(45deg, #dbdbdb 25%, transparent 25%, transparent 75%, #dbdbdb 75%, #dbdbdb), linear-gradient(45deg, #dbdbdb 25%, transparent 25%, transparent 75%, #dbdbdb 75%, #dbdbdb);
      background-position: 0 0, 4px 4px;
      background-size: 8px 8px; }
      #designPanel .section-live-site-wrapper .section-live-site-block-layer .section-live-site-item.design-panel-transparent-skin-item.section-scaled-in-half {
        background-position: 0 0, 16px 16px;
        background-size: 32px 32px; }

.hidden-item {
  height: 60px;
  border-bottom: 1px solid #ddd;
  position: relative;
  background-color: #fff; }
  .hidden-item:hover {
    background-color: #eaf7ff; }
    .hidden-item:hover .add-button {
      background-color: #4eb7f5; }
      .hidden-item:hover .add-button .symbol-showComp path {
        fill: #fff; }
  .hidden-item .icon {
    position: absolute;
    height: 40px;
    width: 40px;
    box-shadow: 0 0 4px 0 rgba(43, 86, 114, 0.2);
    border-radius: 8px;
    background-repeat: no-repeat;
    left: 25px;
    top: 10px;
    display: flex; }
    .hidden-item .icon svg {
      margin: auto;
      width: 14px;
      height: 14px; }
  .hidden-item .label {
    position: relative;
    margin-left: 80px;
    line-height: 60px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 195px; }
  .hidden-item .add-button {
    position: absolute;
    height: 26px;
    width: 26px;
    right: 17px;
    top: 17px;
    border-radius: 50%;
    text-align: center;
    cursor: pointer; }
    .hidden-item .add-button .symbol-showComp {
      margin-top: 7px; }
      .hidden-item .add-button .symbol-showComp path {
        fill: #3899ec; }
    .hidden-item .add-button:hover {
      background-color: #3899ec; }
      .hidden-item .add-button:hover .symbol-showComp path {
        fill: #fff; }

.hidden-items-container .container-header {
  height: 42px;
  text-align: center;
  line-height: 40px;
  background-color: #f1f3f5;
  color: #2b5672;
  text-overflow: ellipsis;
  overflow: hidden;
  border-bottom: 1px solid #ddd; }

.hidden-items-panel {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  max-height: calc(100vh - 50px - 165px); }
  .hidden-items-panel .custom-scroll {
    width: 352px; }
    .hidden-items-panel .custom-scroll .content {
      max-height: calc(100vh - 50px - 54px - 165px);
      position: relative; }
      .hidden-items-panel .custom-scroll .content .scrollable {
        min-height: 450px;
        display: flex;
        flex-direction: column; }
        .hidden-items-panel .custom-scroll .content .scrollable .first-time-info-box {
          margin-bottom: 12px; }
      .hidden-items-panel .custom-scroll .content .no-items {
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #e3e3e3; }
      .hidden-items-panel .custom-scroll .content .hidden-items-container {
        flex: 1 0 auto; }

.mobile-element-settings {
  position: absolute;
  top: 0;
  left: 340px;
  width: 440px;
  height: 100%;
  border-radius: 0 10px 10px 0;
  background-color: #fff;
  transition-property: width;
  transition-duration: 0.4s;
  transition-timing-function: ease-in;
  overflow: hidden;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.35); }
  .mobile-element-settings .content {
    padding-left: 24px;
    margin-left: 17px; }
  .mobile-element-settings .category-desc {
    color: #000000;
    font-size: 14px;
    font-weight: 300; }
  .mobile-element-settings .learn-more,
  .mobile-element-settings .go-to-preview {
    color: #3899ec;
    cursor: pointer; }
    .mobile-element-settings .learn-more:hover,
    .mobile-element-settings .go-to-preview:hover {
      text-decoration: underline; }

.mobile-item-wrapper {
  display: inline-block;
  vertical-align: top; }
  .mobile-item-wrapper .imgDesc,
  .mobile-item-wrapper .imgDesc2 {
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    text-align: center;
    height: 16px; }
  .mobile-item-wrapper .imgDesc {
    color: #162d3d;
    margin-top: 10px; }
  .mobile-item-wrapper .imgDesc2 {
    font-size: 12px;
    color: #2b5672;
    margin-top: 4px; }
  .mobile-item-wrapper .mobile-item-selection {
    width: 179px;
    border: 1px solid #e3e3e3;
    box-shadow: 0 0 4px 0 rgba(43, 86, 114, 0.29);
    border-radius: 8px;
    cursor: pointer; }
    .mobile-item-wrapper .mobile-item-selection:hover {
      border: 1px solid #7fccf7; }
    .mobile-item-wrapper .mobile-item-selection.selected {
      border: 1px solid #3899ec; }
    .mobile-item-wrapper .mobile-item-selection.mobile-item-optimized {
      background: url("//static.parastorage.com/services/santa-resources/resources/editor/mobileEditor/mobileSettings/optimized.gif") no-repeat 50% 50%; }
    .mobile-item-wrapper .mobile-item-selection.mobile-item-non-optimized {
      margin-right: 15px;
      background: url("//static.parastorage.com/services/santa-resources/resources/editor/mobileEditor/mobileSettings/nonOptimized.png") no-repeat 50% 50%; }
    .mobile-item-wrapper .mobile-item-selection .image {
      width: 180px;
      height: 235px; }

.mobile-element-settings.backToTopEnabled .imageWrapper {
  border: 1px solid #3899ec; }

.mobile-element-settings .img {
  width: 123px;
  height: 205px;
  margin-left: 119px;
  margin-top: 17px;
  background: url("//static.parastorage.com/services/santa-resources/resources/editor/mobileEditor/mobileSettings/backToTop.v3.gif"); }

.mobile-element-settings .imageWrapper {
  width: 373px;
  height: 240px;
  box-shadow: 0 0 4px 0 rgba(43, 86, 114, 0.29);
  border: 1px solid #d9e1e8;
  margin-top: 6px;
  border-radius: 8px; }
  .mobile-element-settings .imageWrapper + .text {
    padding-right: 24px;
    line-height: 20px; }

.mobile-element-settings.action-bar-settings {
  width: 613px; }
  .mobile-element-settings.action-bar-settings .content .category-desc {
    width: 435px; }
    .mobile-element-settings.action-bar-settings .content .category-desc + .control-switch .symbol-switch {
      margin-right: 29px; }

.action-bar-main-content {
  position: absolute;
  bottom: 45px;
  top: 204px;
  left: 30px;
  right: 0; }

.action-bar-menu-wrapper {
  border-top: 1px solid #d9e1e8;
  border-bottom: 1px solid #d9e1e8;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }
  .action-bar-menu-wrapper .action-bar-menu-title {
    background-color: #EFF3F5;
    padding: 13px 20px;
    position: absolute;
    left: -15px;
    top: -1px;
    width: 284px;
    text-align: center;
    color: #075673;
    font-size: 16px;
    border: 1px solid #d9e1e8; }
  .action-bar-menu-wrapper .action-bar-menu {
    position: absolute;
    top: 45px;
    left: 0;
    bottom: 10px;
    border-right: 1px solid #d9e1e8; }
    .action-bar-menu-wrapper .action-bar-menu .boolean-control span.label {
      vertical-align: top;
      margin-left: 5px; }
    .action-bar-menu-wrapper .action-bar-menu .link-label {
      vertical-align: top;
      display: inline-block;
      margin: 5px auto;
      color: #2b5672;
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
      .action-bar-menu-wrapper .action-bar-menu .link-label.link-active {
        color: #3899ec; }
        .action-bar-menu-wrapper .action-bar-menu .link-label.link-active:hover {
          cursor: pointer;
          text-decoration: underline; }
    .action-bar-menu-wrapper .action-bar-menu .text {
      display: inline-block;
      vertical-align: top; }
    .action-bar-menu-wrapper .action-bar-menu .action-bar-item {
      width: 320px;
      margin-left: -10px; }
  .action-bar-menu-wrapper .action-bar-color-scheme {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    overflow: hidden; }
    .action-bar-menu-wrapper .action-bar-color-scheme .action-bar-color-scheme-picker {
      background-color: #FFF;
      width: 224px;
      height: 105px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      margin: 0 24px;
      border-bottom: 1px solid #d9e1e8;
      padding-bottom: 5px; }
      .action-bar-menu-wrapper .action-bar-color-scheme .action-bar-color-scheme-picker .color-scheme-label {
        padding: 15px 13px;
        color: #075673;
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
      .action-bar-menu-wrapper .action-bar-color-scheme .action-bar-color-scheme-picker .control-buttons-group {
        text-align: center; }
    .action-bar-menu-wrapper .action-bar-color-scheme .action-bar-preview {
      background-color: #FFF;
      width: 250px;
      background: no-repeat center;
      background-size: 65%;
      position: absolute;
      right: 0;
      bottom: 8%;
      height: 320px;
      margin: 0 14px; }
      .action-bar-menu-wrapper .action-bar-color-scheme .action-bar-preview .action-bar-menu-preview {
        position: absolute;
        bottom: 73px;
        left: 1px;
        height: 40px;
        background: no-repeat center;
        right: 0;
        width: 100%;
        background-size: 55%; }

.action-bar-footer {
  position: absolute;
  bottom: 0;
  padding: 20px 0 20px 23px;
  margin-left: -23px;
  background-color: #FFF;
  border-top: 1px solid #DDD;
  width: 100%; }

.action-bar-publish {
  cursor: pointer;
  color: #3899ec; }
  .action-bar-publish:hover {
    text-decoration: underline; }

.mobile-settings-header {
  border-bottom: 1px solid #e3e3e3;
  height: 53px; }
  .mobile-settings-header .title {
    display: inline-block;
    margin-bottom: 0;
    padding-left: 24px;
    margin-left: 17px;
    line-height: 54px;
    height: 54px;
    font-size: 18px;
    color: #2b5672; }
  .mobile-settings-header .helpSign {
    width: 25px;
    height: 25px;
    line-height: 25px;
    cursor: pointer;
    text-align: center;
    background-color: #d3edff;
    color: #3899ec;
    border-radius: 50%;
    position: absolute;
    right: 55px;
    top: 14px; }
    .mobile-settings-header .helpSign:hover {
      background-color: #eaf7ff; }
  .mobile-settings-header .symbol-arrowRightBig {
    width: 25px;
    height: 25px;
    cursor: pointer;
    background-color: #d3edff;
    fill: #3899ec;
    border-radius: 50%;
    position: absolute;
    right: 20px;
    top: 14px;
    transform: rotateY(180deg); }
    .mobile-settings-header .symbol-arrowRightBig:hover {
      background-color: #eaf7ff; }

.left-panel-frame.mobile-elements-panel {
  overflow: visible;
  min-height: 520px; }
  .left-panel-frame.mobile-elements-panel.action-bar-menu-open-wrapper {
    min-height: 470px; }
  .left-panel-frame.mobile-elements-panel .panel-header {
    border-top-right-radius: 10px; }
  .left-panel-frame.mobile-elements-panel .left-panel-frame-content {
    background-color: #ffffff;
    border-bottom-right-radius: 10px;
    min-height: 466px;
    padding-top: 11px; }
    .left-panel-frame.mobile-elements-panel .left-panel-frame-content.action-bar-menu-open {
      height: calc(100vh - 150px);
      max-height: 678px;
      min-height: calc(470px - 54px); }
      .left-panel-frame.mobile-elements-panel .left-panel-frame-content.action-bar-menu-open .mobile-element-settings {
        height: calc(100vh - 150px + 54px);
        max-height: calc(678px + 54px);
        min-height: 470px; }
  .left-panel-frame.mobile-elements-panel .items-wrapper {
    width: 350px;
    position: relative; }
    .left-panel-frame.mobile-elements-panel .items-wrapper .selected .listItem {
      background-color: #d3edff; }
    .left-panel-frame.mobile-elements-panel .items-wrapper .listItem {
      display: flex;
      align-items: center;
      padding: 13px 24px; }
      .left-panel-frame.mobile-elements-panel .items-wrapper .listItem .content {
        margin-top: 0; }
      .left-panel-frame.mobile-elements-panel .items-wrapper .listItem .image {
        height: 60px;
        width: 60px;
        position: relative; }
      .left-panel-frame.mobile-elements-panel .items-wrapper .listItem .has-tooltip {
        display: flex; }
      .left-panel-frame.mobile-elements-panel .items-wrapper .listItem.list-item-with-symbol .image {
        border-radius: 50%;
        height: 90px;
        width: 90px;
        padding: 21px;
        margin: 0;
        background-color: #ECF9FF;
        border: 3px #fff solid;
        box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.1);
        box-sizing: border-box; }
    .left-panel-frame.mobile-elements-panel .items-wrapper .symbol-arrowRightBig {
      margin-left: 49px;
      width: 23px;
      height: 23px; }
  .left-panel-frame.mobile-elements-panel .panel-shadow {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.35);
    border-radius: 6px 10px 10px 6px;
    pointer-events: none; }
  .left-panel-frame.mobile-elements-panel :not(.composite-toggle-switch) > .control-switch > .symbol-switch {
    margin-right: 17px; }
  .left-panel-frame.mobile-elements-panel .category-desc {
    width: 375px; }
    .left-panel-frame.mobile-elements-panel .category-desc .text {
      margin: 21px 0 16px 0;
      line-height: 20px; }
  .left-panel-frame.mobile-elements-panel .mobile-element-settings {
    z-index: -1; }

.mobile-cant-make-changes {
  width: 566px; }
  .mobile-cant-make-changes .panel-header {
    height: 54px; }
    .mobile-cant-make-changes .panel-header .panel-header-title {
      line-height: 54px; }
    .mobile-cant-make-changes .panel-header .close {
      top: 14px;
      right: 18px;
      margin-top: 0; }
  .mobile-cant-make-changes .content-wrapper {
    display: inline-block;
    vertical-align: top;
    margin: 40px 0 30px; }
    .mobile-cant-make-changes .content-wrapper .logo {
      float: left;
      text-align: center;
      width: 186px; }
    .mobile-cant-make-changes .content-wrapper .message {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px;
      color: #162d3d;
      display: inline-block;
      max-width: 312px; }
  .mobile-cant-make-changes .btn-confirm-primary {
    height: 30px;
    line-height: 30px; }

.layout-optimizer-panel {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px; }
  .layout-optimizer-panel .content {
    width: 292px;
    min-height: 426px;
    padding: 24px 30px 0 30px;
    display: flex;
    flex-direction: column; }
    .layout-optimizer-panel .content .image-container {
      align-self: center; }
    .layout-optimizer-panel .content .text {
      padding: 20px 36px;
      text-align: center;
      color: #2b5672; }
      .layout-optimizer-panel .content .text .learn-more {
        color: #3899ec;
        cursor: pointer; }
        .layout-optimizer-panel .content .text .learn-more :hover {
          text-decoration: underline; }
    .layout-optimizer-panel .content .optimize-layout-button {
      display: block;
      margin: 0 auto; }

.choose-domain-panel {
  position: relative;
  width: 720px;
  height: 540px;
  background-color: #fff; }
  .choose-domain-panel .domain-option {
    position: relative;
    display: block;
    box-sizing: border-box;
    height: 138px;
    margin-right: 42px;
    margin-left: 42px;
    border-radius: 6px;
    border: 1px solid #d3edff;
    cursor: pointer; }
    .choose-domain-panel .domain-option:first-of-type {
      margin-top: 30px; }
    .choose-domain-panel .domain-option:nth-of-type(2) {
      margin-top: 10px; }
      .choose-domain-panel .domain-option:nth-of-type(2) .domain-option-label {
        margin-top: 45px; }
    .choose-domain-panel .domain-option .domain-option-label {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 18px;
      display: block;
      padding-left: 70px;
      margin-top: 35px;
      color: #2b5672; }
    .choose-domain-panel .domain-option .domain-option-radio-button {
      display: none; }
      .choose-domain-panel .domain-option .domain-option-radio-button + span {
        position: absolute;
        display: inline-block;
        height: 17px;
        width: 17px;
        left: 30px;
        top: 60px;
        border-radius: 50%;
        box-shadow: inset 0 0 0 1px #3899ec;
        transition-property: background-color, border-width;
        transition-duration: 0.15s;
        box-sizing: border-box;
        cursor: pointer; }
      .choose-domain-panel .domain-option .domain-option-radio-button:checked + span {
        background-color: #3899ec;
        box-shadow: inset 0 0 0 1px #3899ec, inset 0 0 0 2px #fff; }
      .choose-domain-panel .domain-option .domain-option-radio-button:not(:checked) + span:hover {
        background-color: #d3edff; }
    .choose-domain-panel .domain-option .domain-input {
      display: block;
      margin-top: 11px;
      padding-left: 70px;
      width: 522px; }
    .choose-domain-panel .domain-option .connect-domain-example {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      display: block;
      padding-left: 70px;
      margin-top: 4px;
      color: #7a92a5;
      white-space: nowrap; }
    .choose-domain-panel .domain-option:not(.checked)::after {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      cursor: pointer; }
    .choose-domain-panel .domain-option.checked {
      background-color: #eaf7ff; }
    .choose-domain-panel .domain-option:hover:not(.checked) {
      border-color: #7fccf7; }
  .choose-domain-panel .choose-domain-action-button {
    position: absolute;
    height: 30px;
    line-height: 30px;
    right: 24px;
    bottom: 27px;
    padding: 0 18px; }
    .choose-domain-panel .choose-domain-action-button:hover {
      background-color: #4eb7f5; }
    .choose-domain-panel .choose-domain-action-button:active {
      background-color: #3899ec; }
    .choose-domain-panel .choose-domain-action-button:disabled {
      background-color: #bcbcbc; }

.site-was-saved-panel {
  position: fixed;
  top: calc((100% - 348px) / 2);
  left: calc((100% - 720px) / 2);
  width: 720px;
  height: 348px;
  border-radius: 8px;
  overflow: hidden; }
  .site-was-saved-panel.contains-publish-inner-panel {
    height: 480px;
    top: calc((100% - 480px) / 2); }
  .site-was-saved-panel.contains-connect-domain-inner-panel {
    height: 530px;
    top: calc((100% - 530px) / 2); }
  .site-was-saved-panel .wrapper {
    padding-top: 50px;
    margin-left: 72px;
    margin-right: 72px;
    text-align: center;
    height: 100%;
    background-color: #fff; }
    .site-was-saved-panel .wrapper .domain-input {
      display: inline-block;
      box-sizing: border-box;
      width: 100%; }
    .site-was-saved-panel .wrapper .inner-panel {
      top: 60px;
      width: 100%; }
    .site-was-saved-panel .wrapper .site-saved-footer {
      margin-top: 10px; }
      .site-was-saved-panel .wrapper .site-saved-footer .dont-show-again {
        position: absolute;
        bottom: 32px;
        left: 24px; }
      .site-was-saved-panel .wrapper .site-saved-footer .help-connecting-domain {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 16px;
        position: absolute;
        bottom: 36px;
        left: 36px;
        color: #2b5672; }
        .site-was-saved-panel .wrapper .site-saved-footer .help-connecting-domain a {
          font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-size: 16px;
          color: #3899ec;
          cursor: pointer;
          text-decoration: none; }
          .site-was-saved-panel .wrapper .site-saved-footer .help-connecting-domain a:hover {
            text-decoration: underline; }
      .site-was-saved-panel .wrapper .site-saved-footer .footer-buttons {
        position: absolute;
        right: 24px;
        bottom: 30px; }
      .site-was-saved-panel .wrapper .site-saved-footer .continue-button,
      .site-was-saved-panel .wrapper .site-saved-footer .publish-button {
        height: 30px;
        line-height: 30px;
        padding: 0 18px; }
      .site-was-saved-panel .wrapper .site-saved-footer .continue-button {
        margin-left: 18px; }
  .site-was-saved-panel.narrow-domain-input .domain-input {
    width: 430px; }

.concurrent-save-panel .content-wrapper {
  display: inline-block;
  font-size: 0;
  position: relative;
  margin: 36px 0 30px 0;
  width: 100%; }

.concurrent-save-panel .error-logo {
  float: left;
  text-align: center;
  width: 186px; }

.concurrent-save-panel .description-wrapper {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 16px;
  display: inline-block;
  max-width: 294px;
  line-height: 22px;
  margin: -1px 50px 0 0;
  color: #162d3d; }
  .concurrent-save-panel .description-wrapper .description-line {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 16px;
    padding-left: 0.2em; }
    .concurrent-save-panel .description-wrapper .description-line:first-child, .concurrent-save-panel .description-wrapper .description-line.initialPadding {
      padding-left: initial; }

.save-configuration-panel {
  position: fixed;
  right: 20px;
  top: 100px;
  padding: 20px;
  background-color: #ffd392;
  border-radius: 10px; }
  .save-configuration-panel .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer; }
  .save-configuration-panel .checkbox-field {
    margin: 10px 10px; }
  .save-configuration-panel .domain-text-field {
    margin: 0 20px;
    padding-left: 30px; }
  .save-configuration-panel .confirm-button {
    float: right; }
  .save-configuration-panel .save-configuration-footer {
    text-align: center;
    margin-top: 10px; }
  .save-configuration-panel label.disabled {
    color: #808080; }

.save-reminder-panel {
  width: 498px;
  max-height: initial; }
  .save-reminder-panel .content-wrapper {
    margin-top: 35px; }
    .save-reminder-panel .content-wrapper .icon {
      display: inline-block;
      text-align: center;
      padding-left: 45px;
      padding-right: 30px; }
      .save-reminder-panel .content-wrapper .icon svg {
        position: relative;
        top: -10px; }
    .save-reminder-panel .content-wrapper .message {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px;
      color: #162d3d;
      display: inline-block;
      max-width: 290px;
      vertical-align: top;
      line-height: 24px; }
  .save-reminder-panel .panel-footer {
    padding: 0 24px 24px; }
    .save-reminder-panel .panel-footer .control-button {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px;
      bottom: 24px;
      right: 24px;
      height: 30px;
      line-height: 30px; }

.save-your-site-panel {
  width: 564px; }
  .save-your-site-panel .panel-header {
    height: 54px; }
    .save-your-site-panel .panel-header .panel-header-title {
      line-height: 54px; }
    .save-your-site-panel .panel-header .close {
      top: 14px;
      right: 18px;
      margin-top: 0; }
  .save-your-site-panel .content-wrapper {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    margin-top: 40px; }
    .save-your-site-panel .content-wrapper .logo {
      float: left;
      text-align: center;
      width: 186px; }
    .save-your-site-panel .content-wrapper .message {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px;
      color: #162d3d;
      display: inline-block;
      max-width: 312px; }
  .save-your-site-panel .btn-confirm-primary {
    height: 30px;
    line-height: 30px; }

.save-on-boarding-site-confirm-panel {
  margin-top: 0;
  width: 564px;
  height: 275px; }
  .save-on-boarding-site-confirm-panel .save-on-boarding-site-confirm-content {
    width: 504px;
    margin: 34px 30px 30px; }
    .save-on-boarding-site-confirm-panel .save-on-boarding-site-confirm-content .image-container {
      display: inline-block;
      width: 132px;
      height: 94px; }
    .save-on-boarding-site-confirm-panel .save-on-boarding-site-confirm-content .description-container {
      display: inline-block;
      padding-left: 24px;
      width: 348px;
      vertical-align: top; }
      .save-on-boarding-site-confirm-panel .save-on-boarding-site-confirm-content .description-container > .description-text {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 16px;
        line-height: 22px; }
    .save-on-boarding-site-confirm-panel .save-on-boarding-site-confirm-content .footer-buttons {
      text-align: right; }
      .save-on-boarding-site-confirm-panel .save-on-boarding-site-confirm-content .footer-buttons .footer-button {
        margin: 13px 0 0 10px; }

.publish-success-panel {
  width: 744px;
  border-radius: 8px; }
  .publish-success-panel.contains-music-promo-inner-panel .address-bar-wrapper .address, .publish-success-panel.contains-photo-promo-inner-panel .address-bar-wrapper .address {
    width: 485px; }
  .publish-success-panel.contains-music-promo-inner-panel .music-promo-content,
  .publish-success-panel.contains-music-promo-inner-panel .photo-promo-content, .publish-success-panel.contains-photo-promo-inner-panel .music-promo-content,
  .publish-success-panel.contains-photo-promo-inner-panel .photo-promo-content {
    width: 600px; }
  .publish-success-panel.contains-grow-business-inner-panel .publish-inner-panel {
    margin-top: 10px; }
  .publish-success-panel .content {
    position: relative;
    top: -20px; }
  .publish-success-panel .address-bar-wrapper,
  .publish-success-panel .publish-inner-panel,
  .publish-success-panel .publish-success-footer {
    background-color: #fff; }
  .publish-success-panel .address-bar-wrapper {
    color: #7a92a5;
    text-align: center;
    padding: 50px 0;
    position: static;
    height: 36px; }
    .publish-success-panel .address-bar-wrapper .address {
      display: inline-block;
      width: 387px; }
    .publish-success-panel .address-bar-wrapper .view-site-button {
      background-color: #eaf7ff;
      color: #3899ec;
      display: inline-block;
      width: auto;
      min-width: 0;
      height: 30px;
      line-height: 30px;
      margin-left: 12px;
      font-weight: 400;
      vertical-align: top;
      margin-top: 3px; }
      .publish-success-panel .address-bar-wrapper .view-site-button:hover {
        background-color: #3899ec;
        color: #eaf7ff; }
  .publish-success-panel .publish-success-footer {
    position: relative;
    width: 100%;
    height: 105px;
    bottom: 0; }
    .publish-success-panel .publish-success-footer .footer-buttons {
      position: absolute;
      right: 24px;
      bottom: 30px; }
      .publish-success-panel .publish-success-footer .footer-buttons .btn-confirm-primary {
        margin-left: 18px;
        padding: 0 18px;
        height: 30px;
        line-height: 30px; }
      .publish-success-panel .publish-success-footer .footer-buttons .btn-confirm-secondary {
        padding: 0 18px;
        height: 30px;
        line-height: 30px; }
    .publish-success-panel .publish-success-footer .dont-show-again {
      position: absolute;
      left: 32px;
      top: calc(50% - 9px); }

.panel-grow-business,
.panel-shout-out,
.panel-connect-domain {
  border-radius: 8px;
  margin: 0 auto;
  width: 530px;
  height: 198px;
  position: relative; }
  .panel-grow-business .promote-title-wrapper,
  .panel-shout-out .promote-title-wrapper,
  .panel-connect-domain .promote-title-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 498px;
    margin: 0 auto; }
    .panel-grow-business .promote-title-wrapper .promote-title,
    .panel-shout-out .promote-title-wrapper .promote-title,
    .panel-connect-domain .promote-title-wrapper .promote-title {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 20px;
      font-weight: 300;
      color: rgba(43, 86, 114, 0.8);
      margin: 0 18px; }
    .panel-grow-business .promote-title-wrapper .divider-long,
    .panel-shout-out .promote-title-wrapper .divider-long,
    .panel-connect-domain .promote-title-wrapper .divider-long {
      flex: 1 1 auto;
      background-color: rgba(43, 86, 114, 0.3); }
  .panel-grow-business .promotion-content,
  .panel-shout-out .promotion-content,
  .panel-connect-domain .promotion-content {
    display: flex;
    justify-content: space-between;
    margin-top: 22px; }
  .panel-grow-business .promote,
  .panel-shout-out .promote,
  .panel-connect-domain .promote {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 180px;
    color: #2b5672;
    text-align: center; }
    .panel-grow-business .promote .promote-symbol,
    .panel-grow-business .promote .promote-image,
    .panel-shout-out .promote .promote-symbol,
    .panel-shout-out .promote .promote-image,
    .panel-connect-domain .promote .promote-symbol,
    .panel-connect-domain .promote .promote-image {
      position: relative;
      width: 50px;
      height: 62px; }
      .panel-grow-business .promote .promote-symbol svg,
      .panel-grow-business .promote .promote-image svg,
      .panel-shout-out .promote .promote-symbol svg,
      .panel-shout-out .promote .promote-image svg,
      .panel-connect-domain .promote .promote-symbol svg,
      .panel-connect-domain .promote .promote-image svg {
        width: 100%;
        height: 100%; }
    .panel-grow-business .promote .promote-symbol-traffic,
    .panel-shout-out .promote .promote-symbol-traffic,
    .panel-connect-domain .promote .promote-symbol-traffic {
      top: -2px; }
    .panel-grow-business .promote .promote-symbol-newsletter,
    .panel-shout-out .promote .promote-symbol-newsletter,
    .panel-connect-domain .promote .promote-symbol-newsletter {
      left: 5px;
      top: -7px; }
    .panel-grow-business .promote .promote-symbol-mail,
    .panel-shout-out .promote .promote-symbol-mail,
    .panel-connect-domain .promote .promote-symbol-mail {
      top: -8px; }
    .panel-grow-business .promote .promote-image,
    .panel-shout-out .promote .promote-image,
    .panel-connect-domain .promote .promote-image {
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      height: 50px; }
    .panel-grow-business .promote .description,
    .panel-shout-out .promote .description,
    .panel-connect-domain .promote .description {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      margin: 4px 0 2px 0;
      max-width: 154px;
      color: #2b5672; }
    .panel-grow-business .promote a,
    .panel-shout-out .promote a,
    .panel-connect-domain .promote a {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      margin-top: 2px;
      color: #3899ec; }
      .panel-grow-business .promote a:hover,
      .panel-shout-out .promote a:hover,
      .panel-connect-domain .promote a:hover {
        cursor: pointer;
        text-decoration: underline; }

.connect-domain-panel {
  position: fixed;
  top: calc((100% - 507px) / 2);
  left: calc((100% - 720px) / 2);
  height: 507px;
  width: 720px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.14); }
  .connect-domain-panel a {
    color: #3899ec; }
    .connect-domain-panel a:hover {
      text-decoration: underline;
      cursor: pointer; }
  .connect-domain-panel .content {
    background: transparent; }
  .connect-domain-panel .actions,
  .connect-domain-panel .panel-content {
    background-color: #fff;
    height: 233px;
    padding: 43px 35px 5px 35px; }
  .connect-domain-panel .actions .action {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    background-color: #eaf7ff;
    border-radius: 8px;
    display: block;
    margin: 12px auto;
    height: 104px;
    line-height: 104px;
    position: relative;
    width: 528px; }
    .connect-domain-panel .actions .action:first-child {
      margin: 0 auto; }
    .connect-domain-panel .actions .action:hover {
      cursor: pointer;
      background-color: #d3edff; }
    .connect-domain-panel .actions .action .action-graphics {
      display: inline-block;
      margin-left: 42px;
      width: 63px; }
      .connect-domain-panel .actions .action .action-graphics svg {
        height: 56px;
        margin-top: 24px; }
    .connect-domain-panel .actions .action .action-title {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 18px;
      position: relative;
      margin-left: 20px;
      left: 0;
      vertical-align: top;
      word-break: normal;
      width: calc(100% - 220px);
      line-height: 22px;
      top: 50%;
      transform: translateY(-50%);
      color: #2b5672;
      display: inline-block; }
    .connect-domain-panel .actions .action .action-arrow {
      display: inline-block;
      position: relative;
      left: 30px;
      top: 0;
      vertical-align: top;
      height: 100%; }
      .connect-domain-panel .actions .action .action-arrow svg {
        width: 25px;
        height: 25px;
        margin-top: 37.5px;
        padding: 2px; }
  .connect-domain-panel .panel-content .content-graphics {
    display: inline-block; }
    .connect-domain-panel .panel-content .content-graphics svg {
      width: 92px; }
  .connect-domain-panel .panel-content .content-top-part {
    margin-bottom: 43px; }
    .connect-domain-panel .panel-content .content-top-part .content-top-message {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px;
      display: inline-block;
      margin-left: 22px;
      width: 368px;
      height: 62px; }
      .connect-domain-panel .panel-content .content-top-part .content-top-message .public-url {
        color: #7a92a5; }
  .connect-domain-panel .panel-content .content-bottom-message {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 16px;
    border-radius: 8px;
    background-color: #eaf7ff;
    width: 504px;
    height: 66px;
    line-height: 66px;
    text-align: center; }
  .connect-domain-panel .connect-domain-panel-footer {
    bottom: 0;
    background: #fff;
    width: 100%;
    height: 100px;
    position: absolute;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px; }
    .connect-domain-panel .connect-domain-panel-footer .message {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px;
      position: absolute;
      bottom: 27px;
      left: 41px; }
    .connect-domain-panel .connect-domain-panel-footer .maybe-later-button {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px;
      position: absolute;
      bottom: 29px;
      right: 24px;
      height: 30px;
      line-height: 30px;
      padding: 0 18px; }

.connect-domain-in-progress-panel {
  position: fixed;
  top: calc((100% - 474px) / 2);
  left: calc((100% - 720px) / 2);
  height: 474px;
  width: 720px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.14);
  background-color: #fff; }
  .connect-domain-in-progress-panel a {
    color: #3899ec; }
    .connect-domain-in-progress-panel a:hover {
      text-decoration: underline;
      cursor: pointer; }
  .connect-domain-in-progress-panel .content {
    background: transparent; }
  .connect-domain-in-progress-panel .actions,
  .connect-domain-in-progress-panel .panel-content {
    background-color: #fff;
    height: 208px;
    padding: 55px 108px 5px 108px; }
  .connect-domain-in-progress-panel .actions .action {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    background-color: #eaf7ff;
    border-radius: 8px;
    display: block;
    margin: 12px auto;
    height: 104px;
    line-height: 104px;
    position: relative;
    width: 528px; }
    .connect-domain-in-progress-panel .actions .action:first-child {
      margin: 0 auto; }
    .connect-domain-in-progress-panel .actions .action:hover {
      cursor: pointer;
      background-color: #d3edff; }
    .connect-domain-in-progress-panel .actions .action .action-graphics {
      display: inline-block;
      margin-left: 42px;
      width: 63px; }
      .connect-domain-in-progress-panel .actions .action .action-graphics svg {
        height: 56px;
        margin-top: 24px; }
    .connect-domain-in-progress-panel .actions .action .action-title {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 18px;
      position: relative;
      margin-left: 20px;
      left: 0;
      vertical-align: top;
      word-break: normal;
      width: calc(100% - 220px);
      line-height: 22px;
      top: 50%;
      transform: translateY(-50%);
      color: #2b5672;
      display: inline-block; }
    .connect-domain-in-progress-panel .actions .action .action-arrow {
      display: inline-block;
      position: relative;
      left: 30px;
      top: 0;
      vertical-align: top;
      height: 100%; }
      .connect-domain-in-progress-panel .actions .action .action-arrow svg {
        width: 25px;
        height: 25px;
        margin-top: 37.5px;
        padding: 2px; }
  .connect-domain-in-progress-panel .panel-content .content-graphics {
    display: inline-block; }
    .connect-domain-in-progress-panel .panel-content .content-graphics svg {
      width: 92px; }
  .connect-domain-in-progress-panel .panel-content .content-top-part {
    margin-bottom: 43px; }
    .connect-domain-in-progress-panel .panel-content .content-top-part .content-top-message {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px;
      display: inline-block;
      margin-left: 22px;
      width: 368px;
      height: 62px; }
      .connect-domain-in-progress-panel .panel-content .content-top-part .content-top-message .public-url {
        color: #7a92a5; }
  .connect-domain-in-progress-panel .panel-content .content-bottom-message {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 16px;
    border-radius: 8px;
    background-color: #eaf7ff;
    width: 504px;
    height: 66px;
    line-height: 66px;
    text-align: center; }
  .connect-domain-in-progress-panel .connect-domain-panel-footer {
    bottom: 0;
    background: #fff;
    width: 100%;
    height: 80px;
    position: absolute;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px; }
    .connect-domain-in-progress-panel .connect-domain-panel-footer .message {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px;
      position: absolute;
      bottom: 27px;
      left: 41px; }
    .connect-domain-in-progress-panel .connect-domain-panel-footer .maybe-later-button {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px;
      position: absolute;
      bottom: 29px;
      right: 24px;
      height: 30px;
      line-height: 30px;
      padding: 0 18px; }

.general-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 530px;
  margin: 0 auto; }
  .general-content .main-title-wrapper {
    position: relative;
    display: flex;
    align-items: center; }
    .general-content .main-title-wrapper .main-title {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 20px;
      font-weight: 300;
      color: rgba(43, 86, 114, 0.8);
      margin: 0 18px; }
    .general-content .main-title-wrapper .divider-long {
      flex: 1 1 auto;
      background-color: rgba(43, 86, 114, 0.3); }
  .general-content .middle-spacer {
    width: 100%;
    height: 10px; }
  .general-content .bottom-section {
    position: relative;
    display: flex;
    flex: 1 1 auto; }
    .general-content .bottom-section .left-side {
      display: inline-block;
      flex: 1 0 auto;
      width: 145px;
      height: 100%;
      text-align: center; }
      .general-content .bottom-section .left-side svg {
        position: relative;
        left: -5px; }
    .general-content .bottom-section .right-side {
      display: inline-block;
      flex: 1 1 auto;
      height: 100%;
      width: calc(100% - 145px); }
      .general-content .bottom-section .right-side .title {
        font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 20px;
        margin-top: 15px;
        text-align: left;
        color: #2b5672;
        word-wrap: break-word; }
      .general-content .bottom-section .right-side .subtitle {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 16px;
        margin-top: 10px;
        text-align: left;
        color: #7a92a5;
        word-wrap: break-word; }
      .general-content .bottom-section .right-side .general-content-action {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 16px;
        color: #3899ec;
        display: block;
        margin-top: 10px;
        text-align: left;
        word-wrap: break-word; }
        .general-content .bottom-section .right-side .general-content-action:hover {
          text-decoration: underline;
          cursor: pointer; }
  .general-content.music-promo-content .middle-spacer {
    height: 32px; }
  .general-content.music-promo-content .right-side .title {
    margin-top: 0; }
  .general-content.music-promo-content .left-side {
    margin-top: 5px; }
    .general-content.music-promo-content .left-side img {
      position: relative;
      left: -4px; }
  .general-content.photo-promo-content .middle-spacer {
    height: 32px; }
  .general-content.photo-promo-content .right-side .title {
    margin-top: 0; }
  .general-content.photo-promo-content .left-side {
    position: relative;
    width: 121px;
    margin-right: 24px; }
  .general-content.connect-domain-content .middle-spacer {
    height: 17px; }
  .general-content.personal-sale-campaign .bottom-section .left-side svg .symbol-connectDomainPersonalSale {
    top: -12px; }
  .general-content.personal-sale-campaign .bottom-section .right-side .subtitle span {
    color: #aa4dc8; }

.message-panel-frame.fail-panel {
  width: 566px; }
  .message-panel-frame.fail-panel .content-wrapper {
    display: inline-block;
    vertical-align: top;
    color: #162d3d;
    padding: 30px 24px 35px 24px; }
    .message-panel-frame.fail-panel .content-wrapper .icon {
      width: 150px;
      height: initial;
      float: left;
      margin-top: 4px;
      display: flex;
      justify-content: center; }
      .message-panel-frame.fail-panel .content-wrapper .icon .symbol-plaster {
        margin-left: -20px; }
    .message-panel-frame.fail-panel .content-wrapper > .full-textual {
      width: 100%; }
    .message-panel-frame.fail-panel .content-wrapper .textual {
      width: calc(100% - 180px); }
    .message-panel-frame.fail-panel .content-wrapper > .full-textual,
    .message-panel-frame.fail-panel .content-wrapper .textual {
      float: left;
      line-height: 24px; }
      .message-panel-frame.fail-panel .content-wrapper > .full-textual .steps-title,
      .message-panel-frame.fail-panel .content-wrapper .textual .steps-title {
        font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
      .message-panel-frame.fail-panel .content-wrapper > .full-textual .description,
      .message-panel-frame.fail-panel .content-wrapper .textual .description {
        margin-bottom: 16px;
        margin-top: 0; }
      .message-panel-frame.fail-panel .content-wrapper > .full-textual ol,
      .message-panel-frame.fail-panel .content-wrapper .textual ol {
        padding: 0 0 0 24px;
        margin: 0; }
        .message-panel-frame.fail-panel .content-wrapper > .full-textual ol li,
        .message-panel-frame.fail-panel .content-wrapper .textual ol li {
          list-style: inherit; }
      .message-panel-frame.fail-panel .content-wrapper > .full-textual a:hover,
      .message-panel-frame.fail-panel .content-wrapper .textual a:hover {
        text-decoration: underline; }
  .message-panel-frame.fail-panel .fail-panel-help-text {
    margin: 0;
    line-height: 29px; }
    .message-panel-frame.fail-panel .fail-panel-help-text .link-part {
      padding-left: 0.4em;
      color: #3899ec; }
      .message-panel-frame.fail-panel .fail-panel-help-text .link-part:hover {
        cursor: pointer; }
    .message-panel-frame.fail-panel .fail-panel-help-text .error-code-part {
      padding-left: 0.4em; }
  .message-panel-frame.fail-panel button.btn-confirm-primary.align-right {
    height: 30px; }

.save-publish-panel + .dark-frame-overlay {
  background-color: rgba(22, 45, 61, 0.85); }

.save-publish-panel-header,
.confirm-panel-header {
  position: relative;
  height: 126px;
  border-radius: 8px 8px 0 0;
  background-color: #3899ec;
  box-sizing: border-box;
  padding-top: 35px;
  text-align: center; }
  .save-publish-panel-header .title,
  .confirm-panel-header .title {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 30px;
    display: block;
    line-height: 30px;
    color: #fff;
    white-space: nowrap; }
  .save-publish-panel-header .subtitle,
  .confirm-panel-header .subtitle {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 18px;
    display: block;
    margin-top: 10px;
    white-space: nowrap;
    color: #d3edff; }
  .save-publish-panel-header button,
  .confirm-panel-header button {
    border-radius: 50%;
    border: 0;
    position: absolute;
    width: 25px;
    height: 25px;
    top: 24px;
    right: 24px;
    background-color: #2b81cb;
    box-sizing: border-box;
    line-height: 20px;
    padding: 0; }
    .save-publish-panel-header button svg,
    .confirm-panel-header button svg {
      fill: #fff; }
    .save-publish-panel-header button:hover,
    .confirm-panel-header button:hover {
      background-color: #0d487f; }

.multilingual-manage-panel {
  width: 600px; }
  .multilingual-manage-panel .ml-toggle {
    width: 230px;
    margin: 0 auto; }
  .multilingual-manage-panel .control-dropdown-base {
    max-width: none !important; }
  .multilingual-manage-panel .btn-delete {
    padding: 6px; }
    .multilingual-manage-panel .btn-delete svg {
      margin: 0 !important; }
  .multilingual-manage-panel .lang-toggle::before {
    content: '';
    margin-left: 8px;
    margin-right: 9px;
    height: 24px;
    width: 1px;
    background-color: #d9e1e8; }

.multilingual-init-panel .content {
  padding-bottom: 0; }

.multilingual-init-panel .help {
  top: 24px; }

.multilingual-init-panel .composite-dropdown-labeled {
  padding: 0; }

.icon-button-container {
  display: inline-block; }
  .icon-button-container .icon-button {
    border-radius: 8px;
    background-color: #ffffff;
    width: 32px;
    height: 32px;
    border: none;
    outline: none;
    fill: #2b5672;
    padding: 0;
    cursor: pointer; }
    .icon-button-container .icon-button svg:not(.symbol-arrowDown) {
      display: inline-block;
      vertical-align: top;
      padding: 0; }
    .icon-button-container .icon-button.popup {
      width: 52px;
      position: relative;
      text-align: left;
      padding: 0 11px; }
      .icon-button-container .icon-button.popup i.arrow-button {
        position: absolute;
        right: 8px; }
      .icon-button-container .icon-button.popup svg:not(.symbol-arrowDown) {
        vertical-align: middle !important; }
    .icon-button-container .icon-button .symbol-textOutdentRight,
    .icon-button-container .icon-button .symbol-textIndentRight,
    .icon-button-container .icon-button .symbol-textOutdentLeft,
    .icon-button-container .icon-button .symbol-textIndentLeft {
      width: 22px;
      height: 22px; }
    .icon-button-container .icon-button:hover {
      background-color: #eaf7ff; }
    .icon-button-container .icon-button.selected, .icon-button-container .icon-button:active {
      background-color: #3899ec;
      color: #ffffff; }
      .icon-button-container .icon-button.selected svg, .icon-button-container .icon-button:active svg {
        fill: #ffffff !important; }
    .icon-button-container .icon-button.selected:hover {
      background-color: #4eb7f5; }

.dropdown-options .option.icons-dd-option {
  line-height: 32px !important;
  height: 32px !important;
  padding: 0 !important;
  text-align: center; }
  .dropdown-options .option.icons-dd-option svg {
    fill: #2b5672;
    width: 22px;
    height: 22px;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    pointer-events: none; }
    .dropdown-options .option.icons-dd-option svg.symbol-textListNone {
      width: 18px !important;
      height: 18px !important; }
  .dropdown-options .option.icons-dd-option.selected svg {
    fill: #ffffff; }

.icons-dd {
  text-align: center;
  display: inline-block;
  vertical-align: top;
  border-radius: 8px; }
  .icons-dd:hover {
    background-color: #eaf7ff; }
  .icons-dd:active {
    background-color: #4eb7f5; }
    .icons-dd:active svg {
      fill: #ffffff !important; }
  .icons-dd .selected-container {
    height: 32px !important;
    line-height: 32px !important;
    min-height: 32px !important;
    padding-left: 9px;
    padding-right: 24px !important; }
    .icons-dd .selected-container svg:not(.symbol-arrowDown) {
      vertical-align: middle; }
  .icons-dd i.expand.arrow {
    border: none !important;
    width: 9px !important;
    opacity: 1 !important;
    right: 8px !important; }
    .icons-dd i.expand.arrow svg {
      width: 9px;
      padding: 0; }
    .icons-dd i.expand.arrow:hover {
      background-color: transparent !important; }
      .icons-dd i.expand.arrow:hover svg {
        fill: #3899ec !important; }

.back-color-dd {
  text-align: center;
  display: inline-block;
  vertical-align: top;
  border-radius: 8px; }
  .back-color-dd svg {
    fill: #2b5672; }
  .back-color-dd:hover {
    background-color: #eaf7ff; }
  .back-color-dd:active {
    background-color: #4eb7f5; }
    .back-color-dd:active svg {
      fill: #ffffff !important; }
  .back-color-dd .selected-container {
    height: 32px !important;
    line-height: 32px !important;
    min-height: 32px !important;
    width: 32px;
    padding-right: 0 !important; }
    .back-color-dd .selected-container .symbol-textBackColor {
      vertical-align: middle !important; }

.dropdown-options .option.style-option {
  height: 72px !important;
  border-bottom: 1px solid #d9e1e8;
  padding: 0 12px !important; }
  .dropdown-options .option.style-option .style-option-label {
    display: none; }
  .dropdown-options .option.style-option div {
    pointer-events: none; }
  .dropdown-options .option.style-option .style-option-header {
    margin-top: 12px;
    height: 42px; }
    .dropdown-options .option.style-option .style-option-header .style-name {
      width: 186px;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      vertical-align: middle; }
    .dropdown-options .option.style-option .style-option-header .style-color {
      border-radius: 50%;
      width: 21px;
      height: 21px;
      border: 1px solid #d9e1e8;
      display: inline-block;
      vertical-align: middle; }
  .dropdown-options .option.style-option .style-option-footer {
    height: 18px; }
  .dropdown-options .option.style-option.selected .style-size,
  .dropdown-options .option.style-option.selected .style-tag {
    color: #ffffff; }
  .dropdown-options .option.style-option .style-color-container,
  .dropdown-options .option.style-option .style-size {
    display: inline-block;
    width: 42px;
    text-align: center;
    margin-right: 12px; }
  .dropdown-options .option.style-option .style-size,
  .dropdown-options .option.style-option .style-tag {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 12px;
    color: #7a92a5;
    line-height: 12px;
    font-weight: 300;
    display: inline-block;
    vertical-align: top; }
  .dropdown-options .option.style-option.advanced-style-option .style-option-header,
  .dropdown-options .option.style-option.advanced-style-option .style-option-footer {
    margin-left: 12px; }
  .dropdown-options .option.style-option.advanced-style-option .style-color-container,
  .dropdown-options .option.style-option.advanced-style-option .style-tag {
    display: none; }
  .dropdown-options .option.style-option.advanced-style-option .style-size {
    text-align: left; }

.text-style-dd {
  position: inherit !important; }
  .text-style-dd.toolbar-mode {
    display: inline-block;
    vertical-align: middle;
    margin: 4px 5px; }
    .text-style-dd.toolbar-mode i.expand.arrow {
      border: none !important;
      width: 9px !important;
      opacity: 1 !important;
      right: 8px !important; }
      .text-style-dd.toolbar-mode i.expand.arrow svg {
        width: 9px;
        padding: 0; }
      .text-style-dd.toolbar-mode i.expand.arrow:hover {
        background-color: transparent !important; }
        .text-style-dd.toolbar-mode i.expand.arrow:hover svg {
          fill: #3899ec !important; }
    .text-style-dd.toolbar-mode .selected .display-name {
      font-size: 16px; }
  .text-style-dd .selected .style-option-footer,
  .text-style-dd .selected .style-option-header,
  .text-style-dd .selected .custom-style-indicator {
    display: none; }
  .text-style-dd .selected:hover .display-name {
    color: #3899ec; }
  .text-style-dd.custom-style .style-option-label .display-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    max-width: 95%; }
  .text-style-dd.custom-style .style-option-label .custom-style-indicator {
    display: inline-block;
    vertical-align: top; }

.font-option-container {
  display: flex;
  align-items: center; }
  .font-option-container > .font-label {
    font-size: 16px;
    color: #162d3d; }
    .font-family-dd .font-option-container > .font-label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      height: 36px;
      line-height: 36px;
      min-height: 36px;
      display: inline; }
    .font-family-dd:not(.toolbar-mode) .font-option-container > .font-label {
      font-size: 18px;
      padding-right: 30px;
      color: #162d3d; }
  .font-option-container > .font-example {
    font-size: 16px;
    margin-left: auto; }
  .selected-container .font-option-container > .font-image, .selected-container
  .font-option-container > .font-example {
    display: none; }
  .font-option .font-option-container {
    height: 24px; }
  .font-option-container .font-badge {
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    background-color: #3899ec;
    color: #fff;
    padding: 0 7px;
    border-radius: 9px;
    height: 19px;
    line-height: 19px;
    font-size: 12px; }
    .dropdown-option.selected .font-option-container .font-badge {
      background-color: #fff;
      color: #3899ec; }
    .selected-container .font-option-container .font-badge {
      display: none; }

.options-footer {
  display: flex;
  border-top: 1px solid #e2e2e2; }
  .options-footer .font-family-footer-label {
    cursor: pointer; }
  .options-footer .footer-content {
    width: 100%; }
    .options-footer .footer-content .footer-links-container {
      display: flex; }
      .options-footer .footer-content .footer-links-container .font-family-footer-link {
        display: flex;
        align-items: center;
        cursor: pointer; }
        .options-footer .footer-content .footer-links-container .font-family-footer-link.upload-font-link {
          margin-left: auto; }

.text-effect-container {
  padding: 0 0 20px 25px; }
  .text-effect-container .text-none-icon {
    margin: 12px 12px 0 0;
    width: 32px;
    height: 32px;
    vertical-align: top;
    display: inline-block;
    cursor: pointer;
    background: #fff;
    border-radius: 8px;
    position: relative;
    border: 2px solid #d9e1e8; }
    .text-effect-container .text-none-icon:before {
      content: '';
      height: 2px;
      background: #f00;
      position: absolute;
      width: 42px;
      right: -5px;
      top: 15px;
      transform: rotate(135deg); }
    .text-effect-container .text-none-icon:hover {
      background: #eaf7ff; }
    .text-effect-container .text-none-icon.selected {
      border: 2px solid #3899ec; }

.text-effect-button {
  color: #3899ec;
  font-size: 26px;
  border-radius: 8px;
  width: 38px;
  height: 38px;
  background-color: #eaf7ff;
  border: none;
  outline: none;
  display: inline-block;
  margin: 12px 12px 0 0;
  padding: 0;
  font-weight: 600;
  cursor: pointer; }
  .text-effect-button:hover {
    background-color: #d3edff; }
  .text-effect-button.selected, .text-effect-button:active {
    background-color: #3899ec;
    color: #eaf7ff; }
    .text-effect-button.selected:hover, .text-effect-button:active:hover {
      background-color: #4eb7f5; }

.rich-text-toolbar {
  margin-bottom: 12px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(43, 86, 114, 0.2);
  border-radius: 8px 8px 0 0; }
  .rich-text-toolbar > section {
    margin: 0 5px;
    display: flex;
    align-items: center; }
  .rich-text-toolbar .toolbar-divider {
    background: #eaf7ff;
    height: 1px;
    width: 95%;
    margin: 0 auto; }
  .rich-text-toolbar .extra-toolbar {
    box-shadow: 0 1px 2px 0 rgba(43, 86, 114, 0.2); }
  .rich-text-toolbar .icon-button,
  .rich-text-toolbar .toggle-button {
    margin: 4px 5px 4px 5px; }
    .rich-text-toolbar .icon-button.popup,
    .rich-text-toolbar .toggle-button.popup {
      margin: 4px 0; }
  .rich-text-toolbar .back-color-dd {
    vertical-align: middle;
    margin: 4px 5px 4px 5px; }
    .rich-text-toolbar .back-color-dd .icon-button {
      margin: 0; }
  .rich-text-toolbar .expand-toolbar-button {
    color: #2b5672;
    cursor: pointer; }

.text-popup-control {
  display: inline-block;
  position: relative; }
  .text-popup-control .text-popup {
    bottom: 32px;
    position: absolute;
    background: #fff;
    z-index: 5999;
    width: 258px;
    padding: 10px;
    border-radius: 8px; }
  .text-popup-control .tri {
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    position: absolute;
    top: -25px;
    right: 25px; }
  .text-popup-control .cover-shadow {
    background: #fff;
    z-index: 6000; }
  .text-popup-control .with-shadow {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.44); }

.text-style-save-confirm-panel {
  margin-top: 0; }
  .text-style-save-confirm-panel .text-style-save-confirm-content {
    width: 548px;
    margin: 30px 24px; }
    .text-style-save-confirm-panel .text-style-save-confirm-content .image-container {
      display: inline-block;
      padding-left: 17px; }
    .text-style-save-confirm-panel .text-style-save-confirm-content .description-container {
      display: inline-block;
      padding-left: 22px;
      width: 375px;
      vertical-align: top; }
      .text-style-save-confirm-panel .text-style-save-confirm-content .description-container > .description-text {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 16px;
        line-height: 22px; }
        .text-style-save-confirm-panel .text-style-save-confirm-content .description-container > .description-text.bold {
          font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-size: 16px; }
      .text-style-save-confirm-panel .text-style-save-confirm-content .description-container .learn-more-link-container {
        display: block; }
        .text-style-save-confirm-panel .text-style-save-confirm-content .description-container .learn-more-link-container .learn-more-link {
          font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-size: 16px;
          color: #3899ec;
          line-height: 22px;
          display: inline-block;
          margin-left: 0; }
          .text-style-save-confirm-panel .text-style-save-confirm-content .description-container .learn-more-link-container .learn-more-link:hover {
            cursor: pointer;
            text-decoration: underline; }
    .text-style-save-confirm-panel .text-style-save-confirm-content .footer-buttons {
      margin-top: 30px;
      display: flex;
      align-items: center; }
      .text-style-save-confirm-panel .text-style-save-confirm-content .footer-buttons .save-button {
        margin-left: auto; }

.text-link-dd .link-icon-container {
  text-align: center;
  vertical-align: top;
  border-radius: 8px;
  background-color: #3899ec; }
  .text-link-dd .link-icon-container:active {
    background-color: #4eb7f5; }

.tpa-gallery-advanced-style-panel .color-picker-input {
  padding: 16px 24px 19px !important;
  box-sizing: border-box; }
  .tpa-gallery-advanced-style-panel .color-picker-input .color-picker-label ~ .color-picker-wrapper {
    right: 24px !important;
    top: 16px; }

.tpa-gallery-advanced-style-panel .color-picker-input-with-opacity {
  margin: 15px 24px 0 24px; }
  .tpa-gallery-advanced-style-panel .color-picker-input-with-opacity .input-stepper.has-units .input {
    font-size: 18px;
    height: 36px; }
  .tpa-gallery-advanced-style-panel .color-picker-input-with-opacity .input-slider {
    margin: 0 30px 8px 0; }
  .tpa-gallery-advanced-style-panel .color-picker-input-with-opacity .color-picker-input {
    padding: 0 !important; }
  .tpa-gallery-advanced-style-panel .color-picker-input-with-opacity .input-stepper.small {
    margin: -2px 6px 0 3px;
    padding: 0 6px 0 4px; }

.advanced-style-panel {
  text-align: left; }
  .advanced-style-panel.flex-column {
    flex-direction: column;
    display: flex; }
    .advanced-style-panel.flex-column .flex-column {
      flex-direction: column;
      display: flex; }
    .advanced-style-panel.flex-column .flex-child {
      flex: 1; }
  .advanced-style-panel.only-advanced-styling {
    height: auto; }
  .advanced-style-panel .input-slider {
    margin: 10px 24px 7px; }
  .advanced-style-panel .control-section-divider.labeled {
    width: 90%;
    left: 5%;
    padding: 0 18px; }
  .advanced-style-panel .button-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 19px 12px; }
    .advanced-style-panel .button-row .dropdown {
      padding: 0;
      margin: 0; }
  .advanced-style-panel .dropdown {
    padding: 10px 12px;
    margin: 0 11px; }
  .advanced-style-panel .writable-drop-down {
    padding: 10px 12px;
    margin-right: 11px; }
  .advanced-style-panel .selective-text-input .control-checkbox {
    margin: -6px 0; }
  .advanced-style-panel .selective-text-input .control-text-input {
    width: calc(100% - 100px); }
  .advanced-style-panel :not(.selective-text-input) > .control-checkbox {
    display: block;
    width: calc(100% - 60px);
    margin: 21px 30px;
    box-sizing: border-box; }
  .advanced-style-panel .control-alignment {
    padding: 19px 0 24px 24px; }
    .advanced-style-panel .control-alignment .label {
      padding: 0 0 12px 0; }
  .advanced-style-panel :not(.composite-toggle-switch) > .control-switch,
  .advanced-style-panel > .control-switch {
    margin-left: 24px;
    position: static;
    padding-top: 16px; }
    .advanced-style-panel :not(.composite-toggle-switch) > .control-switch .symbol-switch,
    .advanced-style-panel > .control-switch .symbol-switch {
      position: relative;
      bottom: 2px;
      right: 15px; }
  .advanced-style-panel .tab-text {
    font-size: medium;
    position: absolute;
    left: 48px;
    width: calc(100% - 72px);
    line-height: 48px;
    padding-left: 24px;
    font-weight: bold;
    color: #2b5672; }
  .advanced-style-panel .control-angle {
    margin: 17px 24px 24px; }
  .advanced-style-panel .color-picker-input {
    padding: 16px 24px 19px;
    box-sizing: border-box; }
    .advanced-style-panel .color-picker-input .color-picker-label ~ .color-picker-wrapper {
      right: 20px;
      top: 16px; }
  .advanced-style-panel .color-picker-input-with-opacity {
    margin: 15px 24px 0 24px; }
    .advanced-style-panel .color-picker-input-with-opacity .input-stepper.has-units .input {
      font-size: 18px;
      height: 36px; }
    .advanced-style-panel .color-picker-input-with-opacity .input-slider {
      margin: 0 30px 8px 0; }
      .advanced-style-panel .color-picker-input-with-opacity .input-slider .slider {
        margin: 0 75px 0 0; }
    .advanced-style-panel .color-picker-input-with-opacity .color-picker-input {
      padding: 0; }
    .advanced-style-panel .color-picker-input-with-opacity .input-stepper.small {
      margin: -2px 6px 0 3px;
      padding: 0 6px 0 4px; }
  .advanced-style-panel .backBtn {
    margin-top: 7px;
    width: 66px;
    height: 21px;
    background-color: #dfedfd;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #5c99e9;
    font-size: 13px;
    font-weight: 300;
    line-height: 23px;
    text-align: left; }
    .advanced-style-panel .backBtn:hover {
      background-color: #d3edff; }
  .advanced-style-panel .nothing-to-edit {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 18px;
    text-align: center;
    color: #7a92a5; }
    .advanced-style-panel .nothing-to-edit svg {
      margin-top: 45px;
      margin-bottom: 24px; }
  .advanced-style-panel .advanced-style-panel-body {
    position: relative; }
    .advanced-style-panel .advanced-style-panel-body .content-container {
      width: 100%;
      position: absolute;
      height: 100%; }
      .advanced-style-panel .advanced-style-panel-body .content-container .control-tabs-vertical {
        border-radius: 0 0 0 10px; }
      .advanced-style-panel .advanced-style-panel-body .content-container .content {
        position: absolute;
        display: inline-block;
        left: 48px;
        top: 0;
        height: 100%;
        width: calc(100% - 48px);
        border-radius: 0 0 10px 0;
        background: #fff; }
        .advanced-style-panel .advanced-style-panel-body .content-container .content .tab-text {
          left: 0;
          position: relative; }
        .advanced-style-panel .advanced-style-panel-body .content-container .content .section .control-section-divider + div hr:first-child {
          display: none; }
    .advanced-style-panel .advanced-style-panel-body .scrolled-content {
      overflow-x: hidden; }
    .advanced-style-panel .advanced-style-panel-body .no-options {
      margin-left: 24px;
      margin-right: 24px; }
  .advanced-style-panel .font-control .text-buttons {
    margin: 20px 24px 20px; }
    .advanced-style-panel .font-control .text-buttons span {
      display: inline-block;
      margin-right: 10px; }
  .advanced-style-panel .fixed-button-wrapper {
    padding-bottom: 12px;
    width: 100%;
    text-align: center; }
  .advanced-style-panel .shadow-divider {
    background-color: transparent;
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 8px;
    border: 0;
    box-shadow: 0 7px 10px -8px rgba(22, 45, 61, 0.2) inset; }
  .advanced-style-panel .border-position-switch .radio-control {
    margin: 0 10px 10px 0; }

.tpa-gallery-advanced-style-panel .control-section-divider {
  width: 100% !important;
  left: 0 !important; }
  .tpa-gallery-advanced-style-panel .control-section-divider.labeled {
    padding: 0 24px; }

.tpa-gallery-advanced-style-panel .boolean-control span.label {
  padding-top: 15px;
  padding-bottom: 15px; }

.tpa-gallery-advanced-style-panel .boolean-control input ~ svg,
.tpa-gallery-advanced-style-panel .boolean-control input ~ .label {
  padding-top: 20px; }

.tpa-gallery-advanced-style-panel .advanced-style-panel .control-switch .symbol-switch {
  padding-top: 20px; }

.design-data-panel-frame .comp-panel-content {
  min-height: 400px; }

.design-data-panel {
  text-align: left; }
  .design-data-panel.flex-column {
    flex-direction: column;
    display: flex; }
    .design-data-panel.flex-column .flex-column {
      flex-direction: column;
      display: flex; }
    .design-data-panel.flex-column .flex-child {
      flex: 1; }
  .design-data-panel.only-advanced-styling {
    height: auto; }
  .design-data-panel .input-slider {
    margin: 10px 24px 7px; }
  .design-data-panel .control-section-divider.labeled {
    width: 90%;
    left: 5%;
    padding: 0 18px; }
  .design-data-panel .button-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 19px 12px; }
    .design-data-panel .button-row .dropdown {
      padding: 0;
      margin: 0; }
  .design-data-panel .dropdown {
    padding: 10px 12px;
    margin: 0 11px; }
  .design-data-panel .writable-drop-down {
    padding: 10px 12px;
    margin-right: 11px; }
  .design-data-panel .selective-text-input .control-checkbox {
    margin: -6px 0; }
  .design-data-panel .selective-text-input .control-text-input {
    width: calc(100% - 100px); }
  .design-data-panel :not(.selective-text-input) > .control-checkbox {
    display: block;
    width: calc(100% - 60px);
    margin: 21px 30px;
    box-sizing: border-box; }
  .design-data-panel .control-alignment {
    padding: 19px 0 24px 24px; }
    .design-data-panel .control-alignment .label {
      padding: 0 0 12px 0; }
  .design-data-panel .tab-text {
    font-size: medium;
    position: absolute;
    left: 48px;
    width: calc(100% - 72px);
    line-height: 48px;
    padding-left: 24px;
    font-weight: bold;
    color: #2b5672; }
  .design-data-panel .control-angle {
    margin: 17px 24px 24px; }
  .design-data-panel .color-picker-input {
    padding: 16px 24px 19px;
    box-sizing: border-box; }
    .design-data-panel .color-picker-input .color-picker-label ~ .color-picker-wrapper {
      right: 20px;
      top: 16px; }
  .design-data-panel .color-picker-input-with-opacity {
    margin: 15px 24px 0 24px; }
    .design-data-panel .color-picker-input-with-opacity .input-stepper.has-units .input {
      font-size: 18px;
      height: 36px; }
    .design-data-panel .color-picker-input-with-opacity .input-slider {
      margin: 0 30px 8px 0; }
      .design-data-panel .color-picker-input-with-opacity .input-slider .slider {
        margin: 0 75px 0 0; }
    .design-data-panel .color-picker-input-with-opacity .color-picker-input {
      padding: 0; }
    .design-data-panel .color-picker-input-with-opacity .input-stepper.small {
      margin: -2px 6px 0 3px;
      padding: 0 6px 0 4px; }
  .design-data-panel .backBtn {
    margin-top: 7px;
    width: 66px;
    height: 21px;
    background-color: #dfedfd;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #5c99e9;
    font-size: 13px;
    font-weight: 300;
    line-height: 23px;
    text-align: left; }
    .design-data-panel .backBtn:hover {
      background-color: #d3edff; }
  .design-data-panel .nothing-to-edit {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 18px;
    text-align: center;
    color: #7a92a5; }
    .design-data-panel .nothing-to-edit svg {
      margin-top: 45px;
      margin-bottom: 24px; }
  .design-data-panel .design-data-panel-body {
    position: relative; }
    .design-data-panel .design-data-panel-body .content-container {
      width: 100%;
      position: absolute;
      height: 100%; }
      .design-data-panel .design-data-panel-body .content-container .control-tabs-vertical {
        border-radius: 0 0 0 10px; }
      .design-data-panel .design-data-panel-body .content-container .content {
        position: absolute;
        display: inline-block;
        left: 48px;
        top: 0;
        height: 100%;
        width: calc(100% - 48px);
        border-radius: 0 0 10px 0;
        background: #fff; }
        .design-data-panel .design-data-panel-body .content-container .content .tab-text {
          left: 0;
          position: relative; }
        .design-data-panel .design-data-panel-body .content-container .content .section .control-section-divider + div hr:first-child {
          display: none; }
    .design-data-panel .design-data-panel-body .scrolled-content {
      overflow-x: hidden; }
    .design-data-panel .design-data-panel-body .no-options {
      margin-left: 24px;
      margin-right: 24px; }
  .design-data-panel .font-control .text-buttons {
    margin: 20px 24px 20px; }
    .design-data-panel .font-control .text-buttons span {
      display: inline-block;
      margin-right: 10px; }
  .design-data-panel .fixed-button-wrapper {
    padding-bottom: 12px;
    width: 100%;
    text-align: center; }

.media-button .symbol.symbol-with-label {
  position: absolute;
  left: 12px;
  top: 12px;
  width: 14px; }

.info-icon > .symbol circle {
  fill: transparent; }

.info-icon > .symbol path {
  fill: #7a92a5; }

.info-icon > .symbol:hover path {
  fill: #3899ec; }

.label-info-icon-container .symbol {
  vertical-align: middle;
  margin-bottom: 1px; }
  .label-info-icon-container .symbol:hover path {
    fill: #fff; }

.symbol-addressBarMiniToolbar .c1 {
  fill: #ADB7BF; }

.symbol-arrowLeftNarrow .cls-4 {
  fill: #3899ec; }

.symbol-arrowLeft {
  fill: #3899ec; }
  .symbol-arrowLeft path {
    fill: #3899ec; }

.symbol-arrowWithCircle .cls-4 {
  fill: #0099ef;
  stroke: #0099ef;
  stroke-width: 2px;
  opacity: 0.5;
  fill-opacity: 0; }

.symbol-arrowWithCircle .cls-5 {
  fill: #0099ef; }

.symbol-arrowWithStates .st0 {
  fill: transparent;
  stroke: #3899ec; }
  .disabled.symbol-arrowWithStates .st0 {
    stroke: #bcbcbc; }

.symbol-arrowWithStates .st1 {
  fill: #3899ec; }
  .disabled.symbol-arrowWithStates .st1 {
    fill: #bcbcbc; }

.symbol-arrowWithStates:hover:not(.disabled) .st0 {
  fill: #4eb7f5;
  stroke: #4eb7f5; }

.symbol-arrowWithStates:hover:not(.disabled) .st1 {
  fill: #ffffff; }

.symbol-arrowRight {
  fill: none;
  stroke-width: 1px;
  stroke: 000; }
  .section-footer .symbol-arrowRight {
    vertical-align: middle;
    margin-left: 6px;
    width: 4px;
    height: 7px; }
    .section-footer .symbol-arrowRight .c1 {
      stroke: #3899ec; }

.symbol-bgImage g.image-icon .st2 {
  fill: #3899ec; }

.symbol-bgImage g.image-icon .st6 {
  fill: #E9F1F4; }

.symbol-bgImage g.image-icon:hover .st2 {
  fill: #4eb7f5; }

.symbol-bgImage g.image-icon:hover .st6 {
  fill: #E9F1F4; }

.symbol-bgImage .disabled g.image-icon .st2,
.symbol-bgImage .disabled:hover g.image-icon .st2 {
  fill: #A7A7A7; }

.symbol-bgImage .disabled g.image-icon .st6 .disabled:hover g.image-icon .st6 {
  fill: #E9F1F4; }

.symbol-bgSlideShow .slideshow-icon .st2 {
  fill: #3899ec; }

.symbol-bgSlideShow .slideshow-icon .st6 {
  fill: #E9F1F4; }

.symbol-bgSlideShow .slideshow-icon:hover .st2 {
  fill: #ECF7FF; }

.symbol-bgSlideShow .slideshow-icon:hover .st6 {
  fill: #3899ec; }

.symbol-bgSlideShow .slideshow-icon .disable .st2 {
  fill: #A7A7A7; }

.symbol-bgSlideShow .slideshow-icon .disable .st6 {
  fill: #E9F1F4; }

.symbol-bgVideo .video-icon .st2 {
  fill: #3899ec; }

.symbol-bgVideo .video-icon .st6 {
  fill: #E9F1F4; }

.symbol-bgVideo .video-icon:hover .st2 {
  fill: #4eb7f5; }

.symbol-bgVideo .video-icon:hover .st6 {
  fill: #E9F1F4; }

.disabled .video-icon .st2,
.disabled .video-icon:hover .st2 {
  fill: #F1F1F1; }

.disabled .video-icon .st6,
.disabled .video-icon:hover .st6 {
  fill: #CCCCCC; }

.symbol-bgVideoIndicator .video-indicator-icon {
  fill: #FFFFFF; }

.symbol-ArchiveDropDown .cls-4 {
  stroke: #7fccf7;
  stroke-width: 1px;
  fill: none; }

.symbol-ArchiveDropDown .cls-5 {
  fill: #7fccf7; }

.symbol-ArchiveDropDown .cls-7 {
  fill: #eaf7ff; }

.symbol-ArchiveDropDown .cls-8 {
  fill: #ffffff; }

.symbol-ArchiveList .cls-4 {
  fill: #7fccf7; }

.symbol-Masonry .cls-5 {
  stroke: #7fccf7;
  stroke-width: 1px;
  fill: none; }

.symbol-Masonry .cls-7 {
  fill: #7fccf7; }

.symbol-Masonry .cls-8 {
  fill: #b5e2fb; }

.symbol-Masonry .cls-9 {
  fill: #eaf7ff; }

.symbol-MasonryPage .cls-5 {
  stroke: #7fccf7;
  stroke-width: 1px;
  fill: none; }

.symbol-MasonryPage .cls-7 {
  fill: #7fccf7; }

.symbol-MasonryPage .cls-8 {
  fill: #b5e2fb; }

.symbol-MasonryPage .cls-9 {
  fill: #eaf7ff; }

.symbol-MediaBottom .cls-4 {
  fill: #7fccf7; }

.symbol-MediaBottom .cls-5 {
  fill: #b5e2fb; }

.symbol-MediaBottom .cls-6 {
  fill: #eaf7ff; }

.symbol-MediaBottomPage .cls-4 {
  fill: #7fccf7; }

.symbol-MediaBottomPage .cls-5 {
  fill: #b5e2fb; }

.symbol-MediaBottomPage .cls-6 {
  fill: #eaf7ff; }

.symbol-MediaLeft .cls-4 {
  fill: #7fccf7; }

.symbol-MediaLeft .cls-9 {
  fill: #b5e2fb; }

.symbol-MediaLeft .cls-10 {
  fill: #f0f3f5; }

.symbol-MediaLeft .cls-11 {
  fill: #eaf7ff; }

.symbol-MediaLeftPage .cls-4 {
  fill: #7fccf7; }

.symbol-MediaLeftPage .cls-9 {
  fill: #b5e2fb; }

.symbol-MediaLeftPage .cls-10 {
  fill: #f0f3f5; }

.symbol-MediaLeftPage .cls-11 {
  fill: #eaf7ff; }

.symbol-MediaRight .cls-4 {
  fill: #7fccf7; }

.symbol-MediaRight .cls-7 {
  fill: #b5e2fb; }

.symbol-MediaRight .cls-8 {
  fill: #f0f3f5; }

.symbol-MediaRight .cls-9 {
  fill: #eaf7ff; }

.symbol-MediaRightPage .cls-4 {
  fill: #7fccf7; }

.symbol-MediaRightPage .cls-7 {
  fill: #b5e2fb; }

.symbol-MediaRightPage .cls-8 {
  fill: #f0f3f5; }

.symbol-MediaRightPage .cls-9 {
  fill: #eaf7ff; }

.symbol-MediaTop .cls-4 {
  fill: #7fccf7; }

.symbol-MediaTop .cls-5 {
  fill: #b5e2fb; }

.symbol-MediaTop .cls-6 {
  fill: #eaf7ff; }

.symbol-MediaTopPage .cls-4 {
  fill: #7fccf7; }

.symbol-MediaTopPage .cls-5 {
  fill: #b5e2fb; }

.symbol-MediaTopPage .cls-6 {
  fill: #eaf7ff; }

.symbol-MediaZigzag .cls-4 {
  fill: #7fccf7; }

.symbol-MediaZigzag .cls-7 {
  fill: #b5e2fb; }

.symbol-MediaZigzag .cls-8 {
  fill: #f0f3f5; }

.symbol-MediaZigzag .cls-9 {
  fill: #eaf7ff; }

.symbol-MediaZigzagPage .cls-4 {
  fill: #7fccf7; }

.symbol-MediaZigzagPage .cls-7 {
  fill: #b5e2fb; }

.symbol-MediaZigzagPage .cls-8 {
  fill: #f0f3f5; }

.symbol-MediaZigzagPage .cls-9 {
  fill: #eaf7ff; }

.symbol-PostsGalleryMediaBottom .cls-4 {
  fill: #7fccf7; }

.symbol-PostsGalleryMediaBottom .cls-6 {
  fill: #b5e2fb; }

.symbol-PostsGalleryMediaBottom .cls-7 {
  fill: #eaf7ff; }

.symbol-PostsGalleryMediaTop .cls-4 {
  fill: #7fccf7; }

.symbol-PostsGalleryMediaTop .cls-6 {
  fill: #b5e2fb; }

.symbol-PostsGalleryMediaTop .cls-7 {
  fill: #eaf7ff; }

.symbol-PostsList .cls-4 {
  fill: #7fccf7; }

.symbol-PostsListMediaBottom .cls-4 {
  fill: #7fccf7; }

.symbol-PostsListMediaBottom .cls-6 {
  fill: #b5e2fb; }

.symbol-PostsListMediaBottom .cls-7 {
  fill: #eaf7ff; }

.symbol-PostsListMediaLeft .cls-4 {
  fill: #7fccf7; }

.symbol-PostsListMediaLeft .cls-5 {
  fill: #b5e2fb; }

.symbol-PostsListMediaLeft .cls-6 {
  fill: #eaf7ff; }

.symbol-PostsListMediaTop .cls-4 {
  fill: #7fccf7; }

.symbol-PostsListMediaTop .cls-6 {
  fill: #b5e2fb; }

.symbol-PostsListMediaTop .cls-7 {
  fill: #eaf7ff; }

.symbol-TickerMediaBottom .cls-4 {
  fill: #7fccf7; }

.symbol-TickerMediaBottom .cls-6 {
  fill: #b5e2fb; }

.symbol-TickerMediaBottom .cls-7 {
  fill: #eaf7ff; }

.symbol-TickerMediaTop .cls-4 {
  fill: #7fccf7; }

.symbol-TickerMediaTop .cls-6 {
  fill: #b5e2fb; }

.symbol-TickerMediaTop .cls-7 {
  fill: #eaf7ff; }

.symbol-bottomTumbnails .cls-4 {
  fill: #eaf7ff; }

.symbol-bottomTumbnails .cls-5 {
  fill: #7fccf7; }

.symbol-connectNewDomain .cls-44 {
  fill: #d3edff; }

.symbol-connectNewDomain .cls-5 {
  fill: #ffffff; }

.symbol-connectNewDomain .cls-6 {
  fill: #4eb7f5; }

.symbol-defaultSkinIcon .style0 {
  font-size: 13px;
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  fill: white; }

.symbol-diamond .cls-4 {
  fill: #3899ec; }

.symbol-diamond .cls-5 {
  fill: #7fccf7; }

.symbol-dragHandle .cls-4 {
  fill: #7a92a5; }

.symbol-firstTimeInfoBoxClose {
  stroke: #3899ec; }

.symbol-firstTimeInfoBoxArrowRight {
  cursor: pointer;
  position: relative;
  padding-left: 9px;
  stroke: #3899ec; }

.symbol-hangon .cls-4 {
  fill: #d3edff; }

.symbol-hangon .cls-5 {
  fill: #ffffff; }

.symbol-hangon .cls-6 {
  fill: #3899ec; }

.symbol-hexagon .cls-4 {
  fill: #eaf7ff; }

.symbol-hexagon .cls-5 {
  fill: #7fccf7; }

.symbol-horizontalSpread .cls-4 {
  fill: #7fccf7; }

.symbol-horizontalSpread .cls-5 {
  fill: #4eb7f5; }

.symbol-leftTumbnails .cls-4 {
  fill: #eaf7ff; }

.symbol-leftTumbnails .cls-5 {
  fill: #7fccf7; }

.symbol-linkBtnThin path {
  fill: #fff; }

.symbol-mobile .icon {
  fill: #1D2D3C; }

.symbol-desktop .icon {
  fill: #1D2D3C; }

.symbol-noLink {
  stroke: none;
  stroke-width: 1;
  fill: none;
  fill-rule: evenodd; }
  .symbol-noLink .plug-pin {
    fill: #B1DDF9; }
  .symbol-noLink .wires {
    stroke: #A7E3FA;
    stroke-width: 2; }
  .symbol-noLink .plug-body {
    fill: #A7E3FA; }
  .symbol-noLink .plug-strip {
    fill: #5FCEF6; }
  .symbol-noLink .plug-shadow {
    fill: #eaf7ff; }
  .symbol-noLink .white {
    fill: #fff; }

.symbol-noPopup {
  stroke: none;
  stroke-width: 1;
  fill: none;
  fill-rule: evenodd; }
  .symbol-noPopup .shadow {
    fill: #eaf7ff; }
  .symbol-noPopup .header {
    fill: #A7E3FA; }
  .symbol-noPopup .blue {
    fill: #5FCEF5; }
  .symbol-noPopup .white {
    fill: #fff; }

.symbol-anchorBottom {
  stroke: none;
  stroke-width: 1;
  fill: none;
  fill-rule: evenodd; }
  .symbol-anchorBottom .background {
    fill: #d3edff; }
  .symbol-anchorBottom .bottom-line {
    fill: #7fccf7; }
  .symbol-anchorBottom .arrow {
    stroke: #3899ec;
    stroke-linecap: square; }

.symbol-anchorTop {
  stroke: none;
  stroke-width: 1;
  fill: none;
  fill-rule: evenodd; }
  .symbol-anchorTop .background {
    fill: #d3edff; }
  .symbol-anchorTop .top-line {
    fill: #7fccf7; }
  .symbol-anchorTop .arrow {
    stroke: #3899ec;
    stroke-linecap: square; }

.symbol-openLinkInWindow .cls-4 {
  fill: #0099ef; }

.symbol-pageSelector {
  fill: #fff; }
  .control-action-with-symbol > .symbol-container > .symbol-pageSelector {
    width: 12px;
    height: 12px; }

.symbol-publishSite .cls-4 {
  fill: #d3edff; }

.symbol-publishSite .cls-5 {
  fill: #ffffff; }

.symbol-publishSite .cls-6 {
  fill: #4eb7f5; }

.symbol-plusBig .circle {
  stroke: #3899ec;
  fill: transparent; }
  .disabled.symbol-plusBig .circle {
    stroke: #bcbcbc; }

.symbol-plusBig .plus {
  fill: #3899ec; }
  .disabled.symbol-plusBig .plus {
    fill: #bcbcbc; }

.symbol-plusBig:hover:not(.disabled) .circle {
  fill: #4eb7f5;
  stroke: #4eb7f5; }

.symbol-plusBig:hover:not(.disabled) .plus {
  fill: #ffffff; }

.symbol-rightTumbnails .cls-4 {
  fill: #3899ec; }

.symbol-rightTumbnails .cls-5 {
  fill: #7fccf7; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowPolaroid .cls-4 {
  fill: #7fccf7; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowPolaroid .cls-5 {
  fill: #b5e2fb; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowPolaroid .cls-6 {
  fill: #eaf7ff; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowPolaroid .cls-7 {
  fill: #4eb7f5; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowPolaroid .cls-9 {
  fill: #ffffff; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextBottom .cls-4 {
  fill: #ffffff; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextBottom .cls-5 {
  fill: #7fccf7; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextBottom .cls-6 {
  fill: #b5e2fb; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextBottom .cls-7 {
  fill: #eaf7ff; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextBottom .cls-9 {
  fill: #4eb7f5; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextFloating .cls-4 {
  fill: #7fccf7; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextFloating .cls-5 {
  fill: #b5e2fb; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextFloating .cls-6 {
  fill: #ffffff; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextFloating .cls-8 {
  fill: #eaf7ff; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextFloating .cls-10 {
  fill: #4eb7f5; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextOverlay .cls-4 {
  fill: #7fccf7; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextOverlay .cls-5 {
  fill: #b5e2fb; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextOverlay .cls-6 {
  fill: #eaf7ff; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextOverlay .cls-9 {
  fill: #ffffff; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextRight .cls-4 {
  fill: #7fccf7; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextRight .cls-5 {
  fill: #b5e2fb; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextRight .cls-6 {
  fill: #eaf7ff; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextRight .cls-8 {
  fill: #4eb7f5; }

.symbol-wysiwyg_viewer_skins_gallery_SlideShowTextRight .cls-9 {
  fill: #ffffff; }

.symbol-spreadLayout .cls-3 {
  fill: #4eb7f5; }

.symbol-spreadLayout .cls-4 {
  fill: #7fccf7; }

.symbol-spreadLayout .cls-6 {
  fill: #d3edff; }

.symbol-square .cls-4 {
  fill: #eaf7ff; }

.symbol-square .cls-5 {
  fill: #7fccf7; }

.symbol-successIcon {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  fill: #60bc57; }

.symbol-stackLayout .cls-3 {
  fill: #d3edff; }

.symbol-stackLayout .cls-4 {
  fill: #7fccf7; }

.symbol-stackLayout .cls-5 {
  fill: #4eb7f5; }

.symbol-topTumbnails .cls-4 {
  fill: #eaf7ff; }

.symbol-topTumbnails .cls-5 {
  fill: #7fccf7; }

.symbol-topbarDevicePromo .cls-3 {
  fill: #eaf7ff; }

.symbol-topbarDevicePromo .cls-4 {
  fill: #7fccf7; }

.checkmark-symbol {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  position: absolute;
  background-color: #ffffff; }
  .top-bar-edit-mode-last-btn .checkmark-symbol {
    background-color: #3899ec; }
  .top-bar-edit-mode-last-btn:hover .checkmark-symbol {
    background-color: #4eb7f5; }
  .checkmark-symbol .check {
    fill: #3899ec; }
    .top-bar-edit-mode-last-btn .checkmark-symbol .check {
      fill: #ffffff; }

.symbol-triangle .cls-4 {
  fill: #eaf7ff; }

.symbol-triangle .cls-5 {
  fill: #7fccf7; }

.symbol-vCyclePicker .cls-2 {
  fill: #3899ec; }

.symbol-verticalSpread .cls-4 {
  fill: #7fccf7; }

.symbol-verticalSpread .cls-5 {
  fill: #4eb7f5; }

.symbol-videoLayoutShow .st1 {
  fill: #7fccf7; }

.symbol-videoLayoutShow .st2 {
  fill: #eaf7ff; }

.symbol-videoLayoutHide .st1 {
  fill: #7fccf7; }

.symbol-videoLayoutHide .st2 {
  fill: #eaf7ff; }

.symbol-videoPlayerStoryboardNone .st1,
.control-videoPlayerStoryboardNone .symbol-videoPlayerStoryboardNone .st1 {
  fill: #7fccf7;
  opacity: 0.6; }

.symbol-videoPlayerStoryboardNone .st2,
.control-videoPlayerStoryboardNone .symbol-videoPlayerStoryboardNone .st2 {
  fill: #4eb7f5; }

.selected .symbol-videoPlayerStoryboardNone .st1,
.control-videoPlayerStoryboardNone .input-videoPlayerStoryboardNone:checked ~ .symbol-videoPlayerStoryboardNone .st1 {
  fill: #fff; }

.selected .symbol-videoPlayerStoryboardNone .st2,
.control-videoPlayerStoryboardNone .input-videoPlayerStoryboardNone:checked ~ .symbol-videoPlayerStoryboardNone .st2 {
  fill: #d3edff; }

.symbol-videoPlayerStoryboardPreview .st1,
.control-videoPlayerStoryboardPreview .symbol-videoPlayerStoryboardPreview .st1 {
  fill: #7fccf7;
  opacity: 0.6; }

.symbol-videoPlayerStoryboardPreview .st2,
.control-videoPlayerStoryboardPreview .symbol-videoPlayerStoryboardPreview .st2 {
  fill: #4eb7f5; }

.symbol-videoPlayerStoryboardPreview .st3,
.control-videoPlayerStoryboardPreview .symbol-videoPlayerStoryboardPreview .st3 {
  fill: #4eb7f5; }

.symbol-videoPlayerStoryboardPreview .st4,
.control-videoPlayerStoryboardPreview .symbol-videoPlayerStoryboardPreview .st4 {
  fill: #d3edff; }

.symbol-videoPlayerStoryboardPreview .st5,
.control-videoPlayerStoryboardPreview .symbol-videoPlayerStoryboardPreview .st5 {
  fill: #7fccf7;
  opacity: 0.6; }

.symbol-videoPlayerStoryboardPreview .st6,
.control-videoPlayerStoryboardPreview .symbol-videoPlayerStoryboardPreview .st6 {
  fill: #7fccf7; }

.symbol-videoPlayerStoryboardPreview .st7,
.control-videoPlayerStoryboardPreview .symbol-videoPlayerStoryboardPreview .st7 {
  fill: #fff; }

.selected .symbol-videoPlayerStoryboardPreview .st1,
.control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:checked ~ .symbol-videoPlayerStoryboardPreview .st1 {
  fill: #fff; }

.selected .symbol-videoPlayerStoryboardPreview .st2,
.control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:checked ~ .symbol-videoPlayerStoryboardPreview .st2 {
  fill: #d3edff; }

.selected .symbol-videoPlayerStoryboardPreview .st3,
.control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:checked ~ .symbol-videoPlayerStoryboardPreview .st3 {
  fill: #d3edff; }

.selected .symbol-videoPlayerStoryboardPreview .st4,
.control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:checked ~ .symbol-videoPlayerStoryboardPreview .st4 {
  fill: #7fccf7; }

.selected .symbol-videoPlayerStoryboardPreview .st5,
.control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:checked ~ .symbol-videoPlayerStoryboardPreview .st5 {
  fill: #eaf7ff; }

.selected .symbol-videoPlayerStoryboardPreview .st6,
.control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:checked ~ .symbol-videoPlayerStoryboardPreview .st6 {
  fill: #fff; }

.selected .symbol-videoPlayerStoryboardPreview .st7,
.control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:checked ~ .symbol-videoPlayerStoryboardPreview .st7 {
  fill: #4eb7f5; }

.control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:disabled ~ .symbol-videoPlayerStoryboardPreview {
  pointer-events: none; }
  .control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:disabled ~ .symbol-videoPlayerStoryboardPreview .st1 {
    fill: #d5d5d5;
    opacity: 0.6; }
  .control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:disabled ~ .symbol-videoPlayerStoryboardPreview .st2 {
    fill: #c8c8c8; }
  .control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:disabled ~ .symbol-videoPlayerStoryboardPreview .st3 {
    fill: #c8c8c8; }
  .control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:disabled ~ .symbol-videoPlayerStoryboardPreview .st4 {
    fill: #e2e2e2; }
  .control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:disabled ~ .symbol-videoPlayerStoryboardPreview .st5 {
    fill: #d5d5d5;
    opacity: 0.6; }
  .control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:disabled ~ .symbol-videoPlayerStoryboardPreview .st6 {
    fill: #d5d5d5; }
  .control-videoPlayerStoryboardPreview .input-videoPlayerStoryboardPreview:disabled ~ .symbol-videoPlayerStoryboardPreview .st7 {
    fill: #fff; }

.symbol-videoPlayerStoryboardTime .st1,
.control-videoPlayerStoryboardTime .symbol-videoPlayerStoryboardTime .st1 {
  fill: #7fccf7;
  opacity: 0.6; }

.symbol-videoPlayerStoryboardTime .st2,
.control-videoPlayerStoryboardTime .symbol-videoPlayerStoryboardTime .st2 {
  fill: #4eb7f5; }

.symbol-videoPlayerStoryboardTime .st3,
.control-videoPlayerStoryboardTime .symbol-videoPlayerStoryboardTime .st3 {
  fill: #fff; }

.selected .symbol-videoPlayerStoryboardTime .st1,
.control-videoPlayerStoryboardTime .input-videoPlayerStoryboardTime:checked ~ .symbol-videoPlayerStoryboardTime .st1 {
  fill: #fff; }

.selected .symbol-videoPlayerStoryboardTime .st2,
.control-videoPlayerStoryboardTime .input-videoPlayerStoryboardTime:checked ~ .symbol-videoPlayerStoryboardTime .st2 {
  fill: #d3edff; }

.selected .symbol-videoPlayerStoryboardTime .st3,
.control-videoPlayerStoryboardTime .input-videoPlayerStoryboardTime:checked ~ .symbol-videoPlayerStoryboardTime .st3 {
  fill: #4eb7f5; }

.symbol-video-player-change-poster .st1 {
  fill: #3899ec; }

.symbol-video-player-change-poster .st2 {
  fill: #fff; }

.symbol-video-player-change-poster .st3 {
  fill: #fff; }

.symbol-video-player-change-poster:hover .st1 {
  fill: #7fccf7; }

.symbol-video-player-change-poster:hover .st2 {
  fill: #fff; }

.symbol-video-player-change-poster:hover .st3 {
  fill: #fff; }

.symbol-addThumbnail .line {
  fill: #3899ec; }

.symbol-zoomClose {
  stroke: none;
  stroke-width: 1px;
  fill: none; }
  .symbol-zoomClose .c1 {
    fill: #DFF2DD; }
  .symbol-zoomClose .c2 {
    stroke: #468B42; }

.symbol-zoomClose:hover .c1 {
  fill: #cfebcc; }

.symbol-zoomBarHelp .c1 {
  fill: #2D4150; }

.symbol-zoomBarHelp:hover .c1 {
  fill: #3899EC; }

.symbol-transition-gallery-crossfade .st2,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st2 {
  fill: #ADDDFB; }

.symbol-transition-gallery-crossfade .st3,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st3 {
  fill: #CDEBFD; }

.symbol-transition-gallery-crossfade .st4,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st4 {
  fill: #E3F3FF; }

.symbol-transition-gallery-crossfade .st5,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st5 {
  fill: #7DCAF9; }

.symbol-transition-gallery-crossfade .st6,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st6 {
  fill: #6AC2F8; }

.symbol-transition-gallery-crossfade .st7,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st7 {
  fill: #74C6F8; }

.symbol-transition-gallery-crossfade .st8,
.symbol-transition-gallery-crossfade .st9,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st8,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st9 {
  fill: #7AC9F9; }

.symbol-transition-gallery-crossfade .st10,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st10 {
  fill: #B6E1FC; }

.symbol-transition-gallery-crossfade .st11,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st11 {
  fill: #A2D9FB; }

.symbol-transition-gallery-crossfade .st12,
.symbol-transition-gallery-crossfade .st16,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st12,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st16 {
  fill: #C8E8FE; }

.symbol-transition-gallery-crossfade .st13,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st13 {
  fill: #D1ECFE; }

.symbol-transition-gallery-crossfade .st14,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st14 {
  fill: #D8EFFF; }

.symbol-transition-gallery-crossfade .st15,
.control-transition-gallery-crossfade .symbol-transition-gallery-crossfade .st15 {
  fill: #DBF0FF; }

.selected .symbol-transition-gallery-crossfade .st2,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st2 {
  fill: #76BBF4; }

.selected .symbol-transition-gallery-crossfade .st3,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st3 {
  fill: #62B3F2; }

.selected .symbol-transition-gallery-crossfade .st4,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st4 {
  fill: #54ADF0; }

.selected .symbol-transition-gallery-crossfade .st5,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st5 {
  fill: #A5D4F9; }

.selected .symbol-transition-gallery-crossfade .st6,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st6 {
  fill: #B7DEFC; }

.selected .symbol-transition-gallery-crossfade .st7,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st7 {
  fill: #B1DCFB; }

.selected .symbol-transition-gallery-crossfade .st8,
.selected .symbol-transition-gallery-crossfade .st9,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st8,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st9 {
  fill: #ADDAFB; }

.selected .symbol-transition-gallery-crossfade .st10,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st10 {
  fill: #82C6F7; }

.selected .symbol-transition-gallery-crossfade .st11,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st11 {
  fill: #94D0F9; }

.selected .symbol-transition-gallery-crossfade .st12,
.selected .symbol-transition-gallery-crossfade .st16,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st12,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st16 {
  fill: #7CC7F7; }

.selected .symbol-transition-gallery-crossfade .st13,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st13 {
  fill: #76C5F7; }

.selected .symbol-transition-gallery-crossfade .st14,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st14 {
  fill: #72C3F6; }

.selected .symbol-transition-gallery-crossfade .st15,
.control-transition-gallery-crossfade .input-transition-gallery-crossfade:checked ~ .symbol-transition-gallery-crossfade .st15 {
  fill: #6ABDF4; }

.symbol-transition-gallery-fade .st2,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st2 {
  fill: #ADDDFB; }

.symbol-transition-gallery-fade .st3,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st3 {
  fill: #CDEBFD; }

.symbol-transition-gallery-fade .st4,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st4 {
  fill: #E3F3FF; }

.symbol-transition-gallery-fade .st5,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st5 {
  fill: #7DCAF9; }

.symbol-transition-gallery-fade .st6,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st6 {
  fill: #6AC2F8; }

.symbol-transition-gallery-fade .st7,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st7 {
  fill: #74C6F8; }

.symbol-transition-gallery-fade .st8,
.symbol-transition-gallery-fade .st9,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st8,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st9 {
  fill: #7AC9F9; }

.symbol-transition-gallery-fade .st10,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st10 {
  fill: #B6E1FC; }

.symbol-transition-gallery-fade .st11,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st11 {
  fill: #A2D9FB; }

.symbol-transition-gallery-fade .st12,
.symbol-transition-gallery-fade .st16,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st12,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st16 {
  fill: #C8E8FE; }

.symbol-transition-gallery-fade .st13,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st13 {
  fill: #D1ECFE; }

.symbol-transition-gallery-fade .st14,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st14 {
  fill: #D8EFFF; }

.symbol-transition-gallery-fade .st15,
.control-transition-gallery-fade .symbol-transition-gallery-fade .st15 {
  fill: #DBF0FF; }

.selected .symbol-transition-gallery-fade .st2,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st2 {
  fill: #76BBF4; }

.selected .symbol-transition-gallery-fade .st3,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st3 {
  fill: #62B3F2; }

.selected .symbol-transition-gallery-fade .st4,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st4 {
  fill: #54ADF0; }

.selected .symbol-transition-gallery-fade .st5,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st5 {
  fill: #A5D4F9; }

.selected .symbol-transition-gallery-fade .st6,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st6 {
  fill: #B7DEFC; }

.selected .symbol-transition-gallery-fade .st7,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st7 {
  fill: #B1DCFB; }

.selected .symbol-transition-gallery-fade .st8,
.selected .symbol-transition-gallery-fade .st9,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st8,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st9 {
  fill: #ADDAFB; }

.selected .symbol-transition-gallery-fade .st10,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st10 {
  fill: #82C6F7; }

.selected .symbol-transition-gallery-fade .st11,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st11 {
  fill: #94D0F9; }

.selected .symbol-transition-gallery-fade .st12,
.selected .symbol-transition-gallery-fade .st16,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st12,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st16 {
  fill: #7CC7F7; }

.selected .symbol-transition-gallery-fade .st13,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st13 {
  fill: #76C5F7; }

.selected .symbol-transition-gallery-fade .st14,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st14 {
  fill: #72C3F6; }

.selected .symbol-transition-gallery-fade .st15,
.control-transition-gallery-fade .input-transition-gallery-fade:checked ~ .symbol-transition-gallery-fade .st15 {
  fill: #6ABDF4; }

.symbol-transition-gallery-horizontal .st2,
.control-transition-gallery-horizontal .symbol-transition-gallery-horizontal .st2 {
  fill: #4EB7F6; }

.symbol-transition-gallery-horizontal .st3,
.control-transition-gallery-horizontal .symbol-transition-gallery-horizontal .st3 {
  fill: #9ED7FB; }

.symbol-transition-gallery-horizontal .st4,
.symbol-transition-gallery-horizontal .st5,
.control-transition-gallery-horizontal .symbol-transition-gallery-horizontal .st4,
.control-transition-gallery-horizontal .symbol-transition-gallery-horizontal .st5 {
  fill: #d3edff; }

.symbol-transition-gallery-horizontal .st6,
.symbol-transition-gallery-horizontal .st7,
.symbol-transition-gallery-horizontal .st8,
.control-transition-gallery-horizontal .symbol-transition-gallery-horizontal .st6,
.control-transition-gallery-horizontal .symbol-transition-gallery-horizontal .st7,
.control-transition-gallery-horizontal .symbol-transition-gallery-horizontal .st8 {
  fill: #7FCCF8; }

.selected .symbol-transition-gallery-horizontal .st2,
.control-transition-gallery-horizontal .input-transition-gallery-horizontal:checked ~ .symbol-transition-gallery-horizontal .st2 {
  fill: #d3edff; }

.selected .symbol-transition-gallery-horizontal .st3,
.control-transition-gallery-horizontal .input-transition-gallery-horizontal:checked ~ .symbol-transition-gallery-horizontal .st3 {
  fill: #A1D9FA; }

.selected .symbol-transition-gallery-horizontal .st4,
.selected .symbol-transition-gallery-horizontal .st5,
.control-transition-gallery-horizontal .input-transition-gallery-horizontal:checked ~ .symbol-transition-gallery-horizontal .st4,
.control-transition-gallery-horizontal .input-transition-gallery-horizontal:checked ~ .symbol-transition-gallery-horizontal .st5 {
  fill: #7FCCF8; }

.selected .symbol-transition-gallery-horizontal .st6,
.selected .symbol-transition-gallery-horizontal .st7,
.selected .symbol-transition-gallery-horizontal .st8,
.control-transition-gallery-horizontal .input-transition-gallery-horizontal:checked ~ .symbol-transition-gallery-horizontal .st6,
.control-transition-gallery-horizontal .input-transition-gallery-horizontal:checked ~ .symbol-transition-gallery-horizontal .st7,
.control-transition-gallery-horizontal .input-transition-gallery-horizontal:checked ~ .symbol-transition-gallery-horizontal .st8 {
  fill: #FFFFFF; }

.symbol-transition-gallery-inout .st2,
.control-transition-gallery-inout .symbol-transition-gallery-inout .st2 {
  fill: #ADDDFB; }

.symbol-transition-gallery-inout .st3,
.control-transition-gallery-inout .symbol-transition-gallery-inout .st3 {
  fill: #7DCAF9; }

.symbol-transition-gallery-inout .st4,
.control-transition-gallery-inout .symbol-transition-gallery-inout .st4 {
  fill: #6AC2F8; }

.symbol-transition-gallery-inout .st5,
.control-transition-gallery-inout .symbol-transition-gallery-inout .st5 {
  fill: #B6E1FC; }

.symbol-transition-gallery-inout .st6,
.control-transition-gallery-inout .symbol-transition-gallery-inout .st6 {
  fill: #A2D9FB; }

.symbol-transition-gallery-inout .st7,
.symbol-transition-gallery-inout .st9,
.control-transition-gallery-inout .symbol-transition-gallery-inout .st7,
.control-transition-gallery-inout .symbol-transition-gallery-inout .st9 {
  fill: #C8E8FE; }

.symbol-transition-gallery-inout .st8,
.control-transition-gallery-inout .symbol-transition-gallery-inout .st8 {
  fill: #DBF0FF; }

.selected .symbol-transition-gallery-inout .st2,
.control-transition-gallery-inout .input-transition-gallery-inout:checked ~ .symbol-transition-gallery-inout .st2 {
  fill: #76BBF4; }

.selected .symbol-transition-gallery-inout .st3,
.control-transition-gallery-inout .input-transition-gallery-inout:checked ~ .symbol-transition-gallery-inout .st3 {
  fill: #A5D4F9; }

.selected .symbol-transition-gallery-inout .st4,
.control-transition-gallery-inout .input-transition-gallery-inout:checked ~ .symbol-transition-gallery-inout .st4 {
  fill: #B7DEFC; }

.selected .symbol-transition-gallery-inout .st5,
.control-transition-gallery-inout .input-transition-gallery-inout:checked ~ .symbol-transition-gallery-inout .st5 {
  fill: #82C6F7; }

.selected .symbol-transition-gallery-inout .st6,
.control-transition-gallery-inout .input-transition-gallery-inout:checked ~ .symbol-transition-gallery-inout .st6 {
  fill: #94D0F9; }

.selected .symbol-transition-gallery-inout .st7,
.selected .symbol-transition-gallery-inout .st9,
.control-transition-gallery-inout .input-transition-gallery-inout:checked ~ .symbol-transition-gallery-inout .st7,
.control-transition-gallery-inout .input-transition-gallery-inout:checked ~ .symbol-transition-gallery-inout .st9 {
  fill: #7CC7F7; }

.selected .symbol-transition-gallery-inout .st8,
.control-transition-gallery-inout .input-transition-gallery-inout:checked ~ .symbol-transition-gallery-inout .st8 {
  fill: #6ABDF4; }

.symbol-transition-gallery-popabove .st2,
.control-transition-gallery-popabove .symbol-transition-gallery-popabove .st2 {
  fill: #4EB7F6; }

.symbol-transition-gallery-popabove .st3,
.control-transition-gallery-popabove .symbol-transition-gallery-popabove .st3 {
  fill: #9ED7FB; }

.symbol-transition-gallery-popabove .st4,
.symbol-transition-gallery-popabove .st5,
.control-transition-gallery-popabove .symbol-transition-gallery-popabove .st4,
.control-transition-gallery-popabove .symbol-transition-gallery-popabove .st5 {
  fill: #d3edff; }

.symbol-transition-gallery-popabove .st6,
.symbol-transition-gallery-popabove .st7,
.symbol-transition-gallery-popabove .st8,
.symbol-transition-gallery-popabove .st9,
.control-transition-gallery-popabove .symbol-transition-gallery-popabove .st6,
.control-transition-gallery-popabove .symbol-transition-gallery-popabove .st7,
.control-transition-gallery-popabove .symbol-transition-gallery-popabove .st8,
.control-transition-gallery-popabove .symbol-transition-gallery-popabove .st9 {
  fill: #7FCCF8; }

.selected .symbol-transition-gallery-popabove .st2,
.control-transition-gallery-popabove .input-transition-gallery-popabove:checked ~ .symbol-transition-gallery-popabove .st2 {
  fill: #d3edff; }

.selected .symbol-transition-gallery-popabove .st3,
.control-transition-gallery-popabove .input-transition-gallery-popabove:checked ~ .symbol-transition-gallery-popabove .st3 {
  fill: #A1D9FA; }

.selected .symbol-transition-gallery-popabove .st4,
.selected .symbol-transition-gallery-popabove .st5,
.control-transition-gallery-popabove .input-transition-gallery-popabove:checked ~ .symbol-transition-gallery-popabove .st4,
.control-transition-gallery-popabove .input-transition-gallery-popabove:checked ~ .symbol-transition-gallery-popabove .st5 {
  fill: #7FCCF8; }

.selected .symbol-transition-gallery-popabove .st6,
.selected .symbol-transition-gallery-popabove .st7,
.selected .symbol-transition-gallery-popabove .st8,
.selected .symbol-transition-gallery-popabove .st9,
.control-transition-gallery-popabove .input-transition-gallery-popabove:checked ~ .symbol-transition-gallery-popabove .st6,
.control-transition-gallery-popabove .input-transition-gallery-popabove:checked ~ .symbol-transition-gallery-popabove .st7,
.control-transition-gallery-popabove .input-transition-gallery-popabove:checked ~ .symbol-transition-gallery-popabove .st8,
.control-transition-gallery-popabove .input-transition-gallery-popabove:checked ~ .symbol-transition-gallery-popabove .st9 {
  fill: #FFFFFF; }

.symbol-transition-gallery-popleft .st2,
.symbol-transition-gallery-popleft .st3,
.symbol-transition-gallery-popleft .st4,
.symbol-transition-gallery-popleft .st9,
.control-transition-gallery-popleft .symbol-transition-gallery-popleft .st2,
.control-transition-gallery-popleft .symbol-transition-gallery-popleft .st3,
.control-transition-gallery-popleft .symbol-transition-gallery-popleft .st4,
.control-transition-gallery-popleft .symbol-transition-gallery-popleft .st9 {
  fill: #7FCCF8; }

.symbol-transition-gallery-popleft .st5,
.control-transition-gallery-popleft .symbol-transition-gallery-popleft .st5 {
  fill: #4EB7F6; }

.symbol-transition-gallery-popleft .st6,
.control-transition-gallery-popleft .symbol-transition-gallery-popleft .st6 {
  fill: #9ED7FB; }

.symbol-transition-gallery-popleft .st7,
.symbol-transition-gallery-popleft .st8,
.control-transition-gallery-popleft .symbol-transition-gallery-popleft .st7,
.control-transition-gallery-popleft .symbol-transition-gallery-popleft .st8 {
  fill: #d3edff; }

.selected .symbol-transition-gallery-popleft .st2,
.selected .symbol-transition-gallery-popleft .st3,
.selected .symbol-transition-gallery-popleft .st4,
.selected .symbol-transition-gallery-popleft .st9,
.control-transition-gallery-popleft .input-transition-gallery-popleft:checked ~ .symbol-transition-gallery-popleft .st2,
.control-transition-gallery-popleft .input-transition-gallery-popleft:checked ~ .symbol-transition-gallery-popleft .st3,
.control-transition-gallery-popleft .input-transition-gallery-popleft:checked ~ .symbol-transition-gallery-popleft .st4,
.control-transition-gallery-popleft .input-transition-gallery-popleft:checked ~ .symbol-transition-gallery-popleft .st9 {
  fill: #FFFFFF; }

.selected .symbol-transition-gallery-popleft .st5,
.control-transition-gallery-popleft .input-transition-gallery-popleft:checked ~ .symbol-transition-gallery-popleft .st5 {
  fill: #d3edff; }

.selected .symbol-transition-gallery-popleft .st6,
.control-transition-gallery-popleft .input-transition-gallery-popleft:checked ~ .symbol-transition-gallery-popleft .st6 {
  fill: #A1D9FA; }

.selected .symbol-transition-gallery-popleft .st7,
.selected .symbol-transition-gallery-popleft .st8,
.control-transition-gallery-popleft .input-transition-gallery-popleft:checked ~ .symbol-transition-gallery-popleft .st7,
.control-transition-gallery-popleft .input-transition-gallery-popleft:checked ~ .symbol-transition-gallery-popleft .st8 {
  fill: #7FCCF8; }

.symbol-transition-gallery-popright .st2,
.symbol-transition-gallery-popright .st3,
.symbol-transition-gallery-popright .st4,
.symbol-transition-gallery-popright .st9,
.control-transition-gallery-popright .symbol-transition-gallery-popright .st2,
.control-transition-gallery-popright .symbol-transition-gallery-popright .st3,
.control-transition-gallery-popright .symbol-transition-gallery-popright .st4,
.control-transition-gallery-popright .symbol-transition-gallery-popright .st9 {
  fill: #7FCCF8; }

.symbol-transition-gallery-popright .st5,
.control-transition-gallery-popright .symbol-transition-gallery-popright .st5 {
  fill: #4EB7F6; }

.symbol-transition-gallery-popright .st6,
.control-transition-gallery-popright .symbol-transition-gallery-popright .st6 {
  fill: #9ED7FB; }

.symbol-transition-gallery-popright .st7,
.symbol-transition-gallery-popright .st8,
.control-transition-gallery-popright .symbol-transition-gallery-popright .st7,
.control-transition-gallery-popright .symbol-transition-gallery-popright .st8 {
  fill: #d3edff; }

.selected .symbol-transition-gallery-popright .st2,
.selected .symbol-transition-gallery-popright .st3,
.selected .symbol-transition-gallery-popright .st4,
.selected .symbol-transition-gallery-popright .st9,
.control-transition-gallery-popright .input-transition-gallery-popright:checked ~ .symbol-transition-gallery-popright .st2,
.control-transition-gallery-popright .input-transition-gallery-popright:checked ~ .symbol-transition-gallery-popright .st3,
.control-transition-gallery-popright .input-transition-gallery-popright:checked ~ .symbol-transition-gallery-popright .st4,
.control-transition-gallery-popright .input-transition-gallery-popright:checked ~ .symbol-transition-gallery-popright .st9 {
  fill: #FFFFFF; }

.selected .symbol-transition-gallery-popright .st5,
.control-transition-gallery-popright .input-transition-gallery-popright:checked ~ .symbol-transition-gallery-popright .st5 {
  fill: #d3edff; }

.selected .symbol-transition-gallery-popright .st6,
.control-transition-gallery-popright .input-transition-gallery-popright:checked ~ .symbol-transition-gallery-popright .st6 {
  fill: #A1D9FA; }

.selected .symbol-transition-gallery-popright .st7,
.selected .symbol-transition-gallery-popright .st8,
.control-transition-gallery-popright .input-transition-gallery-popright:checked ~ .symbol-transition-gallery-popright .st7,
.control-transition-gallery-popright .input-transition-gallery-popright:checked ~ .symbol-transition-gallery-popright .st8 {
  fill: #7FCCF8; }

.symbol-transition-gallery-random .st2,
.control-transition-gallery-random .symbol-transition-gallery-random .st2 {
  fill: #ADDDFB; }

.symbol-transition-gallery-random .st3,
.control-transition-gallery-random .symbol-transition-gallery-random .st3 {
  fill: #8ED1F9; }

.symbol-transition-gallery-random .st4,
.control-transition-gallery-random .symbol-transition-gallery-random .st4 {
  fill: #4EB7F6; }

.symbol-transition-gallery-random .st5,
.control-transition-gallery-random .symbol-transition-gallery-random .st5 {
  fill: #9ED7FB; }

.symbol-transition-gallery-random .st6,
.symbol-transition-gallery-random .st7,
.control-transition-gallery-random .symbol-transition-gallery-random .st6,
.control-transition-gallery-random .symbol-transition-gallery-random .st7 {
  fill: #d3edff; }

.selected .symbol-transition-gallery-random .st2,
.control-transition-gallery-random .input-transition-gallery-random:checked ~ .symbol-transition-gallery-random .st2 {
  fill: #76BBF4; }

.selected .symbol-transition-gallery-random .st3,
.control-transition-gallery-random .input-transition-gallery-random:checked ~ .symbol-transition-gallery-random .st3 {
  fill: #95CBF8; }

.selected .symbol-transition-gallery-random .st4,
.control-transition-gallery-random .input-transition-gallery-random:checked ~ .symbol-transition-gallery-random .st4 {
  fill: #d3edff; }

.selected .symbol-transition-gallery-random .st5,
.control-transition-gallery-random .input-transition-gallery-random:checked ~ .symbol-transition-gallery-random .st5 {
  fill: #A1D9FA; }

.selected .symbol-transition-gallery-random .st6,
.selected .symbol-transition-gallery-random .st7,
.control-transition-gallery-random .input-transition-gallery-random:checked ~ .symbol-transition-gallery-random .st6,
.control-transition-gallery-random .input-transition-gallery-random:checked ~ .symbol-transition-gallery-random .st7 {
  fill: #7FCCF8; }

.symbol-transition-gallery-rollabove .st2,
.control-transition-gallery-rollabove .symbol-transition-gallery-rollabove .st2 {
  fill: #4EB7F6; }

.symbol-transition-gallery-rollabove .st3,
.control-transition-gallery-rollabove .symbol-transition-gallery-rollabove .st3 {
  fill: #B6E1FB; }

.symbol-transition-gallery-rollabove .st4,
.symbol-transition-gallery-rollabove .st5,
.control-transition-gallery-rollabove .symbol-transition-gallery-rollabove .st4,
.control-transition-gallery-rollabove .symbol-transition-gallery-rollabove .st5 {
  fill: #d3edff; }

.symbol-transition-gallery-rollabove .st6,
.symbol-transition-gallery-rollabove .st7,
.control-transition-gallery-rollabove .symbol-transition-gallery-rollabove .st6,
.control-transition-gallery-rollabove .symbol-transition-gallery-rollabove .st7 {
  fill: #7FCCF8; }

.selected .symbol-transition-gallery-rollabove .st2,
.control-transition-gallery-rollabove .input-transition-gallery-rollabove:checked ~ .symbol-transition-gallery-rollabove .st2 {
  fill: #d3edff; }

.selected .symbol-transition-gallery-rollabove .st3,
.control-transition-gallery-rollabove .input-transition-gallery-rollabove:checked ~ .symbol-transition-gallery-rollabove .st3 {
  fill: #A1D9FA; }

.selected .symbol-transition-gallery-rollabove .st4,
.selected .symbol-transition-gallery-rollabove .st5,
.control-transition-gallery-rollabove .input-transition-gallery-rollabove:checked ~ .symbol-transition-gallery-rollabove .st4,
.control-transition-gallery-rollabove .input-transition-gallery-rollabove:checked ~ .symbol-transition-gallery-rollabove .st5 {
  fill: #7FCCF8; }

.selected .symbol-transition-gallery-rollabove .st6,
.selected .symbol-transition-gallery-rollabove .st7,
.control-transition-gallery-rollabove .input-transition-gallery-rollabove:checked ~ .symbol-transition-gallery-rollabove .st6,
.control-transition-gallery-rollabove .input-transition-gallery-rollabove:checked ~ .symbol-transition-gallery-rollabove .st7 {
  fill: #FFFFFF; }

.symbol-transition-gallery-shrink .st2,
.control-transition-gallery-shrink .symbol-transition-gallery-shrink .st2 {
  fill: #4EB7F6; }

.symbol-transition-gallery-shrink .st3,
.control-transition-gallery-shrink .symbol-transition-gallery-shrink .st3 {
  fill: #9ED7FB; }

.symbol-transition-gallery-shrink .st4,
.symbol-transition-gallery-shrink .st5,
.control-transition-gallery-shrink .symbol-transition-gallery-shrink .st4,
.control-transition-gallery-shrink .symbol-transition-gallery-shrink .st5 {
  fill: #d3edff; }

.symbol-transition-gallery-shrink .st6,
.symbol-transition-gallery-shrink .st7,
.symbol-transition-gallery-shrink .st8,
.symbol-transition-gallery-shrink .st9,
.control-transition-gallery-shrink .symbol-transition-gallery-shrink .st6,
.control-transition-gallery-shrink .symbol-transition-gallery-shrink .st7,
.control-transition-gallery-shrink .symbol-transition-gallery-shrink .st8,
.control-transition-gallery-shrink .symbol-transition-gallery-shrink .st9 {
  fill: #7FCCF8; }

.selected .symbol-transition-gallery-shrink .st2,
.control-transition-gallery-shrink .input-transition-gallery-shrink:checked ~ .symbol-transition-gallery-shrink .st2 {
  fill: #d3edff; }

.selected .symbol-transition-gallery-shrink .st3,
.control-transition-gallery-shrink .input-transition-gallery-shrink:checked ~ .symbol-transition-gallery-shrink .st3 {
  fill: #A1D9FA; }

.selected .symbol-transition-gallery-shrink .st4,
.selected .symbol-transition-gallery-shrink .st5,
.control-transition-gallery-shrink .input-transition-gallery-shrink:checked ~ .symbol-transition-gallery-shrink .st4,
.control-transition-gallery-shrink .input-transition-gallery-shrink:checked ~ .symbol-transition-gallery-shrink .st5 {
  fill: #7FCCF8; }

.selected .symbol-transition-gallery-shrink .st6,
.selected .symbol-transition-gallery-shrink .st7,
.selected .symbol-transition-gallery-shrink .st8,
.selected .symbol-transition-gallery-shrink .st9,
.control-transition-gallery-shrink .input-transition-gallery-shrink:checked ~ .symbol-transition-gallery-shrink .st6,
.control-transition-gallery-shrink .input-transition-gallery-shrink:checked ~ .symbol-transition-gallery-shrink .st7,
.control-transition-gallery-shrink .input-transition-gallery-shrink:checked ~ .symbol-transition-gallery-shrink .st8,
.control-transition-gallery-shrink .input-transition-gallery-shrink:checked ~ .symbol-transition-gallery-shrink .st9 {
  fill: #FFFFFF; }

.symbol-transition-gallery-vertical .st2,
.control-transition-gallery-vertical .symbol-transition-gallery-vertical .st2 {
  fill: #4EB7F6; }

.symbol-transition-gallery-vertical .st3,
.control-transition-gallery-vertical .symbol-transition-gallery-vertical .st3 {
  fill: #9ED7FB; }

.symbol-transition-gallery-vertical .st4,
.symbol-transition-gallery-vertical .st5,
.control-transition-gallery-vertical .symbol-transition-gallery-vertical .st4,
.control-transition-gallery-vertical .symbol-transition-gallery-vertical .st5 {
  fill: #d3edff; }

.symbol-transition-gallery-vertical .st6,
.symbol-transition-gallery-vertical .st7,
.symbol-transition-gallery-vertical .st8,
.control-transition-gallery-vertical .symbol-transition-gallery-vertical .st6,
.control-transition-gallery-vertical .symbol-transition-gallery-vertical .st7,
.control-transition-gallery-vertical .symbol-transition-gallery-vertical .st8 {
  fill: #7FCCF8; }

.selected .symbol-transition-gallery-vertical .st2,
.control-transition-gallery-vertical .input-transition-gallery-vertical:checked ~ .symbol-transition-gallery-vertical .st2 {
  fill: #d3edff; }

.selected .symbol-transition-gallery-vertical .st3,
.control-transition-gallery-vertical .input-transition-gallery-vertical:checked ~ .symbol-transition-gallery-vertical .st3 {
  fill: #A1D9FA; }

.selected .symbol-transition-gallery-vertical .st4,
.selected .symbol-transition-gallery-vertical .st5,
.control-transition-gallery-vertical .input-transition-gallery-vertical:checked ~ .symbol-transition-gallery-vertical .st4,
.control-transition-gallery-vertical .input-transition-gallery-vertical:checked ~ .symbol-transition-gallery-vertical .st5 {
  fill: #7FCCF8; }

.selected .symbol-transition-gallery-vertical .st6,
.selected .symbol-transition-gallery-vertical .st7,
.selected .symbol-transition-gallery-vertical .st8,
.control-transition-gallery-vertical .input-transition-gallery-vertical:checked ~ .symbol-transition-gallery-vertical .st6,
.control-transition-gallery-vertical .input-transition-gallery-vertical:checked ~ .symbol-transition-gallery-vertical .st7,
.control-transition-gallery-vertical .input-transition-gallery-vertical:checked ~ .symbol-transition-gallery-vertical .st8 {
  fill: #FFFFFF; }

.symbol-transition-gallery-zoom .st2,
.control-transition-gallery-zoom .symbol-transition-gallery-zoom .st2 {
  fill: #4EB7F6; }

.symbol-transition-gallery-zoom .st3,
.control-transition-gallery-zoom .symbol-transition-gallery-zoom .st3 {
  fill: #9ED7FB; }

.symbol-transition-gallery-zoom .st4,
.symbol-transition-gallery-zoom .st5,
.control-transition-gallery-zoom .symbol-transition-gallery-zoom .st4,
.control-transition-gallery-zoom .symbol-transition-gallery-zoom .st5 {
  fill: #d3edff; }

.symbol-transition-gallery-zoom .st6,
.symbol-transition-gallery-zoom .st7,
.symbol-transition-gallery-zoom .st8,
.symbol-transition-gallery-zoom .st9,
.control-transition-gallery-zoom .symbol-transition-gallery-zoom .st6,
.control-transition-gallery-zoom .symbol-transition-gallery-zoom .st7,
.control-transition-gallery-zoom .symbol-transition-gallery-zoom .st8,
.control-transition-gallery-zoom .symbol-transition-gallery-zoom .st9 {
  fill: #7FCCF8; }

.selected .symbol-transition-gallery-zoom .st2,
.control-transition-gallery-zoom .input-transition-gallery-zoom:checked ~ .symbol-transition-gallery-zoom .st2 {
  fill: #d3edff; }

.selected .symbol-transition-gallery-zoom .st3,
.control-transition-gallery-zoom .input-transition-gallery-zoom:checked ~ .symbol-transition-gallery-zoom .st3 {
  fill: #A1D9FA; }

.selected .symbol-transition-gallery-zoom .st4,
.selected .symbol-transition-gallery-zoom .st5,
.control-transition-gallery-zoom .input-transition-gallery-zoom:checked ~ .symbol-transition-gallery-zoom .st4,
.control-transition-gallery-zoom .input-transition-gallery-zoom:checked ~ .symbol-transition-gallery-zoom .st5 {
  fill: #7FCCF8; }

.selected .symbol-transition-gallery-zoom .st6,
.selected .symbol-transition-gallery-zoom .st7,
.selected .symbol-transition-gallery-zoom .st8,
.selected .symbol-transition-gallery-zoom .st9,
.control-transition-gallery-zoom .input-transition-gallery-zoom:checked ~ .symbol-transition-gallery-zoom .st6,
.control-transition-gallery-zoom .input-transition-gallery-zoom:checked ~ .symbol-transition-gallery-zoom .st7,
.control-transition-gallery-zoom .input-transition-gallery-zoom:checked ~ .symbol-transition-gallery-zoom .st8,
.control-transition-gallery-zoom .input-transition-gallery-zoom:checked ~ .symbol-transition-gallery-zoom .st9 {
  fill: #FFFFFF; }

.symbol-transition-imagebutton-dissolve .st2,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st2 {
  fill: #ADDDFB; }

.symbol-transition-imagebutton-dissolve .st3,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st3 {
  fill: #CDEBFD; }

.symbol-transition-imagebutton-dissolve .st4,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st4 {
  fill: #E3F3FF; }

.symbol-transition-imagebutton-dissolve .st5,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st5 {
  fill: #7DCAF9; }

.symbol-transition-imagebutton-dissolve .st6,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st6 {
  fill: #6AC2F8; }

.symbol-transition-imagebutton-dissolve .st7,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st7 {
  fill: #74C6F8; }

.symbol-transition-imagebutton-dissolve .st8,
.symbol-transition-imagebutton-dissolve .st9,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st8,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st9 {
  fill: #7AC9F9; }

.symbol-transition-imagebutton-dissolve .st10,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st10 {
  fill: #B6E1FC; }

.symbol-transition-imagebutton-dissolve .st11,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st11 {
  fill: #A2D9FB; }

.symbol-transition-imagebutton-dissolve .st12,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st12 {
  fill: #B3DFFC; }

.symbol-transition-imagebutton-dissolve .st13,
.symbol-transition-imagebutton-dissolve .st17,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st13,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st17 {
  fill: #C8E8FE; }

.symbol-transition-imagebutton-dissolve .st14,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st14 {
  fill: #D1ECFE; }

.symbol-transition-imagebutton-dissolve .st15,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st15 {
  fill: #D8EFFF; }

.symbol-transition-imagebutton-dissolve .st16,
.control-transition-imagebutton-dissolve .symbol-transition-imagebutton-dissolve .st16 {
  fill: #DBF0FF; }

.selected .symbol-transition-imagebutton-dissolve .st2,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st2 {
  fill: #76BBF4; }

.selected .symbol-transition-imagebutton-dissolve .st3,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st3 {
  fill: #62B3F2; }

.selected .symbol-transition-imagebutton-dissolve .st4,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st4 {
  fill: #54ADF0; }

.selected .symbol-transition-imagebutton-dissolve .st5,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st5 {
  fill: #A5D4F9; }

.selected .symbol-transition-imagebutton-dissolve .st6,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st6 {
  fill: #B7DEFC; }

.selected .symbol-transition-imagebutton-dissolve .st7,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st7 {
  fill: #B1DCFB; }

.selected .symbol-transition-imagebutton-dissolve .st8,
.selected .symbol-transition-imagebutton-dissolve .st9,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st8,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st9 {
  fill: #ADDAFB; }

.selected .symbol-transition-imagebutton-dissolve .st10,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st10 {
  fill: #82C6F7; }

.selected .symbol-transition-imagebutton-dissolve .st11,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st11 {
  fill: #94D0F9; }

.selected .symbol-transition-imagebutton-dissolve .st12,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st12 {
  fill: #8ACCF8; }

.selected .symbol-transition-imagebutton-dissolve .st13,
.selected .symbol-transition-imagebutton-dissolve .st17,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st13,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st17 {
  fill: #7CC7F7; }

.selected .symbol-transition-imagebutton-dissolve .st14,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st14 {
  fill: #76C5F7; }

.selected .symbol-transition-imagebutton-dissolve .st15,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st15 {
  fill: #72C3F6; }

.selected .symbol-transition-imagebutton-dissolve .st16,
.control-transition-imagebutton-dissolve .input-transition-imagebutton-dissolve:checked ~ .symbol-transition-imagebutton-dissolve .st16 {
  fill: #6ABDF4; }

.symbol-transition-pages-crossfade .st2,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st2 {
  fill: #9DD7FA; }

.symbol-transition-pages-crossfade .st3,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st3 {
  fill: #B6E1FB; }

.symbol-transition-pages-crossfade .st4,
.symbol-transition-pages-crossfade .st5,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st4,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st5 {
  fill: #E0F2FF; }

.symbol-transition-pages-crossfade .st6,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st6 {
  fill: #ADDDFB; }

.symbol-transition-pages-crossfade .st7,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st7 {
  fill: #7DCAF9; }

.symbol-transition-pages-crossfade .st8,
.symbol-transition-pages-crossfade .st9,
.symbol-transition-pages-crossfade .st10,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st8,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st9,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st10 {
  fill: #A6DAFB; }

.symbol-transition-pages-crossfade .st11,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st11 {
  fill: #91D3F9; }

.symbol-transition-pages-crossfade .st12,
.symbol-transition-pages-crossfade .st14,
.symbol-transition-pages-crossfade .st15,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st12,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st14,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st15 {
  fill: #E3F3FF; }

.symbol-transition-pages-crossfade .st13,
.symbol-transition-pages-crossfade .st16,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st13,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st16 {
  fill: #B3E0FC; }

.symbol-transition-pages-crossfade .st17,
.control-transition-pages-crossfade .symbol-transition-pages-crossfade .st17 {
  fill: #DBF0FF; }

.selected .symbol-transition-pages-crossfade .st2,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st2 {
  fill: #86C3F7; }

.selected .symbol-transition-pages-crossfade .st3,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st3 {
  fill: #9CCCF7; }

.selected .symbol-transition-pages-crossfade .st4,
.selected .symbol-transition-pages-crossfade .st5,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st4,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st5 {
  fill: #5CB3F2; }

.selected .symbol-transition-pages-crossfade .st6,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st6 {
  fill: #76BBF4; }

.selected .symbol-transition-pages-crossfade .st7,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st7 {
  fill: #A5D4FA; }

.selected .symbol-transition-pages-crossfade .st8,
.selected .symbol-transition-pages-crossfade .st9,
.selected .symbol-transition-pages-crossfade .st10,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st8,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st9,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st10 {
  fill: #8CCAF8; }

.selected .symbol-transition-pages-crossfade .st11,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st11 {
  fill: #B6DBFA; }

.selected .symbol-transition-pages-crossfade .st12,
.selected .symbol-transition-pages-crossfade .st14,
.selected .symbol-transition-pages-crossfade .st15,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st12,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st14,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st15 {
  fill: #54ADF0; }

.selected .symbol-transition-pages-crossfade .st13,
.selected .symbol-transition-pages-crossfade .st16,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st13,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st16 {
  fill: #83C7F7; }

.selected .symbol-transition-pages-crossfade .st17,
.control-transition-pages-crossfade .input-transition-pages-crossfade:checked ~ .symbol-transition-pages-crossfade .st17 {
  fill: #6ABDF4; }

.symbol-transition-pages-horizontal .st2,
.symbol-transition-pages-horizontal .st3,
.symbol-transition-pages-horizontal .st4,
.symbol-transition-pages-horizontal .st6,
.control-transition-pages-horizontal .symbol-transition-pages-horizontal .st2,
.control-transition-pages-horizontal .symbol-transition-pages-horizontal .st3,
.control-transition-pages-horizontal .symbol-transition-pages-horizontal .st4,
.control-transition-pages-horizontal .symbol-transition-pages-horizontal .st6 {
  fill: #7FCCF8; }

.symbol-transition-pages-horizontal .st5,
.control-transition-pages-horizontal .symbol-transition-pages-horizontal .st5 {
  fill: #4EB7F6; }

.symbol-transition-pages-horizontal .st7,
.symbol-transition-pages-horizontal .st8,
.symbol-transition-pages-horizontal .st9,
.control-transition-pages-horizontal .symbol-transition-pages-horizontal .st7,
.control-transition-pages-horizontal .symbol-transition-pages-horizontal .st8,
.control-transition-pages-horizontal .symbol-transition-pages-horizontal .st9 {
  fill: #d3edff; }

.selected .symbol-transition-pages-horizontal .st2,
.selected .symbol-transition-pages-horizontal .st3,
.selected .symbol-transition-pages-horizontal .st4,
.selected .symbol-transition-pages-horizontal .st6,
.control-transition-pages-horizontal .input-transition-pages-horizontal:checked ~ .symbol-transition-pages-horizontal .st2,
.control-transition-pages-horizontal .input-transition-pages-horizontal:checked ~ .symbol-transition-pages-horizontal .st3,
.control-transition-pages-horizontal .input-transition-pages-horizontal:checked ~ .symbol-transition-pages-horizontal .st4,
.control-transition-pages-horizontal .input-transition-pages-horizontal:checked ~ .symbol-transition-pages-horizontal .st6 {
  fill: #FFFFFF; }

.selected .symbol-transition-pages-horizontal .st5,
.control-transition-pages-horizontal .input-transition-pages-horizontal:checked ~ .symbol-transition-pages-horizontal .st5 {
  fill: #d3edff; }

.selected .symbol-transition-pages-horizontal .st7,
.selected .symbol-transition-pages-horizontal .st8,
.selected .symbol-transition-pages-horizontal .st9,
.control-transition-pages-horizontal .input-transition-pages-horizontal:checked ~ .symbol-transition-pages-horizontal .st7,
.control-transition-pages-horizontal .input-transition-pages-horizontal:checked ~ .symbol-transition-pages-horizontal .st8,
.control-transition-pages-horizontal .input-transition-pages-horizontal:checked ~ .symbol-transition-pages-horizontal .st9 {
  fill: #7FCCF8; }

.symbol-transition-pages-inout .st2,
.control-transition-pages-inout .symbol-transition-pages-inout .st2 {
  fill: #79C8F9; }

.symbol-transition-pages-inout .st3,
.control-transition-pages-inout .symbol-transition-pages-inout .st3 {
  fill: #9DD8F9; }

.symbol-transition-pages-inout .st4,
.control-transition-pages-inout .symbol-transition-pages-inout .st4 {
  fill: #8ED1F9; }

.symbol-transition-pages-inout .st5,
.control-transition-pages-inout .symbol-transition-pages-inout .st5 {
  fill: #5FBEF7; }

.symbol-transition-pages-inout .st6,
.control-transition-pages-inout .symbol-transition-pages-inout .st6 {
  fill: #7DCAF8; }

.symbol-transition-pages-inout .st7,
.symbol-transition-pages-inout .st9,
.symbol-transition-pages-inout .st10,
.control-transition-pages-inout .symbol-transition-pages-inout .st7,
.control-transition-pages-inout .symbol-transition-pages-inout .st9,
.control-transition-pages-inout .symbol-transition-pages-inout .st10 {
  fill: #DDF1FF; }

.symbol-transition-pages-inout .st8,
.symbol-transition-pages-inout .st11,
.control-transition-pages-inout .symbol-transition-pages-inout .st8,
.control-transition-pages-inout .symbol-transition-pages-inout .st11 {
  fill: #AFDEFC; }

.selected .symbol-transition-pages-inout .st2,
.control-transition-pages-inout .input-transition-pages-inout:checked ~ .symbol-transition-pages-inout .st2 {
  fill: #A9D6FA; }

.selected .symbol-transition-pages-inout .st3,
.control-transition-pages-inout .input-transition-pages-inout:checked ~ .symbol-transition-pages-inout .st3 {
  fill: #C9E3FA; }

.selected .symbol-transition-pages-inout .st4,
.control-transition-pages-inout .input-transition-pages-inout:checked ~ .symbol-transition-pages-inout .st4 {
  fill: #95CBF8; }

.selected .symbol-transition-pages-inout .st5,
.control-transition-pages-inout .input-transition-pages-inout:checked ~ .symbol-transition-pages-inout .st5 {
  fill: #C2E4FD; }

.selected .symbol-transition-pages-inout .st6,
.control-transition-pages-inout .input-transition-pages-inout:checked ~ .symbol-transition-pages-inout .st6 {
  fill: #DDEFFD; }

.selected .symbol-transition-pages-inout .st7,
.selected .symbol-transition-pages-inout .st9,
.selected .symbol-transition-pages-inout .st10,
.control-transition-pages-inout .input-transition-pages-inout:checked ~ .symbol-transition-pages-inout .st7,
.control-transition-pages-inout .input-transition-pages-inout:checked ~ .symbol-transition-pages-inout .st9,
.control-transition-pages-inout .input-transition-pages-inout:checked ~ .symbol-transition-pages-inout .st10 {
  fill: #63B8F3; }

.selected .symbol-transition-pages-inout .st8,
.selected .symbol-transition-pages-inout .st11,
.control-transition-pages-inout .input-transition-pages-inout:checked ~ .symbol-transition-pages-inout .st8,
.control-transition-pages-inout .input-transition-pages-inout:checked ~ .symbol-transition-pages-inout .st11 {
  fill: #90D0F9; }

.symbol-transition-pages-vertical .st2,
.control-transition-pages-vertical .symbol-transition-pages-vertical .st2 {
  fill: #4eb7f6; }

.symbol-transition-pages-vertical .st3,
.symbol-transition-pages-vertical .st7,
.symbol-transition-pages-vertical .st8,
.symbol-transition-pages-vertical .st9,
.control-transition-pages-vertical .symbol-transition-pages-vertical .st3,
.control-transition-pages-vertical .symbol-transition-pages-vertical .st7,
.control-transition-pages-vertical .symbol-transition-pages-vertical .st8,
.control-transition-pages-vertical .symbol-transition-pages-vertical .st9 {
  fill: #7fccf8; }

.symbol-transition-pages-vertical .st4,
.symbol-transition-pages-vertical .st5,
.symbol-transition-pages-vertical .st6,
.control-transition-pages-vertical .symbol-transition-pages-vertical .st4,
.control-transition-pages-vertical .symbol-transition-pages-vertical .st5,
.control-transition-pages-vertical .symbol-transition-pages-vertical .st6 {
  fill: #d3edff; }

.selected .symbol-transition-pages-vertical .st2,
.control-transition-pages-vertical .input-transition-pages-vertical:checked ~ .symbol-transition-pages-vertical .st2 {
  fill: #d3edff; }

.selected .symbol-transition-pages-vertical .st3,
.selected .symbol-transition-pages-vertical .st7,
.selected .symbol-transition-pages-vertical .st8,
.selected .symbol-transition-pages-vertical .st9,
.control-transition-pages-vertical .input-transition-pages-vertical:checked ~ .symbol-transition-pages-vertical .st3,
.control-transition-pages-vertical .input-transition-pages-vertical:checked ~ .symbol-transition-pages-vertical .st7,
.control-transition-pages-vertical .input-transition-pages-vertical:checked ~ .symbol-transition-pages-vertical .st8,
.control-transition-pages-vertical .input-transition-pages-vertical:checked ~ .symbol-transition-pages-vertical .st9 {
  fill: #ffffff; }

.selected .symbol-transition-pages-vertical .st4,
.selected .symbol-transition-pages-vertical .st5,
.selected .symbol-transition-pages-vertical .st6,
.control-transition-pages-vertical .input-transition-pages-vertical:checked ~ .symbol-transition-pages-vertical .st4,
.control-transition-pages-vertical .input-transition-pages-vertical:checked ~ .symbol-transition-pages-vertical .st5,
.control-transition-pages-vertical .input-transition-pages-vertical:checked ~ .symbol-transition-pages-vertical .st6 {
  fill: #7fccf8; }

.symbol-transition-none .st2,
.control-transition-none .symbol-transition-none .st2 {
  stroke: #ee5951;
  stroke-width: 2; }

.symbol-transition-none .st3,
.control-transition-none .symbol-transition-none .st3 {
  fill: transparent; }

.selected .symbol-transition-none,
.control-transition-none .input-transition-none:checked ~ .symbol-transition-none {
  background-color: #eaf7ff; }
  .selected .symbol-transition-none .st2,
  .control-transition-none .input-transition-none:checked ~ .symbol-transition-none .st2 {
    stroke: #ee5951;
    stroke-width: 2; }
  .selected .symbol-transition-none .st3,
  .control-transition-none .input-transition-none:checked ~ .symbol-transition-none .st3 {
    fill: #3899ec; }

.symbol-settings-circle .st0,
.control-settings-circle .symbol-settings-circle .st0 {
  fill: #162d3d;
  fill-opacity: 0.5;
  stroke: #fff;
  stroke-width: 1; }

.symbol-settings-circle .st1,
.control-settings-circle .symbol-settings-circle .st1 {
  fill: #fff; }

.symbol-settings-circle .st2,
.control-settings-circle .symbol-settings-circle .st2 {
  fill: transparent; }

.control-settings-circle:hover .symbol-settings-circle .st0 {
  fill: #eaf7ff; }

.control-settings-circle:active .symbol-settings-circle .st0 {
  fill: #fff; }

.control-settings-circle .input-settings-circle:disabled ~ .symbol-settings-circle {
  pointer-events: none; }
  .control-settings-circle .input-settings-circle:disabled ~ .symbol-settings-circle .st0 {
    fill: #bcbcbc;
    opacity: 0.4; }
  .control-settings-circle .input-settings-circle:disabled ~ .symbol-settings-circle .st1 {
    fill: #fff;
    opacity: 0.4; }
  .control-settings-circle .input-settings-circle:disabled ~ .symbol-settings-circle .st2 {
    fill: transparent; }

.selected .symbol-settings-circle .st0,
.control-settings-circle .input-settings-circle:checked ~ .symbol-settings-circle .st0 {
  fill: #3899ec; }

.selected .symbol-settings-circle .st1,
.control-settings-circle .input-settings-circle:checked ~ .symbol-settings-circle .st1 {
  fill: transparent; }

.selected .symbol-settings-circle .st2,
.control-settings-circle .input-settings-circle:checked ~ .symbol-settings-circle .st2 {
  fill: #fff; }

.control-settings-circle:hover .input-settings-circle:not(:disabled):checked ~ .symbol-settings-circle .st0 {
  fill: #4eb7f5; }

.control-settings-circle:active .input-settings-circle:checked ~ .symbol-settings-circle .st0 {
  fill: #3899ec; }

.control-settings-circle .input-settings-circle:checked:disabled ~ .symbol-settings-circle {
  pointer-events: none; }
  .control-settings-circle .input-settings-circle:checked:disabled ~ .symbol-settings-circle .st0 {
    fill: #bcbcbc;
    opacity: 0.4; }
  .control-settings-circle .input-settings-circle:checked:disabled ~ .symbol-settings-circle .st1 {
    fill: transparent; }
  .control-settings-circle .input-settings-circle:checked:disabled ~ .symbol-settings-circle .st2 {
    fill: #fff;
    opacity: 0.4; }

.symbol-settings .st0,
.control-settings .symbol-settings .st0 {
  fill: #fff; }

.control-settings:hover .symbol-settings .st0 {
  fill: #3899ec; }

.control-settings:active .symbol-settings .st0 {
  fill: #fff; }

.control-settings .input-settings:disabled ~ .symbol-settings {
  pointer-events: none; }
  .control-settings .input-settings:disabled ~ .symbol-settings .st0 {
    fill: #bcbcbc; }

.selected .symbol-settings .st0,
.control-settings .input-settings:checked ~ .symbol-settings .st0 {
  fill: #fff; }

.control-settings:hover .input-settings:not(:disabled):checked ~ .symbol-settings .st0 {
  fill: #3899ec; }

.control-settings:active .input-settings:checked ~ .symbol-settings .st0 {
  fill: #fff; }

.control-settings .input-settings:checked:disabled ~ .symbol-settings {
  pointer-events: none; }
  .control-settings .input-settings:checked:disabled ~ .symbol-settings .st0 {
    fill: #bcbcbc; }

.symbol-play-video .st0,
.control-play-video .symbol-play-video .st0 {
  fill: #fff; }

.symbol-play-video .st1,
.control-play-video .symbol-play-video .st1 {
  fill: transparent; }

.control-play-video:hover .symbol-play-video .st0 {
  fill: #3899ec; }

.control-play-video:active .symbol-play-video .st0 {
  fill: #fff; }

.control-play-video .input-play-video:disabled ~ .symbol-play-video {
  pointer-events: none; }
  .control-play-video .input-play-video:disabled ~ .symbol-play-video .st0 {
    fill: #bcbcbc; }

.selected .symbol-play-video .st0,
.control-play-video .input-play-video:checked ~ .symbol-play-video .st0 {
  fill: transparent; }

.selected .symbol-play-video .st1,
.control-play-video .input-play-video:checked ~ .symbol-play-video .st1 {
  fill: #fff; }

.control-play-video:hover .input-play-video:not(:disabled):checked ~ .symbol-play-video .st1 {
  fill: #3899ec; }

.control-play-video:active .input-play-video:checked ~ .symbol-play-video .st1 {
  fill: #fff; }

.control-play-video .input-play-video:checked:disabled ~ .symbol-play-video {
  pointer-events: none; }
  .control-play-video .input-play-video:checked:disabled ~ .symbol-play-video .st1 {
    fill: #bcbcbc; }

.symbol-play .st0,
.control-play .symbol-play .st0 {
  fill: transparent;
  stroke: #3899ec;
  stroke-width: 1.15;
  opacity: 0.5; }

.symbol-play .st1,
.control-play .symbol-play .st1 {
  fill: #3899ec; }

.symbol-play .st2,
.control-play .symbol-play .st2 {
  fill: transparent; }

.symbol-play .st3,
.control-play .symbol-play .st3 {
  fill: transparent;
  stroke: #3899ec;
  stroke-width: 1.15; }

.control-play:hover .symbol-play .st0 {
  fill: #eaf7ff; }

.selected .symbol-play .st0,
.control-play .input-play:checked ~ .symbol-play .st0 {
  fill: transparent;
  stroke: #3899ec;
  stroke-width: 1.15;
  opacity: 0.5; }

.selected .symbol-play .st1,
.control-play .input-play:checked ~ .symbol-play .st1 {
  fill: transparent; }

.selected .symbol-play .st2,
.control-play .input-play:checked ~ .symbol-play .st2 {
  fill: #3899ec; }

.selected .symbol-play .st3,
.control-play .input-play:checked ~ .symbol-play .st3 {
  fill: transparent;
  stroke: #3899ec;
  stroke-width: 1.15; }

.control-play:hover .input-play:not(:disabled):checked ~ .symbol-play .st0 {
  fill: #eaf7ff; }

.symbol-thumb-none .st1,
.control-thumb-none .symbol-thumb-none .st1 {
  stroke: #ee5951;
  stroke-width: 2; }

.symbol-thumb-none .st2,
.control-thumb-none .symbol-thumb-none .st2 {
  fill: transparent; }

.selected .symbol-thumb-none .st1,
.control-thumb-none .input-thumb-none:checked ~ .symbol-thumb-none .st1 {
  stroke: #ee5951;
  stroke-width: 2; }

.selected .symbol-thumb-none .st2,
.control-thumb-none .input-thumb-none:checked ~ .symbol-thumb-none .st2 {
  fill: #3899ec; }

.symbol-video-layout-dark .st1,
.control-video-layout-dark .symbol-video-layout-dark .st1 {
  fill: #7fccf7; }

.symbol-video-layout-dark .st2,
.control-video-layout-dark .symbol-video-layout-dark .st2 {
  fill: #3899ec; }

.symbol-video-layout-dark .st3,
.control-video-layout-dark .symbol-video-layout-dark .st3 {
  fill: #eaf7ff; }

.symbol-video-layout-light .st1,
.control-video-layout-light .symbol-video-layout-light .st1 {
  fill: #7fccf7; }

.symbol-video-layout-light .st2,
.control-video-layout-light .symbol-video-layout-light .st2 {
  fill: #d3edff; }

.symbol-video-layout-light .st3,
.control-video-layout-light .symbol-video-layout-light .st3 {
  fill: #eaf7ff; }

.symbol-video-layout-show .st1,
.control-video-layout-show .symbol-video-layout-show .st1 {
  fill: #7fccf7; }

.symbol-video-layout-show .st2,
.control-video-layout-show .symbol-video-layout-show .st2 {
  fill: #eaf7ff; }

.symbol-video-layout-hide .st1,
.control-video-layout-hide .symbol-video-layout-hide .st1 {
  fill: #7fccf7; }

.symbol-video-layout-hide .st2,
.control-video-layout-hide .symbol-video-layout-hide .st2 {
  fill: #eaf7ff; }

.symbol-aspect-ratio-1-1 .st1,
.control-aspect-ratio-1-1 .symbol-aspect-ratio-1-1 .st1 {
  fill: #7fccf7;
  transition: fill 0.2s; }

.selected .symbol-aspect-ratio-1-1 .st1,
.control-aspect-ratio-1-1 .input-aspect-ratio-1-1:checked ~ .symbol-aspect-ratio-1-1 .st1 {
  fill: #eaf7ff; }

.symbol-aspect-ratio-3-4,
.control-aspect-ratio-3-4 .symbol-aspect-ratio-3-4 {
  transform: rotate(90deg); }
  .symbol-aspect-ratio-3-4 .st1,
  .control-aspect-ratio-3-4 .symbol-aspect-ratio-3-4 .st1 {
    fill: #7fccf7;
    transition: fill 0.2s; }

.selected .symbol-aspect-ratio-3-4 .st1,
.control-aspect-ratio-3-4 .input-aspect-ratio-3-4:checked ~ .symbol-aspect-ratio-3-4 .st1 {
  fill: #eaf7ff; }

.symbol-aspect-ratio-4-3 .st1,
.control-aspect-ratio-4-3 .symbol-aspect-ratio-4-3 .st1 {
  fill: #7fccf7;
  transition: fill 0.2s; }

.selected .symbol-aspect-ratio-4-3 .st1,
.control-aspect-ratio-4-3 .input-aspect-ratio-4-3:checked ~ .symbol-aspect-ratio-4-3 .st1 {
  fill: #eaf7ff; }

.symbol-aspect-ratio-9-16,
.control-aspect-ratio-9-16 .symbol-aspect-ratio-9-16 {
  transform: rotate(90deg); }
  .symbol-aspect-ratio-9-16 .st1,
  .control-aspect-ratio-9-16 .symbol-aspect-ratio-9-16 .st1 {
    fill: #7fccf7;
    transition: fill 0.2s; }

.selected .symbol-aspect-ratio-9-16 .st1,
.control-aspect-ratio-9-16 .input-aspect-ratio-9-16:checked ~ .symbol-aspect-ratio-9-16 .st1 {
  fill: #eaf7ff; }

.symbol-aspect-ratio-16-9 .st1,
.control-aspect-ratio-16-9 .symbol-aspect-ratio-16-9 .st1 {
  fill: #7fccf7;
  transition: fill 0.2s; }

.selected .symbol-aspect-ratio-16-9 .st1,
.control-aspect-ratio-16-9 .input-aspect-ratio-16-9:checked ~ .symbol-aspect-ratio-16-9 .st1 {
  fill: #eaf7ff; }

.symbol-size-small .st1,
.control-size-small .symbol-size-small .st1 {
  fill: #7fccf7;
  transition: fill 0.2s; }

.selected .symbol-size-small .st1,
.control-size-small .input-size-small:checked ~ .symbol-size-small .st1 {
  fill: #eaf7ff; }

.symbol-size-medium .st1,
.control-size-medium .symbol-size-medium .st1 {
  fill: #7fccf7;
  transition: fill 0.2s; }

.selected .symbol-size-medium .st1,
.control-size-medium .input-size-medium:checked ~ .symbol-size-medium .st1 {
  fill: #eaf7ff; }

.symbol-size-standard .st1,
.control-size-standard .symbol-size-standard .st1 {
  fill: #7fccf7;
  transition: fill 0.2s; }

.selected .symbol-size-standard .st1,
.control-size-standard .input-size-standard:checked ~ .symbol-size-standard .st1 {
  fill: #eaf7ff; }

.symbol-size-wide .st1,
.control-size-wide .symbol-size-wide .st1 {
  fill: #7fccf7;
  transition: fill 0.2s; }

.selected .symbol-size-wide .st1,
.control-size-wide .input-size-wide:checked ~ .symbol-size-wide .st1 {
  fill: #eaf7ff; }

.symbol-position-bottom .st1,
.control-position-bottom .symbol-position-bottom .st1 {
  fill: #7fccf7; }

.selected .symbol-position-bottom .st1,
.control-position-bottom .input-position-bottom:checked ~ .symbol-position-bottom .st1 {
  fill: #eaf7ff; }

.symbol-position-left .st1,
.control-position-left .symbol-position-left .st1 {
  fill: #7fccf7; }

.selected .symbol-position-left .st1,
.control-position-left .input-position-left:checked ~ .symbol-position-left .st1 {
  fill: #eaf7ff; }

.symbol-position-right .st1,
.control-position-right .symbol-position-right .st1 {
  fill: #7fccf7; }

.selected .symbol-position-right .st1,
.control-position-right .input-position-right:checked ~ .symbol-position-right .st1 {
  fill: #eaf7ff; }

.symbol-position-top .st1,
.control-position-top .symbol-position-top .st1 {
  fill: #7fccf7; }

.selected .symbol-position-top .st1,
.control-position-top .input-position-top:checked ~ .symbol-position-top .st1 {
  fill: #eaf7ff; }

.symbol-shape-hexagon .st1,
.control-shape-hexagon .symbol-shape-hexagon .st1 {
  fill: #3899ec; }

.selected .symbol-shape-hexagon .st1,
.control-shape-hexagon .input-shape-hexagon:checked ~ .symbol-shape-hexagon .st1 {
  fill: #eaf7ff; }

.symbol-shape-rectangle .st1,
.control-shape-rectangle .symbol-shape-rectangle .st1 {
  fill: #3899ec; }

.selected .symbol-shape-rectangle .st1,
.control-shape-rectangle .input-shape-rectangle:checked ~ .symbol-shape-rectangle .st1 {
  fill: #eaf7ff; }

.symbol-shape-rhombus .st1,
.control-shape-rhombus .symbol-shape-rhombus .st1 {
  fill: #3899ec; }

.selected .symbol-shape-rhombus .st1,
.control-shape-rhombus .input-shape-rhombus:checked ~ .symbol-shape-rhombus .st1 {
  fill: #eaf7ff; }

.symbol-shape-triangle .st1,
.control-shape-triangle .symbol-shape-triangle .st1 {
  fill: #3899ec; }

.selected .symbol-shape-triangle .st1,
.control-shape-triangle .input-shape-triangle:checked ~ .symbol-shape-triangle .st1 {
  fill: #eaf7ff; }

.symbol-imageScaleCrop .st1,
.control-imageScaleCrop .symbol-imageScaleCrop .st1 {
  fill: #4EB7F6; }

.symbol-imageScaleCrop .st2,
.control-imageScaleCrop .symbol-imageScaleCrop .st2 {
  fill: #D8F0FD; }

.symbol-imageScaleCrop .st3,
.control-imageScaleCrop .symbol-imageScaleCrop .st3 {
  fill: #d3edff; }

.symbol-imageScaleCrop .st4,
.control-imageScaleCrop .symbol-imageScaleCrop .st4 {
  fill: #A4DAFB; }

.selected .symbol-imageScaleCrop .st1,
.control-imageScaleCrop .input-imageScaleCrop:checked ~ .symbol-imageScaleCrop .st1 {
  fill: #d3edff; }

.selected .symbol-imageScaleCrop .st2,
.control-imageScaleCrop .input-imageScaleCrop:checked ~ .symbol-imageScaleCrop .st2 {
  fill-opacity: 0.3; }

.selected .symbol-imageScaleCrop .st3,
.control-imageScaleCrop .input-imageScaleCrop:checked ~ .symbol-imageScaleCrop .st3 {
  fill: #7FCCF8; }

.selected .symbol-imageScaleCrop .st4,
.control-imageScaleCrop .input-imageScaleCrop:checked ~ .symbol-imageScaleCrop .st4 {
  fill: #A1D9FA; }

.symbol-imageScaleFit .st1,
.control-imageScaleFit .symbol-imageScaleFit .st1 {
  fill: #4EB7F6; }

.symbol-imageScaleFit .st2,
.control-imageScaleFit .symbol-imageScaleFit .st2 {
  fill: #9ED7FB; }

.symbol-imageScaleFit .st3,
.control-imageScaleFit .symbol-imageScaleFit .st3 {
  fill: #d3edff; }

.selected .symbol-imageScaleFit .st1,
.control-imageScaleFit .input-imageScaleFit:checked ~ .symbol-imageScaleFit .st1 {
  fill: #d3edff; }

.selected .symbol-imageScaleFit .st2,
.control-imageScaleFit .input-imageScaleFit:checked ~ .symbol-imageScaleFit .st2 {
  fill: #A1D9FA; }

.selected .symbol-imageScaleFit .st3,
.control-imageScaleFit .input-imageScaleFit:checked ~ .symbol-imageScaleFit .st3 {
  fill: #7FCCF8; }

.symbol-imageScaleStretch .st1,
.control-imageScaleStretch .symbol-imageScaleStretch .st1 {
  fill: #4EB7F6; }

.symbol-imageScaleStretch .st2,
.control-imageScaleStretch .symbol-imageScaleStretch .st2 {
  fill: #9ED7FB; }

.symbol-imageScaleStretch .st3,
.control-imageScaleStretch .symbol-imageScaleStretch .st3 {
  fill: #d3edff; }

.selected .symbol-imageScaleStretch .st1,
.control-imageScaleStretch .input-imageScaleStretch:checked ~ .symbol-imageScaleStretch .st1 {
  fill: #d3edff; }

.selected .symbol-imageScaleStretch .st2,
.control-imageScaleStretch .input-imageScaleStretch:checked ~ .symbol-imageScaleStretch .st2 {
  fill: #A1D9FA; }

.selected .symbol-imageScaleStretch .st3,
.control-imageScaleStretch .input-imageScaleStretch:checked ~ .symbol-imageScaleStretch .st3 {
  fill: #7FCCF8; }

.symbol-imageScaleCenter .st1,
.control-imageScaleCenter .symbol-imageScaleCenter .st1 {
  fill: #4EB7F6; }

.symbol-imageScaleCenter .st2,
.control-imageScaleCenter .symbol-imageScaleCenter .st2 {
  fill: #9ED7FB; }

.symbol-imageScaleCenter .st3,
.control-imageScaleCenter .symbol-imageScaleCenter .st3 {
  fill: #d3edff; }

.selected .symbol-imageScaleCenter .st1,
.control-imageScaleCenter .input-imageScaleCenter:checked ~ .symbol-imageScaleCenter .st1 {
  fill: #d3edff; }

.selected .symbol-imageScaleCenter .st2,
.control-imageScaleCenter .input-imageScaleCenter:checked ~ .symbol-imageScaleCenter .st2 {
  fill: #A1D9FA; }

.selected .symbol-imageScaleCenter .st3,
.control-imageScaleCenter .input-imageScaleCenter:checked ~ .symbol-imageScaleCenter .st3 {
  fill: #7FCCF8; }

.symbol-alignLeft,
.control-alignLeft .symbol-alignLeft {
  shape-rendering: crispEdges; }
  .symbol-alignLeft path,
  .control-alignLeft .symbol-alignLeft path {
    transition: fill 0.2s; }
  .symbol-alignLeft .cls-b1,
  .control-alignLeft .symbol-alignLeft .cls-b1 {
    fill: #3899ec; }
  .symbol-alignLeft .cls-b3,
  .control-alignLeft .symbol-alignLeft .cls-b3 {
    fill: #7fccf7; }

.selected .symbol-alignLeft .cls-b1,
.control-alignLeft .input-alignLeft:checked ~ .symbol-alignLeft .cls-b1 {
  fill: #7fccf7; }

.selected .symbol-alignLeft .cls-b3,
.control-alignLeft .input-alignLeft:checked ~ .symbol-alignLeft .cls-b3 {
  fill: #eaf7ff; }

.symbol-alignCenter,
.control-alignCenter .symbol-alignCenter {
  shape-rendering: crispEdges; }
  .symbol-alignCenter path,
  .control-alignCenter .symbol-alignCenter path {
    transition: fill 0.2s; }
  .symbol-alignCenter .cls-b1,
  .control-alignCenter .symbol-alignCenter .cls-b1 {
    fill: #3899ec; }
  .symbol-alignCenter .cls-b3,
  .control-alignCenter .symbol-alignCenter .cls-b3 {
    fill: #7fccf7; }

.selected .symbol-alignCenter .cls-b1,
.control-alignCenter .input-alignCenter:checked ~ .symbol-alignCenter .cls-b1 {
  fill: #7fccf7; }

.selected .symbol-alignCenter .cls-b3,
.control-alignCenter .input-alignCenter:checked ~ .symbol-alignCenter .cls-b3 {
  fill: #eaf7ff; }

.symbol-alignRight,
.control-alignRight .symbol-alignRight {
  shape-rendering: crispEdges; }
  .symbol-alignRight path,
  .control-alignRight .symbol-alignRight path {
    transition: fill 0.2s; }
  .symbol-alignRight .cls-b1,
  .control-alignRight .symbol-alignRight .cls-b1 {
    fill: #3899ec; }
  .symbol-alignRight .cls-b3,
  .control-alignRight .symbol-alignRight .cls-b3 {
    fill: #7fccf7; }

.selected .symbol-alignRight .cls-b1,
.control-alignRight .input-alignRight:checked ~ .symbol-alignRight .cls-b1 {
  fill: #7fccf7; }

.selected .symbol-alignRight .cls-b3,
.control-alignRight .input-alignRight:checked ~ .symbol-alignRight .cls-b3 {
  fill: #eaf7ff; }

.symbol-alignTop,
.control-alignTop .symbol-alignTop {
  shape-rendering: crispEdges; }
  .symbol-alignTop path,
  .control-alignTop .symbol-alignTop path {
    transition: fill 0.2s; }
  .symbol-alignTop .cls-b1,
  .control-alignTop .symbol-alignTop .cls-b1 {
    fill: #3899ec; }
  .symbol-alignTop .cls-b3,
  .control-alignTop .symbol-alignTop .cls-b3 {
    fill: #7fccf7; }

.selected .symbol-alignTop .cls-b1,
.control-alignTop .input-alignTop:checked ~ .symbol-alignTop .cls-b1 {
  fill: #7fccf7; }

.selected .symbol-alignTop .cls-b3,
.control-alignTop .input-alignTop:checked ~ .symbol-alignTop .cls-b3 {
  fill: #eaf7ff; }

.symbol-alignMiddle,
.control-alignMiddle .symbol-alignMiddle {
  shape-rendering: crispEdges; }
  .symbol-alignMiddle path,
  .control-alignMiddle .symbol-alignMiddle path {
    transition: fill 0.2s; }
  .symbol-alignMiddle .cls-b1,
  .control-alignMiddle .symbol-alignMiddle .cls-b1 {
    fill: #3899ec; }
  .symbol-alignMiddle .cls-b3,
  .control-alignMiddle .symbol-alignMiddle .cls-b3 {
    fill: #7fccf7; }

.selected .symbol-alignMiddle .cls-b1,
.control-alignMiddle .input-alignMiddle:checked ~ .symbol-alignMiddle .cls-b1 {
  fill: #7fccf7; }

.selected .symbol-alignMiddle .cls-b3,
.control-alignMiddle .input-alignMiddle:checked ~ .symbol-alignMiddle .cls-b3 {
  fill: #eaf7ff; }

.symbol-alignBottom,
.control-alignBottom .symbol-alignBottom {
  shape-rendering: crispEdges; }
  .symbol-alignBottom path,
  .control-alignBottom .symbol-alignBottom path {
    transition: fill 0.2s; }
  .symbol-alignBottom .cls-b1,
  .control-alignBottom .symbol-alignBottom .cls-b1 {
    fill: #3899ec; }
  .symbol-alignBottom .cls-b3,
  .control-alignBottom .symbol-alignBottom .cls-b3 {
    fill: #7fccf7; }

.selected .symbol-alignBottom .cls-b1,
.control-alignBottom .input-alignBottom:checked ~ .symbol-alignBottom .cls-b1 {
  fill: #7fccf7; }

.selected .symbol-alignBottom .cls-b3,
.control-alignBottom .input-alignBottom:checked ~ .symbol-alignBottom .cls-b3 {
  fill: #eaf7ff; }

.symbol-align-center .st1,
.control-align-center .symbol-align-center .st1 {
  fill: #3899ec; }

.symbol-align-center .st2,
.control-align-center .symbol-align-center .st2 {
  fill: #7fccf7; }

.selected .symbol-align-center .st1,
.control-align-center .input-align-center:checked ~ .symbol-align-center .st1 {
  fill: #7fccf7; }

.selected .symbol-align-center .st2,
.control-align-center .input-align-center:checked ~ .symbol-align-center .st2 {
  fill: #d3edff; }

.symbol-align-right .st1,
.control-align-right .symbol-align-right .st1 {
  fill: #3899ec; }

.symbol-align-right .st2,
.control-align-right .symbol-align-right .st2 {
  fill: #7fccf7; }

.selected .symbol-align-right .st1,
.control-align-right .input-align-right:checked ~ .symbol-align-right .st1 {
  fill: #7fccf7; }

.selected .symbol-align-right .st2,
.control-align-right .input-align-right:checked ~ .symbol-align-right .st2 {
  fill: #d3edff; }

.symbol-align-left .st1,
.control-align-left .symbol-align-left .st1 {
  fill: #3899ec; }

.symbol-align-left .st2,
.control-align-left .symbol-align-left .st2 {
  fill: #7fccf7; }

.selected .symbol-align-left .st1,
.control-align-left .input-align-left:checked ~ .symbol-align-left .st1 {
  fill: #7fccf7; }

.selected .symbol-align-left .st2,
.control-align-left .input-align-left:checked ~ .symbol-align-left .st2 {
  fill: #d3edff; }

.symbol-align-row-left .st1,
.control-align-row-left .symbol-align-row-left .st1 {
  fill: #4eb7f5; }

.symbol-align-row-left .st2,
.control-align-row-left .symbol-align-row-left .st2 {
  fill: #4eb7f5; }

.symbol-align-row-left .st3,
.control-align-row-left .symbol-align-row-left .st3 {
  fill: #b1ddf8; }

.selected .symbol-align-row-left .st1,
.control-align-row-left .input-align-row-left:checked ~ .symbol-align-row-left .st1 {
  fill: #d3edff; }

.selected .symbol-align-row-left .st2,
.control-align-row-left .input-align-row-left:checked ~ .symbol-align-row-left .st2 {
  fill: #d3edff; }

.selected .symbol-align-row-left .st3,
.control-align-row-left .input-align-row-left:checked ~ .symbol-align-row-left .st3 {
  fill: #7fccf7; }

.symbol-align-row-right .st1,
.control-align-row-right .symbol-align-row-right .st1 {
  fill: #4eb7f5; }

.symbol-align-row-right .st2,
.control-align-row-right .symbol-align-row-right .st2 {
  fill: #4eb7f5; }

.symbol-align-row-right .st3,
.control-align-row-right .symbol-align-row-right .st3 {
  fill: #b1ddf8; }

.selected .symbol-align-row-right .st1,
.control-align-row-right .input-align-row-right:checked ~ .symbol-align-row-right .st1 {
  fill: #d3edff; }

.selected .symbol-align-row-right .st2,
.control-align-row-right .input-align-row-right:checked ~ .symbol-align-row-right .st2 {
  fill: #d3edff; }

.selected .symbol-align-row-right .st3,
.control-align-row-right .input-align-row-right:checked ~ .symbol-align-row-right .st3 {
  fill: #7fccf7; }

.symbol-save2 .st1,
.control-save2 .symbol-save2 .st1 {
  fill: #2d4150; }

.symbol-save2 .st2,
.control-save2 .symbol-save2 .st2 {
  fill: transparent; }

.control-save2:hover .symbol-save2 .st1 {
  fill: #4eb7f5; }

.control-save2:hover .symbol-save2 .st2 {
  fill: transparent; }

.control-save2:active .symbol-save2 .st1 {
  fill: #2d4150; }

.control-save2:active .symbol-save2 .st2 {
  fill: transparent; }

.control-save2 .input-save2:disabled ~ .symbol-save2 {
  pointer-events: none; }
  .control-save2 .input-save2:disabled ~ .symbol-save2 .st1 {
    fill: #8a8a8a; }
  .control-save2 .input-save2:disabled ~ .symbol-save2 .st2 {
    fill: transparent; }

.control-save2 .input-save2[data-indeterimnate="true"]:disabled ~ .symbol-save2 {
  pointer-events: none; }
  .control-save2 .input-save2[data-indeterimnate="true"]:disabled ~ .symbol-save2 .st1 {
    fill: transparent; }
  .control-save2 .input-save2[data-indeterimnate="true"]:disabled ~ .symbol-save2 .st2 {
    fill: #3899ec; }

.symbol-save-bld .st1,
.control-save-bld .symbol-save-bld .st1 {
  fill: #2d4150; }

.symbol-save-bld .st2,
.control-save-bld .symbol-save-bld .st2 {
  fill: transparent; }

.control-save-bld:hover .symbol-save-bld .st1 {
  fill: #4eb7f5; }

.control-save-bld:hover .symbol-save-bld .st2 {
  fill: transparent; }

.control-save-bld:active .symbol-save-bld .st1 {
  fill: #2d4150; }

.control-save-bld:active .symbol-save-bld .st2 {
  fill: transparent; }

.control-save-bld .input-save-bld:disabled ~ .symbol-save-bld {
  pointer-events: none; }
  .control-save-bld .input-save-bld:disabled ~ .symbol-save-bld .st1 {
    fill: #8a8a8a; }
  .control-save-bld .input-save-bld:disabled ~ .symbol-save-bld .st2 {
    fill: transparent; }

.control-save-bld .input-save-bld[data-indeterimnate="true"]:disabled ~ .symbol-save-bld {
  pointer-events: none; }
  .control-save-bld .input-save-bld[data-indeterimnate="true"]:disabled ~ .symbol-save-bld .st1 {
    fill: transparent; }
  .control-save-bld .input-save-bld[data-indeterimnate="true"]:disabled ~ .symbol-save-bld .st2 {
    fill: #3899ec; }

.control-button2.delete-circle .st0 {
  fill: #fff; }

.control-button2.delete-circle .st1 {
  fill: #3899ec; }

.control-button2.delete-circle .st2 {
  fill: transparent; }

.control-button2.delete-circle:hover .st0 {
  fill: #eaf7ff; }

.control-button2.delete-circle:active .st0 {
  fill: #fff; }

.control-button2.delete-circle[disabled] .st0 {
  fill: #bcbcbc;
  opacity: 0.4; }

.control-button2.delete-circle[disabled] .st1 {
  fill: #fff;
  opacity: 0.4; }

.control-button2.delete-circle[disabled] .st2 {
  fill: transparent; }

.control-button2.image-btn .st0 {
  fill: #3899ec; }

.control-button2.image-btn:hover .st0 {
  fill: #3899ec; }

.control-button2.image-btn:active .st0 {
  fill: #3899ec; }

.control-button2.image-btn[disabled] .st0 {
  fill: #bcbcbc; }

.control-button2.video-btn .st0 {
  fill: #3899ec; }

.control-button2.video-btn:hover .st0 {
  fill: #3899ec; }

.control-button2.video-btn:active .st0 {
  fill: #3899ec; }

.control-button2.video-btn[disabled] .st0 {
  fill: #bcbcbc; }

.control-button2.slideShow-btn .st0 {
  fill: #3899ec; }

.control-button2.slideShow-btn .st1 {
  fill: #fff; }

.control-button2.slideShow-btn:hover .st0 {
  fill: #4eb7f5; }

.control-button2.slideShow-btn:active .st0 {
  fill: #3899ec; }

.control-button2.slideShow-btn[disabled] .st0 {
  fill: #bcbcbc; }

.control-button2.color-btn .st0 {
  fill: #3899ec; }

.control-button2.color-btn:hover .st0 {
  fill: #3899ec; }

.control-button2.color-btn:active .st0 {
  fill: #3899ec; }

.control-button2.color-btn[disabled] .st0 {
  fill: #bcbcbc; }

.control-button2.device-btn .st1 {
  fill: #2d4150; }

.control-button2.device-btn:hover .st1 {
  fill: #4eb7f5; }

.control-button2.device-btn:active .st1 {
  fill: #2d4150; }

.control-button2.device-btn[disabled] .st1 {
  fill: #8a8a8a; }

.control-button2.device-btn-bld .st1 {
  fill: #2d4150; }

.control-button2.device-btn-bld:hover .st1 {
  fill: #4eb7f5; }

.control-button2.device-btn-bld:active .st1 {
  fill: #2d4150; }

.control-button2.device-btn-bld[disabled] .st1 {
  fill: #8a8a8a; }

.control-button2.help-btn .st1 {
  fill: #2d4150; }

.control-button2.help-btn:hover .st1 {
  fill: #4eb7f5; }

.control-button2.help-btn:active .st1 {
  fill: #2d4150; }

.control-button2.help-btn[disabled] .st1 {
  fill: #8a8a8a; }

.control-button2.help-btn-bld .st1 {
  fill: #2d4150; }

.control-button2.help-btn-bld:hover .st1 {
  fill: #4eb7f5; }

.control-button2.help-btn-bld:active .st1 {
  fill: #2d4150; }

.control-button2.help-btn-bld[disabled] .st1 {
  fill: #8a8a8a; }

.control-button2.pages-btn .st1 {
  fill: #2d4150; }

.control-button2.pages-btn:hover .st1 {
  fill: #4eb7f5; }

.control-button2.pages-btn:active .st1 {
  fill: #2d4150; }

.control-button2.pages-btn[disabled] .st1 {
  fill: #8a8a8a; }

.control-button2.pages-btn-bld .st1 {
  fill: #2d4150; }

.control-button2.pages-btn-bld:hover .st1 {
  fill: #4eb7f5; }

.control-button2.pages-btn-bld:active .st1 {
  fill: #2d4150; }

.control-button2.pages-btn-bld[disabled] .st1 {
  fill: #8a8a8a; }

.control-button2.preview-btn .st1 {
  fill: #2d4150; }

.control-button2.preview-btn:hover .st1 {
  fill: #4eb7f5; }

.control-button2.preview-btn:active .st1 {
  fill: #2d4150; }

.control-button2.preview-btn[disabled] .st1 {
  fill: #8a8a8a; }

.control-button2.preview-btn-bld .st1 {
  fill: #2d4150; }

.control-button2.preview-btn-bld:hover .st1 {
  fill: #4eb7f5; }

.control-button2.preview-btn-bld:active .st1 {
  fill: #2d4150; }

.control-button2.preview-btn-bld[disabled] .st1 {
  fill: #8a8a8a; }

.control-button2.redo-btn .st1 {
  fill: #2d4150; }

.control-button2.redo-btn:hover .st1 {
  fill: #4eb7f5; }

.control-button2.redo-btn:active .st1 {
  fill: #2d4150; }

.control-button2.redo-btn[disabled] .st1 {
  fill: #8a8a8a; }

.control-button2.redo-btn-bld .st1 {
  fill: #2d4150; }

.control-button2.redo-btn-bld:hover .st1 {
  fill: #4eb7f5; }

.control-button2.redo-btn-bld:active .st1 {
  fill: #2d4150; }

.control-button2.redo-btn-bld[disabled] .st1 {
  fill: #8a8a8a; }

.control-button2.undo-btn .st1 {
  fill: #2d4150; }

.control-button2.undo-btn:hover .st1 {
  fill: #4eb7f5; }

.control-button2.undo-btn:active .st1 {
  fill: #2d4150; }

.control-button2.undo-btn[disabled] .st1 {
  fill: #8a8a8a; }

.control-button2.undo-btn-bld .st1 {
  fill: #2d4150; }

.control-button2.undo-btn-bld:hover .st1 {
  fill: #4eb7f5; }

.control-button2.undo-btn-bld:active .st1 {
  fill: #2d4150; }

.control-button2.undo-btn-bld[disabled] .st1 {
  fill: #8a8a8a; }

.control-button2.align-bottom-edges-btn-bld .st1 {
  fill: #2d4150; }

.control-button2.align-bottom-edges-btn-bld .st2 {
  fill: #7a92a5; }

.control-button2.align-bottom-edges-btn-bld:hover .st1 {
  fill: #3899ec; }

.control-button2.align-bottom-edges-btn-bld:hover .st2 {
  fill: #7fccf7; }

.control-button2.align-bottom-edges-btn-bld:active .st1 {
  fill: #2d4150; }

.control-button2.align-bottom-edges-btn-bld:active .st2 {
  fill: #7a92a5; }

.control-button2.align-bottom-edges-btn-bld[disabled] .st1 {
  fill: #8a8a8a; }

.control-button2.align-bottom-edges-btn-bld[disabled] .st2 {
  fill: #bcbcbc; }

.control-button2.align-center-edges-btn-bld .st1 {
  fill: #2d4150; }

.control-button2.align-center-edges-btn-bld .st2 {
  fill: #7a92a5; }

.control-button2.align-center-edges-btn-bld:hover .st1 {
  fill: #3899ec; }

.control-button2.align-center-edges-btn-bld:hover .st2 {
  fill: #7fccf7; }

.control-button2.align-center-edges-btn-bld:active .st1 {
  fill: #2d4150; }

.control-button2.align-center-edges-btn-bld:active .st2 {
  fill: #7a92a5; }

.control-button2.align-center-edges-btn-bld[disabled] .st1 {
  fill: #8a8a8a; }

.control-button2.align-center-edges-btn-bld[disabled] .st2 {
  fill: #bcbcbc; }

.control-button2.align-center-vertical-btn-bld .st1 {
  fill: #2d4150; }

.control-button2.align-center-vertical-btn-bld .st2 {
  fill: #7a92a5; }

.control-button2.align-center-vertical-btn-bld:hover .st1 {
  fill: #3899ec; }

.control-button2.align-center-vertical-btn-bld:hover .st2 {
  fill: #7fccf7; }

.control-button2.align-center-vertical-btn-bld:active .st1 {
  fill: #2d4150; }

.control-button2.align-center-vertical-btn-bld:active .st2 {
  fill: #7a92a5; }

.control-button2.align-center-vertical-btn-bld[disabled] .st1 {
  fill: #8a8a8a; }

.control-button2.align-center-vertical-btn-bld[disabled] .st2 {
  fill: #bcbcbc; }

.control-button2.align-left-edges-btn-bld .st1 {
  fill: #2d4150; }

.control-button2.align-left-edges-btn-bld .st2 {
  fill: #7a92a5; }

.control-button2.align-left-edges-btn-bld:hover .st1 {
  fill: #3899ec; }

.control-button2.align-left-edges-btn-bld:hover .st2 {
  fill: #7fccf7; }

.control-button2.align-left-edges-btn-bld:active .st1 {
  fill: #2d4150; }

.control-button2.align-left-edges-btn-bld:active .st2 {
  fill: #7a92a5; }

.control-button2.align-left-edges-btn-bld[disabled] .st1 {
  fill: #8a8a8a; }

.control-button2.align-left-edges-btn-bld[disabled] .st2 {
  fill: #bcbcbc; }

.control-button2.align-right-edges-btn-bld .st1 {
  fill: #2d4150; }

.control-button2.align-right-edges-btn-bld .st2 {
  fill: #7a92a5; }

.control-button2.align-right-edges-btn-bld:hover .st1 {
  fill: #3899ec; }

.control-button2.align-right-edges-btn-bld:hover .st2 {
  fill: #7fccf7; }

.control-button2.align-right-edges-btn-bld:active .st1 {
  fill: #2d4150; }

.control-button2.align-right-edges-btn-bld:active .st2 {
  fill: #7a92a5; }

.control-button2.align-right-edges-btn-bld[disabled] .st1 {
  fill: #8a8a8a; }

.control-button2.align-right-edges-btn-bld[disabled] .st2 {
  fill: #bcbcbc; }

.control-button2.align-top-edges-btn-bld .st1 {
  fill: #2d4150; }

.control-button2.align-top-edges-btn-bld .st2 {
  fill: #7a92a5; }

.control-button2.align-top-edges-btn-bld:hover .st1 {
  fill: #3899ec; }

.control-button2.align-top-edges-btn-bld:hover .st2 {
  fill: #7fccf7; }

.control-button2.align-top-edges-btn-bld:active .st1 {
  fill: #2d4150; }

.control-button2.align-top-edges-btn-bld:active .st2 {
  fill: #7a92a5; }

.control-button2.align-top-edges-btn-bld[disabled] .st1 {
  fill: #8a8a8a; }

.control-button2.align-top-edges-btn-bld[disabled] .st2 {
  fill: #bcbcbc; }

.control-button2.copy-btn-bld .st1 {
  fill: #162d3d; }

.control-button2.copy-btn-bld:hover .st1 {
  fill: #3899ec; }

.control-button2.copy-btn-bld:active .st1 {
  fill: #162d3d; }

.control-button2.copy-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.control-button2.copy-style-btn-bld .st1 {
  fill: #162d3d; }

.control-button2.copy-style-btn-bld:hover .st1 {
  fill: #3899ec; }

.control-button2.copy-style-btn-bld:active .st1 {
  fill: #162d3d; }

.control-button2.copy-style-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.control-button2.delete-btn-bld .st1 {
  fill: #162d3d; }

.control-button2.delete-btn-bld:hover .st1 {
  fill: #3899ec; }

.control-button2.delete-btn-bld:active .st1 {
  fill: #162d3d; }

.control-button2.delete-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.symbol-distribute-horizontal-btn-bld path,
.control-distribute-horizontal-btn-bld .symbol-distribute-horizontal-btn-bld path {
  transition: fill 0.2s; }

.symbol-distribute-horizontal-btn-bld .st1,
.control-distribute-horizontal-btn-bld .symbol-distribute-horizontal-btn-bld .st1 {
  fill: #7fccf7; }

.selected .symbol-distribute-horizontal-btn-bld .st1,
.control-distribute-horizontal-btn-bld .input-distribute-horizontal-btn-bld:checked ~ .symbol-distribute-horizontal-btn-bld .st1 {
  fill: #eaf7ff; }

.control-button2.distribute-horizontal-btn-bld .st1 {
  fill: #162d3d; }

.control-button2.distribute-horizontal-btn-bld:hover .st1 {
  fill: #3899ec; }

.control-button2.distribute-horizontal-btn-bld:active .st1 {
  fill: #162d3d; }

.control-button2.distribute-horizontal-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.symbol-distribute-hw-btn-bld path,
.control-distribute-hw-btn-bld .symbol-distribute-hw-btn-bld path {
  transition: fill 0.2s; }

.symbol-distribute-hw-btn-bld .st1,
.control-distribute-hw-btn-bld .symbol-distribute-hw-btn-bld .st1 {
  fill: #7fccf7; }

.selected .symbol-distribute-hw-btn-bld .st1,
.control-distribute-hw-btn-bld .input-distribute-hw-btn-bld:checked ~ .symbol-distribute-hw-btn-bld .st1 {
  fill: #eaf7ff; }

.control-button2.distribute-hw-btn-bld .st1 {
  fill: #162d3d; }

.control-button2.distribute-hw-btn-bld:hover .st1 {
  fill: #3899ec; }

.control-button2.distribute-hw-btn-bld:active .st1 {
  fill: #162d3d; }

.control-button2.distribute-hw-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.symbol-distribute-vertical-btn-bld path,
.control-distribute-vertical-btn-bld .symbol-distribute-vertical-btn-bld path {
  transition: fill 0.2s; }

.symbol-distribute-vertical-btn-bld .st1,
.control-distribute-vertical-btn-bld .symbol-distribute-vertical-btn-bld .st1 {
  fill: #7fccf7; }

.selected .symbol-distribute-vertical-btn-bld .st1,
.control-distribute-vertical-btn-bld .input-distribute-vertical-btn-bld:checked ~ .symbol-distribute-vertical-btn-bld .st1 {
  fill: #eaf7ff; }

.control-button2.distribute-vertical-btn-bld .st1 {
  fill: #162d3d; }

.control-button2.distribute-vertical-btn-bld:hover .st1 {
  fill: #3899ec; }

.control-button2.distribute-vertical-btn-bld:active .st1 {
  fill: #162d3d; }

.control-button2.distribute-vertical-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.control-button2.duplicate-btn-bld .st1 {
  fill: #162d3d; }

.control-button2.duplicate-btn-bld:hover .st1 {
  fill: #3899ec; }

.control-button2.duplicate-btn-bld:active .st1 {
  fill: #162d3d; }

.control-button2.duplicate-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.control-button2.lock-btn-bld .st1 {
  fill: #162d3d; }

.control-button2.lock-btn-bld:hover .st1 {
  fill: #3899ec; }

.control-button2.lock-btn-bld:active .st1 {
  fill: #162d3d; }

.control-button2.lock-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.symbol-mech-hw-btn-bld path,
.control-mech-hw-btn-bld .symbol-mech-hw-btn-bld path {
  transition: fill 0.2s; }

.symbol-mech-hw-btn-bld .st2,
.control-mech-hw-btn-bld .symbol-mech-hw-btn-bld .st2 {
  fill: #3899ec; }

.symbol-mech-hw-btn-bld .st1,
.control-mech-hw-btn-bld .symbol-mech-hw-btn-bld .st1 {
  fill: #7fccf7; }

.selected .symbol-mech-hw-btn-bld .st2,
.control-mech-hw-btn-bld .input-mech-hw-btn-bld:checked ~ .symbol-mech-hw-btn-bld .st2 {
  fill: #7fccf7; }

.selected .symbol-mech-hw-btn-bld .st1,
.control-mech-hw-btn-bld .input-mech-hw-btn-bld:checked ~ .symbol-mech-hw-btn-bld .st1 {
  fill: #eaf7ff; }

.control-button2.mech-hw-btn-bld .st1 {
  fill: #162d3d; }

.control-button2.mech-hw-btn-bld .st2 {
  fill: #7a92a5; }

.control-button2.mech-hw-btn-bld:hover .st1 {
  fill: #3899ec; }

.control-button2.mech-hw-btn-bld:hover .st2 {
  fill: #162d3d; }

.control-button2.mech-hw-btn-bld:active .st1 {
  fill: #162d3d; }

.control-button2.mech-hw-btn-bld:active .st2 {
  fill: #7fccf7; }

.control-button2.mech-hw-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.control-button2.mech-hw-btn-bld[disabled] .st2 {
  fill: #bcbcbc; }

.symbol-mech-to-height-btn-bld path,
.control-mech-to-height-btn-bld .symbol-mech-to-height-btn-bld path {
  transition: fill 0.2s; }

.symbol-mech-to-height-btn-bld .st2,
.control-mech-to-height-btn-bld .symbol-mech-to-height-btn-bld .st2 {
  fill: #3899ec; }

.symbol-mech-to-height-btn-bld .st1,
.control-mech-to-height-btn-bld .symbol-mech-to-height-btn-bld .st1 {
  fill: #7fccf7; }

.selected .symbol-mech-to-height-btn-bld .st2,
.control-mech-to-height-btn-bld .input-mech-to-height-btn-bld:checked ~ .symbol-mech-to-height-btn-bld .st2 {
  fill: #7fccf7; }

.selected .symbol-mech-to-height-btn-bld .st1,
.control-mech-to-height-btn-bld .input-mech-to-height-btn-bld:checked ~ .symbol-mech-to-height-btn-bld .st1 {
  fill: #eaf7ff; }

.control-button2.mech-to-height-btn-bld .st1 {
  fill: #162d3d; }

.control-button2.mech-to-height-btn-bld .st2 {
  fill: #7a92a5; }

.control-button2.mech-to-height-btn-bld:hover .st1 {
  fill: #3899ec; }

.control-button2.mech-to-height-btn-bld:hover .st2 {
  fill: #162d3d; }

.control-button2.mech-to-height-btn-bld:active .st1 {
  fill: #162d3d; }

.control-button2.mech-to-height-btn-bld:active .st2 {
  fill: #7fccf7; }

.control-button2.mech-to-height-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.control-button2.mech-to-height-btn-bld[disabled] .st2 {
  fill: #bcbcbc; }

.symbol-mech-to-width-btn-bld path,
.control-mech-to-width-btn-bld .symbol-mech-to-width-btn-bld path {
  transition: fill 0.2s; }

.symbol-mech-to-width-btn-bld .st2,
.control-mech-to-width-btn-bld .symbol-mech-to-width-btn-bld .st2 {
  fill: #3899ec; }

.symbol-mech-to-width-btn-bld .st1,
.control-mech-to-width-btn-bld .symbol-mech-to-width-btn-bld .st1 {
  fill: #7fccf7; }

.selected .symbol-mech-to-width-btn-bld .st2,
.control-mech-to-width-btn-bld .input-mech-to-width-btn-bld:checked ~ .symbol-mech-to-width-btn-bld .st2 {
  fill: #7fccf7; }

.selected .symbol-mech-to-width-btn-bld .st1,
.control-mech-to-width-btn-bld .input-mech-to-width-btn-bld:checked ~ .symbol-mech-to-width-btn-bld .st1 {
  fill: #eaf7ff; }

.control-button2.mech-to-width-btn-bld .st1 {
  fill: #162d3d; }

.control-button2.mech-to-width-btn-bld .st2 {
  fill: #7a92a5; }

.control-button2.mech-to-width-btn-bld:hover .st1 {
  fill: #3899ec; }

.control-button2.mech-to-width-btn-bld:hover .st2 {
  fill: #162d3d; }

.control-button2.mech-to-width-btn-bld:active .st1 {
  fill: #162d3d; }

.control-button2.mech-to-width-btn-bld:active .st2 {
  fill: #7fccf7; }

.control-button2.mech-to-width-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.control-button2.mech-to-width-btn-bld[disabled] .st2 {
  fill: #bcbcbc; }

.control-button2.move-backward-btn-bld .st1 {
  fill: #162d3d; }

.control-button2.move-backward-btn-bld:hover .st1 {
  fill: #3899ec; }

.control-button2.move-backward-btn-bld:active .st1 {
  fill: #162d3d; }

.control-button2.move-backward-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.control-button2.move-foreward-btn-bld .st1 {
  fill: #162d3d; }

.control-button2.move-foreward-btn-bld:hover .st1 {
  fill: #3899ec; }

.control-button2.move-foreward-btn-bld:active .st1 {
  fill: #162d3d; }

.control-button2.move-foreward-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.control-button2.move-to-back-btn-bld .st1 {
  fill: #162d3d; }

.control-button2.move-to-back-btn-bld:hover .st1 {
  fill: #3899ec; }

.control-button2.move-to-back-btn-bld:active .st1 {
  fill: #162d3d; }

.control-button2.move-to-back-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.control-button2.move-to-front-btn-bld .st1 {
  fill: #162d3d; }

.control-button2.move-to-front-btn-bld:hover .st1 {
  fill: #3899ec; }

.control-button2.move-to-front-btn-bld:active .st1 {
  fill: #162d3d; }

.control-button2.move-to-front-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.control-button2.paste-btn-bld .st1 {
  fill: #162d3d; }

.control-button2.paste-btn-bld:hover .st1 {
  fill: #3899ec; }

.control-button2.paste-btn-bld:active .st1 {
  fill: #162d3d; }

.control-button2.paste-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.control-button2.paste-style-btn-bld .st1 {
  fill: #162d3d; }

.control-button2.paste-style-btn-bld:hover .st1 {
  fill: #3899ec; }

.control-button2.paste-style-btn-bld:active .st1 {
  fill: #162d3d; }

.control-button2.paste-style-btn-bld[disabled] .st1 {
  fill: #7a7a7a; }

.control-button2.rotation-btn .st1 {
  fill: #162d3d; }

.control-button2.rotation-btn:hover .st1 {
  fill: #3899ec; }

.control-button2.rotation-btn:active .st1 {
  fill: #162d3d; }

.control-button2.rotation-btn[disabled] .st1 {
  fill: #7a7a7a; }

.category-view-wrapper .symbol-headerCloseButton {
  fill: #3899ec; }

.wixstores-migration-base-modal-header .symbol-headerCloseButton {
  fill: #d3edff; }

.cancel-icon .symbol-headerCloseButton {
  fill: #3899ec; }

.symbol-anim-arc-in .st1,
.control-anim-arc-in .symbol-anim-arc-in .st1 {
  fill: #4eb7f5; }

.symbol-anim-arc-in .st2,
.control-anim-arc-in .symbol-anim-arc-in .st2 {
  fill: #7fccf7; }

.selected .symbol-anim-arc-in .st1,
.control-anim-arc-in .input-anim-arc-in:checked ~ .symbol-anim-arc-in .st1 {
  fill: #d3edff; }

.selected .symbol-anim-arc-in .st2,
.control-anim-arc-in .input-anim-arc-in:checked ~ .symbol-anim-arc-in .st2 {
  fill: #fff; }

.symbol-anim-expand-in .st1,
.control-anim-expand-in .symbol-anim-expand-in .st1 {
  fill: #4eb7f5; }

.symbol-anim-expand-in .st2,
.control-anim-expand-in .symbol-anim-expand-in .st2 {
  fill: #7fccf7; }

.selected .symbol-anim-expand-in .st1,
.control-anim-expand-in .input-anim-expand-in:checked ~ .symbol-anim-expand-in .st1 {
  fill: #d3edff; }

.selected .symbol-anim-expand-in .st2,
.control-anim-expand-in .input-anim-expand-in:checked ~ .symbol-anim-expand-in .st2 {
  fill: #fff; }

.symbol-anim-fade-in .st1,
.symbol-anim-fade-in .st2,
.symbol-anim-fade-in .st3,
.symbol-anim-fade-in .st4,
.symbol-anim-fade-in .st5,
.control-anim-fade-in .symbol-anim-fade-in .st1,
.control-anim-fade-in .symbol-anim-fade-in .st2,
.control-anim-fade-in .symbol-anim-fade-in .st3,
.control-anim-fade-in .symbol-anim-fade-in .st4,
.control-anim-fade-in .symbol-anim-fade-in .st5 {
  fill: #4eb7f5; }

.symbol-anim-fade-in .st1,
.control-anim-fade-in .symbol-anim-fade-in .st1 {
  opacity: 0.3; }

.symbol-anim-fade-in .st2,
.control-anim-fade-in .symbol-anim-fade-in .st2 {
  opacity: 0.4; }

.symbol-anim-fade-in .st3,
.control-anim-fade-in .symbol-anim-fade-in .st3 {
  opacity: 0.5; }

.symbol-anim-fade-in .st4,
.control-anim-fade-in .symbol-anim-fade-in .st4 {
  opacity: 0.6; }

.symbol-anim-fade-in .st5,
.control-anim-fade-in .symbol-anim-fade-in .st5 {
  opacity: 0.8; }

.selected .symbol-anim-fade-in .st1,
.selected .symbol-anim-fade-in .st2,
.selected .symbol-anim-fade-in .st3,
.selected .symbol-anim-fade-in .st4,
.control-anim-fade-in .input-anim-fade-in:checked ~ .symbol-anim-fade-in .st1,
.control-anim-fade-in .input-anim-fade-in:checked ~ .symbol-anim-fade-in .st2,
.control-anim-fade-in .input-anim-fade-in:checked ~ .symbol-anim-fade-in .st3,
.control-anim-fade-in .input-anim-fade-in:checked ~ .symbol-anim-fade-in .st4 {
  fill: #d3edff; }

.selected .symbol-anim-fade-in .st1,
.control-anim-fade-in .input-anim-fade-in:checked ~ .symbol-anim-fade-in .st1 {
  opacity: 0.3; }

.selected .symbol-anim-fade-in .st2,
.control-anim-fade-in .input-anim-fade-in:checked ~ .symbol-anim-fade-in .st2 {
  opacity: 0.4; }

.selected .symbol-anim-fade-in .st3,
.control-anim-fade-in .input-anim-fade-in:checked ~ .symbol-anim-fade-in .st3 {
  opacity: 0.5; }

.selected .symbol-anim-fade-in .st4,
.control-anim-fade-in .input-anim-fade-in:checked ~ .symbol-anim-fade-in .st4 {
  opacity: 0.6; }

.selected .symbol-anim-fade-in .st5,
.control-anim-fade-in .input-anim-fade-in:checked ~ .symbol-anim-fade-in .st5 {
  fill: #fff;
  opacity: 0.8; }

.symbol-anim-flip-in .st1,
.control-anim-flip-in .symbol-anim-flip-in .st1 {
  fill: #4eb7f5; }

.symbol-anim-flip-in .st2,
.control-anim-flip-in .symbol-anim-flip-in .st2 {
  fill: #7fccf7; }

.selected .symbol-anim-flip-in .st1,
.control-anim-flip-in .input-anim-flip-in:checked ~ .symbol-anim-flip-in .st1 {
  fill: #d3edff; }

.selected .symbol-anim-flip-in .st2,
.control-anim-flip-in .input-anim-flip-in:checked ~ .symbol-anim-flip-in .st2 {
  fill: #fff; }

.symbol-anim-float-in .st1,
.control-anim-float-in .symbol-anim-float-in .st1 {
  fill: #4eb7f5; }

.symbol-anim-float-in .st2,
.control-anim-float-in .symbol-anim-float-in .st2 {
  fill: #7fccf7; }

.selected .symbol-anim-float-in .st1,
.control-anim-float-in .input-anim-float-in:checked ~ .symbol-anim-float-in .st1 {
  fill: #d3edff; }

.selected .symbol-anim-float-in .st2,
.control-anim-float-in .input-anim-float-in:checked ~ .symbol-anim-float-in .st2 {
  fill: #fff; }

.symbol-anim-fly-in .st1,
.control-anim-fly-in .symbol-anim-fly-in .st1 {
  fill: #4eb7f5; }

.symbol-anim-fly-in .st2,
.control-anim-fly-in .symbol-anim-fly-in .st2 {
  fill: #7fccf7; }

.selected .symbol-anim-fly-in .st1,
.control-anim-fly-in .input-anim-fly-in:checked ~ .symbol-anim-fly-in .st1 {
  fill: #d3edff; }

.selected .symbol-anim-fly-in .st2,
.control-anim-fly-in .input-anim-fly-in:checked ~ .symbol-anim-fly-in .st2 {
  fill: #fff; }

.symbol-anim-fold-in .st1,
.control-anim-fold-in .symbol-anim-fold-in .st1 {
  fill: #4eb7f5; }

.symbol-anim-fold-in .st2,
.control-anim-fold-in .symbol-anim-fold-in .st2 {
  fill: #7fccf7; }

.symbol-anim-fold-in .st3,
.control-anim-fold-in .symbol-anim-fold-in .st3 {
  fill: #4eb7f5;
  opacity: 0.25; }

.selected .symbol-anim-fold-in .st1,
.control-anim-fold-in .input-anim-fold-in:checked ~ .symbol-anim-fold-in .st1 {
  fill: #d3edff; }

.selected .symbol-anim-fold-in .st2,
.control-anim-fold-in .input-anim-fold-in:checked ~ .symbol-anim-fold-in .st2 {
  fill: #fff; }

.selected .symbol-anim-fold-in .st3,
.control-anim-fold-in .input-anim-fold-in:checked ~ .symbol-anim-fold-in .st3 {
  fill: #d3edff;
  opacity: 0.25; }

.symbol-anim-puff-in .st1,
.control-anim-puff-in .symbol-anim-puff-in .st1 {
  fill: #4eb7f5; }

.symbol-anim-puff-in .st2,
.control-anim-puff-in .symbol-anim-puff-in .st2 {
  fill: #7fccf7; }

.selected .symbol-anim-puff-in .st1,
.control-anim-puff-in .input-anim-puff-in:checked ~ .symbol-anim-puff-in .st1 {
  fill: #d3edff; }

.selected .symbol-anim-puff-in .st2,
.control-anim-puff-in .input-anim-puff-in:checked ~ .symbol-anim-puff-in .st2 {
  fill: #fff; }

.symbol-anim-reveal .st1,
.control-anim-reveal .symbol-anim-reveal .st1 {
  fill: #4eb7f5; }

.symbol-anim-reveal .st2,
.control-anim-reveal .symbol-anim-reveal .st2 {
  fill: #7fccf7; }

.symbol-anim-reveal .st3,
.control-anim-reveal .symbol-anim-reveal .st3 {
  fill: #4eb7f5;
  opacity: 0.25; }

.selected .symbol-anim-reveal .st1,
.control-anim-reveal .input-anim-reveal:checked ~ .symbol-anim-reveal .st1 {
  fill: #d3edff; }

.selected .symbol-anim-reveal .st2,
.control-anim-reveal .input-anim-reveal:checked ~ .symbol-anim-reveal .st2 {
  fill: #fff; }

.selected .symbol-anim-reveal .st3,
.control-anim-reveal .input-anim-reveal:checked ~ .symbol-anim-reveal .st3 {
  fill: #d3edff;
  opacity: 0.25; }

.symbol-anim-slide-in .st1,
.control-anim-slide-in .symbol-anim-slide-in .st1 {
  fill: #4eb7f5; }

.symbol-anim-slide-in .st2,
.control-anim-slide-in .symbol-anim-slide-in .st2 {
  fill: #7fccf7; }

.symbol-anim-slide-in .st3,
.control-anim-slide-in .symbol-anim-slide-in .st3 {
  fill: #4eb7f5;
  opacity: 0.25; }

.selected .symbol-anim-slide-in .st1,
.control-anim-slide-in .input-anim-slide-in:checked ~ .symbol-anim-slide-in .st1 {
  fill: #d3edff; }

.selected .symbol-anim-slide-in .st2,
.control-anim-slide-in .input-anim-slide-in:checked ~ .symbol-anim-slide-in .st2 {
  fill: #fff; }

.selected .symbol-anim-slide-in .st3,
.control-anim-slide-in .input-anim-slide-in:checked ~ .symbol-anim-slide-in .st3 {
  fill: #d3edff;
  opacity: 0.25; }

.symbol-anim-spin-in .st1,
.control-anim-spin-in .symbol-anim-spin-in .st1 {
  fill: #4eb7f5; }

.symbol-anim-spin-in .st2,
.control-anim-spin-in .symbol-anim-spin-in .st2 {
  fill: #7fccf7; }

.selected .symbol-anim-spin-in .st1,
.control-anim-spin-in .input-anim-spin-in:checked ~ .symbol-anim-spin-in .st1 {
  fill: #d3edff; }

.selected .symbol-anim-spin-in .st2,
.control-anim-spin-in .input-anim-spin-in:checked ~ .symbol-anim-spin-in .st2 {
  fill: #fff; }

.symbol-anim-turn-in .st1,
.control-anim-turn-in .symbol-anim-turn-in .st1 {
  fill: #4eb7f5; }

.symbol-anim-turn-in .st2,
.control-anim-turn-in .symbol-anim-turn-in .st2 {
  fill: #7fccf7; }

.selected .symbol-anim-turn-in .st1,
.control-anim-turn-in .input-anim-turn-in:checked ~ .symbol-anim-turn-in .st1 {
  fill: #d3edff; }

.selected .symbol-anim-turn-in .st2,
.control-anim-turn-in .input-anim-turn-in:checked ~ .symbol-anim-turn-in .st2 {
  fill: #fff; }

.symbol-anim-bounce-in .st1,
.control-anim-bounce-in .symbol-anim-bounce-in .st1 {
  fill: #4eb7f5; }

.symbol-anim-bounce-in .st2,
.control-anim-bounce-in .symbol-anim-bounce-in .st2 {
  fill: #7fccf7; }

.selected .symbol-anim-bounce-in .st1,
.control-anim-bounce-in .input-anim-bounce-in:checked ~ .symbol-anim-bounce-in .st1 {
  fill: #d3edff; }

.selected .symbol-anim-bounce-in .st2,
.control-anim-bounce-in .input-anim-bounce-in:checked ~ .symbol-anim-bounce-in .st2 {
  fill: #fff; }

.symbol-anim-glide-in .st1,
.control-anim-glide-in .symbol-anim-glide-in .st1 {
  fill: #7fccf7; }

.symbol-anim-glide-in .st2,
.control-anim-glide-in .symbol-anim-glide-in .st2 {
  fill: #4eb7f5; }

.selected .symbol-anim-glide-in .st1,
.control-anim-glide-in .input-anim-glide-in:checked ~ .symbol-anim-glide-in .st1 {
  fill: #fff; }

.selected .symbol-anim-glide-in .st2,
.control-anim-glide-in .input-anim-glide-in:checked ~ .symbol-anim-glide-in .st2 {
  fill: #d9e1e8; }

.symbol-anim-on .st0,
.symbol-anim-on .st2,
.symbol-anim-on .st3,
.control-anim-on .symbol-anim-on .st0,
.control-anim-on .symbol-anim-on .st2,
.control-anim-on .symbol-anim-on .st3 {
  fill: #4eb7f5; }

.symbol-anim-on .st1,
.control-anim-on .symbol-anim-on .st1 {
  fill: #7fccf7; }

.selected .symbol-anim-on .st0,
.selected .symbol-anim-on .st3,
.control-anim-on .input-anim-on:checked ~ .symbol-anim-on .st0,
.control-anim-on .input-anim-on:checked ~ .symbol-anim-on .st3 {
  fill: #d3edff; }

.selected .symbol-anim-on .st1,
.selected .symbol-anim-on .st2,
.control-anim-on .input-anim-on:checked ~ .symbol-anim-on .st1,
.control-anim-on .input-anim-on:checked ~ .symbol-anim-on .st2 {
  fill: #fff; }

.symbol-play-anim .st0,
.control-play-anim .symbol-play-anim .st0 {
  fill: transparent;
  stroke: #fff;
  stroke-width: 2;
  opacity: 0.5; }

.symbol-play-anim .st1,
.control-play-anim .symbol-play-anim .st1 {
  fill: #fff; }

.symbol-play-anim .st2,
.control-play-anim .symbol-play-anim .st2 {
  fill: transparent; }

.symbol-play-anim .st3,
.control-play-anim .symbol-play-anim .st3 {
  fill: transparent;
  stroke: #fff;
  stroke-width: 2; }

.selected .symbol-play-anim .st0,
.control-play-anim .input-play-anim:checked ~ .symbol-play-anim .st0 {
  fill: transparent;
  stroke: #fff;
  stroke-width: 2;
  opacity: 0.5; }

.selected .symbol-play-anim .st1,
.control-play-anim .input-play-anim:checked ~ .symbol-play-anim .st1 {
  fill: transparent; }

.selected .symbol-play-anim .st2,
.control-play-anim .input-play-anim:checked ~ .symbol-play-anim .st2 {
  fill: #fff; }

.selected .symbol-play-anim .st3,
.control-play-anim .input-play-anim:checked ~ .symbol-play-anim .st3 {
  fill: transparent;
  stroke: #fff;
  stroke-width: 2; }

.symbol-flickr-number-images-1 .st1,
.control-flickr-number-images-1 .symbol-flickr-number-images-1 .st1 {
  fill: #54CCF9; }

.selected .symbol-flickr-number-images-1 .st1,
.control-flickr-number-images-1 .input-flickr-number-images-1:checked ~ .symbol-flickr-number-images-1 .st1 {
  fill: #ffffff; }

.symbol-flickr-number-images-3 .st1,
.control-flickr-number-images-3 .symbol-flickr-number-images-3 .st1 {
  fill: #54CCF9; }

.selected .symbol-flickr-number-images-3 .st1,
.control-flickr-number-images-3 .input-flickr-number-images-3:checked ~ .symbol-flickr-number-images-3 .st1 {
  fill: #ffffff; }

.symbol-flickr-number-images-5 .st1,
.control-flickr-number-images-5 .symbol-flickr-number-images-5 .st1 {
  fill: #54CCF9; }

.selected .symbol-flickr-number-images-5 .st1,
.control-flickr-number-images-5 .input-flickr-number-images-5:checked ~ .symbol-flickr-number-images-5 .st1 {
  fill: #ffffff; }

.symbol-flickr-number-images-10 .st1,
.control-flickr-number-images-10 .symbol-flickr-number-images-10 .st1 {
  fill: #54CCF9; }

.selected .symbol-flickr-number-images-10 .st1,
.control-flickr-number-images-10 .input-flickr-number-images-10:checked ~ .symbol-flickr-number-images-10 .st1 {
  fill: #ffffff; }

.symbol-quickActionsLinkArrow {
  transform: scale(-1, 1); }
  .symbol-quickActionsLinkArrow .st1 {
    fill: #2b5672; }

.symbol-add-custom-color-plus {
  fill: #3899ec; }

.control-accordion .symbol-accordionOpen {
  fill: #162d3d;
  width: 10px;
  height: 5px; }

.rich-text-input-control .symbol-accordionOpen {
  fill: #2b5672;
  width: 7px;
  padding: 3px 7px; }

.control-accordion .symbol-accordionClose {
  fill: #162d3d;
  width: 5px;
  height: 10px; }

.rich-text-input-control .symbol-accordionClose {
  fill: #2b5672;
  width: 4px;
  padding: 0 9px; }

.dropdown .symbol-arrowDown,
.dropdown-options .symbol-arrowDown {
  fill: #3899ec;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%); }

.dropdown-options .arrow:hover .symbol-arrowDown {
  opacity: 1; }

.dropdown.disabled.select .symbol-arrowDown {
  fill: #bcbcbc; }

.font-family-dd.toolbar-mode .symbol-arrowDown,
.units-dd .symbol-arrowDown {
  width: 9px;
  padding: 0; }

.font-family-dd.toolbar-mode .expand.arrow:hover .symbol-arrowDown,
.units-dd .expand.arrow:hover .symbol-arrowDown {
  fill: #3899ec; }

.symbol-inputValidationError {
  fill: #ee5951; }
  .symbol-inputValidationError .c1,
  .symbol-inputValidationError .c2 {
    fill: #fff; }

.symbol-inputValidationSuccess {
  fill: #60bc57; }
  .symbol-inputValidationSuccess .c1 {
    fill: #fff; }
  .media-button-filename-text .symbol-inputValidationSuccess {
    width: 20px;
    height: 20px;
    padding-left: 4px;
    vertical-align: middle;
    display: inline-block;
    background-color: #fff; }

.symbol-inputValidationWarning {
  fill: #ffc233; }
  .symbol-inputValidationWarning > .line {
    stroke: #fff; }

.symbol-switchToOldEditorPanel {
  stroke: none;
  stroke-width: 1px;
  fill: none; }
  .symbol-switchToOldEditorPanel > .c1 {
    fill: #d9e1e8; }
  .symbol-switchToOldEditorPanel > .c2 {
    fill: #7992a5; }
  .symbol-switchToOldEditorPanel > .c3 {
    fill: #a7e3fa; }
  .symbol-switchToOldEditorPanel > .c4 {
    fill: #d3edff; }
  .symbol-switchToOldEditorPanel > .c5 {
    fill: #fff; }
  .symbol-switchToOldEditorPanel > .c9 {
    fill: #2b5672; }

.symbol-upload-btn {
  fill: #fff; }

.symbol-fonts-manager-clipart .d-1 {
  fill: #E9F6FF; }

.symbol-fonts-manager-clipart .d-2 {
  fill: #92E0F8; }

.symbol-fonts-manager-clipart .d-3 {
  fill: #5FCEF6; }

.dragging .symbol-fonts-manager-clipart .d-2,
.dragging .symbol-fonts-manager-clipart .d-3 {
  fill: #4EB7F5; }

.symbol-site-members {
  fill: #3899ec;
  width: 17px;
  height: 15px; }

.label-info-icon-container .connected-info-icon .content {
  fill: #fff; }

.platform-warning .a {
  fill: #2B5672; }

.platform-warning .b {
  fill: #FDB5B4; }

.platform-warning .c {
  fill: #FACDCA; }

.platform-warning .d {
  fill: #FFF; }

.platform-warning .e {
  fill: #7992A5; }

.platform-warning .f {
  fill: #E6EBED; }

.platform-warning .g {
  stroke-width: 4;
  stroke: #E6EBED; }

.symbol-textUnderline,
.control-textUnderline .symbol-textUnderline {
  fill: #2b5672; }

.control-textUnderline:active .symbol-textUnderline {
  fill: #fff; }

.selected .symbol-textUnderline,
.control-textUnderline .input-textUnderline:checked ~ .symbol-textUnderline {
  fill: #fff; }

.symbol-textBold,
.control-textBold .symbol-textBold {
  fill: #2b5672; }

.control-textBold:active .symbol-textBold {
  fill: #fff; }

.selected .symbol-textBold,
.control-textBold .input-textBold:checked ~ .symbol-textBold {
  fill: #fff; }

.symbol-textItalic,
.control-textItalic .symbol-textItalic {
  fill: #2b5672; }

.control-textItalic:active .symbol-textItalic {
  fill: #fff; }

.selected .symbol-textItalic,
.control-textItalic .input-textItalic:checked ~ .symbol-textItalic {
  fill: #fff; }

.symbol-textLink,
.control-textLink .symbol-textLink {
  fill: #2b5672; }

.control-textLink:active .symbol-textLink {
  fill: #fff; }

.selected .symbol-textLink,
.control-textLink .input-textLink:checked ~ .symbol-textLink {
  fill: #fff; }

.control-textLink .input-textLink:disabled ~ .symbol-textLink {
  pointer-events: none;
  fill: #939393; }

.text-link-dd .symbol-textLink {
  pointer-events: none;
  vertical-align: middle;
  padding: 0;
  display: inline-block; }

.selected .symbol-textLink {
  fill: #ffffff; }

.control-accordion .symbol-textEffectsIndicator {
  fill: #80abe4; }

.symbol-font-delete {
  fill: #2b5672;
  width: 13px;
  height: 14px; }

.symbol-font-download {
  fill: #2b5672;
  width: 17px;
  height: 13px; }
  .font-actions .symbol-font-download {
    margin: 0 12px; }

.symbol-font-rename {
  fill: #2b5672;
  width: 15px;
  height: 14px; }

.symbol-font-uploading-loader {
  fill: #3899ec;
  width: 24px;
  height: 24px; }
  .symbol-font-uploading-loader.processing {
    animation: spin 1.5s linear infinite; }

@keyframes spin {
  100% {
    transform: rotate(-360deg); } }
  .symbol-font-uploading-loader.waiting, .symbol-font-uploading-loader.uploading {
    fill: #d9e1e8; }

.symbol-textAddLang {
  fill: #3899ec; }
  .font-family-footer-link .symbol-textAddLang {
    margin-right: 5px; }

.symbol-textUploadFonts {
  fill: #3899ec; }
  .font-family-footer-link .symbol-textUploadFonts {
    margin-right: 8px; }

.symbol-handle-dots {
  margin: 4px 0; }
  .symbol-handle-dots.dark .regular {
    fill: #7a92a5; }
  .symbol-handle-dots.dark .offset {
    fill: #fff; }
  .symbol-handle-dots.light .regular {
    fill: #fff; }
  .symbol-handle-dots.light .offset {
    display: none; }

.symbol-handle-arrow .arrow {
  fill: #fff; }

.symbol-handle-arrow.bottom {
  transform: scale(1, -1); }

.symbol-scroll-fx-site-bg-freeze .st1,
.control-scroll-fx-site-bg-freeze .symbol-scroll-fx-site-bg-freeze .st1 {
  fill: #4eb7f5; }

.symbol-scroll-fx-site-bg-freeze .st2,
.control-scroll-fx-site-bg-freeze .symbol-scroll-fx-site-bg-freeze .st2 {
  fill: #d3edff; }

.symbol-scroll-fx-site-bg-freeze .st3,
.control-scroll-fx-site-bg-freeze .symbol-scroll-fx-site-bg-freeze .st3 {
  fill: #eaf7ff; }

.symbol-scroll-fx-site-bg-freeze .st4,
.control-scroll-fx-site-bg-freeze .symbol-scroll-fx-site-bg-freeze .st4 {
  fill: #7fccf7;
  opacity: 0.9; }

.symbol-scroll-fx-site-bg-freeze .st5,
.control-scroll-fx-site-bg-freeze .symbol-scroll-fx-site-bg-freeze .st5 {
  fill: #d3edff; }

.symbol-scroll-fx-site-bg-freeze .st6,
.control-scroll-fx-site-bg-freeze .symbol-scroll-fx-site-bg-freeze .st6 {
  fill: none;
  stroke: #7fccf7; }

.selected .symbol-scroll-fx-site-bg-freeze .st1,
.control-scroll-fx-site-bg-freeze .input-scroll-fx-site-bg-freeze:checked ~ .symbol-scroll-fx-site-bg-freeze .st1 {
  fill: #d3edff; }

.selected .symbol-scroll-fx-site-bg-freeze .st2,
.control-scroll-fx-site-bg-freeze .input-scroll-fx-site-bg-freeze:checked ~ .symbol-scroll-fx-site-bg-freeze .st2 {
  fill: #7fccf7; }

.selected .symbol-scroll-fx-site-bg-freeze .st3,
.control-scroll-fx-site-bg-freeze .input-scroll-fx-site-bg-freeze:checked ~ .symbol-scroll-fx-site-bg-freeze .st3 {
  fill: #4eb7f5; }

.selected .symbol-scroll-fx-site-bg-freeze .st4,
.control-scroll-fx-site-bg-freeze .input-scroll-fx-site-bg-freeze:checked ~ .symbol-scroll-fx-site-bg-freeze .st4 {
  fill: #eaf7ff;
  opacity: 0.9; }

.selected .symbol-scroll-fx-site-bg-freeze .st5,
.control-scroll-fx-site-bg-freeze .input-scroll-fx-site-bg-freeze:checked ~ .symbol-scroll-fx-site-bg-freeze .st5 {
  fill: #7fccf7; }

.selected .symbol-scroll-fx-site-bg-freeze .st6,
.control-scroll-fx-site-bg-freeze .input-scroll-fx-site-bg-freeze:checked ~ .symbol-scroll-fx-site-bg-freeze .st6 {
  fill: none;
  stroke: #7fccf7; }

.symbol-scroll-fx-site-bg-parallax .st1,
.control-scroll-fx-site-bg-parallax .symbol-scroll-fx-site-bg-parallax .st1 {
  fill: #4eb7f5; }

.symbol-scroll-fx-site-bg-parallax .st2,
.control-scroll-fx-site-bg-parallax .symbol-scroll-fx-site-bg-parallax .st2 {
  fill: #d3edff; }

.symbol-scroll-fx-site-bg-parallax .st3,
.control-scroll-fx-site-bg-parallax .symbol-scroll-fx-site-bg-parallax .st3 {
  fill: #eaf7ff; }

.symbol-scroll-fx-site-bg-parallax .st4,
.control-scroll-fx-site-bg-parallax .symbol-scroll-fx-site-bg-parallax .st4 {
  fill: #7fccf7;
  opacity: 0.9; }

.symbol-scroll-fx-site-bg-parallax .st5,
.control-scroll-fx-site-bg-parallax .symbol-scroll-fx-site-bg-parallax .st5 {
  fill: #d3edff; }

.symbol-scroll-fx-site-bg-parallax .st6,
.control-scroll-fx-site-bg-parallax .symbol-scroll-fx-site-bg-parallax .st6 {
  fill: none;
  stroke: #4eb7f5; }

.symbol-scroll-fx-site-bg-parallax .st7,
.control-scroll-fx-site-bg-parallax .symbol-scroll-fx-site-bg-parallax .st7 {
  fill: none;
  stroke: #7fccf7; }

.selected .symbol-scroll-fx-site-bg-parallax .st1,
.control-scroll-fx-site-bg-parallax .input-scroll-fx-site-bg-parallax:checked ~ .symbol-scroll-fx-site-bg-parallax .st1 {
  fill: #d3edff; }

.selected .symbol-scroll-fx-site-bg-parallax .st2,
.control-scroll-fx-site-bg-parallax .input-scroll-fx-site-bg-parallax:checked ~ .symbol-scroll-fx-site-bg-parallax .st2 {
  fill: #7fccf7; }

.selected .symbol-scroll-fx-site-bg-parallax .st3,
.control-scroll-fx-site-bg-parallax .input-scroll-fx-site-bg-parallax:checked ~ .symbol-scroll-fx-site-bg-parallax .st3 {
  fill: #4eb7f5; }

.selected .symbol-scroll-fx-site-bg-parallax .st4,
.control-scroll-fx-site-bg-parallax .input-scroll-fx-site-bg-parallax:checked ~ .symbol-scroll-fx-site-bg-parallax .st4 {
  fill: #eaf7ff;
  opacity: 0.9; }

.selected .symbol-scroll-fx-site-bg-parallax .st5,
.control-scroll-fx-site-bg-parallax .input-scroll-fx-site-bg-parallax:checked ~ .symbol-scroll-fx-site-bg-parallax .st5 {
  fill: #7fccf7; }

.selected .symbol-scroll-fx-site-bg-parallax .st6,
.control-scroll-fx-site-bg-parallax .input-scroll-fx-site-bg-parallax:checked ~ .symbol-scroll-fx-site-bg-parallax .st6 {
  fill: none;
  stroke: #d3edff; }

.selected .symbol-scroll-fx-site-bg-parallax .st7,
.control-scroll-fx-site-bg-parallax .input-scroll-fx-site-bg-parallax:checked ~ .symbol-scroll-fx-site-bg-parallax .st7 {
  fill: none;
  stroke: #7fccf7; }

.symbol-scroll-fx-strip-bg-reveal .st1,
.control-scroll-fx-strip-bg-reveal .symbol-scroll-fx-strip-bg-reveal .st1 {
  fill: #A5DBF9; }

.symbol-scroll-fx-strip-bg-reveal .st2,
.control-scroll-fx-strip-bg-reveal .symbol-scroll-fx-strip-bg-reveal .st2 {
  fill: #fff; }

.symbol-scroll-fx-strip-bg-reveal .st3,
.control-scroll-fx-strip-bg-reveal .symbol-scroll-fx-strip-bg-reveal .st3 {
  fill: #eaf7ff; }

.symbol-scroll-fx-strip-bg-reveal .st4,
.control-scroll-fx-strip-bg-reveal .symbol-scroll-fx-strip-bg-reveal .st4 {
  fill: #4eb7f5; }

.symbol-scroll-fx-strip-bg-reveal .st5,
.control-scroll-fx-strip-bg-reveal .symbol-scroll-fx-strip-bg-reveal .st5 {
  fill: #4eb7f5;
  opacity: 0.9; }

.symbol-scroll-fx-strip-bg-reveal .st6,
.control-scroll-fx-strip-bg-reveal .symbol-scroll-fx-strip-bg-reveal .st6 {
  fill: #7fccf7; }

.symbol-scroll-fx-strip-bg-reveal .st7,
.control-scroll-fx-strip-bg-reveal .symbol-scroll-fx-strip-bg-reveal .st7 {
  fill: #d3edff; }

.selected .symbol-scroll-fx-strip-bg-reveal .st1,
.control-scroll-fx-strip-bg-reveal .input-scroll-fx-strip-bg-reveal:checked ~ .symbol-scroll-fx-strip-bg-reveal .st1 {
  fill: #4eb7f5;
  opacity: 0.7; }

.selected .symbol-scroll-fx-strip-bg-reveal .st2,
.control-scroll-fx-strip-bg-reveal .input-scroll-fx-strip-bg-reveal:checked ~ .symbol-scroll-fx-strip-bg-reveal .st2 {
  fill: #7fccf7; }

.selected .symbol-scroll-fx-strip-bg-reveal .st3,
.control-scroll-fx-strip-bg-reveal .input-scroll-fx-strip-bg-reveal:checked ~ .symbol-scroll-fx-strip-bg-reveal .st3 {
  fill: #7fccf7;
  opacity: 0.6; }

.selected .symbol-scroll-fx-strip-bg-reveal .st4,
.control-scroll-fx-strip-bg-reveal .input-scroll-fx-strip-bg-reveal:checked ~ .symbol-scroll-fx-strip-bg-reveal .st4 {
  fill: #d3edff; }

.selected .symbol-scroll-fx-strip-bg-reveal .st5,
.control-scroll-fx-strip-bg-reveal .input-scroll-fx-strip-bg-reveal:checked ~ .symbol-scroll-fx-strip-bg-reveal .st5 {
  fill: #d3edff;
  opacity: 0.9; }

.selected .symbol-scroll-fx-strip-bg-reveal .st6,
.control-scroll-fx-strip-bg-reveal .input-scroll-fx-strip-bg-reveal:checked ~ .symbol-scroll-fx-strip-bg-reveal .st6 {
  fill: #7fccf7; }

.selected .symbol-scroll-fx-strip-bg-reveal .st7,
.control-scroll-fx-strip-bg-reveal .input-scroll-fx-strip-bg-reveal:checked ~ .symbol-scroll-fx-strip-bg-reveal .st7 {
  fill: #7fccf7; }

.symbol-scroll-fx-strip-bg-parallax .st1,
.control-scroll-fx-strip-bg-parallax .symbol-scroll-fx-strip-bg-parallax .st1 {
  fill: #A5DBF9; }

.symbol-scroll-fx-strip-bg-parallax .st2,
.control-scroll-fx-strip-bg-parallax .symbol-scroll-fx-strip-bg-parallax .st2 {
  fill: #eaf7ff; }

.symbol-scroll-fx-strip-bg-parallax .st3,
.control-scroll-fx-strip-bg-parallax .symbol-scroll-fx-strip-bg-parallax .st3 {
  fill: #fff; }

.symbol-scroll-fx-strip-bg-parallax .st4,
.control-scroll-fx-strip-bg-parallax .symbol-scroll-fx-strip-bg-parallax .st4 {
  fill: #4eb7f5; }

.symbol-scroll-fx-strip-bg-parallax .st5,
.control-scroll-fx-strip-bg-parallax .symbol-scroll-fx-strip-bg-parallax .st5 {
  fill: #7fccf7; }

.symbol-scroll-fx-strip-bg-parallax .st6,
.control-scroll-fx-strip-bg-parallax .symbol-scroll-fx-strip-bg-parallax .st6 {
  fill: #4eb7f5;
  opacity: 0.9; }

.symbol-scroll-fx-strip-bg-parallax .st7,
.control-scroll-fx-strip-bg-parallax .symbol-scroll-fx-strip-bg-parallax .st7 {
  fill: #d3edff; }

.selected .symbol-scroll-fx-strip-bg-parallax .st1,
.control-scroll-fx-strip-bg-parallax .input-scroll-fx-strip-bg-parallax:checked ~ .symbol-scroll-fx-strip-bg-parallax .st1 {
  fill: #4eb7f5;
  opacity: 0.7; }

.selected .symbol-scroll-fx-strip-bg-parallax .st2,
.control-scroll-fx-strip-bg-parallax .input-scroll-fx-strip-bg-parallax:checked ~ .symbol-scroll-fx-strip-bg-parallax .st2 {
  fill: #7fccf7;
  opacity: 0.6; }

.selected .symbol-scroll-fx-strip-bg-parallax .st3,
.control-scroll-fx-strip-bg-parallax .input-scroll-fx-strip-bg-parallax:checked ~ .symbol-scroll-fx-strip-bg-parallax .st3 {
  fill: #7fccf7; }

.selected .symbol-scroll-fx-strip-bg-parallax .st4,
.control-scroll-fx-strip-bg-parallax .input-scroll-fx-strip-bg-parallax:checked ~ .symbol-scroll-fx-strip-bg-parallax .st4 {
  fill: #d3edff; }

.selected .symbol-scroll-fx-strip-bg-parallax .st5,
.control-scroll-fx-strip-bg-parallax .input-scroll-fx-strip-bg-parallax:checked ~ .symbol-scroll-fx-strip-bg-parallax .st5 {
  fill: #7fccf7; }

.selected .symbol-scroll-fx-strip-bg-parallax .st6,
.control-scroll-fx-strip-bg-parallax .input-scroll-fx-strip-bg-parallax:checked ~ .symbol-scroll-fx-strip-bg-parallax .st6 {
  fill: #d3edff;
  opacity: 0.9; }

.selected .symbol-scroll-fx-strip-bg-parallax .st7,
.control-scroll-fx-strip-bg-parallax .input-scroll-fx-strip-bg-parallax:checked ~ .symbol-scroll-fx-strip-bg-parallax .st7 {
  fill: #7fccf7; }

.symbol-scroll-fx-strip-bg-zoom .st1,
.control-scroll-fx-strip-bg-zoom .symbol-scroll-fx-strip-bg-zoom .st1 {
  fill: #5FBEF7; }

.symbol-scroll-fx-strip-bg-zoom .st2,
.control-scroll-fx-strip-bg-zoom .symbol-scroll-fx-strip-bg-zoom .st2 {
  fill: #A5DAFB; }

.symbol-scroll-fx-strip-bg-zoom .st3,
.control-scroll-fx-strip-bg-zoom .symbol-scroll-fx-strip-bg-zoom .st3 {
  fill: #d3edff; }

.symbol-scroll-fx-strip-bg-zoom .st4,
.control-scroll-fx-strip-bg-zoom .symbol-scroll-fx-strip-bg-zoom .st4 {
  fill: #7fccf7; }

.selected .symbol-scroll-fx-strip-bg-zoom .st1,
.control-scroll-fx-strip-bg-zoom .input-scroll-fx-strip-bg-zoom:checked ~ .symbol-scroll-fx-strip-bg-zoom .st1 {
  fill: #d3edff;
  opacity: 0.9; }

.selected .symbol-scroll-fx-strip-bg-zoom .st2,
.control-scroll-fx-strip-bg-zoom .input-scroll-fx-strip-bg-zoom:checked ~ .symbol-scroll-fx-strip-bg-zoom .st2 {
  fill: #7fccf7; }

.selected .symbol-scroll-fx-strip-bg-zoom .st3,
.control-scroll-fx-strip-bg-zoom .input-scroll-fx-strip-bg-zoom:checked ~ .symbol-scroll-fx-strip-bg-zoom .st3 {
  fill: #4eb7f5; }

.selected .symbol-scroll-fx-strip-bg-zoom .st4,
.control-scroll-fx-strip-bg-zoom .input-scroll-fx-strip-bg-zoom:checked ~ .symbol-scroll-fx-strip-bg-zoom .st4 {
  fill: #D8EFFF; }

.symbol-scroll-fx-strip-bg-fade .st1,
.control-scroll-fx-strip-bg-fade .symbol-scroll-fx-strip-bg-fade .st1 {
  fill: #5FBEF7;
  opacity: 0.4; }

.symbol-scroll-fx-strip-bg-fade .st2,
.control-scroll-fx-strip-bg-fade .symbol-scroll-fx-strip-bg-fade .st2 {
  fill: #d3edff; }

.symbol-scroll-fx-strip-bg-fade .st3,
.control-scroll-fx-strip-bg-fade .symbol-scroll-fx-strip-bg-fade .st3 {
  fill: #d3edff;
  opacity: 0.6; }

.symbol-scroll-fx-strip-bg-fade .st4,
.control-scroll-fx-strip-bg-fade .symbol-scroll-fx-strip-bg-fade .st4 {
  fill: #5FBEF7; }

.symbol-scroll-fx-strip-bg-fade .st5,
.control-scroll-fx-strip-bg-fade .symbol-scroll-fx-strip-bg-fade .st5 {
  fill: #A5DAFB; }

.selected .symbol-scroll-fx-strip-bg-fade .st1,
.control-scroll-fx-strip-bg-fade .input-scroll-fx-strip-bg-fade:checked ~ .symbol-scroll-fx-strip-bg-fade .st1 {
  fill: #d3edff;
  opacity: 0.4; }

.selected .symbol-scroll-fx-strip-bg-fade .st2,
.control-scroll-fx-strip-bg-fade .input-scroll-fx-strip-bg-fade:checked ~ .symbol-scroll-fx-strip-bg-fade .st2 {
  fill: #4eb7f5;
  opacity: 0.5; }

.selected .symbol-scroll-fx-strip-bg-fade .st3,
.control-scroll-fx-strip-bg-fade .input-scroll-fx-strip-bg-fade:checked ~ .symbol-scroll-fx-strip-bg-fade .st3 {
  fill: #4eb7f5;
  opacity: 0.4; }

.selected .symbol-scroll-fx-strip-bg-fade .st4,
.control-scroll-fx-strip-bg-fade .input-scroll-fx-strip-bg-fade:checked ~ .symbol-scroll-fx-strip-bg-fade .st4 {
  fill: #d3edff;
  opacity: 0.9; }

.selected .symbol-scroll-fx-strip-bg-fade .st5,
.control-scroll-fx-strip-bg-fade .input-scroll-fx-strip-bg-fade:checked ~ .symbol-scroll-fx-strip-bg-fade .st5 {
  fill: #7fccf7; }

.symbol-scroll-fx-strip-bg-blur .st1,
.control-scroll-fx-strip-bg-blur .symbol-scroll-fx-strip-bg-blur .st1 {
  fill: #5FBEF7; }

.symbol-scroll-fx-strip-bg-blur .st2,
.control-scroll-fx-strip-bg-blur .symbol-scroll-fx-strip-bg-blur .st2 {
  fill: #A5DAFB; }

.symbol-scroll-fx-strip-bg-blur .st3,
.control-scroll-fx-strip-bg-blur .symbol-scroll-fx-strip-bg-blur .st3 {
  fill: #d3edff; }

.selected .symbol-scroll-fx-strip-bg-blur .st1,
.control-scroll-fx-strip-bg-blur .input-scroll-fx-strip-bg-blur:checked ~ .symbol-scroll-fx-strip-bg-blur .st1 {
  fill: #d3edff;
  opacity: 0.9; }

.selected .symbol-scroll-fx-strip-bg-blur .st2,
.control-scroll-fx-strip-bg-blur .input-scroll-fx-strip-bg-blur:checked ~ .symbol-scroll-fx-strip-bg-blur .st2 {
  fill: #7fccf7; }

.selected .symbol-scroll-fx-strip-bg-blur .st3,
.control-scroll-fx-strip-bg-blur .input-scroll-fx-strip-bg-blur:checked ~ .symbol-scroll-fx-strip-bg-blur .st3 {
  fill: #4eb7f5; }

.wix-store-manage-panel .symbol-onlineStoreManager {
  margin-top: 30px;
  margin-bottom: 14px; }

.symbol-somethingWentWrong .blue1 {
  fill: #E9F6FF; }

.symbol-somethingWentWrong .blue2 {
  fill: #D3EDFF;
  opacity: 0.95; }

.symbol-somethingWentWrong .blue3 {
  fill: #5FCEF5; }

.symbol-somethingWentWrong .white {
  fill: #FFFFFF; }

.symbol-somethingWentWrong .gradient1 {
  fill: url(#gradient1); }

.symbol-newStorePreviewReady .blue1 {
  fill: #E9F6FF; }

.symbol-newStorePreviewReady .blue2 {
  fill: #D3EDFF; }

.symbol-newStorePreviewReady .blue3 {
  fill: #5FCEF5; }

.symbol-newStorePreviewReady .blue4 {
  fill: #A7E3FA; }

.symbol-newStorePreviewReady .blue5 {
  fill: #4EB7F5; }

.symbol-newStorePreviewReady .white {
  fill: #FFFFFF; }

.symbol-newStorePreviewReady .opacity95 {
  opacity: 0.95; }

.symbol-router_icon {
  margin-top: 3px;
  margin-left: -1px; }

.symbol-delete_icon {
  margin-top: 3px;
  margin-left: -1px; }

.symbol-unlink_icon {
  margin-top: 3px;
  margin-left: -4px; }

.symbol-page_dynamic_icon {
  margin-top: 3px;
  margin-left: -1px; }

.symbol-edit_icon {
  margin-top: 3px;
  margin-left: -1px; }

.control-thumbnails-list[data-max-items-per-row="3"] .symbol {
  width: 72px;
  height: 72px; }

.control-thumbnails-list[data-max-items-per-row="4"] .symbol {
  width: 51px;
  height: 51px; }

.media-preview-panel {
  width: 270px;
  height: 176px;
  overflow: hidden;
  margin: 12px 12px 4px 12px; }
  .media-preview-panel .hide * {
    visibility: hidden; }
  .media-preview-panel .show * {
    visibility: visible; }
  .media-preview-panel .media-preview-wrapper {
    position: relative;
    background-color: #000;
    height: 152px;
    width: 270px;
    overflow: hidden;
    border: 1px solid #eaf7ff; }
    .media-preview-panel .media-preview-wrapper .media-preview-color {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .media-preview-panel .media-preview-wrapper .media-preview-image {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%; }
    .media-preview-panel .media-preview-wrapper .media-preview-video-wrapper {
      position: absolute;
      top: 0;
      left: 0; }
      .media-preview-panel .media-preview-wrapper .media-preview-video-wrapper .media-preview-video {
        width: 100%;
        height: 100%; }
    .media-preview-panel .media-preview-wrapper .media-preview-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .media-preview-panel .media-preview-caption {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 13px;
    padding: 3px 0 2px;
    line-height: 20px;
    color: #162d3d; }
    .media-preview-panel .media-preview-caption .media-title {
      width: 87%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      float: left; }
    .media-preview-panel .media-preview-caption .video-duration {
      text-align: right;
      width: 12%;
      float: right;
      font-size: 0.9em; }

.bg-preset-list-section .preset-list-section-title {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  color: #2b5672;
  border-bottom: 1px solid #2b5672;
  font-weight: normal;
  margin-bottom: 16px;
  line-height: 1em;
  padding-bottom: 4px;
  position: relative; }

.bg-preset-list-section .section-header {
  margin-bottom: 22px; }

.bg-preset-list-section .preset-list-section-list {
  text-align: center; }

.bg-preset-list-section .preset-list-item {
  font-size: 0;
  margin-bottom: 10px;
  text-align: center;
  width: 100%; }

.bg-preset-list-section .columns-2 .preset-list-item {
  text-align: left;
  display: inline-block;
  width: 50%; }
  .bg-preset-list-section .columns-2 .preset-list-item:nth-child(2n) {
    text-align: right; }

.bg-preset-list-section .columns-3 .preset-list-item {
  text-align: left;
  display: inline-block;
  width: 33%; }
  .bg-preset-list-section .columns-3 .preset-list-item:nth-child(2), .bg-preset-list-section .columns-3 .preset-list-item:nth-child(3n + 2) {
    text-align: center; }
  .bg-preset-list-section .columns-3 .preset-list-item:nth-child(3n) {
    text-align: right; }

.bg-preset-list-section .preset-list-preset {
  font-size: 12px;
  border: 1px solid #eaf7ff;
  text-align: center;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  display: inline-block; }
  .bg-preset-list-section .preset-list-preset.selected {
    box-shadow: 0 0 0 2px #3899ec; }
  .bg-preset-list-section .preset-list-preset .preset-list-preset-content {
    position: absolute; }
  .bg-preset-list-section .preset-list-preset .preset-title {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    text-align: center;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  .bg-preset-list-section .preset-list-preset .preset-list-preset-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .bg-preset-list-section .preset-list-preset .preset-list-preset-video-controls {
    height: 100%;
    background: linear-gradient(to top, #555555 0, rgba(85, 85, 85, 0) 14px, transparent 15px); }
    .bg-preset-list-section .preset-list-preset .preset-list-preset-video-controls .symbol-bgVideoIndicator {
      width: 14px;
      height: 9px;
      left: 3px;
      bottom: 3px;
      position: absolute; }

.wix-code-file-editor {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column; }
  .wix-code-file-editor > .editor-body {
    display: flex;
    flex: 1;
    overflow: hidden;
    width: 100%;
    background-color: #f0f3f5;
    border-top: 1px solid #d9e1e8;
    box-sizing: border-box; }

.wix-code-page-editor {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column; }
  .wix-code-page-editor > .editor-body {
    display: flex;
    flex: 1;
    overflow: hidden;
    width: 100%;
    background-color: #f0f3f5;
    border-top: 1px solid #d9e1e8;
    box-sizing: border-box; }
    .wix-code-page-editor > .editor-body > .tabs {
      display: flex;
      flex-direction: column;
      width: 42px;
      min-height: 100px;
      background-color: #ffffff; }
      .wix-code-page-editor > .editor-body > .tabs > .tab-item {
        cursor: pointer;
        flex: 1;
        position: relative;
        border-right: 1px solid #d3edff;
        border-top: 1px solid #d3edff; }
        .wix-code-page-editor > .editor-body > .tabs > .tab-item:first-child {
          border-top: 0; }
        .wix-code-page-editor > .editor-body > .tabs > .tab-item:hover {
          background-color: rgba(240, 243, 245, 0.5); }
        .wix-code-page-editor > .editor-body > .tabs > .tab-item.is-selected {
          cursor: initial;
          background-color: #f0f3f5;
          border-right: 0; }
          .wix-code-page-editor > .editor-body > .tabs > .tab-item.is-selected:hover {
            background-color: #f0f3f5; }
          .wix-code-page-editor > .editor-body > .tabs > .tab-item.is-selected > .text {
            color: #162d3d; }
        .wix-code-page-editor > .editor-body > .tabs > .tab-item > .text {
          font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-size: 14px;
          color: #3899ec;
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%) rotate(-90deg); }

.wix-code-top-bar {
  background-color: #ffffff; }
  .wix-code-top-bar > .tool-bar {
    height: 36px;
    line-height: 36px;
    text-align: center;
    position: relative; }
    .wix-code-top-bar > .tool-bar > .tree-button-container {
      position: absolute;
      left: 0;
      top: 0; }
    .wix-code-top-bar > .tool-bar > .text {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      color: #2d4150; }
    .wix-code-top-bar > .tool-bar > .actions-container {
      height: 100%;
      position: absolute;
      right: 0;
      top: 0; }
      .wix-code-top-bar > .tool-bar > .actions-container > .search-button-container {
        display: inline-block; }

.wix-code-find-replace {
  height: 48px;
  box-sizing: border-box;
  border-top: 1px solid #3899ec;
  padding: 9px 24px 8px 68px;
  display: flex;
  align-items: center; }
  .wix-code-find-replace > .find-button {
    margin-right: 12px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #eaf7ff;
    cursor: pointer;
    display: inline-flex;
    vertical-align: top;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0; }
    .wix-code-find-replace > .find-button svg {
      fill: #3899ec; }
    .wix-code-find-replace > .find-button:hover {
      background-color: #d3edff; }
    .wix-code-find-replace > .find-button.previous {
      margin-right: 24px; }
  .wix-code-find-replace > .separator {
    display: inline-block;
    height: 100%;
    border-left: 1px solid #d6e1e8;
    width: 1px; }
  .wix-code-find-replace > .find-replace-input {
    display: flex;
    align-items: center;
    width: initial;
    margin-right: 12px; }
  .wix-code-find-replace > .replace-with-input {
    margin-left: 12px; }
  .wix-code-find-replace > .replace-button {
    flex-shrink: 0;
    height: 24px;
    margin-right: 12px; }
  .wix-code-find-replace > .replace-all-button {
    flex-shrink: 0;
    height: 24px;
    margin-right: 24px; }
  .wix-code-find-replace .control-regex {
    background-color: #eaf7ff;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    margin-left: 24px; }
    .wix-code-find-replace .control-regex:hover {
      background-color: #d3edff; }
    .wix-code-find-replace .control-regex:active path, .wix-code-find-replace .control-regex.checked path {
      fill: #ffffff; }
  .wix-code-find-replace .control-caseSensitive {
    background-color: #eaf7ff;
    width: 24px;
    height: 24px;
    margin-right: 12px; }
    .wix-code-find-replace .control-caseSensitive:hover {
      background-color: #d3edff; }
    .wix-code-find-replace .control-caseSensitive:active path, .wix-code-find-replace .control-caseSensitive.checked path {
      fill: #ffffff; }
  .wix-code-find-replace .control-wholeWord {
    background-color: #eaf7ff;
    width: 24px;
    height: 24px; }
    .wix-code-find-replace .control-wholeWord:hover {
      background-color: #d3edff; }
    .wix-code-find-replace .control-wholeWord:active path, .wix-code-find-replace .control-wholeWord.checked path {
      fill: #ffffff; }

.wix-code-page-editor-overlay {
  background-color: #f0f3f5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; }
  .wix-code-page-editor-overlay > .message-container {
    max-width: 50%;
    text-align: center; }
    .wix-code-page-editor-overlay > .message-container > .lead {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 20px;
      font-weight: 300;
      color: #2b5672; }
    .wix-code-page-editor-overlay > .message-container > .sub {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 16px;
      color: #7a92a5; }
    .wix-code-page-editor-overlay > .message-container > .circle-preloader {
      position: inherit;
      margin: 0 auto; }

.wix-code-code-editor-container {
  display: flex;
  flex: 1;
  width: 100%; }

.wix-code-ide .annotationRange.warning {
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/wixCode/codeEditor/range-warning.png"); }

.wix-code-ide .annotationRange.error {
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/wixCode/codeEditor/range-error.png"); }

.wix-code-ide .annotationHTML.warning {
  margin-top: -2px;
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/wixCode/codeEditor/gutter-warning.v2.png"); }

.wix-code-ide .annotationHTML.error {
  margin-top: -2px;
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/wixCode/codeEditor/gutter-error.png"); }

.wix-code-ide .wix-code-page-editor .wix-code-code-editor {
  flex: 1;
  position: relative; }

.wix-code-ide .wix-code-file-editor .wix-code-code-editor {
  flex: 1;
  position: relative; }

.wix-code-ide .wix-code-code-editor > .orion-code-editor {
  position: absolute;
  height: 100%;
  width: 100%; }
  .wix-code-ide .wix-code-code-editor > .orion-code-editor > .santa-theme.textview {
    background-color: #f0f3f5; }
  .wix-code-ide .wix-code-code-editor > .orion-code-editor > .santa-theme .textviewContent {
    -moz-osx-font-smoothing: initial;
    -webkit-font-smoothing: initial; }
    .wix-code-ide .wix-code-code-editor > .orion-code-editor > .santa-theme .textviewContent span {
      user-select: text !important; }
  .wix-code-ide .wix-code-code-editor > .orion-code-editor > .santa-theme .textviewLeftRuler {
    border-right: 0; }
  .wix-code-ide .wix-code-code-editor > .orion-code-editor > .santa-theme .rulerLines {
    color: #7192a6; }
  .wix-code-ide .wix-code-code-editor > .orion-code-editor > .santa-theme .annotationLine.currentLine {
    background-color: rgba(217, 225, 232, 0.33); }
  .wix-code-ide .wix-code-code-editor > .orion-code-editor > .santa-theme .string.interpolated {
    color: initial; }

.wix-code-ide .wix-code-code-editor .textviewTooltip {
  font: 14px HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  background-color: #ffffff;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.14);
  color: #7a92a5;
  padding: 14px 16px;
  border-radius: 8px;
  resize: none; }
  .wix-code-ide .wix-code-code-editor .textviewTooltip a {
    color: #3899ec; }
  .wix-code-ide .wix-code-code-editor .textviewTooltip multi_anno {
    color: #2b5672; }
  .wix-code-ide .wix-code-code-editor .textviewTooltip .commandButton {
    border: 0;
    background-color: #ffffff;
    color: #3899ec; }
  .wix-code-ide .wix-code-code-editor .textviewTooltip .tooltipRow {
    line-height: 24px; }
  .wix-code-ide .wix-code-code-editor .textviewTooltip .media-hover-image {
    display: block;
    margin: auto; }
  .wix-code-ide .wix-code-code-editor .textviewTooltip .media-hover-link {
    cursor: pointer;
    color: #3899ec; }
  .wix-code-ide .wix-code-code-editor .textviewTooltip .media-hover-suggestion {
    text-align: center;
    margin-top: 5px; }

.wix-code-content-assist {
  font: 14px HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  display: none;
  position: fixed;
  color: #072d3e;
  background-color: #ffffff;
  width: 370px;
  height: 186px;
  border-radius: 8px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.14);
  line-height: 24px;
  z-index: 102;
  cursor: default;
  min-width: 70px; }
  .wix-code-content-assist.clone-wrapper {
    display: block;
    height: 0;
    overflow: visible;
    visibility: hidden;
    width: 0;
    z-index: 103; }
    .wix-code-content-assist.clone-wrapper:hover {
      overflow: visible; }
  .wix-code-content-assist:focus {
    outline: none; }
  .wix-code-content-assist .clone-proposal {
    box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 8px;
    position: fixed;
    visibility: visible;
    z-index: 1000;
    background: #0099ef;
    color: #ffffff;
    padding-left: 17px;
    padding-right: 6px; }
  .wix-code-content-assist .proposal-container {
    white-space: nowrap;
    width: 210px;
    min-width: 210px;
    height: 174px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 6px;
    margin-bottom: 6px; }
    .wix-code-content-assist .proposal-container .proposal-emphasis {
      font-weight: normal; }
    .wix-code-content-assist .proposal-container hr {
      border: 0;
      height: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
    .wix-code-content-assist .proposal-container > div {
      padding: 0 3px 0 17px;
      margin-bottom: 6px; }
      .wix-code-content-assist .proposal-container > div:hover {
        background-color: #eaf7ff; }
      .wix-code-content-assist .proposal-container > div:nth-last-child(2) {
        margin-bottom: 0; }
      .wix-code-content-assist .proposal-container > div.selected {
        background: #0099ef;
        border-radius: 0;
        color: #ffffff; }
    .wix-code-content-assist .proposal-container > .proposal-hr {
      display: none; }
    .wix-code-content-assist .proposal-container > .proposal-noemphasis-title {
      background-color: #ffffff;
      color: #7a92a5;
      padding-left: 11px; }
    .wix-code-content-assist .proposal-container .proposal-name {
      font-weight: 300; }
  .wix-code-content-assist .docs-container {
    white-space: normal;
    border-left: 1px solid #b6c1cd;
    padding-left: 12px;
    padding-top: 12px;
    padding-right: 12px;
    overflow-y: auto; }
    .wix-code-content-assist .docs-container p {
      word-wrap: break-word; }
      .wix-code-content-assist .docs-container p:first-of-type {
        margin-top: 0;
        margin-bottom: 0; }
    .wix-code-content-assist .docs-container a {
      color: #3899ec;
      text-decoration: none; }

.wix-code-ide .textViewFind {
  background-color: #ffffff !important;
  height: 47px;
  right: 0 !important;
  border-color: #d6e1e8 !important; }
  .wix-code-ide .textViewFind > input {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    height: 24px;
    font-weight: 700;
    box-sizing: border-box;
    padding: 0 11px 0;
    border: 1px solid #4eb7f5;
    outline: none;
    border-radius: 8px;
    color: #162d3d;
    text-align: left;
    text-overflow: ellipsis;
    margin-top: 12px;
    margin-left: 24px; }
    .wix-code-ide .textViewFind > input:focus {
      background-color: transparent;
      box-shadow: inset 0 0 3px 0 rgba(0, 90, 132, 0.25); }
    .wix-code-ide .textViewFind > input:hover {
      background-color: #d3edff;
      color: #2b5672; }
  .wix-code-ide .textViewFind .textViewFindButton:last-child {
    margin-right: 0; }
  .wix-code-ide .textViewFind > span {
    display: inline-block !important;
    height: 30px;
    margin-left: 12px;
    vertical-align: middle; }
    .wix-code-ide .textViewFind > span .textViewFindButton {
      border-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      height: 24px;
      margin-left: 12px; }
    .wix-code-ide .textViewFind > span .textViewFindButton:focus {
      outline: 0; }
    .wix-code-ide .textViewFind > span .textViewFindButton:first-child {
      margin-left: 0; }
  .wix-code-ide .textViewFind > span:first-of-type {
    border-right: 1px solid #d6e1e8;
    padding-right: 24px; }
    .wix-code-ide .textViewFind > span:first-of-type .textViewFindButton {
      border-radius: 50%;
      background-color: #eaf7ff;
      width: 24px;
      color: transparent; }
      .wix-code-ide .textViewFind > span:first-of-type .textViewFindButton:hover {
        background-color: #d3edff; }
    .wix-code-ide .textViewFind > span:first-of-type .textViewFindButton:nth-child(1) {
      background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/wixCode/findReplace/ide_find_next.png"); }
    .wix-code-ide .textViewFind > span:first-of-type .textViewFindButton:nth-child(2) {
      background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/wixCode/findReplace/ide_find_previous.png"); }
  .wix-code-ide .textViewFind > span:nth-of-type(2) > .textViewFindButton {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    background-color: #eaf7ff;
    color: #3899ec;
    text-align: left;
    border-radius: 25px;
    padding-left: 18px;
    padding-right: 18px; }
    .wix-code-ide .textViewFind > span:nth-of-type(2) > .textViewFindButton:hover {
      background-color: #d3edff; }
  .wix-code-ide .textViewFind > span:nth-of-type(3) {
    padding-left: 24px;
    border-left: 1px solid #d6e1e8; }
    .wix-code-ide .textViewFind > span:nth-of-type(3) > .textViewFindButton {
      border-radius: 6px;
      background-color: #eaf7ff;
      width: 24px;
      color: transparent; }
      .wix-code-ide .textViewFind > span:nth-of-type(3) > .textViewFindButton:hover {
        background-color: #d3edff; }
      .wix-code-ide .textViewFind > span:nth-of-type(3) > .textViewFindButton.checked {
        background-color: #3899ec; }
    .wix-code-ide .textViewFind > span:nth-of-type(3) > .textViewFindButton:nth-child(1) {
      background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/wixCode/findReplace/ide_find_regex.png") !important; }
      .wix-code-ide .textViewFind > span:nth-of-type(3) > .textViewFindButton:nth-child(1):hover {
        background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/wixCode/findReplace/ide_find_regex_hover.png") !important; }
      .wix-code-ide .textViewFind > span:nth-of-type(3) > .textViewFindButton:nth-child(1).checked {
        background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/wixCode/findReplace/ide_find_regex_selected.png") !important; }
    .wix-code-ide .textViewFind > span:nth-of-type(3) > .textViewFindButton:nth-child(2) {
      background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/wixCode/findReplace/ide_find_case_sensitive.png") !important; }
      .wix-code-ide .textViewFind > span:nth-of-type(3) > .textViewFindButton:nth-child(2):hover {
        background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/wixCode/findReplace/ide_find_case_sensitive_hover.png") !important; }
      .wix-code-ide .textViewFind > span:nth-of-type(3) > .textViewFindButton:nth-child(2).checked {
        background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/wixCode/findReplace/ide_find_case_sensitive_selected.png") !important; }
    .wix-code-ide .textViewFind > span:nth-of-type(3) > .textViewFindButton:nth-child(3) {
      background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/wixCode/findReplace/ide_find_whole_word.png") !important; }
      .wix-code-ide .textViewFind > span:nth-of-type(3) > .textViewFindButton:nth-child(3):hover {
        background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/wixCode/findReplace/ide_find_whole_word_hover.png") !important; }
      .wix-code-ide .textViewFind > span:nth-of-type(3) > .textViewFindButton:nth-child(3).checked {
        background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/wixCode/findReplace/ide_find_whole_word_selected.png") !important; }

.wix-code-ide {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box; }

.wix-code-site-structure {
  box-sizing: border-box;
  border-right: 1px solid #3899ec;
  display: flex;
  flex-direction: column;
  height: 100%; }
  .wix-code-site-structure > .wix-code-ide-tree {
    width: 100%;
    overflow: auto;
    flex: 1; }
    .wix-code-site-structure > .wix-code-ide-tree > .title {
      color: #162d3d;
      font-size: 16px;
      padding: 12px;
      border-bottom: 1px solid #d9e1e8; }
    .wix-code-site-structure > .wix-code-ide-tree > .wix-code-pages-tree,
    .wix-code-site-structure > .wix-code-ide-tree > .wix-code-file-tree {
      margin: 5px; }

.wix-code-pages-tree > .node-content {
  display: flex;
  box-sizing: border-box;
  height: 24px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 6px;
  margin-top: 3px;
  margin-bottom: 3px;
  border-radius: 12px;
  white-space: nowrap; }
  .root.wix-code-pages-tree > .node-content {
    padding-left: 0; }
  .wix-code-pages-tree > .node-content.selectable {
    cursor: pointer; }
  .wix-code-pages-tree > .node-content.selectable:hover {
    background-color: #eaf7ff; }
  .wix-code-pages-tree > .node-content.selected {
    background-color: #f0f3f5; }
    .wix-code-pages-tree > .node-content.selected:hover {
      background-color: #f0f3f5; }
  .wix-code-pages-tree > .node-content > .icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    text-align: center;
    position: relative; }
  .wix-code-pages-tree > .node-content > .label {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.wix-code-pages-tree > .subtree {
  margin-left: 18px; }
  .root.wix-code-pages-tree > .subtree {
    margin-left: 10px; }

.wix-code-pages-tree > .node-content {
  cursor: default; }
  .root.wix-code-pages-tree > .node-content {
    cursor: pointer; }

.wix-code-file-tree > .node-content {
  display: flex;
  box-sizing: border-box;
  height: 24px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 6px;
  margin-top: 3px;
  margin-bottom: 3px;
  border-radius: 12px;
  white-space: nowrap; }
  .root.wix-code-file-tree > .node-content {
    padding-left: 0; }
  .wix-code-file-tree > .node-content.selectable {
    cursor: pointer; }
  .wix-code-file-tree > .node-content.selectable:hover {
    background-color: #eaf7ff; }
  .wix-code-file-tree > .node-content.selected {
    background-color: #f0f3f5; }
    .wix-code-file-tree > .node-content.selected:hover {
      background-color: #f0f3f5; }
  .wix-code-file-tree > .node-content > .icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    text-align: center;
    position: relative; }
  .wix-code-file-tree > .node-content > .label {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.wix-code-file-tree > .subtree {
  margin-left: 18px; }
  .root.wix-code-file-tree > .subtree {
    margin-left: 10px; }

.wix-code-file-tree > .node-content {
  cursor: pointer; }
  .wix-code-file-tree > .node-content.pending {
    cursor: progress; }

.wix-code-file-tree > .subtree:empty {
  padding-top: 6px; }

.wix-code-file-tree > .subtree > .empty-dir-message {
  color: #2b5672;
  padding: 3px 0;
  margin-left: 12px; }

.wix-code-context-menu-item {
  color: #2b5672; }
  .wix-code-context-menu-item > .icon {
    width: 14px;
    display: inline-block;
    text-align: center; }
  .wix-code-context-menu-item > .label {
    padding-left: 9px;
    vertical-align: top; }

.component-event {
  display: flex;
  position: relative; }
  .component-event.add-event-symbol {
    justify-content: space-between; }
    .component-event.add-event-symbol .plusIcon,
    .component-event.add-event-symbol .infoIcon {
      margin-top: 5px;
      cursor: pointer;
      width: 18px;
      height: 18px; }
    .component-event.add-event-symbol .plusIcon {
      fill: #3899ec;
      margin-left: 9px; }
    .component-event.add-event-symbol .infoIcon circle {
      fill: transparent; }
    .component-event.add-event-symbol .infoIcon path {
      fill: #7a92a5; }
    .component-event.add-event-symbol .add-event-wrapper {
      visibility: hidden;
      position: absolute;
      right: 0; }
  .component-event.add-event-label {
    justify-content: flex-start; }
    .component-event.add-event-label label {
      margin-top: 2px;
      cursor: pointer;
      flex: 1;
      font-size: 14px;
      font-weight: 700; }
  .component-event .delete-handler {
    display: none;
    padding-top: 4px; }
    .component-event .delete-handler path {
      fill: #2b5672; }
  .component-event .event-label {
    padding-right: 6px; }
  .component-event .event-handler-wrapper {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1; }
  .component-event:hover .add-event-wrapper {
    visibility: visible; }
  .component-event:hover .delete-handler {
    display: initial;
    cursor: pointer; }
  .component-event .handler-name:hover {
    color: #3899ec; }

.events-binding .events-title {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  display: block;
  padding: 10px 0;
  color: #7a92a5; }

.developer-toolbar {
  box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.36);
  position: fixed;
  border-radius: 8px;
  background-color: #fff;
  width: 222px;
  min-height: 312px;
  z-index: 1055;
  right: 10px;
  padding-bottom: 18px; }
  .developer-toolbar.hidden {
    display: none; }
  .developer-toolbar .checkBoxContainer {
    padding: 6px 0 6px; }
  .developer-toolbar .header-seperator {
    margin: 0 0 6px 0;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #f0f3f5; }
  .developer-toolbar .seperator {
    margin: 9px 0;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #f0f3f5; }
  .developer-toolbar .header {
    height: 42px;
    min-height: 42px;
    border-radius: 50%;
    cursor: move; }
  .developer-toolbar .inner {
    text-align: center;
    display: block; }
    .developer-toolbar .inner.form {
      text-align: left;
      padding: 0 12px 10px 12px; }
  .developer-toolbar .empty-state-text {
    margin: 0;
    padding: 10px 0; }
  .developer-toolbar .empty-state-symbol {
    position: absolute;
    left: 55px;
    width: 113px;
    height: 113px;
    top: 140px; }
  .developer-toolbar .layout-title {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 12px;
    font-size: 14px;
    text-align: center;
    color: #2b5672;
    line-height: 24px;
    overflow: hidden; }
  .developer-toolbar .panel-header .panel-header-title {
    padding-left: 0;
    line-height: 42px; }
  .developer-toolbar .panel-header > .control-button {
    width: 23px;
    height: 23px; }

.wix-code-ide-toolbar-button {
  vertical-align: top;
  border-left: 1px solid #d6e1e8;
  background-color: #ffffff;
  width: 42px;
  cursor: pointer;
  height: 36px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .wix-code-ide-toolbar-button:hover:not(.disabled) svg path {
    fill: #3899ec; }
  .wix-code-ide-toolbar-button.disabled {
    cursor: default; }
  .wix-code-top-bar > .tool-bar > .tree-button-container .wix-code-ide-toolbar-button {
    border-left: 0;
    border-right: 1px solid #d6e1e8;
    box-sizing: border-box; }
    .wix-code-top-bar > .tool-bar > .tree-button-container .wix-code-ide-toolbar-button svg path {
      fill: #3899ec; }
    .wix-code-top-bar > .tool-bar > .tree-button-container .wix-code-ide-toolbar-button:hover {
      background-color: #eaf7ff; }
  .wix-code-top-bar > .tool-bar > .actions-container > .search-button-container.selected .wix-code-ide-toolbar-button {
    background-color: #3899ec; }
    .wix-code-top-bar > .tool-bar > .actions-container > .search-button-container.selected .wix-code-ide-toolbar-button svg path {
      fill: #ffffff; }

.wix-code-sizing-button-container {
  display: inline-block; }

.wix-code-delete-file-message {
  padding: 30px; }
  .wix-code-delete-file-message .icon {
    display: inline-block; }
  .wix-code-delete-file-message .message {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #162d3d;
    font-size: 18px;
    display: inline-block;
    line-height: 22px;
    vertical-align: top;
    max-width: 325px;
    margin-left: 30px; }

.wix-code-fs-operation-error {
  padding: 30px; }
  .wix-code-fs-operation-error .icon {
    display: inline-block; }
  .wix-code-fs-operation-error .message {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #162d3d;
    font-size: 18px;
    display: inline-block;
    line-height: 22px;
    vertical-align: top;
    max-width: 325px;
    margin-left: 30px; }

.wix-code-console-message {
  font-family: "Consolas", "Monaco", "Vera Mono", monospace;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px; }
  .wix-code-console-message.info, .wix-code-console-message.log {
    color: #162d3d; }
  .wix-code-console-message.error {
    color: #ee5951; }
  .wix-code-console-message.warning {
    color: #ed9930; }
  .wix-code-console-message > .icon {
    text-align: center;
    width: 27px; }
    .wix-code-console-message > .icon > .symbol {
      vertical-align: top;
      padding-top: 3px; }
  .wix-code-console-message > .message {
    -moz-user-select: text;
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
    overflow: hidden;
    word-wrap: break-word;
    width: 100%; }
  .wix-code-console-message > .called-from {
    text-align: right; }

.wix-code-console {
  background-color: #f0f3f5;
  border-top: 1px solid #d9e1e8;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%; }
  .wix-code-console > .wix-code-top-bar {
    border-bottom: 1px #d6e1e8 solid; }
  .wix-code-console > .console {
    width: 100%;
    height: calc(100% - 36px);
    overflow: hidden; }
    .wix-code-console > .console > .no-logs-to-show {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%; }
      .wix-code-console > .console > .no-logs-to-show > .title {
        color: #2b5672;
        font-size: 24px;
        font-weight: 500;
        line-height: 24px;
        display: flex;
        margin-top: 0; }
    .wix-code-console > .console .message-container {
      padding-top: 9px; }

.wix-code-exposure {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  height: 36px;
  background-color: #eaf7ff;
  color: #3899ec;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.14);
  cursor: pointer;
  position: relative; }
  .wix-code-exposure:hover {
    background-color: #d3edff; }
  .wix-code-exposure > .exposure-label {
    padding: 10px;
    text-align: center; }
    .wix-code-exposure > .exposure-label.selected {
      background-color: #eaf7ff; }
  .wix-code-exposure > .exposure-context-menu {
    visibility: hidden;
    position: absolute;
    right: -4px;
    top: 0; }

.image-crop-layer.parentSizeAbs {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

.image-crop-layer .clip,
.image-crop-layer .clip-marker,
.image-crop-layer .image-crop-container {
  position: absolute;
  color: transparent; }
  .image-crop-layer .clip img:-moz-broken,
  .image-crop-layer .clip img:-moz-user-disabled,
  .image-crop-layer .clip-marker img:-moz-broken,
  .image-crop-layer .clip-marker img:-moz-user-disabled,
  .image-crop-layer .image-crop-container img:-moz-broken,
  .image-crop-layer .image-crop-container img:-moz-user-disabled {
    display: none !important; }

.image-crop-layer .clip-marker {
  border: 1px solid #3899ec;
  z-index: 1;
  box-sizing: border-box;
  cursor: move; }
  .image-crop-layer .clip-marker .clip-handle {
    height: 12px;
    width: 12px;
    background: transparent;
    position: absolute;
    z-index: 2; }
    .image-crop-layer .clip-marker .clip-handle.handle-top-left {
      top: -1px;
      left: 0;
      transform: rotate(90deg); }
    .image-crop-layer .clip-marker .clip-handle.handle-top-right {
      top: 0;
      right: -1px;
      transform: rotate(180deg); }
    .image-crop-layer .clip-marker .clip-handle.handle-bottom-left {
      bottom: 0;
      left: -1px; }
    .image-crop-layer .clip-marker .clip-handle.handle-bottom-right {
      bottom: -1px;
      right: 0;
      transform: rotate(-90deg); }
    .image-crop-layer .clip-marker .clip-handle.handle-top {
      width: 4px;
      top: -5px;
      left: calc(50% - 1px);
      transform: rotate(90deg); }
    .image-crop-layer .clip-marker .clip-handle.handle-right {
      width: 4px;
      top: calc(50% - 5px);
      right: -1px;
      transform: rotate(180deg); }
    .image-crop-layer .clip-marker .clip-handle.handle-left {
      width: 4px;
      top: calc(50% - 7px);
      left: -1px; }
    .image-crop-layer .clip-marker .clip-handle.handle-bottom {
      width: 4px;
      bottom: -5px;
      left: calc(50% - 3px);
      transform: rotate(-90deg); }
    .image-crop-layer .clip-marker .clip-handle.cursor-resize-vertical {
      cursor: ns-resize; }
    .image-crop-layer .clip-marker .clip-handle.cursor-resize-horizontal {
      cursor: ew-resize; }
    .image-crop-layer .clip-marker .clip-handle.cursor-resize-diagonal-top-left {
      cursor: nwse-resize; }
    .image-crop-layer .clip-marker .clip-handle.cursor-resize-diagonal-top-right {
      cursor: nesw-resize; }

.image-crop-layer .outer {
  opacity: 0.5;
  cursor: move;
  position: relative; }

.image-crop-layer .crop-mode-tool-bar {
  white-space: nowrap;
  background-color: #fff;
  box-shadow: 0 0 14px 0 rgba(22, 51, 70, 0.36);
  width: 378px;
  height: 54px;
  border-radius: 50px; }
  .image-crop-layer .crop-mode-tool-bar .crop-mode-options {
    list-style: none;
    height: 100%; }
    .image-crop-layer .crop-mode-tool-bar .crop-mode-options .crop-mode-control {
      display: inline-block;
      vertical-align: top;
      height: 100%;
      padding: 0 12px 0 18px;
      border-right: 1px solid #f0f3f5; }
      .image-crop-layer .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-slider {
        width: 198px; }
        .image-crop-layer .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-slider .input-slider {
          top: 6px; }
          .image-crop-layer .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-slider .input-slider .input-stepper {
            width: 52px;
            margin-top: 2px; }
          .image-crop-layer .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-slider .input-slider .slider {
            margin: 0 73px 0 0;
            padding-top: 4px; }
            .image-crop-layer .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-slider .input-slider .slider .line {
              top: 18px; }
      .image-crop-layer .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-dropdown-options {
        padding: 10px 12px 8px 12px;
        height: auto;
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
        .image-crop-layer .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-dropdown-options .icon-button-wrapper {
          height: 25px;
          width: 34px;
          display: inline-block;
          cursor: pointer;
          padding-top: 8px;
          margin: 1px 0 5px 0; }
          .image-crop-layer .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-dropdown-options .icon-button-wrapper:hover, .image-crop-layer .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-dropdown-options .icon-button-wrapper.active {
            background: #eaf7ff;
            border-radius: 6px; }
      .image-crop-layer .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-save-or-cancel {
        padding: 8px 18px 20px 12px;
        height: auto; }
        .image-crop-layer .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-save-or-cancel .crop-mode-save,
        .image-crop-layer .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-save-or-cancel .crop-mode-cancel {
          cursor: pointer;
          border: none;
          outline: 0;
          border-radius: 50%;
          background-color: #eaf7ff;
          color: #3899ec;
          font-weight: bold;
          margin: 4px 6px 4px 0;
          width: 30px;
          height: 30px;
          display: inline-block; }
          .image-crop-layer .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-save-or-cancel .crop-mode-save:hover,
          .image-crop-layer .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-save-or-cancel .crop-mode-cancel:hover {
            background-color: #d3edff; }
          .image-crop-layer .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-save-or-cancel .crop-mode-save svg path:first-of-type,
          .image-crop-layer .crop-mode-tool-bar .crop-mode-options .crop-mode-control.crop-mode-save-or-cancel .crop-mode-cancel svg path:first-of-type {
            fill: #3899ec; }
      .image-crop-layer .crop-mode-tool-bar .crop-mode-options .crop-mode-control:last-child {
        border-right: none; }

.columns-container-box {
  border: 3px solid #3899ec;
  box-sizing: border-box;
  position: absolute; }
  .columns-container-box.fade {
    border-color: #2b81cb;
    opacity: 0.45; }
  .columns-container-box.components-ui-color-orange {
    border-color: #fb7d33; }
    .columns-container-box.components-ui-color-orange.fade {
      border-color: #b8561d; }

@keyframes flicker {
  1% {
    background: rgba(78, 183, 245, 0); }
  50% {
    background: rgba(78, 183, 245, 0.2); }
  100% {
    background: rgba(78, 183, 245, 0); } }

.column-content-highlight {
  position: absolute;
  background: rgba(78, 183, 245, 0.2); }
  .column-content-highlight.flicker {
    background: rgba(78, 183, 245, 0);
    animation: flicker 1s forwards; }

.first-time-steps {
  width: 257px;
  min-height: 276px; }
  .first-time-steps .text-container {
    padding: 23px; }
    .first-time-steps .text-container .title {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 18px;
      color: #2b5672;
      margin-bottom: 12px; }
    .first-time-steps .text-container .text {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      color: #2b5672; }
  .first-time-steps .image-container {
    text-align: center;
    left: 0;
    right: 0;
    height: 115px;
    background-size: 182px 115px;
    background-repeat: no-repeat;
    background-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px; }
    .first-time-steps .image-container .btn-close {
      padding: 0;
      border-radius: 50%;
      border: 0;
      position: absolute;
      width: 25px;
      height: 25px;
      top: 12px;
      right: 12px;
      background-color: rgba(255, 255, 255, 0.2);
      color: #fff;
      box-sizing: border-box; }
      .first-time-steps .image-container .btn-close:focus {
        outline: 0; }
      .first-time-steps .image-container .btn-close:hover {
        background-color: rgba(0, 0, 0, 0.2); }
      .first-time-steps .image-container .btn-close svg {
        fill: #ffffff; }
  .first-time-steps > .panel-footer {
    padding: 0 18px 18px 23px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .first-time-steps > .panel-footer.one-step {
      justify-content: flex-end; }
    .first-time-steps > .panel-footer > .btn-confirm {
      background-color: #3899ec;
      height: 24px;
      line-height: 13px;
      min-width: 87px; }
      .first-time-steps > .panel-footer > .btn-confirm:hover {
        background-color: #4eb7f5; }

.first-time-steps-menu {
  display: inline-flex; }
  .first-time-steps-menu .first-time-steps-menu-item {
    display: inline-flex; }
  .first-time-steps-menu .step-button {
    cursor: pointer;
    border-radius: 50%;
    display: inline-block;
    height: 16px;
    width: 16px;
    box-shadow: inset 0 0 0 1px #3899ec;
    transition-property: background-color, border-width;
    transition-duration: 0.15s;
    box-sizing: border-box;
    margin-right: 6px;
    vertical-align: middle; }
    .first-time-steps-menu .step-button:hover {
      background-color: #d3edff; }
    .first-time-steps-menu .step-button.checked {
      background-color: #3899ec;
      box-shadow: inset 0 0 0 1px #3899ec, inset 0 0 0 3px #fff; }

.full-stage-frame {
  position: absolute;
  top: 0;
  background: #fff;
  z-index: 1310; }

.platformToolBarPanel {
  box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.36);
  position: fixed;
  border-radius: 8px;
  background-color: #fff;
  z-index: 1055;
  padding-bottom: 18px; }
  .platformToolBarPanel .header-seperator {
    margin: 0 0 6px 0;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #f0f3f5; }
  .platformToolBarPanel .seperator {
    margin: 9px 0;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #f0f3f5; }
  .platformToolBarPanel .header {
    display: flex;
    align-items: center;
    height: 42px;
    min-height: 42px;
    border-radius: 50%;
    cursor: move; }
  .platformToolBarPanel .header-button-platform-toolbar {
    width: 23px;
    height: 23px; }
    .platformToolBarPanel .header-button-platform-toolbar svg {
      width: 23px;
      height: 23px; }

.platform-tool-panel-content {
  border-top: solid 1px #d9e1e8; }

.pages-tree-node {
  margin: 4px 0 0;
  border: 0;
  position: relative; }
  .pages-tree-node:first-child > .pages-tree-item:before {
    height: 50%;
    padding-top: 3px; }
  .pages-tree-node .pages-tree-node {
    margin-left: 25px; }
    .pages-tree-node .pages-tree-node > .pages-tree-item:before {
      display: block; }
  .pages-tree-node .expander {
    display: none;
    background-color: #cde;
    border-radius: 100%;
    color: #1a92df;
    cursor: pointer;
    height: 14px;
    left: -7px;
    font-size: 14px;
    line-height: 10px;
    position: absolute;
    text-align: center;
    top: 12.5px;
    width: 14px;
    z-index: 1445; }

.pages-tree-shrinked .pages-tree {
  margin: 5px 0 0; }
  .pages-tree-shrinked .pages-tree:first-child > .pages-tree-item:before {
    padding-top: 4px; }
  .pages-tree-shrinked .pages-tree .symbol-quickActionsSettings {
    margin-left: -0.5px;
    margin-top: 0.5px; }
  .pages-tree-shrinked .pages-tree .expander {
    top: 4.5px; }

.has-children.pages-tree-node > .expander {
  display: block; }

.tree-collapsed.pages-tree-node + .pages-tree-node {
  margin-top: 12px; }

.tree-collapsed .pages-tree-node {
  display: none; }
  .tree-collapsed .pages-tree-node.shadow {
    display: block;
    margin-top: 12px; }
    .tree-collapsed .pages-tree-node.shadow > .pages-tree-item:before {
      height: 50%;
      padding-top: 4px; }

.tree-collapsed > .pages-tree-item,
.rotated.has-children > .pages-tree-item {
  margin-bottom: 6px; }

.tree-collapsed > .pages-tree-item {
  box-shadow: 0 0 0 1px #cde, 0 3px 0 #fff, 0 4px 0 #cde, 0 6px 0 #fff, 0 7px 0 #cde; }

.tree-collapsed > .pages-tree-item:hover,
.tree-collapsed > .pages-tree-item.selected,
.rotated.has-children > .pages-tree-item {
  box-shadow: 0 0 0 1px #cde, 0 3px 0 #eaf7ff, 0 4px 0 #cde, 0 6px 0 #eaf7ff, 0 7px 0 #cde; }

.rotated > .pages-tree-item {
  background-color: #eaf7ff;
  padding-bottom: 20px;
  transform-origin: 50% 50%;
  transform: rotate(-6deg); }
  .rotated > .pages-tree-item:before {
    display: none !important; }
  .rotated > .pages-tree-item .pages-quick-actions {
    display: none; }

.shadow .pages-tree {
  display: none; }

.shadow > .expander {
  display: none !important; }

.shadow > .pages-tree-item {
  background-color: #d9e1e8;
  border: 1px solid #cde; }
  .shadow > .pages-tree-item > * {
    display: none; }

section.dropdown-options > div.options > ol.list > li.option.pages-menu-quick-action {
  text-overflow: clip; }

.page-node-list {
  box-sizing: border-box;
  padding: 0 17px 20px 17px;
  width: 100%; }
  .page-node-list > .page-node-item {
    margin-bottom: 6px; }
  .page-node-list > .selected .page-node-item {
    background-color: #d3edff; }
    .page-node-list > .selected .page-node-item .action-button {
      box-shadow: 0 1px 1px 0 #7a92a5;
      visibility: visible; }
    .page-node-list > .selected .page-node-item > .page-title svg path {
      fill: #7a92a5; }

.page-node-list-item {
  cursor: auto;
  margin-bottom: 4px;
  line-height: 37px; }

section.dropdown-options.context-menu > div.options > ol.list {
  min-width: 144px; }
  section.dropdown-options.context-menu > div.options > ol.list > .options-container > li.option:not(.separator) {
    height: 30px;
    line-height: 30px;
    padding: 0 18px; }
    section.dropdown-options.context-menu > div.options > ol.list > .options-container > li.option:not(.separator) > .qa-label {
      padding-left: 9px;
      color: #2b5672;
      vertical-align: top; }
    section.dropdown-options.context-menu > div.options > ol.list > .options-container > li.option:not(.separator) > .svg-container {
      width: 18px;
      display: inline-block; }
  section.dropdown-options.context-menu > div.options > ol.list > .options-container > li.option.psp-qa {
    height: 30px;
    line-height: 30px; }
  section.dropdown-options.context-menu > div.options > ol.list > .options-container > li.option.separator {
    padding: 6px 12px; }

section.dropdown-options.context-menu .symbol-quickActionsSettings {
  margin-top: 6px;
  margin-left: -1px; }

section.dropdown-options.context-menu .symbol-quickActionsHide,
section.dropdown-options.context-menu .symbol-quickActionsShow,
section.dropdown-options.context-menu .symbol-qaDuplicate,
section.dropdown-options.context-menu .symbol-qaDelete,
section.dropdown-options.context-menu .symbol-quickActionsRename,
section.dropdown-options.context-menu .symbol-qaPopupSettings {
  margin-top: 8px; }

section.dropdown-options.context-menu .symbol-quickActionsSubpage,
section.dropdown-options.context-menu .symbol-quickActionsMainPage {
  margin-left: 2px; }

section.page-transitions {
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.35);
  bottom: -33px;
  left: -31px;
  position: absolute;
  width: 300px;
  z-index: 1446;
  background-color: #fff;
  overflow: hidden; }
  section.page-transitions > div.content {
    background-color: #fff;
    border-radius: 0 0 10px 10px; }
    section.page-transitions > div.content > .first-time-info-box {
      margin-bottom: 0; }
    section.page-transitions > div.content > div.control-thumbnails {
      margin: 0 auto;
      padding: 24px 18px 16px 30px; }
      section.page-transitions > div.content > div.control-thumbnails > label {
        vertical-align: top; }
      section.page-transitions > div.content > div.control-thumbnailslabel:nth-child(-n+3) {
        margin-top: 0; }
  section.page-transitions > i.triangle {
    background-color: #fff;
    bottom: 22px;
    box-shadow: -2px 2px 6px -2px rgba(0, 0, 0, 0.35);
    height: 10px;
    left: -5px;
    position: absolute;
    transform: rotate(45deg);
    width: 10px; }
  section.page-transitions:focus {
    outline: none; }

.tooltips-layer .popover.popover-add-page-menu .wrapper {
  top: calc(-50% + 50px);
  position: relative;
  width: 300px; }
  .tooltips-layer .popover.popover-add-page-menu .wrapper > .content {
    padding: 0;
    width: 100%; }

.tooltips-layer .popover.popover-add-page-menu .cover-shadow {
  transform: translateX(0) rotate(0deg) !important;
  width: 0;
  height: 0;
  border-top: 11.3px solid transparent;
  border-bottom: 11.3px solid transparent;
  border-right: 11.3px solid #fff;
  background-color: transparent;
  margin-top: -3px;
  left: -11px; }

.tooltip-pages-add-menu {
  list-style-type: none;
  padding: 7px 0; }
  .tooltip-pages-add-menu > li {
    cursor: pointer; }
    .tooltip-pages-add-menu > li:not(:last-child) {
      border-bottom: 1px solid #d9e1e8; }
    .tooltip-pages-add-menu > li:last-child > div {
      padding-bottom: 18px; }
    .tooltip-pages-add-menu > li > div {
      min-height: 48px;
      padding: 23px 18px;
      word-wrap: break-word;
      white-space: initial; }
      .tooltip-pages-add-menu > li > div:hover {
        background-color: #e6f7ff; }
        .tooltip-pages-add-menu > li > div:hover > .icon {
          background-color: #caedff; }
      .tooltip-pages-add-menu > li > div:not(:hover) .title > .title-help-icon {
        display: none; }
      .tooltip-pages-add-menu > li > div .tooltip-about-page .wrapper .content {
        max-width: 200px; }
      .tooltip-pages-add-menu > li > div > .icon {
        background-color: #e6f7ff;
        border-radius: 8px;
        float: left;
        height: 50px;
        margin-right: 20px; }
      .tooltip-pages-add-menu > li > div > div {
        margin-top: 3px; }
        .tooltip-pages-add-menu > li > div > div .title {
          font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-size: 18px;
          display: block;
          color: #2b5672;
          margin-bottom: 4px;
          line-height: 24px; }
          .tooltip-pages-add-menu > li > div > div .title > .title-help-icon {
            display: inline;
            position: relative;
            left: 3px;
            top: 2px; }
            .tooltip-pages-add-menu > li > div > div .title > .title-help-icon:hover .symbol-infoSmall path {
              fill: #7fccf7; }
        .tooltip-pages-add-menu > li > div > div .description {
          font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-size: 14px;
          display: block;
          color: #2b5672;
          margin-left: 70px; }

.popups-list .scroll-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0; }

.popups-tab {
  box-sizing: border-box;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0 0 10px 10px; }
  .popups-tab .popups-list {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    position: relative; }
  .popups-tab > footer {
    background-color: #fff;
    border-top: 1px solid #d9e1e8;
    flex-shrink: 0;
    padding: 10px 0;
    z-index: 1;
    flex-grow: 0; }
    .popups-tab > footer > button.control-button {
      display: block;
      font-size: 16px;
      line-height: 32px;
      margin: 0 auto;
      min-width: initial;
      padding: 0;
      width: 240px; }

.filtered-pages-and-popups {
  position: absolute;
  background-color: #f0f3f5;
  border-radius: 0 0 10px 10px;
  height: 100%;
  width: 100%;
  z-index: 1255; }
  .filtered-pages-and-popups .without-special-page {
    height: 100%; }

.unfiltered-pages-and-popups {
  position: absolute;
  background-color: #f0f3f5;
  height: 100%;
  width: 100%;
  z-index: 1255;
  border-radius: 10px; }
  .unfiltered-pages-and-popups .tabs-pages-and-popups {
    height: 52px; }
    .unfiltered-pages-and-popups .tabs-pages-and-popups ~ .pages-menu,
    .unfiltered-pages-and-popups .tabs-pages-and-popups ~ .popups-tab {
      background-color: #f0f3f5;
      height: calc(100% - 52px); }
  .unfiltered-pages-and-popups .pages-menu,
  .unfiltered-pages-and-popups .popups-tab {
    height: 100%; }

.pages-and-popups-list {
  height: 100%; }
  .pages-and-popups-list .list {
    margin: 0 auto;
    width: calc(100% - 34px); }
    .pages-and-popups-list .list.list-of-pop-ups {
      margin-top: 28px; }
    .pages-and-popups-list .list.list-of-pages {
      padding-top: 20px; }
  .pages-and-popups-list .list-label {
    margin: 0 0 13px 0;
    padding: 0 0 4px 13px;
    font-size: 14px;
    font-weight: 300;
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #2b5672;
    border-bottom: 1px solid #d9e1e8; }
  .pages-and-popups-list .item {
    margin-top: 5px; }
    .pages-and-popups-list .item:first-child {
      margin-top: 0; }

.empty-pages-and-popups {
  overflow: auto; }
  .empty-pages-and-popups > .empty-figure {
    width: 204px;
    height: 110px;
    margin: 50px auto 0 auto; }
  .empty-pages-and-popups > .main-message {
    font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 16px;
    color: #2b5672;
    text-align: center;
    margin-top: 32px; }
  .empty-pages-and-popups > .try-again-message {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    color: #7a92a5;
    text-align: center;
    margin-top: 6px; }

.my-uploads-panel .panel-background {
  background-color: #fff;
  width: 306px;
  height: calc(100vh - 166px);
  max-height: 550px; }

.my-uploads-panel .listWrapper {
  height: 100%; }

.my-uploads-panel .panel-button {
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid #d9e1e8;
  background-color: #fff; }
  .my-uploads-panel .panel-button .label {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 15px;
    color: #2b5672;
    overflow: hidden; }
    .my-uploads-panel .panel-button .label .button-label {
      vertical-align: middle; }
    .my-uploads-panel .panel-button .label .symbol {
      width: 66px;
      height: 58px;
      margin: 24px 32px 27px 26px;
      vertical-align: middle; }
  .my-uploads-panel .panel-button .action {
    position: absolute;
    right: 15px;
    width: 28px;
    height: 28px;
    line-height: 28px;
    top: 40px; }
  .my-uploads-panel .panel-button:last-child {
    border-bottom: none; }
  .my-uploads-panel .panel-button:hover {
    background-color: #eaf7ff; }

.bg-panel {
  background-color: #fff; }
  .bg-panel .bg-panel-top {
    position: relative; }
  .bg-panel .bg-panel-preview {
    height: 174px;
    width: 352px; }
  .bg-panel .bg-panel-preview-media {
    position: absolute;
    width: 100%;
    height: 174px; }
  .bg-panel .bg-panel-preview-overlay {
    position: absolute;
    width: 100%;
    height: 174px; }
  .bg-panel .bg-panel-preview-color {
    width: 44px;
    height: 44px;
    border-bottom-right-radius: 6px;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0; }
    .bg-panel .bg-panel-preview-color .symbol-fold-clipart {
      width: 50px;
      height: 50px;
      position: relative;
      top: 0;
      left: 0; }
  .bg-panel .bg-panel-preview-buttons {
    width: 100%;
    height: 120px;
    text-align: center;
    position: absolute; }
    .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button {
      display: inline-block;
      text-align: center;
      vertical-align: top;
      margin-top: 52px;
      padding: 0 4px;
      position: relative; }
      .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .symbol-play-video,
      .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video .symbol-play-video {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: #fff 2px solid;
        box-sizing: border-box;
        background-color: rgba(22, 45, 61, 0.5); }
      .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:hover .symbol-play-video {
        border-color: #4eb7f5; }
        .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:hover .symbol-play-video .st0 {
          fill: #4eb7f5; }
      .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:hover .input-play-video:not(:disabled):checked ~ .symbol-play-video {
        border-color: #4eb7f5; }
        .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:hover .input-play-video:not(:disabled):checked ~ .symbol-play-video .st0 {
          fill: transparent; }
        .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:hover .input-play-video:not(:disabled):checked ~ .symbol-play-video .st1 {
          fill: #4eb7f5; }
      .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:active .input-play-video:checked ~ .symbol-play-video {
        border-color: #fff !important; }
        .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:active .input-play-video:checked ~ .symbol-play-video .st0 {
          fill: transparent !important; }
        .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:active .input-play-video:checked ~ .symbol-play-video .st1 {
          fill: #fff !important; }
      .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:active .symbol-play-video {
        border: #fff 2px solid; }
        .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video:active .symbol-play-video .st0 {
          fill: #fff; }
      .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video .input-play-video:disabled ~ .symbol-play-video {
        pointer-events: none;
        border-color: #bcbcbc; }
        .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video .input-play-video:disabled ~ .symbol-play-video .st0 {
          fill: #bcbcbc; }
      .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video .input-play-video:checked:disabled ~ .symbol-play-video {
        pointer-events: none;
        border-color: #bcbcbc; }
        .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video .input-play-video:checked:disabled ~ .symbol-play-video .st1 {
          fill: #bcbcbc; }
        .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-play-video .input-play-video:checked:disabled ~ .symbol-play-video .st0 {
          fill: transparent; }
      .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .symbol-settings-icon,
      .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-settings-icon .symbol-settings-icon {
        position: absolute;
        left: 5px;
        top: 1px; }
        .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .symbol-settings-icon .st0,
        .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-settings-icon .symbol-settings-icon .st0 {
          fill: #fff; }
      .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-settings-icon:hover .symbol-settings-icon .st0 {
        fill: #4eb7f5; }
      .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .label-settings-icon,
      .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-settings-icon .label-settings-icon {
        border-radius: 18px;
        border: #fff 2px solid;
        box-sizing: border-box;
        background-color: rgba(22, 45, 61, 0.5);
        height: 36px;
        line-height: 30px;
        padding: 0 12px 0 34px;
        color: #fff; }
      .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-settings-icon:hover .label-settings-icon {
        border-color: #4eb7f5;
        color: #4eb7f5; }
      .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-settings-icon:active .symbol-settings-icon .st0 {
        fill: #fff; }
      .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-settings-icon:active .input-settings-icon:checked ~ .symbol-settings-icon .st0 {
        fill: #fff; }
      .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-settings-icon:active .label-settings-icon {
        border: #fff 2px solid;
        color: #fff; }
      .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .control-settings-icon:active .input-settings-icon:checked ~ .label-settings-icon {
        border: #fff 2px solid;
        color: #fff; }
      .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .circle-preloader {
        width: 18px;
        height: 36px;
        margin: 0;
        top: 0;
        left: 4px;
        animation-duration: 2s; }
        .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .circle-preloader::before, .bg-panel .bg-panel-preview-buttons .bg-panel-preview-button .circle-preloader::after {
          color: #fff;
          border-width: 2px;
          animation-duration: 1s; }
  .bg-panel .bg-panel-top-actions {
    position: absolute;
    bottom: 0;
    height: 54px;
    width: 100%;
    background: rgba(177, 221, 248, 0.4);
    text-align: center; }
  .bg-panel .bg-panel-action {
    padding: 11px 5px 0 5px;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative; }
    .bg-panel .bg-panel-action .control-button2.bg-panel-action-btn {
      background-color: #fff;
      height: 32px;
      line-height: 32px;
      width: 92px;
      text-align: center; }
    .bg-panel .bg-panel-action .control-button2.bg-panel-action-btn .symbol {
      margin-left: -1px; }
      .bg-panel .bg-panel-action .control-button2.bg-panel-action-btn .symbol .st0 {
        fill: #3899ec; }
    .bg-panel .bg-panel-action .control-button2.bg-panel-action-btn .label {
      font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 14px;
      color: #3899ec;
      padding: 0 8px 0 0;
      margin-left: -2px; }
    .bg-panel .bg-panel-action .control-button2.bg-panel-action-btn:hover {
      background-color: #eaf7ff; }
    .bg-panel .bg-panel-action .control-button2.bg-panel-action-btn input:disabled ~ .label {
      color: #bcbcbc; }
    .bg-panel .bg-panel-action .control-button2.bg-panel-action-btn[disabled] {
      background-color: #fff; }
    .bg-panel .bg-panel-action .control-button2.bg-panel-action-btn input:disabled ~ .symbol .st0 {
      fill: #bcbcbc; }
    .bg-panel .bg-panel-action :first-child {
      padding-left: 0; }
    .bg-panel .bg-panel-action :last-child {
      padding-right: 0; }
  .bg-panel .bg-panel-bottom {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.7) 0, white 70%);
    height: 73px;
    padding-top: 19px;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    box-sizing: border-box; }
  .bg-panel .bg-panel-section-list {
    margin-top: 21px; }
  .bg-panel .bg-panel-preset-list {
    min-height: 359px;
    max-height: calc(100vh - 348px);
    width: 352px;
    box-sizing: border-box; }
    .bg-panel .bg-panel-preset-list .bg-panel-preset-list-sections {
      padding: 20px 25px 73px; }
      .bg-panel .bg-panel-preset-list .bg-panel-preset-list-sections .preset-list-item {
        display: inline-block;
        font-size: 0;
        width: 33.33%;
        margin-bottom: 18px;
        text-align: center; }
        .bg-panel .bg-panel-preset-list .bg-panel-preset-list-sections .preset-list-item:nth-child(1), .bg-panel .bg-panel-preset-list .bg-panel-preset-list-sections .preset-list-item:nth-child(3n+1) {
          text-align: left; }
        .bg-panel .bg-panel-preset-list .bg-panel-preset-list-sections .preset-list-item:nth-child(3n+3) {
          text-align: right; }
      .bg-panel .bg-panel-preset-list .bg-panel-preset-list-sections .preset-list-preset {
        display: inline-block; }

.background-image-settings-panel .scroll-type-thumbs {
  padding-top: 24px; }

.background-sub-panel .control-thumbnails input:checked + span {
  background-color: #eaf7ff;
  box-shadow: 0 0 0 3px #fff, 0 0 0 5px #3899ec; }

.background-sub-panel .bg-settings-panel-scrollbox {
  min-height: 196px;
  max-height: calc(100vh - 193px); }

.bg-apply-panel-item {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #7a7a7a;
  border: 1px solid #b1ddf8;
  border-radius: 8px;
  background-color: #fff;
  height: 48px;
  line-height: 48px;
  position: relative;
  margin-bottom: 5px;
  padding: 0 10px; }
  .bg-apply-panel-item.item-is-page {
    cursor: pointer;
    color: #162d3d; }
  .bg-apply-panel-item.current-page {
    background-color: #d3edff; }
  .bg-apply-panel-item.item-hidden {
    color: #7a92a5; }
  .bg-apply-panel-item:not(.current-page).item-is-page:hover {
    background-color: #eaf7ff; }
  .bg-apply-panel-item.item-second-level {
    margin-left: 25px; }
    .bg-apply-panel-item.item-second-level:before {
      content: '';
      width: 11px;
      height: 0;
      top: 50%;
      left: -11px;
      border-top: 1px solid #b1ddf8;
      position: absolute; }
    .bg-apply-panel-item.item-second-level:after {
      content: '';
      width: 11px;
      height: 55px;
      top: -6px;
      left: -11px;
      border-left: 1px solid #b1ddf8;
      position: absolute; }
    .bg-apply-panel-item.item-second-level.item-second-level-last:after {
      height: 30px; }
  .bg-apply-panel-item > .item-label {
    display: flex;
    align-items: center;
    width: 100%; }
  .bg-apply-panel-item .page-preview {
    width: 50px;
    height: 34px;
    border: 1px solid #eaf7ff;
    margin: 0 10px; }
    .bg-apply-panel-item .page-preview .page-preview-video-controls {
      height: 34px;
      background: linear-gradient(to bottom, transparent 65%, rgba(85, 85, 85, 0) 66%, #555555 100%);
      position: relative; }
      .bg-apply-panel-item .page-preview .page-preview-video-controls .symbol-bgVideoIndicator {
        width: 14px;
        height: 9px;
        left: 1px;
        bottom: 1px;
        position: absolute; }
  .bg-apply-panel-item .page-name {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1; }
  .bg-apply-panel-item .symbol-homePage {
    width: 13px;
    height: 10px; }
  .bg-apply-panel-item .symbol-hiddenPage {
    width: 17px;
    height: 15px; }

.bg-apply-panel .bg-apply-panel-wrapper {
  background-color: #f0f3f5; }

.bg-apply-panel .bg-apply-panel-description {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  color: #2b5672;
  padding: 20px 27px; }

.bg-apply-panel .bg-apply-panel-all {
  padding: 0 30px 10px; }

.bg-apply-panel .bg-apply-panel-scrollbox {
  min-height: 166px;
  max-height: calc(100vh - 293px);
  padding: 0 18px 40px; }

.slideShow-apply-panel-item {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #7a7a7a;
  border: 1px solid #b1ddf8;
  border-radius: 8px;
  background-color: #fff;
  height: 48px;
  line-height: 48px;
  position: relative;
  margin-bottom: 5px;
  padding: 0 10px; }
  .slideShow-apply-panel-item.current-slide {
    background-color: #d3edff; }
  .slideShow-apply-panel-item > .item-label {
    display: flex;
    align-items: center;
    width: 100%; }
  .slideShow-apply-panel-item .slide-preview {
    width: 50px;
    height: 34px;
    border: 1px solid #eaf7ff;
    margin: 0 10px; }
    .slideShow-apply-panel-item .slide-preview .slide-preview-video-controls {
      height: 34px;
      background: linear-gradient(to bottom, transparent 65%, rgba(85, 85, 85, 0) 66%, #555555 100%);
      position: relative; }
      .slideShow-apply-panel-item .slide-preview .slide-preview-video-controls .symbol-bgVideoIndicator {
        width: 14px;
        height: 9px;
        left: 1px;
        bottom: 1px;
        position: absolute; }
  .slideShow-apply-panel-item .slide-name {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1; }

.slide-show-apply-panel {
  z-index: 1310; }
  .slide-show-apply-panel .slide-show-apply-panel-wrapper {
    background-color: #f0f3f5; }
  .slide-show-apply-panel .slide-show-apply-panel-description {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    color: #2b5672;
    padding: 20px 27px; }
  .slide-show-apply-panel .slide-show-apply-panel-all {
    padding: 0 30px 10px; }
  .slide-show-apply-panel .slide-show-apply-panel-scrollbox {
    min-height: 166px;
    max-height: calc(100vh - 293px);
    padding: 0 18px 40px; }

.popup-overlay-settings-panel .group-buttons-container {
  text-align: center; }

.pages-panel-frame {
  z-index: 1445; }
  .pages-panel-frame.special-pages .pages-tree-container {
    padding-top: 0; }
  .pages-panel-frame .pages-panel-frame-content {
    height: 80vh;
    height: calc(100vh - 166px);
    max-height: 796px;
    position: relative; }
  .pages-panel-frame .tabs-pages-and-popups {
    background-color: #fff; }
  .pages-panel-frame .pages-panel-shadow {
    box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.36);
    border-radius: 8px;
    height: calc(100% + 54px);
    left: 0;
    pointer-events: none;
    position: absolute;
    top: -54px;
    width: 100%;
    z-index: 1446; }
  .pages-panel-frame .panel-header {
    background-color: #3899ec;
    color: #fff;
    height: 54px;
    margin-bottom: 0;
    padding-left: 17px;
    z-index: 1447; }
    .pages-panel-frame .panel-header > .panel-header-title {
      font-size: 20px; }
    .pages-panel-frame .panel-header > span {
      line-height: 24px;
      margin-top: 14px; }
    .pages-panel-frame .panel-header > a {
      background-color: #2b81cb;
      border-radius: 100%;
      cursor: pointer;
      font-weight: 100; }
  .pages-panel-frame.in-transition > .panel-header {
    z-index: 1445; }
  .pages-panel-frame .pages {
    border-radius: 0 0 8px 8px;
    background: #f0f3f5;
    width: 300px;
    z-index: 1445; }
  .pages-panel-frame .pages-menu {
    background-color: #f0f3f5;
    border-radius: 0 0 10px 10px;
    height: 100%;
    position: absolute;
    overflow: visible;
    width: 100%;
    z-index: 1445;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 12px; }
    .pages-panel-frame .pages-menu ul {
      max-height: initial;
      overflow: initial; }
    .pages-panel-frame .pages-menu .item:hover {
      background-color: initial; }
    .pages-panel-frame .pages-menu > footer {
      background-color: #fff;
      border-top: 1px solid #d9e1e8;
      flex-shrink: 0;
      padding: 10px 0;
      z-index: 1; }
      .pages-panel-frame .pages-menu > footer > button.control-button {
        display: block;
        font-size: 16px;
        line-height: 32px;
        margin: 0 auto 10px;
        min-width: initial;
        padding: 0;
        width: 240px; }
      .pages-panel-frame .pages-menu > footer > div.page-transition {
        border-top: 1px solid #d9e1e8;
        color: #2b5672;
        cursor: pointer;
        height: 35px;
        padding: 10px 30px 0; }
        .pages-panel-frame .pages-menu > footer > div.page-transition > div {
          display: inline-block;
          max-width: 200px;
          vertical-align: middle; }
          .pages-panel-frame .pages-menu > footer > div.page-transition > div > * {
            vertical-align: middle; }
          .pages-panel-frame .pages-menu > footer > div.page-transition > div > svg {
            display: inline-block;
            margin-right: 10px; }
          .pages-panel-frame .pages-menu > footer > div.page-transition > div > span {
            margin-right: 3px; }
          .pages-panel-frame .pages-menu > footer > div.page-transition > div > button.control-button {
            border-radius: 0;
            font-size: 14px;
            height: auto;
            line-height: 16px;
            min-width: initial;
            padding: 0; }
            .pages-panel-frame .pages-menu > footer > div.page-transition > div > button.control-button .button-content::after {
              border-bottom: 4px solid transparent;
              border-left: 6px solid #3899ec;
              border-right: 0;
              border-top: 4px solid transparent;
              content: '';
              display: inline-block;
              height: 0;
              margin-left: 4px;
              width: 0; }
          .pages-panel-frame .pages-menu > footer > div.page-transition > div.icon {
            line-height: 35px; }
  .pages-panel-frame .popups-are-shown .pages-menu {
    height: calc(100% - 52px); }
  .pages-panel-frame .pages-tree-container {
    height: 100%;
    width: 100%;
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0; }
    .pages-panel-frame .pages-tree-container > .first-time-info-box {
      flex-shrink: 0; }
    .pages-panel-frame .pages-tree-container > .pages-tree-docker {
      flex-grow: 1;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0; }
    .pages-panel-frame .pages-tree-container.disable-drag .pages-tree-item {
      cursor: default; }
  .pages-panel-frame .pages-tree-container > .custom-scroll,
  .pages-panel-frame .pages-tree-docker > .custom-scroll {
    flex-grow: 1;
    position: relative;
    height: 100%; }
    .pages-panel-frame .pages-tree-container > .custom-scroll > .outer-container,
    .pages-panel-frame .pages-tree-docker > .custom-scroll > .outer-container {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0; }
      .pages-panel-frame .pages-tree-container > .custom-scroll > .outer-container > .inner-container,
      .pages-panel-frame .pages-tree-docker > .custom-scroll > .outer-container > .inner-container {
        height: 100%; }
  .pages-panel-frame .pages-tree-container .tree-root,
  .pages-panel-frame .pages-tree-docker .tree-root {
    box-sizing: border-box;
    padding: 0 17px 20px;
    width: 100%; }
  .pages-panel-frame .pages-tree-controls {
    background-color: #fff;
    border-bottom: 1px solid #d9e1e8;
    box-sizing: border-box;
    height: 47px;
    min-height: 47px;
    line-height: 47px; }
    .pages-panel-frame .pages-tree-controls > a {
      color: rgba(43, 86, 114, 0.9);
      font-size: 14px;
      text-decoration: none;
      display: block;
      padding-left: 20px; }
      .pages-panel-frame .pages-tree-controls > a:hover {
        text-decoration: underline; }
        .pages-panel-frame .pages-tree-controls > a:hover .button-control {
          background-color: #7fccf7; }
    .pages-panel-frame .pages-tree-controls .button-control {
      border-radius: 50%;
      border: 0;
      width: 25px;
      height: 25px;
      background-color: rgba(13, 72, 127, 0.3);
      color: #fff;
      box-sizing: border-box;
      line-height: 25px;
      min-width: 0;
      padding: 0;
      background-color: #d3edff;
      margin-right: 10px; }
      .pages-panel-frame .pages-tree-controls .button-control:hover {
        background-color: rgba(13, 72, 127, 0.6); }
      .pages-panel-frame .pages-tree-controls .button-control.back-to-pages {
        position: relative;
        top: 8px; }
        .pages-panel-frame .pages-tree-controls .button-control.back-to-pages:focus {
          outline: none; }
      .pages-panel-frame .pages-tree-controls .button-control svg path {
        fill: #3899ec; }
    .pages-panel-frame .pages-tree-controls .panel-header-close .button-control {
      background-color: #d3edff;
      margin-right: 0; }
    .pages-panel-frame .pages-tree-controls .panel-header-close span {
      padding-left: 10px; }
    .pages-panel-frame .pages-tree-controls .panel-header-close:hover .button-control {
      background-color: #eaf7ff; }
  .pages-panel-frame .page-settings-panel {
    height: calc(100% + 54px);
    top: -54px; }
  .pages-panel-frame .no-pages {
    position: absolute;
    width: 100%;
    height: calc(100% - 200px);
    top: calc(50% - 54px);
    text-align: center; }
    .pages-panel-frame .no-pages > svg {
      padding-bottom: 22px; }
    .pages-panel-frame .no-pages > div {
      color: #7a92a5;
      font-size: 14px; }
  .pages-panel-frame .popups-tab {
    position: absolute;
    top: 52px;
    bottom: 0;
    right: 0;
    left: 0; }
    .pages-panel-frame .popups-tab footer {
      border-radius: 0 0 10px 10px; }

.symbol-addPage {
  margin-bottom: 1px; }

.editorVersionInfoPanel .content {
  max-height: 300px;
  width: auto;
  overflow-y: auto;
  padding: 12px; }
  .editorVersionInfoPanel .content .row {
    border-bottom: 1px solid #c0c0c0;
    padding: 10px 0; }
    .editorVersionInfoPanel .content .row label {
      display: inline-block;
      width: 100px; }
    .editorVersionInfoPanel .content .row span {
      margin-left: 10px;
      font-weight: bold; }

.editorVersionInfoPanel .panel-footer {
  padding: 5px 0; }
  .editorVersionInfoPanel .panel-footer .done-button {
    left: calc(100% - 80px);
    position: relative;
    height: 25px;
    line-height: 25px; }

.site-settings-panel {
  position: relative; }
  .site-settings-panel .hidden {
    display: none; }

.video-preloader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 100%;
  z-index: 1982;
  background-color: white; }

.preloader-overlap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent; }

.preview-holder {
  overflow: auto;
  overflow-x: hidden; }

@keyframes progress-indicator-anim {
  0% {
    width: 0; }
  50% {
    width: 100%; }
  100% {
    width: 0; } }

@keyframes top-bar-mobile-icon-shake {
  0% {
    transform: scale3d(1, 1, 1); }
  30% {
    transform: scale3d(0.75, 1.25, 1); }
  40% {
    transform: scale3d(1.25, 0.75, 1); }
  50% {
    transform: scale3d(0.85, 1.15, 1); }
  65% {
    transform: scale3d(1.05, 0.95, 1); }
  75% {
    transform: scale3d(0.95, 1.05, 1); }
  100% {
    transform: scale3d(1, 1, 1); } }

.top-bar-button {
  text-align: center;
  height: 48px;
  cursor: pointer; }
  .top-bar-button.icon-shiver .top-bar-button-content svg {
    animation: top-bar-mobile-icon-shake 1000ms infinite linear; }
  .top-bar-button.top-bar-item-margin .top-bar-button-content {
    padding: 0 20px; }
  .top-bar-button .wrapper-to-perserve-ref {
    height: 100%; }
    .top-bar-button .wrapper-to-perserve-ref .control-button2-wrapper {
      max-height: 48px; }
  .top-bar-button:hover svg.button-symbol path {
    fill: #3899ec; }
  .top-bar-button.disabled-button {
    cursor: default;
    color: #bcbcbc; }
    .top-bar-button.disabled-button:hover .top-bar-button-content path,
    .top-bar-button.disabled-button .top-bar-button-content path {
      fill: #bcbcbc; }
    .top-bar-button.disabled-button svg.button-symbol path {
      fill: #bcbcbc; }
  .top-bar-button.saved-button, .top-bar-button.in-progress {
    color: #7a92a5;
    fill: #7a92a5; }
    .top-bar-button.saved-button:hover svg.button-symbol path, .top-bar-button.in-progress:hover svg.button-symbol path {
      color: #3899ec;
      fill: #3899ec; }
  .top-bar-button .top-bar-button-content {
    height: 48px;
    width: 100%; }
    .top-bar-button .top-bar-button-content.icon-and-label:hover {
      color: #3899ec; }
  .top-bar-button .button-symbol {
    height: 48px; }
  .top-bar-button .vSign {
    position: absolute;
    top: calc(50% - 14px / 2);
    left: calc(50% - 14px / 2);
    max-width: 14px;
    max-height: 14px;
    width: 14px;
    height: 14px; }
    .top-bar-button .vSign path {
      fill: #3899ec; }
  .top-bar-button .invisible {
    visibility: hidden; }

.zoom-bar-wrapper {
  position: fixed;
  top: 0;
  z-index: 1510;
  font-size: 0; }
  .zoom-bar-wrapper * {
    box-sizing: border-box; }
  .zoom-bar-wrapper .zoom-mode-bar {
    position: relative;
    box-shadow: 0 0 15px 0 rgba(22, 45, 61, 0.5);
    left: -50%;
    white-space: nowrap; }
    .zoom-bar-wrapper .zoom-mode-bar > .left-zoom-bar {
      display: inline-flex;
      position: relative;
      background-color: #60bc57;
      width: calc(100% -  3 * 55px);
      height: 49px;
      font-size: initial;
      align-items: center;
      padding: 0 49px 0 22px;
      cursor: pointer; }
      .zoom-bar-wrapper .zoom-mode-bar > .left-zoom-bar:hover,
      .zoom-bar-wrapper .zoom-mode-bar > .left-zoom-bar:hover > .symbol {
        background: #80c979; }
      .zoom-bar-wrapper .zoom-mode-bar > .left-zoom-bar > .zoom-bar-text {
        font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 14px;
        color: #ffffff; }
      .zoom-bar-wrapper .zoom-mode-bar > .left-zoom-bar > .zoom-bar-symbol {
        position: absolute;
        right: 0;
        top: 0;
        width: 55px;
        height: 49px; }
        .zoom-bar-wrapper .zoom-mode-bar > .left-zoom-bar > .zoom-bar-symbol .top-bar-button-content {
          width: inherit; }
    .zoom-bar-wrapper .zoom-mode-bar > .right-zoom-bar {
      display: inline-block;
      background-color: #fff;
      vertical-align: top; }
      .zoom-bar-wrapper .zoom-mode-bar > .right-zoom-bar > .zoom-bar-btn-seperator,
      .zoom-bar-wrapper .zoom-mode-bar > .right-zoom-bar > .zoom-bar-btn {
        display: inline-block;
        width: 55px;
        height: 49px;
        vertical-align: top; }
        .zoom-bar-wrapper .zoom-mode-bar > .right-zoom-bar > .zoom-bar-btn-seperator:hover g,
        .zoom-bar-wrapper .zoom-mode-bar > .right-zoom-bar > .zoom-bar-btn:hover g {
          fill: #3899ec; }
        .zoom-bar-wrapper .zoom-mode-bar > .right-zoom-bar > .zoom-bar-btn-seperator:hover path,
        .zoom-bar-wrapper .zoom-mode-bar > .right-zoom-bar > .zoom-bar-btn:hover path {
          fill: #3899ec; }
        .zoom-bar-wrapper .zoom-mode-bar > .right-zoom-bar > .zoom-bar-btn-seperator .top-bar-button-content,
        .zoom-bar-wrapper .zoom-mode-bar > .right-zoom-bar > .zoom-bar-btn .top-bar-button-content {
          width: inherit; }
      .zoom-bar-wrapper .zoom-mode-bar > .right-zoom-bar > .zoom-bar-btn-seperator {
        border-right: solid 1px #e3eaf6; }
      .zoom-bar-wrapper .zoom-mode-bar > .right-zoom-bar > .zoom-bar-btn .top-bar-button-content {
        padding: 0 16px; }

.dynamic-pages-combo-box {
  z-index: 1448;
  position: fixed;
  top: 60px;
  left: calc(100% - 396px);
  width: 386px;
  height: 54px;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0 0 14px 0 rgba(22, 51, 70, 0.36);
  display: flex;
  align-items: center; }
  .dynamic-pages-combo-box .inner-route-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .dynamic-pages-combo-box .combo-box-wrapper {
    padding: 0 12px;
    height: 100%;
    border-right: 1px solid #f0f0f0;
    width: 210px;
    box-sizing: border-box; }
  .dynamic-pages-combo-box .go-to-preview {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    margin: 0 12px;
    height: 30px; }
  .dynamic-pages-combo-box .dragable-area {
    width: 30px;
    cursor: move;
    height: 100%;
    border-right: 1px solid #f0f0f0; }
  .dynamic-pages-combo-box .close-area {
    padding: 12px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    height: 100%;
    cursor: pointer; }
  .dynamic-pages-combo-box .close-sign {
    margin-top: 17px; }
  .dynamic-pages-combo-box .nav-btn {
    border-radius: 50%;
    border: 0;
    width: 30px;
    height: 30px;
    background-color: #d3edff;
    box-sizing: border-box;
    margin-left: 23px;
    line-height: 30px;
    padding: 0;
    cursor: pointer; }
    .dynamic-pages-combo-box .nav-btn:hover {
      background-color: #eaf7ff; }
  .dynamic-pages-combo-box .symbol-smallDragHandle {
    margin-top: 21px;
    margin-left: 14px;
    fill: #3899ec; }
  .dynamic-pages-combo-box .dynamic-pages-combobox {
    margin-top: 9px; }
  .dynamic-pages-combo-box.preview .dragable-area {
    border-right: 0 solid #f0f0f0; }
  .dynamic-pages-combo-box.preview .prev-route-wrapper {
    height: 100%;
    padding: 14px 12px 0 12px;
    border-right: 1px solid #f0f0f0;
    box-sizing: border-box; }
  .dynamic-pages-combo-box.preview .nav-btn {
    margin-left: 0; }
    .dynamic-pages-combo-box.preview .nav-btn svg {
      fill: #4eb7f5; }
  .dynamic-pages-combo-box.preview .next-route {
    margin: 3px 12px 0 12px; }

.dynamic-pages-options .dropdown-options {
  max-width: 300px;
  max-height: 300px; }

.dynamic-pages-options .selected-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.show-tools {
  transition: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) transform, 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) opacity; }

.hide-tools {
  transition: 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) transform; }
  .hide-tools.slide-left {
    transform: translateX(-100vw); }
  .hide-tools.slide-right {
    transform: translateX(100vw); }

.half-opacity {
  opacity: 0.5; }

.rootcomp-toolbar {
  box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.36);
  position: fixed;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.94);
  width: 96px;
  z-index: 1055;
  right: 10px; }
  .rootcomp-toolbar.hidden {
    display: none; }
  .rootcomp-toolbar .header-seperator {
    margin: 0 0 9px 0;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #f0f3f5; }
  .rootcomp-toolbar .seperator {
    margin: 9px 0;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #f0f3f5; }
  .rootcomp-toolbar .header {
    height: 30px; }
    .rootcomp-toolbar .header .drag-zone {
      height: 30px;
      cursor: move;
      background: transparent;
      width: 100%; }
      .rootcomp-toolbar .header .drag-zone .drag-handle {
        left: calc(50% - 6px);
        position: absolute;
        top: 13px;
        color: #2d4150; }
        .rootcomp-toolbar .header .drag-zone .drag-handle > svg {
          display: block; }
    .rootcomp-toolbar .header .close {
      float: right;
      margin: 6px 7px 0 0;
      border: 0;
      background: transparent;
      padding: 7px;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0; }
      .rootcomp-toolbar .header .close > svg {
        fill: #2d4150;
        display: block; }
      .rootcomp-toolbar .header .close:hover path {
        fill: #3899ec; }
    .rootcomp-toolbar .header .help {
      font-size: 12px;
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      color: #2d4150;
      float: left;
      margin: 9px 0 0 13px;
      padding: 0;
      border: 0;
      background: transparent;
      cursor: pointer;
      position: absolute;
      top: 0; }
      .rootcomp-toolbar .header .help:hover {
        color: #3899ec; }
      .rootcomp-toolbar .header .help:focus {
        outline: none; }
  .rootcomp-toolbar:hover {
    background-color: white; }
    .rootcomp-toolbar:hover .inner.form .row .control-text-input > input {
      background: #eaf7ff; }
    .rootcomp-toolbar:hover .inner.form .row .input-stepper:not(.disabled) {
      background: #eaf7ff; }
  .rootcomp-toolbar .inner {
    text-align: center;
    display: block; }
    .rootcomp-toolbar .inner.button-grid {
      font-size: 0; }
      .rootcomp-toolbar .inner.button-grid .button,
      .rootcomp-toolbar .inner.button-grid button,
      .rootcomp-toolbar .inner.button-grid .control-button {
        display: inline-block;
        width: 33px;
        height: 33px;
        background-color: transparent;
        border: 0;
        border-radius: 6px;
        color: #2d4150;
        font-size: 15px;
        line-height: 33px;
        margin: 3px;
        padding: 0;
        vertical-align: top;
        min-width: initial;
        cursor: pointer;
        text-align: center; }
        .rootcomp-toolbar .inner.button-grid .button:hover,
        .rootcomp-toolbar .inner.button-grid button:hover,
        .rootcomp-toolbar .inner.button-grid .control-button:hover {
          background-color: #eaf7ff; }
        .rootcomp-toolbar .inner.button-grid .button:active,
        .rootcomp-toolbar .inner.button-grid button:active,
        .rootcomp-toolbar .inner.button-grid .control-button:active {
          background-color: #4eb7f5; }
          .rootcomp-toolbar .inner.button-grid .button:active svg > path,
          .rootcomp-toolbar .inner.button-grid button:active svg > path,
          .rootcomp-toolbar .inner.button-grid .control-button:active svg > path {
            fill: #fff; }
        .rootcomp-toolbar .inner.button-grid .button svg,
        .rootcomp-toolbar .inner.button-grid button svg,
        .rootcomp-toolbar .inner.button-grid .control-button svg {
          display: inline-block;
          position: relative; }
          .rootcomp-toolbar .inner.button-grid .button svg > path,
          .rootcomp-toolbar .inner.button-grid button svg > path,
          .rootcomp-toolbar .inner.button-grid .control-button svg > path {
            fill: #2d4150; }
        .rootcomp-toolbar .inner.button-grid .button.disabled,
        .rootcomp-toolbar .inner.button-grid button.disabled,
        .rootcomp-toolbar .inner.button-grid .control-button.disabled {
          background-color: transparent;
          cursor: default; }
          .rootcomp-toolbar .inner.button-grid .button.disabled svg > path,
          .rootcomp-toolbar .inner.button-grid button.disabled svg > path,
          .rootcomp-toolbar .inner.button-grid .control-button.disabled svg > path {
            fill: #bcbcbc; }
    .rootcomp-toolbar .inner.form {
      text-align: left;
      padding: 0 12px; }
      .rootcomp-toolbar .inner.form .row {
        width: 100%;
        height: 30px;
        display: flex;
        margin-bottom: 5px; }
        .rootcomp-toolbar .inner.form .row > span {
          line-height: 30px;
          color: #2b5672;
          padding: 0 7px 0 7px;
          font-size: 12px; }
          .rootcomp-toolbar .inner.form .row > span > svg {
            height: 85%; }
            .rootcomp-toolbar .inner.form .row > span > svg > path {
              fill: #2b5672; }
        .rootcomp-toolbar .inner.form .row:hover .input-stepper:not(.disabled) {
          background: #d3edff; }
        .rootcomp-toolbar .inner.form .row .input-stepper {
          flex: 1;
          float: right;
          padding: 0 2px !important;
          background-color: transparent; }
          .rootcomp-toolbar .inner.form .row .input-stepper > input {
            border: 0 solid transparent;
            color: #2b5672;
            padding: 5px 8px 4px 4px;
            text-align: right;
            transition: background 0.1s ease;
            will-change: background;
            background-color: rgba(250, 250, 250, 0); }
            .rootcomp-toolbar .inner.form .row .input-stepper > input:focus {
              background: rgba(250, 250, 250, 0); }
              .rootcomp-toolbar .inner.form .row .input-stepper > input:focus:hover {
                background: rgba(250, 250, 250, 0); }
          .rootcomp-toolbar .inner.form .row .input-stepper.disabled {
            opacity: 1; }
            .rootcomp-toolbar .inner.form .row .input-stepper.disabled > input {
              color: #bcbcbc !important; }
          .rootcomp-toolbar .inner.form .row .input-stepper.edited:after {
            border: 1px solid #4eb7f5; }
            .rootcomp-toolbar .inner.form .row .input-stepper.edited:after:hover {
              border: 1px solid #4eb7f5; }
          .rootcomp-toolbar .inner.form .row .input-stepper.degrees input {
            padding: 4px 0; }
        .rootcomp-toolbar .inner.form .row.show-on-all-pages-row {
          height: auto;
          display: block;
          padding-top: 5px; }
          .rootcomp-toolbar .inner.form .row.show-on-all-pages-row .control-switch {
            justify-content: center; }
          .rootcomp-toolbar .inner.form .row.show-on-all-pages-row .show-on-all-pages .symbol-switch {
            float: none; }
  .rootcomp-toolbar .boolean-control .label-checkbox {
    display: inline-block;
    cursor: pointer;
    color: #2b5672;
    font-size: 12px;
    margin: 0 0 0 6px;
    line-height: 14px;
    width: 51px;
    position: absolute;
    white-space: normal;
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  .rootcomp-toolbar .tool-selector {
    display: inline-block; }
    .rootcomp-toolbar .tool-selector .drop-down {
      box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.36);
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      border-radius: 10px;
      background-color: #fff;
      padding: 3px;
      width: 40px; }
      .rootcomp-toolbar .tool-selector .drop-down.grid-2-col {
        width: 82px; }
      .rootcomp-toolbar .tool-selector .drop-down.grid-3-col {
        width: 120px; }
      .rootcomp-toolbar .tool-selector .drop-down.grid-4-col {
        width: 166px; }
      .rootcomp-toolbar .tool-selector .drop-down .control-button {
        width: 30px;
        height: 30px;
        display: inline-block;
        margin: 2px; }
    .rootcomp-toolbar .tool-selector.disabled .button {
      cursor: default; }
    .rootcomp-toolbar .tool-selector.disabled .button:hover,
    .rootcomp-toolbar .tool-selector.disabled .button:active {
      background-color: transparent !important; }
    .rootcomp-toolbar .tool-selector.disabled .small-indicator {
      border-bottom: 2px solid #bcbcbc !important;
      border-right: 2px solid #bcbcbc !important; }
    .rootcomp-toolbar .tool-selector .small-indicator {
      box-sizing: border-box;
      width: 4px;
      height: 4px;
      border-top: 1px solid transparent;
      border-left: 1px solid transparent;
      border-bottom: 2px solid #3899ec;
      border-right: 2px solid #3899ec;
      position: absolute;
      bottom: 6px;
      right: 6px; }
  .rootcomp-toolbar .layout-title {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 12px;
    display: block;
    text-align: center;
    color: #2b5672;
    line-height: 24px;
    overflow: hidden; }
    .rootcomp-toolbar .layout-title.show-on-all-pages-title {
      line-height: normal; }

.left-bar-items {
  display: inline-block; }
  .left-bar-items .left-bar-item {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 16px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.96) 0%, rgba(238, 238, 238, 0.96) 100%);
    box-shadow: 0 2px 5px 0 rgba(22, 45, 61, 0.58);
    border-radius: 24px;
    line-height: 48px;
    height: 48px;
    position: relative;
    cursor: pointer;
    padding-right: 46px;
    margin-bottom: 12px;
    clear: left;
    z-index: 1050; }
    .left-bar-items .left-bar-item.pages-panel-pp.selected svg.ear path {
      fill: #00aaaf; }
    .left-bar-items .left-bar-item.pages-panel-pp svg.icon {
      top: 12px;
      left: 12px; }
      .left-bar-items .left-bar-item.pages-panel-pp svg.icon path {
        fill: #00aaaf; }
    .left-bar-items .left-bar-item.bg-panel.selected svg.ear path {
      fill: #f8a239; }
    .left-bar-items .left-bar-item.bg-panel svg.icon {
      top: 12px;
      left: 12px; }
      .left-bar-items .left-bar-item.bg-panel svg.icon path {
        fill: #f8a239; }
    .left-bar-items .left-bar-item.add-panel.selected svg.ear path {
      fill: #3797ea; }
    .left-bar-items .left-bar-item.add-panel svg.icon {
      top: 11px;
      left: 11px; }
      .left-bar-items .left-bar-item.add-panel svg.icon path {
        fill: #3797ea; }
    .left-bar-items .left-bar-item.app-market-panel.selected svg.ear path {
      fill: #d0427c; }
    .left-bar-items .left-bar-item.app-market-panel svg.icon {
      top: 12px;
      left: 12px; }
      .left-bar-items .left-bar-item.app-market-panel svg.icon path {
        fill: #d0427c; }
    .left-bar-items .left-bar-item.app-market-panel-20.selected svg.ear path {
      fill: #d0427c; }
    .left-bar-items .left-bar-item.app-market-panel-20 svg.icon {
      top: 12px;
      left: 12px; }
      .left-bar-items .left-bar-item.app-market-panel-20 svg.icon path {
        fill: #d0427c; }
    .left-bar-items .left-bar-item.my-uploads-panel.selected svg.ear path {
      fill: #fa7c33; }
    .left-bar-items .left-bar-item.my-uploads-panel svg.icon {
      top: 13px;
      left: 11px; }
      .left-bar-items .left-bar-item.my-uploads-panel svg.icon path {
        fill: #fa7c33; }
    .left-bar-items .left-bar-item.blog-panel.selected svg.ear path {
      fill: #60bc57; }
    .left-bar-items .left-bar-item.blog-panel svg.icon {
      top: 13px;
      left: 10px; }
      .left-bar-items .left-bar-item.blog-panel svg.icon path {
        fill: #60bc57; }
    .left-bar-items .left-bar-item.new-blog-panel.selected svg.ear path {
      fill: #faad4d; }
    .left-bar-items .left-bar-item.new-blog-panel svg.icon {
      top: 9px;
      left: 15px; }
      .left-bar-items .left-bar-item.new-blog-panel svg.icon path {
        fill: #faad4d; }
    .left-bar-items .left-bar-item.shoutout-panel.selected svg.ear path {
      fill: #ffc233; }
    .left-bar-items .left-bar-item.shoutout-panel svg.icon {
      top: 13px;
      left: 10px; }
      .left-bar-items .left-bar-item.shoutout-panel svg.icon path {
        fill: #ffc233; }
    .left-bar-items .left-bar-item.wix-store-panel.selected svg.ear path {
      fill: #00adf5; }
    .left-bar-items .left-bar-item.wix-store-panel svg.icon {
      top: 9px;
      left: 13px; }
      .left-bar-items .left-bar-item.wix-store-panel svg.icon path {
        fill: #00adf5; }
    .left-bar-items .left-bar-item.wix-bookings-panel.selected svg.ear path {
      fill: #733ca6; }
    .left-bar-items .left-bar-item.wix-bookings-panel svg.icon {
      top: 9px;
      left: 11px; }
      .left-bar-items .left-bar-item.wix-bookings-panel svg.icon path {
        fill: #733ca6; }
    .left-bar-items .left-bar-item.hidden-items-panel.selected svg.ear path {
      fill: #d0427d; }
    .left-bar-items .left-bar-item.hidden-items-panel svg.icon {
      top: 13px;
      left: 11px; }
      .left-bar-items .left-bar-item.hidden-items-panel svg.icon path {
        fill: #d0427d; }
    .left-bar-items .left-bar-item.mobile-elements-panel.selected svg.ear path {
      fill: #3899eb; }
    .left-bar-items .left-bar-item.mobile-elements-panel svg.icon {
      top: 11px;
      left: 16px; }
      .left-bar-items .left-bar-item.mobile-elements-panel svg.icon path {
        fill: #3899eb; }
    .left-bar-items .left-bar-item.layout-optimizer-panel.selected svg.ear path {
      fill: #45c8c1; }
    .left-bar-items .left-bar-item.layout-optimizer-panel svg.icon {
      top: 13px;
      left: 13px; }
      .left-bar-items .left-bar-item.layout-optimizer-panel svg.icon path {
        fill: #45c8c1; }
    .left-bar-items .left-bar-item:hover {
      background: linear-gradient(to bottom, white 0%, #eeeeee 100%); }
      .left-bar-items .left-bar-item:hover svg.icon path {
        fill: #2d4150; }
    .left-bar-items .left-bar-item svg.icon {
      position: absolute; }
    .left-bar-items .left-bar-item .mask {
      width: 4px;
      position: absolute;
      left: 58px;
      height: 77px;
      top: -13px; }
    .left-bar-items .left-bar-item.first-panel .mask {
      top: 0;
      height: 67px; }
    .left-bar-items .left-bar-item .text {
      padding-left: 48px;
      color: #162d3d;
      white-space: nowrap;
      display: inline-block; }
    .left-bar-items .left-bar-item .notifications {
      position: absolute;
      top: -3px;
      right: 0;
      transform: translate3d(50%, 0, 0);
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      background: #ee5951;
      color: #fff;
      padding: 4px;
      font-size: 13px;
      display: block;
      text-align: center;
      line-height: 13px;
      min-width: 13px;
      height: 13px;
      border-radius: 11px; }
  .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item:not(.selected):hover, .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.force-hover {
    width: inherit;
    z-index: 1060; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item:not(.selected):hover .text, .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.force-hover .text {
      display: inline-block;
      width: auto;
      padding-right: 18px; }
  .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click {
    width: inherit;
    display: inline-block;
    float: left;
    clear: left; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click .text {
      display: inline-block;
      width: auto;
      padding-right: 18px; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.pages-panel-pp:not(.selected) svg.icon path {
      fill: #00aaaf; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.bg-panel:not(.selected) svg.icon path {
      fill: #f8a239; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.add-panel:not(.selected) svg.icon path {
      fill: #3797ea; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.app-market-panel:not(.selected) svg.icon path {
      fill: #d0427c; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.app-market-panel-20:not(.selected) svg.icon path {
      fill: #d0427c; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.my-uploads-panel:not(.selected) svg.icon path {
      fill: #fa7c33; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.blog-panel:not(.selected) svg.icon path {
      fill: #60bc57; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.new-blog-panel:not(.selected) svg.icon path {
      fill: #faad4d; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.shoutout-panel:not(.selected) svg.icon path {
      fill: #ffc233; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.wix-store-panel:not(.selected) svg.icon path {
      fill: #00adf5; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.wix-bookings-panel:not(.selected) svg.icon path {
      fill: #733ca6; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.hidden-items-panel:not(.selected) svg.icon path {
      fill: #d0427d; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.mobile-elements-panel:not(.selected) svg.icon path {
      fill: #3899eb; }
    .left-bar-items.menu-collapsed:not(.menu-opened) .left-bar-item.wait-for-click.layout-optimizer-panel:not(.selected) svg.icon path {
      fill: #45c8c1; }
  .left-bar-items.menu-collapsed .left-bar-item {
    width: 48px;
    padding-right: 0; }
    .left-bar-items.menu-collapsed .left-bar-item .text {
      display: none; }
    .left-bar-items.menu-collapsed .left-bar-item svg.icon path {
      fill: #2d4150; }
    .left-bar-items.menu-collapsed .left-bar-item.selected {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: none;
      z-index: 1150; }
      .left-bar-items.menu-collapsed .left-bar-item.selected svg.icon path {
        fill: #fff; }
      .left-bar-items.menu-collapsed .left-bar-item.selected .notifications {
        display: none; }
    .left-bar-items.menu-collapsed .left-bar-item.pages-panel-pp:not(.selected):hover svg.icon path {
      fill: #00aaaf; }
    .left-bar-items.menu-collapsed .left-bar-item.pages-panel-pp.selected .mask {
      background-color: #00aaaf; }
    .left-bar-items.menu-collapsed .left-bar-item.bg-panel:not(.selected):hover svg.icon path {
      fill: #f8a239; }
    .left-bar-items.menu-collapsed .left-bar-item.bg-panel.selected .mask {
      background-color: #f8a239; }
    .left-bar-items.menu-collapsed .left-bar-item.add-panel:not(.selected):hover svg.icon path {
      fill: #3797ea; }
    .left-bar-items.menu-collapsed .left-bar-item.add-panel.selected .mask {
      background-color: #3797ea; }
    .left-bar-items.menu-collapsed .left-bar-item.app-market-panel:not(.selected):hover svg.icon path {
      fill: #d0427c; }
    .left-bar-items.menu-collapsed .left-bar-item.app-market-panel.selected .mask {
      background-color: #d0427c; }
    .left-bar-items.menu-collapsed .left-bar-item.app-market-panel-20:not(.selected):hover svg.icon path {
      fill: #d0427c; }
    .left-bar-items.menu-collapsed .left-bar-item.app-market-panel-20.selected .mask {
      background-color: #d0427c; }
    .left-bar-items.menu-collapsed .left-bar-item.my-uploads-panel:not(.selected):hover svg.icon path {
      fill: #fa7c33; }
    .left-bar-items.menu-collapsed .left-bar-item.my-uploads-panel.selected .mask {
      background-color: #fa7c33; }
    .left-bar-items.menu-collapsed .left-bar-item.blog-panel:not(.selected):hover svg.icon path {
      fill: #60bc57; }
    .left-bar-items.menu-collapsed .left-bar-item.blog-panel.selected .mask {
      background-color: #60bc57; }
    .left-bar-items.menu-collapsed .left-bar-item.new-blog-panel:not(.selected):hover svg.icon path {
      fill: #faad4d; }
    .left-bar-items.menu-collapsed .left-bar-item.new-blog-panel.selected .mask {
      background-color: #faad4d; }
    .left-bar-items.menu-collapsed .left-bar-item.shoutout-panel:not(.selected):hover svg.icon path {
      fill: #ffc233; }
    .left-bar-items.menu-collapsed .left-bar-item.shoutout-panel.selected .mask {
      background-color: #ffc233; }
    .left-bar-items.menu-collapsed .left-bar-item.wix-store-panel:not(.selected):hover svg.icon path {
      fill: #00adf5; }
    .left-bar-items.menu-collapsed .left-bar-item.wix-store-panel.selected .mask {
      background-color: #00adf5; }
    .left-bar-items.menu-collapsed .left-bar-item.wix-bookings-panel:not(.selected):hover svg.icon path {
      fill: #733ca6; }
    .left-bar-items.menu-collapsed .left-bar-item.wix-bookings-panel.selected .mask {
      background-color: #733ca6; }
    .left-bar-items.menu-collapsed .left-bar-item.hidden-items-panel:not(.selected):hover svg.icon path {
      fill: #d0427d; }
    .left-bar-items.menu-collapsed .left-bar-item.hidden-items-panel.selected .mask {
      background-color: #d0427d; }
    .left-bar-items.menu-collapsed .left-bar-item.mobile-elements-panel:not(.selected):hover svg.icon path {
      fill: #3899eb; }
    .left-bar-items.menu-collapsed .left-bar-item.mobile-elements-panel.selected .mask {
      background-color: #3899eb; }
    .left-bar-items.menu-collapsed .left-bar-item.layout-optimizer-panel:not(.selected):hover svg.icon path {
      fill: #45c8c1; }
    .left-bar-items.menu-collapsed .left-bar-item.layout-optimizer-panel.selected .mask {
      background-color: #45c8c1; }
  .left-bar-items.menu-collapsed svg.ear {
    top: -20px;
    position: absolute;
    left: -9px; }
    .left-bar-items.menu-collapsed svg.ear.ear-top {
      top: -4px;
      left: -5px; }

.left-bar {
  position: absolute;
  top: 32px;
  left: 12px;
  transition: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) left, 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) opacity; }
  .left-bar.is-hidden {
    transition-duration: 0.4s; }
    .left-bar.is-hidden .mask {
      display: none; }
  .left-bar.half-opacity {
    opacity: 0.5; }

.mouseCatcher.parentSize, .mouseCatcher.parentSizeAbs {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

.mouseCatcher.parentSizeAbs {
  position: absolute; }

.mouseCatcher.draggable {
  cursor: move; }

.mouseCatcher > .margins-indicator {
  position: absolute; }
  .mouseCatcher > .margins-indicator > .left,
  .mouseCatcher > .margins-indicator > .right {
    background: rgba(122, 146, 165, 0.3) url("//static.parastorage.com/services/santa-resources/resources/editor/baseUI/panelInputs/dead-areas-indication-pattern.v2.png") repeat;
    height: 100%;
    position: absolute; }
  .mouseCatcher > .margins-indicator > .left {
    left: 0; }
  .mouseCatcher > .margins-indicator > .right {
    right: 0; }

.editor-stage {
  position: relative;
  text-align: center;
  height: 100%;
  width: 100%;
  transition: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) top; }
  .editor-stage.tools-hidden {
    transition-duration: 0.4s; }
    .editor-stage.tools-hidden .block-layer .shadow {
      top: -42px;
      transition-duration: 0.4s; }
  .editor-stage .site-cover {
    position: relative;
    min-height: 100%;
    overflow: hidden; }
  .editor-stage .scrollable-editor-stage {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden; }
    .editor-stage .scrollable-editor-stage.preview-mode, .editor-stage .scrollable-editor-stage.scrolling-disabled {
      overflow: hidden; }
  .editor-stage .fixed-editor-stage {
    position: absolute;
    top: 0;
    height: 100%;
    pointer-events: none;
    visibility: hidden; }
    .editor-stage .fixed-editor-stage .fixed-editor-stage-contents-wrapper {
      pointer-events: auto;
      visibility: visible; }
      .editor-stage .fixed-editor-stage .fixed-editor-stage-contents-wrapper.no-pointer-events {
        pointer-events: none; }
  .editor-stage .mobile-optimized-teaser {
    font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    position: absolute;
    z-index: 1;
    top: 20px;
    right: 20px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.96);
    box-shadow: 0 0 16px 0 rgba(57, 86, 113, 0.24);
    width: 238px;
    height: 115px;
    pointer-events: auto;
    padding: 3px 17px 14px 23px;
    text-align: left;
    cursor: default;
    color: #2b5672; }
    .editor-stage .mobile-optimized-teaser h1 {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-size: 18px; }
    .editor-stage .mobile-optimized-teaser a {
      color: #3899ec;
      cursor: pointer; }
  .editor-stage .block-layer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
    .editor-stage .block-layer .shadow {
      position: absolute;
      top: 0;
      box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.2);
      transition: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) top, 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) height; }
    .editor-stage .block-layer .side {
      position: absolute;
      height: 100%;
      background-color: #f0f3f5; }
      .editor-stage .block-layer .side.left {
        left: 0; }
      .editor-stage .block-layer .side.right {
        right: 0; }
      .editor-stage .block-layer .side.bottom {
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%; }
      .editor-stage .block-layer .side.transition {
        transition: width 0.5s, height 0.5s; }

#gfpp {
  height: 34px;
  white-space: nowrap;
  color: #000;
  text-align: left;
  user-select: none;
  z-index: 1058; }
  #gfpp .gfpp-btn-base, #gfpp .gfpp-btn {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-size: 14px;
    display: inline-block;
    box-sizing: border-box;
    height: 32px;
    background: linear-gradient(to bottom, white 0%, #eeeeee 100%);
    box-shadow: 0 2px 6px 0 rgba(22, 45, 61, 0.55);
    border-radius: 16px; }
  #gfpp .gfpp-btn {
    min-width: 32px;
    cursor: pointer;
    position: relative;
    color: #2d4150;
    line-height: 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 0 7px 0 0;
    padding: 0;
    font-size: 14px;
    list-style: none;
    vertical-align: top; }
    #gfpp .gfpp-btn:last-child {
      margin-right: 0; }
    #gfpp .gfpp-btn.gfpp-pin {
      left: 11px; }
      #gfpp .gfpp-btn.gfpp-pin svg path {
        fill: #fb7d33; }
    #gfpp .gfpp-btn.connected:not(.selected) svg path {
      fill: #60bc57; }
    #gfpp .gfpp-btn.applied:not(.selected) svg path {
      fill: #60bc57; }
    #gfpp .gfpp-btn.applied:not(.selected):hover svg path {
      fill: #80c979; }
    #gfpp .gfpp-btn.disabled, #gfpp .gfpp-btn.disabled:hover {
      color: #8a8a8a;
      cursor: default; }
      #gfpp .gfpp-btn.disabled svg *, #gfpp .gfpp-btn.disabled:hover svg * {
        fill: #8a8a8a; }
    #gfpp .gfpp-btn svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
      #gfpp .gfpp-btn svg * {
        fill: #2d4150; }
    #gfpp .gfpp-btn:hover {
      color: #3899ec; }
      #gfpp .gfpp-btn:hover svg * {
        fill: #3899ec; }
    #gfpp .gfpp-btn.selected {
      background: #3899ec;
      color: #fff;
      border: 2px solid #fff;
      box-shadow: 0 2px 6px 0 rgba(22, 45, 61, 0.65);
      line-height: 30px; }
      #gfpp .gfpp-btn.selected svg * {
        fill: #ffffff; }
      #gfpp .gfpp-btn.selected .gfpp-label {
        padding: 0 11px; }
    #gfpp .gfpp-btn.more-btn {
      margin: 0 5px 0 0;
      left: 0;
      top: 0;
      cursor: default;
      position: absolute; }
      #gfpp .gfpp-btn.more-btn span {
        display: inline-block; }
    #gfpp .gfpp-btn .gfpp-label {
      padding: 0 13px; }
    #gfpp .gfpp-btn .gfpp-icon {
      font-size: 18px; }

.rightClickMenu {
  box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.36);
  z-index: 1446;
  position: absolute;
  border-radius: 8px;
  padding: 14px 0 16px 0;
  background-color: rgba(255, 255, 255, 0.95);
  color: #1d2d3c;
  user-select: none; }
  .rightClickMenu.fixed-position {
    position: fixed; }
  .rightClickMenu ul:after {
    content: '';
    height: 1px;
    display: block;
    border: 0;
    background-color: #d9e1e8;
    margin: 10px 24px 12px 24px; }
  .rightClickMenu ul:last-of-type:after {
    display: none; }
  .rightClickMenu li {
    line-height: 25px;
    height: 25px;
    padding-left: 24px;
    padding-right: 24px;
    position: relative;
    cursor: pointer; }
    .rightClickMenu li:hover > .subMenu {
      display: block; }
    .rightClickMenu li.disabled {
      color: #bababa;
      cursor: default; }
  .rightClickMenu .hoverable:hover {
    background-color: #daedfe; }
  .rightClickMenu .subMenu {
    display: none;
    position: absolute;
    left: 100%;
    margin-left: -10px;
    top: -14px;
    background-color: white;
    z-index: 1; }
  .rightClickMenu .icon {
    line-height: initial;
    position: absolute;
    left: 24px;
    top: 50%; }
    .rightClickMenu .icon + .label {
      margin-left: 24px; }
  .rightClickMenu .item-basic .icon {
    width: 14px;
    height: 14px;
    margin-top: -7px; }
    .rightClickMenu .item-basic .icon svg {
      width: 14px;
      height: 14px; }
    .rightClickMenu .item-basic .icon span {
      position: absolute;
      width: 14px;
      height: 14px;
      border-radius: 2px; }
  .rightClickMenu .item-shortcut .icon {
    width: 14px;
    height: 14px;
    margin-top: -7px; }
    .rightClickMenu .item-shortcut .icon svg {
      width: 14px;
      height: 14px; }
  .rightClickMenu .label {
    font-size: 13px;
    display: inline-block;
    white-space: nowrap; }
  .rightClickMenu .label-sub-item {
    margin-right: 46px; }
  .rightClickMenu .label-toggle {
    cursor: default;
    margin-right: 86px; }
  .rightClickMenu .label-shortcut {
    margin-right: 96px; }
  .rightClickMenu .action {
    position: absolute;
    right: 24px;
    bottom: -1px; }
    .rightClickMenu .action .symbol.symbol-switch svg {
      position: relative;
      left: 8px; }
  .rightClickMenu .action-shortcut {
    text-align: right;
    font-size: 10px;
    line-height: 25px;
    height: 25px; }
  .rightClickMenu .action-expand {
    width: 4px;
    height: 4px;
    top: 50%;
    margin-top: -2px;
    transform: rotate(45deg);
    border-top: 1px solid;
    border-right: 1px solid; }
  .rightClickMenu.open-sub-menu-left .subMenu {
    left: inherit;
    right: 100%; }

.recommended-mobile-header-height {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .recommended-mobile-header-height > .header-footer-info-tooltip {
    position: absolute;
    background: #f0f3f5;
    padding-right: 25px;
    max-width: 200px;
    height: 28px;
    pointer-events: auto; }
    .recommended-mobile-header-height > .header-footer-info-tooltip .header-tooltip-content {
      display: flex;
      align-items: center; }
      .recommended-mobile-header-height > .header-footer-info-tooltip .header-tooltip-content > .recommended-mobile-header-height-title {
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 12px;
        display: inline-block;
        line-height: 14px;
        margin-left: 10px;
        text-align: start;
        color: #7a92a5; }
      .recommended-mobile-header-height > .header-footer-info-tooltip .header-tooltip-content > .info-icon {
        fill: #7a92a5; }
        .recommended-mobile-header-height > .header-footer-info-tooltip .header-tooltip-content > .info-icon circle {
          fill: transparent; }
  .recommended-mobile-header-height > svg.header-recommended-height-separator {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    shape-rendering: crispEdges; }
    .recommended-mobile-header-height > svg.header-recommended-height-separator > line.header-recommended-height-separator {
      stroke-width: 1;
      stroke: #7a92a5;
      stroke-dasharray: 10 5; }

.sections-reorganize-exp {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; }
  .sections-reorganize-exp .spacer-handle {
    pointer-events: auto;
    position: relative;
    height: 12px;
    background-color: #3899ec;
    margin: 0 auto;
    cursor: row-resize;
    display: flex;
    align-items: center;
    justify-content: center; }
  .sections-reorganize-exp > .section-separators {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    shape-rendering: crispEdges; }
    .sections-reorganize-exp > .section-separators > .section-separator {
      stroke-width: 1;
      stroke: #d9e1e8;
      stroke-dasharray: 10 5; }

.section-controls-button {
  pointer-events: auto;
  cursor: pointer;
  display: inline-block;
  margin-right: 6px;
  border-radius: 18px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(22, 45, 61, 0.36);
  min-width: 36px;
  height: 36px;
  line-height: 36px;
  position: relative;
  color: #2d4150; }
  .section-controls-button > .symbol {
    position: absolute;
    top: 50%;
    left: 18px;
    transform: translate(-50%, -50%); }
    .section-controls-button > .symbol * {
      fill: #2d4150; }
  .section-controls-button:not(.with-label):hover {
    background-color: #3899ec; }
    .section-controls-button:not(.with-label):hover > .symbol * {
      fill: #fff; }
  .section-controls-button.with-label {
    padding: 0 10px 0 36px; }
    .section-controls-button.with-label:hover {
      background-color: #3899ec;
      color: #fff; }
      .section-controls-button.with-label:hover > .symbol * {
        fill: #fff; }
  .section-controls-button.disabled {
    background-color: #fff !important;
    cursor: default; }
    .section-controls-button.disabled > .symbol * {
      fill: #bcbcbc !important; }

.section-controls {
  position: absolute;
  left: 0;
  width: 100%;
  pointer-events: none; }
  .section-controls > .highlight {
    display: none;
    height: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    border: 3px solid #3899ec;
    align-items: center;
    justify-content: flex-end;
    background-color: rgba(94, 255, 255, 0.2);
    pointer-events: auto;
    cursor: move; }
    .show-highlight.section-controls > .highlight {
      display: flex; }
    .no-drag-handle.section-controls > .highlight > .drag-handle {
      display: none; }
    .header-footer-blocker.section-controls > .highlight {
      display: flex;
      border: none;
      background-color: transparent;
      cursor: default; }
    .section-controls > .highlight.with-shadow {
      box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.2); }
    .section-controls > .highlight > .drag-handle {
      max-height: 36px;
      width: 18px;
      margin-right: -21px;
      background-color: #3899ec;
      border-radius: 0 18px 18px 0;
      cursor: move;
      display: flex;
      align-items: center;
      justify-content: center; }
      .section-controls > .highlight > .drag-handle > .drag-handle-dots {
        margin-left: -5px; }
  .section-controls > .section-buttons-wrapper {
    position: absolute;
    top: 0;
    max-height: 74px;
    width: 168px; }
    .section-controls > .section-buttons-wrapper > .section-buttons {
      position: absolute;
      top: 50%;
      margin-top: -18px; }
      .section-controls > .section-buttons-wrapper > .section-buttons .header-footer-info {
        height: 36px;
        pointer-events: auto;
        display: flex;
        align-items: center; }
        .section-controls > .section-buttons-wrapper > .section-buttons .header-footer-info > .info-icon circle {
          fill: transparent; }
        .section-controls > .section-buttons-wrapper > .section-buttons .header-footer-info > .info-icon path {
          fill: #7a92a5; }
        .section-controls > .section-buttons-wrapper > .section-buttons .header-footer-info > .info-text {
          padding-left: 10px;
          color: #7a92a5; }
        .section-controls > .section-buttons-wrapper > .section-buttons .header-footer-info:hover > .info-icon path {
          fill: #3899ec; }

.attach-highlight .highlight-container-exp {
  width: 300px;
  height: 300px;
  position: absolute;
  text-align: center;
  background-color: rgba(53, 234, 255, 0.05); }
  .attach-highlight .highlight-container-exp label {
    position: absolute;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    padding: 5px 7px;
    top: 3px;
    color: #fff;
    background-color: #3899ec;
    transform: translate(-50%); }
  .attach-highlight .highlight-container-exp.master {
    background-color: rgba(255, 153, 0, 0.05); }
    .attach-highlight .highlight-container-exp.master label {
      background-color: #fb7d33; }
    .attach-highlight .highlight-container-exp.master ~ .attach-overlay {
      background-color: rgba(255, 153, 0, 0.1); }
  .attach-highlight .highlight-container-exp.box {
    background-color: rgba(208, 66, 125, 0.1); }
    .attach-highlight .highlight-container-exp.box label {
      background-color: #d0427d; }
    .attach-highlight .highlight-container-exp.box ~ .attach-overlay {
      background-color: rgba(208, 66, 125, 0.08); }

.attach-highlight .attach-overlay {
  position: absolute;
  background-color: rgba(53, 234, 255, 0.1); }

.highlight-container {
  width: 300px;
  height: 300px;
  border: 2px dashed #35eaff;
  position: absolute;
  text-align: center;
  background-color: rgba(53, 234, 255, 0.1); }
  .highlight-container label {
    position: relative;
    top: 8px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.7);
    border-radius: 3px 3px 3px 3px;
    font-weight: bold;
    margin: 0;
    padding: 5px 7px;
    color: #fff;
    background-color: rgba(53, 234, 255, 0.7); }
  .highlight-container.master {
    border-color: #ff9900;
    background-color: rgba(255, 153, 0, 0.1); }
    .highlight-container.master label {
      background-color: rgba(255, 153, 0, 0.7); }

.flying-musa {
  position: fixed;
  width: 143px;
  height: 72px; }

.constraint-area {
  position: absolute;
  text-align: center;
  background-color: rgba(108, 203, 101, 0.32); }
  .constraint-area .box {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: 1px solid #60bc57;
    border-bottom: 1px dashed #60bc57;
    background-color: rgba(108, 203, 101, 0.8);
    margin-left: 50px;
    margin-right: 50px; }
  .constraint-area label {
    line-height: 34px;
    color: #fff;
    font-size: 14px; }

.negative-constraint-indication.constraint-area {
  background-color: rgba(56, 153, 236, 0.12);
  background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/baseUI/panelInputs/dead-areas-indication-pattern.v2.png");
  background-repeat: repeat; }

.negative-constraint-indication .box {
  display: none; }

.snap-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100; }
  .snap-layer svg {
    height: 100%;
    width: 100%;
    shape-rendering: crispEdges; }
    .snap-layer svg line {
      stroke: #ff00ff;
      stroke-width: 1px; }
    .snap-layer svg rect {
      stroke: #92fafe;
      stroke-width: 1px;
      fill: none; }
      .snap-layer svg rect.components-ui-color-orange {
        stroke: #fb7d33; }

.highlightFrame {
  box-sizing: border-box; }
  .highlightFrame.thick {
    border: 3px solid #3899ec; }
  .highlightFrame.with-opacity {
    opacity: 0.6;
    border: 3px solid #3899ec; }
  .highlightFrame.show-on-all-pages {
    border-color: #fb7d33; }
  .highlightFrame.with-background {
    background: rgba(56, 153, 236, 0.3);
    outline: 1px solid #3899ec; }
    .highlightFrame.with-background.with-background-opacity {
      background: rgba(211, 237, 255, 0.52);
      opacity: 0.3;
      outline: 1px solid #d3edff; }

.gridLines {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  shape-rendering: crispEdges; }
  .gridLines.fade {
    opacity: 0; }
  .gridLines line {
    stroke-width: 1; }
    .gridLines line.backLine {
      stroke: #FFFFFF; }
    .gridLines line.frontLine {
      stroke: #000000;
      stroke-dasharray: 5 5; }

.edit-box.draggable {
  cursor: move; }

.edit-box.multi-select .layout .handle.handle-resize-corner,
.edit-box.multi-select .layout.show-on-all-pages .handle.handle-resize-corner {
  border: 1px solid #000; }

.edit-box.multi-select .layout .handle.handle-resize-side.top:after, .edit-box.multi-select .layout .handle.handle-resize-side.right:after, .edit-box.multi-select .layout .handle.handle-resize-side.bottom:after, .edit-box.multi-select .layout .handle.handle-resize-side.left:after,
.edit-box.multi-select .layout.show-on-all-pages .handle.handle-resize-side.top:after,
.edit-box.multi-select .layout.show-on-all-pages .handle.handle-resize-side.right:after,
.edit-box.multi-select .layout.show-on-all-pages .handle.handle-resize-side.bottom:after,
.edit-box.multi-select .layout.show-on-all-pages .handle.handle-resize-side.left:after {
  border: 1px solid #000; }

.edit-box .layout {
  border: 1px solid #3899ec; }
  .edit-box .layout .handle {
    position: absolute; }
    .edit-box .layout .handle:active {
      visibility: visible !important; }
    .edit-box .layout .handle.top-left-resize-cursor {
      cursor: nwse-resize; }
    .edit-box .layout .handle.top-resize-cursor {
      cursor: ns-resize; }
    .edit-box .layout .handle.top-right-resize-cursor {
      cursor: nesw-resize; }
    .edit-box .layout .handle.right-resize-cursor {
      cursor: ew-resize; }
    .edit-box .layout .handle.bottom-right-resize-cursor {
      cursor: nwse-resize; }
    .edit-box .layout .handle.bottom-resize-cursor {
      cursor: ns-resize; }
    .edit-box .layout .handle.bottom-left-resize-cursor {
      cursor: nesw-resize; }
    .edit-box .layout .handle.left-resize-cursor {
      cursor: ew-resize; }
    .edit-box .layout .handle.handle-resize-corner {
      width: 7px;
      height: 7px;
      background-color: #fff;
      border: 1px solid #3899ec;
      border-radius: 50%;
      z-index: 1; }
      .edit-box .layout .handle.handle-resize-corner.top.left {
        top: -5px;
        left: -5px; }
      .edit-box .layout .handle.handle-resize-corner.top.right {
        top: -5px;
        right: -5px; }
      .edit-box .layout .handle.handle-resize-corner.bottom.right {
        bottom: -5px;
        right: -5px; }
      .edit-box .layout .handle.handle-resize-corner.bottom.left {
        bottom: -5px;
        left: -5px; }
    .edit-box .layout .handle.handle-resize-side.top {
      height: 5px;
      left: 4px;
      right: 4px;
      top: -2.5px; }
      .edit-box .layout .handle.handle-resize-side.top:after {
        content: "";
        width: 7px;
        height: 7px;
        background-color: #fff;
        border: 1px solid #3899ec;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -5px;
        margin-top: -5px; }
    .edit-box .layout .handle.handle-resize-side.right {
      width: 5px;
      top: 4px;
      bottom: 4px;
      right: -2.5px; }
      .edit-box .layout .handle.handle-resize-side.right:after {
        content: "";
        width: 7px;
        height: 7px;
        background-color: #fff;
        border: 1px solid #3899ec;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        right: 50%;
        margin-top: -5px;
        margin-right: -5px; }
    .edit-box .layout .handle.handle-resize-side.bottom {
      height: 5px;
      left: 4px;
      right: 4px;
      bottom: -2.5px; }
      .edit-box .layout .handle.handle-resize-side.bottom:after {
        content: "";
        width: 7px;
        height: 7px;
        background-color: #fff;
        border: 1px solid #3899ec;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        bottom: 50%;
        margin-left: -5px;
        margin-bottom: -5px; }
    .edit-box .layout .handle.handle-resize-side.left {
      width: 5px;
      top: 4px;
      bottom: 4px;
      left: -2.5px; }
      .edit-box .layout .handle.handle-resize-side.left:after {
        content: "";
        width: 7px;
        height: 7px;
        background-color: #fff;
        border: 1px solid #3899ec;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -5px;
        margin-left: -5px; }
    .edit-box .layout .handle.handle-rotate {
      width: 18px;
      height: 18px;
      top: -24px;
      left: -24px;
      background: linear-gradient(0deg, #d9d9d9 0%, #f4f4f4 69%, #ffffff 100%);
      box-shadow: 0 0 5px 0 rgba(22, 45, 61, 0.41);
      border-radius: 50%;
      cursor: pointer; }
      .edit-box .layout .handle.handle-rotate:active {
        opacity: 0.5; }
        .edit-box .layout .handle.handle-rotate:active svg path {
          fill: #3899ec; }
      .edit-box .layout .handle.handle-rotate:hover svg path {
        fill: #3899ec; }
      .edit-box .layout .handle.handle-rotate svg.symbol-rotate {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    .edit-box .layout .handle.handle-rotate-cancel {
      width: 13px;
      height: 13px;
      top: -38px;
      left: -38px;
      cursor: pointer;
      border: 1px #ee5951 solid;
      opacity: 0.6;
      border-radius: 50%;
      background: linear-gradient(0deg, #d9d9d9 0%, #f4f4f4 69%, #ffffff 100%); }
      .edit-box .layout .handle.handle-rotate-cancel:hover {
        border-color: #ee5951;
        opacity: 1; }
      .edit-box .layout .handle.handle-rotate-cancel svg {
        position: absolute;
        top: calc(50% - (2.5px));
        left: calc(50% - (2.5px)); }
    .edit-box .layout .handle.handle-push {
      cursor: s-resize; }
    .edit-box .layout .handle.handle-pull {
      cursor: n-resize; }
    .edit-box .layout .handle.handle-pull, .edit-box .layout .handle.handle-push {
      display: inline-block;
      padding: 0 10px;
      height: 19px;
      left: 75%;
      margin-left: -9.5px;
      border-radius: 6px;
      background: linear-gradient(0deg, #d9d9d9 0%, #f4f4f4 69%, #ffffff 100%);
      box-shadow: 0 0 5px 0 rgba(22, 45, 61, 0.41);
      font-size: 0;
      transition: padding-left 0.1s; }
      .edit-box .layout .handle.handle-pull.handle-drag-with-anchors, .edit-box .layout .handle.handle-push.handle-drag-with-anchors {
        top: -9.5px; }
        .edit-box .layout .handle.handle-pull.handle-drag-with-anchors:active, .edit-box .layout .handle.handle-push.handle-drag-with-anchors:active {
          opacity: 0.5; }
          .edit-box .layout .handle.handle-pull.handle-drag-with-anchors:active svg path, .edit-box .layout .handle.handle-push.handle-drag-with-anchors:active svg path {
            fill: #3899ec; }
        .edit-box .layout .handle.handle-pull.handle-drag-with-anchors:hover svg path, .edit-box .layout .handle.handle-push.handle-drag-with-anchors:hover svg path {
          fill: #3899ec; }
        .edit-box .layout .handle.handle-pull.handle-drag-with-anchors.showHover, .edit-box .layout .handle.handle-push.handle-drag-with-anchors.showHover {
          left: calc(50% + 76px);
          padding: 0 16px; }
          .edit-box .layout .handle.handle-pull.handle-drag-with-anchors.showHover:hover:not(:active), .edit-box .layout .handle.handle-push.handle-drag-with-anchors.showHover:hover:not(:active) {
            font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
            font-size: 12px;
            height: 18px;
            color: #3899ec;
            padding-left: 26px;
            padding-right: 12px;
            padding-top: 1px; }
          .edit-box .layout .handle.handle-pull.handle-drag-with-anchors.showHover svg, .edit-box .layout .handle.handle-push.handle-drag-with-anchors.showHover svg {
            left: 16px; }
        .edit-box .layout .handle.handle-pull.handle-drag-with-anchors svg, .edit-box .layout .handle.handle-push.handle-drag-with-anchors svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
      .edit-box .layout .handle.handle-pull.handle-resize-with-anchors:not(.footer-handle), .edit-box .layout .handle.handle-push.handle-resize-with-anchors:not(.footer-handle) {
        bottom: -9.5px; }
      .edit-box .layout .handle.handle-pull.handle-resize-with-anchors.footer-handle, .edit-box .layout .handle.handle-push.handle-resize-with-anchors.footer-handle {
        top: -9.5px; }
      .edit-box .layout .handle.handle-pull.handle-resize-with-anchors:active, .edit-box .layout .handle.handle-push.handle-resize-with-anchors:active {
        opacity: 0.5; }
        .edit-box .layout .handle.handle-pull.handle-resize-with-anchors:active svg path, .edit-box .layout .handle.handle-push.handle-resize-with-anchors:active svg path {
          fill: #3899ec; }
      .edit-box .layout .handle.handle-pull.handle-resize-with-anchors:hover svg path, .edit-box .layout .handle.handle-push.handle-resize-with-anchors:hover svg path {
        fill: #3899ec; }
      .edit-box .layout .handle.handle-pull.handle-resize-with-anchors.showHover, .edit-box .layout .handle.handle-push.handle-resize-with-anchors.showHover {
        left: calc(50% + 76px);
        padding: 0 16px; }
        .edit-box .layout .handle.handle-pull.handle-resize-with-anchors.showHover:hover:not(:active), .edit-box .layout .handle.handle-push.handle-resize-with-anchors.showHover:hover:not(:active) {
          font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
          font-size: 12px;
          height: 17px;
          color: #3899ec;
          padding-left: 26px;
          padding-right: 12px;
          padding-top: 1px; }
        .edit-box .layout .handle.handle-pull.handle-resize-with-anchors.showHover svg, .edit-box .layout .handle.handle-push.handle-resize-with-anchors.showHover svg {
          left: 16px; }
      .edit-box .layout .handle.handle-pull.handle-resize-with-anchors svg, .edit-box .layout .handle.handle-push.handle-resize-with-anchors svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
  .edit-box .layout.components-ui-color-orange {
    border-color: #fb7d33; }
    .edit-box .layout.components-ui-color-orange .handle.handle-resize-corner {
      border-color: #fb7d33; }
    .edit-box .layout.components-ui-color-orange .handle.handle-resize-side:after {
      border-color: #fb7d33; }
  .edit-box .layout.components-ui-color-orange.secondary-component:before, .edit-box .layout.secondary-component:before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    border: 1px dashed #fff; }
  .edit-box .layout.secondary-component {
    border: 1px solid #626262; }

.edit-box .move-to-footer {
  display: none;
  position: absolute;
  padding-left: 18px;
  padding-right: 15px;
  transform: translate(-50%);
  height: 28px;
  line-height: 28px;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 0 8px 0 rgba(22, 45, 61, 0.35);
  border-radius: 20px;
  cursor: pointer; }
  .edit-box .move-to-footer svg {
    margin-left: 9px; }
  .edit-box .move-to-footer.components-ui-color-orange {
    border-color: #fb7d33; }
  .edit-box .move-to-footer:active {
    opacity: 0.5;
    color: #3899ec; }
    .edit-box .move-to-footer:active svg path {
      fill: #3899ec; }
  .edit-box .move-to-footer:hover {
    color: #3899ec; }
    .edit-box .move-to-footer:hover svg path {
      fill: #3899ec; }

.edit-box.show-move-to-footer-button .move-to-footer {
  display: inline-block; }

.edit-box .bounding {
  visibility: hidden;
  border: 1px solid #3899ec; }
  .edit-box .bounding.components-ui-color-orange {
    border-color: #fb7d33; }

.edit-box .padding-wrapper {
  width: 100%; }
  .edit-box .padding-wrapper > .left-padding,
  .edit-box .padding-wrapper > .right-padding {
    position: absolute;
    background: rgba(56, 153, 236, 0.12) url("//static.parastorage.com/services/santa-resources/resources/editor/baseUI/panelInputs/graphic-slider-fill-pattern.png") repeat; }
    .edit-box .padding-wrapper > .left-padding .min-indicator-margin,
    .edit-box .padding-wrapper > .right-padding .min-indicator-margin {
      width: 56px;
      display: inline-block;
      position: relative; }
    .edit-box .padding-wrapper > .left-padding .indicator.resizing,
    .edit-box .padding-wrapper > .right-padding .indicator.resizing {
      visibility: visible;
      position: relative;
      padding: 3px 10px;
      top: 0; }
    .edit-box .padding-wrapper > .left-padding:before,
    .edit-box .padding-wrapper > .right-padding:before {
      content: '';
      display: inline-block;
      width: 3px;
      height: 15px;
      position: absolute;
      top: calc(50% - 7px);
      background: #3899ec; }
    .edit-box .padding-wrapper > .left-padding:after,
    .edit-box .padding-wrapper > .right-padding:after {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      border-top: #3899ec 1px dashed; }
  .edit-box .padding-wrapper > .left-padding:before {
    left: 0; }
  .edit-box .padding-wrapper > .right-padding:before {
    right: 0; }

.edit-box .indicator {
  visibility: hidden;
  position: absolute;
  height: 22px;
  line-height: 22px;
  top: -32px;
  left: 0;
  background-color: #343434;
  border-radius: 10px;
  opacity: 0.8;
  color: #fff;
  padding: 0 3px;
  white-space: nowrap; }

.edit-box.rotating .layout .handle {
  visibility: hidden; }

.edit-box.rotating .layout .indicator {
  visibility: visible; }

.edit-box.dragging .layout {
  border: none; }
  .edit-box.dragging .layout.secondary-component {
    border: 1px solid #626262; }
  .edit-box.dragging .layout .handle {
    visibility: hidden; }

.edit-box.dragging .bounding {
  visibility: visible; }
  .edit-box.dragging .bounding .indicator.dragging {
    visibility: visible; }

.edit-box.resizing .bounding {
  visibility: visible;
  border: none; }
  .edit-box.resizing .bounding .indicator.resizing {
    visibility: visible; }

.edit-box.resizing .layout .handle {
  visibility: hidden; }

.edit-box.dimmed-handles .layout {
  border-color: rgba(56, 153, 236, 0.3); }

.edit-box.dimmed-handles.components-ui-color-orange .layout {
  border-color: rgba(251, 125, 51, 0.3); }

.edit-box.dimmed-handles .handle:not(.handle-rotate):not(.handle-rotate-cancel) {
  opacity: 0.3; }

.edit-box.edit-box-for-pin-mode .handle {
  opacity: 0; }

.edit-box.small .layout .handle.handle-push {
  left: calc(100% + 13px);
  margin-left: 0; }

.focusbox .focus-frame {
  border: 3px solid #3899ec;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .focusbox .focus-frame > .grid-lines {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    shape-rendering: crispEdges; }
    .focusbox .focus-frame > .grid-lines > .line {
      stroke-width: 1; }
      .focusbox .focus-frame > .grid-lines > .line.back {
        stroke: #ffffff; }
      .focusbox .focus-frame > .grid-lines > .line.front {
        stroke: #000000;
        stroke-dasharray: 5.5; }

.focusbox.with-opacity {
  opacity: 0.3; }

.focusbox.components-ui-color-orange .focus-frame {
  border-color: #fb7d33; }

.hover-box {
  position: absolute;
  border: 1px solid rgba(211, 237, 255, 0.8); }
  .hover-box.components-background-color {
    background: rgba(211, 237, 255, 0.07); }
  .hover-box.overlay {
    background-color: rgba(211, 237, 255, 0.24); }
  .hover-box.components-ui-color-orange {
    border-color: rgba(235, 165, 84, 0.6);
    background-color: rgba(235, 165, 84, 0.05); }
    .hover-box.components-ui-color-orange .comp-label {
      background-color: #eba554;
      color: #fff; }
    .hover-box.components-ui-color-orange.overlay {
      background-color: rgba(235, 165, 84, 0.24); }
  .hover-box.fixed-position {
    position: fixed; }

.component-labels {
  position: absolute;
  white-space: nowrap;
  cursor: default;
  display: inline-flex;
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif; }
  .component-labels.label-large {
    font-size: 16px; }
    .component-labels.label-large.label-above-line {
      top: -20px; }
  .component-labels.label-inner {
    left: -6px; }
  .component-labels.label-small {
    left: -1px;
    font-size: 12px; }
  .component-labels.label-top {
    top: -17px; }
  .component-labels.label-bottom {
    bottom: -17px; }
  .component-labels.dimmed {
    opacity: 0.2;
    pointer-events: none; }
  .component-labels > .symbol-container {
    padding: 2px 0 1px 4px;
    height: 16px;
    display: inline-flex;
    box-sizing: border-box;
    background-color: #d3edff; }
    .component-labels > .symbol-container.connected-comp {
      background-color: #f0f3f5; }
    .component-labels > .symbol-container.components-ui-color-orange {
      background-color: #eba554; }
  .component-labels > .label-comp-type,
  .component-labels > .label-comp-nickname {
    display: inline-flex;
    height: 16px;
    padding: 0 6px; }
    .label-large.component-labels > .label-comp-type, .label-large.component-labels > .label-comp-nickname {
      height: 20px; }
    .component-labels > .label-comp-type.components-ui-color-orange,
    .component-labels > .label-comp-nickname.components-ui-color-orange {
      background-color: #eba554; }
  .component-labels > .label-comp-type {
    line-height: 16px;
    color: #598bdf;
    background-color: #d3edff; }
    .label-large.component-labels > .label-comp-type {
      line-height: 18px; }
    .component-labels > .label-comp-type.components-ui-color-orange {
      color: #fff; }
  .component-labels > .label-comp-nickname {
    line-height: 18px;
    font-family: Consolas, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    color: #162d3d;
    background-color: #f0f3f5; }
    .label-large.component-labels > .label-comp-nickname {
      line-height: 22px; }

.overlay {
  position: absolute;
  opacity: 0;
  transition-property: opacity;
  height: 100%;
  width: 100%; }
  .overlay.visible {
    opacity: 1; }

.pagination-controller {
  background: linear-gradient(0deg, #3899ec 0%, #4eb7f5 100%);
  box-shadow: 0 1px 3px 0 rgba(22, 45, 61, 0.55);
  height: 24px;
  line-height: 24px;
  padding-left: 32px;
  padding-right: 32px; }
  .pagination-controller.comp-bottom {
    border-radius: 0 0 6px 6px; }
  .pagination-controller.comp-top {
    border-radius: 6px 6px 0 0; }
  .pagination-controller .middle-label {
    text-overflow: ellipsis;
    overflow: hidden;
    color: #fff;
    display: inline-block;
    cursor: default; }
    .pagination-controller .middle-label:hover {
      cursor: move; }
  .pagination-controller .arrow {
    width: 20px;
    height: 20px;
    top: -2px;
    border-radius: 50%;
    background-color: #2b81cb;
    display: inline-block;
    margin-left: 6px;
    margin-right: 6px;
    position: absolute; }
    .pagination-controller .arrow:after {
      border-left: 2px solid #fff;
      border-top: 2px solid #fff;
      content: '';
      height: 6px;
      position: absolute;
      width: 6px; }
    .pagination-controller .arrow:hover {
      cursor: pointer;
      background-color: #d3edff; }
      .pagination-controller .arrow:hover:after {
        border-color: #3899ec; }
    .pagination-controller .arrow.prev-arrow {
      left: 0;
      top: 3px; }
      .pagination-controller .arrow.prev-arrow:after {
        left: calc(50% - 2px);
        top: calc(50% - 4px);
        transform: rotate(-45deg); }
    .pagination-controller .arrow.next-arrow {
      right: 0;
      top: 3px; }
      .pagination-controller .arrow.next-arrow:after {
        left: calc(50% - 5px);
        top: calc(50% - 4px);
        transform: rotate(135deg); }
  .pagination-controller.components-ui-color-orange {
    background: linear-gradient(0deg, #fb7d33 0%, #fc975c 100%); }
    .pagination-controller.components-ui-color-orange .arrow {
      background-color: #b8561d; }
      .pagination-controller.components-ui-color-orange .arrow:hover {
        background-color: #fed8c1; }
        .pagination-controller.components-ui-color-orange .arrow:hover:after {
          border-color: #fb7d33; }

.tabs-controller {
  overflow: hidden;
  position: relative;
  z-index: 0;
  left: 1px; }
  .tabs-controller.comp-top {
    margin-bottom: -3px; }
  .tabs-controller.comp-bottom {
    margin-top: -3px; }

.tabs-controller.comp-top .tab {
  border-radius: 6px 6px 0 0;
  line-height: 24px;
  align-self: flex-end; }
  .tabs-controller.comp-top .tab.active {
    line-height: 30px; }
  .tabs-controller.comp-top .tab.left-most-tab {
    border-radius: 6px 6px 0 3px; }
  .tabs-controller.comp-top .tab.right-most-tab {
    border-radius: 6px 6px 6px 0; }

.tabs-controller.comp-bottom .tab {
  border-radius: 0 0 6px 6px;
  line-height: 30px;
  align-self: flex-start; }
  .tabs-controller.comp-bottom .tab.left-most-tab {
    border-radius: 6px 0 6px 6px; }
  .tabs-controller.comp-bottom .tab.right-most-tab {
    border-radius: 0 6px 6px 6px; }

.tabs-controller .tab {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  position: relative;
  padding: 0 12px 0 17px;
  height: 27px;
  box-shadow: 0 1px 3px 0 rgba(22, 45, 61, 0.55);
  display: inline-block;
  background-color: #2b81cb;
  color: #ffffff;
  margin-left: -5px;
  text-align: center; }
  .tabs-controller .tab:hover {
    cursor: pointer; }
    .tabs-controller .tab:hover.active {
      cursor: default; }
  .tabs-controller .tab.left-most-tab {
    margin-left: 0;
    padding: 0 17px 0 12px; }
  .tabs-controller .tab.active {
    background: linear-gradient(0deg, #3899ec 0%, #4eb7f5 100%);
    color: #ffffff;
    height: 30px;
    padding: 0 12px;
    z-index: 1; }
  .tabs-controller .tab .action {
    position: absolute;
    top: 0;
    opacity: 0;
    text-align: center;
    width: 60px; }

.tabs-controller.components-ui-color-orange .tab {
  background-color: #b8561d; }
  .tabs-controller.components-ui-color-orange .tab.active {
    background: linear-gradient(0deg, #fb7d33 0%, #fc975c 100%); }

.nav-controls {
  white-space: nowrap;
  position: absolute; }
  .nav-controls.comp-bottom {
    top: 100%; }
  .nav-controls.comp-top {
    bottom: 100%; }

.editor-animation-manager-wrapper {
  position: relative;
  height: 100vh;
  width: 100vw; }

.editor-wrapper {
  z-index: 0;
  height: 100%;
  width: 100%; }
  .editor-wrapper.zoom-mode {
    background: #f0f3f5; }

.editor-content-wrapper {
  position: relative;
  top: 48px;
  height: calc(100% - 48px);
  transition: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) top, 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) height; }
  .editor-content-wrapper.tools-hidden {
    top: 6px;
    height: calc(100% - 6px);
    transition: 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) top, 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) height; }
  .editor-content-wrapper .splitter {
    height: 100%;
    width: 100%; }
  .editor-content-wrapper .wix-code-ide-pane {
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.14);
    z-index: 1070; }
    .editor-content-wrapper .wix-code-ide-pane.above-editor {
      z-index: 1440; }
  .editor-content-wrapper .ide-splitter .splitter-divider {
    z-index: 1071; }

.file-context {
  visibility: hidden; }

.editing-area {
  position: absolute;
  height: 100%;
  width: 100%; }
  .editing-area.mobile-editor {
    background: #f0f3f5; }
  .editing-area .preview-container {
    overflow-y: scroll;
    position: relative;
    width: 100%;
    height: 100%; }
  .editing-area.preview-mode .preview-container {
    overflow: hidden; }

.editor-stage {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%; }
  .editor-stage.mobile-editor {
    top: 59px; }

.text-editor {
  position: absolute;
  top: 0;
  left: 0; }

.tag-triangle:before {
  width: 10px;
  height: 10px;
  content: '';
  background-color: inherit;
  display: block;
  position: absolute;
  transform: rotate(45deg); }

.tag-triangle.tag-top:before {
  box-shadow: -2px -2px 6px -2px rgba(0, 0, 0, 0.35);
  top: -5px; }

.tag-triangle.tag-right:before {
  box-shadow: 2px -2px 6px -2px rgba(0, 0, 0, 0.35);
  right: -5px; }

.tag-triangle.tag-bottom:before {
  box-shadow: 2px 2px 6px -2px rgba(0, 0, 0, 0.35);
  bottom: -5px; }

.tag-triangle.tag-left:before {
  box-shadow: -2px 2px 6px -2px rgba(0, 0, 0, 0.35);
  left: -5px; }

.preview-frame-cover {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%; }
  .zoom-mode:not(.mobile-editor) .preview-frame-cover {
    top: 37px;
    height: calc(100% - 37px); }
  .mobile-editor .preview-frame-cover {
    top: 59px;
    height: calc(100% - 153px); }
  .mobile-editor.preview-mode .preview-frame-cover {
    max-height: 512px; }

#preview {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: 0;
  overflow: hidden; }

body {
  overflow-y: hidden; }

.testStyles {
  overflow-y: hidden; }

.mobile-frame-opacity-layer {
  position: absolute;
  pointer-events: none; }
  .mobile-frame-opacity-layer:before, .mobile-frame-opacity-layer:after {
    content: '';
    position: absolute;
    top: 59px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    width: calc((100% - 320px) / 2); }
  .mobile-frame-opacity-layer:before {
    left: 0; }
  .mobile-frame-opacity-layer:after {
    right: 0; }

.mobile-frame {
  z-index: 1;
  position: absolute;
  top: 0;
  pointer-events: none;
  width: 320px;
  left: calc((100% - 320px) / 2);
  height: 100%;
  transform-origin: center 0 0;
  transition: 0.3s transform, 0.3s height; }
  .mobile-frame .block-layer {
    position: absolute;
    top: auto;
    bottom: auto;
    left: 0;
    width: 100%;
    background-color: #f0f3f4; }
    .mobile-frame .block-layer.top {
      top: -59px;
      height: 59px; }
    .mobile-frame .block-layer.bottom {
      bottom: -94px;
      height: 94px;
      top: auto; }
  .mobile-frame .mobile-frame-top,
  .mobile-frame .mobile-frame-bottom,
  .mobile-frame .site-shadow {
    position: absolute; }
  .mobile-frame .site-shadow {
    height: 100%;
    width: 376px;
    left: -37px;
    background: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/mobileFrame/phone-bg.v3.png") center 0 repeat-y;
    background-size: 376px 5px; }
  .mobile-frame .mobile-frame-top {
    left: -26px;
    width: 353px;
    height: 45px;
    top: -45px;
    background: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/mobileFrame/phone-top.v3.png") center 0 no-repeat;
    background-size: 353px 45px;
    z-index: 1; }
  .mobile-frame .mobile-frame-bottom {
    left: -37px;
    width: 377px;
    height: 108px;
    bottom: -108px;
    background: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/mobileFrame/phone-bottom.v3.png") center 0 no-repeat;
    background-size: 377px 108px;
    z-index: 1; }
  @media only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
    .mobile-frame .mobile-frame-top {
      background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/mobileFrame/phone-top@x2.v4.png"); }
    .mobile-frame .mobile-frame-bottom {
      background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/mobileFrame/phone-bottom@x2.v4.png"); }
    .mobile-frame .site-shadow {
      background-image: url("//static.parastorage.com/services/santa-resources/resources/editor/rEditor/mobileFrame/phone-bg@x2.v3.png"); } }
  .mobile-frame.previewMode .site-shadow {
    max-height: 512px; }

.anchors-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }
  .anchors-container.mobile-editor {
    width: 320px;
    left: calc((100% - 320px) / 2); }
  .anchors-container .anchorWrapper {
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    transform: translate(0, -50%);
    pointer-events: none; }
    .anchors-container .anchorWrapper .anchor-line {
      border-top: 1px solid #5effff;
      transform: scale(1.00001); }
      .anchors-container .anchorWrapper .anchor-line.before {
        flex: 1; }
      .anchors-container .anchorWrapper .anchor-line.after {
        width: 20px; }
      .anchors-container .anchorWrapper .anchor-line.after-with-sections-reorganize {
        width: 68px; }
      .anchors-container .anchorWrapper .anchor-line.in-zoom {
        border-top-width: 2px; }
    .anchors-container .anchorWrapper .anchor {
      height: 24px;
      width: 126px;
      pointer-events: auto;
      margin-left: 14px; }
      .anchors-container .anchorWrapper .anchor .anchorContent {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        background: rgba(94, 255, 255, 0.7);
        line-height: 24px;
        text-align: left;
        box-sizing: border-box;
        height: 100%;
        cursor: move;
        position: relative; }
        .anchors-container .anchorWrapper .anchor .anchorContent:before {
          position: absolute;
          right: 100%;
          content: '';
          width: 0;
          height: 0;
          border-top: 12px solid transparent;
          border-bottom: 12px solid transparent;
          border-right: 15px solid rgba(94, 255, 255, 0.7); }
      .anchors-container .anchorWrapper .anchor svg {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%); }
        .anchors-container .anchorWrapper .anchor svg * {
          fill: rgba(10, 99, 99, 0.6); }
      .anchors-container .anchorWrapper .anchor .anchorIcon {
        left: 2px;
        width: 13px;
        height: 15px; }
      .anchors-container .anchorWrapper .anchor .rightIcon {
        right: 12px; }
      .anchors-container .anchorWrapper .anchor .anchorName {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
        font-size: 12px;
        position: absolute;
        left: 19px;
        width: 67px;
        color: #0a6363; }
      .anchors-container .anchorWrapper .anchor.anchor-selected .anchorContent,
      .anchors-container .anchorWrapper .anchor .anchorContent:hover {
        background: #5effff; }
        .anchors-container .anchorWrapper .anchor.anchor-selected .anchorContent svg *,
        .anchors-container .anchorWrapper .anchor .anchorContent:hover svg * {
          fill: #0a6363; }
        .anchors-container .anchorWrapper .anchor.anchor-selected .anchorContent:before,
        .anchors-container .anchorWrapper .anchor .anchorContent:hover:before {
          border-right-color: #5effff; }

.lasso-layer {
  width: 100%;
  height: 100%;
  position: absolute; }
  .lasso-layer .lasso-highlights {
    width: 100%;
    height: 100%;
    shape-rendering: crispEdges; }
    .lasso-layer .lasso-highlights .lasso-rect {
      fill: #3899ec;
      fill-opacity: 0.24;
      stroke: #3899ec;
      stroke-width: 1px; }
    .lasso-layer .lasso-highlights .lasso-candidate {
      stroke: #4a86e8;
      fill: none; }
      .lasso-layer .lasso-highlights .lasso-candidate.show-on-all-pages {
        stroke: #dc9024; }

.apply-style-panel .content {
  overflow: initial;
  padding: 0;
  overflow-x: hidden; }

.apply-style-panel .custom-scroll {
  border-left: 1px solid #d6e1e8; }

.apply-style-panel .comp-container {
  display: inline-block;
  width: 352px;
  max-height: 428px;
  background-color: #fff; }

.apply-style-panel .live-comps {
  margin-top: 91px; }

.apply-style-panel .current-style {
  border-right: 0; }
  .apply-style-panel .current-style .section-live-site-block-layer,
  .apply-style-panel .current-style .section-live-site-item {
    cursor: default !important; }

.apply-style-panel .container-wrapper {
  width: 352px;
  position: relative; }

.apply-style-panel .header {
  height: 67px;
  background-color: rgba(255, 255, 255, 0.9);
  border-bottom: 0;
  width: 340px;
  position: absolute;
  z-index: 100;
  pointer-events: none; }
  .apply-style-panel .header.applied-styles-header {
    border-left: 1px solid #d6e1e8; }

.apply-style-panel .current-style-header {
  border-right: 0; }

.apply-style-panel footer {
  right: 30px;
  bottom: 0;
  margin-bottom: 24px; }
  .apply-style-panel footer .btn-confirm-secondary {
    margin-right: 480px; }

.apply-style-panel .text {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-size: 14px;
  margin-left: 20px;
  color: #2b5672;
  line-height: 67px; }

.apply-style-panel .panel-content {
  margin-bottom: 24px;
  display: flex;
  border-bottom: 1px solid #d6e1e8;
  flex-direction: row; }

.apply-style-panel .help {
  color: #fff;
  right: 55px; }

.apply-style-panel .empty-styles {
  width: 352px;
  height: 185px;
  margin-top: 75px;
  text-align: center; }
  .apply-style-panel .empty-styles .empty-style-text {
    position: relative;
    top: 0; }

.pin-mode > .preview-rect {
  position: absolute;
  background-color: #3899ec;
  opacity: 0.5;
  border: 1px dashed rgba(255, 255, 255, 0.5); }

.pin-mode > .blocking-layer {
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute; }

.box-slide-show-manage-slides-panel.comp-panel-content {
  height: 525px; }

.box-slide-show-manage-slides-panel .sort-by-drag-list {
  padding-bottom: 114px; }

.box-slide-show-manage-slides-panel .item-list-row {
  height: 60px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding: 0 24px 0 12px;
  border-bottom: 1px solid #d9e1e8;
  cursor: move;
  position: relative; }
  .box-slide-show-manage-slides-panel .item-list-row.compacted {
    height: 32px; }
    .box-slide-show-manage-slides-panel .item-list-row.compacted .quick-actions {
      top: 3px; }
  .box-slide-show-manage-slides-panel .item-list-row .slide-name {
    display: inline-block;
    width: 191px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .box-slide-show-manage-slides-panel .item-list-row .symbol-dragHandle {
    width: 13px;
    height: 13px;
    padding-right: 13px; }
    .box-slide-show-manage-slides-panel .item-list-row .symbol-dragHandle path {
      fill: #7fccf7; }
    .box-slide-show-manage-slides-panel .item-list-row .symbol-dragHandle:hover path {
      fill: #3899ec; }
  .box-slide-show-manage-slides-panel .item-list-row .item-details {
    display: flex;
    flex: 1;
    align-items: center;
    height: 100%;
    font-size: 14px; }
  .box-slide-show-manage-slides-panel .item-list-row:hover {
    background-color: #eaf7ff; }
    .box-slide-show-manage-slides-panel .item-list-row:hover .quick-actions {
      visibility: visible; }
      .box-slide-show-manage-slides-panel .item-list-row:hover .quick-actions .context-menu-button {
        background-color: #fff; }
  .box-slide-show-manage-slides-panel .item-list-row .quick-actions {
    position: absolute;
    right: 24px;
    top: 15px;
    border: 0;
    visibility: hidden; }
    .box-slide-show-manage-slides-panel .item-list-row .quick-actions svg .cls-b1 {
      fill: #3899ec; }
    .box-slide-show-manage-slides-panel .item-list-row .quick-actions:hover .context-menu-button {
      background-color: #7fccf7; }
  .box-slide-show-manage-slides-panel .item-list-row.selected {
    background-color: #d3edff; }
    .box-slide-show-manage-slides-panel .item-list-row.selected .quick-actions {
      visibility: visible; }
      .box-slide-show-manage-slides-panel .item-list-row.selected .quick-actions .context-menu-button {
        background-color: #fff; }
      .box-slide-show-manage-slides-panel .item-list-row.selected .quick-actions .is-open .context-menu-button {
        background-color: #7fccf7; }

.box-slide-show-manage-slides-panel .control-text-input-with-fixed-button {
  flex: 1; }

.box-slide-show-manage-slides-panel .drag-placeholder {
  background-color: #f8f8f8;
  border-top: 1px dashed #d9e1e8; }

.box-slide-show-manage-slides-panel .drag-container {
  background-color: #eaf7ff;
  box-shadow: 0 1px 3.92px 0.08px rgba(45, 86, 114, 0.22); }

.box-slide-show-manage-slides-panel .button-wrapper {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0, white 90%);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  bottom: 0;
  height: 114px;
  width: 100%;
  position: absolute;
  text-align: center; }
  .box-slide-show-manage-slides-panel .button-wrapper .mainButton {
    top: 24px;
    position: relative;
    line-height: 26px; }
  .box-slide-show-manage-slides-panel .button-wrapper .secondaryButton {
    bottom: -30px;
    position: relative;
    font-size: 16px;
    width: 100%; }

.vertical-ruler {
  pointer-events: auto;
  position: absolute;
  right: 22px;
  top: 0;
  height: 22px; }
  .vertical-ruler .vertical-ticks {
    width: 100%;
    height: 100%; }
    .vertical-ruler .vertical-ticks:hover {
      cursor: copy; }
    .tools-hidden .vertical-ruler .vertical-ticks {
      height: 0; }
    .vertical-ruler .vertical-ticks .rulers-separator-blocker {
      position: absolute;
      top: 0;
      right: -22px;
      height: 22px;
      width: 22px;
      cursor: default;
      z-index: 160; }
    .vertical-ruler .vertical-ticks .rulers-background .rulers-vertical-ticks {
      background: url(//static.parastorage.com/services/santa-resources/resources/editor/rEditor/rootComps/rulers/ruler_ticks.png) 0 0 repeat-x;
      width: 100%;
      height: 100%; }
    .vertical-ruler .vertical-ticks .rulers-background .ruler-numbers-container {
      position: absolute;
      bottom: 2px;
      display: flex; }
      .vertical-ruler .vertical-ticks .rulers-background .ruler-numbers-container .ruler-number {
        display: inline-block;
        width: 100px;
        text-align: left; }

.horizontal-ruler {
  position: absolute;
  right: 0;
  top: 0;
  width: 22px; }
  .tools-hidden .horizontal-ruler .horizontal-ticks {
    width: 0; }
  .horizontal-ruler .horizontal-ticks .rulers-separator {
    position: absolute;
    width: 22px;
    height: 22px;
    top: 0;
    background-color: #fff;
    transition: opacity 0.15s ease-in;
    transition-delay: 0.15s;
    opacity: 0.5; }
    .horizontal-ruler .horizontal-ticks .rulers-separator.rulers-separator-hover {
      transition: opacity 0.15s ease-out;
      opacity: 0.9;
      cursor: default; }
  .horizontal-ruler .horizontal-ticks .rulers-background {
    position: absolute;
    top: 22px;
    right: 0; }
    .horizontal-ruler .horizontal-ticks .rulers-background.rulers-background-mobile {
      top: 0; }
    .horizontal-ruler .horizontal-ticks .rulers-background .rulers-horizontal-ticks {
      background: url(//static.parastorage.com/services/santa-resources/resources/editor/rEditor/rootComps/rulers/ruler_ticks_vertical.png) repeat-y;
      width: 100%;
      height: 100%; }
    .horizontal-ruler .horizontal-ticks .rulers-background .ruler-numbers-container {
      position: absolute;
      text-align: left;
      width: 100%;
      height: 100%; }
      .horizontal-ruler .horizontal-ticks .rulers-background .ruler-numbers-container .ruler-number {
        word-break: break-all;
        height: 100px;
        width: 0;
        margin-left: 4px;
        text-align: left; }
  .horizontal-ruler .horizontal-ticks:hover {
    cursor: copy; }

.rulers .rulers-background {
  height: 100%;
  width: 100%;
  background-color: #fff;
  transition: opacity 0.15s ease-in;
  transition-delay: 0.15s;
  opacity: 0.5; }
  .rulers .rulers-background.rulers-background-hover {
    transition: opacity 0.15s ease-out;
    opacity: 0.9; }
  .rulers .rulers-background .ruler-numbers-container {
    color: #384f68;
    font-size: 9px;
    letter-spacing: 1px; }

.rulers.mobile-editor-rulers.vertical-ruler {
  top: -59px; }

.rulers.mobile-editor-rulers .horizontal-ticks {
  top: 0; }
  .rulers.mobile-editor-rulers .horizontal-ticks .ruler-numbers-container {
    top: 0; }

.vertical-guide-container {
  display: inline-block;
  position: absolute;
  width: 0;
  top: 0; }
  .vertical-guide-container .vertical-guide-catcher {
    display: inline-block;
    position: absolute;
    left: -5px;
    width: 11px;
    height: 22px; }
    .vertical-guide-container .vertical-guide-catcher:hover {
      cursor: ew-resize; }
    .tools-hidden .vertical-guide-container .vertical-guide-catcher {
      height: 0; }
      .tools-hidden .vertical-guide-container .vertical-guide-catcher:hover {
        cursor: default; }
    .vertical-guide-container .vertical-guide-catcher .vertical-guide-handler {
      z-index: 150;
      position: absolute; }
      .vertical-guide-container .vertical-guide-catcher .vertical-guide-handler .symbol-guidesHandler_regular {
        position: absolute;
        top: 0; }
      .vertical-guide-container .vertical-guide-catcher .vertical-guide-handler .symbol-guidesHandler_selected {
        position: absolute;
        top: 0;
        left: -2px; }
  .vertical-guide-container .vertical-guide {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: #91f7fd;
    pointer-events: none; }
    .vertical-guide-container .vertical-guide:hover {
      cursor: default; }
    .mobile-editor-rulers .vertical-guide-container .vertical-guide {
      z-index: 1; }

.horizontal-guide-container {
  position: absolute;
  height: 0;
  width: 0;
  right: 0; }
  .horizontal-guide-container .horizontal-guide-catcher {
    position: absolute;
    right: 0;
    top: -5px;
    height: 11px;
    width: 22px; }
    .horizontal-guide-container .horizontal-guide-catcher:hover {
      cursor: ns-resize; }
    .tools-hidden .horizontal-guide-container .horizontal-guide-catcher {
      width: 0; }
      .tools-hidden .horizontal-guide-container .horizontal-guide-catcher:hover {
        cursor: default; }
    .horizontal-guide-container .horizontal-guide-catcher .horizontal-guide-handler {
      z-index: 150;
      position: absolute;
      right: -3px;
      top: -4px; }
      .horizontal-guide-container .horizontal-guide-catcher .horizontal-guide-handler .symbol-guidesHandler_regular,
      .horizontal-guide-container .horizontal-guide-catcher .horizontal-guide-handler .symbol-guidesHandler_selected {
        transform: rotate(90deg); }
  .horizontal-guide-container .horizontal-guide {
    position: absolute;
    height: 1px;
    background-color: #91f7fd;
    pointer-events: none; }
    .horizontal-guide-container .horizontal-guide:hover {
      cursor: default; }

/*# sourceMappingURL=editor.css.map */